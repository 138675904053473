import { message, Upload } from "antd";
import { IExternalServiceInfo } from "legacyComponents/FileUploadContainer";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import pLimit from "p-limit";

import { MediaType, TempFileTypes } from "types/enum";
import { maxAllowedFileSizeMB } from "types/MediaConstants";

import { APIError, showAPIErrorMessage } from "utilities/apiHandler";
import { FileUploadHandler } from "utilities/document/fileUpload.api.handler";
import { convertFileSizeToBytes, FileSizeUnit } from "utilities/file/file";
import { getDataThumbnailFromVideoBlob } from "utilities/video/video.utility";
import { VideoUploadHandler } from "utilities/video/videoUpload.api.handler";

import { isFileAVideo } from "entity/media/common/media.utils";

// Mirroring UploadFile_BasicConstantsSerialized.FileTypeBlacklist on the backend
const fileTypeBlacklistString =
    ".adp,.app,.asp,.bas,.bat,.cer,.chm,.cmd,.cnt,.com,.cpl,.crt,.csh,.der,.exe,.fxp,.gadget,.hlp,.hpj,.hta,.inf,.ins,.isp,.its,.js,.jse,.ksh,.lnk,.mad,.maf,.mag,.mam,.maq,.mar,.mas,.mat,.mau,.mav,.maw,.mda,.mdb,.mde,.mdt,.mdw,.mdz,.msc,.msh,.msh1,.msh2,.mshxml,.msh1xml,.msh2xml,.msi,.msp,.mst,.ops,.osd,.pcd,.pif,.plg,.prf,.prg,.pst,.reg,.scf,.scr,.sct,.shb,.shs,.ps1,.ps1xml,.ps2,.ps2xml,.psc1,.psc2,.tmp,.url,.vb,.vbe,.vbp,.vbs,.vsmacros,.vsw,.ws,.wsc,.wsf,.wsh,.xnk,.ade,.cla,.class,.grp,.jar,.mcf,.ocx,.pl,.xbap,.ds_store";
export const fileTypeBlacklist = fileTypeBlacklistString
    .split(",")
    .map((type) => type.substring(1));

const tempFileMaxSizeInMegabytes = maxAllowedFileSizeMB;
const actualTempFileMaxSizeInMegabytes = 513;
const videoMaxSizeInMegabytes = 5120; // 5GB
const concurrentUploadLimit = pLimit(10);
const tempFileMaxSizeInBytes = convertFileSizeToBytes(
    actualTempFileMaxSizeInMegabytes,
    FileSizeUnit.Megabyte
);
const videoMaxSizeInBytes = convertFileSizeToBytes(videoMaxSizeInMegabytes, FileSizeUnit.Megabyte);

export interface IFileUploadUtilProps {
    file: File;
    tempFileType: TempFileTypes;
    jobId: number | null;
    builderId?: number;
    /** event function will be called as file upload progress increments*/
    uploadProgress: (e: ProgressEvent) => void;
    uploadConfig: IUploadConfig;
    mediaType?: MediaType;
    shouldSuppressErrorMessage?: boolean;
    isExternal?: boolean;
    externalServiceInfo?: IExternalServiceInfo;
}

export interface IUploadConfig {
    /** Marks photo as full res and does not compress on server*/
    shouldUploadFullResolutionPhoto?: boolean;
    shouldSuppressProgressEvents?: boolean;
}

function shouldUseVideoUploadHandler(file: File, mediaType: MediaType | undefined) {
    return (
        (mediaType === MediaType.Video || mediaType === MediaType.Unavailable) && isFileAVideo(file)
    );
}

export async function uploadMultiMedia(props: IFileUploadUtilProps) {
    try {
        let file;
        if (shouldUseVideoUploadHandler(props.file, props.mediaType)) {
            const videoUploadHandler = new VideoUploadHandler();
            file = await concurrentUploadLimit(() => videoUploadHandler.upload(props));
        } else {
            const fileUploadHandler = new FileUploadHandler();
            file = await concurrentUploadLimit(() => fileUploadHandler.upload(props));
        }

        // Process videos accordingly to support thumbnails & Lightbox viewing.
        if (isFileAVideo(props.file)) {
            file.nativeFile = props.file;
            file.thumbnail = await getDataThumbnailFromVideoBlob(props.file);
            file = new BTFileSystem(file);
        }

        return file;
    } catch (e) {
        if (!props.shouldSuppressErrorMessage) {
            showAPIErrorMessage(e);
        }
        throw e;
    }
}

export function beforeFileUpload(
    allowedFileTypes: string[],
    beforeUpload?: (file: File, fileList: File[]) => Promise<boolean>,
    suppressErrorMessages?: boolean,
    disallowedFileTypes?: string[],
    mediaType?: MediaType
) {
    return (file: File, fileList: File[]) => {
        const isValidFile = isValidFileType(
            file,
            allowedFileTypes,
            suppressErrorMessages,
            disallowedFileTypes
        );

        if (!isValidFile) {
            return Upload.LIST_IGNORE;
        }

        const shouldUseVideoHandlerMaxSize = shouldUseVideoUploadHandler(file, mediaType);
        const maxFileSize = shouldUseVideoHandlerMaxSize
            ? videoMaxSizeInBytes
            : tempFileMaxSizeInBytes;
        if (file.size >= maxFileSize) {
            if (!suppressErrorMessages) {
                const fileSizeErrorMessage = shouldUseVideoHandlerMaxSize
                    ? `The size limit for an individual video upload is ${videoMaxSizeInMegabytes} MB with a time limit of 15-minutes.`
                    : `${file.name} is too large to be selected! Max size per file is ${tempFileMaxSizeInMegabytes} MB`;

                void message.error(fileSizeErrorMessage);
            }
            return Upload.LIST_IGNORE;
        }

        return new Promise<void>(async (resolve, reject) => {
            if (beforeUpload && !(await beforeUpload(file, fileList))) {
                reject();
            }
            resolve();
        });
    };
}

// Blacklist filetypes are not needed for onDrop. They will be checked for in beforeUpload because
// antd's Upload only prevents that function when the filetype doesn't have a type match in 'accept' prop
export function onFileUploadDrop(allowedFileTypes: string[], suppressErrorMessages?: boolean) {
    if (suppressErrorMessages) {
        return () => {};
    }
    return (dragEvent: React.DragEvent) => {
        const files = dragEvent.dataTransfer.files;

        for (const file of Array.from(files)) {
            const isValidFile = isValidFileType(file, allowedFileTypes);
            if (!isValidFile) {
                break; // Only need to display error toast once
            }
        }
    };
}

function isValidFileType(
    file: File,
    allowedFileTypes: string[],
    suppressErrorMessages?: boolean,
    disallowedFileTypes?: string[]
) {
    const allFileTypes = allowedFileTypes.length === 1 && allowedFileTypes[0] === "*";
    const fileNameArray = file.name.toLocaleLowerCase().split(".");
    const hasNoExtension = fileNameArray.length === 1; // If for some reason there is no extension, decline it

    const whiteListMatch = allowedFileTypes.find(
        (allowedFileType) =>
            allowedFileType.toLocaleLowerCase() === fileNameArray[fileNameArray.length - 1]
    );
    const blackListMatch = (disallowedFileTypes ?? fileTypeBlacklist).find(
        (type) =>
            type.toLocaleLowerCase() === fileNameArray[fileNameArray.length - 1].toLocaleLowerCase()
    );

    if (hasNoExtension || (!allFileTypes && !whiteListMatch) || blackListMatch) {
        if (!suppressErrorMessages) {
            let errorMessage = "The file type selected is not supported for this field.";
            if (!allFileTypes && !whiteListMatch) {
                errorMessage = `${errorMessage} Files with the following extensions are allowed: .${allowedFileTypes.join(
                    ", ."
                )}`;
            }
            void message.error(errorMessage, 5);
        }
        return false;
    }
    return true;
}

export function uploadAction(
    tempFileType: TempFileTypes,
    uploadConfig: IUploadConfig,
    mediaType?: MediaType,
    builderId?: number,
    jobId?: number,
    isExternal?: boolean,
    externalServiceInfo?: IExternalServiceInfo
) {
    return async (options: object) => {
        const requestOpts = options as any;
        const uploadProps: IFileUploadUtilProps = {
            tempFileType,
            file: requestOpts.file,
            jobId: jobId ?? null,
            builderId: builderId,
            uploadProgress: (e) => {
                requestOpts.onProgress({ percent: e.loaded });
            },
            uploadConfig,
            mediaType,
            shouldSuppressErrorMessage: true,
            externalServiceInfo,
            isExternal,
        };

        try {
            const response = await uploadMultiMedia(uploadProps);
            requestOpts.onSuccess(response);
        } catch (e) {
            requestOpts.onError(e);
        }
    };
}

export function showFailedUploadMessage(errorValue?: unknown) {
    let displayMessage = "Failed to upload file";

    // display server error message if it's a size issue
    // (primarily photos between 10MB and 50MB)
    if (
        errorValue instanceof APIError &&
        (errorValue.errorMessage.includes("was too large to upload") ||
            errorValue.errorMessage.includes("0 bytes"))
    ) {
        displayMessage = errorValue.errorMessage;
    }

    void message.error(displayMessage);
}
