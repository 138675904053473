import { Dispatch, SetStateAction, useContext, useState } from "react";

import { FilterValueContext } from "helpers/globalContext/FilterValueContext";

import { track } from "utilities/analytics/analytics";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconFilter } from "commonComponents/btWrappers/BTIcon";

import {
    Filter,
    FilterUnmanaged,
    IFilterProps,
    IFilterUnmanagedProps,
} from "entity/filters/Filter/Filter";
import { FilterCountDisplay, FilterCountProvider } from "entity/filters/Filter/FilterCount";
import { IFilterDrawerProps } from "entity/filters/Filter/FilterDrawer.types";

import "./FilterDrawer.less";

type UnmanagedFilterDrawerProps = IFilterDrawerProps & IFilterUnmanagedProps;

function isUnmanagedFilterDrawerProps(
    props: UnmanagedFilterDrawerProps | IFilterDrawerProps
): props is UnmanagedFilterDrawerProps {
    return !!(props as UnmanagedFilterDrawerProps).updateUnsavedChanges;
}

export const FilterDrawer: React.FC<IFilterDrawerProps | UnmanagedFilterDrawerProps> = track({
    component: "Filter Drawer",
})((props) => {
    const openState: [boolean, Dispatch<SetStateAction<boolean>>] =
        props.open === undefined && props.setOpen === undefined
            ? useState(false as boolean)
            : [!!props.open, props.setOpen || ((() => {}) as Dispatch<SetStateAction<boolean>>)];
    const [open, setOpen] = openState;

    const filterContext = useContext(FilterValueContext);

    const appliedType = filterContext?.appliedFilter?.type;
    if (appliedType !== undefined && appliedType !== props.filterTypeEntity) {
        filterContext?.setAppliedFilter(undefined);
        return null;
    }

    let label: React.ReactNode;
    if (!props.iconOnly) {
        label = (
            <>
                Filter <FilterCountDisplay />
            </>
        );
    }

    return (
        <FilterCountProvider>
            {!props.setOpen ? (
                <BTButton
                    type={props.iconOnly ? "link" : "secondary"}
                    htmlType={props.iconOnly ? "button" : undefined}
                    data-testid="filters"
                    icon={<BTIconFilter />}
                    onClick={() => setOpen(!open)}
                    className="FilterDrawerButton"
                    disabled={props.disabled}
                >
                    {label}
                </BTButton>
            ) : null}
            {isUnmanagedFilterDrawerProps(props) ? (
                <FilterUnmanaged {...props} useDrawer drawerOpen={open} setDrawerOpen={setOpen} />
            ) : (
                <Filter {...props} useDrawer drawerOpen={open} setDrawerOpen={setOpen} />
            )}
        </FilterCountProvider>
    );
});

/**
 * Use when filter button needs to appear before entity is loaded
 */
export const FilterDrawerWrapped: React.FC<{ filterProps?: IFilterProps }> = (props) => {
    if (!props.filterProps) {
        return (
            <BTButton type="secondary" data-testid="filters" icon={<BTIconFilter />}>
                Filter <FilterCountDisplay />
            </BTButton>
        );
    }
    return <FilterDrawer {...props.filterProps} />;
};
