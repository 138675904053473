import { BTButtonType } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTIconExternalLink, BTIconLearningAcademy } from "commonComponents/btWrappers/BTIcon";

interface IBLALinkProps {
    href?: string;
    buttonType?: BTButtonType;
    isEmptyState?: boolean;
}

const BLALink: React.FC<IBLALinkProps> = ({
    href,
    buttonType = "secondary",
    isEmptyState = true,
}) => {
    if (isEmptyState) {
        return (
            <BTExternalLink buttonType={buttonType} href={href} data-testid="bla-link-learnHow">
                <BTIconExternalLink />
                Learn How
            </BTExternalLink>
        );
    } else {
        return (
            <BTExternalLink buttonType={buttonType} href={href} data-testid="bla-link-learnMore">
                <BTIconLearningAcademy />
                Learn More
            </BTExternalLink>
        );
    }
};

export default BLALink;
