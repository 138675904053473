import { message } from "antd";
import { FormikErrors } from "formik";
import {
    AttachedFiles,
    AttachedFilesRequest,
    BTFileSystem,
    MapBTFileToFileUpload,
} from "legacyComponents/FileUploadContainer.types";
import {
    GeneralItemMappingLineItem,
    ILineItemDefaults,
    ILineItemResponse,
    LineItem,
    LineItemType,
} from "legacyComponents/LineItemContainer.types";
import { isEqual, orderBy } from "lodash-es";
import memoizeOne from "memoize-one";

import { BTSelectItem, mapBTServiceDropdownToSelectItem } from "types/apiResponse/apiResponse";
import {
    CostTypes,
    DocumentInstanceType,
    EntityWithLinkedCostCodeItemsType,
    POMappingEntityTypes,
} from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import yup from "utilities/form/yup";
import { SortDirection } from "utilities/list/list.types";
import { add, divide, multiply, round, subtract } from "utilities/math/math";
import { isNullOrUndefined } from "utilities/object/object";
import { lexicographicSorter, numberSorter } from "utilities/sort/sort";
import { isNullOrWhitespace } from "utilities/string/string";
import { calculateTotalWithTax } from "utilities/tax/tax.utilities";

import { btConfirm, btDeleteConfirmAsync } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { IVarianceCodeExtraData } from "commonComponents/entity/variance/Variance/Variance.api.types";
import { TaxGroupServiceListItemExtraData } from "commonComponents/financial/TaxRateSelect/TaxRateSelect.api.types";
import {
    ILineItemGroup,
    ILineItemRow,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    IBaseLineItem,
    ICostLineItem,
    IMarkupLineItem,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";
import {
    getLineItemData,
    isLineItemRow,
    isMarkupLineItem,
    LineItemColumns,
    MarkupType,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";
import { PercentageSection } from "commonComponents/utilities/PercentageDisplayBar/PercentageDisplayBar.types";

import { AssemblyEntity } from "entity/assembly/Assembly/Assembly.api.types";
import { CostCatalogHandler } from "entity/costCatalog/CostCatalog.api.handler";
import { IAddFromCatalogRequest } from "entity/costCatalog/CostCatalog.types";
import { CostItemHandler } from "entity/costItem/CostItem/CostItem.api.handler";
import { ICostItemEntity } from "entity/costItem/CostItem/CostItem.api.types";
import {
    UnassignedCostGroupId,
    WorksheetLineItem,
} from "entity/estimate/common/estimate.common.types";
import { ICostTypesMarkup } from "entity/job/Job.api.types";
import { POPaymentStatus } from "entity/purchaseOrderPayment/common/PurchaseOrderPaymentStatus/PurchaseOrderPaymentStatus";
import { TaxMethod } from "entity/tax/common/tax.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";

export function getOwnerPriceRawTotal(lineItems: LineItem[] | IMarkupLineItem[]): number {
    return round(
        (lineItems as any[]).reduce(
            (total: number, lineItem: LineItem | IMarkupLineItem) =>
                add(total, lineItem.ownerPrice || 0),
            0
        ),
        2
    );
}

export function getBuilderCostRawTotal(lineItems: LineItem[] | ICostLineItem[]): number {
    return round(
        (lineItems as any[]).reduce(
            (total: number, lineItem: LineItem | ICostLineItem) =>
                add(total, lineItem.builderCost || 0),
            0
        ),
        2
    );
}

export const mapGeneralItemsToEntityLineItems = (generalItems: IBaseLineItem[]) => {
    let mappedItems: GeneralItemMappingLineItem[] = [];
    generalItems.forEach((li: any) => {
        mappedItems.push(new GeneralItemMappingLineItem(li));
    });
    return mappedItems;
};

/**
 * Returns data for a new line item. The returned value should be passed to the constructor for the specific
 * line item type required. This returns default fields for all line item types.
 * @param id line item ID for the new line item
 * @param lineItemType the type of line item we are using
 * @param costCategoryId cost category ID for the new line item
 * @param costCodeId optional cost code ID for the new line item
 * @param isInEdit optional value to set whether or not the new line item should be in edit mode
 * @param quantity optional quantity for the new line item
 * @returns default field values for all line item types. Pass to the constructor for required line item type
 */
export const newLineItemDefaults = (
    id: number,
    lineItemType: LineItemType,
    costCategoryId: number | null,
    costCodeId: number | null,
    isInEdit: boolean = true,
    quantity: number | null = 1,
    isTBD: boolean = false,
    markup?: number,
    margin?: number
): ILineItemRow => {
    let defaultMarkup = markup ?? 0;
    if (margin) {
        defaultMarkup = getMarkupPercentageFromMarginPercentage(margin);
    }
    return {
        hasRelatedPurchaseOrder: false,
        relatedItem: null,
        lineItemType: lineItemType,
        ownerPrice: 0,
        amountInvoiced: 0,
        costItemId: null,
        jobId: 0, // todo: when hooking up with service see if we need to set this before sending up
        builderCost: 0,
        builderId: 0, // todo: when hooking up with service see if we need to set this before sending up
        id: id,
        costCategoryId: costCategoryId,
        costCodeId: costCodeId,
        costTypes: [],
        description: "",
        internalNotes: "",
        relatedItems: null,
        itemTitle: "",
        markedAs: -1,
        markupAmount: 0,
        markupPercent: defaultMarkup,
        markupPerUnit: 0,
        markupType: MarkupType.percent,
        margin: margin ?? getMarginPercentageFromMarkupPercentage(defaultMarkup),
        quantity: quantity,
        unit: "",
        unitCost: 0,
        isEditable: true,
        _isInEdit: isInEdit,
        isFirstEdit: true,
        costCategoryFormatId: UnassignedCostGroupId,
        taxGroupId: NoTaxId,
        totalWithTax: 0,
        isTBD: isTBD,
        takeoffLineItemId: null,
    } as ILineItemRow;
};

export const mapAssemblyToCategoryGroup = (
    assembly: AssemblyEntity,
    isInEdit: boolean = true
): ILineItemGroup => {
    return {
        id: assembly.id,
        costCategoryId: null,
        title: assembly.title,
        builderId: assembly.builderId,
        jobId: assembly.jobId,
        leadId: assembly.leadId,
        proposalId: assembly.proposalId,
        description: "",
        lineItems: [],
        items: [],
        isExpanded: false,
        _isInEdit: isInEdit,
    } as ILineItemGroup;
};

export function calculateBuilderCost(unitCost: number, quantity: number | null) {
    if (quantity === 0) {
        return 0;
    } else if (quantity && quantity !== null) {
        return round(multiply(unitCost || 0, quantity), 2);
    }
    return unitCost;
}

export function recalculateBuilderCost(
    lineItem: ICostLineItem,
    lineItemPath: string,
    onChange: (fieldId: string, value: any) => void,
    ownerPriceLocked?: boolean,
    totalEffectiveRate?: number,
    taxMethod?: TaxMethod
): void {
    const builderCost = calculateBuilderCost(lineItem.unitCost || 0, lineItem.quantity);

    if (isMarkupLineItem(lineItem)) {
        if (ownerPriceLocked) {
            const originalMarkupType = lineItem.markupType;
            const calculatedFields = recalculateMarkupFields({
                ...lineItem,
                markupType: MarkupType.none,
                builderCost,
                totalEffectiveRate,
                taxMethod,
            });
            onChange(lineItemPath, { ...calculatedFields, markupType: originalMarkupType });
        } else {
            onChange(
                lineItemPath,
                recalculateMarkupFields({ ...lineItem, builderCost }, totalEffectiveRate, taxMethod)
            );
        }
    } else {
        onChange(lineItemPath, { ...lineItem, builderCost });
    }
}

export function recalculateMarkupFields<T extends IMarkupLineItem>(
    lineItem: T,
    totalEffectiveRate?: number,
    taxMethod?: number,
    newMarkupInput?: boolean
): T {
    const { markupAmount, markupPerUnit, markupPercent, ownerPrice, totalWithTax, margin } =
        calculateMarkupFields(lineItem, totalEffectiveRate, taxMethod, newMarkupInput);

    return {
        ...lineItem,
        markupAmount,
        markupPerUnit,
        markupPercent,
        ownerPrice,
        totalWithTax,
        margin,
    };
}

export function recalculateAndSetMarkupFields<T extends IMarkupLineItem>(
    lineItem: T,
    totalEffectiveRate?: number,
    taxMethod?: number,
    newMarkupInput?: boolean
) {
    const { markupAmount, markupPerUnit, markupPercent, ownerPrice, totalWithTax, margin } =
        calculateMarkupFields(lineItem, totalEffectiveRate, taxMethod, newMarkupInput);

    lineItem.markupAmount = markupAmount;
    lineItem.markupPerUnit = markupPerUnit;
    lineItem.markupPercent = markupPercent;
    lineItem.ownerPrice = ownerPrice;
    lineItem.totalWithTax = totalWithTax;
    lineItem.margin = margin;
}

export function recalculateQuantity(
    lineItem: ICostLineItem,
    lineItemPath: string,
    onChange: (fieldId: string, value: ICostLineItem) => void
): void {
    if (lineItem.unitCost) {
        const quantity = round(divide(lineItem.builderCost, lineItem.unitCost), 4);
        onChange(lineItemPath, { ...lineItem, quantity });
    } else {
        onChange(lineItemPath, { ...lineItem, quantity: 0, builderCost: 0 });
    }
}

function calculateMarkupFields<T extends IMarkupLineItem>(
    lineItem: T,
    totalEffectiveRate: number | undefined,
    taxMethod: number | undefined,
    newMarkupInput: boolean | undefined
) {
    let markupAmount = 0;
    let ownerPrice;
    let markupPercent;
    let markupPerUnit;
    let totalWithTax;
    let margin;

    switch (lineItem.markupType) {
        case MarkupType.percent:
            markupPercent = lineItem.markupPercent;
            markupAmount = round(
                divide(multiply(lineItem.builderCost, lineItem.markupPercent), 100),
                2
            );
            markupPerUnit = round(multiply(lineItem.unitCost ?? 0, divide(markupPercent, 100)), 2);
            break;
        case MarkupType.flat:
            markupAmount = lineItem.markupAmount;
            break;
        case MarkupType.perUnit:
            markupPerUnit = lineItem.markupPerUnit;
            markupAmount = round(multiply(lineItem.markupPerUnit, lineItem.quantity ?? 0), 2);
            break;
        case MarkupType.none:
            ownerPrice = lineItem.ownerPrice || 0;
            markupAmount = round(subtract(ownerPrice, lineItem.builderCost), 2);
            break;
        default:
            ownerPrice = lineItem.ownerPrice || 0;
    }

    if (!markupPercent) {
        if (lineItem.builderCost) {
            markupPercent = round(multiply(divide(markupAmount, lineItem.builderCost), 100), 2);
        } else {
            markupPercent = 0;
        }
    }

    if (!markupPerUnit) {
        if (lineItem.quantity) {
            markupPerUnit = round(divide(markupAmount, lineItem.quantity), 2);
        } else {
            markupPerUnit = 0;
        }
    }

    if (ownerPrice === undefined) {
        ownerPrice = round(add(lineItem.builderCost, markupAmount), 2);
    }

    if (totalEffectiveRate && taxMethod) {
        totalWithTax = calculateTotalWithTax(ownerPrice, totalEffectiveRate, taxMethod);
    } else {
        totalWithTax = ownerPrice;
    }

    if (!lineItem.margin || newMarkupInput) {
        // calculate margin from markup amount or markup per unit if markup percent is zero
        margin = calculateMarginPercentageFromMarkup(
            markupPercent,
            markupAmount,
            markupPerUnit,
            lineItem.unitCost ?? 0,
            lineItem.quantity
        );
    } else {
        margin = lineItem.margin;
    }

    return {
        ...lineItem,
        markupAmount,
        markupPerUnit,
        markupPercent,
        ownerPrice,
        margin,
        totalWithTax,
    };
}

export function recalculateLineItemFromMargin(
    margin: number | undefined,
    lineItem: IMarkupLineItem,
    totalEffectiveRate?: number,
    taxMethod?: number
): IMarkupLineItem {
    const markupPercent = getMarkupPercentageFromMarginPercentage(margin ?? 0);
    const markupAmount = round(multiply(lineItem.builderCost, markupPercent / 100), 2);
    const markupPerUnit = lineItem.quantity ? round(divide(markupAmount, lineItem.quantity), 2) : 0;
    const ownerPrice = round(add(lineItem.builderCost, markupAmount), 2);
    const newMargin =
        margin !== undefined ? getMarginPercentageFromMarkupPercentage(markupPercent) : undefined;

    const totalWithTax =
        totalEffectiveRate && taxMethod
            ? calculateTotalWithTax(ownerPrice, totalEffectiveRate, taxMethod)
            : ownerPrice;

    const newLineItem: IMarkupLineItem = {
        ...lineItem,
        markupPercent,
        markupAmount,
        markupPerUnit,
        ownerPrice,
        totalWithTax,
        margin: newMargin,
    };

    return newLineItem;
}

export function calculateMarkupFromOwnerPrice<T extends IMarkupLineItem>(
    lineItem: T,
    marginInput: number,
    totalEffectiveRate?: number,
    taxMethod?: number
): T {
    const margin = round(multiply(marginInput, 100), 2);

    const markupAmount = round(subtract(lineItem.ownerPrice, lineItem.builderCost), 2);
    const markupPercent = getMarkupPercentageFromMarginPercentage(margin);
    const markupPerUnit = lineItem.quantity ? round(divide(markupAmount, lineItem.quantity), 2) : 0;

    const totalWithTax =
        totalEffectiveRate && taxMethod
            ? calculateTotalWithTax(lineItem.ownerPrice, totalEffectiveRate, taxMethod)
            : lineItem.ownerPrice;

    return {
        ...lineItem,
        markupAmount,
        markupPerUnit,
        markupPercent,
        margin,
        totalWithTax,
    };
}

export function getMarkupPercentageFromMarginPercentage(percentMargin: number) {
    const percentMarginAsDecimal = percentMargin / 100;
    if (percentMarginAsDecimal === 1) {
        // Margin should never be 100% but this will prevent an exception
        return 0;
    }
    return round((percentMarginAsDecimal / (1 - percentMarginAsDecimal)) * 100, 2);
}

export function getMarginPercentageFromMarkupPercentage(percentMarkup: number) {
    if (percentMarkup <= 0) {
        // builder is losing money, but we'll just say 0% margin
        return 0;
    }
    const percentMarkupAsDecimal = divide(percentMarkup, 100);
    return round(multiply(divide(percentMarkupAsDecimal, add(1, percentMarkupAsDecimal)), 100), 2);
}

export function getMarginPercentageFromMarkupAmount(markupAmount: number, unitCost: number) {
    return markupAmount !== 0
        ? round(multiply(divide(subtract(markupAmount, unitCost), markupAmount), 100), 2)
        : 0;
}

export function calculateMarginPercentageFromMarkup(
    markupPercent: number,
    markupAmount: number,
    markupPerUnit: number,
    unitCost: number,
    quantity: number
) {
    if (markupPercent !== 0) {
        return getMarginPercentageFromMarkupPercentage(markupPercent);
    } else {
        if (markupAmount !== 0) {
            return getMarginPercentageFromMarkupAmount(markupAmount, unitCost);
        } else if (markupPerUnit !== 0) {
            const markupAmountFromMarkupPerUnit = markupPerUnit * quantity;
            if (markupAmountFromMarkupPerUnit === 0) {
                return 0;
            } else {
                return getMarginPercentageFromMarkupAmount(markupAmountFromMarkupPerUnit, unitCost);
            }
        }
        return 0;
    }
}

export function mapLineItemTypeToPurchaseOrderLineItemType(lineItemType: LineItemType) {
    switch (lineItemType) {
        case LineItemType.Bid:
            return POMappingEntityTypes.BidLineItems;
        case LineItemType.ChangeOrder:
            return POMappingEntityTypes.ChangeOrderLineItems;
        case LineItemType.SelectionChoice:
            return POMappingEntityTypes.SelectionChoiceLineItems;
        case LineItemType.EstimateLineItem:
            return POMappingEntityTypes.Estimates;
        default:
            throw new Error("Invalid Line Item Type");
    }
}

export function sortLineItemsByTitleAndCostCode(
    lineItems: IBaseLineItem[],
    sortDirection: "asc" | "desc",
    costCodes: BTSelectItem<any>[]
) {
    const formatTitleCostCode = (title: string | null, costCodeTitle: string | undefined) =>
        `${costCodeTitle}${title}`;
    return lineItems.sort((a, b) => {
        const aCostCodeTitle = costCodes.find((c) => +c.id === a.costCodeId)?.title;
        const bCostCodeTitle = costCodes.find((c) => +c.id === b.costCodeId)?.title;
        return lexicographicSorter(
            sortDirection === "desc"
                ? formatTitleCostCode(b.itemTitle, bCostCodeTitle)
                : formatTitleCostCode(a.itemTitle, aCostCodeTitle),
            sortDirection === "desc"
                ? formatTitleCostCode(a.itemTitle, aCostCodeTitle)
                : formatTitleCostCode(b.itemTitle, bCostCodeTitle)
        );
    });
}

export function sortLineItemsByVarianceCode(
    lineItems: ICostLineItem[],
    sortDirection: SortDirection,
    varianceCodes: BTSelectItem<IVarianceCodeExtraData>[]
) {
    return lineItems.sort((a, b) => {
        const aTitle =
            a.varianceCodeTitle ?? varianceCodes.find((c) => +c.id === a.varianceCodeId)?.title;
        const bTitle =
            b.varianceCodeTitle ?? varianceCodes.find((c) => +c.id === b.varianceCodeId)?.title;
        if (aTitle === "-- Not a Variance --") return 1;
        if (bTitle === "-- Not a Variance --") return -1;
        return lexicographicSorter(
            sortDirection === "desc" ? bTitle : aTitle,
            sortDirection === "desc" ? aTitle : bTitle
        );
    });
}

export function getUpdatedRelatedGeneralItems(
    lineItems?: LineItem[],
    generalItems?: WorksheetLineItem[]
) {
    return lineItems?.map((li) => {
        const updatedLineItem = { ...li };
        if (li.relatedGeneralItemId && generalItems) {
            const associatedGeneralItem = generalItems.find(
                (gi) => gi.id === updatedLineItem.relatedGeneralItemId
            );
            if (
                associatedGeneralItem &&
                associatedGeneralItem.costCodeId !== updatedLineItem.costCodeId
            ) {
                updatedLineItem.relatedGeneralItemId = null;
            }
        }
        return updatedLineItem as LineItem;
    });
}

export function sortLineItemsByCostTypes(
    lineItems: ICostLineItem[],
    columnDirection: SortDirection
) {
    return lineItems.sort((a, b) =>
        lexicographicSorter(
            columnDirection === "desc" ? CostTypes[b.costTypes![0]] : CostTypes[a.costTypes![0]],
            columnDirection === "desc" ? CostTypes[a.costTypes![0]] : CostTypes[b.costTypes![0]]
        )
    );
}

export function sortBaseLineItems(
    lineItems: IBaseLineItem[],
    sortColumn: LineItemColumns,
    columnDirection: SortDirection,
    costCodes: BTSelectItem<any>[]
): IBaseLineItem[] {
    let sortedLineItems: IBaseLineItem[];

    switch (sortColumn) {
        case LineItemColumns.TitleAndCostCode:
            sortedLineItems = sortLineItemsByTitleAndCostCode(
                lineItems,
                columnDirection!,
                costCodes
            ) as IMarkupLineItem[];
            break;
        case LineItemColumns.Unit:
            sortedLineItems = lineItems.sort((a, b) =>
                lexicographicSorter(
                    columnDirection === "desc" ? b.unit : a.unit,
                    columnDirection === "desc" ? a.unit : b.unit
                )
            );
            break;
        case LineItemColumns.Quantity:
            sortedLineItems = lineItems.sort((a, b) =>
                numberSorter(
                    columnDirection === "desc" ? b.quantity : a.quantity,
                    columnDirection === "desc" ? a.quantity : b.quantity
                )
            );
            break;
        default:
            sortedLineItems = lineItems;
    }
    return sortedLineItems;
}

export function sortCostItemLineItems(
    lineItems: ICostLineItem[],
    sortColumn: LineItemColumns,
    columnDirection: SortDirection,
    costCodes: BTSelectItem<any>[],
    varianceCodes?: BTSelectItem<IVarianceCodeExtraData>[]
): ICostLineItem[] {
    let sortedLineItems: ICostLineItem[];
    switch (sortColumn) {
        case LineItemColumns.TitleAndCostCode:
        case LineItemColumns.Unit:
        case LineItemColumns.Quantity:
            sortedLineItems = sortBaseLineItems(
                lineItems,
                sortColumn,
                columnDirection,
                costCodes
            ) as ICostLineItem[];
            break;
        case LineItemColumns.UnitCost:
            sortedLineItems = lineItems.sort((a, b) =>
                numberSorter(
                    columnDirection === "desc" ? b.unitCost : a.unitCost,
                    columnDirection === "desc" ? a.unitCost : b.unitCost
                )
            );
            break;
        case LineItemColumns.BuilderCost:
            sortedLineItems = lineItems.sort((a, b) =>
                numberSorter(
                    columnDirection === "desc" ? b.builderCost : a.builderCost,
                    columnDirection === "desc" ? a.builderCost : b.builderCost
                )
            );
            break;
        case LineItemColumns.CostTypes:
            sortedLineItems = sortLineItemsByCostTypes(lineItems, columnDirection);
            break;
        case LineItemColumns.Variance:
            sortedLineItems = sortLineItemsByVarianceCode(
                lineItems,
                columnDirection,
                varianceCodes!
            );
            break;
        default:
            sortedLineItems = lineItems;
    }
    return sortedLineItems;
}

export function sortMarkupLineItems(
    lineItems: IMarkupLineItem[],
    sortColumn: LineItemColumns,
    columnDirection: SortDirection,
    costCodes: BTSelectItem<any>[]
): IMarkupLineItem[] {
    let sortedLineItems: IMarkupLineItem[];
    switch (sortColumn) {
        case LineItemColumns.TitleAndCostCode:
        case LineItemColumns.Unit:
        case LineItemColumns.Quantity:
            sortedLineItems = sortBaseLineItems(
                lineItems as IBaseLineItem[],
                sortColumn,
                columnDirection,
                costCodes
            ) as IMarkupLineItem[];
            break;
        case LineItemColumns.CostTypes:
        case LineItemColumns.UnitCost:
        case LineItemColumns.BuilderCost:
            sortedLineItems = sortCostItemLineItems(
                lineItems as ICostLineItem[],
                sortColumn,
                columnDirection,
                costCodes
            ) as IMarkupLineItem[];
            break;
        case LineItemColumns.Markup:
            sortedLineItems = lineItems.sort((a, b) =>
                numberSorter(
                    columnDirection === "desc" ? b.markupAmount : a.markupAmount,
                    columnDirection === "desc" ? a.markupAmount : b.markupAmount
                )
            );
            break;
        case LineItemColumns.OwnerPrice:
            sortedLineItems = lineItems.sort((a, b) =>
                numberSorter(
                    columnDirection === "desc" ? b.ownerPrice : a.ownerPrice,
                    columnDirection === "desc" ? a.ownerPrice : b.ownerPrice
                )
            );
            break;
        default:
            sortedLineItems = lineItems;
    }

    return sortedLineItems;
}

/**
 * Gets a list of cost codes as BTSelectItems from line item defaults. Consider using memoization
 * instead of directly calling this helper function.
 */
export function getCostCodeSelectItemsFromLineItems(
    lineItemDefaults: ILineItemDefaults
): BTSelectItem<any>[] {
    return (
        lineItemDefaults.costCodeOptions?.value.map(
            (x: any) =>
                new BTSelectItem<any>({
                    id: x.id,
                    name: x.name,
                    value: x.id,
                    extraData: x.extraData,
                })
        ) ?? []
    );
}

export function getTaxGroupSelectItemsFromLineItemDefaults(
    lineItemDefaults: ILineItemDefaults
): BTSelectItem<TaxGroupServiceListItemExtraData>[] {
    return lineItemDefaults.taxGroups !== undefined
        ? mapBTServiceDropdownToSelectItem<TaxGroupServiceListItemExtraData>(
              lineItemDefaults.taxGroups
          )[0]?.children ?? []
        : [];
}

export function getCostTypesMarkupFromLineItemDefaults(
    lineItemDefaults?: ILineItemDefaults
): ICostTypesMarkup[] {
    const costTypesMarkup: ICostTypesMarkup[] = [];
    if (lineItemDefaults?.costTypesMarkup) {
        lineItemDefaults.costTypesMarkup.forEach((option) => {
            costTypesMarkup.push({
                costType: option.costType,
                markup: option.markupPercent.value ?? 0,
                margin: option.marginPercent.value ?? 0,
            });
        });
    }
    return costTypesMarkup;
}

export function getDefaultMarkupMarginForCostType(
    costTypesMarkup?: ICostTypesMarkup[],
    costTypes?: number | number[] | null
) {
    let costTypesArray: number[] = [];
    if (costTypes) {
        costTypesArray = Array.isArray(costTypes) ? costTypes : [costTypes];
    }
    if (costTypesMarkup && costTypesArray.length <= 1) {
        const markupMargin = costTypesMarkup.find((x) => x.costType === (costTypesArray[0] ?? -1));
        return {
            markup: markupMargin?.markup,
            margin: markupMargin?.margin,
        };
    }
    return {
        markup: 0,
        margin: 0,
    };
}

export async function shouldBreakLinkToCostItem(
    lineItem: IMarkupLineItem | ICostLineItem,
    lineItemType: LineItemType,
    costCatalogHandler?: CostCatalogHandler
) {
    if (isNullOrUndefined(lineItem?.costItemId) || lineItem.costItemId <= 0) {
        // there is no cost item linked
        return;
    }

    const handler = costCatalogHandler ?? new CostCatalogHandler();
    const request = mapLineItemToLineItemResponse(lineItem);

    const shouldBreakLinks = (
        await handler.shouldBreakLinkToCostItem(request, lineItemType)
    ).valueOf();

    if (shouldBreakLinks) {
        void message.info(`${lineItem.itemTitle} will be unlinked from the Catalog upon save.`, 5);
    }
}

export function mapLineItemToLineItemResponse(
    lineItem: IMarkupLineItem | ICostLineItem
): ILineItemResponse {
    const response = {
        id: lineItem.id,
        costCodeId: lineItem.costCodeId,
        costCode: lineItem.costCodeId!,
        costCodeItemId: lineItem.costItemId,
        catalogItemId: lineItem.costItemId,
        costTypeId: 0,
        calculatedAmount: lineItem.builderCost,
        builderCost: lineItem.builderCost,
        unitCost: lineItem.unitCost,
        quantity: lineItem.quantity,
        unitType: lineItem.unit,
        title: lineItem.itemTitle,
        description: lineItem.description,
        internalNotes: lineItem.internalNotes,
        costTypes: lineItem.costTypes,
        markedAs: lineItem.markedAs,
        relatedGeneralItemId: lineItem.relatedGeneralItemId,
        vendorType: lineItem.vendorType,
        vendorProductId: lineItem.vendorProductId,
        isDiscontinued: lineItem.isDiscontinued,
    } as ILineItemResponse;

    if (isMarkupLineItem(lineItem)) {
        response.markupColumn = lineItem.markupType as number;
        response.markupPercentage = lineItem.markupPercent;
        response.markupPerUnit = lineItem.markupPerUnit;
        response.markupPrice = lineItem.markupAmount;
        response.ownerPrice = lineItem.ownerPrice;
    }

    return response;
}

export function calculatePercentage(
    dividend: number | null,
    divisor: number | null,
    decimals?: number
) {
    if (!dividend && !divisor) {
        return null;
    }

    if (dividend === 0 || !dividend) {
        return 0;
    }
    if (divisor === 0 || !divisor) {
        return 100;
    }
    const percentage = divide(dividend, divisor);
    if (percentage.lessThan(1) && percentage.greaterThan(0.99)) {
        return 99;
    }
    return round(multiply(percentage, 100), decimals);
}

export class MarkupEntity {
    constructor(data: IMarkupLineItem) {
        this.id = data.id;
        this.markupType = data.markupType;
    }
    id: number;
    markupType: MarkupType;
}

export const handleCheckForPendingCostItemUpdates = async (
    costItemUpdateMessage: string | null,
    entityIds: number | number[],
    jobId: number | undefined,
    entityType: EntityWithLinkedCostCodeItemsType,
    costItemHandler: CostItemHandler,
    continueReset: (applyCostItemUpdates?: boolean) => Promise<void>
) => {
    const entityIdList = Array.isArray(entityIds) ? entityIds : [entityIds];
    // many entities load up with a cost item update message already so we won't need to make an additional call
    if (isNullOrUndefined(costItemUpdateMessage) && entityIdList.length > 0) {
        try {
            const pendingUpdateResponse = await costItemHandler.getPendingUpdateCount({
                entityIds: entityIdList,
                entityType: entityType,
                jobId: jobId,
            });
            costItemUpdateMessage = pendingUpdateResponse.costItemUpdateMessage;
        } catch (e) {
            showAPIErrorMessage(e);
            return;
        }
    }

    await checkForCostItemUpdatesAndContinue(
        costItemUpdateMessage,
        "Update Available",
        continueReset
    );
};

export const checkForCostItemUpdatesAndContinue = async (
    costItemUpdateMessage: string | null | undefined,
    confirmTitle: string,
    onContinue: (applyCostItemUpdates?: boolean) => Promise<void>
) => {
    if (costItemUpdateMessage) {
        btConfirm({
            title: confirmTitle,
            content: costItemUpdateMessage,
            okText: "Apply Update",
            cancelText: "Do Not Update",
            onOk: async () => {
                await onContinue(true);
            },
            onCancel: async () => {
                await onContinue(false);
            },
        });
    } else {
        await onContinue();
    }
};

/**
 * Method for displaying line item validation on actions external to the line item container component.
 * Also used to validate group rows within line item
 * @param setFieldTouched method to touch a field within a form, causes Formik validation errors to show.
 * @param validationData The Formik errors array
 * @param field the field to show validation errors on, typically "lineItems"
 * @returns True if errors are displayed, false otherwise.
 */
export function validateLineItemOnAdd<T>(
    setFieldTouched: (field: string, isTouched: boolean | undefined) => void,
    validationData: FormikErrors<T>,
    field: string
): boolean {
    if (!Array.isArray(validationData)) {
        return false;
    }

    for (let x = 0; x < validationData.length; x++) {
        if (validationData[x]) {
            Object.keys(validationData[x]).forEach((key) => {
                setFieldTouched(`${field}[${x}].${key}`, true);
            });
        }
    }

    return true;
}

/**
 *
 * @param lineItem The Line Item to delete
 * @param lineItems List of all Line Items, usually comes from values
 * @param defaultLineItem The default Line Item for the specific type (Allowance, etc.)
 * @param lineItemFriendlyName A readable name for the Line Item for BTDeleteConfirm content or GA events ("Allowance", etc.)
 */
export async function confirmAndDeleteLineItem<T extends ILineItemRow>(
    lineItem: T,
    lineItems: T[],
    defaultLineItem: T,
    lineItemFriendlyName: string
) {
    const lineItemData = getLineItemData(lineItem);
    const defaultLineItemData = getLineItemData(defaultLineItem);

    const matchesDefaultLineItem = isEqual(lineItemData, defaultLineItemData);

    const newLineItems = [...lineItems];
    if (!matchesDefaultLineItem) {
        const result = await btDeleteConfirmAsync({
            entityName: "Line Item",
            content: `Are you sure you want to delete the selected ${lineItemFriendlyName} Line Item?`,
            modalRender: (node) => <div data-delete-confirm>{node}</div>,
        });
        if (result === "ok") {
            newLineItems.splice(lineItems.indexOf(lineItem), 1);
        }
    } else {
        newLineItems.splice(lineItems.indexOf(lineItem), 1);
    }
    return newLineItems;
}

export const addExtraCostGroupInformation = async (
    addFromCatalogRequest: IAddFromCatalogRequest,
    description: string,
    attachedFilesRequest: AttachedFilesRequest,
    attachedFiles: AttachedFiles,
    type: DocumentInstanceType
) => {
    const filesList: BTFileSystem[] = [...attachedFiles.files];
    const attachDocs = [...attachedFilesRequest.attachDocs];
    addFromCatalogRequest.costGroups?.forEach((group) => {
        description += `<br><br>Cost Group Title<br>${group.title}`;
        if (addFromCatalogRequest.includeDescription && !isNullOrWhitespace(group.description)) {
            description += `<br><br>Cost Group Description<br>${group.description}`;
        }

        if (addFromCatalogRequest.includeFiles) {
            filesList.push(...group.files.files);
            attachDocs.push(...group.files.files.map((f) => MapBTFileToFileUpload(f, type)));
        }
    });

    return {
        description,
        attachedFilesRequest: {
            ...attachedFilesRequest,
            attachDocs,
        },
        attachedFiles: {
            ...attachedFiles,
            files: orderBy(
                filesList,
                ["mainFileId", "dateAttached", "id"],
                ["asc", "desc", "desc"]
            ),
        },
    };
};

export const setLineItemPaymentSummary = (
    lineItemPaymentSummary: PercentageSection[] | undefined,
    status: POPaymentStatus,
    paymentPercent: number | undefined,
    paymentAmount: number | undefined
): PercentageSection[] => {
    if (lineItemPaymentSummary !== null) {
        if (isNullOrUndefined(paymentAmount) || isNullOrUndefined(paymentPercent)) {
            return lineItemPaymentSummary!;
        }

        let summary = [...lineItemPaymentSummary!].filter((x) => !x.striped);
        const color: "green" | "yellow" | "blue" | "" =
            status === POPaymentStatus.Open ? "yellow" : "blue";
        summary.push(
            new PercentageSection({
                color,
                isOutstanding: false,
                label: "Payment Amount",
                percentage: paymentPercent!,
                striped: true,
                value: paymentAmount,
            })
        );
        return summary.sort(sortPercentageSections);
    }
    return lineItemPaymentSummary!;
};

// Need to sort percentage sections so no matter which order they come in, we show them as green, blue, yellow.
const sortPercentageSections = (a: any, b: any) => {
    const order = ["green", "blue", "yellow"];
    return order.indexOf(a.color) - order.indexOf(b.color);
};

export const handleUpdateCostLineItems = <T extends ILineItemRow & ICostLineItem>(
    lineItems: T[],
    editedCostItem: ICostItemEntity,
    isBreakingCostItemLinks?: boolean | null
) => {
    if (!isBreakingCostItemLinks) {
        const updatedLineItems = lineItems.map((li) => {
            if (!isLineItemRow(li)) {
                return li;
            }

            if (li.costItemId === editedCostItem.costItemId) {
                const quantity = editedCostItem.includeQuantity
                    ? editedCostItem.quantity
                    : li.quantity;

                const builderCost = calculateBuilderCost(editedCostItem.unitCost, quantity);

                const updatedItem = {
                    ...li,
                    quantity,
                    builderCost,
                    isQuantityLinked: editedCostItem.includeQuantity,
                    quantityIsLinked: editedCostItem.includeQuantity,
                    isUnitTypeLinked: !isNullOrWhitespace(editedCostItem.unit),
                    isMarkupLinked: editedCostItem.includeOwnerPrice,
                    vendorType: editedCostItem.vendorType,
                    vendorProductId: editedCostItem.vendorProductId,
                    costCodeId: editedCostItem.costCodeId,
                    costTypes: editedCostItem.costTypes,
                    itemTitle: editedCostItem.itemTitle,
                    description: editedCostItem.description,
                    internalNotes: editedCostItem.internalNotes,
                    unitCost: editedCostItem.unitCost,
                    unit: editedCostItem.unit,
                };

                if (isMarkupLineItem(li) && editedCostItem.includeOwnerPrice) {
                    const newUpdatedItem: T & IMarkupLineItem = {
                        ...updatedItem,
                        isMarkupLinked: true,
                        markupAmount: editedCostItem.markupAmount,
                        markupPerUnit: editedCostItem.markupPerUnit,
                        markupPercent: editedCostItem.markupPercent,
                        markupType: editedCostItem.markupType,
                        ownerPrice: editedCostItem.ownerPrice,
                        margin: editedCostItem.margin,
                    };

                    return recalculateMarkupFields(newUpdatedItem);
                } else {
                    return updatedItem;
                }
            }
            return li;
        });

        return updatedLineItems;
    } else {
        const updatedLineItems = lineItems.map((li) => {
            if (!isLineItemRow(li)) {
                return li;
            }

            if (li.costItemId === editedCostItem.costItemId) {
                li.costItemId = null;
                return li;
            }

            return li;
        });

        return updatedLineItems;
    }
};

export const isLineItemValid = async <
    TLineItem extends object, // Type of the line item we are validating
    TLineItemKey extends keyof TParent, // Key on the parent that the line item array lives on
    TParent extends { [lineItemKey in TLineItemKey]: TLineItem[] } // Validate that the key provided (below) is an array of TLineItems
>(
    schema: yup.ObjectSchema<TParent>,
    value: TLineItem,
    lineItemKey: TLineItemKey
): Promise<boolean> => {
    try {
        // We are going to be validating the line item "array" for this.
        // However, for performance we don't need to pass in the full array, we will just pass in the single line item to validate.
        // On large estiamtes, re-validating the entire array is quite slow, so we will only validate specific line items
        // When we take it out of edit mode.
        // Additionally, "fields" does not show as a key on ObjectSchema for our typescript, so I'm accessing it via the array accessor.
        await schema["fields"][lineItemKey].validate([value], {
            abortEarly: false,
        });
        // All is good, clear out any errors that existed
        return true;
    } catch (ex) {
        return false;
    }
};

/**
 * Update the markup type to percent unless unitCost is not set and ownerPrice is, then set to None
 * Update the unitCost and ownerPrice when changing from flat fee to line item
 */
export function updateLineItemOnLineItemSwitch<T extends IMarkupLineItem>(
    lineItem: T,
    unitCost: number | undefined,
    ownerPrice: number | undefined
) {
    if (unitCost) {
        lineItem.unitCost = unitCost;
    }

    if (ownerPrice) {
        lineItem.ownerPrice = ownerPrice;
    }

    lineItem.markupType = MarkupType.none;

    return recalculateMarkupLineItem(lineItem);
}

export function recalculateMarkupLineItem<T extends IMarkupLineItem>(lineItem: T) {
    lineItem.builderCost = calculateBuilderCost(lineItem.unitCost, lineItem.quantity);
    lineItem = recalculateMarkupFields(lineItem);

    if (lineItem.unitCost === 0 && lineItem.ownerPrice === 0) {
        lineItem.markupType = MarkupType.percent;
    }

    return lineItem;
}

export const areLineItemsBeingEdited = memoizeOne(
    (lineItems?: (ILineItemRow | ILineItemGroup<any>)[]) => {
        return lineItems?.some((li) => isLineItemRow(li) && li._isInEdit) ?? false;
    }
);

export const areItemsBeingEdited = memoizeOne(
    (lineItems?: (ILineItemRow | ILineItemGroup<any>)[]) => {
        return lineItems?.some((li) => li._isInEdit) ?? false;
    }
);

export function getEntityNameForLineItemType(type: LineItemType) {
    switch (type) {
        case LineItemType.GeneralItem:
            return "Estimates";
        case LineItemType.Bid:
            return "Bids";
        case LineItemType.BidPackage:
            return "Bid Packages";
        case LineItemType.ToDo:
            return "ToDos";
        case LineItemType.PurchaseOrderPayment:
            return "Purchase Order Payments";
        case LineItemType.SelectionChoice:
            return "Selection Choices";
        case LineItemType.ChangeOrder:
            return "Change Orders";
        case LineItemType.Allowance:
            return "Allowances";
        case LineItemType.LegacyBill:
            return "Bills";
        case LineItemType.TimeCardItem:
            return "Time Card Items";
        case LineItemType.EstimateLineItem:
            return "Estimates";
        case LineItemType.ProposalLineItem:
            return "Proposals";
        case LineItemType.ProposalTemplateLineItem:
            return "Proposal Templates";
        case LineItemType.OwnerInvoice:
            return "Owner Invoices";
        case LineItemType.CreditMemo:
            return "Credit Memos";
        case LineItemType.Bill:
            return "Bills";
        case LineItemType.PurchaseOrder:
            return "Purchase Orders";
        default:
            return;
    }
}

export function getFlatRateLineItemForToggle(
    lineItemType: LineItemType,
    dependentValueFields: (Number | undefined)[],
    costCodesList: BTSelectItem[]
) {
    if (dependentValueFields.some((value) => (value ?? 0) !== 0)) {
        const flatRateId = parseInt(
            costCodesList.filter((cc) => cc.title === "Buildertrend Flat Rate")[0].id
        );
        return newLineItemDefaults(0, lineItemType, 0, flatRateId);
    }
    return null;
}
