import moment, { Moment } from "moment";
import { RouteComponentProps } from "react-router";

import { IBaseEntity, IIsSelectable } from "types/apiResponse/apiResponse";
import { ILanguageConstants } from "types/constants";
import {
    BTLoginTypes,
    DirectoryType,
    DocumentInstanceType,
    FileStatus,
    ImageResizeMode,
    MediaType,
    ObjectFit,
} from "types/enum";
import { BTFilePermissions, BTViewingPermissions } from "types/filePermissions";
import { rootLevelFolderId } from "types/MediaConstants";

import { EmptyStateMetadata, GridSort, IListRowData, ListEntity } from "utilities/list/list.types";

import { BTServiceFileUpload } from "commonComponents/entity/media/AttachmentEntityPreview/AttachmentEntity.api.types";
import { RelatedItemType } from "commonComponents/entity/relatedItem/RelatedItem.types";

import { EntityType } from "handlers";

import { getFileUrlFormatted } from "entity/media/common/media.utils";
import type { default as MediaListJson } from "entity/media/common/MediaList.api.json";

type MediaListEntityApiData = typeof MediaListJson;
type BreadCrumbInformationApiData = MediaListEntityApiData["breadCrumbInformation"][number];
export type FileApiData = MediaListEntityApiData["files"][number];
type ExtraDataApiData = MediaListEntityApiData["extraData"];
type UserInfoFromExtraDataApiData = ExtraDataApiData["userInfo"];
type SignatureRequestsInfoApiData = FileApiData["signatureRequests"];
type AnnotationsInfoApiData = FileApiData["annotations"];
type RFIInfoApiData = NonNullable<FileApiData["rfis"]>[number];
type VideoPropertiesApiData = FileApiData["videoProperties"];
type PhotoPropertiesApiData = FileApiData["photoProperties"];
type DocumentPropertiesApiData = FileApiData["documentProperties"];
type AssociatedEntityApiData = FileApiData["associatedEntities"][number];
type FolderApiData = MediaListEntityApiData["folders"][number];
type FileInfoAggregateApiData = FolderApiData["fileInfo"];
type SpecialFolderExtraDataApiData = FolderApiData["specialFolderExtraData"];

export interface ISharedMediaProps {
    directoryType: DirectoryType;
    folderId: number;
}

export interface ISharedMediaRouteProps {
    directoryType: string;
    folderId: string;
}

export class AnnotationInformation {
    constructor(data: any) {
        this.layerCount = data.layerCount;
        this.signatureId = data.signatureId;
    }

    layerCount: number;
    signatureId?: number;
}

export class AnnotationLayer {
    constructor(data: any) {
        this.addedByDate = moment(data.addedByDate);
        this.annotationGroupId = data.annotationGroupId;
        this.annotationLayerId = data.annotationLayerId;
        this.annotationName = data.annotationName;
        this.annotationType = data.annotationType;
    }

    addedByDate: moment.Moment;
    annotationGroupId: number;
    annotationLayerId: number;
    annotationName: string;
    annotationType: AnnotationType;
}

export enum AnnotationType {
    PdfAnnotation = 1,
    PhotoDraw = 2,
    TempFile = 3,
    PdfSignature = 4,
}

export class AttachedMedia {
    constructor(data: any) {
        this.associatedTypeName = data.associatedTypeName;
        this.docInstanceId = data.docInstanceId;
        this.jobName = data.jobName;
        this.title = data.title;
    }

    associatedTypeName: string;
    docInstanceId: number;
    jobName: string;
    title: string;
}

export enum DocumentInstanceUpdateType {
    Overwrite = 0,
    CreateNewVersion = 1,
    NoDocumentChange = 2,
}

export enum GlobalMediaFolderIds {
    AttachedDocuments = -1,
    SubDocuments = -2,
    OwnerDocuments = -3,
    AttachedPhotos = -4,
    AttachedVideos = -5,
}

export class BTServiceDocumentItem<ExtraDataType = undefined> {
    constructor(data: any) {
        this.id = data.id;
        this.tempId = data.tempId;
        this.fileName = data.title || data.fileName;
        this.fileSize = data.fileSize || 0;
        this.url = data.url;
        this.ext = data.ext;
        this.mediaType = data.mediaType;
        this.brandedUrl = data.brandedUrl;
        this.showSubs = data.showSubs;
        this.showOwner = data.showOwner;
        this.canDelete = data.canDelete;
        if (data.extraData) {
            this.extraData = data.extraData;
        }
    }

    id: number;
    tempId?: number;
    fileName: string;
    fileSize: number;
    url: string;
    ext: string;
    mediaType: MediaType;
    brandedUrl?: any;
    showSubs: boolean;
    showOwner: boolean;
    canDelete: boolean;
    extraData?: ExtraDataType;
}

export class MediaListEntity extends ListEntity {
    constructor(data: MediaListEntityApiData) {
        super(data);
        this.mediaEntry = new MediaEntry(data);
        this.listMetadata.emptyStatesMetadata = new EmptyStateMetadata({
            hasFilteredData: false,
            isNewToEntity: true,
            isReadOnly:
                this.mediaEntry.id === rootLevelFolderId
                    ? !this.mediaEntry.canAddFolders
                    : !this.mediaEntry.canAddFiles,
            helpLink: data.helpLink,
        });

        // Entity has records if any files or folders
        this.records =
            (this.mediaEntry.folders ? this.mediaEntry.folders.length : 0) +
            (this.mediaEntry.files ? this.mediaEntry.files.length : 0);
        this.listMetadata.hasData = this.records > 0;
    }
    mediaEntry: MediaEntry;
}

export class MediaEntry {
    constructor(data: MediaListEntityApiData) {
        this.jobId = data.jobId;
        this.builderId = data.builderId;
        this.id = data.currentFolderId;
        this.parentFolderId = data.parentFolderId;
        this.canDelete = data.canDelete;
        this.canAddFiles = data.canAddFiles;
        this.canAddFolders = data.canAddFolders;
        this.canEdit = data.canUpdate;
        this.canEmailFiles = data.canEmailFiles;
        this.canEmailFilesIn = data.canEmailFilesIn;
        this.canEditDailyLogs = data.canEditDailyLogs;
        this.canEditToDos = data.canEditToDos;
        this.canEditScheduleItems = data.canEditScheduleItems;
        this.canEditChangeOrders = data.canEditChangeOrders;
        this.canEditBids = data.canEditBids;
        this.canEditSelections = data.canEditSelections;
        this.jobHasOwners = data.jobHasOwners;
        this.jobHasSubs = data.jobHasSubs;
        this.canEditWarranties = data.canEditWarranties;
        this.allowSubOwnerUploads = data.allowSubOwnerUploads;
        this.hasOwnerUnreadDocs = data.hasOwnerUnreadDocs;
        this.isOwnerFeatureMediaSet = data.isOwnerFeatureMediaSet;
        this.isSpecialFolder = data.isSpecialFolder;
        this.allowedFileExtensions = data.allowedFileExtensions;
        this.folders = data.folders
            ? data.folders.map((folder: any) => new BTFolder(folder))
            : null;
        this.files = data.files ? data.files.map((file: any) => new BTFile(file)) : null;
        this.breadCrumbInformation = data.breadCrumbInformation
            ? data.breadCrumbInformation.map(
                  (breadCrumb: any) => new BreadCrumbInformation(breadCrumb)
              )
            : null;
        this.title = data.currentFolderTitle;
        this.extraData = data.extraData ? new DirectoryExtraData(data.extraData) : undefined;
        this.lastRefreshed = moment();
        const fileCount = this.files ? this.files.length : 0;
        const folderCount = this.folders ? this.folders.length : 0;
        this.totalCount = fileCount + folderCount;
    }
    // IBaseEntity
    id: number;
    jobId: number;
    builderId: number;
    parentFolderId: number;

    breadCrumbInformation: BreadCrumbInformation[] | null;
    canAddFiles: boolean;
    canAddFolders: boolean;
    canDelete: boolean;
    canEdit: boolean;
    canEmailFiles: boolean;
    canEmailFilesIn: boolean;
    canEditDailyLogs: boolean;
    canEditToDos: boolean;
    canEditScheduleItems: boolean;
    canEditChangeOrders: boolean;
    canEditBids: boolean;
    canEditSelections: boolean;
    canEditWarranties?: boolean; // Remove the question mark when ado-214426 has been deployed, it will always be defined
    allowSubOwnerUploads: boolean;
    hasOwnerUnreadDocs: boolean;
    jobHasOwners: boolean;
    jobHasSubs: boolean;
    isOwnerFeatureMediaSet: boolean;
    isSpecialFolder: boolean;
    allowedFileExtensions: string[];
    folders: BTFolder[] | null;
    files: BTFile[] | null;
    totalCount: number;
    title: string;

    lastRefreshed: Moment;

    // Extra data
    extraData?: DirectoryExtraData;
}

export class BreadCrumbInformation {
    constructor(data: BreadCrumbInformationApiData) {
        this.folderId = data.folderId;
        this.title = data.title;
        this.directoryType = data.directoryType;
        this.associatedType = data.associatedType;
    }

    folderId: string;
    title: string;
    directoryType: DirectoryType;
    associatedType: DocumentInstanceType | null;
}

export interface IBaseFile {
    thumbnail?: string;
    title: string;
    extension: string;
    id: number;
    mediaType: MediaType;
}

export class BTFile implements IBaseFile, IBaseEntity, IListRowData, IIsSelectable {
    constructor(data: FileApiData) {
        const docInstanceId = data.documentInstanceId;
        this.builderId = data.builderId;
        this.jobId = data.jobId;
        this.id = docInstanceId;
        this.documentId = data.documentId;
        this.title = data.title;
        this.friendlyFileName = data.friendlyFileName;
        // Note: The thumbnail may not have height and width set.
        // You will need to make to set a height and width if not use the Thumbnail component.
        this.thumbnail = data.thumbnail;
        this.extension = data.extension;
        this.docPath = getFileUrlFormatted(docInstanceId, data.extension, data.docPath);
        this.downloadUrl = getFileUrlFormatted(docInstanceId, data.extension, data.downloadUrl);
        this.sizeInBytes = data.sizeInBytes;
        this.mediaType = data.mediaType;
        this.isTemplate = data.isTemplate;
        this.isSetAsOwnerFeature = data.isSetAsOwnerFeature;
        this.addedBy = data.addedBy;
        this.dateAttached = data.dateAttached ? moment(data.dateAttached) : null;
        this.dateTaken = data.dateTaken ? moment(data.dateTaken) : null;
        this.dateModified = data.dateModified ? moment(data.dateModified) : null;
        this.dateDeleted = data.dateDeleted ? moment(data.dateDeleted) : null;
        this.ownerViewedDate = data.ownerViewedDate ? moment(data.ownerViewedDate) : null;
        this.associatedEntities = data.associatedEntities
            ? (data.associatedEntities as AssociatedEntityApiData[]).map(
                  (e) => new AssociatedEntityInfo(e)
              )
            : [];
        this.viewPermissions = new BTViewingPermissions(data.viewPermissions);
        this.permissions = new BTFilePermissions(data.permissions);
        this.rfiCount = data.rfiCount;
        this.discussionCount = data.discussionCount;
        this.unreadDiscussionCount = data.unreadDiscussionCount;
        this.signatureRequests = data.signatureRequests
            ? new SignatureRequestsInfo(data.signatureRequests)
            : undefined;
        this.annotations = data.annotations ? new AnnotationsInfo(data.annotations) : undefined;
        this.rfis = data.rfis ? data.rfis.map((rfi) => new RFIInfo(rfi)) : undefined;
        this.videoProperties = data.videoProperties
            ? new VideoProperties(data.videoProperties)
            : undefined;
        this.photoProperties = data.photoProperties
            ? new PhotoProperties(data.photoProperties)
            : undefined;
        this.documentProperties = data.documentProperties
            ? new DocumentProperties(data.documentProperties)
            : undefined;

        this.isSelected = false;
    }
    // IListRowData
    id: number;
    documentId?: number;

    addedBy: string;
    annotations?: AnnotationsInfo;
    associatedEntities: AssociatedEntityInfo[];
    builderId: number;
    dateAttached: Moment | null;
    dateDeleted: Moment | null;
    dateTaken: Moment | null;
    dateModified: Moment | null;
    discussionCount: number;
    unreadDiscussionCount: number;
    readonly docPath: string;
    documentProperties?: DocumentProperties;
    readonly downloadUrl: string;
    extension: string;
    friendlyFileName: string;
    isTemplate: boolean;
    isSetAsOwnerFeature: boolean;
    jobId: number;
    mediaType: MediaType;
    ownerViewedDate: Moment | null;
    permissions: BTFilePermissions;
    photoProperties?: PhotoProperties;
    rfiCount: number;
    rfis?: RFIInfo[];
    signatureRequests?: SignatureRequestsInfo;
    sizeInBytes: number;
    thumbnail: string;
    title: string;
    videoProperties?: VideoProperties;
    viewPermissions: BTViewingPermissions;
    // IIsSelectable
    isSelected: boolean;
}

export class BTFolder implements IBaseEntity, IListRowData, IIsSelectable {
    constructor(data: FolderApiData) {
        this.builderId = data.builderId;
        this.jobId = data.jobId;
        this.folderId = data.folderId;
        this.id = data.folderId;
        this.parentFolderId = data.parentFolderId;

        this.title = data.title;
        this.dateAdded = data.dateAdded ? moment(data.dateAdded) : null;
        this.dateModified = data.dateModified ? moment(data.dateModified) : null;
        this.totalSize = data.totalSize;

        this.mediaType = data.mediaType;
        this.directoryType = data.directoryType;

        this.showSubs = data.showSubs;
        this.showOwner = data.showOwner;
        this.canUpload = data.canUpload;
        this.canUpdate = data.canUpdate;
        this.canDelete = data.canDelete;
        this.hasChildFolders = data.hasChildFolders;

        this.fileInfo = data.fileInfo ? new FileInfoAggregate(data.fileInfo) : null;
        this.specialFolderExtraData = new SpecialFolderExtraData(data.specialFolderExtraData);

        this.isSelected = false;
    }
    // IListRowData
    id: number;

    builderId: number;
    jobId: number;
    folderId: number;
    parentFolderId: number;

    title: string;
    dateAdded: Moment | null;
    dateModified: Moment | null;
    totalSize: number;

    mediaType: MediaType;
    directoryType: DirectoryType;

    showSubs: boolean;
    showOwner: boolean;
    canUpload: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    hasChildFolders: boolean;

    fileInfo: FileInfoAggregate | null;
    specialFolderExtraData: SpecialFolderExtraData;

    // IIsSelectable
    isSelected: boolean;
}

export interface IBTFileFolder {
    file: BTFile | null;
    folder: BTFolder | null;
    id: number;
    isSelected: boolean;
}

export class AssociatedEntityInfo {
    constructor(data: AssociatedEntityApiData) {
        this.associatedEntityId = data.associatedEntityId;
        this.associatedParentId = data.associatedParentId;
        this.associatedGrandParentId = data.associatedGrandParentId;
        this.associatedType = data.associatedType;
        this.associatedEntityTitle = data.associatedEntityTitle;
        this.externalUrl = data.externalUrl;
        this.relatedItemType = data.relatedItemType;
    }

    associatedEntityId: number;
    associatedParentId: number;
    associatedGrandParentId: number;
    associatedType: DocumentInstanceType;
    associatedEntityTitle: string;
    externalUrl: string | null;
    relatedItemType: RelatedItemType;
}

class FileInfoAggregate {
    constructor(data: FileInfoAggregateApiData) {
        this.annotationCount = data.annotationCount;
        this.discussionCount = data.discussionCount;
        this.rfiCount = data.rfiCount;
        this.signatureRequestTotal = data.signatureRequestTotal;
        this.signatureRequestsSigned = data.signatureRequestsSigned;
    }

    annotationCount: number;
    discussionCount: number;
    rfiCount: number;
    signatureRequestTotal: number;
    signatureRequestsSigned: number;
}

class SpecialFolderExtraData {
    constructor(data: SpecialFolderExtraDataApiData) {
        this.isSpecialFolder = data.isSpecialFolder;
        this.folderAssociatedType = data.folderAssociatedType;
    }
    isSpecialFolder: boolean;
    folderAssociatedType: DocumentInstanceType;
}

class SignatureRequestsInfo {
    constructor(data: SignatureRequestsInfoApiData) {
        if (data) {
            this.signatureRequestId = data.signatureRequestId;
            this.pending = data.pending;
            this.signed = data.signed;
            this.isAssignedToUser = data.isAssignedToUser;
            this.isAssignedToUserAndPending = data.isAssignedToUserAndPending;
            this.dueDate = data.dueDate ? moment(data.dueDate) : undefined;
        }
    }

    signatureRequestId: number;
    pending: number;
    signed: number;
    isAssignedToUser: boolean;
    isAssignedToUserAndPending: boolean;
    dueDate?: Moment;
}

class AnnotationsInfo {
    constructor(data: AnnotationsInfoApiData) {
        if (data) {
            this.annotationGroupId = data.annotationGroupId;
            this.layerCount = data.layerCount;
            this.layers = data.layers
                ? data.layers.map((layer: any) => new AnnotationLayerInfo(layer))
                : null;
            this.allLayerDocPath = data.allLayerDocPath;
        }
    }

    annotationGroupId?: number;
    layerCount: number;
    layers: AnnotationLayerInfo[] | null;
    allLayerDocPath: string;
}

export class AnnotationLayerInfo {
    constructor(data: any) {
        if (data) {
            this.annotationId = data.annotationId;
            this.title = data.title;
            this.docPath = data.docPath;
            this.downloadUrl = data.downloadUrl;
        }
    }

    annotationId: number;
    title: string;
    docPath: string;
    downloadUrl: string;
}

class RFIInfo {
    constructor(data: RFIInfoApiData) {
        this.rfiId = data.rfiId;
        this.titlePrefix = data.titlePrefix;
        this.title = data.title;
    }

    rfiId: number;
    titlePrefix: string;
    title: string;
}

class VideoProperties {
    constructor(data: VideoPropertiesApiData) {
        if (data) {
            this.videoId = data.videoId;
            this.videoStatus = data.videoStatus;
            if (data.length) {
                this.length = data.length;
            }
        }
    }

    videoId: number;
    length?: number;
    videoStatus: FileStatus;
}

class PhotoProperties {
    constructor(data: PhotoPropertiesApiData) {
        if (data) {
            this.isOriginalResolution = data.isOriginalResolution;
            this.is360Media = data.is360Media;
            this.viewingOrder = data.viewingOrder ? data.viewingOrder : null;
            this.drawingUrl = data.drawingUrl;
        }
    }

    isOriginalResolution: boolean;
    is360Media: boolean;
    viewingOrder: number | null;
    drawingUrl?: string;
}

export class DocumentProperties {
    constructor(data: DocumentPropertiesApiData) {
        if (data) {
            this.isMacroEnabledExcelFile = data.isMacroEnabledExcelFile;
        }
    }

    isMacroEnabledExcelFile: boolean;
}

export class DirectoryExtraData {
    constructor(data: ExtraDataApiData) {
        if (data) {
            this.userInfo = new UserInformation(data.userInfo);
        }
    }
    userInfo: UserInformation;
}

export class UserInformation {
    constructor(data: UserInfoFromExtraDataApiData) {
        if (data) {
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.builderName = data.builderName;
        }
    }
    firstName: string;
    lastName: string;
    builderName: string;
}

export interface IFileFolderListRequest {
    folderId?: number;
    mediaType?: MediaType;
    associatedTypeId?: DocumentInstanceType;
    directoryType?: DirectoryType;
    loginType?: BTLoginTypes;
    jobId: number;
}

export interface IMediaDeleteRequest {
    folderIds: number[];
    fileIds: number[];
    jobId: number;
    mediaType: MediaType;
}

export class DeletedFoldersResponse {
    constructor(data: any) {
        this.totalFoldersCount = data.totalFoldersCount;
        this.failedToDeleteCount = data.failedToDeleteCount;
        this.couldNotDeleteMessage = data.couldNotDeleteMessage;
    }
    totalFoldersCount: number;
    failedToDeleteCount: number;
    couldNotDeleteMessage: string;
}

export interface IDownloadZipOfFolderRequest {
    jobId: number;
    mediaType: MediaType;
    parentFolderId: number;
    folderIds?: number[];
    documentIds?: number[];
    titleOverride?: string;
    isDeleted?: boolean;
}

export interface IDownloadZipOfAttachedFilesRequest {
    mediaType: MediaType;
    documentInstanceTypes: DocumentInstanceType[];
    jobId: number;
    documentIds?: number[];
    titleOverride?: string;
}

export interface IDownloadZipOfAttachedFilesForEntityRequest {
    documentInstanceType: DocumentInstanceType;
    entityId: number;
    jobId: number;
    titleOverride?: string;
}

export interface IFolderPermissionExtraData {
    canShowSubs: boolean;
    canShowOwner: boolean;
}

export type SelectedMediaListViews = "Table" | "Tile";

export type MediaListTabs = "folders" | "files";

export interface IFocusState {
    id?: number;
    type: "folder" | "file";
    shouldReveal?: boolean;
}

export const OSFileDragType = "Files";
export const BTFileDragType = "btfile";
export const BTFolderDragType = "btfolder";

export interface IBTFolderDragPayload {
    folderId: number;
}

export interface IBTFileDragPayload {
    fileId: number;
}

export interface IMediaListViewProps extends RouteComponentProps {
    allowBTMediaDrop: boolean;
    areAllVisibleFilesSelected: boolean;
    areAllVisibleFoldersSelected: boolean;
    areAllVisibleFilesFoldersSelected?: boolean;
    areCheckedActionsForFolders: boolean;
    areFilesExpanded: boolean;
    areFoldersExpanded: boolean;
    entity: MediaEntry;
    focusState: IFocusState;
    isInEditMode?: boolean;
    mediaType: MediaType;
    currentSort?: IMediaListSort;
    filesLoaded?: number;
    directoryName: string;
    resizeMode?: ImageResizeMode;
    fit?: ObjectFit;
    showFolderDetails?: boolean;
    isTrash: boolean;
    isTemplateMode: boolean;
    onFileSortChanged: (sort: GridSort) => void;
    onFolderSortChanged: (sort: GridSort) => void;
    onFileFolderSortChanged?: (sort: GridSort) => void;
    onChangeSortOrder?: (sortedPhotos: BTFile[]) => void;
    onChangeFileFolderSortOrder?: (sortedPhotos: IBTFileFolder[]) => void;
    onDeleteFileClick: (file: BTFile) => Promise<void>;
    onDeleteFolderClick: (folderId: number, jobId: number) => Promise<void>;
    onDownloadFileClick?: (file: BTFile) => Promise<void>;
    onDownloadFolderClick?: (
        folderId: number,
        directoryType: DirectoryType,
        mediaType: MediaType,
        jobId: number,
        parentFolderId: number,
        folderAssociatedType?: DocumentInstanceType
    ) => Promise<void>;
    onDragDataReceived: (targetFolderId: number, dataTransfer: DataTransfer) => void;
    onEditOnline?: (file: BTFile) => Promise<void>;
    onAnnotation?: (file: BTFile) => Promise<void>;
    onRenameFile: (documentInstanceId: number, title: string) => Promise<void>;
    onRenameFolder: (folderId: number, title: string) => Promise<void>;
    onClose?: () => void;
    onExpandChange: (tab: MediaListTabs, isExpanded: boolean) => void;
    onMediaModalOpen: (startingId: number) => void;
    onSelectedChanged: (
        mediaEntry: MediaEntry,
        areAllVisibleFoldersSelected?: boolean,
        areAllVisibleFilesSelected?: boolean,
        areAllVisibleFilesFoldersSelected?: boolean
    ) => Promise<void>;
    setBTMediaDropAbility: (allowBTMediaDrop: boolean) => void;
    setFocus: (focusState: IFocusState) => void;
    onGenerateFileQRCode: (file: BTFile) => void;
    onGenerateFolderQRCode: (folder: BTFolder) => void;
    onAttachToEntity: (entityType: EntityType, file: BTServiceFileUpload) => void;
    onViewAssociatedItems: (file: BTFile) => void;
}

export interface IMediaListUrlProps {
    jobId: string;
    directoryType: string;
    folderId: string;
}

export const MediaTypeNames = new Map<MediaType, ILanguageConstants>([
    [
        MediaType.Document,
        {
            singular: "Document",
            plural: "Documents",
        },
    ],
    [
        MediaType.Photo,
        {
            singular: "Photo",
            plural: "Photos",
        },
    ],
    [
        MediaType.Video,
        {
            singular: "Video",
            plural: "Videos",
        },
    ],
]);

export const defaultMediaTypeNames: ILanguageConstants = {
    singular: "File",
    plural: "Files",
};

export const getMediaTypeNames = (mediaType: MediaType) => {
    return mediaType === MediaType.Document
        ? defaultMediaTypeNames
        : MediaTypeNames.get(mediaType) ?? defaultMediaTypeNames;
};

export interface ISetMainOwnerMediaRequest {
    id: number;
    jobId: number;
    mediaType: MediaType;
}

export interface IZohoSessionInfo {
    sessionDeleteUrl?: string;
    saveUrl?: string;
    sessionId?: string;
    documentDeleteUrl?: string;
    documentId?: string;
    documentUrl?: string;
}

export interface IFolderMenuItemsVisibility {
    showFolderDetails: boolean;
    showEditFolderProperties: boolean;
    showDownload: boolean;
    showCopy: boolean;
    showMove: boolean;
    showDelete: boolean;
    areSomeMenuItemsShown: boolean;
    showGenerateQRCode: boolean;
}

export interface IMediaListSort {
    fileSort?: GridSort;
    folderSort?: GridSort;
}
