import { Divider } from "antd";
import classNames from "classnames";
import { useContext } from "react";

import { BuilderInfo } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { MediaType, NotificationActionType, OpenActivityType } from "types/enum";

import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import {
    BTIconBids,
    BTIconBillsPurchaseOrders,
    BTIconChangeOrders,
    BTIconContacts,
    BTIconDailyLogs,
    BTIconDocuments,
    BTIconEllipsisOutlined,
    BTIconEstimate,
    BTIconEyeglasses,
    BTIconEyeglassesSlashed,
    BTIconGearSix,
    BTIconJobs,
    BTIconLeadOpportunities,
    BTIconLeadProposals,
    BTIconMessages,
    BTIconNotifications,
    BTIconOwnerInvoices,
    BTIconPhoneOutline,
    BTIconPhotos,
    BTIconPurchaseOrderPayments,
    BTIconPurchaseOrders,
    BTIconRebates,
    BTIconReceipts,
    BTIconRFIs,
    BTIconSchedule,
    BTIconSelections,
    BTIconSlidersHorizontal,
    BTIconSubsVendors,
    BTIconSurveys,
    BTIconTimeClock,
    BTIconTodos,
    BTIconToolOutlined,
    BTIconUmbrella,
    BTIconUsers,
    BTIconVideos,
    BTIconWarranty,
} from "commonComponents/btWrappers/BTIcon";
import BTLegacyLink from "commonComponents/btWrappers/BTLegacyLink/BTLegacyLink";
import { BTLink, BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { DateDisplayRelative } from "commonComponents/utilities/DateDisplay/DateDisplayRelative";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";
import { NoteText } from "commonComponents/utilities/NoteText/NoteText";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";
import { JobDetailTab } from "entity/job/Job.api.types";
import { IntegrationEnums } from "entity/marketplace/MarketplaceDirectory/MarketplaceDirectory.api.types";
import { getPreferencesLink } from "entity/notification/NotificationPanel/NotificationPanel";
import {
    MessageData,
    NotificationItem,
} from "entity/notification/NotificationPanel/NotificationPanel.api.types";
import { PurchasingDashboardPages } from "entity/rebate/PurchasingDashboard/PurchasingDashboard.types";
import { SubSetupTabKeys } from "entity/sub/SubSetup/SubSetup.api.types";
import { SubTabKeys } from "entity/sub/SubVendor/SubVendor.api.types";

import "../NotificationPanel/NotificationPanel.less";
import "./NotificationCard.less";

export interface INotificationCardProps {
    userId: number;
    entity: NotificationItem;
    notificationPanelRef: React.RefObject<HTMLDivElement>;
    openMenuId: number;
    onMenuOpen: (id: number) => void;
    onMarkRead: (notificationId: number, markRead: boolean) => Promise<void>;
}

const NotificationCardInternal: React.FunctionComponent<INotificationCardProps> = (props) => {
    const { entity } = props;
    const builderInfo = useContext(BuilderInfoContext);

    const getMenuItems = (entity: NotificationItem) => {
        const menuItemClass = "NotificationPanelDropdownItem";
        return (
            <BTMenu className="NotificationContextMenu">
                <BTMenuItem
                    key="update-read-status"
                    data-testid="update-read-status"
                    className={menuItemClass}
                    onClick={({ domEvent }) => {
                        domEvent.preventDefault();
                        domEvent.stopPropagation();
                        return props.onMarkRead(entity.id, !entity.isRead);
                    }}
                >
                    {entity.isRead && (
                        <>
                            <BTIconEyeglassesSlashed className="padding-right-sm" />
                            Mark as Unread
                        </>
                    )}
                    {!entity.isRead && (
                        <>
                            <BTIconEyeglasses className="padding-right-sm" />
                            Mark as Read
                        </>
                    )}
                </BTMenuItem>
                {entity.canUnfollow && (
                    <BTMenuItem
                        key="open-unfollow-notifications"
                        data-testid="mark-unfollowed"
                        className={menuItemClass}
                        onClick={({ domEvent }) => {
                            domEvent.preventDefault();
                            domEvent.stopPropagation();
                        }}
                    >
                        <BTLinkRelative
                            to={`/NotificationPanel${routes.notifications.getPreferencesLink(
                                entity.id,
                                entity.messageData.builderId
                            )}`}
                            isUnderline={false}
                            className="Unfollow"
                        >
                            <BTIconNotifications
                                isOnWhite
                                className={classNames("padding-right-sm", "Bell")}
                            />
                            Unfollow...
                        </BTLinkRelative>
                    </BTMenuItem>
                )}
                <BTMenuItem
                    key="open-notification-preferences"
                    data-testid="open-notification-preferences"
                    className={menuItemClass}
                    onClick={({ domEvent }) => {
                        domEvent.preventDefault();
                        domEvent.stopPropagation();
                    }}
                >
                    <BTLinkRelative
                        to={`/NotificationPanel${getPreferencesLink(props.userId)}`}
                        className="NotificationLink"
                        isUnderline={false}
                    >
                        <BTIconSlidersHorizontal className="padding-right-sm" />
                        Preferences
                    </BTLinkRelative>
                </BTMenuItem>
            </BTMenu>
        );
    };

    const cardInfo = getCardInfo(entity.actionType, entity.messageData, builderInfo);

    return (
        <div className="NotificationCard">
            <NotificationLink
                notificationId={entity.id}
                onMarkRead={props.onMarkRead}
                link={cardInfo.link}
                isLegacyLink={cardInfo.isLegacyLink}
                isFullPageRedirect={cardInfo.isFullPageRedirect}
            >
                <div
                    data-testid="notification-item"
                    className={classNames("flex padding-all-xss", {
                        Read: entity.isRead,
                        Unread: !entity.isRead,
                    })}
                    style={{ lineHeight: "1.5em" }}
                >
                    <div className="flex-shrink-0" style={{ width: 14 }}>
                        {!entity.isRead && <span className="IsReadDot margin-left-xss" />}
                    </div>
                    <div className="margin-all-xs">{cardInfo.icon}</div>
                    <div className="flex-grow-1 margin-vertical-xs">
                        <div className="text-whitespace flex justify-content-between">
                            <div style={{ display: "inline-grid" }}>
                                <TextTruncate
                                    getPopoverContainer={(trigger) => trigger.parentElement!}
                                >
                                    <b>{entity.messageData.title}</b>
                                </TextTruncate>
                            </div>
                            <div className="text-nowrap">
                                <NoteText>
                                    <DateDisplayRelative
                                        data-chromatic="ignored"
                                        value={entity.dateSent}
                                    />
                                </NoteText>
                            </div>
                        </div>
                        <div style={{ wordBreak: "break-word" }}>{entity.messageData.body}</div>
                        {entity.messageData.extraData && entity.messageData.extraData.subject && (
                            <div style={{ display: "inline-grid" }}>
                                <TextTruncate
                                    getPopoverContainer={(trigger) => trigger.parentElement!}
                                    className="CommentText"
                                >
                                    {entity.messageData.extraData.subject}
                                </TextTruncate>
                            </div>
                        )}
                    </div>
                    <div>
                        <BTDropdown
                            getPopupContainer={(triggerNode) => {
                                return props.notificationPanelRef.current ?? triggerNode;
                            }}
                            overlay={getMenuItems(entity)}
                            placement="bottomRight"
                            visible={props.openMenuId === entity.id}
                            onVisibleChange={(visible) => props.onMenuOpen(visible ? entity.id : 0)}
                        >
                            <div
                                className="align-items-center margin-horizontal-xs margin-vertical-xss"
                                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                            >
                                <BTButton
                                    data-testid="card-menu"
                                    isolated
                                    type="tertiary"
                                    onClick={(e: React.MouseEvent) => e.preventDefault()}
                                    icon={<BTIconEllipsisOutlined />}
                                />
                            </div>
                        </BTDropdown>
                    </div>
                </div>
                <Divider className="margin-vertical-zero" />
            </NotificationLink>
        </div>
    );
};

interface INotificationLinkProps {
    notificationId: number;
    link?: string;
    isLegacyLink?: boolean;
    onMarkRead: (notificationId: number, markRead: boolean) => Promise<void>;
    isFullPageRedirect?: boolean;
}
const NotificationLink: React.FunctionComponent<INotificationLinkProps> = (props) => {
    if (props.link) {
        if (props.isLegacyLink) {
            return (
                <BTLegacyLink
                    className="NotificationLink"
                    role="link"
                    to={`${props.link}`}
                    onClick={() => props.onMarkRead(props.notificationId, true)}
                    isUnderline={false}
                >
                    {props.children}
                </BTLegacyLink>
            );
        }
        if (props.isFullPageRedirect) {
            return (
                <BTLink
                    className="NotificationLink"
                    role="link"
                    to={`${props.link}`}
                    useAutoSPARouting
                    onClick={() => props.onMarkRead(props.notificationId, true)}
                    isUnderline={false}
                >
                    {props.children}
                </BTLink>
            );
        }
        return (
            <BTLinkRelative
                className="NotificationLink"
                role="link"
                to={`/NotificationPanel${props.link}`}
                onClick={() => props.onMarkRead(props.notificationId, true)}
                isUnderline={false}
            >
                {props.children}
            </BTLinkRelative>
        );
    }
    return <div onClick={() => props.onMarkRead(props.notificationId, true)}>{props.children}</div>;
};

interface INotificationCardInfo {
    icon: React.ReactNode;
    link?: string;
    isLegacyLink?: boolean;
    isFullPageRedirect?: boolean;
}

function getCardInfo(
    actionType: NotificationActionType,
    data: MessageData,
    builderInfo: BuilderInfo | null | undefined
): INotificationCardInfo {
    const iconSize = 20;
    const { entityId, jobId, builderId, recipientLoginType, extraData } = data;

    switch (actionType) {
        case NotificationActionType.Bid_Details:
        case NotificationActionType.BidPackage_Details:
            return {
                icon: <BTIconBids size={iconSize} />,
                link: entityId && jobId ? routes.bid.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Bill_Details:
            return {
                icon: <BTIconBillsPurchaseOrders size={iconSize} />,
                link: generateBillsDetailsLink(entityId, jobId, extraData?.relatedId),
            };
        case NotificationActionType.ChangeOrder_Details:
            return {
                icon: <BTIconChangeOrders size={iconSize} />,
                link:
                    entityId && jobId
                        ? routes.changeOrder.getChangeOrderDetailsLink(entityId, jobId)
                        : "",
            };
        case NotificationActionType.CreditMemo_Details:
            return {
                icon: <BTIconOwnerInvoices size={iconSize} />,
                link: entityId && jobId ? routes.creditMemos.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.DailyLog_Details:
            return {
                icon: <BTIconDailyLogs size={iconSize} />,
                link: entityId && jobId ? routes.dailyLog.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Document_Details:
            return {
                icon: <BTIconDocuments size={iconSize} />,
                link: entityId
                    ? routes.media.getMediaPropertiesLink(
                          entityId,
                          jobId ?? JobIdTypes.GlobalDocs,
                          MediaType.Document
                      )
                    : "",
            };
        case NotificationActionType.JobProposal_Details:
            return {
                icon: <BTIconEstimate size={iconSize} />,
                link: entityId && jobId ? routes.estimate.getProposalLink(jobId, entityId) : "",
            };
        case NotificationActionType.InternalUser_Details:
            return {
                icon: <BTIconUsers size={iconSize} />,
                link: entityId ? routes.internalUsers.getInternalUserDetailsLink(entityId) : "",
            };
        case NotificationActionType.InternalUser_Details_NotificationSettings:
            return {
                icon: <BTIconUsers size={iconSize} />,
                link: entityId
                    ? routes.internalUsers.getInternalUserDetailsLink(
                          entityId,
                          InternalUserTabKeys.Notifications
                      )
                    : "",
            };
        case NotificationActionType.Jobsite_Details:
            return {
                icon: <BTIconJobs size={iconSize} />,
                link: entityId ? routes.job.getDetailsLink(entityId, JobDetailTab.Job) : "",
            };
        case NotificationActionType.Jobsite_Details_BuildersRiskInsurance:
            return {
                icon: <BTIconUmbrella size={iconSize} />,
                link: entityId ? routes.job.getDetailsLink(entityId, JobDetailTab.BRI) : "",
            };
        case NotificationActionType.Jobsite_Details_Owner:
            return {
                icon: <BTIconContacts size={iconSize} />,
                link: entityId ? routes.job.getDetailsLink(entityId, JobDetailTab.Owner) : "",
            };
        case NotificationActionType.LeadActivity_Details:
            return {
                icon: <BTIconPhoneOutline size={iconSize} />,
                link:
                    entityId && extraData?.relatedId
                        ? routes.leadActivity.getDetailsLink(
                              entityId,
                              extraData.relatedId,
                              OpenActivityType.ViewActivity,
                              undefined
                          )
                        : "",
            };
        case NotificationActionType.Lead_Details:
            return {
                icon: <BTIconLeadOpportunities size={iconSize} />,
                link: entityId ? routes.lead.getDetailsLink(entityId) : "",
            };
        case NotificationActionType.LeadProposal_Details:
            return {
                icon: <BTIconLeadProposals size={iconSize} />,
                link:
                    entityId && extraData?.relatedId
                        ? routes.leadProposals.getDetailsLink(entityId, extraData.relatedId, false)
                        : "",
            };
        case NotificationActionType.LienWaiver_Details:
            return {
                icon: <BTIconBillsPurchaseOrders size={iconSize} />,
                link: entityId && jobId ? routes.lienWaiver.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Message_Details:
            return {
                icon: <BTIconMessages size={iconSize} />,
                link: generateMessageDetailsLink(entityId, extraData?.folderId),
            };
        case NotificationActionType.Owner_Setup:
            return {
                icon: <BTIconGearSix size={iconSize} />,
                link: entityId ? routes.owner.getSetupLink(entityId) : "",
            };
        case NotificationActionType.OwnerPayment_Details:
            return {
                icon: <BTIconOwnerInvoices size={iconSize} />,
                link: entityId && jobId ? routes.ownerInvoice.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Payment_Details:
            return {
                icon: <BTIconPurchaseOrderPayments size={iconSize} />,
                link:
                    entityId && jobId
                        ? routes.purchaseOrderPayment.getDetailsLink(entityId, jobId)
                        : "",
            };
        case NotificationActionType.Photo_Details:
            return {
                icon: <BTIconPhotos size={iconSize} />,
                link: entityId
                    ? routes.media.getMediaPropertiesLink(
                          entityId,
                          jobId ?? JobIdTypes.GlobalDocs,
                          MediaType.Photo
                      )
                    : "",
            };
        case NotificationActionType.PurchaseOrder_Details:
            return {
                icon: <BTIconPurchaseOrders size={iconSize} />,
                link: entityId && jobId ? routes.purchaseOrder.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Receipt_Details:
            return {
                icon: <BTIconReceipts size={iconSize} />,
                link: entityId ? routes.receipts.getDetailsLink(entityId) : "",
            };
        case NotificationActionType.RFI_Details:
            return {
                icon: <BTIconRFIs size={iconSize} />,
                link: entityId && jobId ? routes.rfi.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Schedule_Details:
            return {
                icon: <BTIconSchedule size={iconSize} />,
                link: entityId && jobId ? routes.schedule.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.SelectionChoice_Details:
            return {
                icon: <BTIconSelections size={iconSize} />,
                link: generateSelectionChoiceLink(entityId, jobId, extraData?.relatedId),
            };
        case NotificationActionType.Selection_Details:
            return {
                icon: <BTIconSelections size={iconSize} />,
                link: entityId && jobId ? routes.selection.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.PdfSignatureRequest_Details:
            return {
                icon: <BTIconDocuments size={iconSize} />,
                link:
                    entityId && jobId && extraData?.documentInstanceId
                        ? routes.signatureRequest.getStandaloneDetailsLink(
                              entityId,
                              jobId,
                              extraData.documentInstanceId
                          )
                        : "",
            };
        case NotificationActionType.PdfSignature_Details_Signing:
            return {
                icon: <BTIconDocuments size={iconSize} />,
                link: extraData?.documentInstanceId
                    ? routes.signatureRequest.getSignLink(
                          extraData.documentInstanceId,
                          recipientLoginType,
                          builderId
                      )
                    : "",
            };
        case NotificationActionType.Sub_Details:
            return {
                icon: <BTIconSubsVendors size={iconSize} />,
                link: entityId ? routes.sub.getDetailsLink(entityId) : "",
            };
        case NotificationActionType.Sub_Details_NotificationSettings:
            return {
                icon: <BTIconSubsVendors size={iconSize} />,
                link: entityId ? routes.sub.getDetailsLink(entityId, SubTabKeys.Notifications) : "",
            };
        case NotificationActionType.Sub_Details_TradeAgreement:
            return {
                icon: <BTIconSubsVendors size={iconSize} />,
                link: entityId
                    ? routes.sub.getDetailsLink(entityId, SubTabKeys.TradeAgreement)
                    : "",
            };
        case NotificationActionType.SubSetup_Details:
            return {
                icon: <BTIconGearSix size={iconSize} />,
                link: entityId ? routes.sub.getSetupLink(entityId) : "",
            };
        case NotificationActionType.SubSetup_Details_Certificates:
            return {
                icon: <BTIconGearSix size={iconSize} />,
                link: entityId
                    ? routes.sub.getSetupLink(entityId, SubSetupTabKeys.CertificatesContracts)
                    : "",
            };
        case NotificationActionType.SubSetup_Details_NotificationSettings:
            return {
                icon: <BTIconGearSix size={iconSize} />,
                link: entityId
                    ? routes.sub.getSetupLink(entityId, SubSetupTabKeys.Notifications)
                    : "",
            };
        case NotificationActionType.SubSetup_Details_TradeAgreement:
            return {
                icon: <BTIconGearSix size={iconSize} />,
                link: entityId
                    ? routes.sub.getSetupLink(entityId, SubSetupTabKeys.TradeAgreement)
                    : "",
            };
        case NotificationActionType.Survey_Details:
            return {
                icon: <BTIconSurveys size={iconSize} />,
                link: entityId && jobId ? routes.survey.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.SurveyOwner_Details:
            return {
                icon: <BTIconSurveys size={iconSize} />,
                link: entityId ? routes.survey.getOwnerSurvey(entityId) : "",
            };
        case NotificationActionType.TimeClock_ClockOut:
            return {
                icon: <BTIconTimeClock size={iconSize} />,
                link: entityId
                    ? routes.timeClock.getClockInClockOutLink(
                          entityId,
                          jobId ?? JobIdTypes.GlobalJob,
                          false,
                          false
                      )
                    : "",
            };
        case NotificationActionType.TimeClock_Details:
            return {
                icon: <BTIconTimeClock size={iconSize} />,
                link:
                    entityId && jobId
                        ? routes.timeClock.getShiftDetailsLink(entityId, jobId)
                        : entityId
                        ? routes.timeClock.getShiftDetailsLink(entityId)
                        : "",
            };
        case NotificationActionType.ToDo_Details:
            return {
                icon: <BTIconTodos size={iconSize} />,
                link: entityId && jobId ? routes.toDo.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.Videos_Details:
            return {
                icon: <BTIconVideos size={iconSize} />,
                link: entityId
                    ? routes.media.getMediaPropertiesLink(
                          entityId,
                          jobId ?? JobIdTypes.GlobalDocs,
                          MediaType.Video
                      )
                    : "",
            };
        case NotificationActionType.Warranty_Details:
            return {
                icon: <BTIconWarranty size={iconSize} />,
                link: entityId && jobId ? routes.warranty.getDetailsLink(entityId, jobId) : "",
            };
        case NotificationActionType.WarrantyService_Details:
            return {
                icon: <BTIconWarranty size={iconSize} />,
                link:
                    entityId && jobId && extraData?.warrantyId && extraData?.completed != null
                        ? routes.warranty.getServiceLink(
                              extraData.warrantyId,
                              jobId,
                              entityId,
                              extraData.completed
                          )
                        : "",
            };
        case NotificationActionType.Deposit_Details:
            return {
                icon: <BTIconOwnerInvoices size={iconSize} />,
                link:
                    entityId && jobId ? routes.deposits.getDetailsLink(entityId, jobId, false) : "",
            };
        case NotificationActionType.Integration_Settings:
            return {
                icon: <BTIconToolOutlined size={iconSize} />,
                link: "",
            };
        case NotificationActionType.HubSpot_Settings:
            return {
                icon: <BTIconToolOutlined size={iconSize} />,
                link: routes.marketplace.getintegrationSettingsLink(IntegrationEnums.HubSpot),
            };
        case NotificationActionType.Salesforce_Settings:
            return {
                icon: <BTIconToolOutlined size={iconSize} />,
                link: routes.marketplace.getintegrationSettingsLink(IntegrationEnums.Salesforce),
            };
        case NotificationActionType.Pipedrive_Settings:
            return {
                icon: <BTIconToolOutlined size={iconSize} />,
                link: routes.marketplace.getintegrationSettingsLink(IntegrationEnums.Pipedrive),
            };
        case NotificationActionType.Reconsent_Details:
            return {
                icon: <BTIconToolOutlined size={iconSize} />,
                link: routes.marketplace.reconsent,
            };
        case NotificationActionType.Rebates_List:
            return {
                icon: <BTIconRebates size={iconSize} />,
                link: builderInfo?.flags.purchasingOneVisionIteration1
                    ? routes.purchasing.getDashboardLink(PurchasingDashboardPages.Summary)
                    : routes.rebates.getListLink(),
                isFullPageRedirect: true,
            };
        default:
            return {
                icon: <></>,
                link: "",
            };
    }
}

function generateMessageDetailsLink(entityId?: number, folderId?: any): string {
    if (entityId && folderId) {
        return routes.messages.getMessageDetailsLink(entityId, folderId);
    } else if (entityId && (folderId === undefined || folderId == null)) {
        return routes.messages.getMessageDetailsLink(entityId);
    } else {
        return "";
    }
}

function generateSelectionChoiceLink(entityId?: number, jobId?: number, relatedId?: any): string {
    if (entityId && jobId && relatedId) {
        return (
            routes.selection.getDetailsLink(relatedId, jobId) +
            routes.selectionChoice.getDetailsLink(entityId, jobId, relatedId)
        );
    } else if (entityId && jobId && (relatedId === undefined || relatedId == null)) {
        return routes.selectionChoice.getDetailsLink(entityId, jobId);
    } else {
        return "";
    }
}

function generateBillsDetailsLink(entityId?: number, jobId?: number, relatedId?: number): string {
    if (entityId && jobId && (relatedId === undefined || relatedId == null)) {
        return routes.bill.getDetailsLink(entityId, jobId);
    } else {
        return "";
    }
}

export const NotificationCard = NotificationCardInternal;
