import classNames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoginTypes } from "types/enum";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { isInPortal } from "utilities/portal/portal";

import { BTAvatar } from "commonComponents/btWrappers/BTAvatar/BTAvatar";
import { BTAvatarSize } from "commonComponents/btWrappers/BTAvatar/BTAvatar.types";
import {
    getAvatarColor,
    getAvatarColorDark,
    isValidUserId,
    sizeNameToPixels,
} from "commonComponents/btWrappers/BTAvatar/BTAvatar.utils";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTUserInfoDisplay } from "commonComponents/btWrappers/BTUser/BTUserInfoDisplay";

import { UserProfileResponse } from "entity/login/Login/Login.api.types";
import {
    IUserActivationPageHandler,
    UserActivationPageHandler,
} from "entity/UserActivationPage/UserActivationPage.api.handler";

import "./BTUser.less";

interface IBTUserProps {
    /**
     * @default middle
     */
    size?: BTAvatarSize;

    displayName: string;
    email?: string;
    phoneCell?: string;

    globalUserId: number;

    /**
     * Show avatar only or avatar AND name (full)
     * @default avatar
     */
    type?: "mini" | "full";

    /**
     * Show the avatars initials
     * @default true
     */
    showInitials?: boolean;

    /**
     * When to show the contact card
     * @default hover
     */
    contactCard?: "none" | "hover" | "click";

    style?: React.CSSProperties;
    className?: string;

    handler?: IUserActivationPageHandler;
    onFullNameClick?: (globalUserId: number) => void;
}

const defaultUserHandler = new UserActivationPageHandler();

export const BTUser: React.FunctionComponent<IBTUserProps & ITrackingProp> = track((props) => ({
    element: "User",
    uniqueId: props.globalUserId,
}))(
    ({
        size = "middle",
        displayName,
        email,
        phoneCell,
        globalUserId,
        type = "mini",
        showInitials = true,
        contactCard = "hover",
        style,
        className,
        handler = defaultUserHandler,
        tracking,
        onFullNameClick,
    }) => {
        const [userProfile, setUserProfile] = useState<UserProfileResponse | undefined>(undefined);

        useEffect(() => {
            setUserProfile(undefined);
        }, [globalUserId]);

        // We use global user id = 3 for some reason on Bills/POs when it could be a real user
        const isMiscBillPOUser = globalUserId === 3 && displayName === "Misc";
        const isRealUser = isValidUserId(globalUserId) && !isMiscBillPOUser;

        const handleLoadUserProfile = useCallback(async () => {
            try {
                tracking?.trackEvent({ event: "PopoverOpen" });

                let userProfile: UserProfileResponse | undefined = undefined;
                if (!isRealUser) {
                    userProfile = {
                        name: displayName,
                        userType: BTLoginTypes.ALL,
                        phone: "",
                        email: "",
                        title: "",
                        cellPhone: "",
                        primaryContact: "",
                        canStartChatConversation: false,
                    };
                } else {
                    userProfile = await handler.profile(globalUserId);
                }
                setUserProfile(userProfile);
            } catch (e) {
                showAPIErrorMessage(e);
            }
        }, [tracking, isRealUser, displayName, handler, globalUserId]);

        const handleVisibleChange = useCallback(
            (visible: boolean) => {
                if (visible && userProfile === undefined) {
                    void handleLoadUserProfile();
                }
            },
            [handleLoadUserProfile, userProfile]
        );

        // todo <ContactInfoDisplay> instead?
        const handleGetUserContent = useCallback(() => {
            return (
                <BTUserInfoDisplay
                    showManageUser
                    hideAllControls={!isRealUser}
                    {...{ globalUserId, displayName, userProfile, isMiscBillPOUser }}
                />
            );
        }, [displayName, globalUserId, isMiscBillPOUser, isRealUser, userProfile]);

        const userInfo = useContext(UserInfoContext);
        const currentUserId = userInfo?.globalUserId;

        const isCurrentUser = currentUserId === globalUserId;
        const sizeInPixels = sizeNameToPixels(size);
        const fontSizeInPixels = Math.max(sizeInPixels / 2 - 2, 14);

        const isBuilder = isInPortal({ builder: true });
        const isDisabled = !isBuilder || !displayName || contactCard === "none";

        return (
            <BTPopover
                trigger={contactCard === "click" ? ["click", "focus"] : ["hover", "focus"]}
                unconstrainedWidth={true}
                onVisibleChange={handleVisibleChange}
                content={handleGetUserContent}
                disabled={isDisabled}
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0.2}
            >
                <BTRow
                    gutter={8}
                    className={classNames("BTUser", className)}
                    role={isDisabled ? undefined : "button"}
                    tabIndex={isDisabled ? undefined : 0}
                    data-testid={`BTUser-${globalUserId}`}
                >
                    <BTCol>
                        <BTAvatar
                            size={size}
                            style={{
                                backgroundColor: getAvatarColor(globalUserId),
                                color: getAvatarColorDark(globalUserId),
                                ...style,
                            }}
                            displayName={showInitials ? displayName : ""}
                        />
                    </BTCol>
                    {type === "full" && (
                        <BTCol>
                            <div
                                className={classNames("text-overflow-auto", {
                                    "BTUser isCurrentUser": isCurrentUser || email || phoneCell,
                                })}
                                style={{ fontSize: fontSizeInPixels }}
                                onClick={() =>
                                    onFullNameClick ? onFullNameClick(globalUserId) : undefined
                                }
                            >
                                {displayName}
                            </div>
                            {email && <div className="BTUser details">{email}</div>}
                            {phoneCell && <div className="BTUser details">{phoneCell}</div>}
                        </BTCol>
                    )}
                </BTRow>
            </BTPopover>
        );
    }
);
