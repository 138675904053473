import { BTIconHighlightOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemDrawProps {
    fileId: number;
    onClick: () => Promise<void>;
}

const MenuItemDraw = ({ fileId, onClick, ...antProps }: IMenuItemDrawProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`draw-menuitem-${fileId}`}
            data-testid={`draw-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconHighlightOutlined /> Draw
        </BTMenuItem>
    );
};

export default MenuItemDraw;
