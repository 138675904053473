import { message } from "antd";
import classNames from "classnames";
import { isEqual } from "lodash-es";
import moment from "moment";
import queryString from "query-string";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { EmailFolder, ReplyType } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { getSelectedItems, replaceItemById } from "utilities/array/array";
import { setLoadingAction } from "utilities/form/form";
import { isGridErrorMessageApiError, showGridErrorMessage } from "utilities/grid/grid";
import {
    EmptyStateEntity,
    getFilterEntityType,
    getInitialFilterValues,
    getPagingData,
    GridRequest,
    GridSort,
    IHasEmptyStateBannerProps,
    IHasEmptyStateBannerState,
    IListContainerProps,
    IListContainerState,
    IListData,
    PagingData,
} from "utilities/list/list.types";
import { routes } from "utilities/routes";
import { saveScrollPosition, ScrollPos, scrollToPosition } from "utilities/scroll/scroll";
import { formattedPlural } from "utilities/string/string";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { btDeleteConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTIconArrowLeftOutlined, BTIconReloadOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import {
    BTListLayout,
    BTListLayoutWhitespace,
    ListSection,
} from "commonComponents/btWrappers/BTListLayout/BTListLayout";
import BTPagination, {
    getDefaultPageSize,
} from "commonComponents/btWrappers/BTPagination/BTPagination";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { EmptyStateHandler } from "commonComponents/entity/emptyState/common/EmptyState.api.handler";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { withJobsitesRequired } from "commonComponents/helpers/JobsitesRequired/JobsitesRequired";
import BTEmailForwardModal from "commonComponents/utilities/BTEmailForwardModal/BTEmailForwardModal";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { TargetProps } from "commonComponents/utilities/DragAndDrop/SortableJsPlugins/Target/Target";
import { CheckedActions } from "commonComponents/utilities/Grid/common/CheckedActions/CheckedActions";
import { ListActionBar } from "commonComponents/utilities/ListActionBar/ListActionBar";
import {
    deleteSearchEntity,
    SearchCategory,
} from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { getSystemFilter } from "commonComponents/utilities/SystemFilters/SystemFilterUtils";

import { FilterHandler } from "entity/filters/Filter/Filter.api.handler";
import { FilterEntity, IFilterFormValues } from "entity/filters/Filter/Filter.api.types";
import { FilterDrawer } from "entity/filters/Filter/FilterDrawer";
import { RouteInternalUser } from "entity/internalUser/InternalUserDetails/RouteInternalUser";
import {
    IMessageDetailsHandler,
    MessageDetailsHandler,
} from "entity/message/common/Message.api.handler";
import { MessageFolder } from "entity/message/common/Message.api.types";
import { MoveToFolderModal } from "entity/message/common/MoveToFolderModal";
import { OutOfOfficeBanner } from "entity/message/common/OutOfOfficeBanner";
import MessageDetails from "entity/message/Message/Message";
import { RouteMessageCompose } from "entity/message/MessageCompose/MessageComposeRoute";
import { MessageEmptyState } from "entity/message/MessageList/MessageEmptyState/MessageEmptyState";
import { MessageEmptyStateBanner } from "entity/message/MessageList/MessageEmptyState/MessageEmptyStateBanner";
import {
    IMessageListHandler,
    MessageListHandler,
} from "entity/message/MessageList/MessageList.api.handler";
import {
    MessageEntry,
    MessageListActions,
    MessageListEntity,
    MessageListPrompts,
    MessageListSortColumns,
} from "entity/message/MessageList/MessageList.api.types";
import { EmailMessagesFilterTypes } from "entity/message/MessageList/MessageList.filters.types";
import MessageListFoldersWrapper from "entity/message/MessageList/MessageListFolders/MessageListFolders";
import {
    IFolderFormValue,
    IMessageListFoldersFormValues,
} from "entity/message/MessageList/MessageListFolders/MessageListFolders";
import MessageListTable from "entity/message/MessageList/MessageListTable/MessageListTable";

import "./MessageList.less";

interface IMessageListProps
    extends RouteComponentProps,
        IListContainerProps,
        IHasEmptyStateBannerProps {
    jobIds: number[];
    jobName: string;
    folderId: number;
    handler?: IMessageListHandler;
    messageHandler?: IMessageDetailsHandler;
    messageId?: number;
}

interface IMessageListState
    extends IListContainerState<MessageListEntity>,
        IHasEmptyStateBannerState {
    filterEntity?: FilterEntity;
    previousFilters?: IFilterFormValues;
    listEntity?: MessageListEntity;
    isLoading: boolean;
    hideFolders: boolean;
    hideList: boolean;
    isFolderEditMode: boolean;
    actionBeingPerformed: MessageListActions;
    folders: MessageFolder[];
    currentPrompt: MessageListPrompts;
    lastUpdatedDate?: moment.Moment;
    previousSortData: GridSort;
    emailForwardAddress: string;
    savedScrollPos?: ScrollPos;
    isOutOfOffice: boolean;
}

class MessageListInternal extends Component<IMessageListProps, IMessageListState> {
    static defaultProps = {
        filterHandler: new FilterHandler(),
        handler: new MessageListHandler(),
        emptyStateHandler: new EmptyStateHandler(),
        messageHandler: new MessageDetailsHandler(),
    };

    state: Readonly<IMessageListState> = {
        isLoading: true,
        hideFolders: true,
        hideList: true,
        isFolderEditMode: false,
        actionBeingPerformed: undefined,
        isEmptyStateBannerDismissed: false,
        folders: [],
        currentPrompt: undefined,
        previousSortData: new GridSort(MessageListSortColumns.LastModifiedDate.toString(), "desc"),
        emailForwardAddress: "",
        savedScrollPos: undefined,
        isOutOfOffice: false,
    };

    componentDidMount = async () => {
        try {
            const { filterEntity, filterValues } = await getInitialFilterValues(this.props);
            const folders = await this.getFoldersForState();
            // eslint-disable-next-line deprecate/member-expression
            const qsValues: any = queryString.parse(this.props.location.search, {
                parseBooleans: true,
            });

            const result = await this.loadList({
                updatedFilterValues: filterValues,
            });
            const isEmptyStateBannerDismissed =
                (result.listEntity &&
                    !result.listEntity.listMetadata.emptyStatesMetadata.isNewToEntity) ||
                false;
            const recipientId = qsValues.recipientId || qsValues.recipientid;

            this.setState({
                ...folders,
                ...result,
                filterEntity,
                previousFilters: filterValues,
                isLoading: false,
                isEmptyStateBannerDismissed,
            });
            // show compose message if needed
            this.showComposeMessage(recipientId, qsValues.replyTo);
        } catch (e) {
            if (isGridErrorMessageApiError(e)) {
                showGridErrorMessage(e);
            } else {
                this.setState(() => {
                    throw e;
                });
            }
        }
    };

    componentDidUpdate = async (prevProps: IMessageListProps) => {
        // Whenever the selected job ids change, we need to do a full reload of the list
        const hasJobChanged = !isEqual(prevProps.jobIds, this.props.jobIds);
        const hasFolderChanged = prevProps.folderId !== this.props.folderId;
        const hasSelectedMessageChanged = prevProps.messageId !== this.props.messageId;
        if (hasJobChanged || hasFolderChanged || hasSelectedMessageChanged) {
            await this.refreshAll(false);
        }
    };

    private showComposeMessage(recipientId?: string, replyTo?: string) {
        if (recipientId || replyTo) {
            const recpId = recipientId ? parseInt(recipientId) : undefined;
            const replyToId = replyTo ? parseInt(replyTo) : undefined;
            const { history, match, jobIds } = this.props;
            const jobId = jobIds.length === 1 ? jobIds[0] : 0;
            const replyType = recpId ? ReplyType.Send : ReplyType.Reply;

            history.push(
                match.url +
                    routes.messages.getComposeLink(0, jobId, {
                        replyId: replyToId,
                        defaultRecipientId: recpId,
                        replyType: replyType,
                    })
            );
        }
    }

    /**
     * This gets the default paging object for page 1, while respecting the users current page size
     */
    private getDefaultPagingData() {
        const { listEntity } = this.state;
        if (listEntity && listEntity.pagingData) {
            const pagingData = listEntity.pagingData;
            return getPagingData(1, pagingData.pageSize, pagingData.pageSize);
        } else {
            return getPagingData(1);
        }
    }

    /**
     * This function abstracts the logic for actually fetching the list data. The parameters of
     * updatedFilterValues and updatedPagingData are only defined when they have been changed.
     * If filters haven't been changed, the api call is made with the previous filters from state.
     * If the paging data and the filters haven't changed, we can use the previous paging data
     * to make the request, if we have it.
     *
     * This method returns all of the list data, as well as
     * the filters used to make the request.
     */
    private loadList = async (listData: IListData) => {
        const { handler, jobIds, entityType } = this.props;
        const { updatedFilterValues, updatedPagingData, updatedSortData } = listData;
        const { listEntity, previousFilters, previousSortData } = this.state;

        const filters = updatedFilterValues || previousFilters;
        const gridSort = updatedSortData || previousSortData;
        let pagingData = updatedPagingData;

        // Update folder hidden filter to use the folder filter
        if (filters) {
            const folderFilter = filters!.items!.find(
                (item) => item.key === `${EmailMessagesFilterTypes.Folder}`
            );
            if (folderFilter) {
                folderFilter.selectedValue = this.props.folderId;
            }
        }

        if (!updatedFilterValues && !updatedPagingData && listEntity) {
            // Only pull the old paging data from state if we didn't apply new filters
            pagingData = listEntity.pagingData;
        } else if (!updatedPagingData) {
            pagingData = new PagingData(getDefaultPageSize(entityType));
        }

        const gridRequest = new GridRequest(EmptyStateEntity.Messages);
        gridRequest.sortColumn = gridSort.sortColumn;
        gridRequest.sortDirection = gridSort.sortDirection!;
        const listResult = await handler!.get(gridRequest, jobIds, filters, pagingData);
        return {
            listEntity: listResult,
            previousFilters: filters,
            previousSortData: gridSort,
            hideList: false,
            lastUpdatedDate: moment(new Date()),
        };
    };

    /**
     * This function abstracts the state management associated with loading all the list data.
     * Filter changes and paging changes will flow through this method.
     */
    private loadListIntoState = async (listData: IListData, refreshFolders?: boolean) => {
        try {
            this.setState({
                isLoading: true,
                isFolderEditMode: false,
                currentPrompt: undefined,
            });

            const scrollPos = this.state.savedScrollPos;
            const result = refreshFolders
                ? {
                      ...(await this.loadList(listData)),
                      ...(await this.getFoldersForState()),
                  }
                : await this.loadList(listData);
            this.setState({
                ...result,
                isLoading: false,
                savedScrollPos: undefined,
            });

            if (scrollPos) {
                scrollToPosition(scrollPos);
                window.dispatchEvent(new Event("scroll"));
            }

            return true;
        } catch (e) {
            showGridErrorMessage(e);
            this.setState({ isLoading: false });
            return false;
        }
    };

    /**
     * This function is called when filters are updated by the user,
     * and will reload all of the data in the list
     */
    private handleFilterSubmit = async (filterValues: IFilterFormValues) => {
        const success = await this.loadListIntoState({ updatedFilterValues: filterValues });
        if (success) {
            void message.success("Results updated");
        }
    };

    /**
     * This function is called when the current page or size is changed,
     * and will reload all of the data in the list
     */
    private handlePagingChange = async (pagingData: PagingData) => {
        await this.loadListIntoState({ updatedPagingData: pagingData });
    };

    /**
     * This refreshes the data, resetting back to page 1. This is used
     * when the job changes, or when a details modal closes and
     * we need to refresh the entire list
     */
    private refreshAll = async (keepPaging: boolean = false) => {
        // If maintaing the page then also keep the scroll position
        if (!keepPaging) {
            await this.loadListIntoState({ updatedPagingData: this.getDefaultPagingData() }, true);
        } else {
            await this.loadListIntoState({}, true);
        }
    };

    private getFoldersForState = async () => {
        const resp = await this.props.handler!.getFolders();
        return {
            folders: resp.folders,
            hideFolders: false,
            isOutOfOffice: resp.isOutOfOffice,
        };
    };

    private refreshFolders = async () => {
        try {
            this.setState(await this.getFoldersForState());
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleBeforeClose = () => {
        this.props.history.replace(this.props.match.url);
        if (this.props.messageId) {
            void this.refreshFolders();
        } else {
            void this.refreshAll(true);
        }
    };

    private handleDismissEmptyStateBanner = async () => {
        try {
            await this.props.emptyStateHandler!.dismissAlert(EmptyStateEntity.Messages);
            this.setState({ isEmptyStateBannerDismissed: true });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleUnselectAll = async () => {
        const { listEntity } = this.state;
        if (listEntity) {
            const newSelectedItems = listEntity.data.map((d) => ({
                ...d,
                isSelected: false,
            }));
            this.setListData(newSelectedItems);
        }
    };

    /**
     * Helper function to replace the list item data in state.
     */
    private setListData = (data: MessageEntry[]) => {
        this.setState({
            listEntity: {
                ...this.state.listEntity!,
                data,
            },
        });
    };

    /**
     * Helper function to replace a single list item in state.
     */
    private handleItemChange = (item: MessageEntry) => {
        const { listEntity } = this.state;
        listEntity && this.setListData(replaceItemById(listEntity.data, item));
    };

    private handleSortUpdated = async (updatedSortData: GridSort) => {
        await this.loadListIntoState({ updatedSortData });
    };

    private handleChangeFolder = async (folderId: number) => {
        this.props.history.push(routes.messages.getListLink(folderId));
    };

    private handleManageFolders = (value: boolean) => {
        this.setState({ isFolderEditMode: value });
    };

    private handleSaveFolders = async (values: IMessageListFoldersFormValues) => {
        try {
            const removedFolderIds = values.folders.filter((f) => f.deleted).map((f) => f.id);
            const existingFolders = values.folders.filter(
                (f) => f.id > 0 && !f.deleted && !Object.values(EmailFolder).includes(f.id)
            );
            const newFolders = values.folders.filter((f) => f.id === 0);

            if (removedFolderIds.length > 0) {
                btDeleteConfirm({
                    entityName: formattedPlural({
                        value: removedFolderIds.length,
                        one: "Folder",
                        other: "Folders",
                    }),
                    content: `All messages in ${formattedPlural({
                        value: removedFolderIds.length,
                        one: "this folder",
                        other: "these folders",
                    })} will be moved to the trash.`,
                    onOk: () => {
                        // Don't await, just fire and close
                        void this.updateFoldersCallback(
                            existingFolders,
                            newFolders,
                            removedFolderIds
                        );
                    },
                });
            } else {
                return await this.updateFoldersCallback(
                    existingFolders,
                    newFolders,
                    removedFolderIds
                );
            }
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleBackToList = () => {
        this.props.history.push(routes.messages.getListLink(this.props.folderId));
    };

    private handleMoveToFolder = async (folderId: number, messageIds: number[]) => {
        try {
            this.setState({ isLoading: true, currentPrompt: undefined });
            await this.props.handler!.moveMessages({
                sourceFolderId: this.props.folderId,
                destFolderId: folderId,
                messageIds,
            });
            await this.refreshAll(true);
            void message.success(
                `${messageIds.length} ${formattedPlural({
                    value: messageIds.length,
                    one: "Message",
                    other: "Messages",
                })} moved`
            );
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleMarkReadOrUnread = async (messageIds: number[], unread: boolean) => {
        try {
            this.setState({ isLoading: true });
            await this.props.messageHandler!.markAsReadOrUnread(messageIds, unread);
            await this.refreshAll(true);
            void message.success(
                `${formattedPlural({
                    value: messageIds.length,
                    one: "Message",
                    other: "Messages",
                })} updated`
            );
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleDeleteMessages = async (messageIds: number[]) => {
        const deleteFrom = [EmailFolder.Drafts, EmailFolder.Sent, EmailFolder.Trash];
        const label = formattedPlural({
            value: messageIds.length,
            one: "Message",
            other: "Messages",
        });
        const sublabel = formattedPlural({
            value: messageIds.length,
            one: "This message",
            other: "These messages",
        });
        const performDeleteMessages = async () => {
            try {
                this.setState({ isLoading: true });
                await this.props.messageHandler!.delete(messageIds, this.props.folderId);
                await this.refreshAll(true);
                if (deleteFrom.includes(this.props.folderId)) {
                    void message.success(`${label} deleted`);
                } else {
                    void message.success(`${label} moved to Trash`);
                }
                deleteSearchEntity(SearchCategory.Messages, ...messageIds);
            } catch (e) {
                showAPIErrorMessage(e);
            }
        };

        if (deleteFrom.includes(this.props.folderId)) {
            btDeleteConfirm({
                entityName: label,
                content: `${sublabel} will be permanently removed.`,
                onOk: performDeleteMessages,
            });
        } else {
            await performDeleteMessages();
        }
    };

    private updateFoldersCallback = async (
        existingFolders: IFolderFormValue[],
        newFolders: IFolderFormValue[],
        removedFolderIds: number[]
    ) => {
        await setLoadingAction<MessageListActions>(this, {
            actionBeingPerformed: "saveFolders",
            callback: async () => {
                await this.props.handler!.saveFolders(
                    existingFolders,
                    newFolders,
                    removedFolderIds
                );
                void message.success("Folders updated");

                // If deleting the current folder then select the inbox
                if (removedFolderIds.includes(this.props.folderId)) {
                    await this.handleChangeFolder(EmailFolder.Inbox);
                } else {
                    await this.refreshFolders();
                }
            },
        });

        // Leave edit mode--update successful
        this.setState({ isFolderEditMode: false });
    };

    private handleMoveMessageDrop = async (targetProps: TargetProps) => {
        const { listEntity } = this.state;
        const movedMessage = this.state.listEntity!.data.find(
            (x) => x.id === targetProps.draggedId
        );
        const selectedItems = getSelectedItems(listEntity && listEntity.data);

        // Add item to the selection list if it doesnt exist
        if (!selectedItems.some((x) => x.id === movedMessage!.id)) {
            selectedItems.push(movedMessage!);
        }

        await this.handleMoveToFolder(
            targetProps.targetId!,
            selectedItems.map((x) => x.id)
        );
    };

    private handleDragStart = (targetProps: TargetProps) => {
        const { listEntity } = this.state;
        const draggedMessage = listEntity!.data.find((x) => x.id === targetProps.draggedId);

        if (!draggedMessage!.isSelected) {
            const data: MessageEntry[] = this.state.listEntity!.data.map((el) => ({
                ...el,
                isSelected: el.id === draggedMessage!.id,
            }));
            this.setListData(data);
        }
    };

    private handleEmailClick = async () => {
        const { handler, jobIds } = this.props;
        const jobId = jobIds.length === 1 ? jobIds[0] : 0;
        const response = await handler?.getJobForwardAddress(jobId);

        if (response !== undefined) {
            this.setState({ currentPrompt: "forwardIntoBT", emailForwardAddress: response.email });
        }
    };

    private handleMessageSelected = async (selectedMessageId: number) => {
        this.setState({
            savedScrollPos: saveScrollPosition(),
        });
        // Reset scroll to the top while viewing message details
        scrollToPosition({ PosY: 0, PosX: 0 });
        this.props.history.push(
            routes.messages.getMessageDetailsLink(selectedMessageId, this.props.folderId)
        );
    };

    render() {
        const { entityType, jobIds, jobName, folderId, messageId } = this.props;
        const {
            filterEntity,
            isLoading,
            hideFolders,
            hideList,
            listEntity,
            folders,
            isEmptyStateBannerDismissed,
            actionBeingPerformed,
            lastUpdatedDate,
            previousSortData,
        } = this.state;

        const jobId = jobIds.length === 1 ? jobIds[0] : 0;
        const selectedItems = getSelectedItems(listEntity && listEntity.data);

        const selectedIds = selectedItems.map((item) => item.id);
        const noSelectedIds = selectedIds.length === 0 || Boolean(messageId);

        let checkedActionButtons = <></>;
        if (listEntity) {
            const restrictedActions = [
                EmailFolder.Drafts,
                EmailFolder.Sent,
                EmailFolder.Trash,
            ].includes(folderId);
            checkedActionButtons = (
                <>
                    <CheckedActions
                        count={selectedItems.length}
                        onUnselectAll={this.handleUnselectAll}
                    />
                    {!restrictedActions && listEntity.canUpdate && (
                        <BTButton
                            data-testid="moveMessage"
                            onClick={() => this.setState({ currentPrompt: "moveMessage" })}
                            disabled={noSelectedIds}
                        >
                            Move
                        </BTButton>
                    )}
                    {listEntity.canDelete && (
                        <BTButton
                            data-testid="deleteMessage"
                            onClick={() => this.handleDeleteMessages(selectedIds)}
                            disabled={noSelectedIds}
                            hotkey={{ hotkey: "delete", popoverPlacement: "bottom" }}
                        >
                            Delete
                        </BTButton>
                    )}
                    {!restrictedActions && listEntity.canUpdate && (
                        <BTButton
                            data-testid="markRead"
                            onClick={() => this.handleMarkReadOrUnread(selectedIds, false)}
                            disabled={noSelectedIds}
                        >
                            Mark as Read
                        </BTButton>
                    )}
                    {!restrictedActions && listEntity.canUpdate && (
                        <BTButton
                            data-testid="markUnread"
                            onClick={() => this.handleMarkReadOrUnread(selectedIds, true)}
                            disabled={noSelectedIds}
                        >
                            Mark as Unread
                        </BTButton>
                    )}
                </>
            );
        }

        const modalConfig = {
            beforeClose: this.handleBeforeClose,
            parentRoute: this.props.match.url,
        };

        const handleAddNew = () => {
            this.props.history.push(
                this.props.match.url + routes.messages.getComposeLink(0, jobId)
            );
        };

        const listActions = (
            <>
                {noSelectedIds && (
                    <div className="ListActions MessageListActions">
                        {listEntity?.canAdd && (
                            <BTLinkRelative
                                useAutoSPARouting
                                to={routes.messages.getComposeLink(0, jobId)}
                                hotkey={{ hotkey: "new", popoverPlacement: "bottom" }}
                            >
                                <BTButton data-testid="newMessage" type="new">
                                    Compose New Message
                                </BTButton>
                            </BTLinkRelative>
                        )}
                        {filterEntity && (
                            <FilterDrawer
                                id={entityType}
                                entity={filterEntity}
                                onSubmit={this.handleFilterSubmit}
                                filterTypeEntity={getFilterEntityType(entityType)}
                                onSavedFiltersUpdated={(entity: FilterEntity) =>
                                    this.setState({ filterEntity: entity })
                                }
                            />
                        )}
                        {jobId > 0 && (
                            <ShowOnPortal
                                builder
                                render={() => (
                                    <BTButton
                                        data-testid="forwardIntoBT"
                                        onClick={this.handleEmailClick}
                                    >
                                        Forward Email into Buildertrend
                                    </BTButton>
                                )}
                            />
                        )}
                        {!messageId && (
                            <>
                                <BTButton
                                    data-testid="refreshFolder"
                                    type="secondary"
                                    onClick={() => this.refreshAll(true)}
                                    icon={<BTIconReloadOutlined />}
                                >
                                    Refresh
                                </BTButton>
                                {lastUpdatedDate && (
                                    <div className="LastUpdated">
                                        {"Last updated at "}
                                        <DateDisplay
                                            data-chromatic="ignore"
                                            showDate={false}
                                            showTime
                                            value={lastUpdatedDate}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
                {!noSelectedIds && (
                    <div className="ListActions CheckedActions">{checkedActionButtons}</div>
                )}
            </>
        );

        const showOutOfOfficeBanner =
            listEntity &&
            listEntity.listMetadata.hasData &&
            !listEntity.listMetadata.emptyStatesMetadata.isNewToEntity &&
            this.state.isOutOfOffice;
        const folder = folders.find((x) => x.folderId === folderId);
        const folderName = folder ? folder.name : "Inbox";

        return (
            <BTListLayout
                className={classNames({ MessageDetailsPrint: messageId !== undefined })}
                title="Messages"
                listActions={listActions}
                jobName={jobName}
                dangerousDoNotUseIExpectAPassbackPageMinWidth={800}
            >
                {showOutOfOfficeBanner && (
                    <ListSection className="padding-bottom-lg">
                        <OutOfOfficeBanner />
                    </ListSection>
                )}
                {listEntity && listEntity.listMetadata && (
                    <ShowOnPortal
                        builder={listEntity.listMetadata.emptyStatesMetadata.isNewToEntity}
                        render={() => (
                            <ListSection className="padding-bottom-md">
                                <MessageEmptyStateBanner
                                    isNewToEntity={
                                        listEntity.listMetadata.emptyStatesMetadata.isNewToEntity
                                    }
                                    isReadonly={
                                        listEntity.listMetadata.emptyStatesMetadata.isReadOnly
                                    }
                                    hasListData={listEntity.listMetadata.hasData}
                                    onCallToActionClick={handleAddNew}
                                    isDismissed={isEmptyStateBannerDismissed}
                                    onDismiss={this.handleDismissEmptyStateBanner}
                                    actionBeingPerformed={undefined}
                                />
                            </ListSection>
                        )}
                    />
                )}
                <ListSection className="MessageList">
                    <BTRow style={{ flexWrap: "nowrap" }}>
                        {!hideFolders && (
                            <BTCol className="MessageFoldersWrapper">
                                <MessageListFoldersWrapper
                                    key={this.state.isFolderEditMode ? "edit" : "view"}
                                    folders={this.state.folders}
                                    selectedFolder={folderId}
                                    isEditMode={this.state.isFolderEditMode}
                                    actionBeingPerformed={actionBeingPerformed}
                                    onChangeFolder={this.handleChangeFolder}
                                    onSaveFolders={this.handleSaveFolders}
                                    onManageFolders={this.handleManageFolders}
                                />
                            </BTCol>
                        )}
                        <BTCol
                            data-testid="messageDetails"
                            flex={1}
                            style={{ overflow: "visible" }}
                        >
                            {Boolean(messageId) && (
                                <MessageDetails
                                    messageId={messageId!}
                                    folderId={folderId}
                                    history={this.props.history}
                                    match={this.props.match}
                                    location={this.props.location}
                                    backButton={
                                        <BTButton
                                            type="link"
                                            data-testid="backToList"
                                            onClick={this.handleBackToList}
                                            icon={<BTIconArrowLeftOutlined />}
                                        >
                                            Back to {folderName}
                                        </BTButton>
                                    }
                                />
                            )}

                            {!messageId && folderId !== EmailFolder.None && (
                                <>
                                    {isLoading && <BTLoading />}
                                    {!hideList && listEntity && listEntity.listMetadata && (
                                        <>
                                            {listEntity.records > 0 && (
                                                <>
                                                    <MessageListTable
                                                        {...this.props}
                                                        messages={listEntity.data}
                                                        jobIds={jobIds}
                                                        selectedFolder={folderId}
                                                        onItemChange={this.handleItemChange}
                                                        onSelectAll={this.setListData}
                                                        onSortUpdated={this.handleSortUpdated}
                                                        onSetList={this.setListData}
                                                        onMoveMessageDrop={
                                                            this.handleMoveMessageDrop
                                                        }
                                                        onDragStart={this.handleDragStart}
                                                        sortData={previousSortData}
                                                        onMessageSelected={
                                                            this.handleMessageSelected
                                                        }
                                                    />
                                                    <BTListLayoutWhitespace />
                                                    <ListActionBar sticky="bottom">
                                                        <BTRow justify="end" align="middle">
                                                            <BTPagination
                                                                entityType={entityType}
                                                                pagingData={listEntity.pagingData}
                                                                onChange={this.handlePagingChange}
                                                                id="MessagesPagingBottom"
                                                            />
                                                        </BTRow>
                                                    </ListActionBar>
                                                </>
                                            )}
                                            <BTListLayoutWhitespace>
                                                <MessageEmptyState
                                                    hasListData={listEntity.listMetadata.hasData}
                                                    hasFilteredData={
                                                        listEntity.listMetadata.emptyStatesMetadata
                                                            .hasFilteredData
                                                    }
                                                    isReadonly={
                                                        listEntity.listMetadata.emptyStatesMetadata
                                                            .isReadOnly
                                                    }
                                                    onCallToActionClick={handleAddNew}
                                                    helpLink={
                                                        listEntity.listMetadata.emptyStatesMetadata
                                                            .helpLink
                                                    }
                                                />
                                            </BTListLayoutWhitespace>
                                        </>
                                    )}
                                </>
                            )}
                        </BTCol>
                    </BTRow>
                </ListSection>
                <BTListLayoutWhitespace />
                <RouteMessageCompose modalConfig={modalConfig} />
                <RouteInternalUser modalConfig={modalConfig} />
                {this.state.currentPrompt === "forwardIntoBT" &&
                    this.state.emailForwardAddress !== "" && (
                        <BTEmailForwardModal
                            title="Forwarding Emails into your Buildertrend job is as easy as 1, 2, 3"
                            emailAddress={this.state.emailForwardAddress}
                            onClose={() =>
                                this.setState({ currentPrompt: undefined, emailForwardAddress: "" })
                            }
                        />
                    )}
                {this.state.currentPrompt === "moveMessage" && (
                    <MoveToFolderModal
                        onOkay={(id) =>
                            this.handleMoveToFolder(
                                id,
                                selectedItems.map((val) => val.id)
                            )
                        }
                        currentFolderId={folderId}
                        beforeClose={() =>
                            this.setState({ currentPrompt: undefined, emailForwardAddress: "" })
                        }
                        centered
                    />
                )}
            </BTListLayout>
        );
    }
}

export function getMessageListPropsFromRoute(
    reactRouterProps: RouteComponentProps<{
        folder: string;
        messageId?: string;
    }>
) {
    const systemFilter = getSystemFilter(reactRouterProps);
    let folderId: number;
    const folder = reactRouterProps.match.params.folder;

    if (folder in EmailFolder) {
        folderId = EmailFolder[folder as keyof typeof EmailFolder];
    } else {
        folderId = Number(folder);
        if (isNaN(folderId)) {
            throw new Error(`Invalid folderId: ${folder}`);
        }
    }

    let messageId: number | undefined;

    if (reactRouterProps.match.params.messageId) {
        const parsedId = Number(reactRouterProps.match.params.messageId);
        messageId = Number.isNaN(parsedId) ? undefined : parsedId;
    } else {
        messageId = undefined;
    }
    return {
        folderId,
        messageId,
        systemFilter,
    };
}

const MessageListWithJobs = withJobsitesRequired("Messages")(MessageListInternal);

export default withErrorBoundary(MessageListWithJobs)("Could not load Messages");
