import moment from "moment";

import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";
import { LinkedScheduleType } from "types/enum";

import { getReadOnly } from "utilities/filters/filter";

export interface IHasLinkedScheduleItem extends IBaseEntity {
    linkToScheduleItemEntity: LinkToScheduleItemEntity;
}

export class LinkToScheduleItemFormValues implements ILinkToScheduleItemFormValues {
    constructor(entity: LinkToScheduleItemEntity, defaultDate?: moment.Moment) {
        this.isDeadlineLinked = entity.isDeadlineLinked;
        this.deadlineOffset = entity.deadlineOffset;
        this.deadlineIsAfterLinkedItem = entity.deadLineIsAfterLinkedItem;

        const selectedValue = entity.scheduleItemDropdownOptions.find((value) => value.selected);
        if (selectedValue) {
            this.scheduleItemSelectedValue = selectedValue.value;
        } else {
            this.scheduleItemSelectedValue = -1;
        }
        if (entity.submittedDate !== undefined) {
            this.dueDate = entity.submittedDate;
            this.dueTime = entity.submittedDate;
        } else {
            this.dueDate =
                entity.deadline === undefined && defaultDate ? defaultDate : entity.deadline;
            this.dueTime = entity.dueTime;
        }
    }

    isDeadlineLinked: boolean;
    deadlineOffset: number;
    deadlineIsAfterLinkedItem: boolean;
    scheduleItemSelectedValue: number | undefined;
    dueDate: moment.Moment | undefined;
    dueTime: moment.Moment | undefined;
}

export interface ILinkToScheduleItemFormValues {
    isDeadlineLinked: boolean;
    deadlineOffset: number;
    deadlineIsAfterLinkedItem: boolean;
    scheduleItemSelectedValue: number | undefined;
    dueDate: moment.Moment | undefined;
    dueTime: moment.Moment | undefined;
}

export interface ILinkToScheduleItemReadOnlyFields {
    isDeadlineLinkedReadOnly: boolean;
    scheduleItemDropdownOptionsReadOnly: boolean;
    deadLineIsAfterLinkedItemReadOnly: boolean;
    deadlineOffsetReadOnly: boolean;
    deadlineReadOnly: boolean;
    dueTimeReadOnly: boolean;
}

export interface ILinkToSchedulePermissions {
    canViewSchedules: boolean;
    canAddSchedules: boolean;
    canEditSchedules: boolean;
}

export interface ILinkToScheduleItem {
    isDeadlineLinked: boolean;
    scheduleItemDropdownOptions: BTSelectItem<ILinkToScheduleItemScheduleExtraData>[];
    deadLineIsAfterLinkedItem: boolean;
    deadlineOffset: number;
    deadline: moment.Moment | undefined;
    deadlineMinDate: moment.Moment | undefined;
    dueTime: moment.Moment | undefined;
    userSelectedDefaultDeadline: boolean;
    submittedDate: moment.Moment | undefined;
    readOnlyFields: ILinkToScheduleItemReadOnlyFields;
    permissions: ILinkToSchedulePermissions;
}

export class LinkToScheduleItemEntity implements ILinkToScheduleItem {
    constructor(data: any) {
        this.isDeadlineLinked = data.isDeadlineLinked.value;
        this.scheduleItemDropdownOptions = mapScheduleItemDropdownOptions(
            data.deadlineLinked.value
        );
        this.deadLineIsAfterLinkedItem =
            data.deadLineBeforeOrAfter.value.find((value: { selected: boolean }) => value.selected)!
                .id === 1;
        this.deadlineOffset = data.deadLineOffset.value;
        if (data.deadLine?.value) {
            this.deadline = moment(data.deadLine.value);
        }
        this.deadlineMinDate = moment(
            (
                data.deadLine.validators.find(
                    (validator: { type: string }) => validator.type === "minDate"
                ) || {}
            ).value
        );
        if (data.dueTime?.value) {
            this.dueTime = moment(data.dueTime.value);
        }
        this.submittedDate =
            data.submittedDate !== undefined ? moment(data.submittedDate) : undefined;

        this.userSelectedDefaultDeadline = data.deadLine.extraData.userSelectedDefaultTime;

        this.readOnlyFields = {
            isDeadlineLinkedReadOnly: getReadOnly(data.isDeadlineLinked.validators),
            scheduleItemDropdownOptionsReadOnly: getReadOnly(data.deadlineLinked.validators),
            deadLineIsAfterLinkedItemReadOnly: getReadOnly(data.deadLineBeforeOrAfter.validators),
            deadlineOffsetReadOnly: getReadOnly(data.deadLineOffset.validators),
            deadlineReadOnly: getReadOnly(data.deadLine.validators),
            dueTimeReadOnly: data.dueTime && getReadOnly(data.dueTime.validators),
        };
        this.permissions = {
            canAddSchedules: data.canAddSchedules,
            canEditSchedules: data.canEditSchedules,
            canViewSchedules: data.canViewSchedules,
        };
    }

    isDeadlineLinked: boolean;
    scheduleItemDropdownOptions: BTSelectItem<ILinkToScheduleItemScheduleExtraData>[];
    deadLineIsAfterLinkedItem: boolean;
    deadlineOffset: number;
    deadline: moment.Moment | undefined;
    deadlineMinDate: moment.Moment | undefined;
    dueTime: moment.Moment | undefined;
    userSelectedDefaultDeadline: boolean;
    submittedDate: moment.Moment | undefined;
    readOnlyFields: ILinkToScheduleItemReadOnlyFields;
    permissions: ILinkToSchedulePermissions;
}

export interface ILinkToScheduleItemScheduleExtraData {
    itemStartDate: string | null;
    itemEndDate: string | null;
    detailText: string | null;
}

export interface ILinkToScheduleItemUpdateRequest {
    entityId: number;
    jobId: number;
    linkedType: LinkedScheduleType;
    isFromTemplate: boolean;
}

export function mapScheduleItemDropdownOptions(
    data: any
): BTSelectItem<ILinkToScheduleItemScheduleExtraData>[] {
    const scheduleItemDropdownOptions: BTSelectItem<ILinkToScheduleItemScheduleExtraData>[] =
        data.map((i: { id: number; name: string; selected: boolean; extraData: any }) => {
            return new BTSelectItem<ILinkToScheduleItemScheduleExtraData>({
                id: i.id,
                name: i.name,
                selected: i.selected,
                extraData: i.extraData,
            });
        });

    // add an unselected option if one doesn't come from the response, or if the list is empty.
    const unselectedOption = new BTSelectItem<ILinkToScheduleItemScheduleExtraData>({
        id: -1,
        name: "-- Select Schedule Item --",
        selected: false,
        extraData: undefined,
    });

    if (scheduleItemDropdownOptions.length === 0) {
        unselectedOption.selected = true;
        scheduleItemDropdownOptions.push(unselectedOption);
    } else if (!scheduleItemDropdownOptions.some((s) => parseInt(s.id) === -1)) {
        scheduleItemDropdownOptions.unshift(unselectedOption);
    }
    return scheduleItemDropdownOptions;
}
