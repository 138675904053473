import { ISocialMediaButtonsMediaInfo } from "commonComponents/btWrappers/BTSocialMediaButtons/SocialMediaButtons";

export const MediaModalDebounceDelay = 500;

export enum MediaModalType {
    image = "image",
    image360 = "image360",
    video = "video",
}

export interface IMediaData {
    fileName: string;
    source: string;
    mediaInfo?: ISocialMediaButtonsMediaInfo;
    sourceWithDrawings?: string;
    type: MediaModalType;
}
