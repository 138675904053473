import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IMediaReplaceProps } from "entity/media/MediaReplace/MediaReplace";

const MediaReplace = lazyLoadWithRetry(() => import("entity/media/MediaReplace/MediaReplace"));

export const RouteMediaReplace = (
    props: Omit<
        IMediaReplaceProps,
        "documentInstanceId" | "isFromMediaPage" | "mediaType" | "history" | "location" | "match"
    >
) => (
    <RouteRelative
        path={routes.media.replace}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <MediaReplace
                        {...props}
                        {...routeProps}
                        documentInstanceId={parseInt(routeProps.match.params.documentInstanceId)}
                        isFromMediaPage={routeProps.match.params.isFromMediaPage === "true"}
                        mediaType={parseInt(routeProps.match.params.mediaType)}
                    />
                </BTSuspense>
            );
        }}
    />
);
