import { Card } from "antd";
import classNames from "classnames";
import { FunctionComponent, memo, ReactNode, useState } from "react";

import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";

import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { PageSectionCollapsible } from "commonComponents/utilities/PageSectionCollapsible/PageSectionCollapsible";
import {
    Sticky,
    StickyCurrentLevelProvider,
    useStickyHeight,
} from "commonComponents/utilities/StickyContext";

import "./PageSection.less";

interface IPageSectionFooter {
    isSticky: boolean;
    children: ReactNode;
}
const PageSectionFooter: FunctionComponent<IPageSectionFooter> = ({ isSticky, children }) => {
    if (isSticky) {
        return (
            <Sticky className="PageSectionFooter" level={100} direction="bottom">
                {children}
            </Sticky>
        );
    }
    return <div className="PageSectionFooter">{children}</div>;
};

export interface IPageSectionProps {
    className?: string;
    extra?: React.ReactNode;

    /**
     * Set true to align the extra content in the title area in a flex layout.
     */
    flexExtra?: boolean;

    isExpanded?: boolean;
    style?: React.CSSProperties;
    title?: React.ReactNode;

    /**
     * Indicates if the Page Section header should be collapsible.
     *
     * Note: Sticky headers are not currently supported on collapsible headers.
     */
    isCollapsible?: boolean | null;

    /**
     * Indicates if the Page Section Header should stick to the bottom of the current
     * StickyHeaderContext stack. Provide the height of the Page Section Header if
     * nested elements also need to be added to the StickyHeaderContext stack.
     *
     * @default false
     */
    isStickyHeader?: boolean;

    /**
     * Indicates if body padding should be removed.
     *
     * @default false
     */
    removeBodyPadding?: boolean;

    /**
     * Indicates if the Page Section Footer should stick to the bottom of the current
     * StickyHeaderContext stack.
     *
     * @default undefined
     */
    footer?: React.ReactNode;
    /**
     * Indicates if the footer should be sticky
     *
     * @default false
     */
    isStickyFooter?: boolean;
}

const PageSectionInternal: React.FunctionComponent<IPageSectionProps> = ({
    children,
    className,
    isCollapsible,
    isStickyHeader = false,
    isStickyFooter = false,
    footer,
    removeBodyPadding = false,
    title,
    flexExtra = false,
    ...otherProps
}) => {
    const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);
    const [, setTitleDivRef] = useResizeObserver((entry) => {
        const titleDiv = entry?.target;
        if (isStickyHeader && titleDiv) {
            const defaultHeaderHeight = 48;
            setStickyHeaderHeight(
                titleDiv.closest(".ant-card-head")?.clientHeight ?? defaultHeaderHeight
            );
        }
    });
    const top = useStickyHeight(stickyHeaderHeight, "top", 100);

    if (isCollapsible) {
        return (
            <PageSectionCollapsible
                className={className}
                removeBodyPadding={removeBodyPadding}
                title={title}
                {...otherProps}
            >
                {children}
            </PageSectionCollapsible>
        );
    }

    const pageSectionClass = classNames("PageSection", className, {
        removeBodyPadding: removeBodyPadding,
        StickyLayoutHeader: isStickyHeader,
        hasStickyFooter: footer && isStickyFooter,
        flexExtra,
    });

    let { extra } = otherProps;
    let titleElement: ReactNode = title;
    if (title || flexExtra) {
        if (typeof title === "string" && title !== "") {
            titleElement = <BTTitle level={3}>{title}</BTTitle>;
        }
        if (flexExtra) {
            titleElement = (
                <>
                    {titleElement ?? <span />}
                    {extra}
                </>
            );
            extra = undefined;
        }
        titleElement = <div ref={setTitleDivRef}>{titleElement}</div>;
    }

    return (
        // eslint-disable-next-line react/forbid-elements
        <Card
            bordered={false}
            className={pageSectionClass}
            headStyle={isStickyHeader ? { top } : undefined}
            {...otherProps}
            title={titleElement}
            extra={extra}
        >
            {children}
            {footer && <PageSectionFooter isSticky={isStickyFooter}>{footer}</PageSectionFooter>}
        </Card>
    );
};

export const PageSection: React.FunctionComponent<IPageSectionProps> = memo<IPageSectionProps>(
    (props) => {
        return (
            <StickyCurrentLevelProvider direction="top" level={100}>
                <PageSectionInternal {...props} />
            </StickyCurrentLevelProvider>
        );
    }
);
