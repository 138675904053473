import { message } from "antd";
import { Component, useContext } from "react";

import { FilterValueContext } from "helpers/globalContext/FilterValueContext";

import { BTLocalStorage } from "types/btStorage";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { reportError } from "utilities/errorHelpers";
import { setLoadingAction } from "utilities/form/form";
import { isInPortal } from "utilities/portal/portal";

import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import { applySerializeBehavior, prepareFiltersForSubmission } from "entity/filters/filters.utils";
import {
    ISavedFilterHandler,
    SavedFilterHandler,
} from "entity/filters/SavedFilter/SavedFilter.api.handler";
import {
    ISavedFilterFormValues,
    SavedFilterItem,
} from "entity/filters/SavedFilter/SavedFilter.api.types";

import {
    FilterEntity,
    FilterEntityType,
    FilterFormActions,
    IFilterFormValues,
    SelectedFilterItem,
    StandardFilterId,
} from "./Filter.api.types";
import { FilterPresentational } from "./FilterPresentational";

interface IFilterInternalProps {
    /** Filter id for saving/retrieval */
    id: number;

    filterTypeEntity: FilterEntityType;

    /** Entity object representing the filters */
    entity: FilterEntity;

    /** Selected Saved Filter ID */
    selectedFilterId?: number;

    /**
     * Initial filter values override from browser storage for Job Picker. Other filter components should
     * reset to selected saved filter on reload, but Job Picker filter is maintained.
     *
     * @warn DO NOT USE OUTSIDE JOB PICKER
     */
    jobPickerFilterOverride?: SelectedFilterItem[];

    /** Submit Action. Also called with initial values when Reset is clicked */
    onSubmit: (values: IFilterFormValues, actionBeingPerformed: FilterFormActions) => Promise<void>;

    // if showSaveButton is true, then this should never be null
    onSavedFiltersUpdated?: (entity: FilterEntity) => void;

    /**
     * When true, Filter component will be laid out for popup (e.g. for Job Picker):
     * * Filter is not collapsible
     * * Grid layout will be adjusted for smaller area
     *
     * NOTE: its parent should be at least 400px wide
     * @default false
     */
    isInPopup?: boolean;

    /**
     * When true, "Save Filter" button will be shown on Filter component
     *
     * @default isInPortal({ builder: true, btadmin: true })
     */
    showSaveButton?: boolean;

    /**
     * When true, will show the saved filter dropdown
     * @default isInPortal({ builder: true, btadmin: true })
     */
    showSavedFilters?: boolean;

    savedFilterHandler?: ISavedFilterHandler;

    /**
     * Callback to track whether the saved filters are visible. This is only necessary when we are in a popover
     * TAGS: antd - ant design - version - version 4
     * This is necessary due to a bug in And which was fixed in V4 where the popover automatically becomes hidden, which removes it from the react stack (or something like that) so updates in the child modal do not propogate
     * This is used to prevent the popover from closing when this modal is visible
     */
    onSavedFilterVisibleChange?: (visible: boolean) => void;

    /**
     * DO_NOT_USE
     * Use the alternate drawer layout. Don't use this directly -- use the FilterDrawer component instead.
     */
    useDrawer?: boolean;
    drawerOpen?: boolean;
    setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    jobIDs?: number[];
    filterCount?: number;
    setFilterCount?: React.Dispatch<React.SetStateAction<number>>;
    /**
     * Callback to determine if the filter field should be disabled
     * @param filterField The current filter field being evaluated
     * @param filterValues All selected filter values
     */
    handleDisableFilterField?: (
        filterField: SelectedFilterItem,
        filterValues: SelectedFilterItem[]
    ) => boolean;
}

export interface IFilterUnmanagedProps {
    appliedFilter?: SelectedFilterItem[];
    setUnsavedAppliedFilter?: (items: SelectedFilterItem[], isChanged: boolean) => void;
    showHasUnsavedChanges: boolean;
    appliedFiltersFromSavedFilter: boolean;
    updateUnsavedChanges?: (
        newFilters: SelectedFilterItem[],
        filterEntity: FilterEntity,
        savedFilter: SavedFilterItem
    ) => void;
    shouldUpdateFilterValueContext?: boolean;
}

interface IFilterState {
    actionBeingPerformed: FilterFormActions;
    selectedFilterId?: number;
}

class FilterInternal extends Component<IFilterInternalProps & IFilterUnmanagedProps, IFilterState> {
    static contextType = FilterValueContext;
    context: React.ContextType<typeof FilterValueContext>;

    static defaultProps = {
        isInPopup: false,
        showSaveButton: isInPortal({ builder: true, btadmin: true }),
        showSavedFilters: isInPortal({ builder: true, btadmin: true }),
        savedFilterHandler: new SavedFilterHandler(),
        persist: true,
        showHasUnsavedChanges: false,
        appliedFiltersFromSavedFilter: false,
        shouldUpdateFilterValueContext: true,
    };

    state: Readonly<IFilterState> = {
        actionBeingPerformed: undefined,
    };

    /**
     * loads the user preference to detect if the filters should be opened or closed on initial load
     */
    private loadUserFilterPreference = (): boolean => {
        const allFilterPreferences = BTLocalStorage.get("bt-object-filterPreferences");
        const currentFilterPreference = allFilterPreferences[this.props.id];

        // default filter to closed if no preference exists
        return currentFilterPreference ?? false;
    };

    private onSubmit = async (values: IFilterFormValues) => {
        await setLoadingAction<FilterFormActions>(this, {
            actionBeingPerformed: "update",
            callback: async () => {
                try {
                    await this.submitFilters(values);
                    this.props.updateUnsavedChanges?.(
                        // This is the updated form state version of the filters.
                        values.items,
                        this.props.entity,
                        values.savedFilter
                    );
                } catch (e) {
                    reportError(e, {
                        internalNotes: "An un-handled error was thrown while updating filters",
                    });
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    private setFilterPreferences = (isPanelOpen: boolean) => {
        const allFilterPreferences = BTLocalStorage.get("bt-object-filterPreferences");

        BTLocalStorage.set("bt-object-filterPreferences", {
            ...allFilterPreferences,
            [this.props.id]: isPanelOpen,
        });
    };

    private handleSavedFilterAddedOrUpdated = async (
        id: number,
        savedFilterValues: ISavedFilterFormValues,
        values: IFilterFormValues,
        canEdit: boolean = true
    ) => {
        await setLoadingAction<FilterFormActions>(this, {
            actionBeingPerformed: "saveFilter",
            callback: async () => {
                const { entity } = this.props;
                const newFilterItem = new SavedFilterItem({
                    id,
                    isDefault: savedFilterValues.isDefault,
                    isMobileDefault: false,
                    isOwner: true,
                    canEdit,
                    isPrivate: savedFilterValues.isPrivate,
                    name: savedFilterValues.filterName,
                    value: savedFilterValues.value,
                    nameAndUpdatedBy: savedFilterValues.filterName,
                });

                let filterExists = false;
                let defaultFilterExists = false;
                let updatedFilters = entity.savedFilters.map((x) => {
                    if (x.id === id) {
                        filterExists = true;
                        newFilterItem.isOwner = x.isOwner;
                        return newFilterItem;
                    }
                    if (savedFilterValues.isDefault) {
                        // New one is default, so make sure we set the old ones to not be default.
                        x.isDefault = false;
                    }
                    if (savedFilterValues.isDefault || x.isDefault) {
                        defaultFilterExists = true;
                    }
                    return x;
                });

                if (!filterExists) {
                    updatedFilters = [newFilterItem].concat(updatedFilters);
                }
                if (!defaultFilterExists) {
                    // If there isn't a default filter, make the StandardFilter default
                    let standardFilter = entity.savedFilters.find((x) => x.id === StandardFilterId);
                    if (standardFilter) {
                        standardFilter.isDefault = true;
                    }
                }

                const selectedFilterItem =
                    this.props.appliedFilter ??
                    values.items.map((item) => new SelectedFilterItem(item));
                this.props.setUnsavedAppliedFilter?.(selectedFilterItem, false);
                this.props.onSavedFiltersUpdated!({ ...entity, savedFilters: updatedFilters });
            },
        });
    };

    private handleSavedFilterDeleted = async (
        id: number,
        newValues: IFilterFormValues,
        wasDefaultDeleted?: boolean
    ) => {
        try {
            await this.props.savedFilterHandler!.delete(id);
            const filterDeletedToast = wasDefaultDeleted
                ? "Filter deleted. Standard filter is now set as default."
                : "Filter deleted";
            void message.success(filterDeletedToast);
            void this.submitFilters(newValues);
            this.props.onSavedFiltersUpdated!({
                ...this.props.entity,
                savedFilters: this.props.entity.savedFilters.filter((x) => x.id !== id),
            });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private handleSetDefaultFilter = async (newDefault: SavedFilterItem) => {
        try {
            const { entity } = this.props;
            await this.props.savedFilterHandler!.setUserDefault(
                newDefault.id,
                this.props.filterTypeEntity
            );
            void message.success(`${newDefault.name} was set as your default filter`);
            let updatedFilters = entity.savedFilters.map((x) => {
                if (x.isDefault) {
                    // New one is default, so make sure we set the old ones to not be default.
                    x.isDefault = false;
                }
                if (x.id === newDefault.id) {
                    x.isDefault = true;
                }
                return x;
            });
            this.props.onSavedFiltersUpdated!({
                ...this.props.entity,
                savedFilters: updatedFilters,
            });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    // Making use of the existing update endpoint to update filter values
    private handleUpdateFilterValueOnly = async (id: number, newValues: ISavedFilterFormValues) => {
        try {
            const nonSpecialJobs = this.props.jobIDs?.filter((j) => j > 0);
            const jobID = nonSpecialJobs?.length === 1 ? nonSpecialJobs[0] : null;
            await this.props.savedFilterHandler!.update(id, newValues, jobID);
            void message.success(`${newValues.filterName} has been updated`);
            let updatedFilters = this.props.entity.savedFilters.map((filter) => {
                if (filter.id !== id) {
                    return filter;
                } else {
                    return new SavedFilterItem({ ...filter, value: newValues.value });
                }
            });
            this.props.onSavedFiltersUpdated!({
                ...this.props.entity,
                savedFilters: updatedFilters,
            });
            const selectedFilterItem = this.context?.appliedFilterItem ?? this.props.appliedFilter;
            this.props.setUnsavedAppliedFilter?.(selectedFilterItem!, false);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private async submitFilters(values: IFilterFormValues) {
        const { entity, handleDisableFilterField, onSubmit, shouldUpdateFilterValueContext } =
            this.props;
        prepareFiltersForSubmission(values, entity, handleDisableFilterField);
        const transformedValues = applySerializeBehavior(entity, values);
        if (shouldUpdateFilterValueContext) {
            this.context?.setAppliedFilter({
                items: values.items,
                type: this.props.filterTypeEntity,
            });
        }
        await onSubmit(transformedValues, this.state.actionBeingPerformed);
    }

    // Sets the selected filter to state. This is overrided by a filterId passed in through props (Job picker)
    private setSelectedFilterId = (id: number) => {
        this.setState({
            selectedFilterId: id,
        });
    };

    render() {
        return (
            <FilterPresentational
                actionBeingPerformed={this.state.actionBeingPerformed}
                appliedFilter={this.props.appliedFilter}
                entity={this.props.entity}
                selectedFilterId={this.props.selectedFilterId ?? this.state.selectedFilterId}
                setSelectedFilterId={this.setSelectedFilterId}
                jobPickerFilterOverride={this.props.jobPickerFilterOverride}
                defaultOpen={this.loadUserFilterPreference()}
                setFilterPreferences={this.setFilterPreferences}
                onSubmit={this.onSubmit}
                filterTypeEntity={this.props.filterTypeEntity}
                onSavedFilterAdded={this.handleSavedFilterAddedOrUpdated}
                onSavedFilterDeleted={this.handleSavedFilterDeleted}
                onSavedFilterUpdated={this.handleSavedFilterAddedOrUpdated}
                onSavedFilterUpdatedClick={this.handleUpdateFilterValueOnly}
                isInPopup={this.props.isInPopup!}
                showSaveButton={this.props.showSaveButton!}
                showSavedFilters={this.props.showSavedFilters!}
                showHasUnsavedChanges={this.props.showHasUnsavedChanges}
                onSavedFilterVisibleChange={this.props.onSavedFilterVisibleChange}
                onSetDefaultFilter={this.handleSetDefaultFilter}
                useDrawer={this.props.useDrawer}
                drawerOpen={this.props.drawerOpen}
                setDrawerOpen={this.props.setDrawerOpen}
                jobIDs={this.props.jobIDs}
                isDefaultFromSavedFilter={this.props.appliedFiltersFromSavedFilter}
                filterCount={this.props.filterCount}
                setFilterCount={this.props.setFilterCount}
            />
        );
    }
}

export interface IFilterProps extends IFilterInternalProps {}

const FilterWrapper: React.FunctionComponent<IFilterInternalProps> = (props) => {
    const filterValueContext = useContext(FilterValueContext);

    return (
        <FilterInternal
            {...props}
            appliedFilter={filterValueContext?.appliedFilterItem}
            setUnsavedAppliedFilter={filterValueContext?.setUnsavedAppliedFilter}
            showHasUnsavedChanges={filterValueContext?.showHasUnsavedChanges}
            appliedFiltersFromSavedFilter={filterValueContext?.appliedFiltersFromSavedFilter}
            updateUnsavedChanges={filterValueContext?.updateUnsavedChanges}
        />
    );
};

export const FilterUnmanaged = withErrorBoundary(FilterInternal)("Could not load Filter");

export const Filter = withErrorBoundary(FilterWrapper)("Could not load Filter");
