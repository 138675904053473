import { Typography } from "antd";
import { LegacyMediaViewer } from "legacyComponents/LegacyMediaViewer";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { ReplyType } from "types/enum";

import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { btDeleteConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import {
    BTLayout,
    BTLayoutContent,
    BTLayoutHeader,
} from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTLink, BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import {
    stickyHeaderHeightActions,
    stickyHeaderHeightTitle,
    stickyListLevelActions as stickyListActionsLevel,
} from "commonComponents/btWrappers/BTListLayout/BTListLayoutHeader";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { PrintHide } from "commonComponents/print/PrintHide/PrintHide";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { CKEditorDisplay } from "commonComponents/utilities/CKEditorDisplay/CKEditorDisplay";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { NoteText } from "commonComponents/utilities/NoteText/NoteText";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import ReadMore from "commonComponents/utilities/ReadMore/ReadMore";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";

import {
    MessageDetailsEntity,
    MessageDetailsFolder,
    MessageDetailsFormActions,
} from "entity/message/common/Message.api.types";
import { formatRecipientNames } from "entity/message/common/message.utils";
import { MoveToFolderModal } from "entity/message/common/MoveToFolderModal";
import { RouteToDo } from "entity/toDo/details/ToDoDetails/RouteToDo";
import { IToDoCreationData } from "entity/toDo/details/ToDoDetails/ToDo.types";

import "./Message.less";

const { Text } = Typography;

interface IMessageDetailsProps extends RouteComponentProps {
    onMoveMessage: (messageIds: number[], sourceFolderId: number, destFolderId: number) => void;
    onMarkReadOrUnread: (messageIds: number[], unread: boolean) => void;
    onReplyForwardMessage: (
        jobId: number,
        replyId: number,
        title: string,
        replyType: ReplyType
    ) => Promise<void>;
    onDeleteMessage: (messageIds: number[], folderId: number) => void;
    entity: MessageDetailsEntity;
    actionBeingPerformed: MessageDetailsFormActions;
    currentFolder: MessageDetailsFolder;
    isGlobal: boolean;
    isSent: boolean;
    backButton?: React.ReactNode;
    modalConfig?: IModalConfiguration;
    todoCreationData?: IToDoCreationData;
    onTodoSave?: () => void;
}

class MessageDetailsInternal extends Component<IMessageDetailsProps> {
    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    /**
     * Function to handle rendering a MoveToFolderModal
     *
     * @returns {MoveToFolderModal} A MoveToFolderModal
     */
    private renderMoveToFolderModal = () => {
        return (
            <MoveToFolderModal
                beforeClose={this.handleModalClose}
                onOkay={this.handleMoveMessage}
                currentFolderId={this.props.currentFolder.folderId}
            />
        );
    };

    /**
     * Reroutes to the Message Details page
     */
    private handleModalClose = () => {
        this.props.history.replace(this.props.match.url);
    };

    /**
     * Function that handles moving the current message
     * @param {number} folderId - The folder to which the message is moved
     */
    private handleMoveMessage = async (folderId: number) => {
        this.props.onMoveMessage(
            [this.props.entity.emailMessageItem.messageId],
            this.props.entity.folderId!,
            folderId
        );

        this.handleModalClose();
    };

    /**
     * Function that toggles the current message's read status between read and unread
     */
    private handleMarkUnread = () => {
        this.props.onMarkReadOrUnread([this.props.entity.emailMessageItem.messageId], true);
    };

    /**
     * Function that handles deleting the current message
     */
    private handleDelete = () => {
        this.props.onDeleteMessage(
            [this.props.entity.emailMessageItem.messageId],
            this.props.entity.folderId!
        );
    };

    /**
     * Function that handles forwarding, replying, and reply all
     */
    private handleReplyForward = async (replyType: ReplyType) => {
        let url = routes.messages.getComposeLink(0, this.props.entity.emailMessageItem.jobId, {
            replyType,
            replyId: this.props.entity.emailMessageItem.messageId,
        });
        if (this.props.modalConfig) {
            url = this.props.modalConfig.parentRoute + url;
        } else {
            url = this.props.match.url + url;
        }

        this.props.history.replace(url);
    };

    /**
     * Function that shows a BTConfirm if delete is permanent
     */
    private checkPermanentDeleteStatus = () => {
        if (
            this.props.entity.emailMessageItem.isDeletePermanent ||
            this.props.currentFolder.deleteIsPermanent
        ) {
            btDeleteConfirm({
                entityName: "Message",
                content: "This message will be permanently removed.",
                onOk: this.handleDelete,
            });
        } else {
            this.handleDelete();
        }
    };

    render() {
        const { entity, actionBeingPerformed, isGlobal, isSent } = this.props;
        const labelColumns = { xs: 24, sm: 4, md: 2 };
        const dataColumns = { xs: 24, sm: 20, md: 22 };

        const footerButtons = (
            <>
                {!isGlobal && (
                    <>
                        <ShowOnPortal
                            builder
                            subs
                            render={() => (
                                <BTButton<MessageDetailsFormActions>
                                    type="primary"
                                    data-testid="replyButton"
                                    actionBeingPerformed={actionBeingPerformed}
                                    loadingAction="reply"
                                    onClick={() => this.handleReplyForward(ReplyType.Reply)}
                                >
                                    Reply
                                </BTButton>
                            )}
                        />
                        <ShowOnPortal
                            owner
                            render={() =>
                                !entity.isOwnerRestricted && (
                                    <BTButton<MessageDetailsFormActions>
                                        type="primary"
                                        data-testid="replyButton"
                                        actionBeingPerformed={actionBeingPerformed}
                                        loadingAction="reply"
                                        onClick={() => this.handleReplyForward(ReplyType.Reply)}
                                    >
                                        Reply
                                    </BTButton>
                                )
                            }
                        />
                        <BTButton<MessageDetailsFormActions>
                            type="secondary"
                            data-testid="replyAllButton"
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="replyAll"
                            onClick={() => this.handleReplyForward(ReplyType.ReplyAll)}
                        >
                            Reply All
                        </BTButton>
                        <BTButton<MessageDetailsFormActions>
                            type="secondary"
                            data-testid="forwardButton"
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="forward"
                            onClick={() => this.handleReplyForward(ReplyType.Forward)}
                        >
                            Forward
                        </BTButton>
                        {!isSent && (
                            <>
                                <BTLinkRelative
                                    to={routes.messages.getMoveMessageLink()}
                                    tabIndex={-1}
                                >
                                    <BTButton<MessageDetailsFormActions>
                                        type="secondary"
                                        actionBeingPerformed={actionBeingPerformed}
                                        loadingAction="move"
                                        data-testid="moveMessageButton"
                                    >
                                        Move Message
                                    </BTButton>
                                </BTLinkRelative>
                                <BTButton<MessageDetailsFormActions>
                                    type="secondary"
                                    actionBeingPerformed={actionBeingPerformed}
                                    loadingAction="markAsUnread"
                                    data-testid="markMessageButton"
                                    onClick={this.handleMarkUnread}
                                >
                                    Mark As Unread
                                </BTButton>
                            </>
                        )}
                        {entity.emailMessageItem.canDelete && (
                            <BTButton<MessageDetailsFormActions>
                                type="secondary"
                                onClick={this.checkPermanentDeleteStatus}
                                actionBeingPerformed={actionBeingPerformed}
                                loadingAction="delete"
                                data-testid="deleteButton"
                                hotkey={{
                                    hotkey: "delete",
                                    popoverPlacement: this.props.modalConfig ? "top" : "bottom",
                                }}
                            >
                                Delete
                            </BTButton>
                        )}
                    </>
                )}
                <BTLink
                    key="print"
                    shouldOpenNewTab
                    useAutoSPARouting
                    to={routes.messages.getPrintLink(
                        entity.emailMessageItem.messageId,
                        this.props.currentFolder.folderId
                    )}
                    hotkey={{
                        hotkey: "print",
                        popoverPlacement: this.props.modalConfig ? "top" : "bottom",
                    }}
                >
                    <BTButton<MessageDetailsFormActions>
                        type="secondary"
                        data-testid="printButton"
                        actionBeingPerformed={actionBeingPerformed}
                        loadingAction="print"
                    >
                        Print
                    </BTButton>
                </BTLink>
                {this.props.entity.canAddToDos && (
                    <ShowOnPortal
                        builder
                        render={() => (
                            <BTLinkRelative
                                to={routes.toDo.getDetailsLink(
                                    0,
                                    this.props.entity.emailMessageItem.jobId
                                )}
                            >
                                <BTButton<MessageDetailsFormActions>
                                    data-testid="newTodo"
                                    type="secondary"
                                    actionBeingPerformed={
                                        actionBeingPerformed as MessageDetailsFormActions
                                    }
                                    loadingAction="newToDo"
                                >
                                    New To-Do
                                </BTButton>
                            </BTLinkRelative>
                        )}
                    />
                )}
            </>
        );

        const btLayoutContent = (
            <>
                <MessageDetailsActionsWrapper>
                    <PrintHide>
                        {!this.props.modalConfig && (
                            <BTLayoutHeader isSticky={false}>
                                <div className="headerButtons">{footerButtons}</div>
                                {this.props.backButton}
                            </BTLayoutHeader>
                        )}
                    </PrintHide>
                </MessageDetailsActionsWrapper>
                <BTLayoutContent>
                    <PageSection
                        className="MessageDetails"
                        title={
                            <BTTitle level={1} className="MessageSubject">
                                <Text className="margin-right-xs" strong>
                                    Subject:
                                </Text>
                                {/* eslint-disable-next-line react/forbid-dom-props  */}
                                <div title={entity.emailMessageItem.subject}>
                                    {entity.emailMessageItem.subject}
                                </div>
                            </BTTitle>
                        }
                    >
                        <BTRow>
                            <BTCol {...labelColumns}>
                                <NoteText>
                                    <strong>From</strong>
                                </NoteText>
                            </BTCol>
                        </BTRow>
                        <BTRow {...dataColumns} className="padding-bottom-xs">
                            <ReadMore numberOfLines={1}>{entity.emailMessageItem.from}</ReadMore>
                        </BTRow>
                        <BTRow>
                            <BTCol {...labelColumns}>
                                <NoteText>
                                    <strong>To</strong>
                                </NoteText>
                            </BTCol>
                        </BTRow>
                        <BTRow className="padding-bottom-xs">
                            <ReadMore numberOfLines={2}>
                                {formatRecipientNames(entity.emailMessageItem.toRecipients)}
                            </ReadMore>
                        </BTRow>
                        <BTRow>
                            <BTCol {...labelColumns}>
                                <NoteText>
                                    <strong>CC</strong>
                                </NoteText>
                            </BTCol>
                        </BTRow>
                        <BTRow className="padding-bottom-xs">
                            <ReadMore numberOfLines={2}>
                                {formatRecipientNames(entity.emailMessageItem.ccRecipients)}
                            </ReadMore>
                        </BTRow>
                        {entity.emailMessageItem.bccRecipients.length > 0 && (
                            <ShowOnPortal
                                builder={entity.isByCurrentUser}
                                owner={false}
                                subs={false}
                                render={() => (
                                    <>
                                        <BTRow>
                                            <BTCol {...labelColumns}>
                                                <NoteText>
                                                    <strong>BCC</strong>
                                                </NoteText>
                                            </BTCol>
                                        </BTRow>
                                        <BTRow className="padding-bottom-xs">
                                            <ReadMore numberOfLines={2}>
                                                {formatRecipientNames(
                                                    entity.emailMessageItem.bccRecipients
                                                )}
                                            </ReadMore>
                                        </BTRow>
                                    </>
                                )}
                            />
                        )}
                        <BTRow className="sent-label-container">
                            <BTCol {...labelColumns}>
                                <NoteText>
                                    <strong>Sent</strong>
                                </NoteText>
                            </BTCol>
                        </BTRow>
                        <BTRow {...dataColumns}>
                            <DateDisplay value={entity.emailMessageItem.messageDate} showTime />
                        </BTRow>
                        {entity.emailMessageItem.body && (
                            <BTRow className="padding-top-lg padding-bottom-lg">
                                <CKEditorDisplay
                                    dangerouslySetInnerHTML={{
                                        __html: entity.emailMessageItem.body,
                                    }}
                                    width="100%"
                                />
                            </BTRow>
                        )}
                        {entity.attachedFiles.files.length !== 0 && (
                            <BTRow>
                                <BTCol span={24} style={{ marginLeft: "6px" }}>
                                    <LegacyMediaViewer
                                        entity={entity}
                                        hideTitle={false}
                                        isReadOnly={true}
                                    />
                                </BTCol>
                            </BTRow>
                        )}
                    </PageSection>
                    {actionBeingPerformed === "replyOrForward" && <BTLoading />}
                </BTLayoutContent>
            </>
        );

        const content = (
            <>
                <RouteToDo
                    modalConfig={{
                        beforeClose: this.handleModalClose,
                        parentRoute: this.props.match.url,
                    }}
                    prefilledFormValues={this.props.todoCreationData}
                    onSave={this.props.onTodoSave}
                    isFromMessage
                />
                <RouteRelative
                    path={routes.messages.moveMessage}
                    render={this.renderMoveToFolderModal}
                />
                {this.props.modalConfig ? btLayoutContent : <BTLayout>{btLayoutContent}</BTLayout>}
            </>
        );

        return !!this.props.modalConfig ? (
            <BTModalLayout
                title="View Message"
                footerContent={footerButtons}
                modalConfig={this.props.modalConfig}
            >
                {content}
            </BTModalLayout>
        ) : (
            content
        );
    }
}

const MessageDetailsActionsWrapper: React.FunctionComponent = (props) => {
    const stickyListActionsHeight = stickyHeaderHeightActions + stickyHeaderHeightTitle;
    const top = useStickyHeight(stickyListActionsHeight, "top", stickyListActionsLevel);
    return (
        <div className="StickyMessageDetailsActions" style={{ top }}>
            {props.children}
        </div>
    );
};

export const MessageDetailsPresentational = MessageDetailsInternal;
