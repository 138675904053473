import { AxiosHeaders, AxiosResponse } from "axios";
import moment from "moment";
import { RouteComponentProps } from "react-router";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { queryClient } from "helpers/queryClient";

import { BTLocalStorage } from "types/btStorage";

import { BTIconNotifications } from "commonComponents/btWrappers/BTIcon";
import { MainNavDropdown } from "commonComponents/utilities/MainNavigation/common/MainNavDropdown";
import { MainNavBadge } from "commonComponents/utilities/MainNavigation/MainNavActionItems/MainNavActionItems";
import {
    IBTSubMenuItemProps,
    menuItemClass,
} from "commonComponents/utilities/MainNavigation/MainNavigation.types";

import {
    HasNotificationResponse,
    useNotificationsHasNotificationsGetV2,
    useNotificationsViewedNotificationsPostV2,
} from "handlers";

import NotificationPanelV2 from "entity/notification/NotifticationPanelV2/NotificationPanelV2";

export const HasNotificationQueryKey = "hasNotifications";

interface INotificationMainNavButtonProps extends RouteComponentProps {
    subMenuProps: Omit<IBTSubMenuItemProps, "itemKey">;
}

function NotificationMainNavButton(props: INotificationMainNavButtonProps) {
    const appDefaultInfo = useRequiredContext(AppDefaultInfoContext);
    const unreadCountCacheTime = appDefaultInfo?.unreadNotificationCountCacheTimeout ?? 300;

    const initial: AxiosResponse<HasNotificationResponse> = {
        data: {
            hasNotifications: BTLocalStorage.get("bt-boolean-hasUnreadNotifications") ?? false,
        },
        status: 200,
        statusText: "OK",
        headers: new AxiosHeaders(),
        config: {
            headers: new AxiosHeaders(),
        },
    };
    const notifications = useNotificationsHasNotificationsGetV2({
        query: {
            queryKey: [HasNotificationQueryKey],
            retry: 3,
            // interval to check if count is stale and new unread count if needed
            refetchInterval: moment.duration(unreadCountCacheTime, "seconds").asMilliseconds(),
            // time that the query lasts in cache after being unmounted
            cacheTime: moment.duration(5, "minutes").asMilliseconds(),
            // once the stale time is reached the new unread count will be retrieved on the next refresh
            staleTime: moment.duration(unreadCountCacheTime, "seconds").asMilliseconds(),
            initialData: initial,
        },
    });

    const markNotificationsAsViewed = useNotificationsViewedNotificationsPostV2();

    const hasNotifications = notifications.data?.data?.hasNotifications ?? false;

    const handleOpenedPanel = async (visible: boolean) => {
        if (visible) {
            await markNotificationsAsViewed.mutateAsync();
            BTLocalStorage.set("bt-boolean-hasUnreadNotifications", false);
            await queryClient.invalidateQueries([HasNotificationQueryKey]);
        }
    };

    return (
        <MainNavDropdown
            {...props.subMenuProps}
            intercomTarget="Notification Icon"
            itemKey="notifications"
            data-testid="notifications-main-nav-button"
            aria-label="Notifications Menu"
            destroyPopupOnHide
            onVisibleChange={handleOpenedPanel}
            tooltip="Notifications"
            customMenuContent={
                <div className={menuItemClass}>
                    <NotificationPanelV2 hasNotifications={hasNotifications} {...props} />
                </div>
            }
            className="MainNavDropdown-icon-only"
        >
            <MainNavBadge dot={hasNotifications}>
                <BTIconNotifications />
            </MainNavBadge>
        </MainNavDropdown>
    );
}

export default NotificationMainNavButton;
