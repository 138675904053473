import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";

interface ICheckedActionsProps {
    count: number;
    onUnselectAll: () => void;
    children?: React.ReactNode;
}

export const CheckedActions: React.FunctionComponent<ICheckedActionsProps> = (props) => {
    return (
        <div className="flex flex-shrink-0">
            <div className="SelectedItemsBanner" data-testid="SelectedItemsBanner">
                {props.count} selected
            </div>
            <BTButton type="link" data-testid="cancel-checked" onClick={props.onUnselectAll}>
                Cancel
            </BTButton>
            {props.children}
        </div>
    );
};
