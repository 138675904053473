import { SelectionItem } from "antd/lib/table/interface";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import {
    BuilderFilterTypes,
    ClosedAccountTypes,
    DateRangeFilterOptionItem,
    FilterType,
    NumberRangeOptionItem,
} from "types/enum";

import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";

export type FilterFormActions = undefined | "update" | "saveFilter" | "reset";

export const FilterPanelKey: number = 1;

export const StandardFilterId: number = -1;

export type SelectedValueTypes =
    | string
    | number[]
    | number
    | SelectionItem
    | boolean
    | NumberRangeValue
    | KeywordValue
    | DateRangeValue
    | (SelectedLazyFormValue | undefined);

export interface IFilterFormValues {
    items: SelectedFilterItem[];
    savedFilter: SavedFilterItem;
}

export class FilterEntity {
    constructor(data: any) {
        this.items = data.filters.map((e: any) => new FilterItem(e));
        this.savedFilters = data.savedFilters.map((e: any) => new SavedFilterItem(e));
        Object.entries(data.options).forEach(
            (entry: [string, any]) =>
                (this.options[entry[0]] = entry[1].map((item: any) => new BTSelectItem(item)))
        );
        this.builderIds = data.builderIds ?? [];
        this.systemFilters = { ...(data.systemFilters || {}) };
    }

    items: FilterItem[];
    savedFilters: SavedFilterItem[];
    systemFilters: Record<number, string>;
    selectedSystemFilter?: SavedFilterItem;
    options: Record<string, BTSelectItem[]> = {};
    builderIds: number[];
}

/**
 * Type specifically used to store selected filter items. Constructor only accepts filterItem.
 * @function getFilterKeyValuePair: Use this utility function to get key-value pairs of filters.
 */
export class SelectedFilterItem {
    constructor(data: FilterItem | SelectedFilterItem | null) {
        if (data) {
            this.type = data.type;
            if (data instanceof FilterItem) {
                this.selectedValue = getSelectedValueFromItem(this.type, data.defaultValue);
            } else {
                this.selectedValue = getSelectedValueFromItem(this.type, data.selectedValue);
                this.allSelected = data.allSelected;
            }
            this.key = data.key;
        }
    }

    selectedValue: SelectedValueTypes;
    type: FilterType;
    key: string;
    allSelected?: boolean;
}

export function getSelectedValueFromItem(
    type: FilterType,
    value: SelectedValueTypes
): SelectedValueTypes {
    if (value === undefined) {
        return undefined;
    }
    switch (type) {
        case FilterType.DateGroups:
            return new DateRangeValue(value);
        case FilterType.NumberField:
            return new NumberRangeValue(value);
        case FilterType.KeywordSearch:
            return new KeywordValue(value);
        case FilterType.MultiSelect:
        case FilterType.GroupedMultiSelect:
            return getMultiSelectValue(value);
        case FilterType.Checkbox:
            // For some reason, values that should be booleans at this point
            // are still strings, so typechecking later fails. Adding an explicit
            // check for strings to get those properly casted.
            if (typeof value === "string") {
                return value === "true";
            }
            return value;
        case FilterType.BuilderSelect:
            if (typeof value === "string") {
                value = JSON.parse(value);
            }

            // if the value is -1 or undefined, that means nothing is selected, and we don't want to display -1, so return undefined
            if ((value as any).value === "-1" || (value as any).value === undefined) {
                return undefined;
            }
            return new SelectedLazyFormValue(value);
        default:
            return value;
    }
}

export function getMultiSelectValue(value: SelectedValueTypes) {
    if (typeof value === "string") {
        if (value === "") {
            return [] as number[];
        } else {
            return value.split(",").map((x) => Number.parseInt(x));
        }
    }
    return value;
}

export function isStandardFilter(filterId: number) {
    return filterId <= StandardFilterId;
}

export class FilterItem {
    constructor(data: any) {
        this.key = data.jsonKey;
        this.title = data.title;
        this.type = data.type;
        this.tooltipText = data.tooltipText;
        this.disabledDescriptor = data.disabledDescriptor;
        this.showLabel = data.showLabel;
        this.options = data.options;
        this.defaultValue = getSelectedValueFromItem(this.type, data.defaultValue);
        this.serializeBehavior = data.serializeBehavior;
        this.allSelectedText = data.allSelectedText;
        this.noneSelectedText = data.noneSelectedText;
        this.isVisible = data.isVisible;
        this.placeholderText = data.placeholderText;
        this.useCurrency = data.useCurrency;

        if (this.type === FilterType.Currency) {
            this.defaultValue = data.defaultValue.NumberValue;
        }
    }

    key: string;
    title: string;
    type: FilterType;
    tooltipText: string;
    disabledDescriptor?: string;
    showLabel: boolean;
    options: string;
    defaultValue: SelectedValueTypes;
    serializeBehavior: SelectAllSerializeBehaviors;
    allSelectedText: string;
    noneSelectedText: string;
    isVisible: boolean;
    placeholderText: string;
    useCurrency: boolean;
}

export class NumberRangeValue {
    constructor(data: any) {
        if (typeof data === "string") {
            data = JSON.parse(data);
        }
        this.SelectedValue = data.SelectedValue;
        this.NumberValue = isNaN(+data.NumberValue) ? undefined : +data.NumberValue;
    }

    public toString() {
        return JSON.stringify(this);
    }

    SelectedValue: NumberRangeOptionItem;
    NumberValue: number | undefined;
}

export class SelectedLazyFormValue extends BTSelectItem<any> {
    constructor(data: any) {
        if (typeof data === "string") {
            data = JSON.parse(data);
        }

        super({ value: data.value, name: data.text || data.title, id: data.value });
        this.accountStatus = data.accountStatus ? parseInt(data.accountStatus) : undefined;
        this.accountType = data.accountType ? parseInt(data.accountType) : undefined;
    }

    public toString() {
        return JSON.stringify({ value: this.value, text: this.title });
    }

    accountStatus?: ClosedAccountTypes;
    accountType?: BuilderFilterTypes;
}

export class DateRangeValue {
    constructor(data: any) {
        if (typeof data === "string") {
            data = JSON.parse(data);
        }
        this.SelectedValue = data.SelectedValue;
        this.StartDate = data.StartDate || null;
        this.EndDate = data.EndDate || null;
    }

    public toString() {
        return JSON.stringify(this);
    }

    SelectedValue: DateRangeFilterOptionItem;
    StartDate: string | null;
    EndDate: string | null;
}
export class KeywordValue {
    constructor(data: any) {
        if (typeof data === "string") {
            data = JSON.parse(data);
        }
        this.SelectedKeywordTypeIndex = data.SelectedKeywordTypeIndex;
        this.KeywordSearchText = data.KeywordSearchText;
    }

    public toString() {
        return JSON.stringify(this);
    }

    SelectedKeywordTypeIndex: number;
    KeywordSearchText: string;
}

export enum FilterEntityType {
    None = 0,
    LeadsListView = 1,
    LeadsActivityView = 2,
    LeadsActivityCalendarView = 3,
    BidsBuilder = 4,
    BudgetPOView = 5,
    BudgetSummaryView = 6,
    CalendarGanttView = 10,
    ScheduleBaselineView = 11,
    ScheduleWorkdayExceptionView = 12,
    Settlements = 14,
    MessagesListView = 15,
    MessagesCommentsView = 16,
    MessagesConversationsView = 17,
    ChangeOrdersListView = 18,
    ChangeOrdersProfitSummaryView = 19,
    SelectionsCategoryView = 20,
    SelectionsLocationView = 21,
    SelectionsListView = 22,
    WarrantySummaryView = 23,
    WarrantyDetailedView = 24,
    WarrantyAddedCostView = 25,
    UsersInternalView = 26,
    UsersSubsView = 27,
    ToDoSummaryView = 28,
    POPaymentsView = 29,
    DailyLogView = 31,
    NotificationHistory = 32,
    JobsList = 33,
    SelectionsAllowanceView = 35,
    ScheduleListView = 36,
    TimeClockReportView = 38,
    OwnerPaymentsList = 39,
    IndividualSurveyList = 40,
    GroupedSurveyList = 41,
    SurveyQuestionsList = 42,
    TimeClockMapView = 43,
    LeadsMapView = 44,
    AdminAudit = 45,
    AdminPartnerReport = 46,
    LeadsDetailActivityView = 47,
    ReceiptList = 48,
    UsersContactsView = 49,
    SelectionCardView = 50,
    MerchantPayments = 51,
    AdminAccountManagement = 52,
    AdminChurnReport = 54,
    LeadsActivityTemplate = 55,
    CreditMemos = 56,
    InvoicePayments = 57,
    ErrorReport = 58,
    ErrorReportDetail = 59,
    ErrorFolder = 60,
    LeadProposals = 61,
    SelectionConditionView = 62,
    PurchaseOrderList = 63,
    RFIsList = 64,
    RiskInsurance = 68,
    OnlinePaymentReport = 69,
    // ChurnReport = 71,
    // BuilderStatusReport = 72,
    LeadProposalTemplate = 73,
    CalendarView = 74,
    OutgoingPayments = 75,
    // Contacts = 76
    DocumentFolderDetails = 77,
    PhotoFolderDetails = 78,
    VideoFolderDetails = 79,
    AdminBuilderMetrics = 80,
    AdminPaymentsQBV3 = 81,
    CalendarPredecessorView = 82,
    InternalUserDetailsJobsList = 83,
    SubDetailsJobsList = 84,
    CalendarShiftHistory = 85,
    SelectionFavorites = 86,
    BudgetCategory = 87,
    ContactDirectory = 88,
    ContactDropdown = 89,
    Dropdown = 90,
    CostCodeItems = 91,
    JobPicker = 92, // General Job Picker
    TemplateJobPicker = 93,
    RuntimeError = 94,
    TemplatesList = 95,
    PhotoAttachedAlbumDetails = 96,
    QuestionReport = 97,
    // VendorProducts = 98,
    CostGroups = 99,
    Suspensions = 100,
    OrderForms = 101,

    // Reporting
    ToDoCountPerJob = 103,
    LeadCountPerSalesperson = 104,
    AmountInvoicedVsAmountPaid = 105,
    LeadActivitiesPerSalesperson = 106,
    DailyLogCountPerUser = 107,
    LeadStatusBySource = 108,
    DailyLogCountPerJob = 109,
    TotalHoursWorkedByUser = 110,
    ToDoCountPerUser = 111,
    ProjectCompletionPerJob = 112,
    RunningTotalVsContractPrice = 113,
    EstimatedCostVsActualCost = 114,
    YourPayments = 115,
    JobCountPerProjectManager = 116,
    AverageAgeOfLeadPerSalesPerson = 118,
    AverageAgeOfLeadPerSource = 119,
    DurationPerJob = 120,
    TotalHoursWorkedByJob = 121,
    CompanyCashflowPerJob = 122,
    TotalCompanyLeadCount = 128,
    WarrantyClaimsPerJob = 129,
    WebServicesQueue = 130,
    // End reporting

    EstimateWorksheet = 134,
    ActivityFeed = 135,
    WarrantyServices = 136,
    MicrodepositVerification = 137,
    TaxRates = 139,
    TaxGroups = 140,
    SubscriptionSignUpCodes = 141,
    Specification = 142,
    OnlinePaymentAccountStatus = 143,
    Deposits = 144,
    JobCostingBudget = 145,
}

export enum SelectAllSerializeBehaviors {
    IncludeNoValues = 1,
    IncludeAllValues = 2,
    IncludeAllValueIdentifier = 3,
}

export enum SystemFilterType {
    PastDue = 1,
    DueToday = 2,
    IncompleteRfi = 3,
    UnconfirmedSchedule = 4,
    Unapproved = 5,
    UnreadMessages = 6,
    WarrantyRescheduled = 7,
    WarrantyFeedback = 8,
    NewWarrantyRequested = 9,
    WarrantyReady = 10,
    ExpiredInsurance = 11,
    ShowCompletedToDos = 13,
    ActionItem = 14,
}

export const getSelectedFilter = (entity: FilterEntity, selectedFilterId?: number | undefined) => {
    // If the filters were loaded with a system filter selected, use that
    if (entity.selectedSystemFilter) {
        return entity.selectedSystemFilter;
    }
    return (
        entity.savedFilters.find((x) => x.id === selectedFilterId) || // Selected filter
        entity.savedFilters.find((x) => x.isDefault) || // Default filter
        entity.savedFilters.find((x) => x.id === -1)!
    ); // Standard filter
};

export const getSelectedFilterItemsFromSavedFilter = (
    entity: FilterEntity,
    selectedSavedFilter?: SavedFilterItem
) => {
    if (selectedSavedFilter === undefined) {
        selectedSavedFilter = getSelectedFilter(entity);
    }
    const filterKeyValuePairs = Object.entries(JSON.parse(selectedSavedFilter.value));
    const selectedFiltersCopy = entity.items.map((e) => new SelectedFilterItem(e));

    filterKeyValuePairs.forEach((pair: [string, any]) => {
        const matchedFilter = selectedFiltersCopy.find((x) => x.key === pair[0]);

        if (matchedFilter) {
            matchedFilter.selectedValue = getSelectedValueFromItem(matchedFilter.type, pair[1]);
        }
    });
    return selectedFiltersCopy;
};

export const mapFilterEntityToFormValues = (
    entity: FilterEntity,
    selectedFilterId?: number | undefined,
    filterOverride?: SelectedFilterItem[] | undefined
): IFilterFormValues => ({
    items:
        filterOverride ||
        getSelectedFilterItemsFromSavedFilter(entity, getSelectedFilter(entity, selectedFilterId)),
    savedFilter: getSelectedFilter(entity, selectedFilterId),
});

export enum FilterItemJsonKey {
    // Add json key values as needed
    PurchaseDate = "8",
    TotalAmount = "9",
}
