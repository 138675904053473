import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { ClientObservableBackgroundJobStatus, TabPermissions } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { routes } from "utilities/routes";

import { BTIconExternalImport } from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { ClientObservableBackgroundJobStatusPopupHandler } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.api.handler";
import {
    BackgroundProcessEntityType,
    ClientObservableBackgroundJobDetails,
} from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.types";

import { templatesActionrequiredGetV2 } from "handlers";

import { JobListTabs } from "entity/job/JobList/JobList.api.types";
import TemplateImport from "entity/templateImport/TemplateImport/TemplateImport";
import { TemplateImportResponse } from "entity/templateImport/TemplateImport/TemplateImport.api.types";

import "./JobImportItem.less";

export function JobImportItem({
    process,
    handler,
    onRefreshList,
}: {
    process: ClientObservableBackgroundJobDetails;
    handler?: ClientObservableBackgroundJobStatusPopupHandler;
    onRefreshList: () => void;
}) {
    const match = useRouteMatch();
    const history = useHistory();
    const location = history.location;
    const currentUrl = match.url;

    const [templateData, setTemplateData] = useState<TemplateImportResponse | undefined>(undefined);

    if (process.entityId === null) {
        return null;
    }

    const onActionRequired = async () => {
        if (handler) {
            try {
                const resp = await templatesActionrequiredGetV2({ jobId: process.processID });
                if (resp.data.scheduleExtraData) {
                    const templateData = new TemplateImportResponse(resp.data.scheduleExtraData);
                    setTemplateData(templateData);
                }
                onRefreshList();
            } catch (e) {
                showAPIErrorMessage(e);
            }
        }
    };

    let link =
        routes.job.getListLink(JobListTabs.List) + routes.job.getDetailsLink(process.entityId);
    let handleClickLink = async () => {};
    if (process.entityType === BackgroundProcessEntityType.JobTemplate) {
        link =
            routes.template.getListLink() +
            routes.template.getTemplateDetailsLink(process.entityId);
    }
    if (process.status === ClientObservableBackgroundJobStatus.ActionRequired) {
        link = "";
        handleClickLink = onActionRequired;
    }

    const onCloseTemplateImport = () => {
        setTemplateData(undefined);
        history.push(currentUrl);
    };

    return (
        <div className="JobImportItem">
            <BTLink useAutoSPARouting to={link} isUnderline={false}>
                <BTTitle level={4} className="TruncateText margin-bottom-zero">
                    <span className="TruncateText">{process.processName}</span>
                </BTTitle>
            </BTLink>
            <span className="ProcessDetails">{process.details}</span>
            {process.status === ClientObservableBackgroundJobStatus.ActionRequired && (
                <div onClick={handleClickLink} className="ActionRequired">
                    <span className="CompleteImport">Complete Import </span>
                    <BTIconExternalImport />
                </div>
            )}

            {templateData && (
                <TemplateImport
                    history={history}
                    match={match}
                    location={location}
                    modalConfig={{
                        beforeClose: onCloseTemplateImport,
                        parentRoute: match.url,
                    }}
                    postImportData={templateData}
                    jobId={process.entityId}
                    tabId={TabPermissions.None}
                />
            )}
        </div>
    );
}
