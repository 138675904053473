import { message } from "antd";
import { AxiosError } from "axios";
import { cloneDeep } from "lodash-es";
import moment from "moment";

import {
    APIHandlerVersion,
    BaseResponse,
    BTSelectItem,
    ExceptionResponse,
    IAPIHandlerResult,
    isCanceledAPIRequestError,
} from "types/apiResponse/apiResponse";
import { BadRequestReason, ForbiddenReason } from "types/enum";

import { getFileNameFromHeader } from "utilities/document/fileDownload.utils";
import { reportError } from "utilities/errorHelpers";
import { isNullOrUndefined, isObjectWithKey } from "utilities/object/object";
import { getCurrentPortalType } from "utilities/portal/portal";
import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

export const defaultErrorMessage = "An error has occurred. Please try again.";
const notLoggedInAPIResponse = "Not authorized - Please login";
export const missingEntityExceptionAPIResponse =
    "This item may have been deleted or your viewing permissions have changed.";

export const responseAs = <T>(data: unknown) => {
    return data as T;
};
/**
 * Detects when the api error is due to an unauthorized response
 * @todo We should be returning the correct status code from the api (401 Unauthorized) when the user is not authorized instead of 200
 * @param error
 */
export function isNotAuthorizedResponse(error: APIError) {
    return error.errorMessage === notLoggedInAPIResponse;
}

/**
 * Detects when the api error is due to a entity being deleted or the user no longer having view permissions
 * @todo We can remove this check when all list pages have been migrated to react, we only need to handle this when iframes are being used
 * @param error
 */
export function isMissingEntityException(error: APIError) {
    return error.errorMessage === missingEntityExceptionAPIResponse;
}

export function isConstructor<TParams extends object[], TObj>(
    func: ((...args: TParams) => TObj) | (new (...args: TParams) => TObj)
): func is new (...args: TParams) => TObj {
    return !!func.prototype && !!func.prototype.constructor.name;
}

/**
 * Displays the error message from the server, if the server does not return an error message the default will be used
 * @param customMessage by default the message returned from the api will be shown, if the api failed but has no message "An error has occurred. Please try again." is shown
 */
export function showAPIErrorMessage(e: unknown, customMessage: string = defaultErrorMessage) {
    if (e instanceof APIError) {
        displayErrorAndLogConsoleMessage(e, customMessage);
    } else if (e instanceof BadRequestError) {
        const limitExceededError = e.errors.find(
            (err) => err.reason === BadRequestReason.LimitExceeded
        );
        if (limitExceededError) {
            void message.error(limitExceededError.message, 5);
        } else {
            void message.error(e.message, 5);
        }
    } else if (e instanceof ConflictError || e instanceof NotFoundError) {
        return;
    } else if (e instanceof NoAccessError) {
        if (e.reason === ForbiddenReason.NotConnected) {
            handleNoJobAccessError();
        } else {
            const apiErr = new APIError(e.response);
            displayErrorAndLogConsoleMessage(apiErr, customMessage);
        }
    } else {
        let messageToDisplay: string = defaultErrorMessage;
        if (customMessage !== defaultErrorMessage) {
            messageToDisplay = customMessage;
        }
        void message.error(messageToDisplay, 5);
    }
}

/**
 * Displays the error message from the server, if the server does not return an error message the default will be used
 * @param customMessage by default the message returned from the api will be shown, if the api failed but has no message "An error has occurred. Please try again." is shown
 * @deprecated Use showApixErrorMessage from apix-handler.ts instead
 */
export function showApixErrorMessage(
    e: AxiosError<ExceptionResponse>,
    customMessage: string = defaultErrorMessage
) {
    let messageToDisplay: string = e.response?.data?.message ?? defaultErrorMessage;
    if (customMessage !== defaultErrorMessage) {
        messageToDisplay = customMessage;
    }
    void message.error(messageToDisplay, 5);
}

const displayErrorAndLogConsoleMessage = (e: APIError, customMessage: string) => {
    let messageToDisplay: string = e.errorMessage;
    if (customMessage !== defaultErrorMessage) {
        messageToDisplay = customMessage;
    }
    console.error("API Error Response", e.response);
    void message.error(messageToDisplay, 5);
};

export function handleNoJobAccessError() {
    window.location.assign(`/app${routes.notConnected.getLink(false)}`);
}

class BadRequestErrorInfo<TRequest = any> {
    constructor(data: any) {
        this.fieldKey = data.fieldKey;
        this.reason = data.errorCode;
        this.message = data.message;
    }

    fieldKey: (keyof TRequest & string) | null;
    reason: BadRequestReason;
    message: string;
}

export class BadRequestError<TRequest = any> extends Error {
    constructor(data: any) {
        super();
        this.errors = data.errors.map((error: any) => new BadRequestErrorInfo<TRequest>(error));
        this.message = data.message;
        this.extraInfo = data.extraInfo;
    }

    errors: BadRequestErrorInfo<TRequest>[];
    message: string;
    extraInfo: string;
}

export class ConflictError extends Error {}
export class TooManyRequestsError extends Error {
    constructor(response: BaseResponse, message?: string) {
        super(message);
        this.response = response;
    }

    response: BaseResponse;
}
export class ServiceUnavailableError extends Error {
    constructor(response: BaseResponse, message?: string) {
        super(message);
        this.response = response;
    }

    response: BaseResponse;
}

export class NotFoundError extends Error {
    constructor(response: BaseResponse = new BaseResponse({}), message?: string) {
        super(message);
        this.response = response;
    }
    response: BaseResponse;
}

export class UpgradeRequiredError extends Error {
    constructor(data: any) {
        super(data.message);
        this.currentPackage = data.currentPackage;
        this.requiredPackage = data.requiredPackage;
        this.userCanUpgrade = data.userCanUpgrade;
    }

    currentPackage: string;
    requiredPackage: string;
    userCanUpgrade: boolean;
}

export interface IBlobResponse {
    blobData: Blob;
    fileName: string;
}

export class NetworkError extends Error {
    constructor(response: BaseResponse | DOMException, message?: string) {
        super(message);
        this.response = response as BaseResponse;
        if (response instanceof DOMException) {
            this.domException = response;
        }
    }

    response: BaseResponse;
    domException?: DOMException;
}

export class APIError extends Error {
    /**
     * @param response The api response
     * @param message Optional, error message to use. This will default to the message that appears in the api response
     */
    constructor(response: ExceptionResponse, message?: string, endpoint?: string) {
        super(message);
        this.response = response;
        this.endpoint = endpoint;
    }

    get errorMessage(): string {
        if (this.message && this.message.length > 0) {
            return this.message;
        } else if (this.response) {
            if (this.response.message && this.response.message.length > 0) {
                return this.response.message;
            } else if (
                this.response.data &&
                this.response.data.message &&
                this.response.data.message.length > 0
            ) {
                return this.response.data.message;
            } else if (
                this.response.data &&
                this.response.data.formMessage &&
                this.response.data.formMessage.length > 0
            ) {
                return this.response.data.formMessage;
            }
        }

        return defaultErrorMessage;
    }

    response: ExceptionResponse;
    endpoint?: string;
}

export class NoAccessError extends Error {
    constructor(data: any) {
        super(data.message);
        this.response = data;
        this.reason = data.reason;
    }

    response: BaseResponse;
    reason: ForbiddenReason;
}

export class PreviewActionAPIError extends APIError {
    constructor() {
        super(new BaseResponse({}), "Cannot commit actions inside of a preview.");
    }
}

export const defaultPathForLoginPage = "/";

export function redirectToLoginPage(isBTAdmin: boolean) {
    const lpr = window.location.pathname + window.location.search;
    const isBtadminPath = window.location.pathname.startsWith("/app/Admin");
    let pathForLoginPage = `${defaultPathForLoginPage}?lpr=${lpr}`;

    if (isBTAdmin || isBtadminPath) {
        pathForLoginPage = routesWebforms.BTAdmin.getLoginLink(lpr);
    }

    window.top!.location.assign(pathForLoginPage);
}

export const getResponseAsJson = async (response: Response) => {
    let responseAsJson: BaseResponse;
    try {
        responseAsJson = await response.json();
    } catch (e) {
        // server likely returned HTML instead of JSON
        responseAsJson = new BaseResponse({});
    }

    return responseAsJson;
};

const isResponseTypeBlob = <T extends object>(
    responseType: ResponseType<T>
): responseType is "blob" => {
    return responseType === "blob";
};

const isResponseContentTypeJson = (contentType: string | null) => {
    return contentType && contentType.lastIndexOf("application/json") !== -1;
};

const getRequestOptions = (
    method: ApiMethod,
    isPayloadJson: boolean,
    headersIn?: any
): RequestInit => {
    let headers = {
        PortalType: getCurrentPortalType().toString(),
        ...headersIn,
    };

    if (isPayloadJson) {
        headers = {
            ...headers,
            "Content-Type": "application/json",
        };
    }

    return {
        method: method,
        credentials: "same-origin",
        headers,
    };
};

type ResponseType<T extends object = never> =
    | ((apiResponse: any) => T)
    | (new (apiResponse: any) => T)
    | "blob";
export const processResponse = async <T extends object>(
    response: Response,
    isBTAdmin: boolean,
    pathName: string,
    responseType: ResponseType<T>
) => {
    const contentType = response.headers.get("content-type");
    // allows us to call new on function or class
    if (isResponseTypeBlob(responseType)) {
        const data = await response.blob();
        const fileName = getFileNameFromHeader(response);
        if (response.ok) {
            let errorResponseAsJson = null;
            try {
                errorResponseAsJson = await response.json();
            } catch {}
            if (isResponseContentTypeJson(contentType)) {
                await checkApiForErrors(errorResponseAsJson, isBTAdmin, pathName, contentType);
            }
            return {
                blobData: data,
                fileName,
            } as IBlobResponse;
        } else {
            const responseAsJson = await getResponseAsJson(response);
            throw new APIError(responseAsJson);
        }
    } else {
        const responseAsJson = await getResponseAsJson(response);

        await checkApiForErrors(responseAsJson, isBTAdmin, pathName, contentType);
        try {
            const data =
                responseAsJson.data !== undefined && !isApiX(pathName)
                    ? responseAsJson.data
                    : responseAsJson;
            return isConstructor(responseType) ? new responseType(data) : responseType(data);
        } catch (e) {
            // Report API entity object construction errors
            let errorMessage = `APIHandler couldn't create an instance of the given class '${responseType.name}' - maybe you're expecting a value to exist in the constructor and it does not`;
            if (isObjectWithKey(e, "stack")) {
                errorMessage += `\n${e.stack}`;
            }
            console.error(errorMessage);
            reportError(
                e,
                {
                    internalNotes: `Response: ${JSON.stringify(
                        responseAsJson
                    )}\nError: ${errorMessage}`,
                },
                "error"
            );
            // Throw as an APIError to prevent double exception reporting in ErrorBoundary
            throw new APIError(responseAsJson);
        }
    }
};

export const checkResponseForStatusErrors = async (
    response: Response,
    url: string,
    isBTAdmin: boolean
) => {
    switch (response.status) {
        case 400:
            throw new BadRequestError(await response.json());
        case 401:
            redirectToLoginPage(isBTAdmin);
            break;
        case 402:
            throw new UpgradeRequiredError(await response.json());
        case 403:
            throw new NoAccessError(await getResponseAsJson(response));
        case 404:
            throw new NotFoundError(await getResponseAsJson(response));
        case 409:
            throw new ConflictError();
        case 429:
            throw new TooManyRequestsError(await getResponseAsJson(response));
        case 503:
            throw new ServiceUnavailableError(await getResponseAsJson(response));
        default:
            // verify the status code is 200
            if (!response.ok) {
                if (response.status >= 400 && response.status < 500) {
                    reportError(new Error(`APIError: request returned ${response.status}`));
                }

                throw new APIError(await getResponseAsJson(response), undefined, url);
            }
    }
    return response;
};

const checkApiForErrors = async (
    responseAsJson: BaseResponse,
    isBTAdmin: boolean,
    url: string,
    contentType: string | null
) => {
    if (!contentType) {
        return;
    }

    const isResponseContentJson = isResponseContentTypeJson(contentType);
    if (!isResponseContentJson) {
        reportError(
            new Error(
                `APIError: application/json content-type was expected, but "${contentType}" was received instead`
            )
        );
        throw new APIError(responseAsJson, undefined, url);
    }

    if (responseAsJson.needsToRelogin) {
        redirectToLoginPage(isBTAdmin);
        throw new APIError(responseAsJson, undefined, url);
    }

    // verify the response has a success=true
    if (responseAsJson.success !== undefined && !responseAsJson.success) {
        throw new APIError(responseAsJson, undefined, url);
    }
};

export class DetailedError extends Error {
    response: string;
}

type ApiMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

interface IAPIHandlerPropsBase<T> {
    method: ApiMethod;

    /* A class representing  */
    responseType: T extends IBlobResponse
        ? "blob"
        : ((apiResponse: any) => T) | (new (apiResponse: any) => T);

    /** normally JSON.stringify() will skip undefined values, setting sendUndefinedAsNull to true will change undefined values to null so the server receives them
     * @default false
     */
    sendUndefinedAsNull?: boolean;

    /* The data you want to send to the API, example: { status: 5, input: "text value" } */
    data?: any;

    isBTAdmin?: boolean;
    headers?: object;

    /**
     * Set to APIHandlerVersion.cancellable for APIHandler to return a result that is cancellable
     * @default APIHandlerVersion.none
     */
    version?: APIHandlerVersion;

    /**
     * This is used to signal not to add the request header for content-type: application/json so
     * we do not get a content type mismatch when submitting multi-part form data to an api.  The
     * primary use case currently is the file uploading.
     *  @default false
     */

    isMultiPartPostData?: boolean;

    /**
     * For controller.abort() function to ignore cancel error
     * @default true
     */
    throwExceptionOnAbort?: boolean;
}
interface IOldAPIHandlerProps<T> extends IAPIHandlerPropsBase<T> {
    version?: APIHandlerVersion.none;
}
interface IAPIHandlerProps<T> extends IAPIHandlerPropsBase<T> {
    version: APIHandlerVersion.cancellable;
}

/**
 * Recursively builds url path
 * @param data
 * @param prefix
 * @example
 * convertObjectToURL({ key: 1, subObj: { key2: 2 } })
 * will return "key=1&subObj.key2=2"
 */
export function convertObjectToURL(data: any, prefix: string = ""): string {
    return Object.keys(data)
        .filter((k: string) => data[k] !== undefined && data[k] !== null)
        .map((k: string) => {
            let qsKey = `${prefix ? prefix + "." : prefix}${encodeURIComponent(k)}`;
            let dataForKey = data[k];
            if (Array.isArray(dataForKey)) {
                return `${qsKey}=${dataForKey.join(`&${qsKey}=`)}`;
            } else if (typeof dataForKey === "object") {
                if (dataForKey instanceof moment) {
                    // send moment dates up as local date/time in BT (ISO without offset)
                    dataForKey = (dataForKey as moment.Moment).format("YYYY-MM-DDTHH:mm:ss");
                } else {
                    return convertObjectToURL(dataForKey, `${prefix ? prefix + "." + k : k}`);
                }
            }

            return `${qsKey}=${encodeURIComponent(dataForKey)}`;
        })
        .join("&");
}

/**
 * Returns a replacer function
 * @param sendUndefinedAsNull should undefined values be converted to null? This is used for legacy api calls that require null
 */
export function convertObjectToJSON(
    sendUndefinedAsNull: boolean = false
): (this: any, key: string, value: any) => any {
    return (key: string, value: any) => {
        if (sendUndefinedAsNull && value === undefined) {
            return null;
        }

        if (typeof value === "object") {
            for (let k in value) {
                if (value[k] instanceof moment) {
                    // send moment dates up as local date/time in BT (ISO without offset)
                    value[k] = value[k].format("YYYY-MM-DDTHH:mm:ss");
                } else if (value[k] instanceof BTSelectItem) {
                    const btItem: BTSelectItem = value[k];
                    value[k] = {
                        id: btItem.id,
                        name: btItem.title,
                        selected: btItem.selected,
                        extraData: btItem.extraData,
                    };
                }
            }
        }

        return value;
    };
}

/**
 *
 * @param url
 * @param props
 * @example await APIHandler(`/api/someEntity/${id}`, { method: "POST", data: newItem, responseType: ClassForResponseType });
 */
export function APIHandler(
    url: string,
    props: IOldAPIHandlerProps<IBlobResponse>
): Promise<IBlobResponse>;
export function APIHandler<T extends object>(
    url: string,
    props: IOldAPIHandlerProps<T>
): Promise<T>;

export function APIHandler(
    url: string,
    props: IAPIHandlerProps<IBlobResponse>
): IAPIHandlerResult<IBlobResponse>;
export function APIHandler<T extends object>(
    url: string,
    props: IAPIHandlerProps<T>
): IAPIHandlerResult<T>;

export function APIHandler<T extends object>(
    url: string,
    props: IAPIHandlerProps<T> | IOldAPIHandlerProps<T>
): Promise<T | IBlobResponse> | IAPIHandlerResult<T> {
    const throwExceptionOnAbort =
        props.throwExceptionOnAbort !== undefined ? props.throwExceptionOnAbort : true;

    let urlQS = "";
    // In these scenarios we don't want to add the application/json header as content type won't match
    // currently btFileSystem is used when we are uploading
    const requestOptions = getRequestOptions(
        props.method,
        !props.isMultiPartPostData,
        props.headers
    );

    let data = props.data || {};

    switch (props.method) {
        case "GET":
            // add data to querystring (if we drop ie11 support or add more polyfills this can be cleaned up)
            let dataCopy = cloneDeep<T>(data);
            const qsParams = convertObjectToURL(dataCopy);

            // Some endpoints are passing in query strings incorrectly
            urlQS = addQsParam(qsParams, url);
            break;

        case "PUT":
        case "POST":
        case "DELETE":
        case "PATCH":
            // add data to the request body
            if (!isNullOrUndefined(props.data)) {
                if (FormData.prototype.isPrototypeOf(props.data)) {
                    requestOptions.body = props.data;

                    // When submitting form data append org links as a query param
                    const qsParams = convertObjectToURL({});
                    urlQS = addQsParam(qsParams, url);
                } else {
                    let dataCopy = cloneDeep<T>(props.data);
                    requestOptions.body = JSON.stringify(
                        dataCopy,
                        convertObjectToJSON(props.sendUndefinedAsNull)
                    );
                }
            }

            break;

        default:
            throw `APIHandler does not support the method type "${props.method}"`;
    }

    const controller = new AbortController();
    const { signal } = controller;
    // credentials: include is needed for edge
    const response = fetch(url + urlQS, { ...requestOptions, signal })
        .catch((e) => {
            throw new NetworkError(e, "NetworkError");
        }) // network error, Create a new exception type and throw it. Then in sentry settings ignore the new exception type
        .then((response) => checkResponseForStatusErrors(response, url, props.isBTAdmin || false))
        .then((response) =>
            processResponse<T>(
                response,
                props.isBTAdmin ?? false,
                url,
                props.responseType as ResponseType<T>
            )
        );
    // any errors thrown from .then()'s above are not caught by design

    if (props.version === APIHandlerVersion.cancellable) {
        return {
            response: response.catch((e) => {
                if (isCanceledAPIRequestError(e) || (!throwExceptionOnAbort && signal.aborted)) {
                    return undefined;
                }
                throw e;
            }),
            unhandledResponse: response,
            cancel: () => {
                controller.abort();
            },
        } as IAPIHandlerResult<T>;
    } else {
        return response;
    }
}

function addQsParam(qsParams: string, url: string) {
    let urlQS = "";
    if (qsParams.length !== 0) {
        if (url.includes("?")) {
            urlQS += "&" + qsParams;
        } else {
            urlQS += "?" + qsParams;
        }
    }
    return urlQS;
}

/**
 * Returns true if the given URL is an APIX URL
 */
function isApiX(pathName: string) {
    return pathName.startsWith("/apix/");
}
