import { OnlinePaymentDefaultAllowValues } from "types/enum";

import { default as JobGetApiJson } from "entity/job/Job/json/Job.get.api.json";
import { default as OwnerMassPayApiJson } from "entity/onlinePayment/OwnerMassOnlinePayment/OwnerMassOnlinePayment/OwnerMassOnlinePayment.api.json";

import { default as OnlinePaymentLimitsApiJson } from "./OnlinePaymentLimits.api.json";

export type OnlinePaymentLimitsApiData =
    | typeof OnlinePaymentLimitsApiJson
    | (typeof JobGetApiJson)["ownerInfo"]
    | (typeof OwnerMassPayApiJson)["onlinePaymentLimits"];

export interface IOnlinePaymentLimitsFormValues {
    allowPartialPayments: boolean;
    allowCreditCards: boolean;
    minCreditCard: number;
    maxCreditCard: number | null;
    isNoLimitCreditCard: boolean;
    allowChecks: boolean;
    minCheck: number;
    maxCheck: number | null;
    isNoLimitCheck: boolean;
    passCreditCardFeesToOwner: boolean;
}

export class OnlinePaymentLimitsEntity {
    constructor(data: OnlinePaymentLimitsApiData) {
        this.allowPartialPayments =
            typeof data.allowPartialPayments === "boolean"
                ? data.allowPartialPayments
                : data.allowPartialPayments?.value ?? false;
        this.allowCreditCards =
            typeof data.allowCreditCards === "boolean"
                ? data.allowCreditCards
                : data.allowCreditCards !== OnlinePaymentDefaultAllowValues.NO;
        this.allowChecks =
            typeof data.allowChecks === "boolean"
                ? data.allowChecks
                : data.allowChecks !== OnlinePaymentDefaultAllowValues.NO;
        this.canAcceptChecks = data.canAcceptChecks;
        this.minCreditCard = data.minCreditCard ?? 0.99;
        this.maxCreditCard =
            data.maxCreditCard !== undefined && data.maxCreditCard !== 0
                ? data.maxCreditCard
                : null;
        this.isNoLimitCreditCard =
            data.creditCardTransactionLimit === 0 && this.maxCreditCard === null; // clean up with btpTransactions
        this.creditCardTransactionLimit = data.creditCardTransactionLimit;
        this.minCheck = data.minCheck ?? 5.0;
        this.maxCheck = data.maxCheck !== undefined && data.maxCheck !== 0 ? data.maxCheck : null;
        this.isNoLimitCheck = this.maxCheck === null;
        this.bankTransactionLimit = data.bankTransactionLimit;
        this.bankRiskImposedTransactionLimit = data.bankRiskImposedTransactionLimit;
        this.allowPassCreditCardFeesToOwner = data.allowPassCreditCardFeesToOwner;
        this.passCreditCardFeesToOwner = data.passCreditCardFeesToOwner;
    }

    allowPartialPayments: boolean;
    allowCreditCards: boolean;
    canAcceptChecks: boolean;
    minCreditCard: number;
    maxCreditCard: number | null;
    isNoLimitCreditCard: boolean;
    allowChecks: boolean;
    minCheck: number;
    maxCheck: number | null;
    isNoLimitCheck: boolean;
    creditCardTransactionLimit: number;
    bankTransactionLimit: number;
    bankRiskImposedTransactionLimit: number;
    allowPassCreditCardFeesToOwner: boolean;
    passCreditCardFeesToOwner: boolean;
}

export class UpdateExistingJobsResponse {}
