import { Recipient } from "entity/message/common/Message.api.types";

/**
 * Function to handle formatting recipient names, including external emails and friendly names
 *
 * @param {Recipient[]} recipients - A list of Recipients
 * @returns {string} A formatted list of recipient names
 */
export const formatRecipientNames = (recipients: Recipient[]): string => {
    let recipientsFormatted: string[] = [];
    recipients.forEach((recipient) => {
        let recipientFormatted = recipient.recipientName;
        if (recipient.friendlyName && recipient.friendlyName !== recipient.recipientName) {
            recipientFormatted = recipient.friendlyName + ` <${recipientFormatted}>`;
        }
        recipientsFormatted.push(recipientFormatted);
    });
    return recipientsFormatted.join(", ");
};
