import moment from "moment";

import { BTSelectItem, mapBTServiceDropdownToSelectItem } from "types/apiResponse/apiResponse";
import {
    BillingPeriodUnit,
    ChargebeeInvoiceEstimateLineItemEntityType,
    ChargebeePriceType,
    ChargebeeSubscriptionStatus,
    DiscountType,
    DurationType,
    GetPaidYourWayTestGroup,
    ItemType,
    MeteredUsageType,
    PricingModel,
    TaxExemptReason,
    TrialEndAction,
} from "types/enum";

import { default as InvoiceEstimateApiResponseExample } from "../Subscription/InvoiceEstimate.api.json";

export class SubscriptionListEntity {
    constructor(data: any) {
        this.subscriptions = data.subscriptions.map((li: any) => new SubscriptionObject(li));
        this.builderId = data.builderId;
        this.globalUserId = data.globalUserId;
        this.builderName = data.builderName;
        this.allAvailableProductFamilyIds = data.allAvailableProductFamilyIds;
        this.demoUnbilledDescription = data.demoUnbilledDescription;
        this.isBillingUser = data.isBillingUser ?? false;
        this.isSupportUser = data.isSupportUser ?? false;
        this.isBillingUtilityUser = data.isBillingUtilityUser ?? false;
        this.getPaidYourWayTestGroup = data.getPaidYourWayTestGroup ?? GetPaidYourWayTestGroup.None;
        this.chargebeeCustomerId = data.chargebeeCustomerId;
        this.churnAuth = data.churnAuth && new ChurnAuth(data.churnAuth);
        this.churnSegmentationAttributes =
            data.churnSegmentationAttributes &&
            new ChurnSegmentationAttributes(data.churnSegmentationAttributes);
        this.billingCycles =
            data.billingCycles === null
                ? []
                : data.billingCycles.map((x: any) => new BTSelectItem(x));
    }

    subscriptions: SubscriptionObject[];
    builderId?: number;
    globalUserId?: number;
    builderName: string;
    allAvailableProductFamilyIds: string[];
    demoUnbilledDescription: string;
    isBillingUser: boolean;
    isSupportUser: boolean;
    isBillingUtilityUser: boolean;
    getPaidYourWayTestGroup: GetPaidYourWayTestGroup;
    chargebeeCustomerId: string;
    churnAuth?: ChurnAuth;
    churnSegmentationAttributes?: ChurnSegmentationAttributes;
    billingCycles: BTSelectItem[];
}

export class GetSubscriptionWithScheduledChangesResponse {
    constructor(data: any) {
        this.subscription = data.subscription;
    }

    subscription: SubscriptionObject;
}

export type SubscriptionDetailsFormActions = undefined | "save" | "estimate" | "accountChange";
export const intMax = 2147483647; // .NET int max

export interface IItemPriceFormValues {
    quantity?: number;
    appliedCoupon: Coupon | null;
}

export interface IHasCouponExtraData {
    coupon: Coupon;
}
export class SubscriptionObject {
    constructor(data: any) {
        this.id = data.id;
        this.billingPeriod = data.billingPeriod;
        this.billingPeriodUnit = data.billingPeriodUnit;
        this.originalAmount = data.originalAmount;
        this.recurringAmount = data.recurringAmount;
        this.nextBillingDate = moment(data.nextBillingDate);
        this.planItemPrice = new ItemPrice(data.planItemPrice);
        this.addOnItemPrices = data.addOnItemPrices.map((a: any) => new ItemPrice(a));
        this.currentPlan = data.currentPlan && new Plan(data.currentPlan);
        this.currentAddons =
            data.currentAddons && data.currentAddons.map((aad: any) => new Addon(aad));
        this.coupons = data.coupons && data.coupons.map((cp: any) => new Coupon(cp));
        this.status = data.status;
        this.trialEndDate = moment(data.trialEndDate);
        if (data.subscriptionEndDate) {
            this.subscriptionEndDate = moment(data.subscriptionEndDate);
        }
        this.loginsDisabled = data.loginsDisabled;
        this.isSuspended = data.isSuspended;
        this.suspendable = data.suspendable;
        this.itemTiers = data.itemTiers;
        this.selectionIndex = data.selectionIndex;
        this.canWriteOff = data.canWriteOff;
        this.canRemoveCancellation = data.canRemoveCancellation;
        this.productFamilyName = data.productFamilyName;
        this.productFamilyId = data.productFamilyId;
        this.hasScheduledChanges = data.hasScheduledChanges;
        this.subscriptionJobUsageInformation =
            data.subscriptionJobUsageInformation &&
            new SubscriptionJobUsageInformation(data.subscriptionJobUsageInformation);
        this.suspensionStartDate = moment(data.suspensionStartDate);
        this.suspensionEndDate = moment(data.suspensionEndDate);
        this.nextPriceIncreaseDate = moment(data.nextPriceIncreaseDate);
        this.hasDiscount = data.hasDiscount;
        this.discountStart = moment(data.discountStart);
        this.discountEnd = moment(data.discountEnd);
        this.discountAmount = data.discountAmount;
        this.hideNextPricingAmount = data.hideNextPricingAmount;
        this.shouldRedirectToPricingChanges = data.shouldRedirectToPricingChanges;
    }

    id: string;
    billingPeriod: number;
    billingPeriodUnit: BillingPeriodUnit;
    originalAmount: number;
    recurringAmount: number;
    nextBillingDate: moment.Moment;
    planItemPrice: ItemPrice;
    addOnItemPrices: ItemPrice[];
    currentPlan: Plan;
    currentAddons: Addon[];
    coupons: Coupon[];
    isSuspended: boolean;
    status: ChargebeeSubscriptionStatus;
    trialEndDate?: moment.Moment;
    subscriptionEndDate?: moment.Moment;
    loginsDisabled: boolean;
    suspendable: boolean;
    itemTiers?: ItemTier[];
    selectionIndex?: number;
    canWriteOff: boolean;
    canRemoveCancellation: boolean;
    productFamilyName: string;
    productFamilyId: string;
    hasScheduledChanges: boolean;
    subscriptionJobUsageInformation?: SubscriptionJobUsageInformation;
    suspensionStartDate?: moment.Moment;
    suspensionEndDate?: moment.Moment;
    nextPriceIncreaseDate?: moment.Moment;
    hasDiscount: boolean;
    discountStart?: moment.Moment;
    discountEnd?: moment.Moment;
    discountAmount?: number;
    hideNextPricingAmount: boolean;
    shouldRedirectToPricingChanges: boolean;
}

export class Coupon {
    constructor(data: any) {
        this.id = data.couponId;
        this.applyTillDate = moment(data.applyTill);
        this.discountType = data.discountType;
        this.discountAmount = data.discountAmount;
        this.discountPercentage = data.discountPercentage;
        this.name = data.name;
        this.period = data.period;
        this.periodUnit = data.periodUnit;
        this.shouldDisplayDiscount = data.shouldDisplayDiscount;
        this.discountExpirationDate = moment(data.discountExpirationDate);
    }

    id: string;
    applyTillDate: moment.Moment;
    discountType: DiscountType;
    durationType: DurationType;
    discountPercentage: number;
    discountAmount: number;
    name: string;
    period?: number;
    periodUnit: BillingPeriodUnit;
    shouldDisplayDiscount: boolean;
    discountExpirationDate: moment.Moment;
}

export class ItemTier {
    constructor(data: any) {
        this.startingUnit = data.startingUnit;
        this.endingUnit = data.endingUnit;
        this.itemPriceId = data.itemPriceId;
        this.price = data.price;
    }
    startingUnit: number;
    endingUnit: number | undefined;
    itemPriceId: string;
    price: number;
}

export class ItemPrice {
    constructor(data: any) {
        this.id = data.id;
        this.itemId = data.itemId;
        this.displayName = data.displayName;
        this.period = data.period;
        this.periodUnit = data.periodUnit;
        this.price = data.price;
        this.discountAmount = data.discountAmount;
        this.pricingModel = data.pricingModel;
        this.itemType = data.itemType;
        this.subscriptionItem = new SubscriptionItem(data.subscriptionItem);
        this.itemTiers = data.itemTiers?.map((o: any) => new ItemTier(o));
        this.appliedCoupon = data.appliedCoupon && new Coupon(data.appliedCoupon);
        this.metered = data.subscriptionItem?.metered ?? false;
        this.externalName = data.externalName;
    }
    id: string;
    itemId: string;
    displayName: string;
    period: number;
    periodUnit: number;
    price: number;
    discountAmount?: number;
    pricingModel: number;
    itemType: number;
    subscriptionItem: SubscriptionItem;
    itemTiers?: ItemTier[];
    appliedCoupon: Coupon | null;
    metered: boolean;
    externalName: string;
}

export class SubscriptionItem {
    constructor(data: any) {
        this.internalName = data.internalName;
        this.displayName = data.externalName;
        this.description = data.description;
        this.itemId = data.itemId;
        this.quantity = data.quantity;
        this.amount = data.amount;
        this.isSelected = data.isSelected;
        this.metered = data.metered;
        this.meteredQuantity = data.meteredQuantity;
        this.unitOfMeasurement = data.unitOfMeasurement;
        this.hasTrainingUpgrades = data.hasTrainingUpgrades;
    }
    internalName: string;
    displayName: string | undefined;
    description: string;
    itemId: string;
    amount: number;
    quantity: number | undefined;
    isSelected: boolean;
    metered: boolean;
    meteredQuantity: number | undefined;
    unitOfMeasurement: string | undefined;
    hasTrainingUpgrades: boolean;
}

export class AvailablePlansEntity {
    constructor(data: any) {
        this.availablePlans = data.availablePlans.map((ap: any) => new Plan(ap));
    }
    availablePlans: Plan[];
}

export class AvailableOptionsEntity {
    constructor(data: any) {
        this.availableOptions = data.availableOptions.map((ao: any) => new Option(ao));
    }
    availableOptions: Option[];
}

export class AvailableAddonsEntity {
    constructor(data: any) {
        this.availableAddons = data.availableAddons.map((aad: any) => new Addon(aad));
    }
    availableAddons: Addon[];
}

export class AvailableCouponsEntity {
    constructor(data: any) {
        this.availableCoupons =
            mapBTServiceDropdownToSelectItem<IHasCouponExtraData>(data.availableCoupons) ?? [];
    }

    availableCoupons: BTSelectItem<IHasCouponExtraData>[];
}

export class Addon {
    constructor(data: any) {
        this.itemId = data.itemId;
        this.internalName = data.internalName;
        this.description = data.description;
        this.addonPriceOptions = data.itemPriceOptions
            .filter((x: ItemPrice) => x.itemType === ItemType.Addon)
            .map((o: any) => new ItemPrice(o));
        this.showToBuilder = data.showToBuilder;
        this.showToAdmin = data.showToAdmin;
        this.attachedItemType = data.attachedItemType;
        this.workflowRequired = data.workflowRequired;
        this.minimumQuantity = data.minimumQuantity;
        this.showToPrePayTestGroup = data.showToPrePayTestGroup;
        this.showToTransactionBasedTestGroup = data.showToTransactionBasedTestGroup;
        this.metered = data.metered;
        this.meteredQuantity = data.meteredQuantity;
        this.unitOfMeasure = data.unitOfMeasure;
        this.usageType = data.usageType;
    }
    itemId: string;
    internalName: string;
    description: string;
    addonPriceOptions: ItemPrice[];
    showToBuilder: boolean;
    showToAdmin: boolean;
    showToPrePayTestGroup: boolean;
    showToTransactionBasedTestGroup: boolean;
    attachedItemType: number;
    workflowRequired: boolean;
    minimumQuantity: number | undefined;
    metered: boolean;
    meteredQuantity: number | undefined;
    unitOfMeasure: string | undefined;
    usageType: MeteredUsageType | undefined;
}

export class Plan {
    constructor(data: any) {
        this.planPriceOptions = data.itemPriceOptions.map((o: any) => new ItemPrice(o));
        this.itemId = data.itemId;
        this.internalName = data.internalName;
        this.description = data.description;
        this.minimumQuantity = data.minimumQuantity;
        this.metered = data.metered;
        this.meteredQuantity = data.meteredQuantity;
        this.upgradeTrainingPlanIds = data.upgradeTrainingPlanIds;
        this.paidTrainingAmount = data.paidTrainingAmount;
    }
    planPriceOptions: ItemPrice[];
    itemId: string;
    internalName: string;
    description: string;
    minimumQuantity: number | undefined;
    metered: boolean;
    meteredQuantity: number | undefined;
    upgradeTrainingPlanIds: string[];
    paidTrainingAmount: number | undefined;
}

export class Option {
    constructor(data: any) {
        this.optionPlan = new Plan(data.optionPlan);
        this.featureList = data.featureList;
    }
    optionPlan: Plan;
    featureList: string[];
}

export class SubscriptionEstimate {
    constructor(data: any) {
        this.id = data.id;
        this.currencyCode = data.currencyCode;
        this.status = data.status;
        this.trialEndAction = data.trialEndAction;
        this.nextBillingAt = moment(data.nextBillingAt);
        this.pauseDate = moment(data.pauseDate);
        this.resumeDate = moment(data.resumeDate);
    }
    id: string;
    currencyCode: string;
    status: ChargebeeSubscriptionStatus;
    trialEndAction: TrialEndAction;
    nextBillingAt?: moment.Moment;
    pauseDate?: moment.Moment;
    resumeDate?: moment.Moment;
}

export class InvoiceEstimate {
    constructor(data: typeof InvoiceEstimateApiResponseExample) {
        this.recurring = data.recurring;
        this.priceType = data.priceType;
        this.currencyCode = data.currencyCode;
        this.subTotal = data.subTotal;
        this.taxTotal = data.taxTotal;
        this.total = data.total;
        this.creditsApplied = data.creditsApplied;
        this.amountPaid = data.amountPaid;
        this.amountDue = data.amountDue;
        this.roundOffAmount = data.roundOffAmount;
        this.customerId = data.customerId;
        this.discounts = data.lineItemDiscounts.map((li) => new ChargebeeLineItemDiscount(li));
        this.lineItems = data.lineItems.map((o) => new ChargebeeLineItem(o));
    }
    recurring: boolean;
    priceType: ChargebeePriceType;
    currencyCode: string;
    subTotal: number;
    total?: number;
    taxTotal?: number;
    creditsApplied?: number;
    amountPaid?: number;
    amountDue?: number;
    roundOffAmount?: number;
    customerId: string;
    discounts: ChargebeeLineItemDiscount[];
    lineItems: ChargebeeLineItem[];
}

export class ChargebeeLineItemDiscount {
    constructor(data: (typeof InvoiceEstimateApiResponseExample.lineItemDiscounts)[0]) {
        this.discountAmount = data.discountAmount;
        this.discountType = data.discountType;
        this.entityId = data.entityId;
        this.lineItemId = data.lineItemId;
    }
    discountAmount: number;
    discountType: DiscountType;
    entityId: string;
    lineItemId: string;
}

export class ChargebeeLineItem {
    constructor(data: (typeof InvoiceEstimateApiResponseExample.lineItems)[0]) {
        this.id = data.id;
        this.subscriptionId = data.subscriptionId;
        this.dateFrom = moment(data.dateFrom);
        this.dateTo = moment(data.dateTo);
        this.unitAmount = data.unitAmount;
        this.quantity = data.quantity;
        this.decimal = data.decimal;
        this.pricingModel = data.pricingModel;
        this.isTaxed = data.isTaxed;
        this.taxAmount = data.taxAmount;
        this.taxRate = data.taxRate;
        this.unitAmountInDecimal = data.unitAmountInDecimal;
        this.quantityInDecimal = data.quantityInDecimal;
        this.amount = data.amount;
        this.discountAmount = data.discountAmount;
        this.itemLevelDiscountAmount = data.itemLevelDiscountAmount;
        this.description = data.description;
        this.entityDescription = data.entityDescription;
        this.entityType = data.entityType;
        this.taxExemptReason = data.taxExemptReason;
        this.entityId = data.entityId;
        this.customerId = data.customerId;
        this.displayName = data.displayName;
        this.displayNameDetailed = data.displayNameDetailed;
        this.billingPeriod = data.billingPeriod;
        this.billingPeriodUnit = data.billingPeriodUnit;
    }
    id: string;
    subscriptionId: string;
    dateFrom: moment.Moment;
    dateTo: moment.Moment;
    unitAmount: number;
    quantity?: number;
    decimal?: number;
    pricingModel: PricingModel;
    isTaxed: boolean;
    taxAmount?: number;
    taxRate?: number;
    unitAmountInDecimal: string;
    quantityInDecimal: string;
    amount: number;
    discountAmount?: number;
    itemLevelDiscountAmount?: number;
    description: string;
    entityDescription: string;
    entityType: ChargebeeInvoiceEstimateLineItemEntityType;
    taxExemptReason: TaxExemptReason;
    entityId: string;
    customerId: string;
    displayName: string;
    displayNameDetailed: string;
    billingPeriod?: number;
    billingPeriodUnit?: BillingPeriodUnit;
}

export class IItemUpdateValues {
    itemId: string;
    quantity: number;
    appliedCoupon: string | null;
    customPrice: number | null;
}

export class ItemSelection {
    constructor(itemPrice: ItemPrice, customPrice: number | null) {
        this.itemId = itemPrice.subscriptionItem.itemId;
        this.itemPriceId = itemPrice.id;
        this.itemType = itemPrice.itemType;
        this.itemQuantity = itemPrice.subscriptionItem.quantity;
        this.itemPrice = customPrice;
        this.metered = itemPrice.metered;
        this.hasTrainingUpgrades = itemPrice.subscriptionItem.hasTrainingUpgrades;
    }
    itemId: string;
    itemPriceId: string;
    itemType: ItemType;
    itemQuantity: number | undefined;
    itemPrice: number | null;
    metered: boolean;
    hasTrainingUpgrades: boolean;
}

export interface ISubscriptionSelections {
    itemSelections: ItemSelection[];
    couponIds: string[];
    comments?: string;
}

export interface IAddOrUpdateSubscriptionItemsRequest {
    subscriptionSelections: ISubscriptionSelections;
    subscriptionId: string;
    builderId: number;
    isBillingCycleChange?: boolean;
    comments?: string;
}

export class AddOrUpdateSubscriptionItemsResponse {
    constructor(data: any) {
        this.subscription = new SubscriptionObject(data.subscription);
        this.errorMessage = data.errorMessage;
    }

    subscription: SubscriptionObject;
    errorMessage: string;
}

export interface IGetSubscriptionFromSelectionsRequest {
    subscriptionSelections: ISubscriptionSelections;
}

export class GetSubscriptionFromSelectionsResponse {
    constructor(data: any) {
        this.subscription = new SubscriptionObject(data.subscription);
    }

    subscription: SubscriptionObject;
}

export interface IGetEstimateForSubscriptionRequest {
    subscriptionSelections: ISubscriptionSelections;
    subscriptionId: string;
    builderId: number | undefined;
    isBillingCycleChange?: boolean;
}

export interface ISetLoginsDisabledRequest {
    builderId: number;
    loginsDisabled: boolean;
}

export class GetEstimateForSubscriptionResponse {
    constructor(data: any) {
        this.nextBillingCycleEstimate = new InvoiceEstimate(data.nextBillingCycleEstimate);
        if (data.proratedEstimate) {
            this.proratedEstimate = new InvoiceEstimate(data.proratedEstimate);
        }
        this.subscriptionEstimate = new SubscriptionEstimate(data.subscriptionEstimate);
        this.buildertrendTermsLink = data.buildertrendTermsLink;
    }

    nextBillingCycleEstimate: InvoiceEstimate;
    proratedEstimate?: InvoiceEstimate;
    subscriptionEstimate: SubscriptionEstimate;
    buildertrendTermsLink: string;
}

export class SubscriptionJobUsageInformation {
    constructor(data: any) {
        this.jobsStarted = data.jobsStarted;
        this.currentJobLimit = data.currentJobLimit;
        this.jobStartResetDate = moment(data.jobStartResetDate);
    }

    jobsStarted: number;
    currentJobLimit?: number;
    jobStartResetDate: moment.Moment;
}

export class ChurnAuth {
    constructor(data: any) {
        this.customerHash = data.customerHash;
        this.appId = data.appId;
        this.mode = data.mode;
        this.provider = data.provider;
    }

    customerHash: string;
    appId: string;
    mode: string;
    provider: string;
}

export class ChurnSegmentationAttributes {
    constructor(data: any) {
        this.activeSinceDate = moment(data.activeSinceDate);
        this.excludeFromRollingPriceIncrease = data.excludeFromRollingPrice;
        this.hasChargeback = data.hasChargeback;
        this.hasPriceOverride = data.hasPriceOverride;
        this.isPastDue = data.isPastDue;
        this.journeyStage = data.journeyStage;
        this.lastSuspensionEnd = moment(data.lastSuspensionEnd);
        this.inMBGPeriod = data.inMBGPeriod;
        this.numberOfPastDue = data.numberOfPastDue;
        this.isSurveyOnly = data.isSurveyOnly;
        this.totalMBGDays = data.totalMBGDays;
        this.daysToMBGExpiration = data.daysToMBGExpiration;
    }

    activeSinceDate: moment.Moment;
    excludeFromRollingPriceIncrease: boolean;
    hasChargeback: boolean;
    hasPriceOverride: boolean;
    isPastDue: boolean;
    journeyStage: string;
    lastSuspensionEnd?: moment.Moment;
    inMBGPeriod: boolean;
    numberOfPastDue: number;
    isSurveyOnly: boolean;
    totalMBGDays: number;
    daysToMBGExpiration: number;
}

export class WriteOffPastDueResponse {}

export interface ISubscriptionDetailsFormValues {
    comments: string;
    annualBilling: boolean;
    billingCycle: number;
    billingPeriodUnit: number;
    planUpdateValues: IItemUpdateValues;
    addonUpdateValues: IItemUpdateValues[];
}

export interface ISubscriptionInvoiceWriteOffFormValues {
    comment: string;
}

export interface ISubscriptionDetailsEntity {
    currentSubscription: SubscriptionObject;
    availablePlans: Plan[];
    availableAddons: Addon[];
    visibleAddons: Addon[];
    availableCoupons: BTSelectItem<IHasCouponExtraData>[] | undefined;
    showAdditionalOptions: boolean;
}

export class SubscriptionDeleteResponse {}

export class SubscriptionCreateResponse {}

export class SetLoginsDisabledResponse {}

export class SetDataRetrievalResponse {}

export type SubscriptionDetailsActions = "dataRetrieval" | undefined;

export class RemoveScheduledChangesResponse {}

export class UpdateCustomerAccountTypeResponse {}

export class SubscriptionCancellationRequest {
    contactId: number | undefined;
    builderId: number;
    subscriptionId: string;
    cancellationReason: string;
}

export class ApplyCouponToSubscriptionRequest {
    builderId: number;
    subscriptionId: string;
    couponId: string;
    loginId?: number;
}

export class BillingPeriodAndUnit {
    billingPeriod: number;
    billingPeriodUnit: BillingPeriodUnit;
}

export type SubscriptionListActions =
    | "suspend"
    | "upgrade"
    | "cancel"
    | "reload"
    | "freezeAccount"
    | "writeOff"
    | "create"
    | "removeCancellation"
    | "closeCancellation"
    | "removeScheduledChanges"
    | "dataRetrieval"
    | "updateCustomerAccountType"
    | undefined;
