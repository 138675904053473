import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { Component } from "react";

import { DocumentInstanceType } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { triggerBrowserDownload } from "utilities/document/fileDownload.utils";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import {
    BTIconDownloadOutlined,
    BTIconExport,
    BTIconFileExcelFilled,
    BTIconFileFilled,
    BTIconFileImageFilled,
    BTIconFilePdfFilled,
    BTIconFilePptFilled,
    BTIconFileVideo,
    BTIconFileWordFilled,
    BTIconPaperClipOutlined,
} from "commonComponents/btWrappers/BTIcon";
import { BTLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox";
import { mediaDataToSlide } from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSpin } from "commonComponents/btWrappers/BTSpin/BTSpin";
import { LazyLoadContextMenu } from "commonComponents/entity/relatedItem/RelatedItemContextMenu/RelatedItemContextMenu";
import { CertificateType } from "commonComponents/entity/sub/CertificateFile/CertificateFile.types";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import {
    DocumentContextMenuHandler,
    IDocumentContextMenuHandler,
} from "commonComponents/utilities/DocumentContextMenu/DocumentContextMenu.api.handler";
import {
    ContextMenuItemType,
    IContextMenuItem,
} from "commonComponents/utilities/DocumentContextMenu/DocumentContextMenu.types";
import { MediaModalType } from "commonComponents/utilities/MediaModal/common/types";
import { IMediaData } from "commonComponents/utilities/MediaModal/common/types";

import {
    BulkDownloadHandler,
    IBulkDownloadHandler,
} from "entity/media/common/BulkDownload.api.handler";
import { IDownloadZipOfAttachedFilesForEntityRequest } from "entity/media/common/mediaTypes";

interface IDocumentContextMenuProps {
    entityId: number;
    entityType: DocumentInstanceType | CertificateType;
    docCount: number;
    overlayClassName?: string;
    handler?: IDocumentContextMenuHandler;
    bulkDownloadHandler?: IBulkDownloadHandler;
}

export class DocumentContextMenuProps {
    constructor(data: any) {
        this.entityId = data.entityId;
        this.entityType = data.entityType;
        this.docCount = data.docCount;
    }
    entityId: number;
    entityType: DocumentInstanceType | CertificateType;
    docCount: number;
}

interface IDocumentContextMenuState {
    menuItems: IContextMenuItem[] | undefined;
    mediaItems: IMediaData[] | undefined;
    isMediaVisible: boolean;
    mediaModalIndex: number;
}

class DocumentContextMenuInternal extends Component<
    IDocumentContextMenuProps,
    IDocumentContextMenuState
> {
    static defaultProps = {
        handler: new DocumentContextMenuHandler(),
        bulkDownloadHandler: new BulkDownloadHandler(),
    };

    state: Readonly<IDocumentContextMenuState> = {
        menuItems: undefined,
        mediaItems: undefined,
        isMediaVisible: false,
        mediaModalIndex: 0,
    };

    componentDidUpdate = (prevProps: IDocumentContextMenuProps) => {
        if (
            prevProps.entityId !== this.props.entityId ||
            prevProps.docCount !== this.props.docCount
        ) {
            this.resetMenu();
        }
    };

    private resetMenu = () => {
        this.setState({ menuItems: undefined });
    };

    private setMediaVisible = (value: boolean, openIndex: number) => {
        this.setState({ isMediaVisible: value, mediaModalIndex: openIndex });
    };

    private handleMediaClose = () => {
        this.setState({ isMediaVisible: false });
    };

    private handleSlideChange = (index: number) => {
        this.setState({ mediaModalIndex: index });
    };

    private getMenuItems = async () => {
        const { handler, entityId, entityType } = this.props;
        if (!this.state.menuItems) {
            const contextMenu = await handler!.GetDocumentContextMenu({
                associatedEntityId: entityId,
                docType: entityType,
            });

            if (contextMenu.contextMenuItems.length > 0) {
                const mediaItems: IMediaData[] = [];
                // add download menu item
                this.addDownloadAllMenuItem(contextMenu.contextMenuItems);
                this.processMenuItems(
                    contextMenu.contextMenuItems,
                    mediaItems,
                    entityType,
                    entityId
                );

                this.setState({ menuItems: contextMenu.contextMenuItems, mediaItems });
            } else {
                throw new Error("No documents were found for this entity");
            }
        }
    };

    private addDownloadAllMenuItem(menuItems: IContextMenuItem[]) {
        if (menuItems && menuItems.length > 0) {
            let videoCount = menuItems.filter((m) => {
                if (m.itemType === ContextMenuItemType.Video) {
                    return m;
                }
                return false;
            }).length;

            let totalCount = menuItems.length - videoCount;

            if (totalCount > 1) {
                let downloadMenuItem: IContextMenuItem = {
                    itemId: 0,
                    itemName: `Download All (${totalCount})`,
                    itemType: ContextMenuItemType.DownloadAll,
                    jobId: menuItems[0].jobId,
                    source: "",
                    downloadLink: "",
                    is360Media: false,
                    isMediaAvailable: true,
                };
                menuItems.unshift(downloadMenuItem);
            }
        }
    }

    private downloadAttachedFiles = async (
        entityType: DocumentInstanceType,
        entityId: number,
        jobId: number
    ) => {
        const { bulkDownloadHandler } = this.props;

        let request: IDownloadZipOfAttachedFilesForEntityRequest = {
            documentInstanceType: entityType,
            entityId: entityId,
            jobId: jobId,
        };

        try {
            const response = await bulkDownloadHandler!.downloadZipOfAttachedDocsForEntity(request);
            triggerBrowserDownload(response.blobData, response.fileName);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private processMenuItems = (
        menuItems: IContextMenuItem[],
        mediaItems: IMediaData[],
        entityType: DocumentInstanceType | CertificateType,
        entityId: number
    ) => {
        menuItems.forEach((menuItem) => {
            // set defaults
            menuItem.callback = () => {
                window.location.assign(menuItem.downloadLink);
            };
            menuItem.icon = <BTIconFileFilled />;

            switch (menuItem.itemType) {
                case ContextMenuItemType.Pdf:
                    menuItem.icon = <BTIconFilePdfFilled />;
                    menuItem.callback = () => {};
                    break;

                case ContextMenuItemType.Photo:
                    menuItem.icon = <BTIconFileImageFilled />;
                    menuItem.mediaIndex = mediaItems.length;
                    mediaItems.push({
                        mediaInfo: {
                            documentInstanceId: menuItem.itemId,
                            jobId: menuItem.jobId,
                        },
                        fileName: menuItem.itemName,
                        source: menuItem.source,
                        type: menuItem.is360Media ? MediaModalType.image360 : MediaModalType.image,
                    });

                    menuItem.callback = () => {
                        this.setMediaVisible(true, menuItem.mediaIndex!);
                    };
                    break;

                case ContextMenuItemType.Video:
                    menuItem.icon = <BTIconFileVideo />;
                    menuItem.mediaIndex = mediaItems.length;
                    mediaItems.push({
                        mediaInfo: {
                            documentInstanceId: menuItem.itemId,
                            jobId: menuItem.jobId,
                        },
                        fileName: menuItem.itemName,
                        source: menuItem.videoId ? menuItem.videoId.toString() : "",
                        type: MediaModalType.video,
                    });

                    menuItem.callback = () => {
                        this.setMediaVisible(true, menuItem.mediaIndex!);
                    };
                    break;

                case ContextMenuItemType.Excel:
                    menuItem.icon = <BTIconFileExcelFilled />;
                    break;

                case ContextMenuItemType.Powerpoint:
                    menuItem.icon = <BTIconFilePptFilled />;
                    break;

                case ContextMenuItemType.Word:
                    menuItem.icon = <BTIconFileWordFilled />;
                    break;

                case ContextMenuItemType.DownloadAll:
                    menuItem.icon = <BTIconDownloadOutlined />;
                    menuItem.title = "Videos cannot be downloaded at this time.";
                    menuItem.callback = async () => {
                        let documentInstanceType: DocumentInstanceType =
                            entityType as DocumentInstanceType;
                        await this.downloadAttachedFiles(
                            documentInstanceType,
                            entityId,
                            menuItem.jobId!
                        );
                    };
                    break;

                default:
                    menuItem.icon = <BTIconFileFilled />;
            }
        });
    };

    private handleMenuClick: MenuClickEventHandler = ({ key }) => {
        const clickedMenuItem = this.state.menuItems!.find(
            (item) => item.itemId.toString() === key
        );
        if (clickedMenuItem) {
            if (clickedMenuItem.callback) {
                clickedMenuItem.callback();
            } else {
                throw new Error("No menu action found");
            }
        }
    };

    private getMenuItemText = (item: IContextMenuItem) => {
        return (
            <>
                {item.icon} {item.itemName} {!item.isMediaAvailable && "(Processing)"}
            </>
        );
    };

    render() {
        const { docCount, entityId, overlayClassName } = this.props;

        // Hide context menu if there are no files
        if (docCount === 0) {
            return null;
        }

        let menu = (
            <BTMenu>
                <BTMenuItem
                    data-testid={`document-menu-${entityId}`}
                    key={`document-menu-${entityId}`}
                >
                    <BTRow align="middle" justify="center">
                        <BTCol>
                            <BTSpin />
                        </BTCol>
                    </BTRow>
                </BTMenuItem>
            </BTMenu>
        );
        if (this.state.menuItems) {
            const menuDisplayItems: JSX.Element[] = [];

            this.state.menuItems.forEach((item) => {
                if (item.itemType === ContextMenuItemType.Pdf) {
                    menuDisplayItems.push(
                        <SubMenu
                            popupClassName={overlayClassName}
                            key={item.itemId}
                            title={this.getMenuItemText(item)}
                        >
                            <BTMenuItem
                                data-testid={`download-${item.itemId}`}
                                key={`download-${item.itemId}`}
                            >
                                <a href={item.downloadLink}>
                                    <BTIconDownloadOutlined /> Download
                                </a>
                            </BTMenuItem>
                            <Menu.Divider />
                            <BTMenuItem
                                data-testid={`open-new-tab-${item.itemId}`}
                                key={`open-new-tab-${item.itemId}`}
                            >
                                <BTExternalLink href={item.source} isUnderline={false}>
                                    <BTIconExport rotate={90} /> Open New Tab
                                </BTExternalLink>
                            </BTMenuItem>
                        </SubMenu>
                    );
                } else {
                    menuDisplayItems.push(
                        <BTMenuItem
                            data-testid={`docMenuItem-${item.itemId}`}
                            key={item.itemId}
                            title={item.title}
                            disabled={!item.isMediaAvailable}
                        >
                            {this.getMenuItemText(item)}
                        </BTMenuItem>
                    );
                }
            });

            menu = <BTMenu onClick={this.handleMenuClick}>{menuDisplayItems}</BTMenu>;
        }

        return (
            <>
                <LazyLoadContextMenu
                    menu={menu}
                    lazyLoadMenu={this.getMenuItems}
                    entityId={this.props.entityId}
                    className="DocumentContextMenu"
                >
                    <BTIconPaperClipOutlined />
                    {this.props.docCount}
                </LazyLoadContextMenu>
                <BTLightbox
                    slides={this.state.mediaItems?.map(mediaDataToSlide) || []}
                    open={this.state.isMediaVisible}
                    onClose={this.handleMediaClose}
                    index={this.state.mediaModalIndex}
                    onSlideChange={this.handleSlideChange}
                />
            </>
        );
    }
}

export const DocumentContextMenu = withErrorBoundary(DocumentContextMenuInternal)(
    "Could not load context menu"
);
