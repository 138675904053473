import { isObject as _isObject, isArray, isEqual, uniq } from "lodash-es";
import moment from "moment";

import { IHash } from "types/IHash";

import { Falsy } from "utilities/type/Falsy";

/**
 * Deep clones an object
 * @param objectToClone object to clone
 */
export function deepClone<T>(objectToClone: T): T {
    return JSON.parse(JSON.stringify(objectToClone));
}

/**
 * Checks if an object is null or undefined
 * @param obj object check
 */
export function isNullOrUndefined(obj: any): obj is null | undefined;
export function isNullOrUndefined<DefinedType, UndefinedType extends null | undefined>(
    obj: DefinedType | UndefinedType
): obj is UndefinedType {
    return obj === undefined || obj === null;
}

export function isTruthy<T>(value: T | Falsy): value is T {
    return Boolean(value);
}

/**
 * Forces strings to be a property on an object.
 * Useful when you have a 3rd party class that accepts a property name, but is not generic to keys on the object
 *
 * @example
 * nameOf<PurchaseOrderPaymentLineItem>("lineItemPaymentSummary")
 */
export function nameOf<T>(name: keyof T) {
    // Forces valid values for tables
    return name;
}

/**
 * Returns boolean check for an object
 *
 * @example
 * if(isObject(maybeSomeObject)) {...}
 */
export function isObject(obj: unknown): obj is object {
    return typeof obj === "object" && obj !== null;
}

/**
 * Checks if a variable is an object and that object contains a key
 * Useful for working with unknown typed error objects
 *
 * @example
 * if(isObjectWithKey(error, "stack") {console.log(error.stack);}
 */
export function isObjectWithKey<TKey extends string | number | symbol = string>(
    obj: unknown,
    key: TKey
): obj is { [k in TKey]: unknown } {
    return isObject(obj) && Boolean((obj as any)[key]);
}

export type ObjectDiff<Type> = {
    [K in keyof Type]?: Type[K] extends object ? ObjectDiff<Type[K]> : boolean;
};

/**
 * Map with boolean values indicating if a key differs between two objects of the same type.
 * Map follows the same structure as the compared objects.
 */
export function getObjectDiff<Type extends Object>(
    objectA: Type,
    objectB: Type,
    ignoreKeys?: string[],
    parent?: string
): [ObjectDiff<Type>, boolean, string[]] {
    const result = {};
    let hasChange = false;
    const keys: string[] = [];
    const ignoreDirtyTracking = (ignoreKeys: string[], key: string) => {
        if (ignoreKeys.length === 0) {
            return false;
        }
        if (ignoreKeys.includes(key)) {
            return true;
        }
        const splittedKeys = key.split(".");
        if (splittedKeys.length > 1) {
            const k = splittedKeys
                .map((x) => {
                    // if the key is in format of lineItem.0.name, convert to lineItem.*.name
                    if (!isNaN(Number(x))) {
                        return "*";
                    }
                    return x;
                })
                .join(".");
            return ignoreKeys.includes(k);
        }
        return false;
    };
    uniq([...Object.keys(objectA ?? {}), ...Object.keys(objectB ?? {})]).forEach((k) => {
        // If a or b is missing keys, make sure to compare to undefined
        const valueA = (objectA ?? {})[k];
        const valueB = (objectB ?? {})[k];

        let r: ObjectDiff<Type> | boolean = false;
        let change = false;
        const key = parent ? `${parent}.${k}` : k;
        if (ignoreDirtyTracking(ignoreKeys ?? [], key)) {
            // Declare any ignored key as false
            r = change = false;
        } else if (moment.isMoment(valueA) || moment.isMoment(valueB)) {
            if (moment.isMoment(valueA) && moment.isMoment(valueB)) {
                // Use moment functions to compare dates
                r = change = !(valueA as moment.Moment).isSame(valueB);
            } else {
                // Date was cleared/filled in
                r = change = true;
            }
        } else if (_isObject(valueA) || isArray(valueA)) {
            if ((_isObject(valueA) && _isObject(valueB)) || (isArray(valueA) && isArray(valueB))) {
                // For nested objects/arrays, return object with child diffs
                const [subr, subchange, subkeys] = getObjectDiff(valueA, valueB, ignoreKeys, key);
                r = subr;
                change = subchange;
                keys.push(...subkeys);
            } else {
                // Value was cleared/filled in
                r = change = true;
            }
        } else {
            // Compare raw values
            r = change = !isEqual(valueA, valueB);
        }

        hasChange = hasChange || change;
        result[k] = r;
        if (typeof r === "boolean" && r) {
            keys.push(key);
        }
    });

    return [result, hasChange, keys];
}

/**
 * Recursive Object Key
 * @param rows
 * @param keyed
 * @param keyBy
 * @param childField
 */
export function keyByRecursive<T>(
    rows: T[],
    keyBy: keyof T,
    childField: keyof T,
    keyed?: IHash<T>,
    fn?: (row: T) => void
): IHash<T> {
    keyed = keyed || {};
    for (const row of rows) {
        if (row) {
            if (row[keyBy]) {
                keyed[row[keyBy] as unknown as string] = row;
            }
            fn?.(row);
            if (row[childField]) {
                keyByRecursive(row[childField] as unknown as T[], keyBy, childField, keyed, fn);
            }
        }
    }
    return keyed;
}

export function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find((key) => object[key] === value);
}
