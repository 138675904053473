import { Helmet } from "react-helmet";

interface IPageTitleProps {
    title: string;
}

const PageTitle = (props: IPageTitleProps) => {
    let title = props.title;
    if (title !== "Buildertrend") {
        title = `${title} | Buildertrend`;
    }
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default PageTitle;
