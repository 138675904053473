// eslint-disable-next-line no-restricted-imports
import { GridCellProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import classNames from "classnames";
import { PureComponent } from "react";

import { IBaseEntity } from "types/apiResponse/apiResponse";

import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { IGridRowData, IGroupedRowData } from "commonComponents/utilities/Grid/GridContainer.types";

export interface IBTGridSelectCellProps extends Omit<GridCellProps, "field"> {
    isSelected: boolean;
    isTabbable: boolean;
    disabled?: boolean;

    field: {
        path: string;
        name: string;
        full: string;
    };

    /** pass formik setFieldValue */
    setFieldValue: (field: string, value: any) => void;
}

type IGridSelectableRow = IGridRowData | IGroupedRowData;

export function isGridGroupSelectableRow(
    data: IGridRowData | IGroupedRowData
): data is IGroupedRowData;
export function isGridGroupSelectableRow(data: IGridSelectableRow): data is IGroupedRowData {
    return data && !!(data as IGroupedRowData).items;
}

/**
 * Mutates the dataItem and sets the selected flag on the current dataItem. This creates a copy of the originalItem so that we do not reset the state when checking/unchecking items.
 * @param dataItem item that was selected/deselected
 * @param checkToggle indicates that item(s) should be selected/deselected
 */
export function setSelected(
    dataItem: IGridSelectableRow,
    checkToggle: boolean
): any | IGroupedRowData;
export function setSelected(dataItem: any | IGroupedRowData, checkToggle: boolean): void {
    let newItem = dataItem;
    if (newItem.isSelected !== checkToggle) {
        newItem = { ...dataItem, isSelected: checkToggle };
    }
    if (isGridGroupSelectableRow(dataItem)) {
        newItem.items = newItem.items.map((innerDataItem: IGridSelectableRow) =>
            setSelected(innerDataItem, checkToggle)
        );
    }
    return newItem;
}

export function getSelectedEntities(dataItem: IGridRowData | IGroupedRowData): IBaseEntity[];
export function getSelectedEntities(dataItem: any | IGroupedRowData): IBaseEntity[] {
    let result: IBaseEntity[] = [];
    if (isGridGroupSelectableRow(dataItem)) {
        result = [
            ...result,
            ...dataItem.items.flatMap((innerDataItem) => getSelectedEntities(innerDataItem)),
        ];
    } else if (dataItem.isSelected) {
        if ((dataItem as IBaseEntity).id) {
            result.push({
                id: dataItem.id,
                builderId: dataItem.builderId,
                jobId: dataItem.jobId,
            } as IBaseEntity);
        }
    }
    return result;
}

export class BTGridSelectCell extends PureComponent<IBTGridSelectCellProps> {
    private handleChange = (e: CheckboxChangeEvent) => {
        this.props.selectionChange!({
            // Kendo expects a syntheticEvent here, but antd components return their own event types.  We will handle this type on the handler side in the grid components.
            syntheticEvent: e as any,
        });
    };

    componentDidUpdate() {
        const { isSelected, field, dataItem } = this.props;

        // in the case of rendering groups, we need to reactively check the state of our items
        // and determine if our group level needs to derive a new checked/unchecked state.
        if (isGridGroupSelectableRow(dataItem)) {
            let areAllChildrenSelected =
                dataItem.items.length > 0 && dataItem.items.every((item) => item.isSelected);
            if (!isSelected && areAllChildrenSelected === true) {
                this.props.setFieldValue(field.full, true); // force true if we find this case.
            } else if (isSelected && !areAllChildrenSelected && dataItem.items.length > 0) {
                this.props.setFieldValue(field.full, false);
            }
        }
    }

    render() {
        const { isSelected, isTabbable, field, className, style, colSpan, disabled } = this.props;

        return (
            <td
                className={classNames(className, "BTGridSelectCell text-center")}
                style={style}
                colSpan={colSpan}
            >
                <BTCheckbox
                    className="BTGridSelectCellCheckbox"
                    id={field.full}
                    data-testid={field.full}
                    checked={isSelected}
                    onChange={this.handleChange}
                    tabIndex={isTabbable ? 0 : -1}
                    disabled={disabled}
                />
            </td>
        );
    }
}

interface IBTGridHeaderSelectCellProps extends GridHeaderCellProps {
    indeterminate?: boolean;
}

export class BTGridHeaderSelectCell extends PureComponent<IBTGridHeaderSelectCellProps> {
    private handleChange = (e: CheckboxChangeEvent) => {
        const { field, selectionChange } = this.props;
        selectionChange({ field, nativeEvent: e.nativeEvent, syntheticEvent: e });
    };

    render() {
        const { field, selectionValue, indeterminate } = this.props;
        return (
            <div style={{ textAlign: "center" }}>
                <BTCheckbox
                    id={field!}
                    data-testid={field!}
                    checked={selectionValue}
                    indeterminate={indeterminate}
                    onChange={this.handleChange}
                    hotkey={{ hotkey: "checkAll", popoverPlacement: "bottom" }}
                />
            </div>
        );
    }
}
