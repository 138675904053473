import { AttachedFiles } from "legacyComponents/FileUploadContainer.types";

import { EmailMessageItem } from "entity/message/common/Message.api.types";
import { formatRecipientNames } from "entity/message/common/message.utils";
import { IToDoCreationData } from "entity/toDo/details/ToDoDetails/ToDo.types";

interface IDailyLogData {
    notes?: string;
    attachedFiles?: AttachedFiles;
}

interface IScheduleData {
    allNotes?: string;
    internalNotes?: string;
    subNotes?: string;
    ownerNotes?: string;
    attachedFiles?: AttachedFiles;
}

export function getToDoCreationDataForDailyLog(data: IDailyLogData): IToDoCreationData | undefined {
    let todoCreationData: IToDoCreationData = {};
    const { notes, attachedFiles } = data;

    if (notes) {
        todoCreationData.notes = `[Daily Log Notes] \n${notes}`;
    }
    if (attachedFiles) {
        todoCreationData.attachedFilesConfiguration = attachedFiles;
    }

    // if nothing is copied return undefined
    if (Object.keys(todoCreationData).length === 0) {
        return undefined;
    }

    return todoCreationData;
}

export function getToDoCreationDataForMessage(message: EmailMessageItem | undefined) {
    let todoCreationData: IToDoCreationData = {};
    if (!message) {
        return undefined;
    }

    todoCreationData.notes = `[From Message] \n${formatToDoNotesFromMessage(message)}`;
    if (message.attachedFiles) {
        todoCreationData.attachedFilesConfiguration = message.attachedFiles;
    }

    // if nothing is copied return undefined
    if (Object.keys(todoCreationData).length === 0) {
        return undefined;
    }

    return todoCreationData;
}

export const formatToDoNotesFromMessage = (message: EmailMessageItem): string => {
    let notes: string[] = [];
    if (message.subject) {
        notes.push(`Subject: ${message.subject}`);
    }
    if (message.from) {
        notes.push(`From: ${message.from}`);
    }
    if (message.toRecipients && message.toRecipients.length > 0) {
        notes.push(`To: ${formatRecipientNames(message.toRecipients)}`);
    }
    if (message.ccRecipients && message.ccRecipients.length > 0) {
        notes.push(`CC: ${formatRecipientNames(message.ccRecipients)}`);
    }
    if (message.messageDate) {
        notes.push(`Sent: ${message.messageDate.format("MMM Do YYYY, h:mmA")}`);
    }
    if (message.bodyForToDo) {
        // This adds an empty line before the body
        notes.push("");
        notes.push(message.bodyForToDo);
    }
    return notes.join("\r\n");
};

export function getToDoCreationDataForSchedule(data: IScheduleData): IToDoCreationData | undefined {
    let todoCreationData: IToDoCreationData = {};
    const { allNotes, internalNotes, subNotes, ownerNotes, attachedFiles } = data;
    let todoNotes = "";
    if (allNotes) {
        todoNotes += `[All Schedule Notes] \n${allNotes}\n\n`;
    }
    if (internalNotes) {
        todoNotes += `[Internal Schedule Notes] \n${internalNotes}\n\n`;
    }
    if (subNotes) {
        todoNotes += `[Sub Schedule Notes] \n${subNotes}\n\n`;
    }
    if (ownerNotes) {
        todoNotes += `[Owner Schedule Notes] \n${ownerNotes}\n\n`;
    }
    if (todoNotes !== "") {
        todoCreationData.notes = todoNotes.slice(0, -2);
    }
    if (attachedFiles) {
        todoCreationData.attachedFilesConfiguration = attachedFiles;
    }

    // if nothing is copied return undefined
    if (Object.keys(todoCreationData).length === 0) {
        return undefined;
    }

    return todoCreationData;
}
