import { Space } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { useState } from "react";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconExternalLink } from "commonComponents/btWrappers/BTIcon";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { MainNavLink } from "commonComponents/utilities/MainNavigation/common/MainNavLink";
import MainNavTooltip from "commonComponents/utilities/MainNavigation/common/MainNavTooltip";
import { NavMenuItemResponse } from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";
import {
    IBTSubMenuItemProps,
    IMenuTabInfo,
    menuItemClass,
    MenuOnClickTypes,
} from "commonComponents/utilities/MainNavigation/MainNavigation.types";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

interface IExpandedMainNavProps {
    customMenuContent?: React.ReactNode;
    iconContent?: React.ReactNode;
    tooltip?: string;
    tooltipPlacement?: TooltipPlacement;
    onVisibleChange?: (visible: boolean) => void;
}

export const MainNavDropdown: React.FunctionComponent<
    IBTSubMenuItemProps & IExpandedMainNavProps
> = (props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const builderInfo = useRequiredContext(BuilderInfoContext);
    const userInfo = useRequiredContext(UserInfoContext);
    const dataForCurrentMenuItem = props.data[props.itemKey];
    const info = props.menuInfo![props.itemKey];
    if (!dataForCurrentMenuItem) {
        return null;
    }

    const orderedKeys = Object.keys(info);
    let dropdownItems = Object.keys(dataForCurrentMenuItem)
        .filter((k) => info[k] !== undefined && k !== "title")
        .filter(
            (k) =>
                dataForCurrentMenuItem[k].visible &&
                (props.isTemplateMode ? info[k].showInTemplateMode?.(props.builderInfo) : true) &&
                (info[k].showOnPortal ? info[k].showOnPortal.includes(props.loginType) : true)
        )
        .sort((a, b) => orderedKeys.indexOf(a) - orderedKeys.indexOf(b));

    if (dropdownItems.length === 0) {
        return null;
    }

    // move upsell tabs to the end
    dropdownItems = [
        ...dropdownItems.filter(
            (item) => (dataForCurrentMenuItem[item] as NavMenuItemResponse).requiredPackage === null
        ),
        ...dropdownItems.filter(
            (item) => (dataForCurrentMenuItem[item] as NavMenuItemResponse).requiredPackage !== null
        ),
    ];

    let menuTitle = dataForCurrentMenuItem.title;
    if (props.isTemplateMode && menuTitle) {
        menuTitle = menuTitle.replace("Job", "Template");
    }

    const hideUpgradeText = menuTitle === "Reports";

    // remove notifications dropdown item since it fully renders in customMenuContent
    dropdownItems = dropdownItems.filter((item) => item !== "notifications");

    const dropdownItemRecord: IMenuTabInfo = info[dropdownItems[0]];
    const navMenuItem: NavMenuItemResponse = dataForCurrentMenuItem[dropdownItems[0]];

    const handleVisibleChange = (visible: boolean) => {
        setDropdownVisible(visible);
        props.onVisibleChange?.(visible);
    };

    const handleMenuClick: MenuClickEventHandler = () => {
        setDropdownVisible(false);
    };

    const button = (
        <BTButton
            data-testid={props["data-testid"]}
            data-intercom-target={props["intercomTarget"]}
            aria-label={props["aria-label"]}
            noShadow
            className={props["className"]}
            onClick={() => setDropdownVisible((prev) => !prev)}
        >
            {props.children}
        </BTButton>
    );

    return props.children ? (
        <BTDropdown
            className="MainNavDropdown"
            overlayClassName="MainNavDropdownMenu"
            getPopupContainer={(trigger) => trigger.parentElement!}
            destroyPopupOnHide={props.destroyPopupOnHide}
            visible={dropdownVisible}
            onVisibleChange={handleVisibleChange}
            overlay={
                props.customMenuContent ? (
                    <>{props.customMenuContent}</>
                ) : (
                    <BTMenu onClick={handleMenuClick}>
                        {dropdownItems.map((dropdownItem) => {
                            const dropdownItemRecord: IMenuTabInfo = info[dropdownItem];
                            const navMenuItem: NavMenuItemResponse =
                                dataForCurrentMenuItem[dropdownItem];

                            const disabledClass =
                                dropdownItemRecord.getIsDisabled?.(userInfo) ||
                                (dropdownItemRecord.enableForSingleJobOnly && props.jobId <= 0)
                                    ? "MenuItemDisabled"
                                    : undefined;

                            const menuTitle =
                                dropdownItemRecord.getTitle?.(
                                    navMenuItem.title,
                                    props.isTemplateMode
                                ) ?? navMenuItem.title;

                            const menuItemContent = (
                                <MainNavLink
                                    info={dropdownItemRecord}
                                    data={props.data}
                                    navMenuItem={navMenuItem}
                                    jobId={props.jobId}
                                    isTemplateMode={props.isTemplateMode}
                                    loginType={props.loginType}
                                    onContentClick={props.onContentClick}
                                    aria-label={menuTitle}
                                    data-testid={dropdownItem}
                                    builderInfo={props.builderInfo}
                                    userInfo={props.userInfo}
                                    appDefaultInfo={props.appDefaultInfo}
                                    environmentInfo={props.envInfo}
                                >
                                    <div className="MainNavMenuItemContent">
                                        <Space size={4}>
                                            <>
                                                {dropdownItemRecord.icon}
                                                <div style={{ height: "100%" }}>{menuTitle}</div>
                                            </>
                                            {dropdownItemRecord.tag}
                                            <ShowOnPortal
                                                builder={navMenuItem.requiredPackage !== null}
                                                render={() => (
                                                    <StatusTagDisplay
                                                        statusType="info"
                                                        statusText="UPGRADE"
                                                    />
                                                )}
                                            />
                                        </Space>
                                        {dropdownItemRecord.onClickType?.(
                                            builderInfo,
                                            props.userInfo
                                        ) === MenuOnClickTypes.External && <BTIconExternalLink />}
                                    </div>
                                </MainNavLink>
                            );

                            return (
                                <BTMenuItem
                                    data-testid={dropdownItem}
                                    key={dropdownItem}
                                    className={disabledClass}
                                >
                                    <div className="MainNavMenuItemContainer">
                                        <div className={classNames(menuItemClass, "flex-grow-1")}>
                                            {menuItemContent}
                                        </div>
                                    </div>
                                </BTMenuItem>
                            );
                        })}
                    </BTMenu>
                )
            }
        >
            {props.tooltip ? (
                <MainNavTooltip
                    title={props.tooltip}
                    className="MainNavDropdown"
                    enabled={!dropdownVisible}
                    placement={props.tooltipPlacement}
                >
                    {button}
                </MainNavTooltip>
            ) : (
                button
            )}
        </BTDropdown>
    ) : (
        <BTButton
            className="MainNavDropdown"
            data-testid={props["data-testid"]}
            aria-label={props["aria-label"]}
            noShadow
        >
            <MainNavLink
                info={dropdownItemRecord}
                data={props.data}
                navMenuItem={navMenuItem}
                jobId={props.jobId}
                isTemplateMode={props.isTemplateMode}
                loginType={props.loginType}
                onContentClick={props.onContentClick}
                builderInfo={props.builderInfo}
                userInfo={props.userInfo}
                appDefaultInfo={props.appDefaultInfo}
            >
                <div className="MainNavMenuButton">
                    <Space size={4}>
                        {props.iconContent ?? <div>{menuTitle}</div>}
                        <ShowOnPortal
                            builder={navMenuItem.requiredPackage !== null}
                            render={() =>
                                !hideUpgradeText && (
                                    <StatusTagDisplay statusType="info" statusText="UPGRADE" />
                                )
                            }
                        />
                    </Space>
                </div>
            </MainNavLink>
        </BTButton>
    );
};
