import classNames from "classnames";

import "./Status.less";
import "./StatusDisplay.less";

export type StatusTypes = "success" | "danger" | "pending" | "emergency" | "info" | "";

export interface IStatusDisplayProps {
    statusType: StatusTypes;
    details?: React.ReactNode;
    className?: string;
    "data-testid"?: string;
    /**
     * Sets the way the status containing div is displayed
     *
     * **The "absolute" value is being deprecated, set to "relative" for display and opt-in to flexExtra on PageSection**
     * @default "absolute"
     */
    displayMode?: "absolute" | "relative";
    actionButtons?: React.ReactNode;
    children: React.ReactNode;
}

export function StatusDisplay(props: IStatusDisplayProps) {
    const {
        className,
        displayMode = "absolute",
        details,
        actionButtons,
        statusType,
        children,
        "data-testid": dataTestId,
    } = props;

    return (
        <div
            className={classNames("StatusDisplay status-text normal-text", className, {
                // add additional css class for fixed or modal mode
                "status-absolute": displayMode === "absolute",
            })}
            data-testid={dataTestId}
        >
            <span className="StatusDisplay--Details status-text status-details">{details}</span>
            {actionButtons}
            <div className={`StatusDisplay--Tag status-text status-tag ${statusType}`}>
                {children}
            </div>
        </div>
    );
}
