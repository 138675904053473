export enum EmailMessagesFilterTypes {
    Keywords = 0,
    From = 1,
    To = 2,
    StartDate = 3,
    EndDate = 4,
    Attachments = 5,
    DateRange = 6,
    MessageStatus = 7,
    Folder = 8,
    EmailMessageType = 9,
}
