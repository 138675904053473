import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { IMediaListHandler, MediaListHandler } from "entity/media/common/MediaList.api.handler";
import {
    IGetVideoDownloadLinksRequest,
    VideoDownloadLinksResponse,
} from "entity/video/VideoList/VideoList.api.types";

export interface IVideoListHandler extends IMediaListHandler {
    getVideoDownloadLinks(
        videoIds: number[],
        jobId: number,
        isDeleted?: boolean
    ): Promise<VideoDownloadLinksResponse>;
    renameFile(documentInstanceId: number, title: string): Promise<EmptyResponseEntity>;
    renameFolder(folderId: number, title: string): Promise<EmptyResponseEntity>;
}

export class VideoListHandler extends MediaListHandler implements IVideoListHandler {
    async getVideoDownloadLinks(
        videoIds: number[],
        jobId: number,
        isDeleted?: boolean
    ): Promise<VideoDownloadLinksResponse> {
        const data: IGetVideoDownloadLinksRequest = {
            videoIds,
            jobId,
            isDeleted: isDeleted ?? false,
        };
        return await APIHandler("/api/Videos/GetDownloadLinks", {
            method: "GET",
            data,
            responseType: VideoDownloadLinksResponse,
        });
    }

    async renameFile(documentInstanceId: number, title: string): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Documents/Rename", {
            method: "PUT",
            data: { documentInstanceId, title },
            responseType: EmptyResponseEntity,
        });
    }

    async renameFolder(folderId: number, title: string): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/MediaFolders/RenameFolder", {
            method: "PUT",
            data: { folderId, title },
            responseType: EmptyResponseEntity,
        });
    }
}
