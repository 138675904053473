/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { BodyType, ErrorType } from "../utilities/api/apix-handler";
import type {
    AdminBuildersSearchGetV2Params,
    ExternalBuildersRequest,
    ForbiddenResponse,
} from "./models";
import { ContractorTypes, Types } from "./models";
import type { BuilderDropdownResponseV2, ExternalBuilderResponse } from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Authorization**: `user-bearer`


**Authentication**: `LegacyBearer` | `auth0`


**Policy**: `ExternalUser`


**Stability Level**: `stable`
 * @summary Get information about the Builder the account is currently associated with.
 */
export const buildersGetV2 = (
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalBuilderResponse>(
        { url: `/apix/v2/Builders`, method: "GET", signal },
        options
    );
};

export const getBuildersGetV2QueryKey = () => {
    return [`/apix/v2/Builders`] as const;
};

export const getBuildersGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof buildersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof buildersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getBuildersGetV2QueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof buildersGetV2>>> = ({ signal }) =>
        buildersGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof buildersGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type BuildersGetV2QueryResult = NonNullable<Awaited<ReturnType<typeof buildersGetV2>>>;
export type BuildersGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about the Builder the account is currently associated with.
 */

export function useBuildersGetV2<
    TData = Awaited<ReturnType<typeof buildersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof buildersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getBuildersGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `machine-to-machine-bearer`


**Authentication**: `auth0`


**Policy**: `ExternalService`


**Stability Level**: `stable`
 */
export const buildersPostV2 = (
    externalBuildersRequest: BodyType<ExternalBuildersRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<ExternalBuilderResponse[]>(
        {
            url: `/apix/v2/Builders`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: externalBuildersRequest,
        },
        options
    );
};

export const getBuildersPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof buildersPostV2>>,
        TError,
        { data: BodyType<ExternalBuildersRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof buildersPostV2>>,
    TError,
    { data: BodyType<ExternalBuildersRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof buildersPostV2>>,
        { data: BodyType<ExternalBuildersRequest> }
    > = (props) => {
        const { data } = props ?? {};

        return buildersPostV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type BuildersPostV2MutationResult = NonNullable<Awaited<ReturnType<typeof buildersPostV2>>>;
export type BuildersPostV2MutationBody = BodyType<ExternalBuildersRequest>;
export type BuildersPostV2MutationError = ErrorType<unknown>;

export const useBuildersPostV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof buildersPostV2>>,
        TError,
        { data: BodyType<ExternalBuildersRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof buildersPostV2>>,
    TError,
    { data: BodyType<ExternalBuildersRequest> },
    TContext
> => {
    const mutationOptions = getBuildersPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * **Authorization**: `machine-to-machine-bearer`


**Authentication**: `auth0`


**Policy**: `ExternalService`


**Stability Level**: `stable`
 * @summary Get information about a specified builder.
 */
export const buildersByBuilderIdGetV2 = (
    builderId: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalBuilderResponse>(
        { url: `/apix/v2/Builders/${builderId}`, method: "GET", signal },
        options
    );
};

export const getBuildersByBuilderIdGetV2QueryKey = (builderId: string) => {
    return [`/apix/v2/Builders/${builderId}`] as const;
};

export const getBuildersByBuilderIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
    TError = ErrorType<void>
>(
    builderId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getBuildersByBuilderIdGetV2QueryKey(builderId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>> = ({
        signal,
    }) => buildersByBuilderIdGetV2(builderId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!builderId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type BuildersByBuilderIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>
>;
export type BuildersByBuilderIdGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about a specified builder.
 */

export function useBuildersByBuilderIdGetV2<
    TData = Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
    TError = ErrorType<void>
>(
    builderId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof buildersByBuilderIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getBuildersByBuilderIdGetV2QueryOptions(builderId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 * @summary Gets the list of builders for a builder select component
 */
export const adminBuildersSearchGetV2 = (
    params?: AdminBuildersSearchGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<BuilderDropdownResponseV2>(
        { url: `/apix/v2/admin/Builders/search`, method: "GET", params, signal },
        options
    );
};

export const getAdminBuildersSearchGetV2QueryKey = (params?: AdminBuildersSearchGetV2Params) => {
    return [`/apix/v2/admin/Builders/search`, ...(params ? [params] : [])] as const;
};

export const getAdminBuildersSearchGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
    TError = ErrorType<ForbiddenResponse>
>(
    params?: AdminBuildersSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminBuildersSearchGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminBuildersSearchGetV2>>> = ({
        signal,
    }) => adminBuildersSearchGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminBuildersSearchGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminBuildersSearchGetV2>>
>;
export type AdminBuildersSearchGetV2QueryError = ErrorType<ForbiddenResponse>;

/**
 * @summary Gets the list of builders for a builder select component
 */

export function useAdminBuildersSearchGetV2<
    TData = Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
    TError = ErrorType<ForbiddenResponse>
>(
    params?: AdminBuildersSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminBuildersSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getAdminBuildersSearchGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const getBuildersGetV2ResponseMock = (
    overrideResponse: Partial<ExternalBuilderResponse> = {}
): ExternalBuilderResponse => ({
    builderName: faker.word.sample(),
    businessAddress: {
        city: faker.word.sample(),
        country: faker.word.sample(),
        state: faker.word.sample(),
        street: faker.word.sample(),
        suburb: faker.word.sample(),
        zip: faker.word.sample(),
    },
    contractorType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(ContractorTypes)),
        undefined,
    ]),
    email: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    phone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    revenueEstimate: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.number.int({ min: undefined, max: undefined }), null]),
        undefined,
    ]),
    setupDate: faker.helpers.arrayElement([
        (() => /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        undefined,
    ]),
    userSegment: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.number.int({ min: undefined, max: undefined }), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getBuildersPostV2ResponseMock = (): ExternalBuilderResponse[] =>
    Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
        builderName: faker.word.sample(),
        businessAddress: {
            city: faker.word.sample(),
            country: faker.word.sample(),
            state: faker.word.sample(),
            street: faker.word.sample(),
            suburb: faker.word.sample(),
            zip: faker.word.sample(),
        },
        contractorType: faker.helpers.arrayElement([
            faker.helpers.arrayElement(Object.values(ContractorTypes)),
            undefined,
        ]),
        email: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
        phone: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        revenueEstimate: faker.helpers.arrayElement([
            faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                null,
            ]),
            undefined,
        ]),
        setupDate: faker.helpers.arrayElement([
            (() => /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
            undefined,
        ]),
        userSegment: faker.helpers.arrayElement([
            faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                null,
            ]),
            undefined,
        ]),
    }));

export const getBuildersByBuilderIdGetV2ResponseMock = (
    overrideResponse: Partial<ExternalBuilderResponse> = {}
): ExternalBuilderResponse => ({
    builderName: faker.word.sample(),
    businessAddress: {
        city: faker.word.sample(),
        country: faker.word.sample(),
        state: faker.word.sample(),
        street: faker.word.sample(),
        suburb: faker.word.sample(),
        zip: faker.word.sample(),
    },
    contractorType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(ContractorTypes)),
        undefined,
    ]),
    email: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    phone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    revenueEstimate: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.number.int({ min: undefined, max: undefined }), null]),
        undefined,
    ]),
    setupDate: faker.helpers.arrayElement([
        (() => /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        undefined,
    ]),
    userSegment: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.number.int({ min: undefined, max: undefined }), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getAdminBuildersSearchGetV2ResponseMock = (
    overrideResponse: Partial<BuilderDropdownResponseV2> = {}
): BuilderDropdownResponseV2 => ({
    builderList: faker.helpers.arrayElement([
        {
            message: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            options: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => ({
                        name: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        options: faker.helpers.arrayElement([
                            Array.from(
                                { length: faker.number.int({ min: 1, max: 10 }) },
                                (_, i) => i + 1
                            ).map(() => ({
                                disabled: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                extraData: faker.helpers.arrayElement([
                                    {
                                        [faker.string.alphanumeric(5)]: {},
                                    },
                                    undefined,
                                ]),
                                group: faker.helpers.arrayElement([
                                    faker.helpers.arrayElement([faker.word.sample(), null]),
                                    undefined,
                                ]),
                                groupID: faker.helpers.arrayElement([
                                    faker.number.int({ min: undefined, max: undefined }),
                                    undefined,
                                ]),
                                id: faker.helpers.arrayElement([
                                    faker.number.int({ min: undefined, max: undefined }),
                                    undefined,
                                ]),
                                key: faker.helpers.arrayElement([
                                    faker.helpers.arrayElement([faker.word.sample(), null]),
                                    undefined,
                                ]),
                                name: faker.helpers.arrayElement([
                                    faker.helpers.arrayElement([faker.word.sample(), null]),
                                    undefined,
                                ]),
                                secondaryName: faker.helpers.arrayElement([
                                    faker.helpers.arrayElement([faker.word.sample(), null]),
                                    undefined,
                                ]),
                                selected: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            })),
                            undefined,
                        ]),
                        type: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                    })
                ),
                undefined,
            ]),
            title: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            type: faker.helpers.arrayElement([
                faker.helpers.arrayElement(Object.values(Types)),
                undefined,
            ]),
            validators: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => ({
                        errorMessage: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        type: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                    })
                ),
                undefined,
            ]),
            value: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => faker.number.int({ min: undefined, max: undefined })
                ),
                undefined,
            ]),
        },
        undefined,
    ]),
    ...overrideResponse,
});

export const getBuildersGetV2MockHandler = (
    overrideResponse?:
        | ExternalBuilderResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ExternalBuilderResponse> | ExternalBuilderResponse)
) => {
    return http.get("*/v2/Builders", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getBuildersGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getBuildersPostV2MockHandler = (
    overrideResponse?:
        | ExternalBuilderResponse[]
        | ((
              info: Parameters<Parameters<typeof http.post>[1]>[0]
          ) => Promise<ExternalBuilderResponse[]> | ExternalBuilderResponse[])
) => {
    return http.post("*/v2/Builders", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getBuildersPostV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getBuildersByBuilderIdGetV2MockHandler = (
    overrideResponse?:
        | ExternalBuilderResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ExternalBuilderResponse> | ExternalBuilderResponse)
) => {
    return http.get("*/v2/Builders/:builderId", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getBuildersByBuilderIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminBuildersSearchGetV2MockHandler = (
    overrideResponse?:
        | BuilderDropdownResponseV2
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<BuilderDropdownResponseV2> | BuilderDropdownResponseV2)
) => {
    return http.get("*/v2/admin/Builders/search", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminBuildersSearchGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};
export const getBuildersMock = () => [
    getBuildersGetV2MockHandler(),
    getBuildersPostV2MockHandler(),
    getBuildersByBuilderIdGetV2MockHandler(),
    getAdminBuildersSearchGetV2MockHandler(),
];
