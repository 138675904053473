import classNames from "classnames";
import { ErrorMessage, InjectedFormikProps, withFormik } from "formik";
import { Component } from "react";

import { EmailFolder } from "types/enum";

import { getValidateStatusTouched } from "utilities/form/form";
import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import {
    BTIconDeleteFilled,
    BTIconPlusCircleFilled,
    BTIconSettingFilled,
} from "commonComponents/btWrappers/BTIcon";
import {
    BTIconCompanyInbox,
    BTIconDrafts,
    BTIconLegacyTrash,
    BTIconMessageFolder,
    BTIconMessageInbox,
    BTIconSent,
} from "commonComponents/btWrappers/BTIcon";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import {
    stickyHeaderHeightActions,
    stickyHeaderHeightTitle,
    stickyListLevelActions,
} from "commonComponents/btWrappers/BTListLayout/BTListLayoutHeader";
import { DragAndDrop } from "commonComponents/utilities/DragAndDrop/DragAndDrop";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";

import { MessageFolder } from "entity/message/common/Message.api.types";
import { MessageListActions } from "entity/message/MessageList/MessageList.api.types";

import "./MessageListFolders.less";

export interface IMessageListFoldersProps {
    folders: MessageFolder[];
    selectedFolder: number;
    isEditMode: boolean;
    actionBeingPerformed: MessageListActions;
    onManageFolders: (value: boolean) => void;
    onSaveFolders: (values: IMessageListFoldersFormValues) => Promise<void>;
    onChangeFolder: (folderId: number) => void;
}

export interface IFolderFormValue {
    id: number;
    name: string;
    unreadCount: number;
    deleted: boolean;
}

export interface IMessageListFoldersFormValues {
    folders: IFolderFormValue[];
}

interface IEmailFolderIconProps {
    emailFolder: EmailFolder;
}

const EmailFolderIcon: React.FunctionComponent<IEmailFolderIconProps> = ({ emailFolder }) => {
    switch (emailFolder) {
        case EmailFolder.Drafts:
            return <BTIconDrafts />;
        case EmailFolder.Global:
            return <BTIconCompanyInbox />;
        case EmailFolder.Inbox:
            return <BTIconMessageInbox />;
        case EmailFolder.Sent:
            return <BTIconSent />;
        case EmailFolder.Trash:
            return <BTIconLegacyTrash />;
        default:
            return <BTIconMessageFolder />;
    }
};

class MessageListFoldersInternal extends Component<
    InjectedFormikProps<IMessageListFoldersProps, IMessageListFoldersFormValues>
> {
    private isSystemFolder = (folderId: number) => {
        return Object.values(EmailFolder).includes(folderId);
    };

    private getFolderName = (name: string, unreadCount: number) => {
        let folderName = name;
        if (unreadCount > 0) {
            folderName += ` (${unreadCount})`;
        }

        return folderName;
    };

    private copyFormValues = () => {
        return this.props.values.folders.map((f) => ({
            id: f.id,
            name: f.name,
            unreadCount: f.unreadCount,
            deleted: f.deleted,
        }));
    };

    private handleDeleteFolder = (folderId: number, index: number) => {
        const newValues = this.copyFormValues();
        if (folderId === 0) {
            newValues.splice(index, 1);
        } else {
            newValues[index].deleted = true;
        }
        this.props.setFieldValue("folders", newValues);
    };

    private handleAddFolder = () => {
        this.props.setFieldValue("folders", [
            ...this.copyFormValues(),
            {
                id: 0,
                name: "",
                unreadCount: 0,
            },
        ]);
    };

    private renderFolder = (folder: IFolderFormValue, index: number) => {
        const {
            selectedFolder,
            isEditMode,
            errors,
            touched,
            onChangeFolder,
            setFieldValue,
            setFieldTouched,
        } = this.props;
        const name = this.getFolderName(folder.name, folder.unreadCount);
        const unreadFolderClass = folder.unreadCount > 0 ? "UnreadFolder" : undefined;
        const folderName = `folders.${index}.name`;
        return (
            <div
                key={`folder-${index}`}
                data-folderid={folder.id}
                className={classNames({ SelectedFolder: folder.id === selectedFolder })}
            >
                {(!isEditMode || folder.deleted || this.isSystemFolder(folder.id)) && (
                    <a
                        className={classNames("FolderLink", { DeletedFolder: folder.deleted })}
                        onClick={folder.deleted ? undefined : () => onChangeFolder(folder.id)}
                    >
                        <div className="Folder">
                            <span className="FolderIcon">
                                <EmailFolderIcon emailFolder={folder.id} />
                            </span>
                            <span className={unreadFolderClass}>{name}</span>
                        </div>
                    </a>
                )}
                {isEditMode && !folder.deleted && !this.isSystemFolder(folder.id) && (
                    <div className="FolderRow">
                        <a className="FolderLink">
                            <div className="EditableFolder">
                                <BTFormItem
                                    validateStatus={getValidateStatusTouched(
                                        errors,
                                        touched,
                                        folderName
                                    )}
                                    help={<ErrorMessage name={folderName} />}
                                >
                                    <BTInput
                                        autoFocus={folder.id === 0}
                                        id={folderName}
                                        data-testid={`name_${folder.id}`}
                                        value={folder.name}
                                        onChange={(field, value) => {
                                            setFieldValue(folderName, value);
                                            setFieldTouched(folderName);
                                        }}
                                        onBlur={() => {}}
                                    />
                                </BTFormItem>
                            </div>
                        </a>
                        <BTButton
                            data-testid={`deleteFolder_${folder.id}`}
                            type="link"
                            onClick={() => this.handleDeleteFolder(folder.id, index)}
                            icon={<BTIconDeleteFilled />}
                        />
                    </div>
                )}
            </div>
        );
    };

    private renderFoldersWithDragAndDrop = (folders: IFolderFormValue[], index: number) => {
        return (
            folders.length > 0 && (
                <DragAndDrop
                    list={folders}
                    setList={() => {}}
                    group={{
                        name: "folderList",
                        put: ["messageItem"],
                    }}
                    target
                    hideGhostClass
                    targetClass="message-drag-highlight"
                    className="FolderList"
                >
                    {this.mapFolders(folders, index)}
                </DragAndDrop>
            )
        );
    };

    private renderFolders = (folders: IFolderFormValue[], index: number) => {
        return (
            folders.length > 0 && (
                <div className="FolderList">{this.mapFolders(folders, index)}</div>
            )
        );
    };

    private mapFolders = (folders: IFolderFormValue[], index: number) => {
        return folders.map((x: IFolderFormValue) => {
            return this.renderFolder(x, index++);
        });
    };

    render() {
        const {
            isEditMode,
            actionBeingPerformed,
            selectedFolder,
            values,
            onManageFolders,
            handleSubmit,
        } = this.props;

        const { folders } = values;
        const globalIndex = folders.findIndex((f) => f.id === EmailFolder.Global);
        const inboxIndex = folders.findIndex((f) => f.id === EmailFolder.Inbox);
        const systemFolders = folders.filter(
            (x: IFolderFormValue) =>
                x.id === EmailFolder.Drafts ||
                x.id === EmailFolder.Sent ||
                x.id === EmailFolder.Trash
        );
        const customFolders = folders.filter(
            (x: IFolderFormValue) => !this.isSystemFolder(x.id) && x.id !== 0
        );
        const newFolders = folders.filter((x: IFolderFormValue) => x.id === 0);

        return (
            <div className="MessageListFolders">
                <div className="FolderSection">
                    {this.renderFoldersWithDragAndDrop([folders[inboxIndex]], 0)}
                    {this.renderFolders(systemFolders, 1)}
                    {this.renderFoldersWithDragAndDrop(customFolders, systemFolders.length + 1)}
                    {this.renderFolders(
                        newFolders,
                        globalIndex === -1
                            ? systemFolders.length + customFolders.length + 1
                            : globalIndex + 1
                    )}
                </div>
                {isEditMode && (
                    <div className="FolderFooter">
                        <BTButton
                            data-testid="addFolder"
                            icon={<BTIconPlusCircleFilled />}
                            type="link"
                            onClick={this.handleAddFolder}
                        >
                            Add Folder
                        </BTButton>
                    </div>
                )}
                {globalIndex > -1 && (
                    <div
                        className={classNames("FolderFooter CompanyInbox", {
                            SelectedFolder: folders[globalIndex].id === selectedFolder,
                        })}
                    >
                        {this.renderFolders([folders[globalIndex]], globalIndex)}
                    </div>
                )}
                {!isEditMode && (
                    <div className="FolderFooter">
                        <BTButton
                            className="ManageFoldersButton"
                            data-testid="manageFolders"
                            type="link"
                            onClick={() => onManageFolders(true)}
                            icon={<BTIconSettingFilled />}
                        >
                            <span className="margin-left-xs">Manage Folders</span>
                        </BTButton>
                    </div>
                )}
                {isEditMode && (
                    <div className="FolderFooter padding-top-md">
                        <BTButton
                            data-testid="saveFolders"
                            type="primary"
                            onClick={handleSubmit}
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="saveFolders"
                            className="margin-right-xs"
                        >
                            Save Folders
                        </BTButton>
                        <BTButton data-testid="cancelEdit" onClick={() => onManageFolders(false)}>
                            Cancel
                        </BTButton>
                    </div>
                )}
            </div>
        );
    }
}

const MessageListFoldersValidators = () =>
    yup.object().shape<IMessageListFoldersFormValues>({
        folders: yup.array().of(
            yup.object().shape<IFolderFormValue>({
                id: yup.number(),
                unreadCount: yup.number(),
                name: yup.string().max(25).required().label("Name"),
                deleted: yup.boolean(),
            })
        ),
    });

const MessageListFolders = withFormik<IMessageListFoldersProps, IMessageListFoldersFormValues>({
    mapPropsToValues: (props: IMessageListFoldersProps) => {
        return {
            folders: props.folders.map((f: MessageFolder) => ({
                id: f.folderId,
                name: f.name,
                unreadCount: f.unreadCount,
                deleted: false,
            })),
        };
    },
    validationSchema: MessageListFoldersValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    handleSubmit: async (values: IMessageListFoldersFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.onSaveFolders(values);
        setSubmitting(false);
    },
})(MessageListFoldersInternal);

const MessageListFoldersWrapper: React.FunctionComponent<IMessageListFoldersProps> = (props) => {
    const stickyListActionsHeight = stickyHeaderHeightActions + stickyHeaderHeightTitle;
    const top = useStickyHeight(stickyListActionsHeight, "top", stickyListLevelActions);
    return (
        <div className="StickyMessageListFolders" style={{ top }}>
            <MessageListFolders {...props} />
        </div>
    );
};

export default MessageListFoldersWrapper;
