import { FileViewOptions, IHasAttachedFiles } from "legacyComponents/FileUploadContainer.types";

import { BTLoginTypes } from "types/enum";
import { BTFilePermissions } from "types/filePermissions";

import { getAllSupportedFileTypes } from "entity/media/common/media.utils";

export const getShowOptions = (fileViewOptions: FileViewOptions | undefined) => {
    return fileViewOptions?.options?.find((typeOption) => typeOption.title === "Show");
};

export const getNotifyOptions = (fileViewOptions: FileViewOptions | undefined) => {
    return fileViewOptions?.options?.find((typeOption) => typeOption.title === "Notify");
};

export const getBuilderOptions = (fileViewOptions: FileViewOptions | undefined) => {
    return fileViewOptions?.options?.find(
        (userOption) => userOption.loginType === BTLoginTypes.BUILDER
    );
};

export const getSubsOptions = (fileViewOptions: FileViewOptions | undefined) => {
    return fileViewOptions?.options?.find(
        (userOption) => userOption.loginType === BTLoginTypes.SUBS
    );
};

export const getOwnerOptions = (fileViewOptions: FileViewOptions | undefined) => {
    return fileViewOptions?.options?.find(
        (userOption) => userOption.loginType === BTLoginTypes.OWNER
    );
};

export const getFileViewOptionDefaults = (fileViewOptions: FileViewOptions | undefined) => {
    const showOptions = getShowOptions(fileViewOptions);
    const showBuilderOptions = getBuilderOptions(showOptions);
    const showSubsOptions = getSubsOptions(showOptions);
    const showOwnerOptions = getOwnerOptions(showOptions);

    const notifyOptions = getNotifyOptions(fileViewOptions);
    const notifyBuilderOptions = getBuilderOptions(notifyOptions);
    const notifySubsOptions = getSubsOptions(notifyOptions);
    const notifyOwnerOptions = getOwnerOptions(notifyOptions);

    return {
        showBuilder: showBuilderOptions?.defaultValue ?? false,
        showSubs: showSubsOptions?.defaultValue ?? false,
        showOwner: showOwnerOptions?.defaultValue ?? false,
        notifyBuilder: notifyBuilderOptions?.defaultValue ?? false,
        notifySubs: notifySubsOptions?.defaultValue ?? false,
        notifyOwner: notifyOwnerOptions?.defaultValue ?? false,
    };
};

export const getAllowedFileExtensions = (entity: IHasAttachedFiles | undefined) => {
    const allowedExtensions = entity?.attachedFiles?.viewingPermissions?.allAllowedExtensions.map(
        (x) => x.replace(".", "")
    );

    if (!allowedExtensions || allowedExtensions.length === 0) {
        return getAllSupportedFileTypes();
    }

    return allowedExtensions;
};

export const getPermissionsOverride = (
    partialPermissions: Partial<BTFilePermissions> = {}
): BTFilePermissions => {
    return new BTFilePermissions({
        canEdit: false,
        canEditOnline: false,
        canDrawOnline: false,
        canAnnotate: false,
        canRequestSignatures: false,
        canSign: false,
        canDelete: false,
        canViewRfis: false,
        canAddRfis: false,
        canComment: false,
        canCopy: false,
        canMove: false,
        canSetAsOwnerFeature: false,
        canSetViewingPermissions: false,
        canEditDailyLogs: false,
        canEditToDos: false,
        canEditScheduleItems: false,
        canEditChangeOrders: false,
        canEditWarranties: false,
        canEditSelections: false,
        canEditBids: false,
        ...partialPermissions,
    });
};
