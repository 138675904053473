import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import classNames from "classnames";
import { Component, memo, useContext, useState } from "react";
import { NumberFormatValues, NumericFormat, SourceInfo } from "react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { round } from "utilities/math/math";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { CurrencyDisplay } from "commonComponents/financial/CurrencyDisplay/CurrencyDisplay";
import {
    IInputDebouncingContext,
    useDebouncedOnChange,
} from "commonComponents/utilities/Input/InputDebouncingContext";
import { ReadOnlyShouldTruncate } from "commonComponents/utilities/TextTruncate/TextTruncate.type";
import {
    isReadOnly,
    shouldTruncate,
} from "commonComponents/utilities/TextTruncate/TextTruncate.utility";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

import "./Currency.less";

interface ICurrencyProps<FormValues> {
    id: KeyOfOrString<FormValues>;

    "data-testid": string;

    /** pass formik setFieldValue */
    onChange: (
        field: string,
        value: number | undefined,
        shouldValidate?: boolean,
        values?: NumberFormatValues
    ) => void;

    /** pass formik setFieldTouched */
    onBlur: (field: string, touched: boolean) => void;

    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;

    value: number | undefined | null;

    /** @default "default" */
    size?: "large" | "default" | "small";

    /** @default true */
    allowNegative?: boolean;

    /** @default 2 */
    decimalScale?: number;

    /** @default false */
    disabled?: boolean;

    /** @default 0 */
    defaultValue?: number;

    /** @default 1000000000 (one billion) */
    max?: number;

    /** @default -1000000000 (negative one billion) */
    min?: number;

    style?: React.CSSProperties;

    className?: string;

    /** @default false */
    readOnly?: ReadOnlyShouldTruncate;

    /** Currency will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    /** @default undefined */
    placeholder?: string;

    /**
     * Suffix to be displayed after the input (Only displayed when in readOnly mode)
     */
    suffix?: string;

    /** @default undefined */
    showPlaceholder?: boolean;

    /** ref for the input */
    fieldRef?: React.RefObject<Input>;

    autoFocus?: boolean;

    removeTrailingZerosOnEdit?: boolean;

    /**
     * Sets the debounce context and limits. Should only be set in edge cases.
     */
    debouncingContext?: IInputDebouncingContext;
}

interface ICustomInputProps {
    defaultClassName: string;
    prefixOverride?: string;
    suffixOverride?: string;
    "data-testid": string;
    fieldRef?: React.RefObject<Input>;
}

export class CustomInput extends Component<ICustomInputProps & InputProps> {
    render() {
        const {
            prefixOverride,
            suffixOverride,
            className,
            disabled,
            defaultClassName,
            "data-testid": dataTestId,
            fieldRef,
            ...rest
        } = this.props;
        const classNameDefault = disabled ? `${defaultClassName}-disabled` : defaultClassName;

        return (
            // eslint-disable-next-line react/forbid-elements
            <Input
                className={classNames(classNameDefault, className)}
                disabled={disabled}
                addonBefore={prefixOverride}
                addonAfter={suffixOverride}
                data-testid={dataTestId}
                ref={fieldRef}
                {...rest}
            />
        );
    }
}

/**
 * Displays a formatted currency as an input, use whenever you want to have currency editable
 */
const CurrencyInternal = track((props) => ({
    element: "Currency Input",
    uniqueId: props["data-testid"],
}))(function CurrencyInternal<FormValues = undefined>(
    props: ICurrencyProps<FormValues> & ITrackingProp
) {
    const {
        value,
        decimalScale = 2,
        readOnly,
        className,
        "data-testid": testid,
        suffix,
        ...otherProps
    } = props;

    if (isReadOnly(readOnly)) {
        const displayValue = (
            <CurrencyDisplay
                className={classNames("top-zero", className)}
                decimalScale={decimalScale}
                value={value ?? 0}
                data-testid={testid}
                suffix={suffix}
                shouldTruncate={shouldTruncate(readOnly)}
                forceUSCulture={otherProps.forceUSCulture}
            />
        );

        return <ValueDisplay value={displayValue} />;
    }

    return <CurrencyEditInternal<FormValues> {...props} />;
});

function CurrencyEditInternal<FormValues>(
    props: Omit<ICurrencyProps<FormValues>, "readOnly"> & ITrackingProp
) {
    const {
        id,
        style,
        suffix,
        placeholder,
        showPlaceholder,
        autoFocus,
        removeTrailingZerosOnEdit,
        disabled = false,
        onChange,
        onBlur,
        onFocus = () => {},
        value,
        defaultValue = 0,
        max = 1_000_000_000,
        min = -1_000_000_000,
        allowNegative = true,
        decimalScale = 2,
        "data-testid": testid,
        fieldRef,
        tracking,
        debouncingContext = { useDebouncing: true, inputDelay: 150 },
        className,
        ...otherProps
    } = props;
    const [valuesObj, setValuesObj] = useState<NumberFormatValues>();
    const [debouncedValue, debouncedChange] = useDebouncedOnChange<typeof value, FormValues>(
        value,
        onChange,
        debouncingContext
    );

    const builderInfo = useContext(BuilderInfoContext);

    const currencyLocale =
        otherProps.forceUSCulture || !builderInfo
            ? CurrencyLocale.default
            : builderInfo.locale.currencyLocale;

    function handleIsAllowed(values: NumberFormatValues) {
        const floatValue = values.floatValue ?? 0;
        // enforce min/max of 1/-1 billion
        return min < floatValue && floatValue < max;
    }

    function handleChange(values: NumberFormatValues, { source }: SourceInfo) {
        if (source === "event") {
            debouncedChange(id, values.floatValue, true, values);
            setValuesObj(values);
        }
    }

    function handleBlur() {
        onBlur(id as string, true);
        tracking?.trackEvent({ event: "InputBlur" });
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
        event.target.select();
        onFocus(event);
        tracking?.trackEvent({ event: "InputFocus" });
    }

    let roundedValue: string | number | null | undefined;
    if (debouncedValue === null) {
        roundedValue = null;
    } else {
        roundedValue =
            debouncedValue !== undefined &&
            typeof debouncedValue === "number" &&
            !isNaN(debouncedValue)
                ? round(debouncedValue, decimalScale)
                : undefined;
    }

    /** On initial load, the valuesObj will be null, need to use the "number" value prop passed in.
     * After a value change, valuesObj will be populated from reactNumberFormat, and now need to use the string value they return so trailing zeros don't get truncated.
     * @see {handleChange} where valuesObj is set
     * @example Using number value would convert 10.000 => 10
     */
    const isUsingStringValue =
        removeTrailingZerosOnEdit &&
        valuesObj?.value !== undefined &&
        roundedValue === valuesObj?.floatValue; // The value was changed from a separate component, such as automatic calculations when changing quantity or unit cost. Use the number prop value that was passed in

    return (
        <NumericFormat
            className={className}
            placeholder={placeholder}
            id={id as string}
            defaultValue={showPlaceholder ? undefined : defaultValue}
            value={isUsingStringValue ? valuesObj.value : roundedValue}
            displayType="input"
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            isAllowed={handleIsAllowed}
            thousandSeparator={currencyLocale.groupSeparator}
            decimalSeparator={currencyLocale.decimalSeparator}
            fixedDecimalScale={!removeTrailingZerosOnEdit}
            thousandsGroupStyle={currencyLocale.thousandsGroupStyle}
            disabled={disabled}
            onValueChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            customInput={CustomInput}
            autoFocus={autoFocus}
            allowedDecimalSeparators={[currencyLocale.decimalSeparator]}
            prefixOverride={currencyLocale.currencySymbol.trim()}
            style={style}
            defaultClassName="top-zero CurrencyInput"
            data-testid={testid}
            fieldRef={fieldRef}
            valueIsNumericString={isUsingStringValue}
        />
    );
}

export const Currency = memo(CurrencyInternal) as typeof CurrencyInternal;
