import { BTLayout } from "commonComponents/btWrappers/BTLayout/BTLayout";
import {
    BTListLayout,
    BTListLayoutWhitespace,
} from "commonComponents/btWrappers/BTListLayout/BTListLayout";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import "commonComponents/helpers/JobsitesRequired/JobsitesRequiredPresentational/JobsitesRequiredPresentational.less";

interface IJobsitesRequiredPresentationalProps {
    listTitle?: string;
    isTemplateMode?: boolean;
}

export const JobsitesRequiredPresentational: React.FC<IJobsitesRequiredPresentationalProps> = ({
    listTitle,
    isTemplateMode,
}) => {
    const innerComponent = (
        <BTListLayoutWhitespace>
            <div className="JobsitesRequiredPresentationalContainer">
                <BTTitle className="JobsitesRequiredPresentationalTitle" level={1}>
                    No {isTemplateMode ? "Template" : "Job"} selected
                </BTTitle>
            </div>
        </BTListLayoutWhitespace>
    );

    return listTitle ? (
        <BTListLayout title={listTitle}>{innerComponent}</BTListLayout>
    ) : (
        <BTLayout>{innerComponent}</BTLayout>
    );
};
