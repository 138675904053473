import { BTIconFormOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemEditOnlineProps {
    fileId: number;
    onClick: () => Promise<void>;
}

const MenuItemEditOnline = ({ fileId, onClick, ...antProps }: IMenuItemEditOnlineProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`editOnline-menuitem-${fileId}`}
            data-testid={`editOnline-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconFormOutlined /> Edit Online
        </BTMenuItem>
    );
};

export default MenuItemEditOnline;
