import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { IZohoModalWrapperProps } from "commonComponents/entity/media/Zoho/ZohoModalWrapper";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

const ZohoModalWrapper = lazyLoadWithRetry(() => import("./ZohoModalWrapper"));

export const RouteZoho = (
    props: Omit<
        IZohoModalWrapperProps,
        "documentInstanceId" | "uuid" | "history" | "location" | "match"
    >
) => (
    <RouteRelative
        path={routes.document.editOnline}
        render={(routeProps) => {
            return (
                <BTSuspense>
                    <ZohoModalWrapper
                        documentInstanceId={parseInt(routeProps.match.params.documentInstanceId)}
                        {...props}
                        {...routeProps}
                    />
                </BTSuspense>
            );
        }}
    />
);
