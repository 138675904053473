import moment from "moment";

import { IBaseEntity } from "types/apiResponse/apiResponse";
import { BTLoginTypes, DiscussionsLinkedTypes } from "types/enum";

import { IListRowData, ListEntity } from "utilities/list/list.types";

import { EditorContent } from "commonComponents/btWrappers/editor/editor.types";

import type { default as CommentListJson } from "entity/comment/CommentList/CommentList.api.json";

type CommentsListEntityApiData = typeof CommentListJson;

export class CommentListEntity extends ListEntity {
    constructor(data: CommentsListEntityApiData) {
        super(data);
        const comments = data.data.map((li: any) => new CommentEntry(li));
        this.data = comments.filter((com) => com.linkedType !== DiscussionsLinkedTypes.Receipts);
    }
    data: CommentEntry[];
}

export class CommentEntry implements IBaseEntity, IListRowData {
    constructor(data: any) {
        this.id = data.commentId;
        this.jobId = data.jobsiteId;
        this.builderId = data.builderId;

        this.linkedType = data.linkedType;
        this.linkedId = data.linkedId;
        this.userFullName = data.userFullName;
        this.sourceTitle = data.sourceTitle;
        this.sourceType = data.sourceType;
        this.commentDate = moment(data.commentDate);
        this.commentText = data.commentText;
        this.commentJson = data.commentJson ? new EditorContent(data.commentJson) : null;
        this.commentCount = data.commentCount;
        this.canDelete = data.canDelete;
        this.canShowOwner = data.showOwner;
        this.canShowSubs = data.showSubs;
        this.mediaData = data.mediaData && new MediaData(data.mediaData);
        this.documentLink = data.documentLink;
        this.isUserComment = data.isUserComment;
        this.addedByID = data.addedByID;
        this.loginType = data.loginType;
        this.vimeoId = data.vimeoId;
        this.jobName = data.jobName;
    }

    // IBaseEntity
    id: number;
    jobId: number;
    builderId: number;

    linkedType: number;
    linkedId: number;
    userFullName: string;
    sourceTitle: string;
    sourceType: string;
    commentDate: moment.Moment;
    commentText: string;
    commentJson: EditorContent | null;
    // commentCount is actually AdditionalCommentCount. It is the number of comments in addtion to the one being displayed in the list.
    commentCount: number;
    canDelete: boolean;
    canShowOwner: boolean;
    canShowSubs: boolean;
    mediaData: MediaData;
    documentLink: string;
    isUserComment: boolean;
    addedByID: number;
    loginType: BTLoginTypes;
    vimeoId: number;
    jobName: string;
}

class MediaData {
    constructor(mediaData: any) {
        this.fullSize = mediaData.FullSize;
        this.originalFile = mediaData.OriginalFile;
        this.thumb = mediaData.Thumb;
        this.attachment = mediaData.Attachment;
        this.description = mediaData.Description;
        this.hasAnnotations = mediaData.HasAnnotations;
        this.is360Media = mediaData.is360Media;
        this.id = mediaData.docId || undefined;
        this.jobId = mediaData.jobId || undefined;
    }

    fullSize: string;
    originalFile: string;
    thumb: string;
    attachment: string;
    description: string;
    hasAnnotations: boolean;
    is360Media: boolean;
    id?: number;
    jobId?: number;
}

export enum CommentListTabKeys {
    Conversation = 1,
    Comment = 2,
}
