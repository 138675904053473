import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

export interface IFolder {
    id: number;
    title: string;
}

export class BrowseBTFilesListResponse {
    // MediaListResponse
    constructor(data: any) {
        this.files = data.files.map((file: any) => new BTFileSystem(file));
    }

    files: BTFileSystem[];
}
