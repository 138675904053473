import queryString from "query-string";
import { Component, ReactNode } from "react";

import { AppProvider } from "helpers/AppProvider";
import { AppDefaultInfo, BuilderInfo, UserInfo } from "helpers/AppProvider.types";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLocalStorage } from "types/btStorage";
import { BTLoginTypes, MenuItemName } from "types/enum";

import { getGlobalValue } from "utilities/globalValueUtils";
import { getSelectedJobId } from "utilities/jobPicker/jobPicker";
import { getRandomInteger } from "utilities/number/number";

import { IRiskInsurance } from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";

export const menuItemClass = "MainNavMenuItem";
export const quickAddClass = "MainNavMenuItemQuickAdd MenuLink";

interface IMainNavigationWrapperState {
    builderId: number;
    userId: number;
    allSubEmails: string;
    jobId: number;
    encryptedBuilderId: string;
    headerKey: number;
    isTemplateMode: boolean;
    username: string;
    userDisplayName: string;
    showSubResetEmailPrompt: boolean;
    loginType: BTLoginTypes;
    selectedMenuItem?: MenuItemName;
    loadNewJob: boolean;
    showAccountSetup: boolean;
    sessionTimeoutMinutes: number;
    riskInsurance?: IRiskInsurance;
}

interface IMainNavigationWrapperProps {
    render: (
        state: IMainNavigationWrapperState,
        setSelectedJobId: (jobId: number, orgId?: number) => Promise<void>
    ) => ReactNode;
}

interface IMainNavigationWrapperInternalProps extends IMainNavigationWrapperProps {
    builderInfo: BuilderInfo | null | undefined;
    userInfo: UserInfo | undefined;
    appDefaultInfo: AppDefaultInfo | undefined;
}

/**
 * Used on webforms list pages only
 */
class MainNavigationWrapperInternal extends Component<
    IMainNavigationWrapperInternalProps,
    IMainNavigationWrapperState
> {
    componentDidMount = async () => {
        const { builderInfo, userInfo, appDefaultInfo } = this.props;

        this.setState({
            ...getApplicationState(builderInfo!, userInfo!, appDefaultInfo!),
            loadNewJob: true,
        });

        BTLocalStorage.addChangeListener(
            "bt-object-dangerousJobPickerState",
            this.listenForJobChange
        );
    };

    componentWillUnmount() {
        BTLocalStorage.removeChangeListener(
            "bt-object-dangerousJobPickerState",
            this.listenForJobChange
        );
    }

    private listenForJobChange = () => {
        const jobId = getSelectedJobId();
        this.setState({ jobId, headerKey: this.generateHeaderKey() });
    };

    /**
     * Helper method to generate a new `itemKey` for <HeaderInfo>. It generates a random number to force header
     * to update every time job or job filter is changed
     */
    private generateHeaderKey = () => {
        return getRandomInteger(1, 100000);
    };

    private onSetSelectedJobId = async (id: number) => {
        this.setState({ jobId: id, headerKey: this.generateHeaderKey(), loadNewJob: false });
    };

    render() {
        if (this.state != null) {
            return this.props.render(this.state, this.onSetSelectedJobId);
        }
        return null;
    }
}

function getApplicationState(
    builderInfo: BuilderInfo,
    userInfo: UserInfo,
    appDefaultInfo: AppDefaultInfo
) {
    const builderId = builderInfo.builderId;
    const userId = userInfo.globalUserId!;
    let jobId = getSelectedJobId();
    let jobCount =
        BTLocalStorage.get("bt-object-dangerousJobPickerState")?.selectedJobIds.length ?? 0;
    jobId = jobId === -1 ? 0 : jobId;

    const encryptedBuilderId = builderInfo.encryptedBuilderId!;
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(window.location.search);

    const isRiskInsurancePayment = !!qsValues.isRiskInsurancePayment;
    const riskInsurance = isRiskInsurancePayment ? { jobId: qsValues.jobId } : undefined;
    const selectedMenuItem: MenuItemName | undefined = window.btJScriptGlobals.selectedMenuItem // todo - comes from master pages
        ? Number(window.btJScriptGlobals.selectedMenuItem)
        : undefined;
    const isTemplateMode = window.btJScriptGlobals.isTemplateMode; // todo - this should probably be part of routes instead
    const username = userInfo.userName!;
    const userDisplayName = userInfo.fullName!;
    const allSubEmails = getGlobalValue("allSubEmails") ?? "";
    const showSubResetEmailPrompt = getGlobalValue("showSubResetEmailPrompt") ?? false;
    const loginType = userInfo.loginType;
    const sessionTimeoutMinutesOverride = appDefaultInfo.sessionTimeoutMinutes;
    const sessionTimeoutMinutes =
        sessionTimeoutMinutesOverride > 0 ? sessionTimeoutMinutesOverride : userInfo.timeoutMinutes;

    return {
        builderId,
        userId,
        allSubEmails,
        jobId,
        encryptedBuilderId,
        headerKey: jobId + jobCount,
        isTemplateMode,
        username,
        userDisplayName,
        showSubResetEmailPrompt,
        selectedMenuItem,
        loginType,
        sessionTimeoutMinutes,
        riskInsurance,
    };
}

export const MainNavigationWrapper = (props: IMainNavigationWrapperProps) => {
    return (
        <AppProvider deferIntercomLoad>
            <BuilderInfoContext.Consumer>
                {(builderInfo) => (
                    <UserInfoContext.Consumer>
                        {(userInfo) => (
                            <AppDefaultInfoContext.Consumer>
                                {(appDefaultInfo) => (
                                    <MainNavigationWrapperInternal
                                        {...props}
                                        builderInfo={builderInfo}
                                        userInfo={userInfo}
                                        appDefaultInfo={appDefaultInfo}
                                    />
                                )}
                            </AppDefaultInfoContext.Consumer>
                        )}
                    </UserInfoContext.Consumer>
                )}
            </BuilderInfoContext.Consumer>
        </AppProvider>
    );
};
