import { message } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { Component } from "react";

import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { MediaType, TempFileTypes } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { setLoadingAction } from "utilities/form/form";
import { isInPortal } from "utilities/portal/portal";
import { isNullOrWhitespace } from "utilities/string/string";

import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { FileUploadHandler } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.handler";
import {
    AttachVideoRequest,
    BTFileUploadAction,
    BTFileUploadEntity,
    BTFileUploadMode,
    CreateVideoRequest,
    EntityDocsRequest,
    EntityDocsResponse,
    IBTFileUploadAttachmentsProps,
    IBTFileUploadFormValues,
    IBTFileUploadStandaloneProps,
} from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { BTFileUploadPresentational } from "commonComponents/entity/media/BTFileUpload/BTFileUploadPresentational";
import { getAttachmentOptions } from "commonComponents/entity/media/MediaAttachmentOptions/MediaAttachmentOptions.utils";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import { GetTempFileTypeFromDocumentInstanceType } from "entity/media/common/media.utils";

interface IBTFileUploadState {
    entity?: BTFileUploadEntity;
    actionBeingPerformed: BTFileUploadAction;
    formikFileList: UploadFile<BTFileSystem>[];
    failedFileIds: { [key: string]: boolean | undefined };
    areViewingPermissionsDropdownsHidden: boolean;
    areNotificationsHidden: boolean;
    disableSave: boolean;
}

class BTFileUpload extends Component<
    IBTFileUploadStandaloneProps | IBTFileUploadAttachmentsProps,
    IBTFileUploadState
> {
    static defaultProps: Partial<IBTFileUploadStandaloneProps | IBTFileUploadAttachmentsProps> = {
        handler: new FileUploadHandler(),
        uploadMode: BTFileUploadMode.Standalone,
        allowedFileTypes: ["*"],
    };

    state: Readonly<IBTFileUploadState> = {
        actionBeingPerformed: undefined,
        formikFileList: [],
        failedFileIds: {},
        areViewingPermissionsDropdownsHidden: true,
        areNotificationsHidden: true,
        disableSave: false,
    };

    componentDidMount = async () => {
        if (this.props.uploadMode === BTFileUploadMode.Attachments) {
            // Nothing to prep for entity attachments
            return;
        }

        const { jobId, folderId, mediaType, handler } = this.props;

        try {
            const response = await handler!.get(folderId, jobId, mediaType);

            this.setState({ entity: response });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    private getTempFileType = () => {
        if (this.props.uploadMode === BTFileUploadMode.Attachments) {
            const { entity } = this.props;

            return GetTempFileTypeFromDocumentInstanceType(entity.documentInstanceType);
        }

        const { mediaType } = this.props;

        if (mediaType === MediaType.Photo) {
            return TempFileTypes.Photos;
        } else if (mediaType === MediaType.Video) {
            return TempFileTypes.AttachedVideos;
        }
        return TempFileTypes.StandaloneDocument;
    };

    private handleDiscard = () => {
        const { modalConfig } = this.props;
        if (modalConfig) {
            modalConfig.beforeClose();
        }
    };

    private handleSave = async (values: IBTFileUploadFormValues) => {
        const { mediaType, handler, modalConfig } = this.props;
        const { failedFileIds } = this.state;

        await setLoadingAction(this, {
            actionBeingPerformed: "save",
            callback: async () => {
                // wait for all files to upload
                let isWaiting = !this.areFilesUploaded();
                while (isWaiting) {
                    await new Promise((resolve) => setTimeout(resolve, 250));
                    isWaiting = !this.areFilesUploaded();
                }
                values = this.getFinalFiles(values);

                if (values.fileList.every((file) => file.status !== "done")) {
                    void message.error("No files uploaded successfully");
                    return;
                }

                try {
                    let isSuccess = true;
                    if (this.props.uploadMode === BTFileUploadMode.Attachments) {
                        const entityIds = this.props.entity.bulkUploadEntityIds ?? [
                            this.props.entity.id,
                        ];
                        const attachResults = await Promise.allSettled(
                            entityIds.map((entityId) =>
                                this.handleSavingEntityAttachments(entityId, values)
                            )
                        );
                        isSuccess = attachResults.every(
                            (result) => result.status === "fulfilled" && result.value
                        );
                        this.setState({ disableSave: !isSuccess });
                    } else {
                        const { folderId } = this.props;
                        if (mediaType === MediaType.Document) {
                            await handler!.saveDocs(folderId, values);
                        } else if (mediaType === MediaType.Photo) {
                            const resp = await handler!.savePhotos(folderId, values);
                            const filesFailed = resp.failedUploads.length;
                            if (filesFailed) {
                                const filesUploaded = values.fileList.length - filesFailed;
                                isSuccess = false;

                                void message.error(
                                    <>
                                        {`${filesUploaded} `}
                                        <BTFormattedPlural
                                            value={filesUploaded}
                                            one="file"
                                            other="files"
                                        />
                                        {` uploaded, ${filesFailed} `}
                                        <BTFormattedPlural
                                            value={filesFailed}
                                            one="file"
                                            other="files"
                                        />
                                        {` failed`}
                                    </>
                                );

                                const newFailedFileIds = { ...failedFileIds };
                                resp.failedUploads.forEach((upload) => {
                                    newFailedFileIds[upload.tempId.toString()] = true;
                                });

                                this.setState({
                                    failedFileIds: newFailedFileIds,
                                });
                            }
                        } else if (mediaType === MediaType.Video) {
                            let saveVideoPromises: Promise<EmptyResponseEntity>[] = [];

                            values.fileList.forEach((video) => {
                                saveVideoPromises.push(
                                    handler!.saveVideo(
                                        new CreateVideoRequest(
                                            folderId,
                                            values.internalUserIds,
                                            values.subIds,
                                            values.showOwner,
                                            values.notifications.notifyInternalUsers,
                                            values.notifications.notifyOwner,
                                            values.notifications.notifySubs,
                                            values.comments,
                                            video
                                        )
                                    )
                                );
                            });

                            if (saveVideoPromises.length) {
                                await Promise.allSettled(saveVideoPromises);
                            }
                        }
                    }

                    if (isSuccess) {
                        void message.success("Files uploaded successfully");

                        if (modalConfig) {
                            modalConfig.beforeClose();
                        }
                    }
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    /**
     * Prepares in-flight file uploads for entity attachment.
     * Note: Largely replicating previous logic from CFV.
     * @param values
     * @param isNewEntity
     * @returns List of files ready for entity attachment
     */
    private prepareFilesForAttachment = (values: IBTFileUploadFormValues, isNewEntity: boolean) => {
        const files = values.fileList
            .filter((f) => f.response !== undefined)
            .map((f) => {
                const file = f.response!;

                file.isTempFile = !file.existingBTDoc;
                file.isOnNewEntity = isNewEntity;

                if (isNewEntity && file.isTempFile && file.mediaType === MediaType.Video) {
                    file.id = file.videoId!;
                }

                file.viewingPermissions = {
                    showAllSubs: false,
                    mainFile: false,
                    ...(file.viewingPermissions ?? {}),
                    showOwner: values.showOwner,
                    showSubs: values.showSubs,
                };
                file.permissions = {
                    canAddRfis: false,
                    canAnnotate: true,
                    canComment: false,
                    canCopy: false,
                    canDelete: true,
                    canDrawOnline: true,
                    canEdit: true,
                    canEditOnline: false,
                    canMove: false,
                    canRequestSignatures: false,
                    canSetAsOwnerFeature: false,
                    canSetViewingPermissions: false,
                    canSign: false,
                    canViewRfis: false,
                    canEditDailyLogs: false,
                    canEditToDos: false,
                    canEditScheduleItems: false,
                    canEditChangeOrders: false,
                    canEditWarranties: false,
                    canEditSelections: false,
                    canEditBids: false,
                    ...(file.permissions ?? {}),
                };

                // Clear non-photo's thumbnail property so that the appropriate filetype thumbnail is displayed in the CFV.
                // Note: Consider removing this and handle in the replacement component for bt-file-wrapper .
                if (!file.isPhoto && file.mediaType !== MediaType.Video) {
                    file.thumbnail = "";
                }

                return new BTFileSystem(file);
            });

        return files;
    };

    private handleSavingEntityAttachments = async (
        entityId: number,
        values: IBTFileUploadFormValues
    ): Promise<boolean> => {
        if (this.props.uploadMode !== BTFileUploadMode.Attachments) {
            return false;
        }

        const isNewEntity = entityId <= 0;

        let isSuccess = false;

        const files = this.prepareFilesForAttachment(values, isNewEntity);

        let filesAdded;
        if (!isNewEntity) {
            const videosToUploadAndAttach: BTFileSystem[] = [];
            const filesToAttach: BTFileSystem[] = [];

            files.forEach((f) => {
                if (f.mediaType === MediaType.Video && !f.existingBTDoc) {
                    videosToUploadAndAttach.push(f);
                } else {
                    filesToAttach.push(f);
                }
            });

            const attachedFiles = await this.attachFilesToEntity(
                entityId,
                filesToAttach,
                values.notifications
            );

            const attachedVideos = await this.attachVideosToEntity(
                entityId,
                videosToUploadAndAttach,
                values.notifications
            );

            isSuccess =
                attachedFiles.length === filesToAttach.length &&
                attachedVideos.length === videosToUploadAndAttach.length;

            filesAdded = [...attachedFiles, ...attachedVideos];
        } else {
            filesAdded = files;
            isSuccess = true;
        }

        this.props.onFilesConfirmed?.(filesAdded);
        return isSuccess;
    };

    /**
     * Attaches the provided list of files (non-video) to the current entity
     * @param fileList Array of files to attach
     * @returns Array of resulting BTFileSystem items representing the newly attached files
     */
    private attachFilesToEntity = async (
        entityId: number,
        fileList: BTFileSystem[],
        notifications: IBTFileUploadFormValues["notifications"]
    ) => {
        if (
            this.props.uploadMode !== BTFileUploadMode.Attachments ||
            !fileList ||
            fileList.length === 0
        ) {
            return [];
        }

        const { handler, entity, externalServiceInfo, isExternal } = this.props;

        // Attach non-video files now
        const requestData = new EntityDocsRequest(
            entity.builderId,
            [entityId],
            entity.jobId ?? null,
            entity.documentInstanceType,
            notifications.notifyInternalUsers,
            notifications.notifyOwner,
            notifications.notifySubs,
            fileList
        );

        let response: EntityDocsResponse;
        if (isExternal && externalServiceInfo) {
            response = await handler!.postEntityDocsExternal(
                requestData,
                externalServiceInfo.subId,
                externalServiceInfo.entityId,
                externalServiceInfo.docType
            );
        } else {
            response = await handler!.saveEntityDocs(requestData);
        }
        return response.listOfAttachedFiles;
    };

    /**
     * Attaches the provided list of videos to the current entity
     * @param videoList Array of videos to attach
     * @returns Array of resulting BTFileSystem items representing the newly attached videos
     */
    private attachVideosToEntity = async (
        entityId: number,
        videoList: BTFileSystem[],
        notifications: IBTFileUploadFormValues["notifications"]
    ) => {
        if (
            this.props.uploadMode !== BTFileUploadMode.Attachments ||
            !videoList ||
            videoList.length === 0
        ) {
            return [];
        }

        const { entity } = this.props;
        const videoAttachPromises: Promise<BTFileSystem>[] = [];

        videoList.forEach(async (video) => {
            videoAttachPromises.push(
                this.attachVideoPromise(
                    new AttachVideoRequest({
                        entityId,
                        linkedType: entity.documentInstanceType,
                        videoId: video.videoId!,
                        videoName: video.fileName,
                        jobId: entity.jobId,
                        showSubs: video.viewingPermissions?.showSubs ?? false,
                        showOwner: video.viewingPermissions?.showOwner ?? false,
                        notifyBuilder: notifications.notifyInternalUsers,
                        notifySubs: notifications.notifySubs,
                        notifyOwner: notifications.notifyOwner,
                        videoDescription: "",
                        fileSize: video.fileSize,
                        thumbnailUrl: "thumb",
                    })
                )
            );
        });

        const results = await Promise.allSettled(videoAttachPromises);

        // Only return succeeded files
        const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
            p.status === "fulfilled";
        return results.filter(isFulfilled).map((r) => r.value);
    };

    /** Handles individual video attach request */
    private attachVideoPromise = (videoRequest: AttachVideoRequest) => {
        const { handler, externalServiceInfo } = this.props;

        if (externalServiceInfo && handler) {
            return handler
                .attachVideoExternal(
                    videoRequest,
                    externalServiceInfo.subId,
                    externalServiceInfo.entityId
                )
                .catch((e) => {
                    showAPIErrorMessage(e, `Error attaching video "${videoRequest.videoName}"`);
                    throw e;
                });
        } else {
            return handler!.attachVideo(videoRequest).catch((e) => {
                showAPIErrorMessage(e, `Error attaching video "${videoRequest.videoName}"`);
                throw e;
            });
        }
    };

    private areFilesUploaded = () => {
        return this.state.formikFileList.every((file) => file.status !== "uploading");
    };

    private getFinalFiles = (values: IBTFileUploadFormValues) => {
        return { ...values, fileList: this.state.formikFileList };
    };

    private handleFileListUpdated = (fileList: UploadFile<BTFileSystem>[]) => {
        this.setState({ formikFileList: fileList });
    };

    private handleShowViewingPermissionsDropdowns = () => {
        this.setState({ areViewingPermissionsDropdownsHidden: false });
    };

    private handleToggleNotifications = () => {
        this.setState((prevState) => {
            return { areNotificationsHidden: !prevState.areNotificationsHidden };
        });
    };

    private hasMediaOptions = () => {
        if (this.props.uploadMode === BTFileUploadMode.Standalone) {
            return isInPortal({ builder: true });
        }

        const { isShowVisible, isNotifyVisible } = getAttachmentOptions(
            this.props.entity.attachedFiles.newFileOptions
        );

        return isShowVisible || isNotifyVisible;
    };

    render() {
        if (
            this.props.uploadMode === BTFileUploadMode.Standalone &&
            (!this.state || !this.state.entity)
        ) {
            return <BTLoading />;
        }

        const {
            jobId,
            mediaType,
            allowedFileTypes,
            fileList,
            isTemplateMode,
            modalConfig,
            uploadMode,
            isExternal,
            externalServiceInfo,
        } = this.props;

        const {
            entity,
            failedFileIds,
            actionBeingPerformed,
            areViewingPermissionsDropdownsHidden,
            areNotificationsHidden,
            disableSave,
        } = this.state;

        const hasMediaOptions = this.hasMediaOptions();

        const component = (
            <BTFileUploadPresentational
                jobId={jobId}
                leadId={
                    uploadMode === BTFileUploadMode.Attachments
                        ? this.props.entity.leadId
                        : undefined
                }
                title={`Upload Files${
                    isNullOrWhitespace(entity?.folderName) ? "" : ` - ${entity?.folderName}`
                }`}
                fileList={fileList}
                failedFileIds={failedFileIds}
                allowedFileTypes={allowedFileTypes!}
                viewingPermissions={entity?.viewingPermissions}
                fileViewOptions={
                    uploadMode === BTFileUploadMode.Attachments
                        ? this.props.entity.attachedFiles.newFileOptions
                        : undefined
                }
                mediaType={mediaType}
                tempFileType={this.getTempFileType()}
                isTemplateMode={isTemplateMode}
                actionBeingPerformed={actionBeingPerformed}
                areViewingPermissionsDropdownsHidden={areViewingPermissionsDropdownsHidden}
                areNotificationsHidden={areNotificationsHidden}
                onSave={this.handleSave}
                disableSave={disableSave}
                onDiscard={this.handleDiscard}
                onFileListUpdated={this.handleFileListUpdated}
                onShowViewingPermissionsDropdowns={this.handleShowViewingPermissionsDropdowns}
                onToggleNotifications={this.handleToggleNotifications}
                modalConfig={modalConfig}
                uploadMode={uploadMode}
                hasMediaOptions={hasMediaOptions}
                isExternal={isExternal}
                externalServiceInfo={externalServiceInfo}
            />
        );

        if (modalConfig) {
            return (
                <BTModal
                    data-testid="btModalFileUpload"
                    visible
                    useModalLayout
                    title={`Upload Files${
                        isNullOrWhitespace(entity?.folderName) ? "" : ` - ${entity?.folderName}`
                    }`}
                    width={`${hasMediaOptions ? 1007 : 600}px`}
                    removeBodyPadding
                    beforeClose={modalConfig.beforeClose}
                >
                    {component}
                </BTModal>
            );
        }

        return component;
    }
}

export default withErrorBoundary(BTFileUpload)("Could not load File Upload");
