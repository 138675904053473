export type VideoListFormActions = "newVideo" | "checkedAction" | "newFolder" | undefined;

export class VideoDownloadLinksResponse {
    constructor(data: any) {
        this.linksList = data.linksList;
    }
    linksList: string[];
}

export interface IGetVideoDownloadLinksRequest {
    videoIds: number[];
    jobId: number;
    isDeleted: boolean;
}
