import {
    AttachedFiles,
    AttachedFilesRequest,
    BTServiceFileUpload,
} from "legacyComponents/FileUploadContainer.types";
import { orderBy } from "lodash-es";
import moment from "moment";

import {
    BTSelectItem,
    BTServiceDropdown,
    mapBTServiceDropdownToSelectItem,
} from "types/apiResponse/apiResponse";
import {
    CostTypes,
    DocumentInstanceType,
    MarkedAs,
    PaymentEntityTypes,
    ProposalGroupTypes,
    ProposalStatus,
} from "types/enum";

import { getSelectedValue } from "utilities/form/form";

import { AddressEntity } from "commonComponents/entity/address/Address/Address.api.types";
import { MerchantPaymentListData } from "commonComponents/entity/merchantPayment/MerchantPaymentList/MerchantPaymentList";
import { TaxGroupServiceListItemExtraData } from "commonComponents/financial/TaxRateSelect/TaxRateSelect.api.types";
import { ILineItemGroup } from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    isLineItemRow,
    MarkupType,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";
import { ProposalDisplayColumnTypes } from "commonComponents/utilities/LineItemDisplaySettings/LineItemDisplaySettings.api.types";

import { IAssemblySelectorExtraData } from "entity/assembly/AssemblySelector/AssemblySelector";
import { AssignToAssemblyActions } from "entity/assembly/AssignToAssembly/AssignToAssembly.api.types";
import { ICostCodeDefaultContainer } from "entity/costCode/common/CostCode.types";
import { AdjustPercentMarkupActions } from "entity/estimate/AdjustPercentMarkup/AdjustPercentMarkup.api.types";
import { CostCodeExtraData } from "entity/estimate/Estimate/Estimate.api.types";
import { ProposalLineItemSaveRequest } from "entity/estimate/EstimateLineItemContainer/EstimateLineItemContainer.types";
import { IResetStatusPromptFormValues } from "entity/estimate/Proposal/ResetStatusPrompt/ResetStatusPrompt.types";
import { ProposalPrintOptions } from "entity/estimate/ProposalPrint/ProposalPrint.api.types";
import { LeadProposalPaymentStatusType } from "entity/leadProposal/common/LeadProposalPaymentStatus";
import {
    DisplayPreferences,
    isFormatItemListGrouped,
    LeadProposalLineItem,
    ProposalCategory,
    ProposalSubCategory,
    TakeoffPartner,
    UnassignedCostGroupId,
} from "entity/leadProposal/LeadProposal/DeprecatedLeadProposalFiles/leadProposal.common.types";
import {
    BuilderInfo,
    CustomerInfo,
    getUnassignedCategory,
    IProposalFormatOptions,
    IProposalPreviewEntity,
    IProposalPreviewFormValues,
    ProposalBodyLayouts,
    ProposalHeaderLayouts,
    ProposalPrintoutType,
    ProposalStatusObject,
    ProposalTypes,
} from "entity/leadProposal/LeadProposal/DeprecatedLeadProposalFiles/LeadProposal.types";
import { IProposalFormatFormValues } from "entity/leadProposal/LeadProposal/DeprecatedLeadProposalFiles/ProposalFormat.types";
import { IHasOnlinePayments } from "entity/onlinePayment/OnlinePayment.api.types";
import { NoTaxId, TaxRateEntity } from "entity/tax/TaxRate/TaxRate.api.types";

export type LeadProposalFormActions =
    | undefined
    | "save"
    | "saveAndClose"
    | "delete"
    | "excelExport"
    | "releaseToOwner"
    | "unrelease"
    | "approve"
    | "decline"
    | "resend"
    | "reset"
    | "print"
    | "refund"
    | "saveAndNew"
    | "sendToHomeDepot"
    | AdjustPercentMarkupActions
    | AssignToAssemblyActions
    | "saveAssembly"
    | "deleteAssembly"
    | "takeoffSync"
    | "saveAndRequestPayment";

export interface ILeadProposalFormValues
    extends IProposalFormatFormValues,
        IProposalPreviewFormValues {
    title: string;
    lineItems: (LeadProposalLineItem | ILineItemGroup<LeadProposalLineItem>)[];
    lineItemDisplayPreference: DisplayPreferences<ProposalDisplayColumnTypes>;
    approvalDeadline: moment.Moment | null;
    internalNotes: string;
    templateDescription?: string;
    introductionText: string;
    closingText: string;
    attachments: AttachedFilesRequest;
    columnsToDisplay: number[];
    deletedCategories: number[];
    deletedLineItems: number[];
    salesPerson?: number;
    newLineItemId: number;
    newCategoryId: number;
    taxMethod: number;
    taxGroupId?: number;
}

export class LeadProposalEntity
    implements IProposalPreviewEntity, IHasOnlinePayments, ICostCodeDefaultContainer
{
    constructor(data: any) {
        this.id = data.id;
        this.builderId = data.builderId;
        this.leadId = data.leadId ?? undefined;
        this.title = data.title.value;
        this.lineItemDisplayPreference = new DisplayPreferences<ProposalDisplayColumnTypes>(
            data.lineItemDisplayPreference
        );

        this.canEditCostCodes = data.canEditCostCodes;
        this.costCategories = data.costCategories.map(
            (category: any) => new BTSelectItem({ id: category.id, name: category.name })
        );
        this.costCodes = data.costCodes.map(
            (code: any) =>
                new BTSelectItem<CostCodeExtraData>({
                    id: code.id,
                    name: code.name,
                    extraData: {
                        costCategoryId: code.costCategoryId,
                        isFlatRateCostCode: code.isFlatRateCostCode,
                    },
                })
        );
        this.costTypes = data.costTypes;

        this.flatRateCostCode = this.costCodes.find((x) => x.extraData?.isFlatRateCostCode);
        this.lineItems = getAndSortLineItems(data, this.costCodes, this.lineItemDisplayPreference);
        this.canEdit = data.canEdit;
        this.hasEditPermission = data.hasEditPermission;
        this.canViewGeneralItems = data.canViewGeneralItems;
        this.canAddGeneralItems = data.canAddGeneralItems;
        this.canEditGeneralItems = data.canEditGeneralItems;
        this.canDeleteGeneralItems = data.canDeleteGeneralItems;
        this.internalNotes = data.internalNotes.value;
        this.defaultIntroductionText = data.defaultIntroText;
        this.introductionText = data.introText.value;
        this.templateDescription = data.templateDescription;
        this.defaultClosingText = data.defaultClosingText;
        this.closingText = data.closingText.value;
        this.approvalDeadline = getApprovalDeadline(data);
        this.entityAddedDate = getEntityAddedDate(data);

        this.status = new ProposalStatusObject(data.status);
        this.columnsToDisplay = mapBTServiceDropdownToSelectItem(data.whatToDisplay);
        this.salesPerson = getSalesPerson(data);
        this.taxMethod = data.taxMethod && mapBTServiceDropdownToSelectItem(data.taxMethod);
        this.formatOptions = data.formatOptions;
        this.proposalPrintOptions = new ProposalPrintOptions(data.proposalPrintOptions);
        this.proposalAddedByName = data.proposalAddedByName;
        this.proposalAddedById = data.proposalAddedById;
        this.proposalAddedDate = moment(data.proposalAddedDate);
        this.lastUpdatedByName = data.lastUpdatedByName;
        this.lastUpdatedById = data.lastUpdatedById;
        this.lastUpdatedByDate = moment(data.lastUpdatedByDate);
        this.builderInfo = new BuilderInfo(data.builderInfo);
        this.builderAddress = new AddressEntity(data.builderAddress);
        this.customerInfo = data.leadInfo ? new CustomerInfo(data.leadInfo) : null;
        this.customerAddress = data.leadAddress ? new AddressEntity(data.leadAddress) : null;
        this.contactAddress = data.contactAddress ? new AddressEntity(data.contactAddress) : null;
        this.showContactAddress = data.showContactAddress;
        this.defaultReleaseText = data.defaultReleaseText;
        this.showResetOtherProposalsText = data.showResetOtherProposalsText;
        this.resetOtherProposalIds = data.resetOtherProposalIds;
        this.hasApprovedProposal = data.hasApprovedProposal;
        this.hasOtherPendingProposal = data.hasOtherPendingProposal;

        this.categories = getCategories(data, this.builderId, this.leadId);

        this.attachedFiles = new AttachedFiles(data.attachedFiles);
        this.name = "Lead Proposal";
        this.documentInstanceType = DocumentInstanceType.LeadProposal;

        this.paymentAmount = data.paymentAmount?.value;
        this.paymentDueDays = data.paymentDueDays;
        this.paymentDueType = data.paymentDueType;
        this.paymentStatus = data.paymentStatus?.paymentStatusId;
        this.paymentType = data.paymentType;
        this.allowedPaymentTypes = data.allowedPaymentTypes;
        this.builderCountry = data.builderCountry;
        this.showPayOnline = data.showPayOnline;
        this.canEditPayment = data.canEditPayment;

        this.entityType = PaymentEntityTypes.LeadProposal;
        this.showPayAndApprove = data.showPayAndApprove !== undefined;
        this.showApprovalSignaturePad = data.showApprovalSignaturePad;
        this.customTitle = this.title;

        this.relatedLeadProposals = data.relatedLeadProposals;
        this.merchantPayments = getMerchantPayments(data);
        this.canRefund = data.canRefund;
        this.showRefund = data.showRefund;
        this.isTemplate = data.isTemplate;
        this.proposalType = getProposalType(data);
        this.disclaimer = data.disclaimer;
        this.costItemUpdateMessage = data.costItemUpdateMessage;
        this.canSendToHomeDepotCart = data.canSendToHomeDepotCart;
        this.connectedTakeoffPartners = data.connectedTakeoffPartners?.map(
            (tp: any) => new TakeoffPartner(tp)
        );
        this.currentDate = data.currentDate;
        this.canLaunchTakeoff = data.canLaunchTakeoff;
        this.takeoffPartnerId = data.takeoffPartnerId;
        this.hasTakeoffUser = data.hasTakeoffUser;
        this.takeoffProjectLink = data.takeoffProjectLink;
        this.takeoffProjectName = data.takeoffProjectName;
        this.viewType = data.proposalFormatGroupType;
        this.projectConnectedToTakeoffs = data.projectConnectedToTakeoffs;

        this.assemblies = getAssemblies(data);
        this.hasCostCodesFeature = data.hasCostCodesFeature;
        this.taxGroups = getTaxGroups(data);
        this.isInternational = data.isInternational;
        this.taxGroupId = data.taxGroupId;

        const selectedTaxGroup = this.taxGroups?.find(
            (taxGroup) => taxGroup.value === this.taxGroupId
        );

        this.taxRates = selectedTaxGroup?.extraData?.taxRates;
    }

    id: number;
    canEdit: boolean;
    hasEditPermission: boolean;
    canViewGeneralItems: boolean;
    canAddGeneralItems: boolean;
    canEditGeneralItems: boolean;
    canDeleteGeneralItems: boolean;

    title: string;
    lineItems: LeadProposalLineItem[];
    lineItemDisplayPreference: DisplayPreferences<ProposalDisplayColumnTypes>;
    canEditCostCodes: boolean;
    costCategories: BTSelectItem[];
    costCodes: BTSelectItem<CostCodeExtraData>[];
    costTypes: BTServiceDropdown;
    internalNotes: string;
    defaultIntroductionText: string;
    introductionText: string;
    defaultClosingText: string;
    closingText: string;
    approvalDeadline: moment.Moment | null;
    entityAddedDate: moment.Moment | undefined;
    columnsToDisplay: BTSelectItem[];
    categories: ProposalCategory[];
    status: ProposalStatusObject;
    formatOptions: IProposalFormatOptions;
    proposalPrintOptions: ProposalPrintOptions;
    proposalAddedByName: string;
    proposalAddedById: number | null;
    proposalAddedDate: moment.Moment;
    viewType: ProposalGroupTypes;
    lastUpdatedByName: string;
    lastUpdatedById: number | null;
    lastUpdatedByDate: moment.Moment;

    // IHasAttachedFiles
    attachedFiles: AttachedFiles; // used for setting up the file upload container
    attachments: AttachedFilesRequest; // used for adding files for new cost groups
    builderId: number;
    documentInstanceType: DocumentInstanceType;
    jobId: number | undefined;
    leadId: number | undefined;
    name: string;

    builderAddress: AddressEntity;
    builderInfo: BuilderInfo;
    customerAddress: AddressEntity | null;
    contactAddress: AddressEntity | null;
    showContactAddress: boolean;
    customerInfo: CustomerInfo | null;
    hasValidEmail: boolean;
    defaultReleaseText: string;
    showResetOtherProposalsText: boolean;
    resetOtherProposalIds: number[] | null;
    hasApprovedProposal: boolean;
    hasOtherPendingProposal: boolean | undefined;

    // online payment info
    paymentAmount?: number;
    paymentDueDays?: string;
    paymentDueType?: string;
    paymentStatus?: LeadProposalPaymentStatusType;
    paymentType?: string;
    allowedPaymentTypes?: PaymentMethodType[];
    showPayOnline: boolean;
    canEditPayment: boolean;
    showPayAndApprove: boolean;
    entityType: PaymentEntityTypes;
    showApprovalSignaturePad: boolean;
    customTitle: string;
    merchantPayments?: MerchantPaymentListData;
    canRefund: boolean;
    showRefund: boolean;
    builderCountry?: "US" | "CA";

    relatedLeadProposals?: IRelatedLeadProposal[];
    isTemplate: boolean;
    templateDescription?: string;
    salesPerson: BTSelectItem[] | undefined;
    taxMethod: BTSelectItem[];
    proposalType: ProposalTypes;
    disclaimer: string;
    costItemUpdateMessage: string | null;
    canSendToHomeDepotCart: boolean;
    connectedTakeoffPartners: TakeoffPartner[] | undefined;
    currentDate: string;

    canLaunchTakeoff: boolean;
    takeoffPartnerId?: number;
    hasTakeoffUser: boolean;
    takeoffProjectLink?: string;
    takeoffProjectName?: string;
    projectConnectedToTakeoffs: boolean;

    assemblies: BTSelectItem<IAssemblySelectorExtraData>[];
    hasCostCodesFeature: boolean;
    flatRateCostCode: BTSelectItem<CostCodeExtraData> | undefined;
    taxGroups: BTSelectItem<TaxGroupServiceListItemExtraData>[];
    isInternational?: boolean;
    taxGroupId?: number;
    taxRates?: TaxRateEntity[];
}

function getAndSortLineItems(
    data: any,
    costCodes: BTSelectItem<CostCodeExtraData>[],
    lineItemDisplayPreference: DisplayPreferences<ProposalDisplayColumnTypes>
) {
    let lineItems: LeadProposalLineItem[] = data.groupOptions.value.flatMap((rootItem: any) => {
        return [
            ...(rootItem.subGroups?.flatMap(
                (subGroup: any) =>
                    subGroup.estimates?.map(
                        (li: any) =>
                            new LeadProposalLineItem(
                                li,
                                data.takeoffProjectLink,
                                data.takeoffProjectName
                            )
                    ) ?? []
            ) ?? []),
            ...(rootItem.estimates?.map(
                (li: any) =>
                    new LeadProposalLineItem(li, data.takeoffProjectLink, data.takeoffProjectName)
            ) ?? []),
        ];
    });

    const costCodeMap = new Map(costCodes.map((x) => [Number(x.id), x.title]));

    if (lineItemDisplayPreference.estimateGroupType === ProposalGroupTypes.CostCategory) {
        return lineItems.sort((a, b) =>
            (costCodeMap.get(a.costCodeId!) ?? "").localeCompare(
                costCodeMap.get(b.costCodeId!) ?? ""
            )
        );
    } else if (lineItemDisplayPreference.estimateGroupType === ProposalGroupTypes.None) {
        // Sort the line items based on worksheet display order if possible
        // Default all null worksheet display orders to -1 so they sort first.
        return orderBy(
            lineItems,
            [(item) => item.worksheetDisplayOrder ?? -1, "dateAddedForSorting", "id"],
            "asc"
        );
    }

    return orderBy(lineItems, ["dateAddedForSorting", "id"], "asc");
}

function getApprovalDeadline(data: any) {
    return data.approvalDeadline?.value ? moment(data.approvalDeadline.value) : null;
}

function getEntityAddedDate(data: any) {
    return data.entityAddedDate ? moment(data.entityAddedDate) : undefined;
}

function getSalesPerson(data: any): BTSelectItem[] | undefined {
    return data.salesPerson ? mapBTServiceDropdownToSelectItem(data.salesPerson) : undefined;
}

function getCategories(data: any, builderId: number, leadId: number | undefined) {
    return data.groupOptions.value.map((x: any) => {
        // since the line items response here will not match keys expected, we will first convert them into lead proposal line items
        const lineItems = x.estimates?.map((li: any) => new LeadProposalLineItem(li));
        return new ProposalCategory({ ...x, lineItems: lineItems }, builderId, undefined, leadId);
    });
}

function getMerchantPayments(data: any) {
    return data.merchantPayments ? new MerchantPaymentListData(data.merchantPayments) : undefined;
}

function getProposalType(data: any) {
    return data.isTemplate ? ProposalTypes.LeadProposalTemplate : ProposalTypes.LeadProposal;
}

function getAssemblies(data: any) {
    return data.assemblies
        ? data.assemblies.map(
              (x: any) =>
                  new BTSelectItem<IAssemblySelectorExtraData>({
                      id: x.id,
                      name: x.name,
                      extraData: x.extraData,
                  })
          )
        : [];
}

function getTaxGroups(data: any) {
    return data.taxGroups
        ? mapBTServiceDropdownToSelectItem<TaxGroupServiceListItemExtraData>(data.taxGroups)[0]
              ?.children ?? []
        : [];
}

export enum PaymentDueTypeString {
    RequiredToApprove = "Required to Approve Proposal",
    AfterApproval = "After Approval Of Proposal",
}

export class LeadProposalDeleteResponse {}

export class LeadProposalCreateResponse {
    constructor(data: any) {
        this.id = data.id ?? data;
    }

    id: number;
}

export class LeadProposalTemplatesResponse {
    constructor(data: any) {
        this.proposals = data.proposal.value
            .filter((p: any) => p.id !== -1)
            .map((p: any) => new BTSelectItem(p));
        this.canEdit = data.canEdit;
    }
    proposals: BTSelectItem[];
    canEdit: boolean;
}

export enum TabKeys {
    Worksheet = "1",
    Format = "2",
    Preview = "3",
}

export class LeadProposalSaveRequest {
    constructor(formValues: ILeadProposalFormValues, builderId: number) {
        let categories: ProposalCategory[] = [];
        if (isFormatItemListGrouped(formValues.formatItems)) {
            categories = formValues.formatItems;
        } else {
            const unassignedGroup = getUnassignedCategory(builderId);
            unassignedGroup.items = formValues.formatItems as LeadProposalLineItem[];
            categories = [unassignedGroup];
        }

        this.groupOptions = categories.map(
            (x, i) => new LeadProposalGroupSaveRequest(x, i, formValues.deletedLineItems)
        );
        this.introText = formValues.introductionText;
        this.closingText = formValues.closingText;
        this.whatToDisplay = formValues.columnsToDisplay;
        this.title = formValues.title;
        this.approvalDeadline = formValues.approvalDeadline;
        this.internalNotes = formValues.internalNotes;
        this.attachedFiles = formValues.attachments;
        this.showAddress = formValues.showAddress;
        this.showContactInfo = formValues.showOwnerContactInfo;
        this.bodyLayout = formValues.bodyLayout;
        this.headerLayout = formValues.headerLayout;
        this.showPrintoutInfo = formValues.showPrintoutInfo;
        this.printoutType = formValues.printoutType;
        this.deletedEstimates = formValues.deletedLineItems;
        this.salesPerson = formValues.salesPerson;
        this.templateDescription = formValues.templateDescription;
        this.deletedGroups = formValues.deletedCategories;
        this.taxMethod = formValues.taxMethod;
    }

    introText: string;
    closingText: string;
    whatToDisplay: number[];
    groupOptions: LeadProposalGroupSaveRequest[];
    title: string;
    internalNotes: string;
    approvalDeadline: moment.Moment | null;
    attachedFiles: AttachedFilesRequest;

    showAddress: boolean;
    showContactInfo: boolean;
    bodyLayout: ProposalBodyLayouts;
    headerLayout: ProposalHeaderLayouts;
    showPrintoutInfo: boolean;
    printoutType: ProposalPrintoutType;
    deletedEstimates: number[];
    salesPerson?: number;
    templateDescription?: string;
    deletedGroups: number[];
    taxMethod: number;
}

class LeadProposalSubCategorySaveRequest {
    constructor(data: ProposalSubCategory, displayOrder: number, parentId: number) {
        this.id = data.id >= UnassignedCostGroupId ? data.id : 0;
        this.parentId = parentId;
        this.displayOrder = displayOrder;
        this.description = data.description;
        this.title = data.title;
        this.attachedFiles = data.attachments;

        this.estimates = data.items.map(
            (item, displayOrder) =>
                new LeadProposalLineItemSaveRequest(
                    item as LeadProposalLineItem,
                    displayOrder,
                    data.id
                )
        );

        this.costCategoryId = data.costCategoryId;
        this.isExpanded = data.isExpanded;
    }

    id: number;
    parentId: number;
    displayOrder: number;
    description: string | null;
    title: string;
    attachedFiles: AttachedFilesRequest;
    estimates: LeadProposalLineItemSaveRequest[];
    isExpanded: boolean;
    costCategoryId: number | null;
}

class LeadProposalGroupSaveRequest {
    constructor(data: ProposalCategory, displayOrder: number, deletedItems?: number[]) {
        this.id = data.id >= UnassignedCostGroupId ? data.id : 0;
        this.assemblyId = data.assemblyId;
        this.displayOrder = displayOrder;
        this.description = data.description;
        this.title = data.title;
        this.attachedFiles = data.attachments;
        let items = [...data.items];
        if (deletedItems !== undefined) {
            items = items.filter((x) => !deletedItems.includes(x.id));
        }

        this.estimates = [];
        this.subGroups = [];
        items.forEach((item, displayOrder) => {
            if (isLineItemRow(item)) {
                this.estimates.push(
                    new LeadProposalLineItemSaveRequest(
                        item as LeadProposalLineItem,
                        displayOrder,
                        data.id
                    )
                );
            } else {
                this.subGroups.push(
                    new LeadProposalSubCategorySaveRequest(item, displayOrder, data.id)
                );
            }
        });

        this.costCategoryId = data.costCategoryId;
        this.isExpanded = data.isExpanded;
    }

    id: number;
    assemblyId?: number;
    displayOrder: number;
    description: string | null;
    title: string;
    attachedFiles: AttachedFilesRequest;
    estimates: LeadProposalLineItemSaveRequest[];
    subGroups: LeadProposalSubCategorySaveRequest[];
    isExpanded: boolean;
    costCategoryId: number | null;
}

class LeadProposalLineItemSaveRequest {
    constructor(data: LeadProposalLineItem, displayOrder: number, categoryId: number) {
        this.id = data.id < 0 ? 0 : data.id;
        this.costCode = data.costCodeId;
        this.groupId = categoryId;
        this.displayOrder = displayOrder;
        this.title = data.itemTitle;
        this.notes = data.description;
        this.internalNotes = data.internalNotes;
        this.costCodeItemId = data.costItemId;
        this.isCostCodeItem = data.costItemId !== null;
        this.quantity = data.quantity;
        this.unitType = data.unit;
        this.unitCost = data.unitCost;
        this.markupType = data.markupType;
        this.markupPercent = data.markupPercent;
        this.markupPerUnit = data.markupPerUnit;
        this.markupAmount = data.markupAmount;
        this.ownerPrice = data.ownerPrice;
        this.hasFullDetails = true;
        this.costTypes = data.costTypes;
        this.markedAs = data.markedAs;
        this.includeItemInCatalog = data.includeItemInCatalog;
        this.assemblyId = data.assemblyId;
        this.taxGroupId = data.taxGroupId === NoTaxId ? null : data.taxGroupId;
        this.dateAddedForSorting = data.dateAddedForSorting;
    }

    id: number;
    groupId: number;
    displayOrder: number;
    hasFullDetails: boolean;

    title: string | null;
    notes: string; // is description
    internalNotes: string | null;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;

    costCode: number | null;
    costCodeItemId: number | null;
    isCostCodeItem: boolean;

    quantity: number;
    unitType: string;
    unitCost: number;

    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
    includeItemInCatalog?: boolean;

    assemblyId?: number | null;
    taxGroupId?: number | null;
    dateAddedForSorting?: moment.Moment | null;
}

export interface ILeadProposalReleaseRequest {
    leadEmail: string | undefined;
    additionalText: string | null;
    sendBuilderACopy: boolean;
    status: ProposalStatus;
}

export class LeadProposalUnreleaseRequest {
    constructor(
        status: ProposalStatus,
        values?: IResetStatusPromptFormValues,
        updateCostItems?: boolean
    ) {
        this.status = status;
        this.updateCostItems = updateCostItems;

        if (values) {
            this.saveToPdf = values.savePdfCopy;
            this.pdfName = values.pdfTitle;
            this.resetAll = values.resetOtherProposals!;
        }
    }

    status: ProposalStatus;
    saveToPdf: boolean;
    pdfName: string;
    resetAll: boolean;
    updateCostItems?: boolean;
}

export interface ILeadProposalApproveDeclineRequest {
    status: ProposalStatus;
    signatureFile: BTServiceFileUpload;
    updatePaymentRequestDueType?: boolean;
    approvalComments?: string;
    isExternalLead?: boolean;
    notifyLead?: boolean;
}

export interface ILeadProposalExternalRequest {
    status: ProposalStatus;
    signatureFile: BTServiceFileUpload;
    approvalComments: string;
    shareToken?: string;
}

export enum ProposalPaymentDueTypes {
    RequiredToApprove = 0,
    AfterApproval = 1,
}

export enum PaymentMethodType {
    Check = 1,
    CreditCard = 2,
    All = 3,
}

class PaymentMethodTypeExtraData {
    constructor(data: any) {
        this.minAmount = data.minAmount;
    }
    minAmount: number;
}

export class LeadProposalPaymentRequestResponse {
    constructor(data: any) {
        this.requireOnlinePayment = data.requireOnlinePayment.value;
        this.paymentAmount = data.paymentAmount.value;
        this.paymentDueType = getSelectedValue(
            data.paymentDueType.value.map((x: any) => new BTSelectItem(x))
        );

        this.paymentType = data.paymentType.value.map((x: any) => new BTSelectItem(x));
        this.minimumCreditCardTransaction = this.paymentType.find(
            (x) => parseInt(x.id) === PaymentMethodType.CreditCard
        )?.extraData!.minAmount!;
        this.minimumCheckTransaction = this.paymentType.find(
            (x) => parseInt(x.id) === PaymentMethodType.Check
        )?.extraData!.minAmount!;

        this.proposalAmount = data.proposalAmount.value;
        this.usePercentage = data.usePercentage.value;
        this.paymentPercent = data.paymentPercent.value;
        this.paymentDueDays = data.paymentDueDays.value ?? undefined;
        this.includeUponApprovalDueType =
            data.paymentDueType.value.find(
                (x: any) => x.id === ProposalPaymentDueTypes.RequiredToApprove
            ) !== undefined;
    }

    requireOnlinePayment: boolean;
    paymentAmount: number;
    paymentDueType: ProposalPaymentDueTypes;
    paymentDueDays: number | undefined;
    paymentType: BTSelectItem<PaymentMethodTypeExtraData>[];
    proposalAmount: number;
    usePercentage: boolean;
    paymentPercent: number;
    // if the proposal is approved, the builder will no longer be able to set the proposal payment to upon approval
    includeUponApprovalDueType: boolean;
    minimumCreditCardTransaction: number;
    minimumCheckTransaction: number;
}

export enum PaymentType {
    Amount = 0,
    Percent = 1,
}

export interface ILeadProposalPaymentRequestFormValues {
    paymentAmount: number | null;
    paymentPercent: number | null;
    paymentDueDays: number | undefined;
    paymentDueType: ProposalPaymentDueTypes;
    paymentType: PaymentMethodType;
    usePercentage: boolean;
}

interface IRelatedLeadProposal {
    encodedId: string;
    title: string;
    shareToken?: string;
}

export class CreateCostItemFromLineItemRequest {
    constructor(lineItem: LeadProposalLineItem) {
        this.lineItems = new ProposalLineItemSaveRequest(lineItem);
    }

    lineItems: ProposalLineItemSaveRequest;
}

export class CreateCostItemFromLineItemResponse {
    constructor(data?: any) {
        this.costItemId = data.costItemId ?? data;
    }

    costItemId?: number;
}

export class GetAvailableTakeoffsResponse {
    constructor(data: any) {
        this.availableTakeoffs = data.availableTakeoffs.map(
            (x: any) => new BTSelectItem<ITakeoffResponseExtraData>(x)
        );
    }

    availableTakeoffs: BTSelectItem<ITakeoffResponseExtraData>[];
}

export class ITakeoffResponseExtraData {
    hasBeenImported: boolean;
}
