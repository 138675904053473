import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FileViewOptions } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent } from "react";

import { MediaType } from "types/enum";

import {
    BTFileUploadMode,
    IBTFileUploadFormValues,
} from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { MediaAttachmentNotifications } from "commonComponents/entity/media/MediaAttachmentOptions/MediaAttachmentNotifications";
import { getAttachmentOptions } from "commonComponents/entity/media/MediaAttachmentOptions/MediaAttachmentOptions.utils";
import { MediaAttachmentViewingPermissions } from "commonComponents/entity/media/MediaAttachmentOptions/MediaAttachmentViewingPermissions";
import { IBTInjectedFormikProps } from "commonComponents/utilities/Formik/WithFormikBT";

interface IMediaAttachmentViewingPermissions {
    fileViewOptions: FileViewOptions;
    isTemplateMode: boolean;
    areNotificationsHidden: boolean;
    uploadMode: BTFileUploadMode;
    mediaType: MediaType;
    isSaving: boolean;
    onToggleNotifications: () => void;
    onViewingPermissionsChange(e: CheckboxChangeEvent): void;
}

export const MediaAttachmentOptions: FunctionComponent<
    IBTInjectedFormikProps<IMediaAttachmentViewingPermissions, IBTFileUploadFormValues>
> = ({ fileViewOptions, isTemplateMode, onViewingPermissionsChange, ...props }) => {
    const { showOptions, isShowVisible, notifyOptions, isNotifyVisible } =
        getAttachmentOptions(fileViewOptions);

    return (
        <>
            {isShowVisible && showOptions && (
                <MediaAttachmentViewingPermissions
                    {...props}
                    showOptions={showOptions}
                    onChange={onViewingPermissionsChange}
                />
            )}
            {!isTemplateMode && isNotifyVisible && notifyOptions && (
                <MediaAttachmentNotifications {...props} notifyOptions={notifyOptions} />
            )}
        </>
    );
};
