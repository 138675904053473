// eslint-disable-next-line no-restricted-imports
import { GroupDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { isEqual, orderBy } from "lodash-es";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { DirectionTypeEnums, ProposalGroupTypes } from "types/enum";

import { APIError, showAPIErrorMessage } from "utilities/apiHandler";
import {
    EmptyStateEntity,
    getDefaultPagingData,
    getInitialFilterValues,
    GridRequest,
    ListEntityType,
    PagingData,
    shouldReloadFilters,
} from "utilities/list/list.types";
import { isNullOrUndefined } from "utilities/object/object";

import { btError } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { getDefaultPageSize } from "commonComponents/btWrappers/BTPagination/BTPagination";
import {
    GridViewColumn,
    GridViewItem,
    GridViewsResponse,
    IGridSettingsFormValues,
    IGridViewSaveRequest,
} from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";
import { gridStateManager } from "commonComponents/utilities/Grid/GridContainer";
import {
    GridColumn,
    GridEntity,
    IGridState,
    IListWithGridHandler,
    IListWithGridProps,
    IListWithGridState,
} from "commonComponents/utilities/Grid/GridContainer.types";
import { ILineItemGroup } from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import { isLineItemRow } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";
import { getFormatLineItemsFromFormatItemList } from "commonComponents/utilities/ProposalBaseLineItemContainer/WorksheetLineItemContainer.utility";

import { IAssemblySelectorExtraData } from "entity/assembly/AssemblySelector/AssemblySelector";
import {
    EstimateGridData,
    FormatItemList,
    getDefaultProposalCategoryAttachedFiles,
    IEstimateLineItem,
    ProposalCategory,
} from "entity/estimate/common/estimate.common.types";
import {
    FilterEntity,
    IFilterFormValues,
    StandardFilterId,
    SystemFilterType,
} from "entity/filters/Filter/Filter.api.types";

const customFieldColumnRegex =
    /\['(?<associatedType>\d*)',(?<customFieldColumnId>\d*),(?<dataType>\d*)\]/;

export const mapGridStateToRequest = (
    emptyStateEntity: EmptyStateEntity,
    columnsList?: (string | number)[],
    sortingData?: SortDescriptor[],
    selectedGridView?: GridViewItem,
    footerData?: {} | null
): GridRequest => {
    const gridRequest = new GridRequest(emptyStateEntity);
    gridRequest.savedViewId = StandardFilterId;
    if (columnsList) {
        gridRequest.selectedColumns = columnsList.map((c) => {
            const columnId = c.toString();
            const matches = columnId.match(customFieldColumnRegex);
            if (matches && matches.groups) {
                // Custom field column id (ex: "['14',169398,0]")
                return matches.groups.customFieldColumnId;
            }
            // Standard column id
            return columnId;
        });
    }
    if (sortingData && sortingData.length > 0) {
        gridRequest.sortColumn = sortingData[0].field;
        gridRequest.sortDirection = sortingData[0].dir || "asc";
    }
    if (selectedGridView) {
        if (selectedGridView.columns.length > 0) {
            gridRequest.selectedColumns = selectedGridView.columns.map((col) => col.id);
        }
        if (!selectedGridView.isCustom) {
            gridRequest.savedViewId = selectedGridView.viewId ?? StandardFilterId;
        }
    }
    gridRequest.hasFooter = !!footerData;
    return gridRequest;
};

export class IGridUpdateData {
    updatedFilterValues?: IFilterFormValues;
    updatedPagingData?: PagingData;
    updatedSortData?: SortDescriptor[];
    updatedGridView?: GridViewItem;
    updatedJobIds?: number[];
    updatedGroups?: GroupDescriptor[];
    selectedSystemFilter?: SystemFilterType;
}

export class IFullReloadGridUpdateData extends IGridUpdateData {
    filterEntity?: FilterEntity;
    selectedSystemFilter?: SystemFilterType;
    gridViewsResponse?: GridViewsResponse;
}

export const mapSavedViewToRequest = (
    values: IGridSettingsFormValues,
    columns: GridViewColumn[],
    pagingData: PagingData,
    gridType: ListEntityType,
    columnList: GridColumn<unknown, unknown>[],
    sortingData: SortDescriptor[]
) => {
    const { viewName, isDefault, isPrivate } = values;
    const { pageSize } = pagingData;
    const columnListIds = columnList.map((col) => col.id);

    const savedViewRequest: IGridViewSaveRequest = {
        viewName,
        isDefault,
        isPrivate,
        columns,
        pageSize,
        gridType,
        columnListIds,
    };

    if (sortingData.length > 0) {
        const sort = sortingData[0];
        savedViewRequest.sortColumnId = sort.field;
        savedViewRequest.sortDirection = sort.dir;
    }

    return savedViewRequest;
};

/**
 * Get flat list of grid data for readonly grid views.
 * Only works for one level of groups (no subgroups).
 */
export function getGridData(
    lineItems: IEstimateLineItem[],
    groups: ILineItemGroup<IEstimateLineItem>[],
    proposalGroupType: ProposalGroupTypes
) {
    if (proposalGroupType === ProposalGroupTypes.None) {
        // For none, we already have all the line items and we don't want to group/tree.
        // So just return what we got
        return lineItems;
    } else {
        return flattenTreeData(getTreeData(lineItems, groups, proposalGroupType));
    }
}

/**
 * Get groups for readonly grid views.
 * Only works for one level of groups (no subgroups).
 */
export function getGridGroups(
    proposalGroupType: ProposalGroupTypes,
    costCategories: BTSelectItem<undefined>[],
    assemblies: BTSelectItem<IAssemblySelectorExtraData>[]
): ILineItemGroup<IEstimateLineItem>[] {
    let selectItems: BTSelectItem<IAssemblySelectorExtraData | undefined>[];
    switch (proposalGroupType) {
        case ProposalGroupTypes.CostCategory:
            selectItems = costCategories;
            break;
        case ProposalGroupTypes.Assembly:
            selectItems = assemblies;
            break;
        case ProposalGroupTypes.Takeoff:
            selectItems = assemblies.filter((a) => a.extraData?.takeoffAssemblyId);
            break;
        default:
            return [];
    }

    return selectItems.map(getLineItemGroupFromSelectItem);
}

/**
 * Generate groups from select items for readonly grid views.
 * Only works for one level of groups (no subgroups).
 */
function getLineItemGroupFromSelectItem(
    selectItem: BTSelectItem<IAssemblySelectorExtraData | undefined>
): ILineItemGroup<IEstimateLineItem> {
    // BTSelectItem.id is typed incorrectly and is a number on these select items
    const itemId = Number(selectItem.id);

    return {
        id: itemId,
        title: selectItem.title,
        isExpanded: true,
        items: [],
    };
}

export function mapGridDataToFormatItems(
    formatData: ProposalCategory[],
    viewType: ProposalGroupTypes,
    costCategories: BTSelectItem<undefined>[],
    assemblies: BTSelectItem<IAssemblySelectorExtraData>[],
    builderId: number,
    jobId: number
): FormatItemList {
    /** getTreeData just associates the line items with their respective groups, it doesn't sort or filter them.
        So we'll modify the list before passing it in or early return if the list doesn't need further conversion.*/
    let preppedLineItems = getFormatLineItemsFromFormatItemList(formatData);
    switch (viewType) {
        case ProposalGroupTypes.None:
            return orderBy(preppedLineItems, ["dateAddedForSorting"], ["asc"]);
        case ProposalGroupTypes.ProposalWorksheet:
            return formatData;
        case ProposalGroupTypes.CostCategory:
        case ProposalGroupTypes.Assembly:
            preppedLineItems = orderBy(preppedLineItems, ["costCodeTitle", "itemTitle"], ["asc"]);
            break;
        case ProposalGroupTypes.Takeoff:
            preppedLineItems = preppedLineItems.filter((li) => li.takeoffLineItemId);
            preppedLineItems = orderBy(preppedLineItems, ["costCodeTitle", "itemTitle"], ["asc"]);
            break;
    }

    const treeData = getTreeData(
        preppedLineItems,
        getGridGroups(viewType, costCategories, assemblies),
        viewType
    );

    // Convert the old line item container types to the format types for display
    const formatItems = treeData.map((group) => {
        let proposalCategory = new ProposalCategory(
            {
                ...group,
                name: group.title,
                lineItems: group.items.map((li, index) => {
                    return { ...li, displayOrder: index };
                }),
                attachedFiles: getDefaultProposalCategoryAttachedFiles(jobId),
            },
            builderId,
            jobId,
            undefined,
            false
        );

        switch (viewType) {
            case ProposalGroupTypes.CostCategory:
                proposalCategory.costCategoryId = group.id;
                break;
            case ProposalGroupTypes.Assembly:
            case ProposalGroupTypes.Takeoff:
                proposalCategory.assemblyId = group.id;
                break;
            default:
                break;
        }
        return proposalCategory;
    });

    return formatItems;
}

/**
 * Assign line items to associated groups to form tree structure for readonly grid views.
 * Only works for one level of groups (no subgroups).
 */
function getTreeData(
    lineItems: IEstimateLineItem[],
    groups: ILineItemGroup<IEstimateLineItem>[],
    proposalGroupType: ProposalGroupTypes
): ILineItemGroup<IEstimateLineItem>[] {
    return groups.map((group) => {
        const items = selectChildrenForGroup(lineItems, group, proposalGroupType);
        return {
            ...group,
            items: items,
            _isInEdit: group._isInEdit ?? false,
            isSelected: undefined,
        };
    });
}

function selectChildrenForGroup(
    lineItems: IEstimateLineItem[],
    group: ILineItemGroup<IEstimateLineItem>,
    proposalGroupType: ProposalGroupTypes
) {
    switch (proposalGroupType) {
        case ProposalGroupTypes.CostCategory:
            return lineItems.filter((li) => li.costCategoryId === group.id);
        case ProposalGroupTypes.Assembly:
        case ProposalGroupTypes.Takeoff:
            return lineItems.filter((li) =>
                group.id === -1 ? isNullOrUndefined(li.assemblyId) : li.assemblyId === group.id
            );
        default:
            return lineItems;
    }
}

export function flattenTreeData(data: EstimateGridData): EstimateGridData {
    return data.flatMap((r) => (isLineItemRow(r) ? r : [r, ...flattenTreeData(r.items)]));
}

export const getUpdatedGridState = <T extends GridEntity, V extends IListWithGridState<T>>(
    prevState: Readonly<V>,
    gridUpdateData: IGridUpdateData,
    gridEntity: T,
    shouldExpandGroups?: boolean,
    persistRowSelection?: boolean
): IGridState => {
    let prevGridState = { ...prevState.gridState };
    return gridStateManager.onDataAndColumnsLoaded(
        prevGridState,
        gridEntity.data,
        gridEntity.columnsListAll,
        gridEntity.footerData,
        gridUpdateData.updatedSortData,
        gridUpdateData.updatedGridView,
        gridUpdateData.updatedGroups,
        shouldExpandGroups,
        persistRowSelection
    );
};

export function isGridErrorMessageApiError(e: unknown): e is APIError {
    const errorTypes = [
        "SQL Deadlock",
        "SQL Exception",
        "SQL Timeout",
        "Please refine your search filters",
    ];
    return (
        e instanceof APIError && errorTypes.some((errorType) => e.errorMessage.includes(errorType))
    );
}

export function showGridErrorMessage(e: unknown): void {
    if (isGridErrorMessageApiError(e)) {
        btError({
            title: "An error occurred while retrieving your data.",
            content: <div className="text-whitespace">{e.errorMessage}</div>,
        });
    } else {
        showAPIErrorMessage(e);
    }
}

export const getGridUpdateDataForFullReload = async <
    T extends GridEntity,
    V extends IListWithGridHandler<T>
>(
    props: IListWithGridProps<V>,
    state: IListWithGridState<T>,
    // Should be true when switching to view all jobs. We need to update the Saved Views to see if the views have any columns that are only shown with multiple jobs selected.
    refreshSavedViews?: boolean,
    isBTAdmin?: boolean
): Promise<IFullReloadGridUpdateData> => {
    const { gridSettingsHandler, entityType, jobIds } = props;
    const { filterEntity: prevFilterEntity, gridEntity: prevGridEntity } = state;
    // Load the filters based on the current job
    const { filterEntity, filterValues } = await getInitialFilterValues(props, isBTAdmin);
    const shouldReload = shouldReloadFilters(filterEntity, prevFilterEntity);
    const filters = shouldReload ? filterValues : undefined;
    // If the filter has changed, reload the default grid view as well, since the available columns might be different
    let gridViewsResponse: GridViewsResponse | undefined = undefined;
    if (shouldReload || refreshSavedViews) {
        gridViewsResponse = await gridSettingsHandler!.getGridViews(entityType, isBTAdmin, jobIds);
    }

    // Don't change the selected grid if manually refreshing the Saved Views
    if (shouldReload && refreshSavedViews) {
        let updatedSelectedGridView: GridViewItem | undefined;
        if (state.selectedGridView) {
            const previousGridViewId = state.selectedGridView?.viewId;
            updatedSelectedGridView = gridViewsResponse?.items.find(
                (view) => view.value === previousGridViewId
            )?.extraData;
        }
        gridViewsResponse!.defaultItem = updatedSelectedGridView ?? gridViewsResponse?.defaultItem!;
        // If we manually reloaded, we should also update the column configs
        gridViewsResponse!.defaultItem.shouldResetColumnConfigs = true;
    }

    // pull the previous paging data
    let pagingData = prevGridEntity?.pagingData;
    if (!pagingData) {
        // If it wasn't already defined, try to load the page size first from your local storage, then from the grid view
        pagingData = new PagingData(
            getDefaultPageSize(entityType, gridViewsResponse?.defaultItem.pageSize)
        );
    }

    if (!gridViewsResponse) {
        gridViewsResponse = new GridViewsResponse({
            defaultItem: {
                ...state.selectedGridView,
                sortColumnId: state.selectedGridView?.sort[0]?.field,
                sortDirection: DirectionTypeEnums.get(state.selectedGridView?.sort[0]?.dir),
            },
            items: state.gridViews,
            allGridColumns: state.gridState.columnsList,
        });
    }

    // If the filterEntity's options have changed then use the new filterEntity
    const filterEntityToUse = isEqual(filterEntity.options, prevFilterEntity?.options)
        ? prevFilterEntity
        : filterEntity;
    return {
        updatedGridView: gridViewsResponse?.defaultItem,
        updatedFilterValues: filters,
        // We know this is the initial load or we're changing jobs so reset to page 1
        updatedPagingData: getDefaultPagingData(pagingData, true),
        filterEntity: filterEntityToUse,
        gridViewsResponse,
        selectedSystemFilter: filterEntity.selectedSystemFilter?.id,
    };
};

export const filterSelectedGridColumns = (
    allColumns: GridColumn<unknown>[],
    updatedColumnsList?: GridViewColumn[]
) => {
    if (updatedColumnsList === undefined) {
        return undefined;
    }
    const updatedColumns = allColumns.map((allCol) =>
        updatedColumnsList.some((selCol) => selCol.id === allCol.id)
            ? {
                  ...allCol,
                  width: updatedColumnsList.find((col) => col.id === allCol.id)!.width,
                  isFrozen: updatedColumnsList.find((col) => col.id === allCol.id)!.isFrozen,
                  enabled: true,
              }
            : { ...allCol, enabled: false }
    );
    return {
        updatedColumnsList: updatedColumns,
        filteredColumns: updatedColumns.filter((allCol) =>
            updatedColumnsList.some((selCol) => selCol.id === allCol.id)
        ),
    };
};
