import { FormikValues, InjectedFormikProps, withFormik, WithFormikConfig } from "formik";
import { useCallback, useState } from "react";

import {
    getDirtyValues,
    IDirtyTrackingConfig,
    IDirtyTrackingInjectedProps,
} from "commonComponents/utilities/DirtyTracking/DirtyTracking.utilities";

// Formik's out-of-the-box config plus those needed by our wrappers
type IBTWithFormikConfig<Props, Values extends FormikValues, Payload = Values> = WithFormikConfig<
    Props,
    Values,
    Payload
> &
    IDirtyTrackingConfig<Values>;

// Formik's out-of-the-box injected props combined with those injected from our wrappers
export type IBTInjectedFormikProps<Props, Values extends FormikValues> = InjectedFormikProps<
    Props,
    Values
> &
    IDirtyTrackingInjectedProps<Values>;

/**
 * This takes our extension of Formik's config and returns a wrapper for a component that
 * expects formik's injected props as well as those from our custom wrappers
 */
export const withFormikBT = <Props, Values extends FormikValues>(
    wrappedConfig: IBTWithFormikConfig<Props, Values>
) => {
    const wrapperHOC = (Component: React.ComponentType<IBTInjectedFormikProps<Props, Values>>) => {
        // Expects injected props from withFormik - handle all custom wrapper logic in here
        const WrapperComponent: React.FC<InjectedFormikProps<Props, Values>> = (props) => {
            const { setFieldValue, resetForm, values, handleSubmit, initialValues } = props;
            const [overriddenValues, setOverriddenValues] = useState<Partial<Values>>({});

            const handleSetFieldValueWithoutDirtyTracking = useCallback(
                (field: string, value: Object) => {
                    setFieldValue(field, value);
                    setOverriddenValues({ ...overriddenValues, [field]: value });
                },
                [setFieldValue, overriddenValues]
            );

            const handleResetForm = (nextValues?: Values) => {
                resetForm(nextValues);
                setOverriddenValues({});
            };

            const handleSubmitWrapper = (e?: React.FormEvent<HTMLFormElement>) => {
                handleSubmit(e);
                setOverriddenValues({});
            };

            const getValues = () => {
                const newInitialValues = {
                    ...initialValues,
                    ...overriddenValues,
                };
                return {
                    initialValues: newInitialValues,
                    currentValues: values,
                    setFieldValueWithoutDirtyTracking: handleSetFieldValueWithoutDirtyTracking,
                };
            };

            const baseIgnoreValues = ["attachedFilesConfiguration"];
            const ignoreDirtyValues = [
                ...(wrappedConfig.ignoreDirtyValues ?? []),
                ...baseIgnoreValues,
            ];

            const dirtyProps = getDirtyValues(getValues, { ...wrappedConfig, ignoreDirtyValues });

            return (
                <Component
                    {...props}
                    resetForm={handleResetForm}
                    handleSubmit={handleSubmitWrapper}
                    {...dirtyProps}
                />
            );
        };

        return withFormik<Props, Values>(wrappedConfig)(WrapperComponent);
    };

    return wrapperHOC;
};
