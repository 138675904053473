import { Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import classNames from "classnames";
import { IExternalServiceInfo } from "legacyComponents/FileUploadContainer";
import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef } from "react";

import { MediaType, TempFileTypes } from "types/enum";

import { track } from "utilities/analytics/analytics";
import {
    beforeFileUpload,
    fileTypeBlacklist,
    IUploadConfig,
    uploadAction,
} from "utilities/document/fileUpload.utils";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTIconInboxOutlined, BTIconUploadOutlined } from "commonComponents/btWrappers/BTIcon";
import "commonComponents/btWrappers/BTUploadRaw/BTUploadRaw.less";
import { FileDragTarget } from "commonComponents/entity/media/FileDragTarget/FileDragTarget";
import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

interface IBTUploadRawProps {
    id: string;
    "data-testid": string;

    readOnly?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    isDragger?: boolean;
    isArea?: boolean;
    jobId?: number;

    /**
     * @example ["*"] // all files
     * @example ["jpg", "gif", "doc", "docx"]
     */
    allowedFileTypes: string[];
    /**
     * If a file matches the both the blacklist and whitelist props,
     * the file is NOT allowed. Blacklists take precedence over whitelists.
     * @example ["jpg", "gif", "doc", "docx"]
     * @default our standard blacklist
     */
    disallowedFileTypes?: string[];

    tempFileType?: TempFileTypes;
    mediaType?: MediaType;

    value: UploadFile[] | undefined;

    uploadConfig?: IUploadConfig;

    showUploadList?: boolean;
    openFileDialogOnClick?: boolean;

    /*
     * Upload file properties that are required for external tempFile uploading
     */
    isExternal?: boolean;
    externalServiceInfo?: IExternalServiceInfo;

    beforeUpload?: (file: File, fileList: File[]) => Promise<boolean>;

    onChange: (info: UploadChangeParam) => void;
    onRemove: (file: UploadFile) => void;
}

const defaultBeforeUpload = async () => {
    return true;
};
const defaultUploadConfig = { shouldUploadFullResolutionPhoto: undefined };

export const BTUploadRaw: FunctionComponent<PropsWithChildren<IBTUploadRawProps>> = track(
    (props) => ({
        component: "Upload Raw",
        uniqueId: props["data-testid"],
    })
)(
    ({
        children,
        id,
        disabled,
        allowedFileTypes,
        value,
        readOnly,
        "data-testid": testid,
        tempFileType,
        multiple,
        isDragger,
        isArea,
        mediaType,
        jobId,
        isExternal,
        externalServiceInfo,
        onChange,
        onRemove,
        disallowedFileTypes = fileTypeBlacklist,
        beforeUpload = defaultBeforeUpload,
        uploadConfig = defaultUploadConfig,
        showUploadList = true,
        openFileDialogOnClick = true,
    }) => {
        const inputRef = useRef<Element | null>(null);
        const removeDirectoryPropFromInput = useCallback(() => {
            if (!inputRef.current?.isConnected) {
                const input = document.querySelector(`input#${id}`);
                inputRef.current = input;
            }

            // browsers do not support selecting both individual files AND directories in the file picker https://stackoverflow.com/questions/42633306/how-to-allow-the-user-to-pick-any-file-or-directory-in-an-input-type-file-ta
            // rc-upload couples webkitdirectory with the ability to drag and drop directories https://github.com/react-component/upload/blob/master/src/AjaxUploader.tsx
            // to get the best user experience, we want the ability to drag and drop directories, but only browse for files, so we remove the neccisary attributes
            // this can be removed if browsers support picking files and folders, or if rc-upload decouples browsing from drag and drop
            if (inputRef.current?.hasAttribute("webkitdirectory")) {
                inputRef.current.removeAttribute("webkitdirectory");
            }
            if (inputRef.current?.hasAttribute("directory")) {
                inputRef.current.removeAttribute("directory");
            }
        }, [id]);
        useEffect(() => {
            // ant will readd this prop on every render so we need to remove it
            removeDirectoryPropFromInput();
        });

        // Should introduce accept and onDrop props in future. We now have an function for onDrop in fileUpload.utils
        // but error toast doesn't display when dropping an incorrect file type using isArea display below. onDrop is
        // only triggered when using Upload.Dragger and the accept prop prevents beforeUpload from running when file invalid.
        let uploadProps = {
            id,
            "data-testid": testid,
            customRequest: tempFileType
                ? uploadAction(
                      tempFileType,
                      uploadConfig,
                      mediaType,
                      undefined,
                      jobId,
                      isExternal,
                      externalServiceInfo
                  )
                : undefined,
            multiple,
            defaultFileList: value,
            fileList: value,
            onChange,
            onRemove,
            beforeUpload: beforeFileUpload(
                allowedFileTypes,
                beforeUpload,
                false,
                disallowedFileTypes,
                mediaType
            ),
            disabled: readOnly || disabled,
            showUploadList,
            openFileDialogOnClick,
            directory: true,
        };

        if (readOnly) {
            return (
                <ValueDisplay
                    value={
                        !value || value.length === 0 ? undefined : (
                            // eslint-disable-next-line react/forbid-elements
                            <Upload {...uploadProps} />
                        )
                    }
                />
            );
        }

        if (isDragger) {
            return (
                <div className="dropbox">
                    {/* eslint-disable-next-line react/forbid-elements */}
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <BTIconInboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            <BTExternalLink isUnderline={true}>Browse</BTExternalLink> or drag
                            file(s) here to upload
                        </p>
                        {children}
                    </Upload.Dragger>
                </div>
            );
        }

        if (isArea) {
            return (
                <div onMouseDown={removeDirectoryPropFromInput}>
                    <FileDragTarget
                        allowDrop={!uploadProps.disabled}
                        forceDragOverDropEffect
                        render={() => (
                            // eslint-disable-next-line react/forbid-elements
                            <Upload
                                {...uploadProps}
                                className={classNames("BTUploadRaw-UploadArea", {
                                    "BTUploadRaw-Unclickable": !openFileDialogOnClick,
                                })}
                            >
                                {children}
                            </Upload>
                        )}
                    />
                </div>
            );
        }

        const buttonActionText = value === undefined || multiple ? "Choose" : "Replace";
        const buttonFilesText = multiple ? "File(s)" : "File";

        return (
            // eslint-disable-next-line react/forbid-elements
            <Upload {...uploadProps}>
                <BTButton
                    disabled={disabled}
                    data-testid="upload"
                    icon={<BTIconUploadOutlined />}
                    onMouseDown={removeDirectoryPropFromInput}
                >
                    {buttonActionText} {buttonFilesText}
                </BTButton>
            </Upload>
        );
    }
);
