// TODO: Fix this. Only doing this for scaffolding
import moment from "moment";

import {
    AccountingIntegrationType,
    BTLoginTypes,
    JobContractType,
    JobStatusTypes,
} from "types/enum";

import { ListMetadata } from "utilities/list/list.types";

import {
    getHeaderInfoFromResponse,
    IHeaderInfoResponse,
} from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";

import { JobStatus } from "entity/job/Job.api.types";

import type { default as JobPickerMetadataJson } from "./JobPicker.metadata.api.json";

type JobPickerMetadatanData = typeof JobPickerMetadataJson;
type JobPickerMetadataJobData = JobPickerMetadatanData["jobs"][number];

export class JobPickerLoadResponse {
    constructor(data: any) {
        if (!data.jobs) {
            console.error(`Job Picker returned invalid job list: ${JSON.stringify(data)}`);
        }
        this.jobs = data.jobs.map((x: any) => new JobPickerJob(x));
        this.selectedJobId = data.selectedJobId;
        this.needsToRefreshFilter = data.needsToRefreshFilter;
        this.needToSetFilterToOpenAndClosed = data.needToSetFilterToOpenAndClosed;
        this.permissions = new JobPickerPermissions(data.permissions);
        this.ownerInfo = new JobPickerOwnerInfo(data.ownerInfo);
        this.listMetadata = new ListMetadata(data.listMetadata);
        if (data.headerInfo) {
            this.headerInfo = getHeaderInfoFromResponse(data.headerInfo);
        }
        this.availableAccounts = data.availableAccounts.map((x: any) => new AccountSwitcherItem(x));
        this.isConnectedToAccounting = data.isConnectedToAccounting;
        this.userId = data.userId;
        if (data.fullJobCount !== null) {
            this.fullJobCount = data.fullJobCount;
        }
        this.isListLimited = data.isListLimited;
    }

    jobs: JobPickerJob[];
    selectedJobId: number;
    needsToRefreshFilter: boolean;
    needToSetFilterToOpenAndClosed: boolean;
    permissions: JobPickerPermissions;
    ownerInfo: JobPickerOwnerInfo;
    listMetadata: ListMetadata;
    availableAccounts: AccountSwitcherItem[];
    headerInfo?: IHeaderInfoResponse;
    isConnectedToAccounting: boolean;
    userId: number;
    isListLimited: boolean;
    fullJobCount?: number;
}

export class JobPickerMetadataLoadResponse {
    constructor(data: JobPickerMetadatanData) {
        this.jobs = data.jobs.map((x) => new JobPickerJobMetadata(x));
    }
    jobs: JobPickerJobMetadata[];
}

export class JobPickerJobMetadata {
    constructor(data: JobPickerMetadataJobData) {
        this.jobId = data.jobId;
        this.hasTakeoff = data.hasTakeoff;
    }

    jobId: number;
    hasTakeoff: boolean | null;
}

export class JobPickerPermissions {
    constructor(data: any) {
        this.canAddJobs = data.canAddJobs;
        this.canViewJobDetails = data.canViewJobDetails;
        this.canSendMessage = data.canSendMessage;
        this.canViewOwnerSite = data.canViewOwnerSite;
    }

    canAddJobs: boolean;
    canViewJobDetails: boolean;
    canSendMessage: boolean;
    canViewOwnerSite: boolean;
}

export class JobPickerBuilder {
    constructor(data: any) {
        this.builderId = data.builderId;
        this.builderName = data.builderName;
        this.builderOwnerViewPerm = data.builderOwnerViewPerm;
    }

    builderName: string;
    builderId: number;
    builderOwnerViewPerm: boolean;
}

export class JobPickerOwnerInfo {
    constructor(data: any) {
        this.owners = data ? data.owners.map((x: any) => new JobPickerOwner(x)) : [];
    }

    owners: JobPickerOwner[];
}

export class JobPickerOwner {
    constructor(data: any) {
        this.displayName = data.displayName;
        this.name = data.name;
        this.lastName = data.lastName;
        this.firstName = data.firstName;
        this.isActive = data.isActive;
    }

    displayName: number;
    name: string;
    lastName: string;
    firstName: string;
    isActive: boolean;
}

export class LinkedLogin {
    constructor(data: any) {
        this.builderName = data.builderName;
        this.userFullName = data.userFullName;
        this.loginId = data.loginId;
        this.selected = data.selected;
    }

    builderName: string;
    userFullName: string;
    loginId: number;
    selected: boolean;
}

export class AccountSwitcherItem {
    constructor(data: any) {
        this.name = data.name;
        this.builderName = data.builderName;
        this.globalUserId = data.globalUserId;
        this.builderId = data.builderId;
        this.loginType = data.loginType;
        this.selected = data.selected;
        this.inSession = data.inSession;
    }
    builderId: number;
    name: string;
    builderName: string;
    globalUserId: number;
    loginType: BTLoginTypes;
    selected: boolean;
    inSession: boolean;
}

export class JobPickerJob {
    constructor(data: any) {
        this.jobId = data.jobId;
        this.builderId = data.builderId;
        this.jobName = data.jobName;
        this.jobInfo = new JobPickerJobInfo(data);
        this.actualStartDate = moment(new Date(data.actualStartDate));
        this.isCalendarOffline = data.isCalendarOffline;
        this.includeAllowances = data.includeAllowances;
        this.projectedClosingDate = moment(new Date(data.projectedClosingDate));
        this.projectedStartDate = moment(new Date(data.projectedStartDate));

        this.accountingEnabled = data.accountingEnabled;
        this.accountingType = data.accountingType ?? AccountingIntegrationType.None;
        this.accountingEnabledImageSrc = data.accountingEnabledImageSrc;
        this.accountingEnabledImageSrcHoverMsg = data.accountingEnabledImageSrcHoverMsg;
        this.customJobIconSrc = data.customJobIconSrc || undefined;
        this.hasEmail = data.hasEmail;
        this.hasTakeoff = data.hasTakeoff;
        this.canImpersonateOwner = data.canImpersonateOwner;
        this.dateOpened = moment(new Date(data.dateOpened));
        this.jobStatus = data.status;
        this.contractType = data.contractType;
    }

    jobId: number;
    builderId: number;
    jobName: string;
    jobInfo: JobPickerJobInfo;
    actualStartDate: moment.Moment;
    isCalendarOffline: boolean | null;
    includeAllowances: boolean;
    projectedStartDate: moment.Moment;
    projectedClosingDate: moment.Moment;
    dateOpened: moment.Moment;
    accountingEnabled: boolean | undefined;
    accountingEnabledImageSrc: string | null;
    accountingEnabledImageSrcHoverMsg: string | null;
    accountingType: AccountingIntegrationType;
    customJobIconSrc: string | undefined;
    hasEmail: boolean | null;
    hasTakeoff: boolean | null;
    canImpersonateOwner: boolean;
    jobStatus: JobStatus;
    contractType: JobContractType;
}

export class JobPickerJobInfo {
    constructor(data: any) {
        this.ownerName = data.ownerDisplayName;
        this.street = data.street;
        this.addressWithoutStreet = data.addressWithoutStreet;
        this.color = data.defaultColor;
        this.globalCountry = data.country;
        this.city = data.city;
        this.suburb = data.suburb;
        this.zip = data.zip;
        this.status = data.status;
    }

    ownerName: string;
    street: string;
    addressWithoutStreet: string;
    color: string;
    globalCountry?: string;
    city?: string;
    suburb?: string;
    zip?: string;
    status?: JobStatusTypes;
}

export interface IApiJobBuilderRequest {
    jobId: number;
    builderId: number;
    jobStatus?: JobStatus;
}

export enum JobPickerFilterTypes {
    Neighborhood = "1",
    ProjectManager = "2",
    Other = "3",
    Keyword = "4",
    Builder = "5",
    Templates = "6",
    LeadStatus = "7",
}

export interface IApiJobFilterRequest {
    keywordFilter: string;
    otherFilter: string | null;
    leadStatusFilter: string | null;
    jobGroupFilter?: string[];
    projectManagerFilter?: string[];
    selectedBuilderFilter: number;
}

export class JobPickerSetRequest {
    constructor(data: any) {
        this.selectedJobId = data.selectedJobId;
        this.selectedJobIds =
            data.selectedJobId === -1 ? null : data.selectedJobIds.map((s: any) => s);
        this.persistFilters = data.persistFilters;
        this.filtersData = data.filtersData;
        this.useJobSession = data.useJobSession;
    }

    selectedJobId: IApiJobBuilderRequest;
    selectedJobIds: IApiJobBuilderRequest[] | null;
    persistFilters: boolean;
    filtersData: IApiJobFilterRequest;
    useJobSession: boolean;
}

export class JobPickerSetResponse {
    constructor(data: any) {
        this.runningTotal = data.runningTotal;
        this.pricePerAreaUnit = data.pricePerAreaUnit;
        this.needsPageReload = data.needsPageReload;
        if (data.headerInfo) {
            this.headerInfo = getHeaderInfoFromResponse(data.headerInfo);
        }
        this.ownerInfo = data.ownerInfo
            ? new JobPickerOwnerInfo(data.ownerInfo)
            : new JobPickerOwnerInfo({ owners: [] });
    }

    runningTotal: string | null;
    pricePerAreaUnit: string | null;
    needsPageReload: boolean;
    headerInfo?: IHeaderInfoResponse;
    ownerInfo: JobPickerOwnerInfo;
}

export class PutOwnerInSessionResponse {
    constructor(data: any) {
        this.ownerPortalUrl = data;
    }
    ownerPortalUrl: string;
}

export class SetSortResponse {
    constructor(data: any) {
        if (data === null) {
            // Things seem to crash if resp is empty, but that's what we have here, so explicitly handling it.
        }
    }
}

export class SwitchLinkedLoginResponse {
    constructor(data: any) {
        this.switchBuilderFrozen = data.SwitchBuilderFrozen;
        this.isAccountClosed = data.isAccountClosed;
    }
    switchBuilderFrozen: boolean;
    isAccountClosed: boolean;
}

export class SwitchAccountResponse {
    constructor(data: any) {
        this.refreshUrl = data.refreshUrl ?? undefined;
        this.switchBuilderFrozen = data.switchBuilderFrozen ?? false;
        this.orgLinkIds = data.orgLinkIds;
    }
    refreshUrl?: string;
    switchBuilderFrozen: boolean;
    orgLinkIds: number[];
}
