import { Skeleton } from "antd";
import classNames from "classnames";

import { BTLoginTypes } from "types/enum";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

import PatternedBackgroundSrc from "images/UserActivationBackgroundPattern.svg";

import "./ValuePropositions.less";

import { UserActivationPageAdvertisements } from "./LegacyAdvertisements/UserActivationPageAdvertisements";
import { ValuePropositions as OwnerValueProps } from "./Owners/ValuePropositions";

export interface IUserActivationValuePropopsitionsProps {
    loginType: BTLoginTypes;
}

export const UserActivationPageValuePropositions: React.FC<
    IUserActivationValuePropopsitionsProps
> = ({ loginType }) => {
    const shouldShowOwnerValueProps =
        loginType === BTLoginTypes.OWNER || loginType === BTLoginTypes.CONTACT;
    const showLegacyAdvertisements = !shouldShowOwnerValueProps;

    let valueProps = <UserActivationPageAdvertisements loginType={loginType} />;
    let background = {};
    if (shouldShowOwnerValueProps) {
        valueProps = <OwnerValueProps />;
        background = { backgroundImage: `url("${PatternedBackgroundSrc}")` };
    }

    return (
        <div
            className={classNames("ValuePropositions", {
                ValuePropositions__Background: shouldShowOwnerValueProps,
                LegacyAdvertisement: showLegacyAdvertisements,
                LegacyAdvertisement__Background: showLegacyAdvertisements,
            })}
            style={{ ...background }}
        >
            <BTRow align="middle" style={{ maxWidth: "100%" }} responsiveMode="viewport">
                <BTCol>
                    <BTRow justify="center" responsiveMode="viewport">
                        <BTCol>{valueProps}</BTCol>
                    </BTRow>
                </BTCol>
            </BTRow>
        </div>
    );
};

export const UserActivationPageValuePropositionsSkeleton = () => {
    return (
        <div
            className="ValuePropositions ValuePropositions__Background"
            style={{ backgroundImage: `url("${PatternedBackgroundSrc}")` }}
        >
            <Skeleton active />
        </div>
    );
};
