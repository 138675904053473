import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Component } from "react";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { CommonConstants } from "types/constants";
import { MediaType, ViewingPermissionAction } from "types/enum";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTFormItem, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import { BTIconCheckCircleOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelectUser } from "commonComponents/btWrappers/BTSelectUser/BTSelectUser";
import {
    IMediaViewingPermissionsFormValues,
    MediaViewingPermissionsEntity,
    UsersAmount,
} from "commonComponents/entity/media/MediaViewingPermissionsInternal/MediaViewingPermissionsInternal.api.types";
import "commonComponents/entity/media/MediaViewingPermissionsInternal/MediaViewingPermissionsInternal.less";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import { MediaTypeNames } from "entity/media/common/mediaTypes";

interface IMediaViewingPermissionsProps {
    entity: MediaViewingPermissionsEntity;
    values: IMediaViewingPermissionsFormValues;
    mediaType: MediaType;
    viewingPermissionAction: ViewingPermissionAction;
    isFolder?: boolean;
    disabled?: boolean;
    documentCount?: number;
    areDropdownsHidden?: boolean;
    setFieldValue: (field: string, value: any) => void;
    handleChange: (e: CheckboxChangeEvent) => void;
    setFieldTouched: (field: string, isTouched?: boolean) => void;
    onShowDropdowns: () => void;
    showOwnersDropdown: boolean;
}

class MediaViewingPermissionsInternal extends Component<IMediaViewingPermissionsProps> {
    static defaultProps = {
        onShowDropdowns: () => {},
    };

    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof MediaViewingPermissionsInternal.contextType>;

    private handleShowOwnerChange = (e: CheckboxChangeEvent) => {
        const { setFieldValue, handleChange } = this.props;
        if (!e.target.checked) {
            setFieldValue("isFeatureMedia", false);
        }
        handleChange(e);
    };

    private handleShowSubsChange = (e: CheckboxChangeEvent) => {
        const { handleChange } = this.props;
        handleChange(e);
    };

    private getDropdownPlaceholderText = (mediaName: string) => {
        const { values, entity, documentCount } = this.props;

        let internalUsersAmount;
        if (values.internalUserIds.length === 0) {
            internalUsersAmount = UsersAmount.None;
        } else if (values.internalUserIds.length === entity.internalUsers?.length) {
            internalUsersAmount = UsersAmount.All;
        } else {
            internalUsersAmount = UsersAmount.Some;
        }

        let subsAmount;
        if (!entity.showSubs || values.subIds.length === 0) {
            subsAmount = UsersAmount.None;
        } else if (values.subIds.length === entity.subs?.length) {
            subsAmount = UsersAmount.All;
        } else {
            subsAmount = UsersAmount.Some;
        }

        const areInternalUsersSubsMatching = internalUsersAmount === subsAmount;

        const allText = " on this Job";
        const someText = " who have access to the parent folder";

        let internalUserText;
        if (internalUsersAmount === UsersAmount.None) {
            internalUserText = "";
        } else {
            internalUserText = (
                <>
                    All <b>Internal Users</b>
                </>
            );
            if (internalUsersAmount === UsersAmount.All) {
                internalUserText = (
                    <>
                        {internalUserText}
                        {!areInternalUsersSubsMatching && allText}
                    </>
                );
            } else {
                internalUserText = (
                    <>
                        {internalUserText}
                        {!areInternalUsersSubsMatching && someText}
                    </>
                );
            }
        }

        let subText;
        if (subsAmount === UsersAmount.None) {
            subText = "";
        } else {
            subText = (
                <>
                    {!areInternalUsersSubsMatching && (
                        <>{internalUsersAmount === UsersAmount.None ? "A" : "a"}ll </>
                    )}
                    <b>Subs / Vendors</b>
                </>
            );
            if (subsAmount === UsersAmount.All) {
                subText = (
                    <>
                        {subText}
                        {allText}
                    </>
                );
            } else {
                subText = (
                    <>
                        {subText}
                        {someText}
                    </>
                );
            }
        }

        const trailingText = (
            <>
                {" "}
                have access to{" "}
                <BTFormattedPlural
                    value={documentCount ?? 0}
                    zero="this"
                    one="this"
                    other="these"
                />{" "}
                {mediaName}.
            </>
        );

        let finalText;
        const emptyInternalUsersString = internalUserText.toString().length === 0;
        const emptySubsString = subText.toString().length === 0;
        if (emptyInternalUsersString && emptySubsString) {
            finalText = (
                <>
                    No <b>Internal Users</b> or <b>Subs / Vendors</b>
                    {trailingText}
                </>
            );
        } else if (emptyInternalUsersString || emptySubsString) {
            finalText = (
                <>
                    {internalUserText}
                    {subText}
                    {trailingText}
                </>
            );
        } else {
            finalText = (
                <>
                    {internalUserText} and {subText}
                    {trailingText}
                </>
            );
        }

        return finalText;
    };

    private handleInternalUsersChanged = (field: string, value: any) => {
        const { setFieldValue } = this.props;
        setFieldValue(field, value);
    };

    private handleSubsChanged = (field: string, value: any) => {
        const { setFieldValue } = this.props;

        setFieldValue(field, value);
    };

    private handleOwnerChanged = (field: string, value: any) => {
        this.props.setFieldValue(field, value);
    };

    render() {
        const {
            entity,
            values,
            mediaType,
            viewingPermissionAction,
            isFolder,
            disabled,
            areDropdownsHidden,
            documentCount,
            showOwnersDropdown,
            setFieldTouched,
            onShowDropdowns,
        } = this.props;

        let mediaName;
        if (mediaType === MediaType.Photo && isFolder) {
            mediaName = "folders";
        } else if (documentCount! > 1) {
            mediaName = MediaTypeNames.get(mediaType)!.plural.toLowerCase();
        } else {
            mediaName = MediaTypeNames.get(mediaType)!.singular.toLowerCase();
        }
        let usersDropdowns = <></>;
        const subsDisabledText = (
            <BTFormItemAutomatic<IMediaViewingPermissionsFormValues>
                id="showSubs"
                label={CommonConstants.Sub.plural}
            >
                {CommonConstants.Sub.plural} are disabled on this folder.
            </BTFormItemAutomatic>
        );

        const ownerDisabledText = (
            <BTFormItemAutomatic<IMediaViewingPermissionsFormValues>
                id="showOwner"
                label={CommonConstants.Owner.singular}
            >
                Owners are disabled on this folder.
            </BTFormItemAutomatic>
        );

        if (!isFolder && mediaType !== MediaType.Photo) {
            if (areDropdownsHidden) {
                usersDropdowns = (
                    <BTRow align="middle">
                        <BTCol xs={15}>
                            <BTFormItem label="Internal Users and Subs / Vendors">
                                <div className="flex">
                                    <BTIconCheckCircleOutlined
                                        size="large"
                                        className="margin-right-xs MediaViewingPermissions-Success"
                                    />
                                    <div>{this.getDropdownPlaceholderText(mediaName)}</div>
                                </div>
                            </BTFormItem>
                        </BTCol>
                        <BTCol xs={9} className="flex justify-content-end">
                            <BTButton
                                data-testid="editUsersSubs"
                                type="link"
                                disabled={disabled}
                                onClick={onShowDropdowns}
                            >
                                Edit
                            </BTButton>
                        </BTCol>
                    </BTRow>
                );
            } else {
                usersDropdowns = (
                    <>
                        <BTFormItemAutomatic<IMediaViewingPermissionsFormValues> id="internalUserIds">
                            <BTSelectUser<IMediaViewingPermissionsFormValues>
                                id="internalUserIds"
                                data-testid="internalUserIds"
                                value={values.internalUserIds}
                                multiple={true}
                                disabled={disabled}
                                onChange={this.handleInternalUsersChanged}
                                onBlur={setFieldTouched}
                                treeData={entity.internalUsers}
                            />
                        </BTFormItemAutomatic>

                        {entity.showSubs ? (
                            <BTFormItemAutomatic<IMediaViewingPermissionsFormValues> id="subIds">
                                <BTSelectUser<IMediaViewingPermissionsFormValues>
                                    id="subIds"
                                    data-testid="subIds"
                                    value={values.subIds}
                                    multiple={true}
                                    disabled={disabled}
                                    onChange={this.handleSubsChanged}
                                    onBlur={setFieldTouched}
                                    treeData={entity.subs}
                                />
                            </BTFormItemAutomatic>
                        ) : (
                            subsDisabledText
                        )}

                        {showOwnersDropdown && (
                            <>
                                {entity.showOwner ? (
                                    <BTFormItemAutomatic<IMediaViewingPermissionsFormValues> id="ownerIds">
                                        <BTSelectUser<IMediaViewingPermissionsFormValues>
                                            id="ownerIds"
                                            data-testid="ownerIds"
                                            value={values.ownerIds}
                                            multiple={true}
                                            disabled={disabled}
                                            onChange={this.handleOwnerChanged}
                                            onBlur={setFieldTouched}
                                            treeData={entity.owners}
                                        />
                                    </BTFormItemAutomatic>
                                ) : (
                                    ownerDisabledText
                                )}
                            </>
                        )}
                    </>
                );
            }
        }

        let showSubs = <></>;
        if (mediaType === MediaType.Photo || isFolder) {
            if (entity.showSubs) {
                let subPermissionString = (
                    <>
                        Allow {CommonConstants.Sub.plural} to view selected {mediaName}.
                    </>
                );
                if (viewingPermissionAction === ViewingPermissionAction.Remove) {
                    subPermissionString = (
                        <>
                            Prevent {CommonConstants.Sub.plural} from viewing selected {mediaName}.
                        </>
                    );
                }
                showSubs = (
                    <BTFormItemAutomatic<IMediaViewingPermissionsFormValues>
                        id="showSubs"
                        label={CommonConstants.Sub.plural}
                    >
                        <BTCheckbox<IMediaViewingPermissionsFormValues>
                            id="showSubs"
                            data-testid="showSubs"
                            disabled={disabled}
                            checked={values.showSubs}
                            onChange={this.handleShowSubsChange}
                        >
                            {subPermissionString}
                        </BTCheckbox>
                    </BTFormItemAutomatic>
                );
            } else {
                showSubs = subsDisabledText;
            }
        }

        let showOwner = <></>;

        if (!showOwnersDropdown || mediaType === MediaType.Photo) {
            if (entity.showOwner) {
                const ownerPermissionString =
                    viewingPermissionAction === ViewingPermissionAction.Remove ? (
                        <>Prevent Owners from viewing selected {mediaName}.</>
                    ) : (
                        <>Allow Owners to view selected {mediaName}.</>
                    );
                showOwner = (
                    <BTFormItemAutomatic<IMediaViewingPermissionsFormValues>
                        id="showOwner"
                        label="Owners"
                    >
                        <BTCheckbox<IMediaViewingPermissionsFormValues>
                            id="showOwner"
                            data-testid="showOwner"
                            disabled={disabled}
                            checked={values.showOwner}
                            onChange={this.handleShowOwnerChange}
                        >
                            {ownerPermissionString}
                        </BTCheckbox>
                    </BTFormItemAutomatic>
                );
            } else {
                showOwner = (
                    <BTFormItemAutomatic<IMediaViewingPermissionsFormValues>
                        id="showOwner"
                        label="Owners"
                    >
                        Owners are disabled on this folder.
                    </BTFormItemAutomatic>
                );
            }
        }

        let pageSectionTitle =
            mediaType === MediaType.Document
                ? ViewingPermissionAction[viewingPermissionAction] + " viewing access"
                : "Update viewing access";
        if (documentCount) {
            pageSectionTitle = `${pageSectionTitle} to ${documentCount} ${
                documentCount === 1
                    ? MediaTypeNames.get(this.props.mediaType)!.singular
                    : MediaTypeNames.get(this.props.mediaType)!.plural
            }`;
        }
        return (
            <PageSection title={showOwnersDropdown ? "Viewing permissions" : pageSectionTitle}>
                {usersDropdowns}
                {showSubs}
                {showOwner}
            </PageSection>
        );
    }
}

export default MediaViewingPermissionsInternal;
