import { Collapse, List, Typography } from "antd";
import { isEqual, once } from "lodash-es";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";

import { BTLocalStorage, ILocalStorage, IProcessStatusStorage } from "types/btStorage";
import { ClientObservableBackgroundJobStatus } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { reportError } from "utilities/errorHelpers";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTCollapse } from "commonComponents/btWrappers/BTCollapse/BTCollapse";
import {
    BTIconCaretDownOutlined,
    BTIconCheckCircleSuccess,
    BTIconCloseCircleError,
    BTIconCloseOutlined,
    BTIconExclamationCircleOutlinedWarning,
    BTIconLoadingOutlined,
} from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { ClientObservableBackgroundJobStatusPopupHandler } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.api.handler";
import {
    ClientObservableBackgroundJobDetails,
    ClientObservableBackgroundJobStatuses,
    ProcessStatusGroupType,
} from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.types";
import { GenericItem } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/Processes/GenericItem";
import { JobImportItem } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/Processes/JobImportItem";

import "./ClientObservableBackgroundJobStatusPopup.less";

const localStorageKey: keyof ILocalStorage = "bt-object-clientObservableProcessStatusStorage";

/**
 * Modify the process status group state that is in localStorage
 * @param stateModifier - Function that will modify the passed process status state object.
 * Any changes to the passed object will be applied to localStorage
 */
const modifyProcessStatusStorageState = (
    stateModifier: (currentState: IProcessStatusStorage) => IProcessStatusStorage
) => {
    const oldState = BTLocalStorage.get(localStorageKey);
    const newState = stateModifier(oldState);

    BTLocalStorage.set(localStorageKey, newState);
};

export const openBackgroundProcessStatusPopup = (groupToOpen: ProcessStatusGroupType) => {
    try {
        modifyProcessStatusStorageState((currentState) => {
            // update localstorage so process group is opened when navigating to other tabs
            return {
                ...currentState,
                [groupToOpen]: {
                    showProcessGroup: true,
                    isPanelExpanded: true,
                },
            };
        });
    } catch (e) {
        reportError(e, {
            internalNotes:
                "This error was most likely caused by exceeding the localStorage event quota",
        });
    }
};

const closeProcessStatusPopup = (groupToClose: ProcessStatusGroupType) => {
    try {
        modifyProcessStatusStorageState((currentState) => {
            // update localstorage so process group is closed when navigating to other tabs
            return {
                ...currentState,
                [groupToClose]: {
                    showProcessGroup: false,
                    isPanelExpanded: false,
                },
            };
        });
    } catch (e) {
        reportError(e, {
            internalNotes:
                "This error was most likely caused by exceeding the localStorage event quota",
        });
    }
};

/**
 * Handles changing the panels from collapsed to expanded. It is important to note that this handler does not directly
 * modify the process group state for this component. Instead, it changes the state in localStorage and the localStorage
 * event handler will manage the setState call for us.
 */
const handlePanelCollapseChange = () => {
    modifyProcessStatusStorageState((currentState) => {
        const collapseNonActivePanels: IProcessStatusStorage = {};
        for (const [key, value] of Object.entries(currentState)) {
            collapseNonActivePanels[key] = {
                showProcessGroup: value.showProcessGroup,
                isPanelExpanded: !value.isPanelExpanded,
            };
        }

        return collapseNonActivePanels;
    });
};

export interface IClientObservableBackgroundJobStatusPopupProps extends RouteComponentProps {
    handler?: ClientObservableBackgroundJobStatusPopupHandler;
}

const getCollapsePanelHeader = (
    isProcessing: boolean,
    isExpanded: boolean,
    processingTitle: JSX.Element | string,
    finishedProcessingTitle: JSX.Element | string,
    onClose: () => Promise<void>
) => {
    return (
        <BTRow
            className="collapseHeader"
            justify="space-between"
            align="middle"
            data-testid={isExpanded ? "headerExpanded" : "headerCollapsed"}
        >
            <BTCol>
                <BTTitle level={4} className="ProcessHeaderTitle">
                    {isProcessing ? processingTitle : finishedProcessingTitle}
                </BTTitle>
            </BTCol>
            {!isProcessing && (
                <BTCol>
                    <BTButton
                        type="link"
                        isolated
                        icon={<BTIconCloseOutlined data-testid="close" title="Close" isOnDark />}
                        data-testid="closeProcessStatusPopup"
                        onClick={async (
                            e: React.FormEvent<HTMLFormElement> | React.MouseEvent<any, MouseEvent>
                        ) => {
                            e.stopPropagation();
                            await onClose();
                        }}
                    />
                </BTCol>
            )}
        </BTRow>
    );
};

const getLoadingDiv = () => {
    return (
        <div data-testid="loadingContainer" className="ProcessGroupLoadingContainer">
            <BTLoading displayMode="absolute" />
        </div>
    );
};

function IndividualProcessDetails(
    process: ClientObservableBackgroundJobDetails,
    onRefreshList: () => void,
    handler?: ClientObservableBackgroundJobStatusPopupHandler
) {
    switch (process.processType) {
        case ProcessStatusGroupType.JobImportNew:
            return (
                <JobImportItem process={process} handler={handler} onRefreshList={onRefreshList} />
            );
        default:
            return <GenericItem process={process} />;
    }
}

const getStatusPanelContent = (
    backgroundProcesses: ClientObservableBackgroundJobDetails[],
    onRefreshList: () => void,
    handler?: ClientObservableBackgroundJobStatusPopupHandler
) => {
    if (!backgroundProcesses) {
        return getLoadingDiv();
    }
    return (
        <List
            className="ProcessListGroup"
            itemLayout="horizontal"
            dataSource={backgroundProcesses}
            renderItem={(process) => {
                return (
                    <List.Item className="padding-all-xs" key={process.processID}>
                        <BTRow
                            align="middle"
                            gutter={8}
                            justify="space-between"
                            className="ProcessItemRow"
                        >
                            <BTCol flex="1" className="TruncateText">
                                {IndividualProcessDetails(process, onRefreshList, handler)}
                            </BTCol>
                        </BTRow>
                        <BTCol>{statusDict[process.status]}</BTCol>
                    </List.Item>
                );
            }}
        />
    );
};

const getStatusPanel = (
    isProcessing: boolean,
    isExpanded: boolean,
    processingTitle: JSX.Element | string,
    finishedProcessingTitle: JSX.Element | string,
    processGroupType: string,
    onClose: () => Promise<void>,
    backgroundProcesses: ClientObservableBackgroundJobDetails[],
    onRefreshList: () => void,
    handler?: ClientObservableBackgroundJobStatusPopupHandler
) => {
    return (
        <Collapse.Panel
            header={getCollapsePanelHeader(
                isProcessing,
                isExpanded,
                processingTitle,
                finishedProcessingTitle,
                async () => {
                    await onClose();
                }
            )}
            key={processGroupType}
            className="ProcessPanel"
        >
            {getStatusPanelContent(backgroundProcesses, onRefreshList, handler)}
        </Collapse.Panel>
    );
};
const defaultHandler = new ClientObservableBackgroundJobStatusPopupHandler();
const ClientObservableBackgroundJobStatusPopupInternal: React.FunctionComponent<
    IClientObservableBackgroundJobStatusPopupProps
> = ({ handler = defaultHandler }) => {
    const [processGroupStates, setProcessGroupStates] = useState<IProcessStatusStorage>({});
    const [backgroundProcessStatuses, setBackgroundProcessStatuses] =
        useState<ClientObservableBackgroundJobStatuses>();
    const [isInit, setIsInit] = useState<boolean>(false);

    const activePanels = Object.keys(processGroupStates).filter((key) => {
        const processGroup = processGroupStates[key];
        return processGroup.showProcessGroup;
    });
    const context = useContext(AppDefaultInfoContext);
    const intervalHandlerRef = useRef<number | null>(null);

    /**
     * Gets polling rate/frequency from Application Defaults
     */
    const getPollingRateInMilliseconds = useMemo(() => {
        let pollingRateInSeconds: number | undefined = context?.importUIRefreshFrequency;
        if (!pollingRateInSeconds) {
            reportError(new Error("Missing UI refresh frequency"), {
                internalNotes:
                    "importUIRefreshFrequency was not found in app defaults -> importUIRefreshFrequency",
            });
            pollingRateInSeconds = 10;
        }
        return pollingRateInSeconds * 1000;
    }, [context?.importUIRefreshFrequency]);

    const showAPIErrorMessageOnce = once(showAPIErrorMessage);

    const clearPollingFunction = useCallback(() => {
        if (intervalHandlerRef.current) {
            clearInterval(intervalHandlerRef.current);
            intervalHandlerRef.current = null;
        }
    }, []);

    const pollBackgroundProcessStatuses = useCallback(async () => {
        try {
            const newProcessData = await handler.getClientObservableBackgroundJobStatuses();

            setBackgroundProcessStatuses(newProcessData);

            // stop polling if all processes are done
            const jobsInProgress = newProcessData.backgroundJobStatuses.some(
                (b) =>
                    b.status === ClientObservableBackgroundJobStatus.NotStarted ||
                    b.status === ClientObservableBackgroundJobStatus.Processing
            );
            if (!jobsInProgress && intervalHandlerRef.current) {
                clearPollingFunction();
            }
            return jobsInProgress;
        } catch (e) {
            showAPIErrorMessageOnce(e);
            return false;
        }
    }, [handler, clearPollingFunction, showAPIErrorMessageOnce]);

    const registerPollingFunction = useCallback(() => {
        if (!intervalHandlerRef.current) {
            intervalHandlerRef.current = window.setInterval(
                pollBackgroundProcessStatuses,
                getPollingRateInMilliseconds
            );
        }
    }, [pollBackgroundProcessStatuses, getPollingRateInMilliseconds]);

    const updateBackgroundProcessStatuses = useCallback(async () => {
        try {
            const storageObject = BTLocalStorage.get(localStorageKey);

            const showProcessGroup = Object.keys(storageObject).some(
                (key) => storageObject[key].showProcessGroup
            );

            // Check if the process group states have been modified
            if (!isEqual(processGroupStates, storageObject)) {
                setProcessGroupStates(storageObject);
            }

            if (showProcessGroup) {
                const shouldRegisterPollingFunc = await pollBackgroundProcessStatuses();

                if (shouldRegisterPollingFunc && !intervalHandlerRef.current) {
                    registerPollingFunction();
                }
            } else if (intervalHandlerRef.current) {
                clearPollingFunction();
            }
        } catch (e) {
            reportError(e);
        }
    }, [
        processGroupStates,
        pollBackgroundProcessStatuses,
        registerPollingFunction,
        clearPollingFunction,
    ]);

    const handleStorageEvent = useCallback(
        async (e?: StorageEvent) => {
            if (!e || e.key !== localStorageKey) {
                return;
            }
            await updateBackgroundProcessStatuses();
        },
        [updateBackgroundProcessStatuses]
    );

    useEffect(() => {
        async function init() {
            await updateBackgroundProcessStatuses();
        }

        if (!isInit) {
            void init();
            setIsInit(true);
        }
    }, [handleStorageEvent, isInit, updateBackgroundProcessStatuses]);

    useEffect(() => {
        window.addEventListener("storage", handleStorageEvent);
        void handleStorageEvent();
        return () => {
            window.removeEventListener("storage", handleStorageEvent);
        };
    }, [handleStorageEvent]);

    return (
        <>
            {activePanels.map((p) => {
                const processStatuses = backgroundProcessStatuses?.backgroundJobStatuses.filter(
                    (s) => s.processType.toString() === p
                );
                if (!processStatuses || processStatuses.length === 0) {
                    return null;
                }
                const isProcessing = processStatuses.some(
                    (s) =>
                        s.status === ClientObservableBackgroundJobStatus.NotStarted ||
                        s.status === ClientObservableBackgroundJobStatus.Processing
                );
                const isExpanded = processGroupStates[p].isPanelExpanded;
                return (
                    <BTCollapse
                        data-testid="backgroundProcessStatusPopup"
                        className="ProcessStatusPopup"
                        activeKey={isExpanded ? p : undefined}
                        key={p}
                        onChange={handlePanelCollapseChange}
                        expandIcon={(props) => (
                            <BTIconCaretDownOutlined
                                data-testid="collapse"
                                rotate={props.isActive ? 0 : -90}
                            />
                        )}
                    >
                        {getStatusPanel(
                            isProcessing,
                            isExpanded,
                            generateProcessingTitle(p, processStatuses),
                            generateFinishedProcessingTitle(p, processStatuses),
                            p,
                            async () => {
                                await handler.clearClientObservableBackgroundJobStatuses(
                                    getProcessType(p)!
                                );
                                closeProcessStatusPopup(getProcessType(p)!);
                            },
                            processStatuses,
                            updateBackgroundProcessStatuses,
                            handler
                        )}
                    </BTCollapse>
                );
            })}
        </>
    );
};

const generateProcessingTitle = (
    processType: string,
    processes: ClientObservableBackgroundJobDetails[]
) => {
    const completedCount = processes.filter(
        (p) => p.status === ClientObservableBackgroundJobStatus.Success
    ).length;
    const total = processes.length;

    switch (processType) {
        case ProcessStatusGroupType.CostItemUpdate.toString():
            return (
                <>
                    Updating cost items ({completedCount}/{total})
                </>
            );
        case ProcessStatusGroupType.JobImportNew.toString():
            return (
                <>
                    Importing templates ({completedCount}/{total})
                </>
            );
        case ProcessStatusGroupType.OrgToOrgCopy.toString():
            return (
                <>
                    Copying templates to other organizations ({completedCount}/{total})
                </>
            );
        default:
            return (
                <>
                    ({completedCount}/{total})
                </>
            );
    }
};

const generateFinishedProcessingTitle = (
    processType: string,
    processes: ClientObservableBackgroundJobDetails[]
) => {
    const completedCount = processes.filter(
        (p) => p.status === ClientObservableBackgroundJobStatus.Success
    ).length;
    const total = processes.length;

    switch (processType) {
        case ProcessStatusGroupType.CostItemUpdate.toString():
            return (
                <>
                    ({completedCount}/{total}) Cost Items successfully updated
                </>
            );
        case ProcessStatusGroupType.JobImportNew.toString():
            return (
                <>
                    {completedCount}/{total} Templates successfully imported
                </>
            );
        case ProcessStatusGroupType.OrgToOrgCopy.toString():
            return (
                <>
                    {completedCount}/{total} Templates successfully copied
                </>
            );
        default:
            return (
                <>
                    ({completedCount}/{total})
                </>
            );
    }
};

const getProcessType = (k: string) => {
    switch (k) {
        case ProcessStatusGroupType.CostItemUpdate.toString():
            return ProcessStatusGroupType.CostItemUpdate;
        case ProcessStatusGroupType.JobImportNew.toString():
            return ProcessStatusGroupType.JobImportNew;
        case ProcessStatusGroupType.OrgToOrgCopy.toString():
            return ProcessStatusGroupType.OrgToOrgCopy;
        default:
            reportError(new Error("Invalid process type"));
            return;
    }
};

const statusDict = {
    [ClientObservableBackgroundJobStatus.NotStarted]: (
        <Typography.Text type="secondary">Waiting…</Typography.Text>
    ),
    [ClientObservableBackgroundJobStatus.Processing]: (
        <BTPopover content="Processing">
            <BTIconLoadingOutlined data-testid="processing" size="large" />
        </BTPopover>
    ),
    [ClientObservableBackgroundJobStatus.Success]: (
        <BTPopover content="Success">
            <BTIconCheckCircleSuccess data-testid="processSuccess" size="large" />
        </BTPopover>
    ),
    [ClientObservableBackgroundJobStatus.Failed]: (
        <BTPopover content="Process Failed">
            <BTIconCloseCircleError data-testid="processFailed" size="large" />
        </BTPopover>
    ),
    [ClientObservableBackgroundJobStatus.Deleted]: (
        <BTPopover content="Process Stopped">
            <BTIconCloseCircleError data-testid="processDeleted" size="large" />
        </BTPopover>
    ),
    [ClientObservableBackgroundJobStatus.ActionRequired]: (
        <BTPopover content="Action Required">
            <BTIconExclamationCircleOutlinedWarning
                data-testid="processActionRequired"
                size="large"
            />
        </BTPopover>
    ),
};

export const ClientObservableBackgroundJobStatusPopup = withErrorBoundary(
    ClientObservableBackgroundJobStatusPopupInternal
)("Could not load Background Process Status Popup");
export default ClientObservableBackgroundJobStatusPopup;
