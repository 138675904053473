import { Component } from "react";

import { GetReadOnlyEmptyStateCreateMessage } from "utilities/emptyState/emptyStateUtility";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconMessages } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import BLALink from "commonComponents/entity/emptyState/common/BLALink";
import {
    EmptyStateDataType,
    emptyStateIconSize,
    IEmptyStateEntityProps,
} from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import { EmptyState, getEmptyStateDataType } from "commonComponents/entity/emptyState/EmptyState";
import { FilteredEmptyState } from "commonComponents/entity/emptyState/FilteredEmptyState";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";

interface IMessageEmptyStateProps extends IEmptyStateEntityProps {}

export class MessageEmptyState extends Component<IMessageEmptyStateProps> {
    private getEmptyStateContent = () => {
        const { onCallToActionClick, isReadonly, helpLink } = this.props;

        if (isReadonly) {
            return GetReadOnlyEmptyStateCreateMessage("Messages");
        }

        return (
            <>
                <BTRow>
                    <BTCol>{getMessageEmptyStateDescription()}</BTCol>
                </BTRow>
                <BTRow gutter={8} className="margin-top-lg">
                    {helpLink && (
                        <ShowOnPortal
                            builder
                            render={() => (
                                <BTCol>
                                    <BLALink href={helpLink} />
                                </BTCol>
                            )}
                        />
                    )}
                    <BTCol className="text-center">
                        <BTButton
                            data-testid="MessageCreate"
                            type="new"
                            onClick={onCallToActionClick}
                        >
                            Send a Message
                        </BTButton>
                    </BTCol>
                </BTRow>
            </>
        );
    };

    render() {
        const dataType = getEmptyStateDataType(this.props);
        if (dataType === null) {
            return null;
        }

        if (dataType === EmptyStateDataType.FilteredState) {
            return <FilteredEmptyState entityTitlePlural="Messages" />;
        }

        return (
            <EmptyState
                title={getMessageEmptyStateTitle()}
                image={
                    <BTIconMessages
                        size={emptyStateIconSize}
                        className="EmptyStateIcon"
                        data-testid="EmptyStateIcon"
                    />
                }
            >
                {this.getEmptyStateContent()}
            </EmptyState>
        );
    }
}

export function getMessageEmptyStateTitle() {
    return "Stay in touch with messages";
}

export function getMessageEmptyStateDescription() {
    return "Monitor communication for each job and store emails in one place.";
}
