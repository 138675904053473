import { Divider } from "antd";
import queryString from "query-string";
import { Component } from "react";
import { match, RouteComponentProps, useHistory, useRouteMatch } from "react-router";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { getBuildertrendAddress } from "commonComponents/entity/address/Address/Address.api.types";
import AddressDisplay from "commonComponents/entity/address/AddressDisplay";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";

import {
    FrozenBuilderHandler,
    IFrozenBuilderHandler,
} from "entity/FrozenBuilder/FrozenBuilder.api.handler";
import { FrozenBuilderEntity } from "entity/FrozenBuilder/FrozenBuilder.api.types";

import "./FrozenBuilder.less";

const SubscriptionManager = lazyLoadWithRetry(
    () => import("entity/SubscriptionManager/SubscriptionManager")
);

interface IFrozenBuilderProps extends RouteComponentProps {
    isAccountClosed: boolean;
    overrideUserId?: number;
    builderName?: string;
    isAdmin?: boolean;
    encryptedBuilderId?: string;
    handler?: IFrozenBuilderHandler;
    contactSupport?: boolean;
}

interface IFrozenBuilderState {
    entity?: FrozenBuilderEntity;
    showSubscriptionManagerModal: boolean;
}

const buildertrendPhoneNumber = "888-415-7159";
const buildertrendEmail = "support@buildertrend.com";

class FrozenBuilderInternal extends Component<IFrozenBuilderProps, IFrozenBuilderState> {
    static defaultProps = {
        handler: new FrozenBuilderHandler(),
    };

    state: IFrozenBuilderState = {
        showSubscriptionManagerModal: false,
    };

    componentDidMount = async () => {
        try {
            const { overrideUserId } = this.props;
            const rewinRedirect = routes.signupForm.getExternalLink(
                "Core",
                "false",
                "0",
                "0",
                this.props.encryptedBuilderId!
            );
            let apiFrozenBuilderResponse: FrozenBuilderEntity;
            if (!this.props.isAccountClosed) {
                apiFrozenBuilderResponse = await this.props.handler!.get(overrideUserId);
            } else {
                apiFrozenBuilderResponse = {
                    builderName: this.props.builderName!,
                    isAdmin: this.props.isAdmin!,
                    updateAccountUrl: rewinRedirect,
                    contactPhoneNumber: buildertrendPhoneNumber,
                    contactEmail: buildertrendEmail,
                    contactAddress: getBuildertrendAddress(),
                } as FrozenBuilderEntity;
            }

            this.setState({
                entity: apiFrozenBuilderResponse,
            });
        } catch (e) {
            this.setState(() => {
                throw e;
            });
        }
    };

    private adminActions = () => {
        if (this.props.isAccountClosed) {
            window.location.assign(this.state.entity!.updateAccountUrl);
        } else {
            this.toggleSubscriptionManagerModal();
        }
    };

    private toggleSubscriptionManagerModal = () => {
        this.setState((prevState) => ({
            showSubscriptionManagerModal: !prevState.showSubscriptionManagerModal,
        }));
    };

    private getMessage = () => {
        const { isAccountClosed, contactSupport } = this.props;
        const { isAdmin } = this.state.entity!;
        let message = "";

        if (isAccountClosed) {
            if (contactSupport) {
                message = `Your account is not currently active. Please call the number below to speak with a representative about reactivating your account.`;
            } else if (isAdmin) {
                message = `Your account is not currently active. Please click the link below to update your account information. 
                Once your account information is up to date, you will regain access to your account.`;
            } else {
                message =
                    "Your account is not currently active. Please contact your account administrator to reactivate your account.";
            }
        } else {
            if (isAdmin) {
                message = `Your payment method has failed. Your account is currently frozen due to nonpayment. 
                    Please click the link below to update your payment method.`;
            } else {
                message = `Your account is currently frozen due to nonpayment.
                    Please inform your account administrators that a valid form of payment is needed.
                    Thank You.`;
            }
        }

        return <BTRow className="margin-bottom-lg">{message}</BTRow>;
    };

    render() {
        if (!this.state || !this.state.entity) {
            return <BTLoading />;
        }

        const modalConfig: IModalConfiguration = {
            parentRoute: this.props.match.url,
            beforeClose: this.toggleSubscriptionManagerModal,
        };
        const { isAccountClosed, contactSupport } = this.props;
        const { showSubscriptionManagerModal, entity } = this.state;
        const builderClosedForMoreThanAYear = isAccountClosed && contactSupport;

        return (
            <div className="FrozenBuilder">
                <BTRow justify="center">
                    <BTLogo className="NewBuildertrendLogoHeader" />
                </BTRow>
                <BTRow justify="center">
                    <BTCol span={24}>
                        <div className="Header">
                            {isAccountClosed ? "Account Closed" : "Account Currently Frozen"}
                        </div>
                    </BTCol>
                </BTRow>

                <div className="content margin-top-sm">
                    {!builderClosedForMoreThanAYear && (
                        <BTTitle level={1} bold className="BuilderName">
                            Dear {entity.builderName}
                        </BTTitle>
                    )}
                    {this.getMessage()}
                    {entity.isAdmin && !builderClosedForMoreThanAYear && (
                        <BTButton
                            id="update"
                            data-testid="update"
                            type="primary"
                            onClick={this.adminActions}
                            className="margin-bottom-lg"
                        >
                            {isAccountClosed
                                ? "Update Account Information"
                                : "Update Payment Method"}
                        </BTButton>
                    )}
                    {!builderClosedForMoreThanAYear && (
                        <BTRow className="margin-bottom-lg">
                            If you have any questions, please call the number below, and a
                            Buildertrend representative would be happy to speak with you about
                            reactivating your account.
                        </BTRow>
                    )}
                    <BTRow className="margin-bottom-lg" gutter={[32, 0]}>
                        <BTCol>
                            <BTTitle level={3} bold className="ContactPhoneNumber">
                                Toll free:
                            </BTTitle>
                        </BTCol>
                        <BTCol>{entity.contactPhoneNumber}</BTCol>
                    </BTRow>
                    <BTRow className="margin-bottom-lg">Thank you,</BTRow>
                    <BTRow justify="start" gutter={[32, 0]}>
                        <BTCol>
                            <BTRow>Buildertrend Solutions, Inc.</BTRow>
                            <BTRow>
                                <a
                                    href={`mailto:${entity.contactEmail}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {entity.contactEmail}
                                </a>
                            </BTRow>
                            <BTRow>{entity.contactPhoneNumber}</BTRow>
                        </BTCol>
                        <BTCol>
                            <AddressDisplay address={entity.contactAddress} />
                        </BTCol>
                    </BTRow>
                </div>
                <Divider />
                <BTRow justify="center" align="middle" className="Footer">
                    <a
                        href="https://www.buildertrend.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="PoweredBy">powered by </span>
                        <BTLogo size="xs" />
                    </a>
                </BTRow>
                {showSubscriptionManagerModal && (
                    <BTSuspense>
                        <SubscriptionManager
                            userId={this.props.overrideUserId}
                            fromSettings={false}
                            fromFrozenBuilder={true}
                            modalConfig={modalConfig}
                            history={this.props.history}
                            location={this.props.location}
                            match={
                                this.props.match as match<{
                                    fromSettings: string;
                                    fromFrozenBuilder: string;
                                }>
                            }
                        />
                    </BTSuspense>
                )}
            </div>
        );
    }
}

export const FrozenBuilder = withErrorBoundary(FrozenBuilderInternal)(
    "Could not load Frozen Builder"
);

export const FrozenBuilderWrapper = () => {
    const state = getApplicationState();
    const history = useHistory();
    const location = history.location;
    const match = useRouteMatch();

    return <FrozenBuilder {...state} history={history} location={location} match={match} />;
};

export default FrozenBuilderWrapper;

function getApplicationState() {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(window.location.search);
    const isAccountClosed = qsValues.isClosed?.toLocaleLowerCase() === "true";
    const isAdmin = qsValues.isAdmin?.toLocaleLowerCase() === "true";
    const builderName = qsValues.builderName;
    const encryptedBuilderId = qsValues.encryptedBuilderId;
    const overrideUserId = parseInt(qsValues.overrideSessionUserLoginID) || undefined;
    const contactSupport = qsValues.mustContactSupport?.toLocaleLowerCase() === "true";
    return {
        isAccountClosed,
        isAdmin,
        builderName,
        encryptedBuilderId,
        overrideUserId,
        contactSupport,
    };
}
