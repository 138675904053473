import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";

import {
    stickyHeaderHeightActions,
    stickyHeaderLevelActions,
} from "commonComponents/btWrappers/BTListLayout/BTListLayoutHeader";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";

import "./ListActionBar.less";

interface IListActionBarProps {
    className?: string;
    sticky?: Sticky;
}

export const StickyFooterClassname = "StickyFooter";

export type Sticky = "top" | "bottom";

export const ListActionBar: React.FunctionComponent<IListActionBarProps> = (props) => {
    const { className, children, sticky } = props;

    const stickyHeaderHeight = sticky === "bottom" ? 0 : stickyHeaderHeightActions;
    const top = useStickyHeight(stickyHeaderHeight, "top", stickyHeaderLevelActions);

    const combinedClasses = classNames("ListActionBar", className, sticky, "StickyLayoutHeader");
    const content = (
        <div
            className={combinedClasses}
            style={sticky !== "bottom" ? { height: stickyHeaderHeight, top } : undefined}
            data-testid={props["data-testid"] ?? ""}
        >
            {children}
        </div>
    );
    return (
        <>
            {sticky === "bottom" && <StickyFooter>{content}</StickyFooter>}
            {sticky !== "bottom" && content}
        </>
    );
};

const StickyFooter: React.FC<{}> = (props) => {
    const ref = useRef<HTMLDivElement>(null);
    const lock = useRef<boolean>(false);
    useEffect(() => {
        setHeight(ref.current?.offsetHeight ?? 0);
    }, [ref?.current?.offsetHeight]);

    const [height, setHeight] = useState(0);
    const [footerLeftRight, setFooterLeftRight] = useState([0, 0]);

    const setDimensions = useCallback(() => {
        // Sometimes scroll and resize events fire simultaneously - lock to only fire once
        if (lock.current) return;
        lock.current = true;

        const rect = ref?.current?.parentElement?.getBoundingClientRect();
        if (rect) {
            // document.body.offsetWidth instead of window.innerWidth - accounting for y-scrollbar
            const right = Math.floor(document.body.offsetWidth - rect.left - rect.width);
            setFooterLeftRight([Math.ceil(rect.left), right]);
        }
        lock.current = false;
    }, []);

    const [, setHeaderParentRef] = useResizeObserver(setDimensions);

    const bottom = useStickyHeight(height, "bottom", stickyHeaderLevelActions);

    useEffect(() => {
        window.addEventListener("scroll", setDimensions);
        window.addEventListener("resize", setDimensions);
        return () => {
            window.removeEventListener("scroll", setDimensions);
            window.removeEventListener("resize", setDimensions);
        };
    });

    return (
        <div className="StickyFooterWrapper" ref={setHeaderParentRef} style={{ height, bottom }}>
            <div
                ref={ref}
                className={StickyFooterClassname}
                style={{ left: footerLeftRight[0], right: footerLeftRight[1] }}
            >
                {props.children}
            </div>
        </div>
    );
};
