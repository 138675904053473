import { Form } from "antd";
import { FormItemProps, FormProps } from "antd/lib/form";
import classNames from "classnames";
import { ErrorMessage, FormikConsumer, FormikContext } from "formik";
import { get } from "lodash-es";
import { Component, memo, useMemo } from "react";

import { getValidateStatusTouched } from "utilities/form/form";
import yup, { getFieldSchema, isFieldRequired } from "utilities/form/yup";
import { isNullOrUndefined } from "utilities/object/object";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { RequiredFieldIndicator } from "commonComponents/utilities/RequiredFieldIndicator/RequiredFieldIndicator";

import "./BTForm.less";

export interface IBTFormProps extends FormProps {
    onSubmit?: () => void;
}

export class BTForm extends Component<IBTFormProps> {
    render() {
        const { onSubmit, ...otherProps } = this.props;
        return (
            // eslint-disable-next-line react/forbid-elements
            <Form layout="vertical" onFinish={onSubmit} {...otherProps} />
        );
    }
}

export interface IBTFormItemProps extends FormItemProps {}

export class BTFormItem extends Component<IBTFormItemProps> {
    render() {
        const { className, ...otherProps } = this.props;
        return (
            // eslint-disable-next-line react/forbid-elements
            <Form.Item {...otherProps} className={classNames(className, "BTFormItem")} />
        );
    }
}

export interface ILabelSchema {
    label: string;
    isRequiredFieldIndicatorVisible: boolean;
}

interface IBTFormItemAutomaticProps<FormValues> {
    /**
     * key name of the field, lodash deep names supported (example: "group.field", or "list[3].field") note: generic cannot be used when using a sub-key
     * id of the field
     */
    id: KeyOfOrString<FormValues> & string;

    /**
     * Set to true if the input being wrapped readOnly. If true, the required field indicator will not be displayed
     * @default false
     */
    readOnly?: boolean;

    /**
     * @default
     * yup validation schema's label, conditionally adds
     * when null no label is used
     */
    label?: null | string | JSX.Element | ((schema: ILabelSchema) => React.ReactNode);

    /**
     * Override for the validator-based required detection.
     * Can use to manually set if the asterisk should be displayed or not.
     */
    required?: boolean;

    className?: string;

    extra?: string;

    /**
     * Set the label size
     * medium: 12px
     * large: 16px
     * @default "medium"
     */
    labelSize?: "medium" | "large";

    children: React.ReactNode;
    help?: React.ReactNode;
}

function isYupSchema<T extends object>(schema: any): schema is yup.ObjectSchema<T> {
    return yup.isSchema(schema);
}

/**
 * @example <BTFormItemAutomatic<FormValues> id="field" />
 * @example <BTFormItemAutomatic id="object.field" />
 * @example <BTFormItemAutomatic id="array[0].field" />
 */
export const BTFormItemAutomatic = memo(
    BTFormItemAutomaticInternal
) as typeof BTFormItemAutomaticInternal;

function BTFormItemAutomaticInternal<FormValues extends object>(
    props: IBTFormItemAutomaticProps<FormValues>
) {
    return (
        <FormikConsumer>
            {function FormikConsumerChildren(formikProps) {
                return <FormItemFormikConsumer<FormValues> {...formikProps} {...props} />;
            }}
        </FormikConsumer>
    );
}

type IFormItemFormikConsumerProps<FormValues> = FormikContext<FormValues> &
    IBTFormItemAutomaticProps<FormValues>;
function FormItemFormikConsumer<FormValues extends object>({
    touched,
    errors,
    validationSchema,
    setFieldValue,
    readOnly,
    values,
    id,
    label,
    required,
    className,
    extra,
    labelSize,
    children,
    help,
}: IFormItemFormikConsumerProps<FormValues>) {
    const schema = useMemo(
        () => (typeof validationSchema === "function" && validationSchema()) || validationSchema,
        [validationSchema]
    );

    const isRequired = required ?? isFieldRequired(schema, id, values);
    const fieldSchemaMetaData = getFieldSchema(schema, id, values);
    const labelToUse = useMemo(() => {
        let labelToUse;
        if (label === undefined) {
            if (fieldSchemaMetaData.label === undefined && (!readOnly || !isRequired)) {
                return "";
            }
            labelToUse = (
                <>
                    {fieldSchemaMetaData.label}
                    {!readOnly && isRequired && <RequiredFieldIndicator />}
                </>
            );
        } else if (typeof label === "function") {
            labelToUse = label({
                label: fieldSchemaMetaData.label,
                isRequiredFieldIndicatorVisible: !readOnly && isRequired,
            });
        } else if (label !== null) {
            labelToUse = label;
        }
        return labelToUse;
    }, [fieldSchemaMetaData.label, isRequired, label, readOnly]);

    // Arbitrarily picked `setFieldValue` to determine if we are in a formik form.  Picked this since it's a common prop provided by formik.
    if (!setFieldValue) {
        throw new Error("Must use FormItemAutomatic in a Formik Form");
    }

    if (!isYupSchema<FormValues>(schema)) {
        throw new Error("Must provide a yup schema!");
    }

    return (
        <BTFormItem
            label={labelToUse}
            htmlFor={id}
            validateStatus={
                touched !== undefined && get(touched, id) !== undefined
                    ? getValidateStatusTouched(errors, touched, id)
                    : undefined
            }
            help={help ?? <ErrorMessage name={id} />}
            className={classNames(className, {
                largeLabel: labelSize === "large",
            })}
            extra={extra}
        >
            {children}
        </BTFormItem>
    );
}

export function btValidateSync<T>(
    schema: yup.Schema<T>,
    value: T
): yup.ValidationError | undefined {
    let errors: yup.ValidationError | undefined = undefined;

    try {
        schema.validateSync(value);
        errors = undefined;
    } catch (e) {
        errors = e as yup.ValidationError;
    }

    return errors;
}

function btValidateSyncAt<T>(
    schema: yup.Schema<T>,
    field: string,
    value: T
): yup.ValidationError | undefined {
    let errors: yup.ValidationError | undefined = undefined;

    try {
        schema.validateSyncAt(field, { [field]: value } as T);
        errors = undefined;
    } catch (e) {
        errors = e as yup.ValidationError;
    }

    return errors;
}

const BTFormItemLabel = (props: { label: string; required: boolean; readOnly: boolean }) => {
    const { label, required, readOnly } = props;
    return (
        <>
            {label}
            {!readOnly && required && <RequiredFieldIndicator />}
        </>
    );
};

interface IBTFormItemYupProps {
    label: React.ReactNode;
    help: string | undefined;
    validateStatus: "error" | undefined;
}

export function getBTFormItemYupValidationProps<T>(
    value: T,
    schema: yup.Schema<unknown>,
    field: string,
    readOnly?: boolean
): IBTFormItemYupProps {
    const currentErrors = btValidateSyncAt(schema, field, value);

    const required = isFieldRequired(schema, field, value);
    const fieldSchemaMetaData = getFieldSchema(schema, field);

    return {
        label: (
            <BTFormItemLabel
                label={fieldSchemaMetaData.label}
                required={required}
                readOnly={!!readOnly}
            />
        ),
        help: currentErrors?.message,
        validateStatus: !isNullOrUndefined(currentErrors) ? "error" : undefined,
    };
}
