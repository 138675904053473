import { useContext } from "react";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { routes } from "utilities/routes";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";

import "./OutOfOfficeBanner.less";

interface IOutOfOfficeBannerProps {}

export const OutOfOfficeBanner: React.FC<IOutOfOfficeBannerProps> = () => {
    const userContext = useContext(UserInfoContext);
    const message = (
        <BTRow gutter={4}>
            <BTCol>Users in Buildertrend will see your status when sending you a message.</BTCol>
            <BTCol>
                <BTLinkRelative
                    to={routes.internalUsers.getInternalUserDetailsLink(
                        userContext!.globalUserId!,
                        InternalUserTabKeys.Messaging
                    )}
                    data-testid="OutofOfficeSettings"
                >
                    Go to Settings
                </BTLinkRelative>
            </BTCol>
        </BTRow>
    );

    const component = (
        <BTAlert
            message={message}
            type="info"
            data-testid="outOfOfficeBanner"
            banner
            showIcon
            title="You Are Out of Office"
            className="OutOfOfficeBanner"
        />
    );
    return component;
};
