import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import {
    IViewDocumentInBrowserRequest,
    ZohoDocumentBrowserInfo,
} from "commonComponents/entity/media/Zoho/Zoho.api.types";

export interface IZohoHandler {
    viewDocumentInBrowser(
        documentInstanceId: number,
        checkToCopy?: boolean,
        forceNewCopy?: boolean
    ): Promise<ZohoDocumentBrowserInfo>;
    deleteZohoDocument(deleteUrls: string[]): Promise<EmptyResponseEntity>;
}

export class ZohoHandler implements IZohoHandler {
    async viewDocumentInBrowser(
        documentInstanceId: number,
        checkToCopy?: boolean,
        forceNewCopy?: boolean
    ) {
        const request: IViewDocumentInBrowserRequest = {
            checkToCopy,
            docId: documentInstanceId,
            forceNewCopy,
        };

        return await APIHandler("/api/Documents/ViewDocInBrowser", {
            method: "GET",
            data: request,
            responseType: ZohoDocumentBrowserInfo,
        });
    }

    async deleteZohoDocument(deleteUrls: string[]): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Media/ZohoDocumentSession", {
            method: "DELETE",
            data: { deleteUrls },
            responseType: EmptyResponseEntity,
        });
    }
}
