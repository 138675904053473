import { FileViewOptions } from "legacyComponents/FileUploadContainer.types";
import {
    getBuilderOptions,
    getOwnerOptions,
    getSubsOptions,
} from "legacyComponents/FileUploadContainer.utils";
import { FunctionComponent } from "react";

import { MediaType } from "types/enum";

import {
    BTFileUploadMode,
    IBTFileUploadFormValues,
} from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { MediaNotifications } from "commonComponents/entity/media/MediaNotifications/MediaNotifications";
import { IBTInjectedFormikProps } from "commonComponents/utilities/Formik/WithFormikBT";

interface IMediaAttachmentNotifications {
    notifyOptions: FileViewOptions;
    areNotificationsHidden: boolean;
    uploadMode: BTFileUploadMode;
    mediaType: MediaType;
    isSaving: boolean;
    onToggleNotifications: () => void;
}

export const MediaAttachmentNotifications: FunctionComponent<
    IBTInjectedFormikProps<IMediaAttachmentNotifications, IBTFileUploadFormValues>
> = ({ notifyOptions, ...props }) => {
    const notifyBuilderOptions = getBuilderOptions(notifyOptions);
    const notifySubOptions = getSubsOptions(notifyOptions);
    const notifyOwnerOptions = getOwnerOptions(notifyOptions);

    return (
        <MediaNotifications
            {...props}
            internalUserNotificationDisabled={
                !props.values.showBuilder || !notifyBuilderOptions?.canEdit
            }
            internalUserNotificationVisible={notifyBuilderOptions?.visible ?? false}
            subNotificationDisabled={!props.values.showSubs || !notifySubOptions?.canEdit}
            subNotificationVisible={notifySubOptions?.visible ?? false}
            ownerNotificationDisabled={!props.values.showOwner || !notifyOwnerOptions?.canEdit}
            ownerNotificationVisible={notifyOwnerOptions?.visible ?? false}
        />
    );
};
