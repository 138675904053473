import { FileTextFilled } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { DocumentIcons } from "styles/buildertrendTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconFileTextFilled: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <FileTextFilled {...props} style={{ ...props.style, color: DocumentIcons.Txt }} />;
};
