import { BTLocalStorage } from "types/btStorage";
import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { DirectoryType, DocumentInstanceType, MediaType } from "types/enum";
import { documentInstanceTypeMultiplier, rootLevelFolderId } from "types/MediaConstants";

import { APIHandler, IBlobResponse } from "utilities/apiHandler";

import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";

import {
    DeletedFoldersResponse,
    IDownloadZipOfAttachedFilesRequest,
    IDownloadZipOfFolderRequest,
    IFileFolderListRequest,
    IMediaDeleteRequest,
    ISetMainOwnerMediaRequest,
    MediaListEntity,
    SelectedMediaListViews,
} from "entity/media/common/mediaTypes";
import {
    IGetLinkedDocumentInstancesRequest,
    MediaReplaceEntity,
} from "entity/media/MediaReplace/MediaReplace.api.types";

export interface IMediaListHandler {
    getMainDirectory(mediaType: MediaType, jobId: number): Promise<MediaListEntity>;
    getFolder(
        directoryType: DirectoryType,
        mediaType: MediaType,
        folderId: number,
        jobId: number
    ): Promise<MediaListEntity>;
    getLinkedDocumentInstances(
        documentInstanceId: number,
        includeThisDoc?: boolean,
        linkedByAnnotationGroup?: boolean
    ): Promise<MediaReplaceEntity>;
    getSavedListView(mediaType: MediaType): SelectedMediaListViews;
    setSavedListView(selectedListView: SelectedMediaListViews, mediaType: MediaType): void;
    deleteMedia(
        folderIds: number[],
        fileIds: number[],
        jobId: number,
        mediaType: number
    ): Promise<DeletedFoldersResponse>;
    downloadZipOfFolder(data: IDownloadZipOfFolderRequest): Promise<IBlobResponse>;
    downloadZipOfAttachedDocs(data: IDownloadZipOfAttachedFilesRequest): Promise<IBlobResponse>;
    setMainOwnerFeature(
        id: number,
        jobId: number,
        mediaType: MediaType
    ): Promise<EmptyResponseEntity>;
}

export class MediaListHandler implements IMediaListHandler {
    async getMainDirectory(mediaType: MediaType, jobId: number): Promise<MediaListEntity> {
        const requestData: IFileFolderListRequest = {
            mediaType,
            folderId: rootLevelFolderId,
            associatedTypeId: DocumentInstanceType.StandaloneDocument,
            directoryType:
                jobId === JobIdTypes.GlobalDocs ? DirectoryType.GlobalDocs : DirectoryType.Standard,
            jobId,
        };
        return await APIHandler("/api/MediaFolders/MainDirectory", {
            method: "GET",
            data: requestData,
            responseType: MediaListEntity,
        });
    }

    async getFolder(
        directoryType: DirectoryType,
        mediaType: MediaType,
        folderId: number,
        jobId: number
    ): Promise<MediaListEntity> {
        const requestData: IFileFolderListRequest = {
            mediaType,
            folderId,
            associatedTypeId:
                directoryType === DirectoryType.AttachedFiles &&
                folderId % documentInstanceTypeMultiplier === 0
                    ? folderId / documentInstanceTypeMultiplier
                    : DocumentInstanceType.StandaloneDocument,
            directoryType,
            jobId,
        };
        return await APIHandler("/api/MediaFolders/GetDirectoryDetails", {
            method: "GET",
            data: requestData,
            responseType: MediaListEntity,
        });
    }

    async getLinkedDocumentInstances(
        documentInstanceId: number,
        includeThisDoc?: boolean,
        linkedByAnnotationGroup?: boolean
    ): Promise<MediaReplaceEntity> {
        const request: IGetLinkedDocumentInstancesRequest = {
            documentInstanceId,
            includeThisDoc,
            linkedByAnnotationGroup,
        };

        return await APIHandler("/api/Documents/GetLinkedDocumentInstances", {
            method: "GET",
            data: request,
            responseType: MediaReplaceEntity,
        });
    }

    getSavedListView(mediaType: MediaType): SelectedMediaListViews {
        switch (mediaType) {
            case MediaType.Document:
                return BTLocalStorage.get("bt-string-selectedDocumentListView");
            case MediaType.Photo:
                return BTLocalStorage.get("bt-string-selectedPhotoListView");
            case MediaType.Video:
                return BTLocalStorage.get("bt-string-selectedVideoListView");
            default:
                return "Tile";
        }
    }

    setSavedListView(selectedListView: SelectedMediaListViews, mediaType: MediaType): void {
        switch (mediaType) {
            case MediaType.Document:
                BTLocalStorage.set("bt-string-selectedDocumentListView", selectedListView);
                break;
            case MediaType.Photo:
                BTLocalStorage.set("bt-string-selectedPhotoListView", selectedListView);
                break;
            case MediaType.Video:
                BTLocalStorage.set("bt-string-selectedVideoListView", selectedListView);
                break;
            default:
                break;
        }
    }

    async deleteMedia(
        folderIds: number[],
        fileIds: number[],
        jobId: number,
        mediaType: MediaType
    ): Promise<DeletedFoldersResponse> {
        const data: IMediaDeleteRequest = {
            folderIds,
            fileIds,
            jobId,
            mediaType,
        };
        return await APIHandler("/api/Media", {
            method: "DELETE",
            data,
            responseType: DeletedFoldersResponse,
        });
    }

    async downloadZipOfFolder(data: IDownloadZipOfFolderRequest): Promise<IBlobResponse> {
        return await APIHandler("/api/MediaFolders/DownloadZipOfFolder", {
            method: "POST",
            data,
            responseType: "blob",
        });
    }

    async downloadZipOfAttachedDocs(
        data: IDownloadZipOfAttachedFilesRequest
    ): Promise<IBlobResponse> {
        return await APIHandler("/api/Media/DownloadZipOfAttachedDocs", {
            method: "POST",
            data,
            responseType: "blob",
        });
    }

    async setMainOwnerFeature(
        id: number,
        jobId: number,
        mediaType: MediaType
    ): Promise<EmptyResponseEntity> {
        const data: ISetMainOwnerMediaRequest = {
            id,
            jobId,
            mediaType,
        };
        return await APIHandler("/api/Media/SetMainOwnerMedia", {
            method: "PUT",
            data,
            responseType: EmptyResponseEntity,
        });
    }
}
