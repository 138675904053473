/**
 * Generates a thumbnail image for the provided raw video blob file.
 * @param videoFile
 */
export const getDataThumbnailFromVideoBlob = async (videoFile: Blob) => {
    const thumbWidth = 125;
    const thumbHeight = 125;

    return new Promise<string>((resolve) => {
        const video = document.createElement("video");
        video.preload = "metadata";

        video.addEventListener("playing", () => {
            const canvas = document.createElement("canvas");
            canvas.width = thumbWidth;
            canvas.height = thumbHeight;
            canvas.getContext("2d")?.drawImage(video, 0, 0, thumbWidth, thumbHeight);
            video.pause();

            const dataThumbnail = canvas.toDataURL();
            canvas.remove();
            resolve(dataThumbnail);
        });

        video.src = URL.createObjectURL(videoFile);
        video.muted = true;
        video.playsInline = true;
        video
            .play()
            .catch(() => {
                // playback failed. Most likely because the browser doesn't have the codec (ex: .avi on chrome)
                resolve("");
            })
            .finally(() => {
                video.remove();
            });
    });
};
