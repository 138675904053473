import { ClientObservableBackgroundJobStatus, JobImportActionRequiredType } from "types/enum";

import { TemplateImportResponse } from "entity/templateImport/TemplateImport/TemplateImport.api.types";

export type ClientObservableBackgroundJobStatusFormActions = string | undefined;

export enum ProcessStatusGroupType {
    CostItemUpdate = 1,
    JobImportNew = 2,
    OrgToOrgCopy = 3,
}

export enum BackgroundProcessEntityType {
    Job = 1,
    JobTemplate = 2,
}

export class ClientObservableBackgroundJobDetails {
    constructor(data: any) {
        this.processID = data.processId;
        this.processName = data.processName;
        this.details = data.details;
        this.status = data.status;
        this.processType = data.processType;
        this.entityId = data.entityId;
        this.entityType = data.entityType;
    }
    processID: string;
    processName: string;
    details: string;
    status: ClientObservableBackgroundJobStatus;
    processType: ProcessStatusGroupType;
    entityId: number | null;
    entityType: BackgroundProcessEntityType | null;
}

export class ClientObservableBackgroundJobStatuses {
    constructor(data: any) {
        this.backgroundJobStatuses = data.jobs.map(
            (x: any) => new ClientObservableBackgroundJobDetails(x)
        );
        this.backgroundJobStatuses.reverse();
    }
    backgroundJobStatuses: ClientObservableBackgroundJobDetails[];
}
export class ClearClientObservableBackgroundJobStatusesResponse {}

export interface IProcessStatusGroupState {
    showProcessGroup: boolean;
    isPanelExpanded: boolean;
}

export class JobImportActionRequiredResponse {
    constructor(data: any) {
        this.actionRequiredType = data.actionRequiredType;
        this.jobId = data.jobId;
        this.scheduleExtraData = data.scheduleExtraData
            ? new TemplateImportResponse(data.scheduleExtraData)
            : undefined;
        this.jobExtraData = data.jobExtraData
            ? new ActionRequiredJobExtraData(data.jobExtraData)
            : undefined;
    }

    actionRequiredType: JobImportActionRequiredType;
    jobId: number;
    scheduleExtraData?: TemplateImportResponse;
    jobExtraData?: ActionRequiredJobExtraData;
}

class ActionRequiredJobExtraData {
    constructor(data: any) {
        this.shouldShowLinkCustomer = data.shouldShowLinkCustomer;
        this.shouldTurnCalendarOffline = data.shouldTurnCalendarOffline;
        this.leadId = data.leadId ?? undefined;
    }

    shouldShowLinkCustomer: boolean;
    shouldTurnCalendarOffline: boolean;
    leadId?: number;
}
