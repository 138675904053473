import {
    BTServiceFileUpload,
    MapFileUploadToBTServiceDocumentItem,
} from "legacyComponents/FileUploadContainer.types";
import moment from "moment";

import { BTSelectItem, IBaseEntityPermissions } from "types/apiResponse/apiResponse";
import { CommonConstants } from "types/constants";
import { Countries } from "types/countries";
import { CustomFieldAssociatedType, UserActivationStatus } from "types/enum";

import { alphabetize, getSelectedValue } from "utilities/form/form";
import { isNullOrUndefined } from "utilities/object/object";

import { LinkingAction } from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import { LinkedAccountingEntityActions } from "commonComponents/entity/accounting/LinkedAccountingEntity/LinkedAccountingEntity";
import { AccountingEntity } from "commonComponents/entity/accounting/LinkedAccountingEntity/LinkedAccountingEntity.api.types";
import { GetAddressServiceObject } from "commonComponents/entity/address/Address/Address";
import {
    AddressEntity,
    AddressFormValues,
    AddressServiceObject,
    IHasAddress,
} from "commonComponents/entity/address/Address/Address.api.types";
import { CellEmailLookupEntity } from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookup.api.types";
import {
    ICellPhoneInputFormValues,
    SmsOptInStatus,
    VerificationMessageType,
} from "commonComponents/entity/cellPhone/CellPhoneInput/CellPhoneInput.types";
import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import {
    ExternalEmailLookupEntity,
    ExternalEmailLookupFormValues,
} from "commonComponents/entity/externalEmailLookup/ExternalEmailLookup/ExternalEmailLookup.api.types";
import { JobAccessEntity } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import {
    INotificationPreference,
    INotificationPreferenceData,
    INotificationPreferenceRow,
} from "commonComponents/entity/notificationPreferences/NotificationPreferencesTable.api.types";
import {
    CertificateType,
    ICertificateFileFormValues,
} from "commonComponents/entity/sub/CertificateFile/CertificateFile.types";
import { UserActivationActions } from "commonComponents/entity/userActivation/UserActivation/UserActivation";
import { JobsiteLinkingTypes } from "commonComponents/financial/Common/Accounting.types";

import { BTServiceDocumentItem } from "entity/media/common/mediaTypes";

export type SubVendorFormActions =
    | undefined
    | "save"
    | "saveAndClose"
    | "saveAndNew"
    | "delete"
    | LinkedAccountingEntityActions
    | UserActivationActions
    | TradeAgreementActions;
export type TradeAgreementActions =
    | undefined
    | "request"
    | "manuallyApprove"
    | "release"
    | "cancel"
    | "replace"
    | "deleteTradeAgreement";

export interface ISubVendorFormValues extends ICellPhoneInputFormValues {
    company: string;
    primaryContact: string;
    businessPhone: string;
    fax: string;
    division: number[];
    address: AddressFormValues;
    cellEmail: string;
    email: ExternalEmailLookupFormValues;
    inviteText: string | null;
    activationStatus: UserActivationStatus;

    generalLiabilityCert: ICertificateFileFormValues;
    workmansCompCert: ICertificateFileFormValues;
    additionalCert1?: ICertificateFileFormValues;
    additionalCert2?: ICertificateFileFormValues;
    tradeAgreement?: BTServiceFileUpload;
    unreleasedTradeAgreement?: BTServiceFileUpload;

    conflictAlertThreshold: number;
    shouldAlertConflicts: boolean;

    notes: string;
    customFields: ICustomFieldFormValues[];

    canAssignRfi: boolean | null;
    shouldAutomaticallyAddToNewJobs: boolean;
    canViewOwnerInformation: boolean;
    canShareCommentsAndDocs: boolean;
    shouldHoldPayment: boolean;
    holdPaymentNotes: string;

    jobAccess: JobAccessEntity | null;
    notificationPreferences: INotificationPreferenceRow[];

    accountingEntityLinkingType: JobsiteLinkingTypes;
    createNewAccountingEntityOption: LinkingAction;
    tradeAgreementDescription: string;
    hasInvitePermission: boolean;
    preferredPaymentEmail: string | undefined;
}

export class SubVendorEntity
    implements
        IBaseEntityPermissions,
        IHasAddress,
        IScheduleConflictData,
        ISubVendorConfigSettings,
        IHasCustomFieldContainer
{
    constructor(data: any) {
        this.id = data.id || 0;
        this.builderId = data.builderId;
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.canEditJobAccess = data.canEditJobAccess;

        this.company = data.company.value;
        this.primaryContact = data.primaryContact.value;
        this.address = new AddressEntity(data.address);
        this.businessPhone = data.businessPhone.value;
        this.cellPhone = data.cellPhone.value;
        this.fax = data.fax.value;
        this.division = alphabetize(data.division.value.map((d: any) => new BTSelectItem(d)));

        this.email = new ExternalEmailLookupEntity(data.email);
        this.cellEmail = data.cellEmail;
        this.cellEmailLookupLoadData = new CellEmailLookupEntity(data.cellEmailLookupLoadData);

        this.configSettings = data.configSettings;
        this.canEmailInternally = data.canEmailInternally;

        this.activationStatusText = data.activationStatusText;
        this.status = new SubActivationStatus(data.status);

        this.shouldHoldPayment = data.holdPaymentChkbox.value;
        this.holdPaymentNotes = data.holdPaymentNotes.value;
        this.notes = data.notes.value;

        this.customFieldAssociatedType = data.customFieldAssociatedType;
        this.customFields = data.customFields;
        this.customFieldOptions = data.customFieldOptions;
        this.customFieldsCanConfigure = data.customFieldsCanConfigure;

        this.defaultInvitationText = null;
        if (data.inviteButton) {
            this.inviteButtonText = data.inviteButton.buttonName;
            this.showInviteButton = true;
            this.defaultInvitationText = data.invitationText.value;
        } else {
            this.showInviteButton = false;
        }

        this.toggleSubStatusButtonText = data.toggleSubStatusButtonText;
        this.isSubEnabled = data.isSubEnabled;

        this.generalLiabilityCert = new SubVendorCertFileWithReminder(data.generalLiabilityCert);
        this.workmansCompCert = new SubVendorCertFileWithReminder(data.workmansCompCert);
        if (data.additionalCert1) {
            this.additionalCert1 = new SubVendorCertFileWithReminder(data.additionalCert1);
        }
        if (data.additionalCert2) {
            this.additionalCert2 = new SubVendorCertFileWithReminder(data.additionalCert2);
        }
        this.additionalCertText = data.additionalCertText;

        this.tradeAgreementContract = new SubVendorCertFile(data.tradeAgreementContract);
        this.unreleasedTradeAgreementContract = new SubVendorCertFile(
            data.unreleasedTradeAgreementContract
        );
        this.certMaxReminderOptions = data.certMaxReminderOptions.value.map(
            (p: any) => new BTSelectItem(p)
        );

        this.scheduleData = data.scheduleData;
        this.subDetailTabs = data.subDetailTabs;
        this.tradeAgreementTitle = data.tradeAgreementTitle;
        this.preferredPaymentEmail = data.preferredPaymentEmail;

        this.canManageSubscription = data.canManageSubscription;
        this.requiredPlanForActiveSubs = data.requiredPlanForActiveSubs;

        this.createdOn = data.createdOn ? moment(data.createdOn) : null;
        this.createdByUserId = data.createdByUserId;
        this.createdByName = data.createdByName ?? undefined;

        this.modifiedOn = data.modifiedOn ? moment(data.modifiedOn) : null;
        this.modifiedByUserId = data.modifiedByUserId;
        this.modifiedByName = data.modifiedByName ?? undefined;

        this.smsOptInStatus = data.smsOptInStatus;
        this.countryCode = data.countryCode;
    }
    id: number;
    jobId: undefined;
    builderId: number;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canEditJobAccess: boolean;

    company: string;
    primaryContact: string;
    address: AddressEntity;
    businessPhone: string;
    cellPhone: string;
    smsOptInStatus: SmsOptInStatus;
    countryCode: Countries;
    fax: string;
    division: BTSelectItem[];

    email: ExternalEmailLookupEntity;
    cellEmail: string;
    cellEmailLookupLoadData: CellEmailLookupEntity;

    configSettings: SubVendorConfigSettings;
    canEmailInternally: boolean; // TODO what is this used for

    activationStatusText: string; // TODO is this needed?
    status: SubActivationStatus;

    shouldHoldPayment: boolean;
    holdPaymentNotes: string;
    notes: string;

    customFieldAssociatedType: CustomFieldAssociatedType;
    customFields: ICustomFieldResponse[];
    customFieldOptions: ICustomFieldOptionsList;
    customFieldsCanConfigure: boolean;

    inviteButtonText?: string;
    showInviteButton: boolean;
    defaultInvitationText: string | null;

    toggleSubStatusButtonText: string;
    isSubEnabled: boolean;

    generalLiabilityCert: SubVendorCertFileWithReminder;
    workmansCompCert: SubVendorCertFileWithReminder;
    additionalCert1?: SubVendorCertFileWithReminder;
    additionalCert2?: SubVendorCertFileWithReminder;
    additionalCertText: string | null;
    certMaxReminderOptions: BTSelectItem[];
    tradeAgreementContract: SubVendorCertFile<ITradeAgreementExtraData>;
    unreleasedTradeAgreementContract: SubVendorCertFile<ITradeAgreementExtraData>;

    scheduleData: SchuleduleConflictData;
    subDetailTabs: ISubDetailTabs;

    accountingEntity: AccountingEntity | null;
    tradeAgreementDescription: string;
    tradeAgreementTitle: string;
    preferredPaymentEmail: string | undefined;

    canManageSubscription: boolean;
    requiredPlanForActiveSubs: string;

    createdOn: moment.Moment | null;
    createdByUserId: number | null;
    createdByName?: string;
    modifiedOn: moment.Moment | null;
    modifiedByUserId: number | null;
    modifiedByName?: string;
}

export class SubVendorCreateUpdateResponse {
    constructor(data: any) {
        this.id = data.id;
        this.addedJobIds = data.addedJobIds;
        if (data.tradeAgreement) {
            this.tradeAgreement = new SubVendorCertFile(data.tradeAgreement);
        }
    }
    id: number;
    addedJobIds: number[];
    tradeAgreement?: SubVendorCertFile<ITradeAgreementExtraData>;
}

export class SubVendorToggleEnableResponse {
    constructor(data: any) {
        this.status = new SubActivationStatus(data.status);
        this.defaultInvitationText = isNullOrUndefined(data.defaultInvitationText)
            ? null
            : data.defaultInvitationText;
        if (data.inviteButtonText) {
            this.inviteButtonText = data.inviteButtonText;
            this.showInviteButton = true;
        } else {
            this.showInviteButton = false;
        }
        this.suggestReassignment = data.suggestReassignment ?? false;
    }
    status: SubActivationStatus;
    inviteButtonText?: string;
    showInviteButton: boolean;
    defaultInvitationText: string | null;
    suggestReassignment: boolean;
}

export class SubActivationStatus {
    constructor(data: any) {
        this.value = data.value;
        this.sinceDate = moment(data.sinceDate);
        this.colorHex = data.color;
        this.canActivate = data.canActivate;
        this.activationString = data.activationString;
    }
    value: UserActivationStatus;
    sinceDate: moment.Moment;
    colorHex: string;
    canActivate: boolean;
    activationString: string;
}

export class SubVendorCertFile<ExtraDataType = undefined> {
    constructor(data: any) {
        if (data.certTitle) {
            this.certTitle = data.certTitle;
        }
        if (data.file) {
            this.file = new BTServiceDocumentItem<ExtraDataType>(data.file);
        }
        if (data.certExpires?.value) {
            this.certExpires = moment(data.certExpires.value);
        }
        this.deleteCert = data.deleteCert;
        this.allowedFileTypes = data.allowedFileTypes;
        this.previousFilesCount = data.previousFilesCount;
        if (data.signatureImagePath) {
            this.signatureImagePath = data.signatureImagePath;
        }
        if (data.tradeAgreementId) {
            this.tradeAgreementId = data.tradeAgreementId;
        }
        if (data.description) {
            this.description = data.description;
        }
        if (data.sentBy) {
            this.sentBy = data.sentBy;
        }
        if (data.sentOn) {
            this.sentOn = moment(data.sentOn);
        }
        if (data.link) {
            this.link = data.link;
        }
        if (data.title) {
            this.title = data.title;
        }
        if (!isNullOrUndefined(data.approvalStatus)) {
            this.approvalStatus = data.approvalStatus;
        }
        if (data.releasedDate) {
            this.releasedDate = moment(data.releasedDate);
        }
        if (data.approvedDate) {
            this.approvedDate = moment(data.approvedDate);
        }
        if (data.approvedBy) {
            this.approvedBy = data.approvedBy;
        }
    }
    certTitle?: string;
    file?: BTServiceDocumentItem<ExtraDataType>;
    certExpires?: moment.Moment;
    deleteCert: boolean;
    allowedFileTypes: string[];
    previousFilesCount: number;
    signatureImagePath?: string;
    tradeAgreementId?: number;
    description?: string;
    sentBy?: string;
    sentOn?: moment.Moment;
    link?: string;
    title?: string;
    approvalStatus?: number;
    releasedDate?: moment.Moment;
    approvedDate?: moment.Moment;
    approvedBy?: string;
}

export class SubVendorCertFileWithReminder extends SubVendorCertFile {
    constructor(data: any) {
        super(data);

        this.certMaxReminders = data.certMaxReminders;
        this.certReminderDays = data.certReminderDays;
        this.certReminderBeforeAfter = data.certReminderBeforeAfter.value.map(
            (b: any) => new BTSelectItem({ ...b, name: b.name.toLowerCase() })
        );
        this.selectedReminderOption = getSelectedValue(this.certReminderBeforeAfter);
    }

    certMaxReminders: number;
    certReminderDays: number;
    certReminderBeforeAfter: BTSelectItem[];
    selectedReminderOption: number;
}

export interface IScheduleConflictData {
    scheduleData: SchuleduleConflictData;
}

export interface ISubDetailTabs {
    canViewAccountingTab: boolean;
    accountingConnected: boolean;
    canViewNotificationTab: boolean;
    // Remove notifications with getAdminValue("reactSubSetup")
    notificationPreferences: INotificationPreferenceData;
}

export interface ISubVendorConfigSettings {
    configSettings: SubVendorConfigSettings;
}

export class SubVendorConfigSettings {
    constructor(
        shouldAutomaticallyAddToNewJobs: boolean,
        canViewOwnerInformation: boolean,
        canShareCommentsAndDocs: boolean,
        canAssignRfi: boolean | null | undefined
    ) {
        this.shouldAutomaticallyAddToNewJobs = shouldAutomaticallyAddToNewJobs;
        this.canViewOwnerInformation = canViewOwnerInformation;
        this.canShareCommentsAndDocs = canShareCommentsAndDocs;
        this.canAssignRfi = isNullOrUndefined(canAssignRfi) ? null : canAssignRfi;
    }
    shouldAutomaticallyAddToNewJobs: boolean;
    canViewOwnerInformation: boolean;
    canShareCommentsAndDocs: boolean;
    canAssignRfi: boolean | null;
    hasInvitePermission: boolean;
}

export class SchuleduleConflictData {
    constructor(
        conflictAlertThreshold: number | undefined,
        shouldAlertConflicts: boolean | undefined,
        conflictCount: number | undefined,
        hasSchedulConflictsOverThreshold: boolean | undefined
    ) {
        this.conflictAlertThreshold =
            (!isNullOrUndefined(conflictAlertThreshold) && conflictAlertThreshold < 2) ||
            isNullOrUndefined(conflictAlertThreshold)
                ? 2
                : conflictAlertThreshold;
        this.shouldAlertConflicts = isNullOrUndefined(shouldAlertConflicts)
            ? false
            : shouldAlertConflicts;
        this.conflictCount = isNullOrUndefined(conflictCount) ? 0 : conflictCount;
        this.hasSchedulConflictsOverThreshold = isNullOrUndefined(hasSchedulConflictsOverThreshold)
            ? false
            : hasSchedulConflictsOverThreshold;
    }
    conflictAlertThreshold: number;
    shouldAlertConflicts: boolean;
    hasSchedulConflictsOverThreshold: boolean;
    conflictCount: number;
    hasScheduleViewPermissions: boolean;
}

export class SubSaveFormRequest {
    constructor(entity: SubVendorEntity, values: ISubVendorFormValues, sendInvitation: boolean) {
        this.sendInvitation = sendInvitation;
        if (sendInvitation) {
            this.invitationText = values.inviteText;
        }
        this.company = values.company;
        this.primaryContact = values.primaryContact;
        this.businessPhone = values.businessPhone;
        this.cellPhone = values.phoneCell;
        this.cellEmail = values.cellEmail;
        this.fax = values.fax;
        this.division = values.division;
        this.email = values.email;
        this.notes = values.notes;
        this.address = GetAddressServiceObject(entity, values.address);
        this.customFields = values.customFields;
        this.scheduleData = new SchuleduleConflictData(
            values.conflictAlertThreshold,
            values.shouldAlertConflicts,
            entity.scheduleData.conflictCount,
            entity.scheduleData.hasSchedulConflictsOverThreshold
        );
        this.configSettings = new SubVendorConfigSettings(
            values.shouldAutomaticallyAddToNewJobs,
            values.canViewOwnerInformation,
            values.canShareCommentsAndDocs,
            values.canAssignRfi
        );
        this.holdPaymentChkbox = values.shouldHoldPayment;
        this.holdPaymentNotes = values.holdPaymentNotes;

        this.generalLiabilityCert = new CertificateFileSaveRequest(
            values.generalLiabilityCert,
            entity.generalLiabilityCert
        );
        this.workmansCompCert = new CertificateFileSaveRequest(
            values.workmansCompCert,
            entity.workmansCompCert
        );

        if (values.additionalCert1 && entity.additionalCert1) {
            this.additionalCert1 = new CertificateFileSaveRequest(
                values.additionalCert1,
                entity.additionalCert1
            );
        }

        if (values.additionalCert2 && entity.additionalCert2) {
            this.additionalCert2 = new CertificateFileSaveRequest(
                values.additionalCert2,
                entity.additionalCert2
            );
        }

        this.tradeAgreementContract = new SubVendorCertFile(entity.tradeAgreementContract);
        if (values.tradeAgreement) {
            this.tradeAgreementContract.file = MapFileUploadToBTServiceDocumentItem(
                values.tradeAgreement
            );
        } else {
            this.tradeAgreementContract.file = undefined;
            this.tradeAgreementContract.deleteCert = !isNullOrUndefined(
                entity.tradeAgreementContract.file
            ); // delete if exists currently
        }
        this.unreleasedTradeAgreementContract = new SubVendorCertFile(
            entity.unreleasedTradeAgreementContract
        );
        if (values.unreleasedTradeAgreement) {
            this.unreleasedTradeAgreementContract.file = MapFileUploadToBTServiceDocumentItem(
                values.unreleasedTradeAgreement
            );
        } else {
            this.unreleasedTradeAgreementContract.file = undefined;
            this.unreleasedTradeAgreementContract.deleteCert = !isNullOrUndefined(
                entity.unreleasedTradeAgreementContract.file
            );
        }
        this.tradeAgreementDescription = values.tradeAgreementDescription;

        this.jobsiteAccessUpdates = values.jobAccess;
        this.notificationPreferences = values.notificationPreferences
            .filter((np) => !np.isCategoryRow && !np.isGroupRow)
            .map((row) => {
                const pref: INotificationPreference = {
                    emailEnabled: row.emailEnabled!,
                    textEnabled: row.textEnabled!,
                    pushEnabled: row.pushEnabled,
                    allUsersEnabled: row.allUsersEnabled,
                    name: row.name,
                    type: row.type!,
                };
                return pref;
            });

        this.createNewAccountingEntityOption =
            values.createNewAccountingEntityOption === LinkingAction.Create;

        this.preferredPaymentEmail = values.preferredPaymentEmail;
        this.verificationRequest = values.verificationRequest;
    }

    company: string;
    primaryContact: string;
    businessPhone: string;
    cellPhone: string;
    cellEmail: string;
    fax: string;
    division: number[];
    address: AddressServiceObject;
    email: ExternalEmailLookupFormValues;
    notes: string;
    customFields: ICustomFieldFormValues[];
    scheduleData: SchuleduleConflictData;
    configSettings: SubVendorConfigSettings;
    holdPaymentChkbox: boolean;
    holdPaymentNotes: string;

    sendInvitation: boolean;
    invitationText: string | null;

    generalLiabilityCert: CertificateFileSaveRequest;
    workmansCompCert: CertificateFileSaveRequest;
    additionalCert1?: CertificateFileSaveRequest;
    additionalCert2?: CertificateFileSaveRequest;
    tradeAgreementContract?: SubVendorCertFile;
    unreleasedTradeAgreementContract: SubVendorCertFile;
    tradeAgreementDescription: string;

    jobsiteAccessUpdates: JobAccessEntity | null;
    // Remove notifications with getAdminValue("reactSubSetup")
    notificationPreferences?: INotificationPreference[];

    createNewAccountingEntityOption: boolean;
    preferredPaymentEmail: string | undefined;
    verificationRequest: VerificationMessageType;
}

class CertificateFileSaveRequest {
    constructor(
        formValueCert: ICertificateFileFormValues,
        entityCert: SubVendorCertFileWithReminder
    ) {
        this.certExpires = formValueCert.certExpires;
        this.certReminderDays = formValueCert.certReminderDays;
        this.certReminderBeforeAfter = formValueCert.selectedReminderOption;
        this.certMaxReminders = formValueCert.certMaxReminders;
        if (formValueCert && formValueCert.attachedFile) {
            this.file = formValueCert.attachedFile;
            this.deleteCert = false;
        } else if (entityCert.file) {
            // file exists on the server but not in form values
            this.deleteCert = true;
        }
    }

    certExpires?: moment.Moment;
    certReminderDays: number;
    certReminderBeforeAfter: number;
    file?: BTServiceFileUpload;
    certMaxReminders: number;
    deleteCert: boolean;
}

export interface IAgreementReleaseRequest {
    tradeAgreementId: number;
    subId: number;
    builderId: number;
    currentStatus: number;
    newStatus: number;
}

export class AgreementReleaseResponse {
    constructor(data: any) {
        this.releasedDate = moment(data.releasedDate);
    }

    releasedDate: moment.Moment;
}

export interface IReplaceAgreementRequest {
    replacementDoc: BTServiceDocumentItem;
    description: string;
    tradeAgreementId: number;
    subId: number;
    builderId: number;
}

export class ReplaceAgreementResponse {
    constructor(data: any) {
        this.tradeAgreement = new SubVendorCertFile(data.tradeAgreement);
    }

    tradeAgreement: SubVendorCertFile<ITradeAgreementExtraData>;
}

export interface ITradeAgreementExtraData {
    approvalStatus: TradeAgreementStatus;
    dateApproved: string;
    nameApproved: string;
}

export enum TradeAgreementStatus {
    NoRequestMade = CommonConstants.EmptyInteger,
    Pending = 0,
    Accepted = 1,
    Declined = 2,
    ManuallyAcceptedByBuilder = 3,
}

export interface ICertificateRequest {
    certificateType: CertificateType;
}

export enum SubPostSaveSteps {
    None = 0,
    PermissionWizard = 1,
    LinkToAccounting = 2,
}

export enum SubStatus {
    Disabled = 0,
    Active = 1,
    Pending = 2,
    NoEmail = 3,
    Ready = 4,
    Frozen = 6,
    Inactive = 7,
}

export enum SubTabKeys {
    AdditionalInfo = "1",
    Notifications = "2",
    JobAccess = "3",
    Accounting = "4",
    TradeAgreement = "5",
}
