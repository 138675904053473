import { message } from "antd";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import { EmailFolder, ReplyType } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { setLoadingAction } from "utilities/form/form";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import {
    deleteSearchEntity,
    SearchCategory,
} from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.api.types";

import {
    IMessageDetailsHandler,
    MessageDetailsHandler,
} from "entity/message/common/Message.api.handler";
import {
    MessageDetailsEntity,
    MessageDetailsFolder,
    MessageDetailsFormActions,
} from "entity/message/common/Message.api.types";
import { MessageDetailsPresentational } from "entity/message/Message/MessagePresentational";
import { getToDoCreationDataForMessage } from "entity/toDo/common/toDoCreation.utilities";
import { IToDoCreationData } from "entity/toDo/details/ToDoDetails/ToDo.types";

export interface IMessageDetailsProps extends RouteComponentProps {
    handler?: IMessageDetailsHandler;
    messageId: number;
    folderId?: number;
    modalConfig?: IModalConfiguration;
    backButton?: React.ReactNode;
    onMessageRemovedFromFolder?: () => void;
}

interface IMessageDetailsState {
    messageDetails?: MessageDetailsEntity;
    currentFolder?: MessageDetailsFolder;
    actionBeingPerformed: MessageDetailsFormActions;
    todoCreationData?: IToDoCreationData;
}

class MessageDetailsInternal extends Component<IMessageDetailsProps, IMessageDetailsState> {
    static defaultProps = {
        handler: new MessageDetailsHandler(),
    };

    state: Readonly<IMessageDetailsState> = {
        actionBeingPerformed: undefined,
    };

    componentDidMount = async () => {
        try {
            const messageDetails = await this.props.handler!.get(
                this.props.messageId,
                this.props.folderId
            );
            const currentFolder = messageDetails.folder;
            this.setState({
                messageDetails,
                currentFolder,
            });
            const todoCreationData = getToDoCreationDataForMessage(
                this.state.messageDetails?.emailMessageItem
            );
            if (todoCreationData) {
                this.setState({ todoCreationData });
            }
        } catch (e) {
            this.setState(() => {
                throw e;
            });
        }
    };

    /**
     * Handles moving the current message to a different folder
     */
    private handleMoveMessage = async (
        messageIds: number[],
        sourceFolderId: number,
        destFolderId: number
    ) => {
        await setLoadingAction<MessageDetailsFormActions>(this, {
            actionBeingPerformed: "move",
            callback: async () => {
                try {
                    // No need to move a message to the folder it's already in
                    if (sourceFolderId !== destFolderId) {
                        await this.props.handler!.move(messageIds, sourceFolderId, destFolderId);
                    }
                    void message.success("Message moved successfully");
                    if (this.props.onMessageRemovedFromFolder) {
                        this.props.onMessageRemovedFromFolder();
                    } else {
                        // eslint-disable-next-line deprecate/member-expression
                        routesWebforms.closeModal();
                    }
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    /**
     * Function that toggles the current message's read status between read and unread
     *
     * @param {number[]} messageIds - List of messageIds to mark
     */
    private handleMarkReadOrUnread = async (messageIds: number[], unread: boolean) => {
        await setLoadingAction<MessageDetailsFormActions>(this, {
            actionBeingPerformed: "markAsUnread",
            callback: async () => {
                try {
                    // We may eventually do something with the return value in the larger parent component
                    // Marking read or unread returns a numberOfRead and destNumberOfUnread, so the parent
                    // May pass down a function to update that value on the folders
                    await this.props.handler!.markAsReadOrUnread(messageIds, unread);
                    void message.success("Message marked as unread");
                    // eslint-disable-next-line deprecate/member-expression
                    routesWebforms.closeModal();
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    /**
     * Function that handles populating compose message modal when replying, forwarding, and reply all
     */
    private handleReplyForwardMessage = async (
        jobId: number,
        replyId: number,
        title: string,
        replyType: ReplyType
    ) => {
        await setLoadingAction<MessageDetailsFormActions>(this, {
            actionBeingPerformed: "replyOrForward",
            callback: async () => {
                try {
                    // eslint-disable-next-line deprecate/member-expression
                    routesWebforms.setModalTitle(title);
                    await this.props.handler!.create(jobId, replyId, replyType);
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    /**
     * Function to delete the current message
     *
     * @param {number[]} messageIds - List of messageIds to delete
     * @param {number} folderId - The id of the folder where the message currently resides
     */
    private handleDeleteMessage = async (messageIds: number[], folderId: number) => {
        await setLoadingAction<MessageDetailsFormActions>(this, {
            actionBeingPerformed: "delete",
            callback: async () => {
                try {
                    await this.props.handler!.delete(messageIds, folderId);
                    if (
                        [EmailFolder.Drafts, EmailFolder.Trash, EmailFolder.Sent].includes(folderId)
                    ) {
                        void message.success("Message deleted");
                    } else {
                        void message.success("Message moved to Trash");
                    }

                    if (this.props.onMessageRemovedFromFolder) {
                        this.props.onMessageRemovedFromFolder();
                    } else {
                        // eslint-disable-next-line deprecate/member-expression
                        routesWebforms.closeModal();
                    }
                    deleteSearchEntity(SearchCategory.Messages, ...messageIds);
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    render() {
        if (!this.state.messageDetails || !this.state.currentFolder) {
            return <BTLoading instant />;
        }

        const isGlobal = this.state.messageDetails.folderId === EmailFolder.Global;
        const isSent = this.state.messageDetails.folderId === EmailFolder.Sent;

        const component = (
            <MessageDetailsPresentational
                entity={this.state.messageDetails}
                onMoveMessage={this.handleMoveMessage}
                onMarkReadOrUnread={this.handleMarkReadOrUnread}
                onReplyForwardMessage={this.handleReplyForwardMessage}
                onDeleteMessage={this.handleDeleteMessage}
                actionBeingPerformed={this.state.actionBeingPerformed}
                currentFolder={this.state.currentFolder}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
                isGlobal={isGlobal}
                isSent={isSent}
                backButton={this.props.backButton}
                modalConfig={this.props.modalConfig}
                todoCreationData={this.state.todoCreationData}
                onTodoSave={() => this.setState({ todoCreationData: undefined })}
            />
        );

        if (this.props.modalConfig) {
            return (
                <BTModal
                    data-testid="btModalMessageView"
                    visible
                    title="View Message"
                    width="850px"
                    removeBodyPadding
                    useModalLayout
                    beforeClose={this.props.modalConfig.beforeClose}
                >
                    {component}
                </BTModal>
            );
        }

        return component;
    }
}

export const MessageDetails = withErrorBoundary(MessageDetailsInternal)(
    "Could not load Message Details"
);
export default MessageDetails;
