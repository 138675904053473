import { FunctionComponent } from "react";
import { IBaseSlide, useLightboxState } from "yet-another-react-lightbox";

import { BTSocialMediaButtons } from "commonComponents/btWrappers/BTSocialMediaButtons/SocialMediaButtons";

interface ILightboxSocialMediaButtons {
    index: number;
    slide: IBaseSlide;
}

export const LightboxSocialMediaButtons: FunctionComponent<ILightboxSocialMediaButtons> = ({
    index,
    slide,
}) => {
    const { currentIndex } = useLightboxState();

    if (index !== currentIndex) {
        return null;
    }

    return (
        <BTSocialMediaButtons
            mediaInfo={{
                documentInstanceId: slide.metadata?.mediaId,
                jobId: slide.metadata?.jobId,
            }}
        />
    );
};
