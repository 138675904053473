import { unwrap } from "legacyComponents/BaseKnockoutWrapper";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { I360PhotoSlide, IBaseSlide, IVideoSlide, IVimeoSlide } from "yet-another-react-lightbox";
import { Slide } from "yet-another-react-lightbox";

import { FileStatus, FolderTypes, MediaType } from "types/enum";

import { isNullOrUndefined } from "utilities/object/object";

import { IMediaData } from "commonComponents/utilities/MediaModal/common/types";

import { isPhotoFileType } from "entity/media/common/media.utils";

export const isVimeoVideoSlide = (slide: Slide): slide is IVimeoSlide =>
    slide.type === "custom-video";
export const is360PhotoSlide = (slide: Slide): slide is I360PhotoSlide =>
    slide.type === "custom-360-photo";

export const canViewBTPhotoInLightbox = (file: BTFileSystem) =>
    file.isPhoto ||
    file.mediaType === MediaType.Photo ||
    (file.extension && isPhotoFileType(file.extension)) ||
    file.is360Media;

export const canViewBTVideoInLightbox = (file: BTFileSystem) => {
    const nativeFile = unwrap(file.nativeFile);
    if (file.mediaType === MediaType.Video && nativeFile !== undefined) {
        return true;
    } else if (
        file.mediaType === MediaType.Video &&
        file.videoId !== undefined &&
        file.fileStatus === FileStatus.Available
    ) {
        return true;
    } else {
        return false;
    }
};

export const canViewBTFileSystemInLightbox = (file: BTFileSystem) => {
    if (!isNullOrUndefined(file.folderType) && file.folderType !== FolderTypes.NotAFolder) {
        return false;
    }

    return canViewBTPhotoInLightbox(file) || canViewBTVideoInLightbox(file);
};

export const btFileSystemToSlide = (file: BTFileSystem): IBaseSlide => {
    if (file.is360Media) {
        return {
            type: "custom-360-photo",
            metadata: {
                mediaId: file.id,
            },
            src: file.docPath,
            title: file.title,
            download: {
                url: file.docPath,
                filename: file.title,
            },
        };
    }

    if (file.mediaType === MediaType.Video) {
        // Function version is needed because the CFV converts to observable.
        // We should be able to remove the function version of 'nativeFile' with the CFV.
        const nativeFile = unwrap(file.nativeFile);
        if (nativeFile) {
            const blobUrl = URL.createObjectURL(nativeFile);
            const slide: IVideoSlide = {
                type: "video",
                metadata: {
                    mediaId: file.id ?? file.videoId,
                    jobId: file.jobsiteId,
                },
                sources: [
                    {
                        src: blobUrl,
                        type: nativeFile.type,
                    },
                    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
                    // Attempt to play the video if the format is not supported
                    // eg. video/quicktime in Chrome
                    {
                        src: blobUrl,
                        type: "",
                    },
                ],
                src: blobUrl,
                title: file.fileName,
                download: FileStatus.Available === file.fileStatus,
                autoPlay: true,
            };
            return slide;
        }

        const slide: IVimeoSlide = {
            type: "custom-video",
            metadata: {
                mediaId: file.id,
                jobId: file.jobsiteId,
            },
            vimeoId: file.videoId,
            src: file.docPath,
            title: file.title,
            download: FileStatus.Available === file.fileStatus,
        };
        return slide;
    }

    return {
        type: "image",
        metadata: {
            mediaId: file.id,
        },
        src: file.hasAnnotations ? file.annotatedDocPath : file.docPath,
        title: file.title,
        download: {
            url: file.hasAnnotations ? file.annotatedDocPath : file.docPath,
            filename: file.title,
        },
    };
};

export const mediaDataToSlide = (data: IMediaData): IBaseSlide => {
    if (data.type === "image360") {
        return {
            type: "custom-360-photo",
            metadata: {
                mediaId: data.mediaInfo?.documentInstanceId,
                jobId: data.mediaInfo?.jobId,
            },
            src: data.source,
            title: data.fileName,
            download: {
                url: data.source,
                filename: data.fileName,
            },
        };
    }

    if (data.type === "video") {
        const slide: IVimeoSlide = {
            type: "custom-video",
            metadata: {
                mediaId: data.mediaInfo?.documentInstanceId,
                jobId: data.mediaInfo?.jobId,
            },
            vimeoId: data.source,
            src: data.source,
            title: data.fileName,
        };

        return slide;
    }

    return {
        type: "image",
        metadata: {
            mediaId: data.mediaInfo?.documentInstanceId,
            jobId: data.mediaInfo?.jobId,
        },
        src: data.sourceWithDrawings ?? data.source,
        title: data.fileName,
        download: {
            url: data.sourceWithDrawings ?? data.source,
            filename: data.fileName,
        },
    };
};

export const shouldAutoplayVideo = (offset: number, isSlideshowPlaying: boolean) =>
    !isSlideshowPlaying && offset === 0;

export const cfvSortOrder = (a: IBaseSlide, b: IBaseSlide) => {
    return (b.metadata?.mediaId ?? 0) - (a.metadata?.mediaId ?? 0);
};

export const isShareableMedia = (slide: IBaseSlide) =>
    (slide.type === "image" || slide.type === "custom-360-photo") &&
    slide.metadata?.jobId !== undefined &&
    slide.metadata?.mediaId !== undefined;
