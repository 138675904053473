import { Steps } from "antd";

interface IBTStepsProps {
    className?: string;
    current?: number;
    direction?: "horizontal" | "vertical";
    size?: "default" | "small";
    status?: "wait" | "process" | "finish" | "error";
    style?: React.CSSProperties;
    "data-testid"?: string;
    responsive?: boolean;
    onChange?: ((current: number) => void) | undefined;
}

export const BTSteps: React.FunctionComponent<IBTStepsProps> = ({
    className,
    current,
    direction,
    size,
    status,
    style,
    responsive,
    onChange,
    ...props
}) => {
    const { children, ...rest } = props;

    return (
        // eslint-disable-next-line react/forbid-elements
        <Steps
            {...rest}
            className={className}
            current={current}
            direction={direction}
            size={size}
            status={status}
            style={style}
            responsive={responsive}
            onChange={onChange}
        >
            {children}
        </Steps>
    );
};
