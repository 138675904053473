import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { Upload } from "tus-js-client";

import { FolderTypes, MediaType } from "types/enum";

import { APIHandler } from "utilities/apiHandler";
import { IFileUploadUtilProps } from "utilities/document/fileUpload.utils";
import { getFileExtension } from "utilities/file/file";
import {
    VideoUploadTicket,
    VideoUploadTicketResponse,
} from "utilities/video/videoUpload.api.types";

export interface IVideoUploadHandler {
    upload(props: IFileUploadUtilProps): Promise<BTFileSystem>;
}

export class VideoUploadHandler implements IVideoUploadHandler {
    async upload(props: IFileUploadUtilProps): Promise<BTFileSystem> {
        const ticket = (await this.prepareUpload(props)).ticket;
        if (!ticket || !ticket.videoId) {
            const errorMessage =
                ticket && ticket.errorMessage ? ticket.errorMessage : "Video upload failed.";
            throw new Error(errorMessage);
        }

        await this.doTusUpload(props, ticket);
        return new BTFileSystem({
            videoId: ticket.videoId,
            isTempFile: true,
            extension: getFileExtension(props.file.name),
            title: props.file.name,
            docPath: ticket.completeUrl,
            mediaType: MediaType.Video,
            jobsiteId: props.jobId,
            fileSize: props.file.size,
            folderType: FolderTypes.NotAFolder,
        });
    }

    async prepareUpload(props: IFileUploadUtilProps): Promise<VideoUploadTicketResponse> {
        const data = {
            name: props.file.name,
            size: props.file.size,
        };
        return await APIHandler(`/api/videos/GetResumableTicket`, {
            method: "GET",
            responseType: VideoUploadTicketResponse,
            data,
        });
    }

    private doTusUpload(props: IFileUploadUtilProps, ticket: VideoUploadTicket) {
        return new Promise((resolve, reject) => {
            const upFile = props.file;
            const tusUpload = new Upload(upFile, {
                endpoint: "", // endpoint to create ticket should be empty because ticket is already created
                uploadUrl: ticket.uploadUrl,
                metadata: {
                    filename: upFile.name,
                    filetype: upFile.type,
                },
                onError: function (error) {
                    reject(error);
                },
                onProgress: props.uploadConfig.shouldSuppressProgressEvents
                    ? undefined
                    : function (bytesUploaded, bytesTotal) {
                          props.uploadProgress(
                              new ProgressEvent("progress", {
                                  lengthComputable: true,
                                  loaded: bytesUploaded,
                                  total: bytesTotal,
                              })
                          );
                      },
                onSuccess: function () {
                    resolve(true);
                },
            });

            tusUpload.start();
        });
    }
}
