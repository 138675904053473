import { UploadFile } from "antd/lib/upload/interface";
import { IExternalServiceInfo } from "legacyComponents/FileUploadContainer";
import {
    AttachedFilesRequest,
    BTFileSystem,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";

import { DocumentInstanceType, MediaType } from "types/enum";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { IFileUploadHandler } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.handler";
import type { default as BTFileUploadJson } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.json";
import type { default as BTFileUploadPhotoRespJson } from "commonComponents/entity/media/BTFileUpload/BTFileUploadPhotoResp.api.json";
import { MediaViewingPermissionsEntity } from "commonComponents/entity/media/MediaViewingPermissionsInternal/MediaViewingPermissionsInternal.api.types";

type BTFileUploadApiData = typeof BTFileUploadJson;
type BTFileUploadPhotoRespApiData = typeof BTFileUploadPhotoRespJson;
type PhotoTempFileData = BTFileUploadPhotoRespApiData["failedUploads"][number];

export type BTFileUploadAction = "save" | "discard" | undefined;

export enum BTFileUploadMode {
    Standalone = 1,
    Attachments = 2,
}

interface IBTFileUploadProps {
    mediaType: MediaType;
    isTemplateMode: boolean;
    /**
     * @default ["*"]
        By default, the uploader operates using a blacklist method. Specifiy allowed file types to only allow white listed files 
    */
    allowedFileTypes?: string[];
    fileList: File[];
    modalConfig?: IModalConfiguration;
    handler?: IFileUploadHandler;
    externalServiceInfo?: IExternalServiceInfo;
    isExternal?: boolean;
}

export interface IBTFileUploadStandaloneProps extends IBTFileUploadProps {
    jobId: number;
    folderId: number;
    uploadMode: BTFileUploadMode.Standalone;
}

export interface IBTFileUploadAttachmentsProps extends IBTFileUploadProps {
    jobId?: number;
    uploadMode: BTFileUploadMode.Attachments;
    entity: IHasAttachedFiles;
    onFilesConfirmed?: (fileList: BTFileSystem[]) => void;
}

export interface IBTFileUploadFormValues {
    fileList: UploadFile<BTFileSystem>[];
    internalUserIds: number[];
    subIds: number[];
    ownerIds: number[];
    showBuilder: boolean;
    showSubs: boolean;
    showOwner: boolean;
    notifications: {
        notifyInternalUsers: boolean;
        notifySubs: boolean;
        notifyOwner: boolean;
    };
    comments: string;
}

export class BTFileUploadEntity {
    constructor(data: BTFileUploadApiData) {
        this.folderName = data.folderName;
        this.viewingPermissions = data.viewingPermissions
            ? new MediaViewingPermissionsEntity(data.viewingPermissions)
            : undefined;
    }

    folderName: string | null;
    viewingPermissions?: MediaViewingPermissionsEntity;
}

export class BTFileUploadDocsRequest {
    constructor(folderId: number, values: IBTFileUploadFormValues) {
        this.folderId = folderId;
        this.showLogins = values.internalUserIds;
        this.showSubs = values.subIds;
        this.showOwner = values.showOwner;
        this.notifyLogins = values.notifications.notifyInternalUsers;
        this.notifySubs = values.notifications.notifySubs;
        this.notifyOwner = values.notifications.notifyOwner;
        this.notificationComments = values.comments;
        this.documentUploads = values.fileList
            .filter((file) => file.status === "done")
            .map((file) => new FileUploadModel(file));
    }

    folderId: number;
    showLogins: number[];
    showSubs: number[];
    showOwner: boolean;
    notifyLogins: boolean;
    notifySubs: boolean;
    notifyOwner: boolean;
    notificationComments: string;
    documentUploads: FileUploadModel[];
}

export class BTFileUploadPhotosRequest {
    constructor(folderId: number, values: IBTFileUploadFormValues) {
        this.albumId = folderId;
        this.showSubs = values.showSubs;
        this.showOwner = values.showOwner;
        this.notifyLogins = values.notifications.notifyInternalUsers;
        this.notifySubs = values.notifications.notifySubs;
        this.notifyOwner = values.notifications.notifyOwner;
        this.notificationComments = values.comments;
        this.photoUploads = values.fileList
            .filter((file) => file.status === "done")
            .map((file) => new FileUploadModel(file));
    }

    albumId: number;
    showSubs: boolean;
    showOwner: boolean;
    notifyLogins: boolean;
    notifySubs: boolean;
    notifyOwner: boolean;
    notificationComments: string;
    photoUploads: FileUploadModel[];
}

export class BTFileUploadPhotosResponse {
    constructor(data: BTFileUploadPhotoRespApiData) {
        this.failedUploads = data.failedUploads.map((u) => new PhotoTempFile(u));
    }

    failedUploads: PhotoTempFile[];
}

export class PhotoTempFile {
    constructor(data: PhotoTempFileData) {
        this.tempId = data.tempId;
    }

    tempId: number;
}

/** Base request object for getting an existing vimeo video into BT */
class VideoRequestBase {
    constructor(
        fileSize: number,
        thumbnailUrl: string,
        videoDescription: string,
        videoId: number,
        videoName: string
    ) {
        this.fileSize = fileSize;
        this.thumbnailUrl = thumbnailUrl;
        this.videoDescription = videoDescription;
        this.videoId = videoId;
        this.videoName = videoName;
    }

    fileSize: number;
    thumbnailUrl: string;
    videoDescription: string;
    videoId: number;
    videoName: string;
}

/** Object representing a request to attach a vimeo video to an entity */
export class AttachVideoRequest extends VideoRequestBase {
    constructor(data: {
        entityId: number;
        linkedType: DocumentInstanceType;
        videoId: number;
        videoName: string;
        jobId: number | undefined;
        showSubs: boolean;
        showOwner: boolean;
        notifyBuilder: boolean;
        notifySubs: boolean;
        notifyOwner: boolean;
        videoDescription: string;
        fileSize: number;
        thumbnailUrl: string;
    }) {
        super(
            data.fileSize,
            data.thumbnailUrl,
            data.videoDescription,
            data.videoId,
            data.videoName
        );

        this.entityId = data.entityId;
        this.linkedType = data.linkedType;
        this.videoLocation = data.videoId.toString();
        this.jobId = data.jobId;
        this.showSubs = data.showSubs;
        this.showOwner = data.showOwner;
        this.notifySubs = data.notifySubs;
        this.notifyOwner = data.notifyOwner;
        this.notifyBuilder = data.notifyBuilder;
        this.friendlyName = data.videoName;
    }

    entityId: number;
    linkedType: DocumentInstanceType;
    videoLocation: string;
    jobId?: number;
    showSubs: boolean;
    showOwner: boolean;
    notifySubs: boolean;
    notifyOwner: boolean;
    notifyBuilder: boolean;
    friendlyName: string;
}

export class CreateVideoRequest extends VideoRequestBase {
    constructor(
        folderId: number,
        internalUsers: number[],
        subs: number[],
        showOwner: boolean,
        notifyInternalUsers: boolean,
        notifyOwner: boolean,
        notifySubs: boolean,
        comments: string,
        video: UploadFile<BTFileSystem>
    ) {
        super(
            video.size ?? 0,
            video.response?.thumbnail ?? "",
            "", // videoDescription
            video.response?.videoId ?? -1,
            video.response?.title ?? "untitled"
        );

        this.folderId = folderId;
        this.showLogins = internalUsers;
        this.showSubs = subs;
        this.showOwner = showOwner;
        this.notifyLogins = notifyInternalUsers;
        this.notifySubs = notifySubs;
        this.notifyOwner = notifyOwner;
        this.notificationComments = comments;
        this.videoId = video.response?.videoId ?? -1;
    }

    folderId: number;
    notificationComments: string;
    notifyLogins: boolean;
    notifySubs: boolean;
    notifyOwner: boolean;
    showLogins: number[];
    showOwner: boolean;
    showSubs: number[];
}

class FileUploadModel {
    constructor(file: UploadFile<BTFileSystem>) {
        this.tempId = file.response?.id ?? 0;
        this.fileName = file.response?.title ?? "untitled";
        this.ext = file.response?.extension ?? "";
        this.description = "";
        this.dateTaken = file.response?.dateTaken;
        this.is360Media = file.response?.is360Media;
    }

    tempId: number;
    fileName: string;
    ext: string;
    description: string;
    dateTaken?: moment.Moment;
    is360Media?: boolean;
}

export class EntityDocsRequest {
    constructor(
        builderId: number,
        entityId: number[],
        jobId: number | null,
        documentType: DocumentInstanceType,
        notifyBuilder: boolean,
        notifyOwner: boolean,
        notifySubs: boolean,
        attachDocs: BTFileSystem[]
    ) {
        this.builderId = builderId;
        this.jobId = jobId;
        this.id = entityId;
        this.documentType = documentType;
        this.notifyBuilder = notifyBuilder;
        this.notifyOwner = notifyOwner;
        this.notifySubs = notifySubs;
        this.attachedFiles = new AttachedFilesRequest({
            attachDocs: attachDocs,
        });
    }

    builderId: number;
    jobId: number | null;
    id: number[];
    documentType: DocumentInstanceType;
    notifyBuilder: boolean;
    notifyOwner: boolean;
    notifySubs: boolean;
    attachedFiles: AttachedFilesRequest;
}

export class EntityDocsResponse {
    constructor(data: any) {
        this.listOfAttachedFiles = data.listOfAttachedFiles.map((f: any) => new BTFileSystem(f));
    }

    listOfAttachedFiles: BTFileSystem[];
}
