import { RouteComponentProps } from "react-router";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { RouteJob } from "commonComponents/entity/job/RouteJob/RouteJob";
import { RouteMediaProperties } from "commonComponents/entity/media/MediaProperties/RouteMediaProperties";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { RouteAllowance } from "entity/allowance/Allowance/RouteAllowance";
import { RouteBid } from "entity/bid/Bid/BidRoute";
import { RouteBidPackage } from "entity/bidPackage/BidPackage/BidPackageRoute";
import { RouteBill } from "entity/bill/Bill/RouteBill";
import { RouteChangeOrder } from "entity/changeOrder/ChangeOrder/ChangeOrderRoute";
import { RouteContact } from "entity/contact/Contact/RouteContact";
import { RouteDailyLog } from "entity/dailyLog/DailyLog/DailyLogRoute";
import { RouteInternalUser } from "entity/internalUser/InternalUserDetails/RouteInternalUser";
import { RouteLead } from "entity/lead/Lead/LeadRoute";
import { RouteLeadProposal } from "entity/leadProposal/LeadProposal/LeadProposalRoute";
import { RouteMessages } from "entity/message/Message/RouteMessage";
import { RouteMessageCompose } from "entity/message/MessageCompose/MessageComposeRoute";
import { RouteOwnerInvoice } from "entity/ownerInvoice/OwnerInvoice/OwnerInvoiceRoute";
import { RoutePurchaseOrder } from "entity/purchaseOrder/PurchaseOrder/PurchaseOrderRoute";
import { RouteRfi } from "entity/rfi/Rfi/RfiRoute";
import { RouteSchedule } from "entity/schedule/Schedule/RouteSchedule";
import { RouteSelection } from "entity/selection/Selection/SelectionRoute";
import { RouteSubVendor } from "entity/sub/SubVendor/RouteSubVendor";
import { RouteToDo } from "entity/toDo/details/ToDoDetails/RouteToDo";
import { RouteWarranty } from "entity/warranty/Warranty/RouteWarranty";

export const SearchBarRoutes: React.FunctionComponent<RouteComponentProps> = (props) => {
    const modalConfig: IModalConfiguration = {
        beforeClose: () => props.history.replace(props.match.url),
        parentRoute: props.match.url + "/SearchResult",
    };

    return (
        <RouteRelative
            path="/SearchResult"
            render={() => (
                <>
                    <RouteToDo modalConfig={modalConfig} />
                    <RouteContact modalConfig={modalConfig} />
                    <RouteChangeOrder modalConfig={modalConfig} />
                    <RoutePurchaseOrder modalConfig={modalConfig} />
                    <RouteBill modalConfig={modalConfig} />
                    <RouteSubVendor modalConfig={modalConfig} fromSearchResults />
                    <RouteInternalUser modalConfig={modalConfig} />
                    <RouteDailyLog modalConfig={modalConfig} />
                    <RouteOwnerInvoice modalConfig={modalConfig} />
                    <RouteMessageCompose isDraft modalConfig={modalConfig} />
                    <RouteMessages modalConfig={modalConfig} />
                    <RouteSchedule modalConfig={modalConfig} />
                    <RouteWarranty modalConfig={modalConfig} />
                    <RouteBidPackage modalConfig={modalConfig} />
                    <RouteBid modalConfig={modalConfig} />
                    <RouteLead modalConfig={modalConfig} />
                    <RouteLeadProposal modalConfig={modalConfig} />
                    <RouteSelection modalConfig={modalConfig} />
                    <RouteRfi modalConfig={modalConfig} />
                    <RouteAllowance modalConfig={modalConfig} />
                    <RouteMediaProperties modalConfig={modalConfig} />
                    <RouteJob
                        history={props.history}
                        parentRoute={modalConfig.parentRoute}
                        beforeClose={async () => modalConfig.beforeClose()}
                    />
                </>
            )}
        />
    );
};
