import type { default as ZohoDocumentBrowserInfoJson } from "commonComponents/entity/media/Zoho/ZohoDocumentBrowserInfo.api.json";

export interface IViewDocumentInBrowserRequest {
    /**
     * DocumentInstanceId
     */
    docId: number;

    checkToCopy?: boolean;
    forceNewCopy?: boolean;
}

export class ZohoDocumentBrowserInfo {
    constructor(data: typeof ZohoDocumentBrowserInfoJson) {
        this.openInEditPopup = data.openInEditPopup;
        this.urlForDoc = data.urlForDoc;
        this.usingWord = data.usingWord;
        this.zohoDocumentDeleteUrl = data.zohoDocumentDeleteUrl;
        this.zohoSessionDeleteUrl = data.zohoSessionDeleteUrl;
    }

    openInEditPopup: boolean;
    urlForDoc: string;
    usingWord: boolean;
    zohoSessionDeleteUrl: string;
    zohoDocumentDeleteUrl: string;
}
