import { BTSelectItem, IValidator } from "types/apiResponse/apiResponse";
import { Countries } from "types/countries";

import { isNullOrWhitespace } from "utilities/string/string";

import { IMapPosition, PinTypes } from "commonComponents/entity/map/Map.types";

import { IBTServiceGlobalAddressItem } from "handlers";

import type { default as addressApiExample } from "./json/Address.api.json";
import type { default as addressCountryChangedAU } from "./json/AddressCountrySwapAU.api.json";
import type { default as addressCountryChangedCA } from "./json/AddressCountrySwapCA.api.json";
import type { default as addressCountryChangedGB } from "./json/AddressCountrySwapGB.api.json";
import type { default as addressCountryChangedNZ } from "./json/AddressCountrySwapNZ.api.json";
import type { default as addressCountryChangedUS } from "./json/AddressCountrySwapUS.api.json";
import type { default as addressNoDropdowns } from "./json/AddressNoDropdowns.api.json";

type AddressApiData =
    | (typeof addressApiExample)["address"]
    | (typeof addressNoDropdowns)["data"]
    | (typeof addressCountryChangedAU)["data"]
    | (typeof addressCountryChangedCA)["data"]
    | (typeof addressCountryChangedGB)["data"]
    | (typeof addressCountryChangedNZ)["data"]
    | (typeof addressCountryChangedUS)["data"];

export class AddressFormValues {
    constructor(data: any) {
        this.street = getValueFromId("street", data);
        this.city = getValueFromId("city", data);
        this.suburb = getValueFromId("suburb", data);
        this.state = getValueFromId("state", data);
        this.zip = getValueFromId("zip", data);
        this.stateId = data.address.stateDropdownId;
        this.countryId = data.address.countryDropdownId;
        this.location = data.address.location;
    }

    street: string | null;
    suburb: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    location: LocationEntity | undefined;
    stateId: number;
    countryId: number;
}

export function getCountryFromDropdown(id: number, data: IHasAddress) {
    const selectedCountry =
        data.address.countryDropdown!.find((item) => parseInt(item.id) === id) || null;
    return typeof selectedCountry === "undefined" ||
        typeof selectedCountry!.extraData === "undefined"
        ? "US"
        : selectedCountry!.extraData.countryCode;
}

export function getValueFromId(id: keyof AddressFormValues, data: IHasAddress) {
    const item = data.address.fields.find((item) => item.id === id) || null;
    return item ? item.value : null;
}

export function getStateAbbrevFromDropdown(id: number, data: IHasAddress) {
    if (data.address.stateDropdown !== null) {
        const item = data.address.stateDropdown.find((item) => parseInt(item.id) === id) || null;
        return item?.extraData!.stateAbbr ?? null;
    } else {
        return getValueFromId("state", data);
    }
}

export function getStateAbbreviationFromDropdown(id: number, address: AddressEntity) {
    const item = address.stateDropdown!.find((item) => parseInt(item.id) === id) || null;
    return item?.extraData!.stateAbbr ?? null;
}

export function getFieldFromAddressEntity(address: AddressEntity, fieldName: string) {
    const fieldValue = address.fields.find((x) => x.id === fieldName);
    return fieldValue?.value ?? null;
}

export function getValueFromAddressEntity(id: keyof AddressFormValues, data: AddressEntity) {
    const item = data.fields.find((item) => item.id === id) || null;
    return item ? item.value : null;
}

export function getAddressFormValuesFromAddressEntity(data: AddressEntity) {
    const item: AddressFormValues = {
        street: getValueFromAddressEntity("street", data),
        city: getValueFromAddressEntity("city", data),
        suburb: getValueFromAddressEntity("suburb", data),
        state: getValueFromAddressEntity("state", data),
        zip: getValueFromAddressEntity("zip", data),
        stateId: data.stateDropdownId,
        countryId: data.countryDropdownId,
        location: data.location,
    };
    return item;
}

export function getDefaultAddressFormValues() {
    const item: AddressFormValues = {
        street: "",
        city: "",
        suburb: "",
        state: "",
        zip: "",
        stateId: -1,
        countryId: -1,
        location: undefined,
    };
    return item;
}

export enum AddressFieldTypes {
    Street = 1,
    Suburb = 2,
    City = 3,
    State = 4,
    Zip = 5,
    Country = 6,
}

export interface IHasAddress {
    address: AddressEntity;
}

export enum EntityMappingStatus {
    Mapped = 1,
    NotMapped = 2,
    MappedManually = 3,
}

export class LocationEntity {
    constructor(data: any) {
        this.mapPosition = data.mapPosition;
        this.mappingStatus = data.mappingStatus;
        this.hasAutoCompleteLocation = false;
    }
    mapPosition: IMapPosition | null;
    mappingStatus: EntityMappingStatus;
    hasAutoCompleteLocation: boolean;
}

export class AddressEntity {
    constructor(data: AddressApiData) {
        this.layout = data.layout;
        this.fields = data.fields.map((f: any) => new AddressItem(f, data.stateDropdown != null));
        this.stateDropdown =
            data.stateDropdown != null
                ? data.stateDropdown.map((item: any) => new BTSelectItem(item))
                : null;
        this.countryDropdown =
            data.countryDropdown != null
                ? data.countryDropdown.map((item: any) => new BTSelectItem(item))
                : null;

        this.stateDropdownId = data.stateDropdownId;
        this.countryDropdownId = data.countryDropdownId;
        this.formattedAddressNoStreet = data.formattedAddressNoStreet;
        this.location = data.location ? new LocationEntity(data.location) : undefined;
        this.globalCountry = data.globalCountry;
    }

    layout: number;
    fields: AddressItem[];
    stateDropdown: BTSelectItem<StateExtraData>[] | null;
    countryDropdown: BTSelectItem<CountryExtraData>[] | null;
    stateDropdownId: number;
    countryDropdownId: number;
    formattedAddressNoStreet: string;
    location?: LocationEntity;
    globalCountry?: string | null;
}

export function mapToAddressEntity(item: IBTServiceGlobalAddressItem): AddressEntity {
    const mappedFields = item.fields.map((f) => ({
        title: f.title ?? "",
        value: f.value ?? "",
        inputType: f.inputType ?? 0,
        validators: [],
        order: f.order ?? 0,
        addressFieldType: f.addressFieldType ?? 0,
    }));

    const mappedStateDropdown = item.stateDropdown
        ? item.stateDropdown.map((d) => ({
              id: d.id ?? 0,
              name: d.name ?? "",
              secondaryName: null,
              selected: d.selected ?? false,
              extraData: {
                  stateAbbr:
                      typeof d.extraData?.stateAbbr === "string" ? d.extraData.stateAbbr : "",
              },
          }))
        : null;

    const mappedCountryDropdown = item.countryDropdown
        ? item.countryDropdown.map((d) => ({
              id: d.id ?? 0,
              name: d.name ?? "",
              secondaryName: null,
              selected: d.selected ?? false,
              extraData: {
                  countryCode:
                      typeof d.extraData?.countryCode === "string" ? d.extraData.countryCode : "",
              },
          }))
        : null;

    const mappedData: AddressApiData = {
        layout: item.layout ?? 0,
        fields: mappedFields,
        stateDropdown: mappedStateDropdown ?? [],
        countryDropdown: mappedCountryDropdown ?? [],
        stateDropdownId: item.stateDropdownId ?? 0,
        countryDropdownId: item.countryDropdownId ?? 0,
        formattedAddressNoStreet: item.formattedAddressNoStreet ?? "",
        location: null,
        globalCountry: "",
    };

    return new AddressEntity(mappedData);
}

export const UpdateAddressEntityFromFormValues = (
    existingEntity: AddressEntity,
    updatedFormData: AddressFormValues
) => {
    const newFieldValues = existingEntity.fields.map((x: AddressItem) => {
        switch (x.addressFieldType) {
            case AddressFieldTypes.City:
                return { ...x, value: updatedFormData.city };
            case AddressFieldTypes.Country:
                return { ...x, value: updatedFormData.countryId };
            case AddressFieldTypes.State:
                return {
                    ...x,
                    value: existingEntity.stateDropdown
                        ? getStateAbbreviationFromDropdown(updatedFormData.stateId, existingEntity)
                        : updatedFormData.state,
                };
            case AddressFieldTypes.Street:
                return { ...x, value: updatedFormData.street };
            case AddressFieldTypes.Suburb:
                return { ...x, value: updatedFormData.suburb };
            case AddressFieldTypes.Zip:
                return { ...x, value: updatedFormData.zip };
        }
        return x;
    });
    return { ...existingEntity, fields: newFieldValues };
};

class StateExtraData {
    stateAbbr: string;
}

class CountryExtraData {
    countryCode: string;
}

export class AddressItem {
    constructor(data: any, hasStateDropDown: boolean) {
        this.title = data.title;
        this.value = data.value;
        this.order = data.order;
        this.addressFieldType = data.addressFieldType;
        this.validators = data.validators;

        switch (this.addressFieldType) {
            case AddressFieldTypes.Street:
                this.id = "street";
                break;
            case AddressFieldTypes.Suburb:
                this.id = "suburb";
                break;
            case AddressFieldTypes.City:
                this.id = "city";
                break;
            case AddressFieldTypes.State:
                if (hasStateDropDown) {
                    this.id = "stateId";
                    break;
                } else {
                    this.id = "state";
                    break;
                }
            case AddressFieldTypes.Zip:
                this.id = "zip";
                break;
            case AddressFieldTypes.Country:
                this.id = "countryId";
                break;
            default:
                break;
        }
    }

    id: keyof AddressFormValues;
    order: number;
    title: string;
    value: string;
    addressFieldType: AddressFieldTypes;
    validators: IValidator[];
}

export interface IAddressCountryChangedRequest {
    street: string | null;
    suburb: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    country: string | null;
    supportedCountryCodes?: Countries[];
}

export interface IResolveAddressRequest {
    streetAddress: string | null;
    city: string | null;
    region: string | null;
    postalCode: string | null;
    country: string | null;
}

export type AddressServiceObject = { title: string; value: string | number }[];

export function getPinTypeForMap(location: LocationEntity) {
    switch (location.mappingStatus) {
        case EntityMappingStatus.NotMapped:
            return PinTypes.Inactive;
        case EntityMappingStatus.Mapped:
            return PinTypes.Default;
        case EntityMappingStatus.MappedManually:
            return PinTypes.Edited;
        default:
            return PinTypes.None;
    }
}
export function getBottomAddressForMapToolTip(address: AddressEntity) {
    let addedCitySub = false;
    let formattedBottomAddress = "";

    address.fields.forEach((field) => {
        if (field.id === "city" || field.id === "suburb" || field.id === "state") {
            if (!isNullOrWhitespace(field.value)) {
                if (!addedCitySub) {
                    formattedBottomAddress = field.value;
                    addedCitySub = true;
                } else {
                    formattedBottomAddress = `${formattedBottomAddress}, ${field.value}`;
                }
            }
        } else if (field.id === "zip") {
            formattedBottomAddress = `${formattedBottomAddress} ${field.value}`.trim();
        }
    });

    return formattedBottomAddress;
}

export function getBuildertrendAddress() {
    return {
        layout: 1,
        fields: [
            new AddressItem(
                {
                    title: "Street Address",
                    value: "11818 I Street",
                    inputType: 1,
                    validators: [
                        {
                            errorMessage: null,
                            value: 50,
                            type: "maxLength",
                        },
                        {
                            errorMessage: null,
                            value: false,
                            type: "readonly",
                        },
                    ],
                    order: 1,
                    addressFieldType: 1,
                },
                false
            ),
            new AddressItem(
                {
                    title: "City",
                    value: "Omaha",
                    inputType: 1,
                    validators: [
                        {
                            errorMessage: null,
                            value: 50,
                            type: "maxLength",
                        },
                        {
                            errorMessage: null,
                            value: false,
                            type: "readonly",
                        },
                    ],
                    order: 3,
                    addressFieldType: 3,
                },
                false
            ),
            new AddressItem(
                {
                    title: "State",
                    value: "NE",
                    inputType: 1,
                    validators: [
                        {
                            errorMessage: null,
                            value: 3,
                            type: "maxLength",
                        },
                        {
                            errorMessage: null,
                            value: false,
                            type: "readonly",
                        },
                    ],
                    order: 4,
                    addressFieldType: 4,
                },
                false
            ),
            new AddressItem(
                {
                    title: "Zip Code",
                    value: "68137",
                    inputType: 1,
                    validators: [
                        {
                            errorMessage: null,
                            value: 15,
                            type: "maxLength",
                        },
                        {
                            errorMessage: null,
                            value: false,
                            type: "readonly",
                        },
                    ],
                    order: 5,
                    addressFieldType: 5,
                },
                false
            ),
            new AddressItem(
                {
                    title: "Country",
                    value: "US",
                    inputType: 1,
                    validators: [
                        {
                            errorMessage: null,
                            value: 5,
                            type: "maxLength",
                        },
                        {
                            errorMessage: null,
                            value: false,
                            type: "readonly",
                        },
                    ],
                    order: -2,
                    addressFieldType: 6,
                },
                false
            ),
        ],
        countryDropdown: [
            new BTSelectItem<CountryExtraData>({
                id: 1,
                name: "United States",
                secondaryName: null,
                selected: false,
                extraData: {
                    countryCode: "US",
                },
            }),
        ],
        countryDropdownId: 1,
        stateDropdown: null,
        stateDropdownId: -1,
        formattedAddressNoStreet: "Omaha, NE 68137",
    } as AddressEntity;
}
