import { MediaType } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import { BrowseBTFilesListResponse } from "commonComponents/entity/media/BrowseBTFiles/BrowseBTFiles.types";

export interface IBrowseBTFilesHandler {
    getDocuments(
        mediaType: MediaType,
        id: number,
        selectedJobsiteID?: number,
        leadId?: number
    ): Promise<BrowseBTFilesListResponse>;
}

export class BrowseBTFilesHandler implements IBrowseBTFilesHandler {
    async getDocuments(
        mediaType: MediaType,
        id: number,
        selectedJobsiteID?: number,
        leadId?: number
    ): Promise<BrowseBTFilesListResponse> {
        const requestPath = `/api/Documents`;
        return await APIHandler(requestPath, {
            method: "GET",
            data: {
                id,
                mediaType,
                selectedJobsiteID,
                leadId,
                showUniqueDocsOnly: true,
            },
            responseType: BrowseBTFilesListResponse,
        });
    }
}
