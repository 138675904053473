import classNames from "classnames";
import { Component } from "react";

import "commonComponents/entity/media/FileDragTarget/FileDragTarget.less";

import { OSFileDragType } from "entity/media/common/mediaTypes";

interface IFileDragTargetProps {
    "data-testid"?: string;
    targetOverlayClassname?: string;
    invalidOverlayClassname?: string;
    disabled?: boolean;
    isEmptyState?: boolean;
    allowDrop: boolean;
    allowedDropTypes: string[];
    forceDragOverDropEffect?: boolean;
    /**
     * preventDefault() essentially is meant to tell the browser "this event has already been handled" https://www.w3schools.com/jsref/event_preventdefault.asp
     * This prop should ONLY be used if GlobalDnDContext is present with this component
     * Our drag and drop library, react-dnd, captures all browser dragevents and prevents default on them
     * We scope the DnD context globally. We are on an older version and cannot scope the context locally.
     * See https://github.com/react-dnd/react-dnd/issues/2995
     */
    dangerous_allowPreventedEvents?: boolean;
    onDataReceived?: (dataTransfer: DataTransfer) => void;

    render: (isTargeted: boolean) => React.ReactNode;
    children?: never;
}

interface IFileDragTargetState {
    isTargeted: boolean;
}

export class FileDragTarget extends Component<IFileDragTargetProps, IFileDragTargetState> {
    static defaultProps = {
        allowedDropTypes: [OSFileDragType],
        dangerous_allowPreventedEvents: false,
    };

    state: Readonly<IFileDragTargetState> = {
        isTargeted: false,
    };

    private dragDepth = 0;

    private getEventResponseType = (event: React.DragEvent<HTMLDivElement>) => {
        const {
            disabled,
            allowedDropTypes,
            forceDragOverDropEffect,
            dangerous_allowPreventedEvents,
        } = this.props;

        const prevented = event.defaultPrevented && !dangerous_allowPreventedEvents;
        if (disabled || (prevented && !forceDragOverDropEffect)) {
            return "none";
        }

        for (const type of allowedDropTypes) {
            if (event.dataTransfer.types.includes(type)) {
                if (!prevented) {
                    return "full";
                }
                return "none";
            }
        }

        return forceDragOverDropEffect ? "drop-effect" : "none";
    };

    private handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        const responseType = this.getEventResponseType(event);
        if (responseType !== "none") {
            event.preventDefault();

            if (!this.props.allowDrop || responseType === "drop-effect") {
                event.dataTransfer.dropEffect = "none";
            }

            if (responseType === "full") {
                if (this.dragDepth === 0) {
                    this.setState({ isTargeted: true });
                }
                this.dragDepth++;
            }
        }
    };

    private handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        const responseType = this.getEventResponseType(event);
        if (responseType !== "none") {
            event.preventDefault();

            if (!this.props.allowDrop || responseType === "drop-effect") {
                event.dataTransfer.dropEffect = "none";
            }
        }
    };

    private handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        if (this.getEventResponseType(event) === "full") {
            event.preventDefault();

            this.dragDepth--;
            if (this.dragDepth === 0) {
                this.setState({ isTargeted: false });
            }
        }
    };

    private handleFileDropIn = (event: React.DragEvent<HTMLDivElement>) => {
        if (this.dragDepth > 0) {
            this.setState({ isTargeted: false });
            this.dragDepth = 0;
        }

        if (this.getEventResponseType(event) === "full") {
            event.preventDefault();

            const { allowDrop, onDataReceived } = this.props;
            if (allowDrop && onDataReceived) {
                onDataReceived(event.dataTransfer);
            }
        }
    };

    render() {
        const {
            "data-testid": dataTestId,
            allowDrop,
            targetOverlayClassname,
            invalidOverlayClassname,
            render,
        } = this.props;
        const { isTargeted } = this.state;

        let overlay = null;
        if (isTargeted) {
            if (allowDrop) {
                overlay = (
                    <div
                        data-testid={`${dataTestId}-drag-target`}
                        className={classNames("FileDragTarget-drag-target", targetOverlayClassname)}
                    />
                );
            } else {
                overlay = (
                    <div
                        data-testid={`${dataTestId}-invalid-drag-target`}
                        className={classNames(
                            "FileDragTarget-invalid-drag-target",
                            invalidOverlayClassname
                        )}
                    />
                );
            }
        }

        return (
            <div
                data-testid={dataTestId}
                className={classNames("FileDragTarget-wrapper", {
                    "FileDragTarget-empty-state": this.props.isEmptyState,
                })}
                onDragEnter={this.handleDragEnter}
                onDragOver={this.handleDragOver}
                onDragLeave={this.handleDragLeave}
                onDrop={this.handleFileDropIn}
            >
                {render(isTargeted)}
                {overlay}
            </div>
        );
    }
}
