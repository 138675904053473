import { DetailPageLayout } from "layouts/DetailPageLayout";
import { RouteComponentProps } from "react-router-dom";

import { AppProvider } from "helpers/AppProvider";
import { AppProviderHandler, IAppProviderHandler } from "helpers/AppProvider.api.handler";
import { BTAdminUserInfoProvider } from "helpers/globalContext/btAdmin/BTAdminUserInfoProvider";

import { BTLayout } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTAdminNavigation } from "commonComponents/utilities/BTAdminNavigation/BTAdminNavigation";

interface IBTAdminLayoutProps {
    component: React.ComponentType<RouteComponentProps<any>>;
    path: string;
    appProviderHandler?: IAppProviderHandler;
}

const defaultAppProviderHandler = new AppProviderHandler();
export const BTAdminLayout: React.FC<IBTAdminLayoutProps> = ({
    component,
    path,
    appProviderHandler = defaultAppProviderHandler,
}) => {
    return (
        <AppProvider handler={appProviderHandler}>
            <BTLayout>
                <BTAdminUserInfoProvider>
                    <div>
                        <BTAdminNavigation />
                        <DetailPageLayout component={component} path={path} />
                    </div>
                </BTAdminUserInfoProvider>
            </BTLayout>
        </AppProvider>
    );
};
export default BTAdminLayout;
