import { default as AppProviderAPI } from "../../helpers/AppProvider.api.json";

class BasePermissions {
    canView: boolean = false;
    canAdd: boolean = false;
    canUpdate: boolean = false;
    canDelete: boolean = false;

    constructor(data: Partial<BasePermissions>) {
        Object.assign(this, data);
    }
}

class SummaryPermissions extends BasePermissions {}

class JobsitesPermissions extends BasePermissions {
    canSyncWithAccounting: boolean = false;
    canLoginAsOwner: boolean = false;
    canViewPrice: boolean = false;
    canViewRequestRiskInsurance: boolean = false;

    constructor(data: Partial<JobsitesPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class CalendarPermissions extends BasePermissions {
    canResetBaseline: boolean = false;

    constructor(data: Partial<CalendarPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class ChangeOrdersPermissions extends BasePermissions {
    canViewPrice: boolean = false;
    canSyncWithAccounting: boolean = false;

    constructor(data: Partial<ChangeOrdersPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class DocumentsPermissions extends BasePermissions {
    canAccessSubOwnerUploads: boolean = false;
    canRequestPdfSignature: boolean = false;

    constructor(data: Partial<DocumentsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class PhotosPermissions extends BasePermissions {}

class EmailPermissions extends BasePermissions {
    isGlobal: boolean = false;

    constructor(data: Partial<EmailPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class SubsPermissions extends BasePermissions {
    canSyncWithAccounting: boolean = false;

    constructor(data: Partial<SubsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class AdminPermissions extends BasePermissions {
    canViewReports: boolean = false;
    canSyncWithAccounting: boolean = false;

    constructor(data: Partial<AdminPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class WarrantyPermissions extends BasePermissions {}

class OptionsPermissions extends BasePermissions {}

class SelectionsPermissions extends BasePermissions {
    canViewPrice: boolean = false;

    constructor(data: Partial<SelectionsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class PurchaseOrdersPermissions extends BasePermissions {
    canManageAccounting: boolean = false;
    canOverridePriceLimit: boolean = false;
    canViewCost: boolean = false;

    constructor(data: Partial<PurchaseOrdersPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class LeadsPermissions extends BasePermissions {
    canViewAllSalespeopleLeads: boolean = false;
    canReassignSalesperson: boolean = false;
    canConvertToJobsite: boolean = false;
    canExportToExcel: boolean = false;
    canRequestProposalPayments: boolean = false;
    canProcessProposalRefunds: boolean = false;
    canOverrideProposalPayments: boolean = false;
    canViewConfidential: boolean = false;

    constructor(data: Partial<LeadsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class EstimateGeneralItemsPermissions extends BasePermissions {
    canViewPrice: boolean = false;

    constructor(data: Partial<EstimateGeneralItemsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class BiddingPermissions extends BasePermissions {}

class ToDosPermissions extends BasePermissions {
    canAssignUsers: boolean = false;
    isGlobal: boolean = false;

    constructor(data: Partial<ToDosPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class DailyLogsPermissions extends BasePermissions {}

class TimeClockPermissions extends BasePermissions {
    canViewOtherUsers: boolean = false;
    canAdjustOtherUsers: boolean = false;
    canViewCost: boolean = false;
    canReviewShift: boolean = false;
    canSendShiftToAccounting: boolean = false;

    constructor(data: Partial<TimeClockPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class SurveysPermissions extends BasePermissions {}

class EstimatesPermissions extends BasePermissions {}

class OwnerInvoicesPermissions extends BasePermissions {}

class ContactsPermissions extends BasePermissions {
    canExportToExcel: boolean = false;
    canViewAllCustomerContacts: boolean = false;

    constructor(data: Partial<ContactsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class VideosPermissions extends BasePermissions {}

class TemplatesPermissions extends BasePermissions {}

class RFIsPermissions extends BasePermissions {}

class SpecificationsPermissions extends BasePermissions {}

class InternalUsersPermissions extends BasePermissions {}

class PayrollPermissions extends BasePermissions {
    canManagePayroll: boolean = false;

    constructor(data: Partial<PayrollPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class TaxesPermissions extends BasePermissions {}

class CostCodesPermissions extends BasePermissions {}

class LeadProposalsPermissions extends BasePermissions {}

class ReceiptsPermissions extends BasePermissions {
    isGlobal: boolean = false;

    constructor(data: Partial<ReceiptsPermissions>) {
        super(data);
        Object.assign(this, data);
    }
}

class RebatesPermissions extends BasePermissions {}

class ChatPermissions extends BasePermissions {}

class JobCostingBudgetPermissions extends BasePermissions {}

class UserTabPermissions {
    summary: SummaryPermissions;
    jobsites: JobsitesPermissions;
    calendar: CalendarPermissions;
    changeOrders: ChangeOrdersPermissions;
    documents: DocumentsPermissions;
    photos: PhotosPermissions;
    email: EmailPermissions;
    subs: SubsPermissions;
    admin: AdminPermissions;
    warranty: WarrantyPermissions;
    options: OptionsPermissions;
    selections: SelectionsPermissions;
    purchaseOrders: PurchaseOrdersPermissions;
    leads: LeadsPermissions;
    estimateGeneralItems: EstimateGeneralItemsPermissions;
    bidding: BiddingPermissions;
    toDos: ToDosPermissions;
    dailyLogs: DailyLogsPermissions;
    timeClock: TimeClockPermissions;
    surveys: SurveysPermissions;
    estimates: EstimatesPermissions;
    ownerInvoices: OwnerInvoicesPermissions;
    contacts: ContactsPermissions;
    videos: VideosPermissions;
    templates: TemplatesPermissions;
    rfIs: RFIsPermissions;
    specifications: SpecificationsPermissions;
    internalUsers: InternalUsersPermissions;
    payroll: PayrollPermissions;
    taxes: TaxesPermissions;
    costCodes: CostCodesPermissions;
    leadProposals: LeadProposalsPermissions;
    receipts: ReceiptsPermissions;
    rebates: RebatesPermissions;
    chat: ChatPermissions;
    jobCostingBudget: JobCostingBudgetPermissions;

    constructor(permissionsData: typeof AppProviderAPI.userInfo.permissions) {
        this.summary = new SummaryPermissions(permissionsData.summary);
        this.jobsites = new JobsitesPermissions(permissionsData.jobsites);
        this.calendar = new CalendarPermissions(permissionsData.calendar);
        this.changeOrders = new ChangeOrdersPermissions(permissionsData.changeOrders);
        this.documents = new DocumentsPermissions(permissionsData.documents);
        this.photos = new PhotosPermissions(permissionsData.photos);
        this.email = new EmailPermissions(permissionsData.email);
        this.subs = new SubsPermissions(permissionsData.subs);
        this.admin = new AdminPermissions(permissionsData.admin);
        this.warranty = new WarrantyPermissions(permissionsData.warranty);
        this.options = new OptionsPermissions(permissionsData.options);
        this.selections = new SelectionsPermissions(permissionsData.selections);
        this.purchaseOrders = new PurchaseOrdersPermissions(permissionsData.purchaseOrders);
        this.leads = new LeadsPermissions(permissionsData.leads);
        this.estimateGeneralItems = new EstimateGeneralItemsPermissions(
            permissionsData.estimateGeneralItems
        );
        this.bidding = new BiddingPermissions(permissionsData.bidding);
        this.toDos = new ToDosPermissions(permissionsData.toDos);
        this.dailyLogs = new DailyLogsPermissions(permissionsData.dailyLogs);
        this.timeClock = new TimeClockPermissions(permissionsData.timeClock);
        this.surveys = new SurveysPermissions(permissionsData.surveys);
        this.estimates = new EstimatesPermissions(permissionsData.estimates);
        this.ownerInvoices = new OwnerInvoicesPermissions(permissionsData.ownerInvoices);
        this.contacts = new ContactsPermissions(permissionsData.contacts);
        this.videos = new VideosPermissions(permissionsData.videos);
        this.templates = new TemplatesPermissions(permissionsData.templates);
        this.rfIs = new RFIsPermissions(permissionsData.rfIs);
        this.specifications = new SpecificationsPermissions(permissionsData.specifications);
        this.internalUsers = new InternalUsersPermissions(permissionsData.internalUsers);
        this.payroll = new PayrollPermissions(permissionsData.payroll);
        this.taxes = new TaxesPermissions(permissionsData.taxes);
        this.costCodes = new CostCodesPermissions(permissionsData.costCodes);
        this.leadProposals = new LeadProposalsPermissions(permissionsData.leadProposals);
        this.receipts = new ReceiptsPermissions(permissionsData.receipts);
        this.rebates = new RebatesPermissions(permissionsData.rebates);
        this.chat = new ChatPermissions(permissionsData.chat);
        this.jobCostingBudget = new JobCostingBudgetPermissions(permissionsData.jobCostingBudget);
    }

    hasPermission<T extends keyof UserTabPermissions>(
        tab: T,
        permission: keyof UserTabPermissions[T]
    ): boolean {
        const tabPermissions = this[tab];

        if (!tabPermissions || typeof tabPermissions[permission] === "undefined") {
            return false;
        }

        return tabPermissions[permission] === true;
    }
}

export default UserTabPermissions;
