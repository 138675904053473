import update from "immutability-helper";
import {
    IFileUploadContainerProps,
    IOnEventParams,
    KnockoutWrapperFileUploadContainer,
} from "legacyComponents/FileUploadContainer";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { IBaseSlide } from "yet-another-react-lightbox";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { CommonConstants } from "types/constants";
import { MediaType } from "types/enum";

import {
    allowedAnalyticsEvents,
    ITrackingData,
    ITrackingProp,
    track,
} from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { getGlobalValue } from "utilities/globalValueUtils";
import { routes } from "utilities/routes";

import { BTLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox";
import {
    btFileSystemToSlide,
    canViewBTFileSystemInLightbox,
    cfvSortOrder,
} from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import {
    AnnotationsHandler,
    IAnnotationsHandler,
} from "commonComponents/entity/media/Annotations/Annotations.api.handler";
import { AnnotationsUrlResponse } from "commonComponents/entity/media/Annotations/Annotations.api.types";
import BTFileUpload from "commonComponents/entity/media/BTFileUpload/BTFileUpload";
import { BTFileUploadMode } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { ViewAllAttachments } from "commonComponents/entity/media/ViewAllAttachments/ViewAllAttachments";
import { MediaReplaceActions } from "commonComponents/entity/media/ViewAllAttachments/ViewAllAttachments.types";
import { ZohoHandler } from "commonComponents/entity/media/Zoho/Zoho.api.handler";
import { RouteZoho } from "commonComponents/entity/media/Zoho/ZohoRoute";
import { SharingSettingsAPI } from "commonComponents/entity/sharing/SharingContainer/SharingContainer.types";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { useStateFromProps } from "commonComponents/utilities/UseStateFromProps/UseStateFromProps";

import { IMediaListHandler, MediaListHandler } from "entity/media/common/MediaList.api.handler";
import {
    AnnotationType,
    AttachedMedia,
    DocumentInstanceUpdateType,
    IZohoSessionInfo,
} from "entity/media/common/mediaTypes";
import { RouteMediaAnnotate } from "entity/media/common/RouteMediaAnnotate";
import { RoutePhotoDraw } from "entity/media/common/RoutePhotoDraw";
import { BreakMediaLinksResponse } from "entity/media/MediaReplace/MediaReplace.api.types";
import { RouteMediaReplace } from "entity/media/MediaReplace/RouteMediaReplace";

/** Context provided by bt-file-wrapper.js Knockout. Must keep in sync there. */
export interface IUploadNewModalContext {
    confirmCallback: (listOfFilesAdded: BTFileSystem[]) => void;
}

interface IFileMetadata {
    docInstanceId: number;
    isOnTempFile?: boolean;
    annotationType?: AnnotationType;
    attachedMedia?: AttachedMedia[];
}

interface ILegacyMediaViewerProps
    extends Omit<IFileUploadContainerProps, "onCustomEvent" | "onAddNew"> {
    "data-testid"?: string;
    onAttachFilesOpen?: () => void;
    sharingSettings?: SharingSettingsAPI;
    mediaListHandler?: IMediaListHandler;
    annotationsHandler?: IAnnotationsHandler;
    isUploadingFromReact?: boolean;
    zohoHandler?: ZohoHandler;
}

const defaultMediaListHandler = new MediaListHandler();
const defaultAnnotationsHandler = new AnnotationsHandler();
const defaultZohoHandler = new ZohoHandler();

const LegacyMediaViewerInner = track({ component: "LegacyMediaViewer" })(
    ({
        entity,
        attachedFiles,
        "data-testid": dataTestId,
        onAllFileChange,
        onHasAnnotationsChanged,
        onAnnotationPathChanged,
        onThumbnailChanged,
        isAttachFilesOpen,
        onAttachFilesOpen,
        onAttachFilesClose,
        isViewAllOpen,
        onViewAllClose,
        tracking,
        externalServiceInfo,
        isOnExternalPage,
        reactViewAll,
        isReactReadOnly,
        onViewingPermissionChange,
        sharingSettings,
        mediaListHandler = defaultMediaListHandler,
        annotationsHandler = defaultAnnotationsHandler,
        isUploadingFromReact = false,
        zohoHandler = defaultZohoHandler,
        isWebview,
        ...props
    }: ILegacyMediaViewerProps & ITrackingProp) => {
        const match = useRouteMatch();
        const history = useHistory();
        const builderInfo = useContext(BuilderInfoContext);
        const userInfo = useContext(UserInfoContext);

        const [files, setFiles] = useState(entity.attachedFiles?.files ?? []);
        const [currentFile, setCurrentFile] = useState<IFileMetadata | null>(null);
        const [selectedLightboxFileId, setSelectedLightboxFileId] = useState<number | null>(null);
        const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(
            isAttachFilesOpen ?? false
        );
        const [showViewAllModal, setShowViewAllModal] = useState(isViewAllOpen ?? false);
        const [uploadNewModalContext, setUploadNewModalContext] = useState<
            IUploadNewModalContext | undefined
        >(undefined);
        const [actionBeingPerformed, setActionBeingPerformed] =
            useState<MediaReplaceActions>(undefined);
        const [zohoSessionInfo, setZohoSessionInfo] = useState<IZohoSessionInfo | undefined>(
            undefined
        );

        const [isUploadingFromReactViewAll, setIsUploadingFromReactViewAll] =
            useStateFromProps(isUploadingFromReact);

        useEffect(() => {
            if (!attachedFiles) {
                return;
            }
            setFiles(attachedFiles.files);
        }, [attachedFiles]);

        useEffect(() => {
            if (isAttachFilesOpen !== undefined) {
                setShowUploadFileModal(isAttachFilesOpen);
            }
        }, [isAttachFilesOpen]);

        useEffect(() => {
            if (isViewAllOpen !== undefined) {
                setShowViewAllModal(isViewAllOpen);
            }
        }, [isViewAllOpen]);

        const getUpdatedAnnotationUrl = async () => {
            if (!currentFile?.annotationType) {
                return;
            }
            try {
                const urlResponse = await annotationsHandler.getAnnotationUrl(
                    currentFile.docInstanceId,
                    currentFile.annotationType,
                    entity.jobId
                );
                handleAnnotationAdded(currentFile.docInstanceId, urlResponse);
            } catch (e) {
                showAPIErrorMessage(e);
            }
        };

        const handleAnnotationPathChanged = (
            id: number,
            key: "annotatedDocPath" | "previewAnnotatedDocPath",
            url: string
        ) => {
            setFiles((curFiles) =>
                update(curFiles, {
                    $apply: (files: BTFileSystem[]) =>
                        files.map((file) => {
                            if (file.id !== id) {
                                return file;
                            }

                            return update(file, {
                                [key]: {
                                    $set: url,
                                },
                            });
                        }),
                })
            );

            onAnnotationPathChanged?.(id, key, url);
        };

        const handleHasAnnotationsChanged = (id: number, hasAnnotations: boolean) => {
            setFiles((curFiles) =>
                update(curFiles, {
                    $apply: (files: BTFileSystem[]) =>
                        files.map((file) => {
                            if (file.id !== id) {
                                return file;
                            }

                            return update(file, {
                                hasAnnotations: {
                                    $set: hasAnnotations,
                                },
                            });
                        }),
                })
            );

            onHasAnnotationsChanged?.(id, hasAnnotations);
        };

        const handleAnnotationAdded = (
            docIntanceId: number,
            annotationData: AnnotationsUrlResponse
        ) => {
            const updatedFiles = update(files, {
                $apply: (files: BTFileSystem[]) =>
                    files.map((file) => {
                        if (file.id !== docIntanceId) {
                            return file;
                        }
                        return update(file, {
                            annotatedDocPath: {
                                $set: annotationData.annotatedDocPathUrl,
                            },
                            hasAnnotations: {
                                $set: annotationData.hasAnnotations,
                            },
                            annotationGroupId: {
                                $set: annotationData.annotationGroupId ?? null,
                            },
                            previewAnnotatedDocPath: {
                                $set: annotationData.previewAnnotatedDocPathUrl ?? "",
                            },
                            thumbnail: {
                                $set: annotationData.thumbnailUrl,
                            },
                        });
                    }),
            });
            onAllFileChange?.(updatedFiles);
        };

        const handleThumbnailChanged = (docInstanceId: number, thumbnailUrl: string) => {
            setFiles((curFiles) =>
                update(curFiles, {
                    $apply: (files: BTFileSystem[]) =>
                        files.map((file) => {
                            if (file.id !== docInstanceId) {
                                return file;
                            }

                            return update(file, {
                                thumbnail: {
                                    $set: thumbnailUrl,
                                },
                            });
                        }),
                })
            );
            onThumbnailChanged?.(docInstanceId, thumbnailUrl);
        };

        const handleAllFileChange = useCallback(
            (newFiles: BTFileSystem[]) => {
                const allFiles = isUploadingFromReactViewAll ? [...files, ...newFiles] : newFiles;
                if (onAllFileChange) {
                    onAllFileChange(allFiles);
                }
                setFiles(allFiles);
            },
            [onAllFileChange, files, isUploadingFromReactViewAll]
        );

        const slides = useMemo(
            () =>
                files
                    .filter(canViewBTFileSystemInLightbox)
                    .map(btFileSystemToSlide)
                    .sort(cfvSortOrder),
            [files]
        );

        const handleSlideChange = (_: number, slide: IBaseSlide) => {
            if (slide.metadata?.mediaId !== undefined) {
                setSelectedLightboxFileId(slide.metadata.mediaId);
            }
        };

        const handleCustomEvent = (event: IOnEventParams) => {
            event.preventDefault = event.data.viewModel.isPhoto || event.data.viewModel.isVideo;
            if (event.preventDefault && !event.data.isDraggingCarousel) {
                const id = event.data.viewModel.id();
                setSelectedLightboxFileId(id);
            }
        };

        const handleClickAdd = (context: IUploadNewModalContext) => {
            onAttachFilesOpen?.();
            setUploadNewModalContext(context);
            setShowUploadFileModal(true);
            tracking?.trackEvent({
                event: "ButtonClick",
                uniqueId: "addAttachment",
            });
        };

        const handleReactClickAdd = () => {
            onAttachFilesOpen?.();
            setShowUploadFileModal(true);
            setIsUploadingFromReactViewAll(true);
        };

        const handleClickViewAll = () => {
            if (reactViewAll) {
                setShowViewAllModal(true);
            }
        };

        const handleCreateNew = () => {
            tracking?.trackEvent({
                event: "ButtonClick",
                uniqueId: "createNewAttachment",
            });
        };

        const handleTrackEvent = (data: Partial<ITrackingData>) => {
            if (!data.event || !allowedAnalyticsEvents.includes(data.event)) {
                // Event not in allowed list. Ignore it.
                return;
            }
            tracking?.trackEvent(data);
        };

        const handleFilesAdded = (fileList: BTFileSystem[]) => {
            if (isUploadingFromReactViewAll) {
                handleAllFileChange(fileList);
            } else {
                uploadNewModalContext?.confirmCallback(fileList);
            }
        };

        const handleViewPermissionsUpdate = (
            id: number,
            key: "showOwner" | "showSubs",
            shouldShow: boolean
        ) => {
            if (reactViewAll && !isReactReadOnly && onViewingPermissionChange) {
                onViewingPermissionChange(id, key, shouldShow);
            }
        };

        const handleBreakDocLink = (newDocInstanceId: number, prevDocInstanceId: number) => {
            const updatedFiles = update(files, {
                $apply: (files: BTFileSystem[]) =>
                    files.map((file) => {
                        if (file.id !== prevDocInstanceId) {
                            return file;
                        }

                        return update(file, {
                            id: {
                                $set: newDocInstanceId,
                            },
                        });
                    }),
            });

            setCurrentFile({ ...currentFile, docInstanceId: newDocInstanceId } as IFileMetadata);
            onAllFileChange?.(updatedFiles);
        };

        const getLinkedDocumentInstances = useCallback(
            async (docInstanceId: number) => {
                try {
                    return await mediaListHandler.getLinkedDocumentInstances(
                        docInstanceId,
                        entity.id === 0
                    );
                } catch (e) {
                    showAPIErrorMessage(e);
                    return;
                }
            },
            [entity.id, mediaListHandler]
        );

        const handleReplace = async (
            response: BreakMediaLinksResponse,
            _updateType: DocumentInstanceUpdateType
        ) => {
            if (!currentFile || !userInfo) {
                history.replace(match.url);
                return;
            }

            handleBreakDocLink(response.documentInstanceId, currentFile.docInstanceId);
            if (actionBeingPerformed === "annotate") {
                history.push(
                    match.url +
                        routes.media.getAnnotationLink(
                            CommonConstants.EmptyInteger,
                            response.documentInstanceId,
                            userInfo.loginType,
                            entity.builderId,
                            entity.id === 0,
                            currentFile.annotationType === AnnotationType.TempFile
                        )
                );
            } else if (actionBeingPerformed === "draw") {
                history.push(
                    match.url +
                        routes.photo.getPhotoDrawLink(
                            response.documentInstanceId,
                            entity.builderId,
                            userInfo.loginType,
                            entity.id === 0,
                            currentFile.annotationType === AnnotationType.TempFile
                        )
                );
            } else if (actionBeingPerformed === "editOnline") {
                await openFileInZoho(response.documentInstanceId);
            } else {
                history.replace(match.url);
            }
        };

        const handleAnnotation = useCallback(
            async (docInstanceId: number, annotationType: AnnotationType, mediaType: MediaType) => {
                if (!userInfo) {
                    return;
                }

                const response = await getLinkedDocumentInstances(docInstanceId);
                if (response?.attachedMedia.length) {
                    setActionBeingPerformed("annotate");
                    setCurrentFile({
                        docInstanceId,
                        annotationType,
                        attachedMedia: response.attachedMedia,
                        isOnTempFile: annotationType === AnnotationType.TempFile,
                    } as IFileMetadata);

                    history.replace(
                        match.url + routes.media.getReplaceLink(docInstanceId, false, mediaType)
                    );
                } else {
                    setCurrentFile({
                        docInstanceId,
                        annotationType,
                    } as IFileMetadata);
                    history.push(
                        match.url +
                            routes.media.getAnnotationLink(
                                CommonConstants.EmptyInteger,
                                docInstanceId,
                                userInfo.loginType,
                                entity.builderId,
                                entity.id === 0,
                                annotationType === AnnotationType.TempFile
                            )
                    );
                }
            },
            [entity.builderId, entity.id, getLinkedDocumentInstances, history, match.url, userInfo]
        );

        const handleDraw = useCallback(
            async (docInstanceId: number, annotationType: AnnotationType, mediaType: MediaType) => {
                if (!userInfo) {
                    return;
                }

                const response = await getLinkedDocumentInstances(docInstanceId);
                if (response?.attachedMedia.length) {
                    setActionBeingPerformed("draw");
                    setCurrentFile({
                        docInstanceId,
                        annotationType,
                        attachedMedia: response.attachedMedia,
                        isOnTempFile: annotationType === AnnotationType.TempFile,
                    } as IFileMetadata);

                    history.replace(
                        match.url + routes.media.getReplaceLink(docInstanceId, false, mediaType)
                    );
                } else {
                    setCurrentFile({
                        docInstanceId: docInstanceId,
                        annotationType,
                    } as IFileMetadata);
                    history.push(
                        match.url +
                            routes.photo.getPhotoDrawLink(
                                docInstanceId,
                                entity.builderId,
                                userInfo.loginType,
                                entity.id === 0,
                                annotationType === AnnotationType.TempFile
                            )
                    );
                }
            },
            [entity.builderId, entity.id, getLinkedDocumentInstances, history, match.url, userInfo]
        );

        const getZohoDocBrowserInfo = useCallback(
            async (docInstanceId: number) => {
                try {
                    const docBrowserInfo = await zohoHandler.viewDocumentInBrowser(docInstanceId);
                    if (!docBrowserInfo) {
                        return;
                    }

                    const sessionInfo: IZohoSessionInfo = {
                        documentUrl: docBrowserInfo.urlForDoc,
                        documentDeleteUrl: docBrowserInfo.zohoDocumentDeleteUrl,
                        sessionDeleteUrl: docBrowserInfo.zohoSessionDeleteUrl,
                    };
                    setZohoSessionInfo(sessionInfo);
                    return docBrowserInfo;
                } catch (e) {
                    showAPIErrorMessage(e);
                    return;
                }
            },
            [zohoHandler]
        );

        const openFileInZoho = useCallback(
            async (docInstanceId: number) => {
                await getZohoDocBrowserInfo(docInstanceId);
                history.push(match.url + routes.document.getEditOnlineLink(docInstanceId));
            },
            [getZohoDocBrowserInfo, history, match.url]
        );

        const handleEditOnline = useCallback(
            async (docInstanceId: number, mediaType: MediaType) => {
                try {
                    const response = await mediaListHandler.getLinkedDocumentInstances(
                        docInstanceId,
                        entity.id === 0
                    );
                    if (response?.attachedMedia.length) {
                        setActionBeingPerformed("editOnline");
                        setCurrentFile({
                            docInstanceId,
                            attachedMedia: response.attachedMedia,
                        } as IFileMetadata);

                        history.replace(
                            match.url + routes.media.getReplaceLink(docInstanceId, false, mediaType)
                        );
                    } else {
                        await openFileInZoho(docInstanceId);
                    }
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            },
            [entity.id, history, match.url, mediaListHandler, openFileInZoho]
        );

        const handleFileDeleted = (fileList: BTFileSystem[]) => {
            if (reactViewAll && !isReactReadOnly) {
                handleAllFileChange(fileList);
            }
        };

        const handleCloseUploadModal = () => {
            onAttachFilesClose?.();
            setShowUploadFileModal(false);
            isUploadingFromReactViewAll && setIsUploadingFromReactViewAll(false);
        };

        const handleCloseViewAllModal = () => {
            onViewAllClose?.();
            setShowViewAllModal(false);
        };

        const handleReplaceBeforeClose = () => {
            setCurrentFile(null);
            setActionBeingPerformed(undefined);
            history.replace(match.url);
        };

        const handleCloseDrawOrAnnotateModal = async () => {
            history.push(match.url);
            setActionBeingPerformed(undefined);
            await getUpdatedAnnotationUrl();
            setCurrentFile(null);
        };

        const handleZohoBeforeClose = async () => {
            history.push(match.url);
            setActionBeingPerformed(undefined);
            if (zohoSessionInfo?.documentDeleteUrl) {
                const deleteZohoDocData = [zohoSessionInfo.documentDeleteUrl];
                await zohoHandler?.deleteZohoDocument(deleteZohoDocData);
            }
            setZohoSessionInfo(undefined);
        };

        // This should be okay with SPA for now, because when we switch between job and template mode we take the user back to the summary page
        // In the long term, this should be converted to use context
        // https://dev.azure.com/buildertrend/BuildertrendProjects/_workitems/edit/160727
        const isTemplateMode = getGlobalValue("isTemplateMode");

        const handleCloseLightbox = useCallback(() => setSelectedLightboxFileId(null), []);

        const index = slides.findIndex((s) => s.metadata?.mediaId === selectedLightboxFileId);
        const open = index >= 0;

        const modalConfig: IModalConfiguration = {
            parentRoute: match.url,
            beforeClose: () => void handleCloseDrawOrAnnotateModal(),
        };

        const mediaReplaceModalConfig: IModalConfiguration = {
            parentRoute: match.url,
            beforeClose: handleReplaceBeforeClose,
        };

        return (
            <div data-testid={dataTestId}>
                <RouteMediaReplace
                    attachedMedia={currentFile?.attachedMedia}
                    modalConfig={mediaReplaceModalConfig}
                    onReplace={handleReplace}
                    onCancel={handleReplaceBeforeClose}
                />
                <RouteMediaAnnotate modalConfig={modalConfig} />
                <RoutePhotoDraw modalConfig={modalConfig} />
                <RouteZoho
                    zohoSessionInfo={zohoSessionInfo}
                    docBrowserInfoCallBack={getZohoDocBrowserInfo}
                    beforeClose={handleZohoBeforeClose}
                />
                <BTLightbox
                    slides={slides}
                    open={open}
                    onClose={handleCloseLightbox}
                    index={index}
                    onSlideChange={handleSlideChange}
                />
                <KnockoutWrapperFileUploadContainer
                    {...props}
                    entity={entity}
                    attachedFiles={attachedFiles}
                    onCustomEvent={handleCustomEvent}
                    onAllFileChange={handleAllFileChange}
                    onAnnotationPathChanged={handleAnnotationPathChanged}
                    onHasAnnotationsChanged={handleHasAnnotationsChanged}
                    onThumbnailChanged={handleThumbnailChanged}
                    onViewingPermissionChange={handleViewPermissionsUpdate}
                    onAddNew={handleClickAdd}
                    onViewAll={handleClickViewAll}
                    onCreateNew={handleCreateNew}
                    onTrackEvent={handleTrackEvent}
                    builderInfo={builderInfo}
                    isAttachFilesOpen={isAttachFilesOpen}
                    onAttachFilesClose={onAttachFilesClose}
                    isViewAllOpen={isViewAllOpen}
                    onViewAllClose={onViewAllClose}
                    isOnExternalPage={isOnExternalPage}
                    externalServiceInfo={externalServiceInfo}
                    onBreakDocLink={handleBreakDocLink}
                    reactViewAll={reactViewAll}
                    isWebview={isWebview}
                />
                {showUploadFileModal && (
                    <BTFileUpload
                        entity={entity}
                        jobId={entity.jobId}
                        mediaType={MediaType.Unavailable}
                        uploadMode={BTFileUploadMode.Attachments}
                        isTemplateMode={isTemplateMode}
                        fileList={[]}
                        modalConfig={{ parentRoute: "", beforeClose: handleCloseUploadModal }}
                        onFilesConfirmed={handleFilesAdded}
                        externalServiceInfo={externalServiceInfo}
                        isExternal={isOnExternalPage}
                    />
                )}
                {reactViewAll && showViewAllModal && (
                    <ViewAllAttachments
                        entity={entity}
                        jobId={entity.jobId}
                        files={files}
                        modalConfig={{ parentRoute: "", beforeClose: handleCloseViewAllModal }}
                        sharingSettings={sharingSettings}
                        isReadonly={isReactReadOnly}
                        isNewEntity={entity.id === 0}
                        onAddClicked={handleReactClickAdd}
                        onViewPermissionsUpdate={handleViewPermissionsUpdate}
                        onFileAnnotate={handleAnnotation}
                        onFileDraw={handleDraw}
                        onEditOnline={handleEditOnline}
                        onFileDelete={handleFileDeleted}
                    />
                )}
            </div>
        );
    }
);

export const LegacyMediaViewer = withErrorBoundary(LegacyMediaViewerInner)(
    "Could not load attachments"
);
