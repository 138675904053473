import BaseKnockoutWrapper, {
    getObservable,
    getObservableArray,
    getOptionalObservable,
} from "legacyComponents/BaseKnockoutWrapper";
import {
    AttachedFiles,
    AttachedFilesRequest,
    BTFileSystem,
    IHasAttachedFiles,
    ObservableBTFileSystem,
} from "legacyComponents/FileUploadContainer.types";
import { IUploadNewModalContext } from "legacyComponents/LegacyMediaViewer";

import { BuilderInfo } from "helpers/AppProvider.types";

import { DocumentInstanceType, TempFileTypes } from "types/enum";

import { ITrackingData } from "utilities/analytics/analytics";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { arrayIsEqualDeep } from "utilities/array/array";
import { triggerBrowserDownload } from "utilities/document/fileDownload.utils";
import { getObjectDiff } from "utilities/object/object";

import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import {
    BulkDownloadHandler,
    IBulkDownloadHandler,
} from "entity/media/common/BulkDownload.api.handler";
import { IDownloadZipOfAttachedFilesForEntityRequest } from "entity/media/common/mediaTypes";

import "./FileUploadContainer.less";

type ViewType = "grid-view" | "list-view" | "carousel-view" | "none";

export interface IOnEventParams {
    key: string;
    data: any;
    preventDefault: boolean;
}

export interface IFilesChangeEventParams {
    attachDocs: BTFileSystem[];
}

interface IExtraArguments {
    isEditing?: boolean;
    isTemplate?: boolean;
    onlyPostApproval?: boolean;
    cappedFileCount?: number;
}

export interface IExternalServiceInfo {
    shareToken?: string;
    // This needs to be converted to use token approach
    subId: string;
    entityId: string;
    docType: DocumentInstanceType;
    tempFileType: TempFileTypes;
}

// Props exposed to React
export interface IFileUploadContainerProps {
    entity: IHasAttachedFiles;

    /** Do not pass in, unless you need to customize how a new entity loads up.
     * @default "{ files: [], notifyOwner: false, notifySubs: false }"
     */
    newEntityAttachJSON?: string;

    /** Overload for passing post approval files */
    attachedFiles?: AttachedFiles;

    /** @default "carousel-view" */
    defaultView?: ViewType;

    /** default true */
    hideTitle?: boolean;

    /** default false */
    isOnExternalPage?: boolean;

    /** default false */
    isReadOnly?: boolean;

    extraArguments?: IExtraArguments;

    externalServiceInfo?: IExternalServiceInfo;

    gaTabName?: string;

    leadId?: number;

    numberToLoad?: number;

    showSectionHeader?: boolean;
    showHeader?: boolean;
    allowViewChanging?: boolean;
    conditionalHeader?: boolean;
    fancyHeader?: boolean;

    /**
     * onCustomEvent can be used to handle a wide variety of events that happen within the Knockout file components.
     * @param event an object containing a key, data, and preventDefault properties
     * ```
     * {
     *      key: 'fileClicked',
     *      data: {
     *          nativeEvent: event,
     *          viewModel: fileVM,
     *      },
     *      preventDefault: false,
     * }
     * ```
     * @example ``` onCustomEvent={(event) => console.log(event)} ```
     */
    onCustomEvent?: (params: IOnEventParams) => void;

    /**
     * Pass setFieldValue or a corresponding change function.
     */
    onChange?: (fieldName: string, value: any) => void;

    /**
     * Key name that the files object will be written to on change.
     * Note: this only works for an entity id of 0, otherwise changes
     * are not written back to the parent.
     * @example ``` idForFiles="attachedFiles" ```
     */
    idForFiles?: string;

    /**
     * Key name that the file count will be written to on change
     * @example ``` idForFileCount="fileCount" ```
     */
    idForFileCount?: string;

    /**
     * This is a boolean prop that should be changed to true when you want to programmatically make the "All Attachments" modal appear.
     * This is the same modal that appears when clicking "View All" within the component, but should NOT be implemented unless you have a
     * use case outside of the normal "View All" button.
     * @example ``` isViewAllOpen={this.state.isViewAllOpen} ```
     */
    isViewAllOpen?: boolean;
    /**
     * This callback will fire when the "View All" modal is closed.
     * NOTE: With the knockout wrapper, this will technically fire immediately after opening, as the observable within the component resets itself.
     * After re-implementing fully in React, this behavior will be cleaned up.
     * @example ``` onViewAllClose={() => this.setState({ isViewAllOpen: false })} ```
     */
    onViewAllClose?: () => void;

    /**
     * This is a boolean prop that should be changed to true when you want to programmatically make the "Attach Files" modal appear.
     * This is the same modal that appears when clicking "Add" within the component, but should NOT be implemented unless you have a
     * use case outside of the normal "Add" button.
     * @example ``` isAttachFilesOpen={this.state.isAttachFilesOpen} ```
     */
    isAttachFilesOpen?: boolean;
    /**
     * This callback will fire when the "Attach Files" modal is closed.
     * NOTE: With the knockout wrapper, this will technically fire immediately after opening, as the observable within the component resets itself.
     * After re-implementing fully in React, this behavior will be cleaned up.
     * @example ``` onAttachFilesClose={() => this.setState({ isAttachFilesOpen: false })} ```
     */
    onAttachFilesClose?: () => void;

    /**
     * This is a boolean prop that should be changed to true when you want to programmatically make the "Create New File" modal appear.
     * This is the same modal that appears when clicking "Create New Doc" within the component, but should NOT be implemented unless you have a
     * use case outside of the normal "Create New Doc" button.
     * @example ``` isCreateFilesOpen={this.state.isCreateFilesOpen} ```
     */
    isCreateFilesOpen?: boolean;
    /**
     * This callback will fire when the "Create New File" modal is closed.
     * NOTE: With the knockout wrapper, this will technically fire immediately after opening, as the observable within the component resets itself.
     * After re-implementing fully in React, this behavior will be cleaned up.
     * @example ``` onCreateFilesClose={() => this.setState({ isCreateFilesOpen: false })} ```
     */
    onCreateFilesClose?: () => void;

    /**
     * A callback specifically for interacting with the full files array. This does not match the format the controllers are expecting,
     * so it should not be placed in form values. In most cases, this callback SHOULD NOT be passed to the component. Only use when you need
     * all of the attached files all the time.
     */
    onAllFileChange?: (files: BTFileSystem[]) => void;

    /**
     * This callback will fire when a file has annotations added/modified
     */
    onAnnotationPathChanged?: (
        id: number,
        key: "annotatedDocPath" | "previewAnnotatedDocPath",
        url: string
    ) => void;

    /**
     * This callback will fire when a file has annotations added/modified
     */
    onHasAnnotationsChanged?: (id: number, hasAnnotations: boolean) => void;

    /**
     * This callback will fire when a file thumbnail has changed
     */
    onThumbnailChanged?: (id: number, thumbnail: string) => void;

    /**
     * This callback will fire when a file has viewing permissions modified
     */
    onViewingPermissionChange?: (
        id: number,
        key: "showOwner" | "showSubs",
        shouldShow: boolean
    ) => void;

    /**
     * This callback will fire when a file has it's link broken to a document
     */
    onBreakDocLink?: (newDocInstanceId: number, prevDocInstanceId: number) => void;

    /**
     * When specified, this triggers special styling that surrounding the matching file in a green outline.
     * In most use cases, you should not specify this.
     */
    selectedFileId?: number;

    /**
     * When false, this will prevent the file wrapper from calling the subscriptions for fileCount, openViewAll, openCreateFiles, and openAttachFiles on initial mount of the bt-file-wrapper component.
     * Undefined will be treated as true
     */
    mutateOnLoad?: boolean;

    /**
     * Handler for making the bulk download api call
     */
    bulkDownloadHandler?: IBulkDownloadHandler;
    builderInfo?: BuilderInfo | null | undefined;

    onAddNew?: (knockoutContext: IUploadNewModalContext) => void;
    onViewAll?: () => void;
    onCreateNew?: () => void;
    /**
     * A callback for tracking analytics events from within Knockout.
     */
    onTrackEvent?: (data: Partial<ITrackingData>) => void;
    /** Temporary prop to control which CFV instances use React for View All Attachments */
    reactViewAll?: boolean;
    /** Determines whether or not to show the React view all modal in a read only state*/
    isReactReadOnly?: boolean;
    isWebview?: boolean;
}

// State exposed to React
interface IFileUploadContainerState {}

// Params to go into the component
interface IFileUploadContainerParams {
    allowViewChanging: boolean;
    builderID: number;
    conditionalHeader: boolean;
    defaultView?: ViewType;
    entityID: KnockoutObservable<number | undefined> | KnockoutObservableArray<number>;
    entityName: string;
    entityType: DocumentInstanceType;
    externalServiceInfo?: IExternalServiceInfo;
    extraArguments?: {
        isEditing?: KnockoutObservable<boolean>;
        isTemplate?: KnockoutObservable<boolean>;
        onlyPostApproval?: KnockoutObservable<boolean>;
        cappedFileCount?: KnockoutObservable<number>;
    };
    fancyHeader: boolean;
    fileCount: KnockoutObservable<number | undefined>;
    files: KnockoutObservableArray<ObservableBTFileSystem | undefined>;
    isInitialized: KnockoutObservable<boolean | undefined>;
    fileViewerVM: KnockoutObservable<AttachedFiles | undefined>;
    gaTabName: string;
    hideTitle?: boolean;
    jobsiteID?: number;
    leadId?: number;
    newEntityAttachJSON: KnockoutObservable<string | undefined>;
    numberToLoad?: KnockoutObservable<number>;
    onEvent?: (event: any) => void;
    openAttachFiles: KnockoutObservable<boolean | undefined>;
    openCreateFiles: KnockoutObservable<boolean | undefined>;
    openViewAll: KnockoutObservable<boolean | undefined>;
    readOnly: KnockoutObservable<boolean | undefined>;
    sectionHeader?: boolean;
    selectedFileId: KnockoutObservable<number | undefined>;
    showHeader?: boolean;
    showViewAll?: KnockoutObservable<boolean>;
    isWebview?: boolean;
    mutateOnLoad?: boolean;
    isOnExternalPage: KnockoutObservable<boolean | undefined>;
    reactViewAll: boolean;
    onBulkDownload: (entityId: number) => Promise<void>;
    onAddNew?: (knockoutContext: IUploadNewModalContext) => void;
    onViewAll?: () => void;
    onCreateNew?: () => void;
    onTrackEvent?: (data: Partial<ITrackingData>) => void;
}

class FileUploadContainer extends BaseKnockoutWrapper<
    IFileUploadContainerProps,
    IFileUploadContainerState,
    IFileUploadContainerParams
> {
    static defaultProps = {
        allowViewChanging: true,
        conditionalHeader: false,
        defaultView: "carousel-view" as ViewType,
        fancyHeader: false,
        hideTitle: true,
        isReadOnly: false,
        isOnExternalPage: false,
        onChange: () => {},
        bulkDownloadHandler: new BulkDownloadHandler(),
    };

    componentName = "bt-file-wrapper";

    componentWillUnmount = () => {
        this.disposeFileSubscriptions();
    };

    private disposeFileSubscriptions = () => {
        this.fileSubscriptions.forEach((sub) => {
            if (sub !== undefined) {
                sub.dispose();
            }
        });
        this.fileSubscriptions = [];
    };

    fileSubscriptions: KnockoutSubscription[] = [];
    getViewModel(): IFileUploadContainerParams {
        const {
            allowViewChanging,
            attachedFiles,
            conditionalHeader,
            defaultView,
            entity,
            externalServiceInfo,
            extraArguments,
            fancyHeader,
            gaTabName,
            hideTitle,
            idForFileCount,
            idForFiles,
            isAttachFilesOpen,
            isCreateFilesOpen,
            isOnExternalPage,
            isReadOnly,
            isViewAllOpen,
            leadId,
            newEntityAttachJSON,
            numberToLoad,
            onAllFileChange,
            onAttachFilesClose,
            onCreateFilesClose,
            onCustomEvent,
            onViewAllClose,
            onAnnotationPathChanged,
            onHasAnnotationsChanged,
            onThumbnailChanged,
            selectedFileId,
            showHeader,
            showSectionHeader,
            mutateOnLoad,
            onAddNew,
            onViewAll,
            onCreateNew,
            onTrackEvent,
            onViewingPermissionChange,
            onBreakDocLink,
            reactViewAll = false,
            isWebview,
        } = this.props;

        const fileViewVM = attachedFiles || entity.attachedFiles;
        const hasExistingFilesOnNewEntity = entity.id === 0 && fileViewVM.files.length > 0;

        const newFileObj = {
            files: [],
            notifyOwner: false,
            notifySubs: false,
        };

        const handleBulkDownload = async (entityId: number) => {
            let data: IDownloadZipOfAttachedFilesForEntityRequest = {
                documentInstanceType: entity.documentInstanceType,
                entityId,
                jobId: entity.jobId!,
            };

            try {
                const response =
                    await this.props.bulkDownloadHandler!.downloadZipOfAttachedDocsForEntity(data);
                triggerBrowserDownload(response.blobData, response.fileName);
            } catch (e) {
                showAPIErrorMessage(e);
            }
        };

        const viewModel: IFileUploadContainerParams = {
            allowViewChanging: allowViewChanging!,
            builderID: entity.builderId,
            conditionalHeader: conditionalHeader!,
            defaultView: defaultView,
            entityID: entity.bulkUploadEntityIds
                ? getObservableArray(entity.bulkUploadEntityIds!)
                : getObservable(entity.id),
            entityName: entity.name,
            entityType: entity.documentInstanceType,
            fancyHeader: fancyHeader!,
            fileCount: getObservable<number>(),
            files: getObservableArray<ObservableBTFileSystem | undefined>(),
            isInitialized: getObservable<boolean>(false),
            fileViewerVM: getObservable(fileViewVM),
            gaTabName: "",
            hideTitle: hideTitle,
            jobsiteID: entity.jobId,
            newEntityAttachJSON: getObservable(
                JSON.stringify(
                    hasExistingFilesOnNewEntity
                        ? { ...fileViewVM, hasFilesOnNewEntity: true }
                        : newFileObj
                )
            ),
            numberToLoad: getOptionalObservable(numberToLoad),
            onEvent: onCustomEvent,
            openViewAll: getObservable(isViewAllOpen),
            openAttachFiles: getObservable(isAttachFilesOpen),
            openCreateFiles: getObservable(isCreateFilesOpen),
            readOnly: getObservable(isReadOnly),
            isOnExternalPage: getObservable(isOnExternalPage),
            selectedFileId: getObservable(selectedFileId),
            showHeader,
            isWebview: isWebview,
            leadId: entity.leadId,
            mutateOnLoad: mutateOnLoad,
            reactViewAll,
            onBulkDownload: handleBulkDownload,
            onAddNew: onAddNew,
            onViewAll: onViewAll,
            onCreateNew: onCreateNew,
            onTrackEvent: onTrackEvent,
        };

        if (extraArguments) {
            const { isEditing, isTemplate, onlyPostApproval, cappedFileCount } = extraArguments;
            viewModel.extraArguments = {
                isEditing: getOptionalObservable(isEditing),
                isTemplate: getOptionalObservable(isTemplate),
                onlyPostApproval: getOptionalObservable(onlyPostApproval),
                cappedFileCount: getOptionalObservable(cappedFileCount),
            };
        }

        if (externalServiceInfo !== undefined) {
            viewModel.externalServiceInfo = externalServiceInfo;
        }

        if (gaTabName !== undefined) {
            viewModel.gaTabName = `${gaTabName} - `;
        }

        if (leadId !== undefined) {
            viewModel.leadId = leadId;
        }

        if (showSectionHeader !== undefined) {
            viewModel.sectionHeader = showSectionHeader;
        }

        if (showHeader !== undefined) {
            viewModel.showHeader = showHeader;
        }

        if (newEntityAttachJSON !== undefined) {
            viewModel.newEntityAttachJSON = getObservable(newEntityAttachJSON);
        }

        if (idForFileCount) {
            viewModel.fileCount.subscribe((value) => {
                this.props.onChange!(idForFileCount, value);
            });
        }

        if (idForFiles) {
            // Note that this subscription currently only fires for new entities (id = 0)
            viewModel.newEntityAttachJSON.subscribe((value) => {
                if (value) {
                    const parsed: any = JSON.parse(value);
                    this.props.onChange!(
                        idForFiles,
                        new AttachedFilesRequest({ attachDocs: parsed.files })
                    );
                }
            });
        }

        if (
            onAllFileChange ||
            onHasAnnotationsChanged ||
            onAnnotationPathChanged ||
            onViewingPermissionChange ||
            onThumbnailChanged ||
            onBreakDocLink
        ) {
            viewModel.files.subscribe((files: any[]) => {
                this.disposeFileSubscriptions();

                if (onHasAnnotationsChanged) {
                    files.forEach((file: ObservableBTFileSystem) => {
                        this.fileSubscriptions.push(
                            file.hasAnnotations?.subscribe((value: boolean) => {
                                onHasAnnotationsChanged(file.id(), value);
                            })
                        );
                    });
                }

                if (onAnnotationPathChanged) {
                    files.forEach((file: ObservableBTFileSystem) => {
                        this.fileSubscriptions.push(
                            file.annotatedDocPath?.subscribe((value: string) => {
                                onAnnotationPathChanged(file.id(), "annotatedDocPath", value);
                            })
                        );
                        this.fileSubscriptions.push(
                            file.previewAnnotatedDocPath?.subscribe((value: string) => {
                                onAnnotationPathChanged(
                                    file.id(),
                                    "previewAnnotatedDocPath",
                                    value
                                );
                            })
                        );
                    });
                }

                if (onThumbnailChanged) {
                    files.forEach((file: ObservableBTFileSystem) => {
                        this.fileSubscriptions.push(
                            file.thumbnail?.subscribe((value: string) => {
                                onThumbnailChanged(file.id(), value);
                            })
                        );
                    });
                }

                if (onViewingPermissionChange) {
                    files.forEach((file: ObservableBTFileSystem) => {
                        if (file.viewingPermissions === undefined) {
                            return;
                        }
                        this.fileSubscriptions.push(
                            file.viewingPermissions.showSubs.subscribe((value: boolean) => {
                                onViewingPermissionChange(file.id(), "showSubs", value);
                            })
                        );
                        this.fileSubscriptions.push(
                            file.viewingPermissions.showOwner.subscribe((value: boolean) => {
                                onViewingPermissionChange(file.id(), "showOwner", value);
                            })
                        );
                    });
                }

                if (onBreakDocLink) {
                    files.forEach((file: ObservableBTFileSystem) => {
                        const prevDocInstanceId = file.id();
                        this.fileSubscriptions.push(
                            file.id?.subscribe((newDocInstanceId: number) => {
                                onBreakDocLink(newDocInstanceId, prevDocInstanceId);
                            })
                        );
                    });
                }

                // Sometimes the file array is set back to `[]` during the initialization process.
                // Only propagate the change up if the vm is initialized so we don't clear the
                // list of files we're tracking in React
                if (onAllFileChange && viewModel.isInitialized()) {
                    onAllFileChange(ko.toJS(files));
                }
            });
        }

        if (isViewAllOpen !== undefined) {
            // Extra validation to make sure the props aren't misused
            if (onViewAllClose === undefined) {
                throw "FileUploadContainer: Since 'isViewAllOpen' is defined, 'onViewAllClose' must also be defined.";
            }
            viewModel.openViewAll.subscribe((value) => {
                if (!value) {
                    onViewAllClose();
                }
            });
        }

        if (isAttachFilesOpen !== undefined) {
            // Extra validation to make sure the props aren't misused
            if (onAttachFilesClose === undefined) {
                throw "FileUploadContainer: Since 'isAttachFilesOpen' is defined, 'onAttachFilesClose' must also be defined.";
            }
            viewModel.openAttachFiles.subscribe((value) => {
                if (!value) {
                    onAttachFilesClose();
                }
            });
        }

        if (isCreateFilesOpen !== undefined) {
            // Extra validation to make sure the props aren't misused
            if (onCreateFilesClose === undefined) {
                throw "FileUploadContainer: Since 'isCreateFilesOpen' is defined, 'onCreateFilesClose' must also be defined.";
            }
            viewModel.openCreateFiles.subscribe((value) => {
                if (!value) {
                    onCreateFilesClose();
                }
            });
        }

        return viewModel;
    }

    updateViewModel(vm: IFileUploadContainerParams, prevProps: IFileUploadContainerProps): void {
        if (vm === undefined) {
            return;
        }

        // Switching between the entity's attachedFiles key and the override is not supported
        const prevAttachedFiles = prevProps.attachedFiles || prevProps.entity.attachedFiles;
        const newAttachedFiles = this.props.attachedFiles || this.props.entity.attachedFiles;

        if (newAttachedFiles && prevAttachedFiles) {
            const [_, hasChange, differentKeys] = getObjectDiff(
                newAttachedFiles,
                prevAttachedFiles
            );
            // skip reinitalize if only change is permissions and they came from KO subscription syncing back
            // i.e. KO already has this state and doesn't need it
            // this is so things like scroll and carousel position don't reset needlessly
            const skipReinitalize =
                differentKeys.every((key) => key.includes("viewingPermissions")) &&
                arrayIsEqualDeep(
                    newAttachedFiles.files.map((file) => file.viewingPermissions),
                    ko.toJS(vm.files).map((file: BTFileSystem) => file.viewingPermissions)
                );

            if (hasChange && !skipReinitalize) {
                vm.entityID(this.props.entity.id);
                vm.fileViewerVM(newAttachedFiles);
            }
        }

        if (this.props.isReadOnly !== undefined && this.props.isReadOnly !== prevProps.isReadOnly) {
            vm.readOnly(this.props.isReadOnly);
        }

        if (
            this.props.isOnExternalPage !== undefined &&
            this.props.isOnExternalPage !== prevProps.isOnExternalPage
        ) {
            vm.readOnly(this.props.isOnExternalPage);
        }

        if (this.props.isViewAllOpen && this.props.isViewAllOpen !== prevProps.isViewAllOpen) {
            // Want to be sure to only trigger this on the react side if the prop is becoming true
            vm.openViewAll(true);
        }

        if (
            this.props.isAttachFilesOpen &&
            this.props.isAttachFilesOpen !== prevProps.isAttachFilesOpen
        ) {
            // Want to be sure to only trigger this on the react side if the prop is becoming true
            vm.openAttachFiles(true);
        }

        if (
            this.props.isCreateFilesOpen &&
            this.props.isCreateFilesOpen !== prevProps.isCreateFilesOpen
        ) {
            // Want to be sure to only trigger this on the react side if the prop is becoming true
            vm.openCreateFiles(true);
        }

        if (this.props.selectedFileId !== prevProps.selectedFileId) {
            vm.selectedFileId(this.props.selectedFileId);
        }
    }
}

/**
 * @deprecated Use LegacyMediaViewer instead, which is a passthrough that uses BTLightbox
 */
export const KnockoutWrapperFileUploadContainer = withErrorBoundary<IFileUploadContainerProps>(
    FileUploadContainer
)("Couldn't load Attached Files.");
