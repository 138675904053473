import { CheckboxChangeEvent } from "antd/lib/checkbox";
import classNames from "classnames";
import { Children, Component, createRef } from "react";
import { RouteComponentProps } from "react-router";
import { MoveEvent, SortableEvent } from "react-sortablejs";

import { EmailFolder } from "types/enum";

import { getBTTableSortKeys, GridSort } from "utilities/list/list.types";
import { routes } from "utilities/routes";
import { formattedPlural } from "utilities/string/string";

import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTIconForward, BTIconReply } from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { IBTColumnProps } from "commonComponents/btWrappers/BTTable/BTTable";
import { BTTable } from "commonComponents/btWrappers/BTTable/BTTable";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { DocumentContextMenu } from "commonComponents/utilities/DocumentContextMenu/DocumentContextMenu";
import { DragAndDrop, DragAndDropHandle } from "commonComponents/utilities/DragAndDrop/DragAndDrop";
import {
    ITargetEvent,
    TargetProps,
} from "commonComponents/utilities/DragAndDrop/SortableJsPlugins/Target/Target";
import { SelectAllCheckbox } from "commonComponents/utilities/SelectAllCheckbox/SelectAllCheckbox";

import {
    MessageEntry,
    MessageListSortColumns,
} from "entity/message/MessageList/MessageList.api.types";

import "./MessageListTable.less";

interface IMessageListItemProps extends RouteComponentProps {
    messages: MessageEntry[];
    jobIds: number[];
    selectedFolder: number;
    onItemChange: (item: MessageEntry) => void;
    onSelectAll: (messages: MessageEntry[]) => void;
    onSortUpdated: (updatedSortData: GridSort) => void;
    onSetList: (messages: MessageEntry[]) => void;
    onDragStart: (targetProps: TargetProps) => void;
    onMoveMessageDrop: (targetProps: TargetProps) => Promise<void>;
    onMessageSelected: (selectedMessageId: number) => void;
    sortData: GridSort;
}

interface IMessageListItemState {
    selectedCount: number;
    preDragList: MessageEntry[];
}

export default class MessageListTable extends Component<
    IMessageListItemProps,
    IMessageListItemState
> {
    state = {
        selectedCount: 0,
        preDragList: [],
    };

    private handleItemSelected = (e: CheckboxChangeEvent, message: MessageEntry) => {
        const { onItemChange } = this.props;
        onItemChange({
            ...message,
            isSelected: e.target.checked,
        });
    };

    private renderSubject = (message: MessageEntry) => {
        const body = message.body.trim();
        const subject = message.subject.trim();
        return (
            <>
                <span className={classNames({ Unread: !message.isRead })}>
                    {subject ? subject : "(no subject)"}
                </span>
                {body.length > 0 && <span className="Body"> - {body}</span>}
            </>
        );
    };

    private renderDate = (message: MessageEntry) => {
        const dateColumn =
            this.props.selectedFolder === EmailFolder.Sent
                ? message.sentDate
                : message.lastModifiedDate;
        return dateColumn && <DateDisplay showTime value={dateColumn} />;
    };

    private getDateHeader = () => {
        switch (this.props.selectedFolder) {
            case EmailFolder.Drafts:
                return "Date Saved ";
            case EmailFolder.Sent:
                return "Date Sent";
            default:
                return "Date Received";
        }
    };

    private renderReplyForwardIcon = (message: MessageEntry) => {
        if (message.isReply || message.isForwarded) {
            const action = message.isReply ? "Replied" : "Forwarded";
            const content = (
                <>
                    {action} on <DateDisplay value={message.lastResponseDate!} showTime />
                </>
            );
            return (
                <BTPopover content={content}>
                    {message.isReply ? <BTIconReply /> : <BTIconForward />}
                </BTPopover>
            );
        }

        return null;
    };

    private onCell = (message: MessageEntry) => {
        return {
            onClick: () => {
                if (this.props.selectedFolder === EmailFolder.Drafts) {
                    this.props.history.push(
                        this.props.match.url +
                            routes.messages.getComposeLink(message.id, message.jobId)
                    );
                } else {
                    this.props.onMessageSelected(message.id);
                }
            },
        };
    };

    private getMessageColumns = () => {
        const messageListColumns: IBTColumnProps<MessageEntry>[] = [];
        const { messages, onSetList, jobIds, selectedFolder } = this.props;
        const foldersWithFrom = [EmailFolder.Sent, EmailFolder.Drafts];
        const foldersWithBoth = [EmailFolder.Global, EmailFolder.Trash];

        // Draggable Column
        if (!this.disableDragAndDrop()) {
            messageListColumns.push({
                title: "",
                key: "draggable",
                render: () => <DragAndDropHandle />,
            });
        }

        // Check All Column
        if (selectedFolder !== EmailFolder.Global) {
            messageListColumns.push({
                key: "selection",
                title: () => {
                    return (
                        <SelectAllCheckbox
                            id="MessageListSelectAll"
                            data-testid="MessageListSelectAll"
                            onChange={(msgs: MessageEntry[]) => onSetList(msgs)}
                            data={messages}
                        >
                            {" "}
                        </SelectAllCheckbox>
                    );
                },
                dataIndex: "selection",
                render: (text, message) => {
                    return (
                        <BTCheckbox
                            id={`selected-${message.id}`}
                            data-testid={`selected-${message.id}`}
                            onChange={(e) => this.handleItemSelected(e, message)}
                            checked={message.isSelected}
                        />
                    );
                },
            });
        }

        // To Column
        if ([...foldersWithBoth, ...foldersWithFrom].includes(selectedFolder)) {
            messageListColumns.push({
                key: "to",
                title: "To",
                dataIndex: "recipients",
                onCell: this.onCell,
                render: (text, message) => {
                    return (
                        // eslint-disable-next-line react/forbid-dom-props
                        <span title={text} className={classNames({ Unread: !message.isRead })}>
                            {text}
                        </span>
                    );
                },
                className: "ClickableColumn",
                ...getBTTableSortKeys(
                    this.props.sortData,
                    MessageListSortColumns.From.toString(),
                    this.props.onSortUpdated
                ),
            });
        }

        // From Column
        if (foldersWithBoth.includes(selectedFolder) || !foldersWithFrom.includes(selectedFolder)) {
            messageListColumns.push({
                key: "from",
                title: "From",
                dataIndex: "from",
                onCell: this.onCell,
                render: (text, message) => {
                    return (
                        // eslint-disable-next-line react/forbid-dom-props
                        <span title={text} className={classNames({ Unread: !message.isRead })}>
                            {text}
                        </span>
                    );
                },
                className: "ClickableColumn",
                ...getBTTableSortKeys(
                    this.props.sortData,
                    MessageListSortColumns.From.toString(),
                    this.props.onSortUpdated
                ),
            });
        }

        // Reply or Forward Column
        messageListColumns.push({
            key: "actions",
            title: "",
            onCell: this.onCell,
            render: (text, message) => this.renderReplyForwardIcon(message),
            className: "ClickableColumn",
        });

        // Subject Column
        messageListColumns.push({
            key: "subject",
            title: "Subject",
            onCell: this.onCell,
            render: (text, message) => this.renderSubject(message),
            className: classNames("SubjectColumn", "ClickableColumn"),
            ...getBTTableSortKeys(
                this.props.sortData,
                MessageListSortColumns.Subject.toString(),
                this.props.onSortUpdated
            ),
        });

        // Job Column - add only when more than 1 job is selected
        if (jobIds.length > 1) {
            messageListColumns.push({
                key: "jobName",
                title: "Job",
                onCell: this.onCell,
                // eslint-disable-next-line react/forbid-dom-props
                render: (text) => <span title={text}>{text}</span>,
                dataIndex: "jobName",
                className: "ClickableColumn",
                ...getBTTableSortKeys(
                    this.props.sortData,
                    MessageListSortColumns.JobName.toString(),
                    this.props.onSortUpdated
                ),
            });
        }

        // File Column
        messageListColumns.push({
            key: "files",
            title: "",
            render: (text, message) => <DocumentContextMenu {...message.attachedFiles} />,
            className: "FilesColumn ClickableColumn",
            onCell: (record) =>
                record.attachedFiles.docCount === 0 ? this.onCell(record) : { onClick: () => {} },
        });

        // Date Column - when viewing sent items we show the sent date instead of last modified date
        messageListColumns.push({
            key: "date",
            title: this.getDateHeader(),
            onCell: this.onCell,
            render: (text, message) => this.renderDate(message),
            className: "ClickableColumn",
            ...getBTTableSortKeys(
                this.props.sortData,
                this.props.selectedFolder === EmailFolder.Sent
                    ? MessageListSortColumns.SentDate.toString()
                    : MessageListSortColumns.LastModifiedDate.toString(),
                this.props.onSortUpdated
            ),
        });

        return messageListColumns;
    };

    // Returns valid folders that messages can be moved to
    private getValidDropTarget(event: MoveEvent, props: IMessageListItemProps) {
        const targetProps = this.getTargetProps(event.dragged, event.related);

        if (targetProps.draggedId && targetProps.targetId) {
            const message = props.messages.find(
                (x: MessageEntry) => x.id === targetProps.draggedId
            );
            if (!message) {
                return false;
            }

            // Filter out invalid drop targets
            switch (targetProps.targetId) {
                case EmailFolder.Drafts:
                case EmailFolder.Sent:
                case EmailFolder.Trash:
                    return false;
            }
            return true;
        } else {
            return false;
        }
    }

    // Used for drag events to get the dragged source and target
    private getTargetProps = (source: HTMLElement | undefined, target: HTMLElement | undefined) => {
        let draggedId: number | undefined = undefined;
        let targetFolderId: number | undefined = undefined;

        if (source) {
            draggedId = +source.getAttribute("data-row-key")!;
        }

        if (target) {
            targetFolderId = +target.getAttribute("data-folderid")!;
        }
        return new TargetProps(draggedId, targetFolderId);
    };

    // Disable drag and drop on certain system folders
    private disableDragAndDrop = () => {
        switch (this.props.selectedFolder) {
            case EmailFolder.Drafts:
            case EmailFolder.Sent:
            case EmailFolder.Trash:
            case EmailFolder.Global:
                return true;
            default:
                return false;
        }
    };

    // Used to set the image when dragging
    dragContainer = createRef<HTMLDivElement>();
    private handleSetData = (dataTransfer: DataTransfer, item: HTMLElement) => {
        const draggedDataId: number = +item.getAttribute("data-row-key")!;
        const message = this.props.messages.find((x) => x.id === draggedDataId);
        let selectedCount = this.props.messages.filter((x) => x.isSelected).length;
        if (!message!.isSelected) {
            selectedCount = 1;
        }

        this.setState({ selectedCount });
        dataTransfer.setDragImage(this.dragContainer.current!, -10, -10);
    };

    // Called when a drag is canceled and nothing was moved
    private cancelDrag = () => {
        this.props.onSetList(this.state.preDragList);
    };

    // Wrap drag and drop component around the table
    private getTableBodyWrapper = (wrapperProps: React.HTMLAttributes<HTMLElement>) => {
        return (
            <DragAndDrop
                setData={this.handleSetData}
                className={wrapperProps.className}
                tag="tbody"
                list={this.props.messages}
                setList={() => {}}
                group={{
                    name: "messageItem",
                }}
                hideGhostClass
                sort={false}
                target
                targetClass="message-drag-highlight"
                onMove={(evt: MoveEvent) => this.getValidDropTarget(evt, this.props)}
                disabled={this.disableDragAndDrop()}
                revertOnSpill
                onSpill={() => this.cancelDrag()}
                onStart={(evt: SortableEvent) => {
                    this.setState({ preDragList: this.props.messages });
                    const targetProps = this.getTargetProps(
                        evt.item,
                        (evt as ITargetEvent).targetItem
                    );
                    this.props.onDragStart(targetProps);
                }}
                onEnd={async (evt: SortableEvent) => {
                    const targetProps = this.getTargetProps(
                        evt.item,
                        (evt as ITargetEvent).targetItem
                    );
                    if (targetProps.draggedId && targetProps.targetId) {
                        await this.props.onMoveMessageDrop(targetProps);
                    } else {
                        this.cancelDrag();
                    }
                }}
            >
                {Children.toArray(wrapperProps.children).filter((c) => Boolean(c))}
            </DragAndDrop>
        );
    };

    render() {
        const { messages } = this.props;
        const { selectedCount } = this.state;
        return (
            <div className="MessageListTable">
                <BTTable<MessageEntry>
                    dataSource={messages}
                    rowKey={(message) => `${message.id}`}
                    columns={this.getMessageColumns()}
                    pagination={false}
                    size="small"
                    rowClassName={(message) =>
                        classNames("MessageRow", { SelectedRow: message.isSelected })
                    }
                    components={{
                        body: {
                            wrapper: this.getTableBodyWrapper,
                        },
                    }}
                />
                <div className="DragContainer" ref={this.dragContainer}>
                    {`Move ${selectedCount} ${formattedPlural({
                        value: selectedCount,
                        one: "Message",
                        other: "Messages",
                    })}`}
                </div>
            </div>
        );
    }
}
