import { Layout } from "antd";
import { BasicProps } from "antd/lib/layout/layout";
import classNames from "classnames";
import { memo, PureComponent, ReactNode, useCallback, useEffect, useRef } from "react";
import { White } from "styles/buildertrendTheme/Colors";

import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";
import { routesWebforms } from "utilities/routesWebforms";

import {
    BTListLayoutHeader,
    stickyHeaderHeightActions,
    stickyHeaderLevelActions,
} from "commonComponents/btWrappers/BTListLayout/BTListLayoutHeader";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import { HotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.utility";
import { StickyFooterClassname } from "commonComponents/utilities/ListActionBar/ListActionBar";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";

import "./BTListLayout.less";

export type RenderListActions = (checkedActions?: React.ReactNode) => React.ReactNode;

interface IBTListLayoutProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
    title: string | ReactNode;
    className?: string;
    jobName?: string;
    listActions?: ReactNode;
    renderWithActions?: (renderListActions: RenderListActions) => React.ReactNode;
    "data-testid"?: string;
    optionalHeader?: React.ReactNode;
    wrapInFocusProvider?: boolean;

    /**
     * Show the default list page header and list title. Avoid setting this to false using this except
     * for list pages that are very unique and handle this from within the list component itself
     *
     * @default true
     */
    showHeader?: boolean;

    /**
     * This property is TEMPORARY and is NOT intended to be used by developers to fix bugs.
     * You MUST make your pages repsonsive. This property was added to assist with the migration, do not use.
     * @deprecate You are expected to make your page responsive. Yes it's ALWAYS possible
     */
    dangerousDoNotUseIExpectAPassbackPageMinWidth?: number;
}

export const BTListLayout: React.FunctionComponent<IBTListLayoutProps> = ({
    title,
    children,
    className,
    jobName,
    listActions,
    "data-testid": testid,
    renderWithActions,
    optionalHeader,
    dangerousDoNotUseIExpectAPassbackPageMinWidth,
    style,
    wrapInFocusProvider,
    showHeader = true,
    ...otherProps
}) => {
    const listLayoutClass = classNames("BTListLayout react", className);
    // This callback will be passed down to be rendered inside the GridContainer's toolbar render function
    const getHeader: RenderListActions = (checkedActions) => {
        if (!showHeader) {
            return null;
        }

        return (
            <BTListLayoutHeader
                headerTitle={title}
                jobName={jobName}
                listActions={checkedActions ?? listActions}
                optionalHeader={optionalHeader}
            />
        );
    };
    const handleContentResize = (entry: ResizeObserverEntry | undefined) => {
        if (entry && entry.target) {
            window.dispatchEvent(new Event("BTLayoutResize"));
        }
    };

    const [, onDivRefChange] = useResizeObserver<HTMLDivElement>(handleContentResize);
    const content = (
        <div ref={onDivRefChange}>
            {/* eslint-disable-next-line react/forbid-elements*/}
            <Layout
                data-testid={testid}
                className={listLayoutClass}
                style={{ ...style, minWidth: dangerousDoNotUseIExpectAPassbackPageMinWidth }}
                {...otherProps}
            >
                {renderWithActions && renderWithActions(getHeader)}
                {!renderWithActions && getHeader()}
                {children}
            </Layout>
        </div>
    );
    if (!wrapInFocusProvider) {
        return content;
    }
    return (
        <FocusProvider shareFocusWithParent>
            <Hotkey
                {...HotkeyCommands["close"]}
                // eslint-disable-next-line deprecate/member-expression
                onCommand={() => routesWebforms.closeModalAndReload()}
                triggerBehavior="down"
            />
            {content}
        </FocusProvider>
    );
};

interface IListActionsProps extends BasicProps {
    isSticky?: boolean;
    checkedActions?: boolean;
}

export const ListActions: React.FunctionComponent<IListActionsProps> = memo<IListActionsProps>(
    ({ children, className, isSticky = false, checkedActions = false }) => {
        const stickyHeaderHeight = isSticky ? stickyHeaderHeightActions : 0;
        const top = useStickyHeight(stickyHeaderHeight, "top", stickyHeaderLevelActions);

        return (
            <Layout.Header
                className={classNames("ListActions", className, {
                    StickyLayoutHeader: isSticky,
                    CheckedActions: checkedActions,
                })}
                style={isSticky ? { height: stickyHeaderHeight || undefined, top } : undefined}
            >
                {children}
            </Layout.Header>
        );
    }
);

export interface IListSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    style?: React.CSSProperties;
}

export class ListSection extends PureComponent<IListSectionProps> {
    render() {
        const { className, children, ...otherProps } = this.props;
        const listSectionClass = classNames("ListSection", "react", className);
        return (
            <div className={listSectionClass} {...otherProps}>
                {children}
            </div>
        );
    }
}

interface IBTListLayoutWhitespaceProps {
    className?: string;
}

export const BTListLayoutWhitespace: React.FunctionComponent<IBTListLayoutWhitespaceProps> = (
    props
) => {
    const { className } = props;
    const combinedClasses = classNames(
        className,
        "BTListLayoutWhitespace",
        "flex justify-content-center"
    );
    const container = useRef<HTMLDivElement>(null);

    const fixDivHeight = useCallback(() => {
        if (container.current) {
            // calculate how much space the whitespace should take up,
            // the area between content above and the footer below (on pages with sticky footer)
            const currentTop = container.current!.getBoundingClientRect().top;
            const footerHeight = container.current.parentElement!.querySelector(
                "." + StickyFooterClassname
            )?.clientHeight;
            const offset = footerHeight ? currentTop + footerHeight : currentTop;

            // Take an additional 20px off to account for horizontal scrollbar heights
            const calculatedMinHeight = window.innerHeight - offset - 20;

            const minHeightValue = Math.max(0, calculatedMinHeight) + "px";
            if (container.current!.style.minHeight !== minHeightValue) {
                container.current!.style.minHeight = minHeightValue;
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("BTLayoutResize", fixDivHeight);
        return () => {
            window.removeEventListener("BTLayoutResize", fixDivHeight);
        };
    }, [fixDivHeight]);

    return (
        <div ref={container} className={combinedClasses} style={{ background: White }}>
            {props.children}
        </div>
    );
};
