import classNames from "classnames";
import { Component } from "react";

import "../BTTable/BTTable.less";

interface ITableProps {
    wrapperClass?: string;
    className?: string;
}

class Table extends Component<ITableProps> {
    render() {
        // Add Table functionality as needed.
        const { wrapperClass, className, ...otherProps } = this.props;

        return (
            <div className={classNames(wrapperClass, "ant-table-wrapper")}>
                <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                        <div className="ant-table ant-table-default ant-table-scroll-position-left">
                            <div className="ant-table-content">
                                <div className="ant-table-body">
                                    {
                                        // eslint-disable-next-line react/forbid-elements
                                        <table
                                            className={classNames(className, "BTTable")}
                                            {...otherProps}
                                        >
                                            {this.props.children}
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

interface IHeaderRow {
    className?: string;
}

class HeaderRow extends Component<IHeaderRow> {
    render() {
        // Add Header Row funtionality as needed.
        const { className, ...otherProps } = this.props;

        return (
            <thead className={classNames("ant-table-thead", className)} {...otherProps}>
                <tr>{this.props.children}</tr>
            </thead>
        );
    }
}

interface IHeaderCell
    extends Omit<
        React.DetailedHTMLProps<
            React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
            HTMLTableHeaderCellElement
        >,
        "align"
    > {
    customSpacing?: boolean; // Override the ant-table-header-column spacing
    textAlign?: "left" | "center" | "right" | "justify";
}

class HeaderCell extends Component<IHeaderCell> {
    render() {
        // Add Header Cell funtionality as needed.
        const { textAlign, className, customSpacing, ...otherProps } = this.props;

        const antdClass = customSpacing ? undefined : "ant-table-header-column";

        return (
            <th style={{ textAlign: textAlign }} {...otherProps}>
                <span className={classNames(className, antdClass)}>
                    <div>
                        <span className="ant-table-column-title">{this.props.children}</span>
                    </div>
                </span>
            </th>
        );
    }
}

interface IBodyRowProps {
    className?: string;
}

class BodyRow extends Component<IBodyRowProps> {
    render() {
        // Add Table Body Row funtionality as needed.
        const { className, ...otherProps } = this.props;

        return (
            <tbody className="ant-table-tbody">
                <tr className={classNames("ant-table-row", className)} {...otherProps} />
            </tbody>
        );
    }
}

interface IBodyCell
    extends Omit<
        React.DetailedHTMLProps<
            React.TdHTMLAttributes<HTMLTableDataCellElement>,
            HTMLTableDataCellElement
        >,
        "align"
    > {
    offset?: number;
    textAlign?: "left" | "center" | "right" | "justify";
}

class BodyCell extends Component<IBodyCell> {
    render() {
        const { textAlign, offset, ...otherProps } = this.props;

        const elements = [];
        if (offset) {
            for (let i = 0; i < offset; i++) {
                elements.push(<td key={i} />);
            }
        }

        elements.push(<td style={{ textAlign: textAlign }} key={-1} {...otherProps} />);

        return elements;
    }
}

export const BTTableCustom = {
    Table: Table,
    HeaderRow: HeaderRow,
    HeaderCell: HeaderCell,
    BodyRow: BodyRow,
    BodyCell: BodyCell,
};
