import { ReplyType } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import {
    DraftSaveResponse,
    MessageComposeRequest,
    MessageComposeResponse,
    MessageDetailsEntity,
    MessageDetailsExternalEntity,
    MessageFoldersResponse,
    UpdateMessageResponse,
} from "./Message.api.types";

export interface IMessageDetailsHandler {
    get(id: number, folderId?: number): Promise<MessageDetailsEntity>;
    getExternal(shareToken: string): Promise<MessageDetailsExternalEntity>;
    create(
        jobId: number,
        replyId?: number,
        replyType?: ReplyType,
        defaultRecipientId?: number,
        docInstanceIds?: number[],
        paymentIds?: number[],
        defaultSubject?: string,
        attachOwnerProfile?: boolean
    ): Promise<MessageDetailsEntity>;
    send(message: MessageComposeRequest): Promise<MessageComposeResponse>;
    saveDraft(message: MessageComposeRequest): Promise<DraftSaveResponse>;
    delete(id: number[], folderId: number): Promise<UpdateMessageResponse>;
    move(
        messageIds: number[],
        sourceFolderId: number,
        destFolderId: number
    ): Promise<UpdateMessageResponse>;
    getFolders(): Promise<MessageFoldersResponse>;
    markAsReadOrUnread(messageIds: number[], unread: boolean): Promise<UpdateMessageResponse>;
}

export class MessageDetailsHandler implements IMessageDetailsHandler {
    async get(id: number, folderId?: number): Promise<MessageDetailsEntity> {
        const data = {
            messageId: id,
            folderId: folderId,
        };

        return await APIHandler("/api/Messages", {
            method: "GET",
            data: data,
            responseType: MessageDetailsEntity,
        });
    }

    async getExternal(shareToken: string): Promise<MessageDetailsExternalEntity> {
        return await APIHandler(`/api/messages/${shareToken}/messageDetailsExternal`, {
            method: "GET",
            responseType: MessageDetailsExternalEntity,
        });
    }

    async create(
        jobId: number,
        replyId?: number,
        replyType?: number,
        defaultRecipientId?: number,
        docInstanceIds?: number[],
        paymentIds?: number[],
        defaultSubject?: string,
        attachOwnerProfile?: boolean
    ): Promise<MessageDetailsEntity> {
        const data = {
            jobId,
            replyId,
            replyType,
            defaultRecipientId,
            defaultSubject,
            attachOwnerProfile,
        };

        let headers = undefined;
        if (docInstanceIds && docInstanceIds.length > 0) {
            headers = { docInstanceIds };
        } else if (paymentIds && paymentIds.length > 0) {
            headers = { paymentIds };
        }

        return await APIHandler("/api/Messages", {
            method: "GET",
            data: data,
            headers: headers,
            responseType: MessageDetailsEntity,
        });
    }

    async send(message: MessageComposeRequest): Promise<MessageComposeResponse> {
        return await APIHandler("/api/Messages", {
            method: "POST",
            data: message,
            responseType: MessageComposeResponse,
        });
    }

    async saveDraft(message: MessageComposeRequest): Promise<DraftSaveResponse> {
        return await APIHandler(`/api/Messages?sendMessage=false`, {
            method: "POST",
            data: message,
            responseType: DraftSaveResponse,
        });
    }

    async delete(messageIds: number[], folderId: number): Promise<UpdateMessageResponse> {
        const data = {
            messageIds: messageIds,
            folderId: folderId,
        };

        return await APIHandler("/api/Messages/Delete", {
            method: "POST",
            data: data,
            responseType: UpdateMessageResponse,
        });
    }

    async move(
        messageIds: number[],
        sourceFolderId: number,
        destFolderId: number
    ): Promise<UpdateMessageResponse> {
        const data = {
            messageIds: messageIds,
            sourceFolderId: sourceFolderId,
            destFolderId: destFolderId,
        };

        return await APIHandler("/api/Messages/Move", {
            method: "POST",
            data: data,
            responseType: UpdateMessageResponse,
        });
    }

    async getFolders(selectedJobIds?: number[]): Promise<MessageFoldersResponse> {
        const reqData: any = {
            selectedJobIds,
        };
        return await APIHandler("/api/Messages/Folders", {
            method: "GET",
            data: reqData,
            responseType: MessageFoldersResponse,
        });
    }

    async markAsReadOrUnread(
        messageIds: number[],
        unread: boolean
    ): Promise<UpdateMessageResponse> {
        const data = {
            messageIds: messageIds,
            unread: unread,
        };

        return await APIHandler("/api/Messages/MarkAsReadOrUnread", {
            method: "POST",
            data: data,
            responseType: UpdateMessageResponse,
        });
    }
}
