import { FolderOpenFilled } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { DocumentIcons } from "styles/buildertrendTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconFolderOpenGlobalFilled: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return (
        <FolderOpenFilled
            {...props}
            type="folder-open"
            style={{ ...props.style, color: DocumentIcons.FolderGlobal }}
        />
    );
};
