import { BTIconSubs } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemShowSubsProps {
    fileId: number;
    isVisibleToSubs: boolean;
    onClick: () => Promise<void>;
}

const MenuItemShowSubs = ({
    fileId,
    isVisibleToSubs,
    onClick,
    ...antProps
}: IMenuItemShowSubsProps) => {
    const opacityPercent = isVisibleToSubs ? "40%" : "100%";
    const text = isVisibleToSubs ? "Hide from Subs/Vendors" : "Show Subs/Vendors";

    return (
        <BTMenuItem
            {...antProps}
            key={`showSubs-menuitem-${fileId}`}
            data-testid={`showSubs-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconSubs style={{ opacity: opacityPercent }} /> {text}
        </BTMenuItem>
    );
};

export default MenuItemShowSubs;
