import { ShareType } from "types/enum";

import type { default as FileShareTokenJson } from "./FileShare.api.json";

type FileShareTokenAPIData = typeof FileShareTokenJson;

export interface IGetShareTokenRequest {
    shareType: ShareType;
    jobId?: number;
}

export class ShareTokenResponse {
    constructor(data: FileShareTokenAPIData) {
        this.shareToken = data.shareToken;
        this.shareContentUrl = data.shareContentUrl;
        this.shareFileUrl = data.shareFileUrl;
    }
    shareToken: string;
    shareContentUrl: string;
    shareFileUrl: string | null;
}
