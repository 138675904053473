import { BTIconFontSizeOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemAnnotateProps {
    fileId: number;
    onClick: () => Promise<void>;
}

const MenuItemAnnotate = ({ fileId, onClick, ...antProps }: IMenuItemAnnotateProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`annotate-menuitem-${fileId}`}
            data-testid={`annotate-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconFontSizeOutlined /> Annotate
        </BTMenuItem>
    );
};

export default MenuItemAnnotate;
