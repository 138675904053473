import { WarningTwoTone } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { ErrorColor } from "styles/buildertrendTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconWarningError: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <WarningTwoTone {...props} type="warning" twoToneColor={ErrorColor} />;
};
