import { APIHandler } from "utilities/apiHandler";

import {
    DocumentContextMenuEntity,
    IDocumentContextMenuRequest,
} from "commonComponents/utilities/DocumentContextMenu/DocumentContextMenu.types";

export interface IDocumentContextMenuHandler {
    GetDocumentContextMenu(data: IDocumentContextMenuRequest): Promise<DocumentContextMenuEntity>;
}

export class DocumentContextMenuHandler implements IDocumentContextMenuHandler {
    async GetDocumentContextMenu(
        data: IDocumentContextMenuRequest
    ): Promise<DocumentContextMenuEntity> {
        return await APIHandler(`/api/Documents/GetNewDocumentContextMenu`, {
            method: "GET",
            data: data,
            responseType: DocumentContextMenuEntity,
        });
    }
}
