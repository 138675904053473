import { CheckboxChangeEvent, CheckboxProps } from "antd/lib/checkbox";
import { Component } from "react";

import { IIsSelectable } from "types/apiResponse/apiResponse";

import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";

export interface ISelectAllCheckboxProps<T>
    extends Omit<CheckboxProps, "id" | "onChange" | "checked" | "indeterminate"> {
    id: string;
    "data-testid": string;
    data: T[];
    onChange: (newSelectedItems: T[]) => void;
    shouldShowLabel?: boolean;
    onHotkeyPressed?: () => void;
}

export class SelectAllCheckbox<T extends IIsSelectable> extends Component<
    ISelectAllCheckboxProps<T>
> {
    static defaultProps = {
        shouldShowLabel: true,
    };

    private handleSelectAll = (e: CheckboxChangeEvent) => {
        const { onChange, data } = this.props;

        const newSelectedItems = data.map((d: T) => ({
            ...d,
            isSelected: e.target.checked,
        }));
        onChange(newSelectedItems);
    };

    render() {
        const { children, data, shouldShowLabel, className, ...rest } = this.props;
        const selectedItems = data.filter((d) => d.isSelected);
        return (
            <BTCheckbox
                {...rest}
                onChange={this.handleSelectAll}
                checked={selectedItems.length > 0}
                className={className}
                indeterminate={selectedItems.length > 0 && selectedItems.length < data.length}
                hotkey={{ hotkey: "checkAll", popoverPlacement: "bottom" }}
                onHotkeyPressed={this.props.onHotkeyPressed}
            >
                {shouldShowLabel ? children || "Select All" : null}
            </BTCheckbox>
        );
    }
}
