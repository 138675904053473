import { Divider } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { MediaType } from "types/enum";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    BTIconCheckOutlined,
    BTIconCloseOutlined,
    BTIconWarningError,
} from "commonComponents/btWrappers/BTIcon";
import { canViewBTFileSystemInLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSpin } from "commonComponents/btWrappers/BTSpin/BTSpin";
import { getFileSystemId } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.utils";
import { ByteDisplay } from "commonComponents/utilities/ByteDisplay/ByteDisplay";
import { IconFile } from "commonComponents/utilities/IconFile/IconFile";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

import "../BTFileUpload/BTFileUploadPresentational.less";

export interface IFileItemProps {
    file: UploadFile<BTFileSystem>;
    mediaType: MediaType;
    isFirst: boolean;
    isSaving: boolean;
    failedFileIds: { [key: string]: boolean | undefined };
    onFileListRemove: (file: UploadFile) => void;
    onClickPreview: (mediaId: number | null) => void;
}

export function FileItem({
    file,
    mediaType,
    isFirst,
    isSaving,
    failedFileIds,
    onFileListRemove,
    onClickPreview,
}: Readonly<IFileItemProps>) {
    const handleRemove = () => {
        onFileListRemove(file);
    };

    const handleClickPreview = () => {
        onClickPreview(file.response?.id ?? file.response?.videoId ?? null);
    };

    /** Determines if the supplied file should show thumbnail & preview in Lightbox */
    const shouldShowPreview = (file: BTFileSystem) => {
        const canView = canViewBTFileSystemInLightbox(file);
        if (file.mediaType === MediaType.Video) {
            return canView && file.thumbnail;
        }

        return canView;
    };

    const nameSplit = file.name.split(".");
    const extensionFromName = nameSplit.length > 0 ? nameSplit[nameSplit.length - 1] : "";

    const extension = file.response?.extension ?? extensionFromName;
    const fileSystemId = getFileSystemId(mediaType, file.response);
    const isFailedFile = fileSystemId && failedFileIds[fileSystemId.toString()];
    const showRemoveButton = !isSaving || file.status !== "done";

    let statusIcon;
    switch (file.status) {
        case "uploading":
            statusIcon = <BTSpin size="small" />;
            break;
        case "done":
        case "success":
            statusIcon = <BTIconCheckOutlined className="BTFileUpload-Success" />;
            break;
        case "error":
            statusIcon = isFailedFile ? (
                <BTIconWarningError className="BTFileUpload-Failure" />
            ) : (
                <BTIconCloseOutlined className="BTFileUpload-Failure" />
            );
            break;
        default:
            statusIcon = null;
            break;
    }

    const showPreview = file.response && shouldShowPreview(file.response);
    const thumbnailUrl = showPreview ? file.response?.thumbnail : "";
    return (
        <>
            {!isFirst && <Divider className="margin-vertical-xs" />}
            <BTRow className="BTFileUpload AddHorizontalBodyPadding FileItem" align="middle">
                <BTCol xs={1}>{statusIcon}</BTCol>
                <BTCol xs={5} sm={3}>
                    <div className="flex justify-content-center">
                        <IconFile
                            size="small"
                            fileType={extension}
                            thumbnail={thumbnailUrl}
                            thumbnailSize="xlarge"
                            onClick={showPreview ? handleClickPreview : undefined}
                        />
                    </div>
                </BTCol>
                <BTCol xs={showRemoveButton ? 9 : 14} sm={showRemoveButton ? 15 : 18}>
                    <TextTruncate showPopover>{file.name}</TextTruncate>
                </BTCol>
                <BTCol xs={4} sm={2} className="padding-left-xs">
                    {isFailedFile ? (
                        <StatusTagDisplay statusType="danger" statusText="Failed" />
                    ) : (
                        <ByteDisplay
                            className="BTFileUpload-SecondaryText"
                            value={file.size ?? 0}
                        />
                    )}
                </BTCol>
                {showRemoveButton && (
                    <BTCol xs={4} sm={3} className="flex justify-content-end">
                        <BTButton
                            data-testid={`fileRemove-${file.uid}`}
                            type="link"
                            isolated
                            onClick={handleRemove}
                        >
                            Remove
                        </BTButton>
                    </BTCol>
                )}
            </BTRow>
        </>
    );
}
