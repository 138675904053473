import { Layout } from "antd";
import { BasicProps } from "antd/lib/layout/layout";
import classNames from "classnames";
import { ContextType, FC, PureComponent, useCallback, useState } from "react";
import { useInView } from "react-intersection-observer";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";
import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";
import { isInIframe } from "utilities/url/url";

import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import { HotkeyCommands } from "commonComponents/utilities/Hotkey/hotkey.utility";
import { useStickyHeight } from "commonComponents/utilities/StickyContext/StickyContext";

import "./BTLayout.less";

interface IBTLayoutProps {
    shouldExpandToFitContent?: boolean;
    /**
     * when true, removes a webforms css hack that adds content to the end of BTLayoutHeader
     * @default false
     */
    removeHeaderAfter?: boolean;
    useModalLayout?: boolean;
    removePadding?: boolean;
}

export const BTLayout: FC<IBTLayoutProps> = ({
    shouldExpandToFitContent = false,
    removeHeaderAfter = false,
    ...props
}) => {
    if (props.useModalLayout) {
        return <>{props.children}</>;
    }
    const layoutClasses = classNames({
        layout: true,
        "BTLayout-ExpandToFillContent": shouldExpandToFitContent,
        "BTLayout-RemoveHeaderAfter": removeHeaderAfter,
    });

    // show close hotkey on settings page
    const closeHotkey = isInIframe() &&
        window.location.href.indexOf(routes.settings.settings) > 0 && (
            <Hotkey
                {...HotkeyCommands["close"]}
                // eslint-disable-next-line deprecate/member-expression
                onCommand={() => routesWebforms.closeModalAndReload()}
                triggerBehavior="down"
            />
        );

    return (
        // eslint-disable-next-line react/forbid-elements
        <Layout className={layoutClasses}>
            <FocusProvider shareFocusWithParent>
                {closeHotkey}
                {props.children}
            </FocusProvider>
        </Layout>
    );
};

interface IBTLayoutHeaderProps extends BasicProps {
    /**
     * Indicates if the Layout Header should stick to the bottom of the current
     * StickyHeaderContext stack. Provide the height of the Layout Header if
     * nested elements also need to be added to the StickyHeaderContext stack.
     *
     * @default true
     */
    isSticky?: boolean;

    /**
     * Only set this to `true` when using BTModalLayout in a presentational  component!
     * If this is set to `true`, this component will not return anything
     *
     * @default false
     */
    useModalLayout?: boolean;
}

/**
 * BTModal header layout
 * @example
 * <BTLayout>
 *  <BTButton type="primary" data-testid="save">Save</BTButton>
 *  <BTButton type="destruction" data-testid="delete">Delete</BTButton>
 * </BTLayout>
 */
export const BTLayoutHeader: FC<IBTLayoutHeaderProps> = ({
    isSticky = true,
    className,
    children,
    useModalLayout = false,
    style,
    ...restProps
}) => {
    /**
     * Note: this can be replaced with the "sticky-change" event if it becomes a standard.
     *
     * .TopScrollPlaceholder is tracked, so when it's no longer visible we know the user is not
     * at the top of the window anymore. This triggers a rerender and we add the .stuck class.
     */
    const { ref, inView } = useInView({
        threshold: 1,
    });

    const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);
    const [, setHeaderRef] = useResizeObserver((entry) => {
        const headerRef = entry?.target;
        if (isSticky && headerRef) {
            const defaultHeaderHeight = 48;
            setStickyHeaderHeight(headerRef.clientHeight ?? defaultHeaderHeight);
        }
    });
    const top = useStickyHeight(stickyHeaderHeight, "top", 0);

    const setRefs = useCallback(
        (r: HTMLDivElement | null) => {
            ref(r);
            // TODO: remove this hack and set ref directly to header ref once we are on antd v4.19.4+ (https://github.com/ant-design/ant-design/commit/8cab1883afeea049fa8554fe70d0491830d95b78#diff-f22153e3f63b1ae5f7b6d84d7ecbd52b795c4ccd564a452021da1205d7c17658)
            setHeaderRef((r?.nextElementSibling ?? null) as HTMLElement | null);
        },
        [ref, setHeaderRef]
    );

    if (useModalLayout) {
        return null;
    }

    return (
        <>
            <div ref={setRefs} className="TopScrollPlaceholder" />
            {children && (
                <Layout.Header
                    {...restProps}
                    className={classNames(
                        "ModalHeader",
                        {
                            StickyLayoutHeader: isSticky,
                            stuck: isSticky && !inView,
                        },
                        className,
                        "BTLayoutHeader"
                    )}
                    style={{
                        ...style,
                        ...(isSticky && { top }),
                    }}
                >
                    {children}
                </Layout.Header>
            )}
        </>
    );
};

export class BTLayoutContent extends PureComponent<{ removePadding?: boolean }> {
    static contextType = BuilderInfoContext;
    context: ContextType<typeof BuilderInfoContext>;

    render() {
        return (
            <Layout.Content
                className={classNames("BTLayoutContent", {
                    removePadding: this.props.removePadding,
                })}
            >
                {this.props.children}
            </Layout.Content>
        );
    }
}

export class BTLayoutFooter extends PureComponent {
    render() {
        return <Layout.Footer className="BTLayoutFooter">{this.props.children}</Layout.Footer>;
    }
}
