import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FileViewOptions } from "legacyComponents/FileUploadContainer.types";
import {
    getBuilderOptions,
    getOwnerOptions,
    getSubsOptions,
} from "legacyComponents/FileUploadContainer.utils";
import { FunctionComponent } from "react";

import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { IBTFileUploadFormValues } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { IBTInjectedFormikProps } from "commonComponents/utilities/Formik/WithFormikBT";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

interface IMediaAttachmentViewingPermissions {
    showOptions: FileViewOptions;
    onChange(e: CheckboxChangeEvent): void;
}

export const MediaAttachmentViewingPermissions: FunctionComponent<
    IBTInjectedFormikProps<IMediaAttachmentViewingPermissions, IBTFileUploadFormValues>
> = ({ showOptions, values, onChange }) => {
    const builderOption = getBuilderOptions(showOptions);
    const subOption = getSubsOptions(showOptions);
    const ownerOption = getOwnerOptions(showOptions);

    return (
        <PageSection title="Viewing Permissions">
            {builderOption?.visible && (
                <BTFormItemAutomatic id="showBuilder" label="Builder" key="showBuilder">
                    <BTCheckbox
                        id="showBuilder"
                        data-testid="showBuilder"
                        disabled={!builderOption.canEdit}
                        checked={values.showBuilder}
                        onChange={onChange}
                    >
                        Allow Builder to view attached files.
                    </BTCheckbox>
                </BTFormItemAutomatic>
            )}
            {subOption?.visible && (
                <BTFormItemAutomatic id="showSubs" label="Subs/Vendors" key="showSubs">
                    <BTCheckbox
                        id="showSubs"
                        data-testid="showSubs"
                        disabled={!subOption.canEdit}
                        checked={values.showSubs}
                        onChange={onChange}
                    >
                        Allow Subs / Vendors to view attached files.
                    </BTCheckbox>
                </BTFormItemAutomatic>
            )}
            {ownerOption?.visible && (
                <BTFormItemAutomatic id="showOwner" label="Owner" key="showOwner">
                    <BTCheckbox
                        id="showOwner"
                        data-testid="showOwner"
                        disabled={!ownerOption.canEdit}
                        checked={values.showOwner}
                        onChange={onChange}
                    >
                        Allow Owner to view attached files.
                    </BTCheckbox>
                </BTFormItemAutomatic>
            )}
        </PageSection>
    );
};
