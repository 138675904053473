export class VideoUploadTicket {
    constructor(data: any) {
        this.uploadUrl = data.uploadUrl;
        this.videoId = data.videoId;
        this.completeUrl = data.completeUrl;
        this.errorMessage = data.errorMessage;
    }
    uploadUrl: string;
    videoId: number;
    completeUrl: string;
    errorMessage: string;
}

export class VideoUploadTicketResponse {
    constructor(data: any) {
        this.ticket = new VideoUploadTicket(data.ticket);
    }
    ticket: VideoUploadTicket;
}
