import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import { BTLoginTypes } from "handlers";

interface IJobPickerRouterProps extends RouteComponentProps {
    nextUrl: string;
    setSelectedJobId?: (jobId: number, orgId?: number, loadMissingJobs?: boolean) => Promise<void>;
}

// This component is used to set a job in the job picker before routing.
// Should be used sparingly until the job picker / routing is refactored.
export function JobPickerRouter({ history, nextUrl, setSelectedJobId }: IJobPickerRouterProps) {
    const userInfo = useContext(UserInfoContext);

    useEffect(() => {
        const selectJob = async (jobId: number) => {
            if (
                setSelectedJobId &&
                (userInfo?.loginType === BTLoginTypes.BUILDER ||
                    userInfo?.loginType === BTLoginTypes.SUBS)
            ) {
                await setSelectedJobId(jobId, undefined, true);
            }
            let url = nextUrl;
            if (url.startsWith("/app")) {
                url = url.replace("/app", "");
            }
            // remove after webforms is removed
            if (url.includes(".aspx")) {
                window.location.assign(url);
            } else {
                history.replace(url);
            }
        };
        const params = new URLSearchParams(history.location.search);
        const jobId = params.get("selectJobId");
        void selectJob(Number(jobId));
    }, [history, nextUrl, setSelectedJobId, userInfo?.loginType]);

    return <BTLoading />;
}
