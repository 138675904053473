import { Skeleton } from "antd";

import { BTTableCustom } from "commonComponents/btWrappers/BTTableCustom/BTTableCustom";

interface ISkeletonTableProps {
    /**
     * should show header row
     * @default true
     */
    headerRow?: boolean;

    columns: number;
    rows: number;

    /**
     * @default true
     */
    active?: boolean;
}

export const SkeletonTable: React.FunctionComponent<ISkeletonTableProps> = ({
    active = true,
    headerRow = true,
    rows,
    columns,
}) => {
    const rowsToFill: number[] = [];
    const columnsToFill: number[] = [];

    for (let row = 0; row < rows; row++) {
        rowsToFill.push(row);
    }

    for (let column = 0; column < columns; column++) {
        columnsToFill.push(column);
    }

    return (
        <BTTableCustom.Table>
            {headerRow && (
                <BTTableCustom.HeaderRow>
                    {columnsToFill.map((column) => (
                        <BTTableCustom.HeaderCell key={column}>
                            <Skeleton paragraph={false} active={active} />
                        </BTTableCustom.HeaderCell>
                    ))}
                </BTTableCustom.HeaderRow>
            )}
            {rowsToFill.map((row) => (
                <BTTableCustom.BodyRow key={row}>
                    {columnsToFill.map((column) => (
                        <BTTableCustom.BodyCell key={column}>
                            <Skeleton paragraph={false} active={active} />
                        </BTTableCustom.BodyCell>
                    ))}
                </BTTableCustom.BodyRow>
            ))}
        </BTTableCustom.Table>
    );
};
