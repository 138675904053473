import { Component } from "react";

export class ContextMenuOverlay extends Component {
    private stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    };

    render() {
        return (
            <div onContextMenu={this.stopPropagation} onDoubleClick={this.stopPropagation}>
                {this.props.children}
            </div>
        );
    }
}
