import Player, { Options } from "@vimeo/player";
import { createRef, PureComponent } from "react";
import { CommonProps } from "react-images";

import { withErrorBoundaryForwardRef } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

interface IVideoPlayerProps {
    vimeoId: number | string | null | undefined;
    vimeoUrl?: string;
    className?: string;
    width?: number;
    height?: number;
    onError?: () => void;
    autoPlay?: boolean;
    "data-testid"?: string;
}

export interface IVideoPlayer {
    pause: () => Promise<void>;
}

class VideoPlayerInternal
    extends PureComponent<IVideoPlayerProps & CommonProps>
    implements IVideoPlayer
{
    vimeoElRef = createRef<HTMLDivElement>();
    player: Player | undefined;
    _isMounted: boolean = false;

    async componentDidMount() {
        if (this.vimeoElRef.current) {
            const playerProps: Options = {
                id: Number(this.props.vimeoId ?? undefined),
                responsive: true,
                autoplay: this.props.autoPlay,
            };
            if (this.props.vimeoUrl) {
                playerProps.url = this.props.vimeoUrl;
            }

            if (this.props.height) {
                playerProps.height = this.props.height;
            }

            if (this.props.width) {
                playerProps.width = this.props.width;
            }
            this.player = new Player(this.vimeoElRef.current, playerProps);
            this.player.ready().catch((e) => {
                if (this._isMounted) {
                    this.setState(() => {
                        throw e;
                    });
                }
            });
            this._isMounted = true;
        }
    }

    async componentDidUpdate(prevProps: IVideoPlayerProps) {
        const player = this.player;

        if (
            player &&
            this.props.currentView &&
            this.props.currentView.source !== this.props.vimeoId
        ) {
            await player.getPaused().then(async (paused) => {
                if (!paused) {
                    await player.pause();
                }
            });
        }
        if (player && this.props.autoPlay !== prevProps.autoPlay) {
            if (this.props.autoPlay) {
                await player.getPaused().then(async (paused) => {
                    if (paused) {
                        await player.play();
                    }
                });
            } else {
                await player.getPaused().then(async (paused) => {
                    if (!paused) {
                        await player.pause();
                    }
                });
            }
        }
        if (prevProps.vimeoId !== this.props.vimeoId && this.vimeoElRef.current) {
            try {
                await player?.loadVideo(Number(this.props.vimeoId));
            } catch (e) {
                if (this.props.onError) {
                    this.props.onError();
                } else {
                    throw e;
                }
            }
        }
    }

    async componentWillUnmount() {
        this._isMounted = false;
        if (this.player) {
            await this.player.destroy();
        }
    }

    async pause() {
        const player = this.player;
        if (player) {
            await player.pause();
        }
    }

    render() {
        return (
            <div
                className={this.props.className}
                ref={this.vimeoElRef}
                data-testid={this.props["data-testid"]}
            />
        );
    }
}

export const VideoPlayer = withErrorBoundaryForwardRef(VideoPlayerInternal)(
    "Error loading video. Please try again later."
);
