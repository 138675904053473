import { DocumentInstanceType } from "types/enum";

import { CertificateType } from "commonComponents/entity/sub/CertificateFile/CertificateFile.types";

export interface IDocumentContextMenuRequest {
    associatedEntityId: number;
    docType: DocumentInstanceType | CertificateType;
}

export class DocumentContextMenuEntity {
    constructor(data: any) {
        this.contextMenuItems = data.contextMenuItems;
    }
    contextMenuItems: IContextMenuItem[];
}

export interface IContextMenuItem {
    itemId: number;
    itemName: string;
    itemType: ContextMenuItemType;
    jobId?: number;
    source: string;
    downloadLink: string;
    is360Media: boolean;
    videoId?: number;
    // set in the client
    mediaIndex?: number;
    icon?: JSX.Element;
    callback?: () => void;
    title?: string;
    isMediaAvailable: boolean;
}

export enum ContextMenuItemType {
    Generic = 0,
    Photo = 1,
    Video = 2,
    Pdf = 3,
    Excel = 4,
    Powerpoint = 5,
    Word = 6,
    Eps = 7,
    DownloadAll = 8,
}
