import { Input, Menu } from "antd";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Grey7 } from "styles/antTheme/Colors";

import { BTLocalStorage } from "types/btStorage";
import { BTLoginTypes } from "types/enum";

import { BTSorting } from "utilities/array/array";
import { handleEnterKeyPressed } from "utilities/form/form";
import { filterJobsList, selectJobs } from "utilities/jobPicker/jobPicker";
import { ListMetadata } from "utilities/list/list.types";
import { isInPortal } from "utilities/portal/portal";
import { getBaseRoute, routes } from "utilities/routes";
// eslint-disable-next-line no-restricted-imports
import { getMobileOS } from "utilities/userAgent/userAgent";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdownActions } from "commonComponents/btWrappers/BTDropdownActions/BTDropdownActions";
import {
    BTIconArrowLeftOutlined,
    BTIconCaretLeft,
    BTIconCaretRight,
    BTIconCloseCircle,
    BTIconMagnifyingGlass,
} from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTArrowButton } from "commonComponents/utilities/BTArrowButton/BTArrowButton";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import ContactInfoButton from "commonComponents/utilities/HeaderInfo/ContactInfoButton/ContactInfoButton";
import { IHeaderInfoResponse } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import {
    AccountSwitcherItem,
    JobPickerJob,
    JobPickerOwnerInfo,
    JobPickerPermissions,
} from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import {
    JobIdTypes,
    JobPickerDisplayModes,
    JobPickerSelectModes,
    JobSortOptions,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import {
    getCollapsed,
    isResponsiveAutoCollapsed,
    isUserPreferenceCollapsed,
} from "commonComponents/utilities/JobPicker/JobPicker.utils";
import { JobPickerEmptyStateBanner } from "commonComponents/utilities/JobPicker/JobPickerEmptyState/JobPickerEmptyStateBanner";
import { JobPickerJobInfoSection } from "commonComponents/utilities/JobPicker/Sections/JobInfo";
import {
    getAddJobLink,
    JobPickerFiltersPopover,
    JobPickerSortPopover,
} from "commonComponents/utilities/JobPicker/Sections/JobPickerPopovers";
import { JobPickerJobsList } from "commonComponents/utilities/JobPicker/Sections/JobsList";
import { NewJobPrompt } from "commonComponents/utilities/NewJobPrompt/NewJobPrompt";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";

import {
    FilterEntity,
    IFilterFormValues,
    SelectedFilterItem,
} from "entity/filters/Filter/Filter.api.types";
import { JobRunningTotal } from "entity/job/Job.api.types";
import { TemplateListTabs } from "entity/template/TemplateList/TemplateListTab/TemplateListTab.api.types";

export interface IJobPickerProps extends RouteComponentProps {
    onJobPickerJobChanged: (job: JobPickerJob, keywordSearch: string) => Promise<void>;
    onJobPickerBuilderChanged: (builderId: number) => Promise<void>;
    selectedJobIds: number[] | number | null;
    jobs: JobPickerJob[];
    jobRunningTotal?: JobRunningTotal;
    listMetadata: ListMetadata;

    availableAccounts: AccountSwitcherItem[];

    onAccountChange?: (newAccounts: AccountSwitcherItem[]) => Promise<void>;
    sortOption?: JobSortOptions;
    filters: FilterEntity;
    selectedFilters: SelectedFilterItem[] | undefined;
    selectedFilterId?: number;
    displayMode: JobPickerDisplayModes;
    templatesOnly: boolean;
    onCollapse?: (isCollapsing: boolean) => void;
    onFilterSubmit: (filterValues: IFilterFormValues, keyword: string) => Promise<void>;
    onSavedFiltersUpdated: (filters: FilterEntity) => void;
    permissions: JobPickerPermissions;
    ownerInfo: JobPickerOwnerInfo;
    onTemplateModeChanged: (
        templatesOnly: boolean,
        builderId: number,
        initialTemplateTab?: TemplateListTabs
    ) => Promise<void>;
    onSortChanged: (sortOption: JobSortOptions) => Promise<void>;
    selectMode: JobPickerSelectModes;
    keywordSearch: string;
    onKeywordClear: () => Promise<void>;
    onDismissBanner: () => Promise<void>;
    loading: boolean;
    onSavedFilterVisibleChange?: (visible: boolean) => void;
    areSavedFiltersShowing: boolean;
    className?: string;
    headerInfo?: IHeaderInfoResponse;
    summaryUrl?: string;
    width: number;
    isConnectedToAccounting: boolean;
    builderId: number;
    userId: number;
    jobId: number;
    fromReact: boolean;
    isTemplateMode: boolean;
    onResizeWindow?: (collapsed: boolean) => void;
    isListLimited?: boolean;
    onServerKeywordUpdate?: (newKeywordSearch: string) => Promise<void>;
}

export interface IJobPickerJobProps extends IJobPickerProps {
    currentJob: JobPickerJob;
    style?: React.CSSProperties;
}

const minSidebarWidth = 250;
const maxSidebarWidth = 450;

const noOpt = () => {};

export const JobPicker: React.FunctionComponent<IJobPickerProps> = (props) => {
    const [collapsed, setCollapsed] = useState(isResponsiveAutoCollapsed());
    const [initialized, setInitialized] = useState(false);
    const [width, setWidth] = useState(props.width);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [keywordSearch, setKeywordSearch] = useState(props.keywordSearch);
    const [showSortPopover, setShowSortPopover] = useState(false);
    const [showFilterPopover, setShowFilterPopover] = useState(false);
    const [showMoreThanOneHundred, setShowMoreThanOneHundred] = useState(false);
    const [isEmptyStateBannerDismissed, setIsEmptyStateBannerDismissed] = useState(
        !props.listMetadata.emptyStatesMetadata.isNewToEntity
    );
    const [quickAddOpen, setQuickAddOpen] = useState(false);
    const {
        sortOption,
        templatesOnly,
        onCollapse = noOpt,
        onTemplateModeChanged,
        jobs,
        builderId,
        isTemplateMode,
    } = props;
    const closeTimeout = useRef<number | null>(null);

    // If possible, use media queries in CSS to define mobile-specific behaivor instead of this
    const mobileOS = getMobileOS();
    const isMobile = mobileOS === "Android" || mobileOS === "iOS";

    useEffect(() => {
        setCollapsed(getCollapsed());
    }, []);

    // This hides the closed component during initial load to prevent glitchy animation (duration 0.2s)
    useEffect(() => {
        if (collapsed) {
            const t = setTimeout(() => setInitialized(true), 200);
            return () => clearTimeout(t);
        } else {
            setInitialized(true);
            return () => {};
        }
    }, [collapsed]);

    useEffect(() => {
        if (mobileOS !== "iOS") {
            window.addEventListener("scroll", closeDropdowns);
            return () => {
                window.removeEventListener("scroll", closeDropdowns);
            };
        }
        return undefined;
    });

    const collapseTimeout = useRef<number | null>(null);
    const handleCollapse = useCallback(
        (newState: boolean) => {
            if (!newState && drawerOpen) {
                // Soft drawer is turning into a normal expanded state, delay the transition to hide any flashing
                collapseTimeout.current = setTimeout(() => {
                    setDrawerOpen(false);
                    setCollapsed(newState);
                }, 200);
            } else {
                setCollapsed(newState);
            }

            onCollapse(newState);
        },
        [drawerOpen, onCollapse]
    );

    const handleToggleCollapse = useCallback(() => {
        // When the page is too small, instead of expanding the jobpicker open it over the top of the page
        if (isResponsiveAutoCollapsed()) {
            let isCollapsed: boolean = collapsed;

            if (isCollapsed && drawerOpen) {
                isCollapsed = !isCollapsed;
            }

            setDrawerOpen(isCollapsed);

            window.btMaster.setSidebarSize(collapsed);

            return;
        }

        // only save the preference when the user is performing the action
        BTLocalStorage.set("bt-string-jobPickerPreference", collapsed ? "open" : "closed");

        handleCollapse(!collapsed);
    }, [drawerOpen, collapsed, handleCollapse]);

    useEffect(() => {
        return () => {
            if (collapseTimeout.current) {
                clearTimeout(collapseTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        const resizeListener = (): void => {
            let isCollapsed = collapsed;
            if (!collapsed && isResponsiveAutoCollapsed()) {
                isCollapsed = true;
                handleCollapse(isCollapsed);
            } else if (collapsed && !isUserPreferenceCollapsed() && !isResponsiveAutoCollapsed()) {
                isCollapsed = false;
                handleCollapse(false);
            }
            if (props.onResizeWindow) {
                props.onResizeWindow(isCollapsed);
            }
        };

        window.addEventListener("resize", resizeListener, { passive: true });

        return () => window.removeEventListener("resize", resizeListener);
    });

    const handleResize = useCallback((x: number) => {
        if (x < minSidebarWidth) {
            x = minSidebarWidth;
        }
        if (x > Math.min(maxSidebarWidth, window.innerWidth / 2)) {
            x = Math.min(maxSidebarWidth, window.innerWidth / 2);
        }
        setWidth(x);
        BTLocalStorage.set("bt-number-sidebarWidth", x);
        window.dispatchEvent(new CustomEvent("resize"));
    }, []);

    const handleDrawerOpen = useCallback(
        (e: React.MouseEvent) => {
            if (closeTimeout.current !== null) {
                clearInterval(closeTimeout.current);
            }

            if (collapsed && !isMobile && !drawerOpen) {
                // To make this more reponsive and work with any adjustment to the main nav height
                // we will grab the element and check against the sidebar element position and widow scroll postion instead of just
                // hardcoded navHeight and page position.
                // Todo: once we are fully SPA looking into doing this with just CSS on the elements in use.

                const elPos = e.currentTarget.getBoundingClientRect();
                const hoverHitbox = elPos.top + window.scrollY + 100;
                if (e.clientY > hoverHitbox) {
                    setDrawerOpen(true);
                }
            }
        },
        [collapsed, isMobile, drawerOpen]
    );

    const handleDrawerClose = useCallback(() => {
        if (collapsed && drawerOpen) {
            setDrawerOpen(false);
            closeDropdowns();
        }
    }, [collapsed, drawerOpen]);

    const startDrawerClose = useCallback(() => {
        // We DO NOT want to call handleDrawerClose() on Mobile Full Site
        if (!isMobile && collapsed && drawerOpen) {
            if (document.querySelectorAll(popoverSelector).length > 0) {
                // Allow a second for the user to move the mouse back into the soft drawer when closing popups
                closeTimeout.current = setTimeout(handleDrawerClose, 1000);
            } else {
                handleDrawerClose();
            }
        }
    }, [collapsed, drawerOpen, isMobile, handleDrawerClose]);

    const getSortOption = useMemo(() => {
        return sortOption !== undefined && !templatesOnly ? sortOption : JobSortOptions.Alphabetic;
    }, [sortOption, templatesOnly]);
    const templateSwitcher = useMemo(() => {
        return (
            <div className="flex-grow-1">
                <BTDropdownActions
                    accessibilityHack
                    buttonProps={{
                        isolated: true,
                        noShadow: true,
                    }}
                    overlay={
                        <BTMenu>
                            <BTMenuItem
                                data-testid="jobMenu"
                                onClick={() => onTemplateModeChanged(false, props.builderId)}
                                key="jobMenu"
                            >
                                Jobs
                            </BTMenuItem>
                            <BTMenuItem
                                data-testid="templateMenu"
                                onClick={() => onTemplateModeChanged(true, props.builderId)}
                                key="templateMenu"
                            >
                                Templates
                            </BTMenuItem>
                        </BTMenu>
                    }
                    // Class name referenced in ResizableJobPicker.tsx
                    className="JobOrTemplateMenu"
                    data-testid="JobsiteOrTemplateMenu"
                    type="link"
                >
                    {templatesOnly ? "Templates" : "Jobs"}
                </BTDropdownActions>
            </div>
        );
    }, [onTemplateModeChanged, templatesOnly, props.builderId]);
    const hasJobOtherThanAllListed = useMemo(() => {
        return jobs.length > 0 && (jobs.length > 1 || jobs[0].jobId !== JobIdTypes.AllJobs);
    }, [jobs]);

    const sortSpecialJobs = useCallback((left: JobPickerJob, right: JobPickerJob) => {
        // All Listed Should Always be on top
        if (left.jobId === JobIdTypes.AllJobs) {
            return -1;
        }
        if (right.jobId === JobIdTypes.AllJobs) {
            return 1;
        }
        if (left.jobId < 1 || right.jobId < 1) {
            // Special job
            return left.jobId - right.jobId;
        }
        return null;
    }, []);

    const sortByStartDate = useCallback(
        (left: JobPickerJob, right: JobPickerJob) => {
            const specialJobSort = sortSpecialJobs(left, right);
            if (specialJobSort !== null) {
                return specialJobSort;
            }
            // subtract dates to compare
            const diff = left.projectedStartDate.valueOf() - right.projectedStartDate.valueOf();
            if (diff !== 0) {
                return diff;
            }

            return BTSorting.sortBySQL_Latin1_General_CP1_CS_AS(
                left.jobName.toLowerCase(),
                right.jobName.toLowerCase()
            );
        },
        [sortSpecialJobs]
    );

    const sortByCreatedDate = useCallback(
        (left: JobPickerJob, right: JobPickerJob) => {
            const specialJobSort = sortSpecialJobs(left, right);
            if (specialJobSort !== null) {
                return specialJobSort;
            }
            // subtract dates to compare
            const diff = left.dateOpened.valueOf() - right.dateOpened.valueOf();
            if (diff !== 0) {
                return diff;
            }

            return BTSorting.sortBySQL_Latin1_General_CP1_CS_AS(
                left.jobName.toLowerCase(),
                right.jobName.toLowerCase()
            );
        },
        [sortSpecialJobs]
    );

    const getJobsList = useCallback(() => {
        let sortedJobsList: JobPickerJob[];

        const filteredJobsList = filterJobsList(props.jobs, keywordSearch, props.templatesOnly);

        let sortOption = getSortOption;
        const list = [...filteredJobsList];
        switch (sortOption) {
            case JobSortOptions.Alphabetic:
                sortedJobsList = list.sort((left, right) => {
                    const specialJobSort = sortSpecialJobs(left, right);
                    if (specialJobSort !== null) {
                        return specialJobSort;
                    }
                    return BTSorting.sortBySQL_Latin1_General_CP1_CS_AS(
                        left.jobName.toLowerCase(),
                        right.jobName.toLowerCase()
                    );
                });
                break;
            case JobSortOptions.StartDateProjected:
                sortedJobsList = list.sort(sortByStartDate);
                break;
            case JobSortOptions.CreatedDate:
                sortedJobsList = list.sort(sortByCreatedDate);
                break;
            case JobSortOptions.CloseDate:
                sortedJobsList = list.sort((left, right) => {
                    const specialJobSort = sortSpecialJobs(left, right);
                    if (specialJobSort !== null) {
                        return specialJobSort;
                    }
                    // subtract dates to compare
                    const diff =
                        left.projectedClosingDate.valueOf() - right.projectedClosingDate.valueOf();
                    if (diff !== 0) {
                        return diff;
                    }
                    // sort by project start date as secondary sort
                    const startDiff =
                        left.actualStartDate.valueOf() - right.actualStartDate.valueOf();
                    if (startDiff !== 0) {
                        return startDiff;
                    }

                    // fall back to jobName
                    return BTSorting.sortBySQL_Latin1_General_CP1_CS_AS(
                        left.jobName.toLowerCase(),
                        right.jobName.toLowerCase()
                    );
                });
                break;
            default:
                throw new Error("Unable to sort for this option");
        }
        if (!showMoreThanOneHundred) {
            return { count: sortedJobsList.length, jobs: sortedJobsList.slice(0, 100) };
        }
        return { count: sortedJobsList.length, jobs: sortedJobsList };
    }, [
        getSortOption,
        keywordSearch,
        props.jobs,
        props.templatesOnly,
        showMoreThanOneHundred,
        sortByCreatedDate,
        sortByStartDate,
        sortSpecialJobs,
    ]);

    const onClickOut = useCallback((e: React.MouseEvent) => {
        if (!(e.target as Element).matches(".QuickAddDropdownMenu *")) {
            setQuickAddOpen(false);
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).addEventListener("click", onClickOut);
        return () => {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).removeEventListener("click", onClickOut);
        };
    }, [onClickOut]);

    const onScroll = useCallback(() => {
        if (quickAddOpen) {
            setQuickAddOpen(false);
        }
    }, [quickAddOpen]);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).addEventListener("scroll", onScroll);
        return () => {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    const onRecommendedTemplatesClick = useCallback(async () => {
        if (isTemplateMode) {
            window.location.assign(
                getBaseRoute() + routes.template.getListLink(TemplateListTabs.RecommendedTemplates)
            );
        } else {
            await onTemplateModeChanged(true, builderId, TemplateListTabs.RecommendedTemplates);
        }
    }, [isTemplateMode, builderId, onTemplateModeChanged]);

    const innerContent = useMemo(() => {
        const showNewJobPromptButton = props.permissions.canAddJobs;

        return (
            <>
                <div className="JobPickerHeader">
                    {" "}
                    {/* While JobPickerHeader is not used in CSS, it is used in the jobpicker wrapper to get the height and fix any scrollbar issues, keep this class here */}
                    {/* props isLoading */}
                    {!props.loading && (
                        <SidebarHeader
                            availableAccounts={props.availableAccounts}
                            onAccountChange={props.onAccountChange}
                            logoPath={props.headerInfo?.logoPath}
                            summaryUrl={props.summaryUrl}
                        />
                    )}
                    {props.headerInfo?.builderName && (
                        <ShowOnPortal
                            subs
                            render={() => (
                                <div className="padding-bottom-xs text-center">
                                    <ContactInfoButton
                                        history={props.history}
                                        match={props.match}
                                        location={props.location}
                                        parentUrl={props.match.url}
                                        jobId={props.jobId}
                                    >
                                        Contact Info
                                    </ContactInfoButton>
                                </div>
                            )}
                        />
                    )}
                    <div className="ActionContainer">
                        {showNewJobPromptButton && (
                            <ShowOnPortal
                                builder={props.permissions.canAddJobs}
                                render={() => (
                                    <div className="AddCondensedJob">
                                        <NewJobPrompt
                                            jobRoute={getAddJobLink(templatesOnly, true, true)}
                                            templateRoute={getAddJobLink(
                                                templatesOnly,
                                                false,
                                                true
                                            )}
                                            block
                                            newName={props.isTemplateMode ? "Template" : "Job"}
                                            onRecommendedTemplatesClick={
                                                onRecommendedTemplatesClick
                                            }
                                        />
                                    </div>
                                )}
                            />
                        )}
                        <BTRow align="middle">
                            <ShowOnPortal
                                subs
                                render={() => <div className="SubJobLabel">Jobs</div>}
                            />
                            <ShowOnPortal builder render={() => templateSwitcher} />
                            <JobPickerFiltersPopover
                                {...props}
                                onFilterSubmit={async (filterValues: IFilterFormValues) => {
                                    return await props.onFilterSubmit(filterValues, keywordSearch);
                                }}
                                showPopover={showFilterPopover}
                                onVisibleChange={(visible) => {
                                    setShowFilterPopover(visible);
                                }}
                                sortOption={getSortOption}
                                shouldCloseOnScroll={getMobileOS() !== "iOS"}
                            />
                            {!props.templatesOnly && (
                                <JobPickerSortPopover
                                    {...props}
                                    sortOption={getSortOption}
                                    showPopover={showSortPopover}
                                    onVisibleChange={(visible) => {
                                        setShowSortPopover(visible);
                                    }}
                                />
                            )}
                        </BTRow>
                    </div>
                    {props.listMetadata && (
                        <div className="EmptyState">
                            <JobPickerEmptyStateBanner
                                actionBeingPerformed={undefined}
                                hasFilteredData={
                                    props.listMetadata.emptyStatesMetadata.hasFilteredData
                                }
                                hasListData={props.listMetadata.hasData}
                                isDismissed={isEmptyStateBannerDismissed}
                                isNewToEntity={props.listMetadata.emptyStatesMetadata.isNewToEntity}
                                isReadonly={props.listMetadata.emptyStatesMetadata.isReadOnly}
                                onCallToActionClick={() => {}}
                                onDismiss={async () => {
                                    if (props.onDismissBanner) {
                                        await props.onDismissBanner();
                                    }
                                    setIsEmptyStateBannerDismissed(true);
                                }}
                                canClearSearch={false}
                                isBanner
                                mode={props.templatesOnly ? "Template" : "Job"}
                                isConnectedToAccounting={props.isConnectedToAccounting}
                            />
                        </div>
                    )}
                    {(hasJobOtherThanAllListed ||
                        props.listMetadata.emptyStatesMetadata.hasFilteredData ||
                        keywordSearch) && (
                        <>
                            <div className="SearchContainer">
                                {/* Not Using BTSearch here because we need to hide the icon when there is text, and that isn't possible with <Input.Search> */}
                                {/* eslint-disable-next-line react/forbid-elements */}
                                <Input
                                    id="JobSearch"
                                    className="JobSearch"
                                    readOnly={props.keywordSearch !== ""} // It will be a prop if the search text has been committed. That means it's locked down until the user clears it
                                    suffix={
                                        keywordSearch === "" ? (
                                            <BTIconMagnifyingGlass
                                                size={16}
                                                style={{ color: Grey7 }}
                                            />
                                        ) : (
                                            <BTButton
                                                data-testid="clear-search"
                                                className="JobSearch-Clear"
                                                type="link"
                                                icon={
                                                    <BTIconCloseCircle
                                                        size={16}
                                                        className="ant-input-clear-icon"
                                                    />
                                                }
                                                onClick={async () => {
                                                    setKeywordSearch("");
                                                    if (props.keywordSearch !== "") {
                                                        await props.onKeywordClear();
                                                    }
                                                }}
                                            />
                                        )
                                    } // We show the search icon when it's empty, then we show the clear icon. Ant handles the clear icon part
                                    data-testid="JobSearch"
                                    value={keywordSearch}
                                    // Disable form submit on enter. Otherwise it opens the clock-in/clock-out dialog
                                    onKeyPress={(e: KeyboardEvent | React.KeyboardEvent<Element>) =>
                                        handleEnterKeyPressed(e, () => {})
                                    }
                                    onChange={async (value: { target: { value: string } }) => {
                                        setKeywordSearch(value.target.value);
                                        // They cleared out their keyword, which was previously committed. Go trigger a refresh and update
                                        if (
                                            props.keywordSearch !== "" &&
                                            value.target.value === ""
                                        ) {
                                            await props.onKeywordClear();
                                        } else if (props.isListLimited) {
                                            await props.onServerKeywordUpdate?.(value.target.value);
                                        }
                                    }}
                                />
                            </div>
                            <JobPickerJobInfoSection {...props} />
                        </>
                    )}
                </div>
                {props.loading && (
                    <div
                        style={{
                            position: "relative",
                            width: props.width,
                            height: "100%",
                        }}
                    >
                        <BTLoading displayMode="absolute" />
                    </div>
                )}
                {!props.loading &&
                    (hasJobOtherThanAllListed ||
                        props.listMetadata.emptyStatesMetadata.hasFilteredData ||
                        keywordSearch) && (
                        <JobPickerJobsList
                            {...props}
                            width={width}
                            getJobsList={getJobsList}
                            onShowMoreThanOneHundred={() => setShowMoreThanOneHundred(true)}
                            keywordSearch={keywordSearch}
                            clearKeywordSearch={async () => {
                                setKeywordSearch("");
                                if (props.keywordSearch !== "") {
                                    await props.onKeywordClear();
                                }
                            }}
                        />
                    )}
            </>
        );
    }, [
        getJobsList,
        getSortOption,
        hasJobOtherThanAllListed,
        isEmptyStateBannerDismissed,
        keywordSearch,
        props,
        showFilterPopover,
        showSortPopover,
        templateSwitcher,
        width,
        templatesOnly,
        onRecommendedTemplatesClick,
    ]);

    let barWidth = undefined;
    if (collapsed) {
        barWidth = drawerOpen ? width + 5 : width;
    }

    let jobPickerMarginLeft = undefined;
    if (collapsed && !drawerOpen) {
        jobPickerMarginLeft = -(width - 5);
        jobPickerMarginLeft = jobPickerMarginLeft + 12;
    }

    let mouseProps:
        | {
              onMouseMove: (e: React.MouseEvent) => void;
              onMouseLeave: () => void;
          }
        | undefined = undefined;

    if (!isMobile) {
        mouseProps = {
            onMouseMove: handleDrawerOpen,
            onMouseLeave: startDrawerClose,
        };
    }

    return (
        <div
            className={classNames("ResizableJobPickerContainer", {
                Drawer: drawerOpen,
            })}
            style={{
                width: barWidth,
                marginLeft: jobPickerMarginLeft,
                marginRight: isInPortal({ subs: true }) ? 20 : undefined,
                display: initialized ? undefined : "none",
            }}
            {...mouseProps}
        >
            <div
                id="reactJobPicker"
                className={classNames("JobPickerContainer", "ResizableJobPicker")}
                style={{
                    position: "sticky",
                    maxHeight: "100%",
                    width: "100%",
                }}
            >
                <div
                    className="JobPickerReact"
                    style={{
                        width: width, // needed for SPA display consistency
                    }}
                >
                    {innerContent}
                </div>
            </div>
            <SidebarResizeHandle
                {...props}
                collapsed={collapsed}
                onCollapse={handleToggleCollapse}
                onResize={handleResize}
                drawerOpen={drawerOpen}
            />
        </div>
    );
};

interface IHandleProps {
    collapsed: boolean;
    drawerOpen: boolean;
    onResize: (dx: number) => void;
}

const SidebarResizeHandle: React.FunctionComponent<IJobPickerProps & IHandleProps> = (props) => {
    const { onResize } = props;
    const [dragShield, setDragShield] = useState(false);

    const handleResize = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onResize(e.x);
        },
        [onResize]
    );

    const endResize = useCallback(() => {
        window.removeEventListener("mousemove", handleResize);
        window.removeEventListener("mouseup", endResize);
        setDragShield(false);
        toggleWebformsResizing(false);
    }, [handleResize]);

    useEffect(() => {
        return () => {
            window.removeEventListener("mousemove", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        return () => {
            window.removeEventListener("mouseup", endResize);
        };
    }, [endResize]);

    return (
        <div
            className={classNames("SidebarResizeHandle", {
                Closed: props.collapsed,
                Drawer: props.drawerOpen,
            })}
            onMouseDown={(e) => {
                if (
                    !props.collapsed &&
                    (e.target as HTMLElement).classList.contains("SidebarResizeHandle")
                ) {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleWebformsResizing(true);
                    window.addEventListener("mousemove", handleResize);
                    window.addEventListener("mouseup", endResize);
                    setDragShield(true);
                }
            }}
        >
            <BTButton
                data-testid="sidebarOpenButton"
                className={classNames("SidebarOpenButton", {
                    Closed: props.collapsed,
                    Drawer: props.drawerOpen,
                })}
                icon={
                    props.collapsed && !props.drawerOpen ? (
                        <BTIconCaretRight />
                    ) : (
                        <BTIconCaretLeft />
                    )
                }
                type="ghost"
                onClick={() => props.onCollapse!(!props.collapsed)}
            />
            {/* This will block other mouse events during drag */}
            {dragShield && <div className="SidebarResizeDragShield" />}
        </div>
    );
};

export const ResizableJobPickerLoading: React.FunctionComponent = () => {
    const ref = useRef<HTMLDivElement>(null);
    return (
        <div ref={ref} className="ResizableJobPickerLoading">
            {(ref.current?.clientWidth || 0) < 100 ? (
                <div className="CollapsedLoading" />
            ) : (
                <BTLoading displayMode="absolute" />
            )}
        </div>
    );
};

const popoverSelector = ".ActionIcon.ant-popover-open";

function closeDropdowns() {
    // Need to close any dropdowns that we have open in the drawer
    // Todo: Major DOM search. This will be a performance hit. We should look into a better approach.
    document
        .querySelectorAll(
            `.AccountSwitcherDropdown.ant-dropdown-open, 
            .JobOrTemplateMenu.ant-dropdown-open,
            ${popoverSelector}`
        )
        .forEach((el) => (el as HTMLElement).click());
}

// REMOVE in SPA
function toggleWebformsResizing(val: boolean) {
    // Todo: There should only be one element with this ID. This will be a performance hit. We should at the very least use getElementById
    // if we can not handle this with react props or state.
    document
        .querySelectorAll("#reactJobPickerPlaceholder")
        .forEach((el) =>
            val ? el.classList.add("IsResizing") : el.classList.remove("IsResizing")
        );
}

interface ISidebarHeaderProps {
    availableAccounts: AccountSwitcherItem[];
    onAccountChange?: (newAccounts: AccountSwitcherItem[]) => Promise<void>;
    logoPath?: string | null;
    summaryUrl?: string;
}

const SidebarHeader: React.FunctionComponent<ISidebarHeaderProps> = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const isPreview = queryParams.toString().includes("preview=true");

    // If in preview mode, do not collapse
    const [logoCollapsed, setLogoCollapsed] = useState(
        isPreview ? false : BTLocalStorage.get("bt-boolean-logoCollapsed")
    );
    const [logoLoaded, setLogoLoaded] = useState(false);
    const [useTransitions, setTransitions] = useState(false);
    const [accountSwitchMenuOpen, setAccountSwitchMenuOpen] = useState(false);
    const [accountSwitchLoading, setAccountSwitchLoading] = useState(false);
    const transitionTimeout = useRef<number | null>(null);

    useEffect(() => {
        return () => {
            if (transitionTimeout.current) {
                clearTimeout(transitionTimeout.current);
            }
        };
    }, []);

    const handleLogoLoaded = useCallback(() => {
        setLogoLoaded(true);
        // We don't want CSS transitions on the initial load
        transitionTimeout.current = setTimeout(() => setTransitions(true), 200);
    }, []);

    const handleAccountChange = async (items: AccountSwitcherItem[]) => {
        setAccountSwitchMenuOpen(false);

        if (props?.onAccountChange) {
            setAccountSwitchLoading(true);
            // Ensures the MainNav component retrieves the correct count for the selected builder(s)
            BTLocalStorage.remove("bt-number-unreadNotificationCount");
            BTLocalStorage.remove("bt-boolean-hasUnreadNotifications");
            BTLocalStorage.remove("bt-boolean-hasUnreadChat");
            selectJobs([], false);
            await props.onAccountChange(items);
            setAccountSwitchLoading(false);
        }
    };

    const handleLogoCollapsed = useCallback(() => {
        setLogoCollapsed(!logoCollapsed);
        BTLocalStorage.set("bt-boolean-logoCollapsed", !logoCollapsed);
    }, [logoCollapsed]);

    const showAccountSwitcher = props.availableAccounts.length > 1;

    return (
        <div className="LogoContainer">
            {showAccountSwitcher && (
                <AccountSwitcher
                    onAccountSwitched={handleAccountChange}
                    accountSwitchLoading={accountSwitchLoading}
                    availableAccounts={props.availableAccounts}
                    collapsed={logoLoaded && logoCollapsed}
                    menuOpen={accountSwitchMenuOpen}
                    onMenuOpen={setAccountSwitchMenuOpen}
                />
            )}
            {!showAccountSwitcher && (
                <div
                    className="BackToSummary"
                    style={{
                        visibility: logoCollapsed && logoLoaded ? undefined : "hidden",
                        marginBottom: logoCollapsed && logoLoaded ? undefined : -46,
                    }}
                >
                    <BTLink
                        useAutoSPARouting
                        to={props.summaryUrl ?? ""}
                        className="flex align-items-center"
                    >
                        <BTIconArrowLeftOutlined className="margin-right-xs" />
                        <span>Back to Summary</span>
                    </BTLink>
                </div>
            )}
            {props.logoPath && (
                <BuilderLogo
                    url={props.logoPath}
                    summaryUrl={props.summaryUrl}
                    collapsed={logoCollapsed}
                    menuOpen={accountSwitchMenuOpen}
                    onLogoLoad={handleLogoLoaded}
                    useTransitions={useTransitions}
                    showAccountSwitcher={showAccountSwitcher}
                />
            )}
            {logoLoaded && (
                <div className={classNames("LogoToggle", { Collapsed: logoCollapsed })}>
                    <BTPopover
                        placement="bottom"
                        mouseEnterDelay={1}
                        content={`Click to ${logoCollapsed ? "expand" : "collapse"}`}
                    >
                        <div className="LogoToggleBar" onClick={handleLogoCollapsed}>
                            <BTArrowButton
                                orientation={logoCollapsed ? "down" : "up"}
                                onClick={handleLogoCollapsed}
                            />
                        </div>
                    </BTPopover>
                </div>
            )}
        </div>
    );
};

interface IAccountSwitcherProps {
    accountSwitchLoading: boolean;
    availableAccounts: AccountSwitcherItem[];
    onAccountSwitched: (accounts: AccountSwitcherItem[]) => Promise<void>;
    collapsed: boolean;
    menuOpen: boolean;
    onMenuOpen: (newVal: boolean) => void;
}

const AccountSwitcher: React.FunctionComponent<IAccountSwitcherProps> = (props) => {
    const portalLabel = isInPortal({ builder: true }) ? "Builder portal" : "Sub/Vendor portal";

    const userAccounts: AccountSwitcherItem[] = [];
    const subAccounts: AccountSwitcherItem[] = [];

    // We want to show special highlighting when selecting all sub accounts
    let numSubsAvailable = 0,
        numSubsSelected = 0;

    // One loop to organize our data
    props.availableAccounts.forEach((a) => {
        (a.loginType === BTLoginTypes.BUILDER ? userAccounts : subAccounts).push(a);
        numSubsAvailable += a.inSession ? 1 : 0;
        numSubsSelected += a.selected ? 1 : 0;
    });

    const handleAccountSwitched = useCallback(
        async (accounts: AccountSwitcherItem[]) => {
            const numSelected = props.availableAccounts.filter((a) => a.selected).length;
            if (numSelected !== accounts.length || accounts.some((a) => !a.selected)) {
                await props.onAccountSwitched(accounts);
            }
        },
        [props]
    );

    const showBoth = userAccounts.length > 0 && subAccounts.length > 0;
    const menu = (
        <BTMenu>
            {(showBoth || userAccounts.length > 1) && (
                <Menu.ItemGroup title="Your internal user accounts">
                    {userAccounts.map((a, i) => (
                        <AccountSwitcherMenuItem
                            key={`users_${i}`}
                            item={a}
                            onAccountSwitched={handleAccountSwitched}
                            allSubsSelected={false}
                        />
                    ))}
                </Menu.ItemGroup>
            )}
            {(showBoth || subAccounts.length > 1) && (
                <Menu.ItemGroup title="Builders you work with">
                    {numSubsAvailable > 1 && (
                        <BTMenuItem
                            data-testid="all_subs"
                            key="all_subs"
                            className={classNames("All", {
                                Selected: numSubsSelected > 1,
                            })}
                            onClick={() =>
                                handleAccountSwitched(subAccounts.filter((a) => a.inSession))
                            }
                        >
                            <div>{`All ${numSubsAvailable} builders`}</div>
                        </BTMenuItem>
                    )}
                    {subAccounts.map((a, i) => (
                        <AccountSwitcherMenuItem
                            key={`subs_${i}`}
                            item={a}
                            onAccountSwitched={handleAccountSwitched}
                            allSubsSelected={numSubsSelected > 1}
                        />
                    ))}
                </Menu.ItemGroup>
            )}
        </BTMenu>
    );

    return (
        <div className={classNames("AccountSwitcher", { Collapsed: props.collapsed })}>
            {props.menuOpen && <div className="PortalLabel">{portalLabel}</div>}
            <BTDropdownActions
                data-testid="accountSwitcher"
                type="link"
                // Class name referenced in ResizableJobPicker.tsx
                className="AccountSwitcherDropdown"
                overlayClassName="AccountSwitcherOverlay"
                buttonProps={{
                    isolated: true,
                    noShadow: true,
                    loading: props.accountSwitchLoading,
                }}
                overlay={menu}
                visible={props.menuOpen}
                onVisibleChange={(val) => props.onMenuOpen(val)}
            >
                {numSubsSelected > 1
                    ? `All ${numSubsSelected} builders`
                    : props.availableAccounts.find((a) => a.selected)?.builderName}
            </BTDropdownActions>
        </div>
    );
};

interface IAccountSwitcherMenuItemProps {
    item: AccountSwitcherItem;
    allSubsSelected: boolean;
    onAccountSwitched: (accounts: AccountSwitcherItem[]) => void;
}

const AccountSwitcherMenuItem: React.FunctionComponent<IAccountSwitcherMenuItemProps> = (props) => {
    const { item, onAccountSwitched, ...restProps } = props;
    return (
        <BTMenuItem
            data-testid={`accountSwitcher-${item.builderId}`}
            {...restProps}
            onClick={() => onAccountSwitched([item])}
            className={classNames({
                Selected: !props.allSubsSelected && item.selected,
                InSession: item.inSession,
            })}
        >
            <div>{item.builderName}</div>
            <div className="AccountName">{item.name}</div>
        </BTMenuItem>
    );
};

interface IBuilderLogoProps {
    url: string;
    summaryUrl?: string;
    collapsed: boolean;
    menuOpen: boolean;
    useTransitions: boolean;
    showAccountSwitcher: boolean;
    onLogoLoad: () => void;
}

const maxLogoWidth = 220;
const miniLogoDimensions = 32;
const maxLogoHeight = 200;

const BuilderLogo: React.FunctionComponent<IBuilderLogoProps> = (props) => {
    const [height, setHeight] = useState(0);
    const { url, onLogoLoad } = props;

    // Determine the height the image should render at given the aspect ratio.
    // Combined with the CSS this will smoothly restrict the container height.
    useEffect(() => {
        if (!height) {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                setHeight(
                    Math.min(maxLogoHeight, Math.floor((img.height * maxLogoWidth) / img.width))
                );
                onLogoLoad();
            };
        }
    }, [height, url, onLogoLoad]);

    // Fit minimized logo within a 32x32 box
    let transformProps: React.CSSProperties = {};
    if (props.collapsed && height > 0) {
        let scale = miniLogoDimensions / height;
        if (scale * maxLogoWidth > miniLogoDimensions) {
            scale = miniLogoDimensions / maxLogoWidth;
        }
        transformProps.top = 16 + Math.floor(0.5 * (miniLogoDimensions - scale * height));
        transformProps.transform = `scale(${scale})`;
    }
    const isWebforms = props.summaryUrl?.endsWith("aspx");
    return (
        <>
            <div
                className={classNames("LogoPlaceholder", { UseTransitions: props.useTransitions })}
                style={{
                    height: props.collapsed ? 0 : height,
                }}
            />
            <BTLink
                useAutoSPARouting={!isWebforms}
                to={props.summaryUrl && !isWebforms ? props.summaryUrl : ""}
                href={isWebforms ? props.summaryUrl : undefined}
                className={classNames("BuilderLogoLink", {
                    Collapsed: props.collapsed,
                    UseTransitions: props.useTransitions,
                    WithSwitcher: props.showAccountSwitcher,
                })}
                style={{ ...transformProps, marginTop: props.menuOpen ? 24 : undefined }}
            >
                <div className="BuilderLogo" style={{ height, width: maxLogoWidth }}>
                    <div
                        className="BuilderLogoImg"
                        style={{
                            backgroundImage: `url(${props.url})`,
                        }}
                    />
                </div>
            </BTLink>
        </>
    );
};
