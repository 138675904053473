import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { BTLoginTypes, FileStatus, MediaType } from "types/enum";

import { AttachmentItems } from "commonComponents/entity/media/common/MediaContextMenu/Overlays/AttachmentOverlay";
import { SharingSettingsAPI } from "commonComponents/entity/sharing/SharingContainer/SharingContainer.types";

import { isPDFFileType, isPhotoFileType } from "entity/media/common/media.utils";

export function areContextItemsPresent(items: Record<string, boolean>) {
    return Object.values(items).some((item) => item);
}

export function getAttachmentOverlayItems(
    file: BTFileSystem,
    isReadOnly: boolean,
    loginType?: BTLoginTypes,
    sharingSettings?: SharingSettingsAPI
): Record<AttachmentItems, boolean> {
    return {
        download: showDownload(file),
        openInNewTab: showOpenInNewTab(file),
        showOwners: showViewingPermissions(file, isReadOnly, loginType),
        showSubs: showViewingPermissions(file, isReadOnly, loginType),
        notifyOwners: showNotifyOwners(file, isReadOnly, loginType, sharingSettings),
        notifySubs: showNotifySubs(file, isReadOnly, loginType, sharingSettings),
        annotate: showAnnotate(file, isReadOnly, loginType),
        draw: showDraw(file, isReadOnly, loginType),
        editOnline: showEditOnline(file, isReadOnly, loginType),
        trash: showTrash(isReadOnly, loginType),
    };
}

export function showDownload(file: BTFileSystem) {
    return !(file.mediaType === MediaType.Video && file.fileStatus !== FileStatus.Available);
}

export function getOpenInNewTabLink(file: BTFileSystem) {
    if (file.mediaType === MediaType.Photo && file.annotatedDocPath) {
        return file.annotatedDocPath;
    }

    return file.docPath;
}

export function showOpenInNewTab(file: BTFileSystem) {
    if (file.mediaType === MediaType.Video && file.fileStatus === FileStatus.Available) {
        return true;
    }

    return (
        getOpenInNewTabLink(file) !== undefined &&
        (isPDFFileType(file.extension) || isPhotoFileType(file.extension))
    );
}

export function showViewingPermissions(
    file: BTFileSystem,
    isReadOnly: boolean,
    loginType?: BTLoginTypes
) {
    return (
        !isReadOnly && loginType === BTLoginTypes.BUILDER && file.viewingPermissions !== undefined
    );
}

export function showNotifyOwners(
    file: BTFileSystem,
    isReadOnly: boolean,
    loginType?: BTLoginTypes,
    sharingSettings?: SharingSettingsAPI
) {
    return (
        !isReadOnly &&
        loginType === BTLoginTypes.BUILDER &&
        file.viewingPermissions?.showOwner === true &&
        sharingSettings?.ownerPreference?.share === true
    );
}

export function showNotifySubs(
    file: BTFileSystem,
    isReadOnly: boolean,
    loginType?: BTLoginTypes,
    sharingSettings?: SharingSettingsAPI
) {
    return (
        !isReadOnly &&
        loginType === BTLoginTypes.BUILDER &&
        file.viewingPermissions?.showSubs === true &&
        sharingSettings?.subVendorPreference?.share === true
    );
}

export function showAnnotate(file: BTFileSystem, isReadOnly: boolean, loginType?: BTLoginTypes) {
    return (
        !!file.permissions?.canAnnotate &&
        loginType !== undefined &&
        !isReadOnly &&
        isPDFFileType(file.extension)
    );
}

export function showDraw(file: BTFileSystem, isReadOnly: boolean, loginType?: BTLoginTypes) {
    return (
        !!file.permissions?.canDrawOnline &&
        loginType !== undefined &&
        !isReadOnly &&
        file.mediaType === MediaType.Photo
    );
}

export function showEditOnline(file: BTFileSystem, isReadOnly: boolean, loginType?: BTLoginTypes) {
    return (
        !!file.permissions?.canEditOnline &&
        !file.isMacroEnabledExcelFile &&
        loginType !== undefined &&
        !isReadOnly
    );
}

export function showTrash(isReadOnly: boolean, loginType?: BTLoginTypes) {
    return !isReadOnly && loginType !== undefined;
}
