import { BTIconNotifications } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemNotifyOwnersProps {
    fileId: number;
    onClick: () => Promise<void>;
}

const MenuItemNotifyOwners = ({ fileId, onClick, ...antProps }: IMenuItemNotifyOwnersProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`notifyOwners-menuitem-${fileId}`}
            data-testid={`notifyOwners-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconNotifications isOnWhite /> Notify Owners
        </BTMenuItem>
    );
};

export default MenuItemNotifyOwners;
