import { BTLocalStorage } from "types/btStorage";
import { JobStatusTypes } from "types/enum";

import { getJobPickerState, setJobPickerState } from "utilities/jobPicker/jobPicker";

import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import { StatusTypes } from "commonComponents/utilities/Status/StatusDisplay";

// note: also set in webforms
// value from @screen-lg less var, this value should match a responsive screen size (xs, sm, md, lg, etc)
export const autoCollapseJobpickerWidth = 992;

export function isResponsiveAutoCollapsed() {
    return window.innerWidth <= autoCollapseJobpickerWidth;
}

export function isUserPreferenceCollapsed() {
    return BTLocalStorage.get("bt-string-jobPickerPreference") === "closed";
}

export function getCollapsed() {
    return isUserPreferenceCollapsed() || isResponsiveAutoCollapsed();
}

/**
 * @deprecated Use routes.jobpicker.selectJob instead
 */
export function selectJobsAndSetJobMode(jobIds: number[], allJobsSelected: boolean) {
    const jobPickerState = getJobPickerState();
    const storageValue = {
        ...jobPickerState,
        selectedJobIds: jobIds,
        isAllJobsSelected: allJobsSelected,
        isTemplateMode: false,
    };
    setJobPickerState(storageValue);
}

export function setJobMode(isTemplateMode: boolean) {
    const jobPickerState = getJobPickerState();
    const storageValue = {
        ...jobPickerState,
        isTemplateMode: isTemplateMode,
    };
    setJobPickerState(storageValue);
}

export function getStatusTypeForJob(status?: JobStatusTypes): StatusTypes {
    switch (status) {
        case JobStatusTypes.Closed:
            return "danger";
        case JobStatusTypes.Presale:
            return "pending";
        case JobStatusTypes.Open:
            return "info";
        case JobStatusTypes.Warranty:
            return "info";
        case JobStatusTypes.Template:
            return "info";
        case JobStatusTypes.Deleted:
            return "emergency";
        default:
            return "";
    }
}

export function getStatusTextForJob(status: JobStatusTypes | undefined): string {
    switch (status) {
        case JobStatusTypes.Closed:
            return "Closed";
        case JobStatusTypes.Presale:
            return "Presale";
        case JobStatusTypes.Open:
            return "Open";
        case JobStatusTypes.Warranty:
            return "Warranty";
        case JobStatusTypes.Deleted:
            return "Deleted";
        default:
            return "";
    }
}

export function getStatusTextForJobFilter(status: JobStatusFilterTypes | undefined): string {
    switch (status) {
        case JobStatusFilterTypes.Closed:
            return "Closed";
        case JobStatusFilterTypes.Presale:
            return "Presale";
        case JobStatusFilterTypes.Open:
            return "Open";
        case JobStatusFilterTypes.Warranty:
            return "Warranty";
        case JobStatusFilterTypes.OpenOrClosed:
            return "Open and Closed";
        default:
            return "";
    }
}
