import moment from "moment";
import { stringifyUrl } from "query-string";

import { BTLoginTypes, EmailLinkType, HelpCenterTypes, OwnerSelectionViews } from "types/enum";

import { getPortalForLoginType, PortalType } from "utilities/portal/portal";
import { routes } from "utilities/routes";

import { SystemFilterType } from "entity/filters/Filter/Filter.api.types";
import { SelectionListContainerTabs } from "entity/selection/SelectionListContainer/SelectionListContainer.types";

// Only use this for Webforms routes. For React routes, use routes.ts
export const routesWebforms = {
    urlForLoginType: (loginType: BTLoginTypes, builder: string, sub: string, owner: string) => {
        const portalType = getPortalForLoginType(loginType);
        switch (portalType) {
            case PortalType.BUILDER:
                return builder;
            case PortalType.SUBS:
                return sub;
            case PortalType.OWNER:
                return owner;
            default:
                return "";
        }
    },
    Allowance: {
        GetListUrl(loginType: BTLoginTypes) {
            return routesWebforms.urlForLoginType(
                loginType,
                "/Selections/Allowances.aspx?tabID=6",
                "",
                "/Selections/OwnerAllowances.aspx?tabID=7"
            );
        },
    },
    BTAdmin: {
        GetAdminBuildersUrl(builderId: number) {
            return `/btadmin/adminBuilders.aspx?builderId=${builderId}`;
        },
        login: "/btadmin/default.aspx",
        getLoginLink(loginPageRedirect: string) {
            return stringifyUrl({
                url: this.login,
                query: {
                    lpr: loginPageRedirect,
                },
            });
        },
        adminAutoLogin: "/btadmin/adminAutoLogin.aspx",
        getAdminAutoLoginLink(loginType: BTLoginTypes, loginId: number, redirectPath?: string) {
            return stringifyUrl({
                url: this.adminAutoLogin,
                query: {
                    loginType: loginType.toString(),
                    id: loginId.toString(),
                    redirectPath: redirectPath,
                },
            });
        },
    },
    Budget: {
        GetListUrl(loginType: BTLoginTypes) {
            const ownerUrl = "/POs/ownerBudget.aspx";
            return routesWebforms.urlForLoginType(
                loginType,
                `/POs/ExpectedBudget.aspx?tab=1`,
                "",
                ownerUrl
            );
        },

        GetJobCostingUrl(loginType: BTLoginTypes) {
            const ownerUrl = "/POs/ownerBudget.aspx?useJobCosting=true";
            return routesWebforms.urlForLoginType(
                loginType,
                `/POs/ExpectedBudget.aspx?useJobCosting=true`,
                "",
                ownerUrl
            );
        },
    },
    Default: {
        path: "/default.aspx",
    },
    Email: {
        GetEmailLinkClickedUrl(linkType: EmailLinkType, encrypted?: boolean, key1?: string) {
            return `/EmailLinkClicked.aspx?elt=${linkType}${
                encrypted !== undefined ? `&encr=${encrypted}` : ""
            }${key1 !== undefined ? `&key1=${key1}` : ""}`;
        },
    },
    Report: {
        GetListUrl() {
            return "/Reporting/ReportList.aspx";
        },
    },
    Settings: {
        GetDisabledTabURL(tabName: string, tabPermissionId: number) {
            return `/setup/ContactUs.aspx?dmethod=iframe&divId=divBasePopupWithIFrame&dialogType=${tabName}&tabPermission=${tabPermissionId}&dialogID=divBasePopupWithIFrame&isModal=true&disabledTab=true`;
        },
        GetContactUsURL() {
            // Removed use of getBasePath because many dialogs that use this are iFrames, which
            // result in getting "" instead of "/app".  All BTModal instances, for example.
            return routes.appBase + routes.contact.contactUs;
        },
        GetLoginBoxURL() {
            return `/setup/loginBoxSetup.aspx`;
        },
        GetContactFormURL() {
            return `/setup/leadContactFormSetup.aspx`;
        },
        GetFinancingURL() {
            return ``;
        },
    },
    Login: {
        GetLoginURL(options: LoginOptions = new LoginOptions()) {
            let qsParams = [];
            if (options.showSignupPrompt) {
                qsParams.push("showSignup=1");
            } else {
                qsParams.push("showSignup=0");
            }

            if (options.postLoginRedirect) {
                qsParams.push(`lpr=${encodeURIComponent(options.postLoginRedirect)}`);
            }

            if (options.isActivated) {
                qsParams.push("isActivated=true");
            }

            return `/?${qsParams.join("&")}`;
        },
        GetLoginURLWithReactPostLoginRedirect(options: LoginOptions) {
            return this.GetLoginURL({
                ...options,
                postLoginRedirect: options.postLoginRedirect && `/app${options.postLoginRedirect}`,
            });
        },
        GetLogoutUrl() {
            return "/logout.aspx";
        },
        GetFrozenSubUrl() {
            return "/frozenHONotice.aspx";
        },
    },
    Misc: {
        GetPrivacyUrl() {
            return `https://buildertrend.com/privacy.aspx`;
        },
        GetLegalUrl() {
            return `https://buildertrend.com/legal.aspx`;
        },
        GetFrozenBuilderUrl() {
            return "/frozenHONotice.aspx";
        },
        GetDataPrivacyUrl(jwt: string) {
            return `/DataPrivacy.aspx?jwt=${jwt}`;
        },
    },
    Dropbox: {
        OpenAuth(url: string) {
            // eslint-disable-next-line no-restricted-syntax
            if ((window as any).btMaster && (window as any).btMaster.btDialogs) {
                // DO NOT COPY. THIS IS AN OLD PATTERN. SEE bt-111516 for new pattern.
                // eslint-disable-next-line no-restricted-syntax
                (window as any).btMaster.btDialogs.fireDialogWithID("divDropboxPopup", {
                    left: 35,
                    width: 600,
                    height: 600,
                    src: url,
                    showLoadingSpinner: true,
                    usesPostBacks: false,
                    unloadOnClose: true,
                });
            }
        },
    },
    Summary: {
        GetSubSummaryUrl() {
            return "/subSummary.aspx";
        },
    },
    Selection: {
        GetAgendaUrl() {
            return "/Selections/OwnerAgenda.aspx?tabID=1";
        },
        GetCardViewUrl() {
            return "/Selections/OwnerSelectionCards.aspx?tabID=2";
        },
        GetCategoryUrl() {
            return "/Selections/OwnerCategoryLocations.aspx?tabID=5";
        },
        GetLocationUrl() {
            return "/Selections/OwnerCategoryLocations.aspx?tabID=6";
        },
        GetHierarchyUrl() {
            return "/Selections/OwnerConditionTree.aspx?tabID=4";
        },
        GetListUrl(loginType: BTLoginTypes) {
            return routesWebforms.urlForLoginType(
                loginType,
                "",
                "/Selections/subSelectionsList.aspx",
                "/Selections/OwnerSelectionList.aspx?tabID=3"
            );
        },
        GetListPageUrl(
            loginType: BTLoginTypes,
            ownerDefaultView: OwnerSelectionViews,
            systemFilterType?: SystemFilterType
        ) {
            let url = "";
            switch (loginType) {
                case BTLoginTypes.OWNER:
                case BTLoginTypes.CONTACT:
                    switch (ownerDefaultView) {
                        case OwnerSelectionViews.Agenda:
                            url = routesWebforms.Selection.GetAgendaUrl();
                            break;
                        case OwnerSelectionViews.Category:
                            url = routesWebforms.Selection.GetCategoryUrl();
                            break;
                        case OwnerSelectionViews.Card:
                            url = routesWebforms.Selection.GetCardViewUrl();
                            break;
                        case OwnerSelectionViews.List:
                            url = routesWebforms.Selection.GetListUrl(loginType);
                            break;
                        case OwnerSelectionViews.Location:
                            url = routesWebforms.Selection.GetLocationUrl();
                            break;
                    }
                    break;
                case BTLoginTypes.SUBS:
                    url = routesWebforms.Selection.GetListUrl(loginType);
                    break;
            }

            if (systemFilterType) {
                url = addQueryParams(url, { filter: systemFilterType.toString() });
            }
            return url;
        },
        // The Main Navigation and actual list pages use different enums to determine which tab to display, so we need separate routing functions here.
        // Otherwise, we have to deal with simplifying to a single enum for code that will be deleted once we're off Webforms
        GetListPageUrlFromTab(loginType: BTLoginTypes, tab: SelectionListContainerTabs) {
            switch (tab) {
                case SelectionListContainerTabs.Agenda:
                    return routesWebforms.Selection.GetAgendaUrl();
                case SelectionListContainerTabs.Allowances:
                    return routesWebforms.Allowance.GetListUrl(loginType);
                case SelectionListContainerTabs.CardView:
                    return routesWebforms.Selection.GetCardViewUrl();
                case SelectionListContainerTabs.Category:
                    return routesWebforms.Selection.GetCategoryUrl();
                case SelectionListContainerTabs.Hierarchy:
                    return routesWebforms.Selection.GetHierarchyUrl();
                case SelectionListContainerTabs.List:
                    return routesWebforms.Selection.GetListUrl(loginType);
                case SelectionListContainerTabs.Location:
                    return routesWebforms.Selection.GetLocationUrl();
                default:
                    return "";
            }
        },
    },
    Media: {
        GetAnnotationUrl(
            annotationId: number,
            documentInstanceId: number,
            loginType: BTLoginTypes,
            builderId: number,
            isOnNewEntity: boolean,
            isOnTempFile: boolean,
            isReadOnly?: boolean
        ) {
            const params = `?annotationid=${annotationId}&id=${documentInstanceId}&isOnNewEntity=${isOnNewEntity}&isDrawingOnTempFile=${isOnTempFile}`;
            const readOnlyParam = isReadOnly ? `&isReadOnly=true` : "";
            return routesWebforms.urlForLoginType(
                loginType,
                `/Documents/Annotate.aspx?${params}${readOnlyParam}`,
                `/Documents/SubAnnotate.aspx?${params}&builderId=${builderId}`,
                `/Documents/OwnerAnnotate.aspx?${params}`
            );
        },
        GetPhotoDrawUrl(
            id: number,
            builderId: number,
            loginType: BTLoginTypes,
            isOnNewEntity: boolean,
            isOnTempFile: boolean
        ) {
            const params = `?id=${id}&isOnNewEntity=${isOnNewEntity}&isDrawingOnTempFile=${isOnTempFile}&isReadOnly=false&isFromCfv=false&breakDocumentLinks=false&isModal=true`;
            return routesWebforms.urlForLoginType(
                loginType,
                `/Media/Photos/Builder/BuilderPhotoDraw.aspx${params}`,
                `/Media/Photos/Subs/SubsPhotoDraw.aspx${params}&builderId=${builderId}`,
                `/Media/Photos/Owner/OwnerPhotoDraw.aspx${params}`
            );
        },
    },
    SignatureRequest: {
        GetSignUrl(
            docInstanceId: number,
            loginType: BTLoginTypes,
            fromScheduleModal: boolean = false,
            fromReact: boolean = true,
            builderId?: number
        ) {
            return routesWebforms.urlForLoginType(
                loginType,
                `/PdfSignature/PdfSignatureSigning.aspx?dmethod=iframe&divId=divBasePopupWithIFrame&fromReact=${fromReact}&fromScheduleModal=${fromScheduleModal}&id=${docInstanceId}&masterPageBuilderId=0&dialogID=divBasePopupWithIFrame`,
                `/PdfSignature/SubPdfSignatureSigning.aspx?dmethod=iframe&divId=divBasePopupWithIFrame&fromReact=${fromReact}&id=${docInstanceId}&dialogID=divBasePopupWithIFrame&builderId=${builderId}`,
                `/PdfSignature/OwnerPdfSignatureSigning.aspx?dmethod=iframe&divId=divBasePopupWithIFrame&fromReact=${fromReact}&id=${docInstanceId}&dialogID=divBasePopupWithIFrame`
            );
        },
    },
    Help: {
        GetHelpCenterLink(loginType: BTLoginTypes) {
            switch (loginType) {
                case BTLoginTypes.BUILDER:
                    return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.HelpCenter}`;
                case BTLoginTypes.SUBS:
                    return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.SubHelpCenter}`;
                case BTLoginTypes.OWNER:
                case BTLoginTypes.CONTACT:
                    return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.OwnerHelpCenter}`;
                default:
                    return "";
            }
        },
        GetPaymentsFromPrinoutsHelp() {
            return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.PaymentsFromPrintouts}`;
        },
        GetThreeDsHelpLink() {
            return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.ThreeDs}`;
        },
        GetExternalCalendarIntegrationLink() {
            return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.CalendarFeedGuide}`;
        },
        GetQuickbooksHelp() {
            return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.QuickBooksHelp}`;
        },
        GetXeroHelp() {
            return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.XeroHelp}`;
        },
        GetNotificationsHelp(loginType: BTLoginTypes) {
            switch (loginType) {
                case BTLoginTypes.BUILDER:
                    return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.NotificationConfigOptions}`;
                case BTLoginTypes.SUBS:
                    return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.SubNotificationConfigOptions}`;
                case BTLoginTypes.OWNER:
                case BTLoginTypes.CONTACT:
                    return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.OwnerNotificationConfigOptions}`;
                default:
                    return "";
            }
        },
        GetChaseBankAccountHelp() {
            return `/HttpHandlers/HelpUrlHandler.ashx?help_url=${HelpCenterTypes.ChaseBankAccounts}`;
        },
    },
    Sub: {
        GetSubDeleteConflictDetails(
            builderId: number,
            subId: number,
            showDeleteConflicts: boolean
        ) {
            return `/SubDeleteConflictsPopup.aspx?dmethod=iframe&divId=divBasePopupWithIFrame&subID=${subId}&showDeleteConflicts=${showDeleteConflicts}&masterPageBuilderId=${builderId}&dialogID=divBasePopupWithIFrame&isModal=true`;
        },
        GetSubActivation(
            activationCode: string,
            encrypted?: boolean,
            emailLinkType?: EmailLinkType,
            key1?: string
        ) {
            return `/subActivation.aspx?actCode2=${activationCode}${
                encrypted !== undefined ? `&encr=${encrypted}` : ""
            }${emailLinkType ? `&elt=${emailLinkType}` : ""}${
                key1 !== undefined ? `&key1=${key1}` : ""
            }`;
        },
    },
    closeModal<T extends ISerializableObject>(data?: T): void {
        const eventMessage: IDialogEventMessage = { type: "closeModal", data: data };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
    closeModalAndReload<T extends ISerializableObject>(data?: T): void {
        const eventMessage: IDialogEventMessage = { type: "closeModalAndReload", data: data };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
    setModalTitle(title: string): void {
        const eventMessage: IDialogEventMessage = { type: "setModalTitle", data: title };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
    /**
     * @param width specify the width of the modal, use null to keep the width the same
     * @param height specify the height of the modal, use null to keep the height the same
     */
    setModalSize(width: number | null, height: number | null): void {
        const eventMessage: IDialogEventMessage = { type: "setModalSize", data: { width, height } };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
    doPostback(postbackControlId: string | null): void {
        const eventMessage: IDialogEventMessage = { type: "doPostback", data: postbackControlId };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
    reloadAfterClose(): void {
        const eventMessage: IDialogEventMessage = { type: "reloadAfterClose", data: null };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
    pushState(stateObj: any, title: string | null, url: string): void {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).history.pushState(stateObj, title, getBaseUrl(url));
    },
    replaceState(stateObj: any, title: string | null, url: string): void {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).history.replaceState(stateObj, title, getBaseUrl(url));
    },
    refreshPage() {
        window.location.reload();
    },
    checkQualtrics(surveyName: string): void {
        const eventMessage: IDialogEventMessage = { type: "checkQualtrics", data: { surveyName } };
        window.parent.postMessage(JSON.stringify(eventMessage), "*");
    },
};

function getBaseUrl(url: string) {
    if (url === "?") {
        return location.protocol + "//" + location.host + location.pathname;
    }
    return url;
}

function addQueryParams(url: string, params: Record<string, string>) {
    const urlParams = new URLSearchParams();
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            urlParams.append(key, params[key]);
        }
    }
    if (url.includes("?")) {
        url += "&" + urlParams.toString();
    } else {
        url += "?" + urlParams.toString();
    }
    return url;
}

interface ISerializableObject {
    // don't allow serializing functions
    [key: string]: number | moment.Moment | Date | boolean | string;
}

export interface IDialogEventMessage<CloseCallbackData = any> {
    type:
        | "closeModal"
        | "doPostback"
        | "setModalTitle"
        | "closeModalAndReload"
        | "setModalSize"
        | "reloadAfterClose"
        | "checkQualtrics";
    data?: CloseCallbackData;
}

export interface IToastMessage extends ISerializableObject {
    toastMessage: string;
    toastType: "error" | "success";
}

class LoginOptions {
    showSignupPrompt?: boolean;
    isActivated?: boolean;
    postLoginRedirect?: string;
}

// please talk to architecture before adding new classes/functions/interfaces
