import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

interface IRoutePhotoDrawProps {
    modalConfig: IModalConfiguration;
}

export const RoutePhotoDraw = ({ modalConfig }: IRoutePhotoDrawProps) => {
    return (
        <RouteRelative
            path={routes.photo.draw}
            render={(routeProps) => {
                const documentInstanceId = parseInt(routeProps.match.params.documentInstanceId);
                const loginType = parseInt(routeProps.match.params.loginType);
                const builderId = parseInt(routeProps.match.params.builderId);
                const isOnNewEntity = routeProps.match.params.isOnNewEntity === "true";
                const isOnTempFile = routeProps.match.params.isOnTempFile === "true";

                return (
                    <BTModal
                        data-testid="btModalPhotoDraw"
                        visible
                        title="Photo Draw"
                        iframeHeight="627px"
                        width="800px"
                        url={routesWebforms.Media.GetPhotoDrawUrl(
                            documentInstanceId,
                            builderId,
                            loginType,
                            isOnNewEntity,
                            isOnTempFile
                        )}
                        beforeClose={modalConfig.beforeClose}
                    />
                );
            }}
        />
    );
};
