import { message, RadioChangeEvent } from "antd";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { uniqBy } from "lodash-es";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { IBaseSlide } from "yet-another-react-lightbox";

import { BTLocalStorage } from "types/btStorage";
import { FolderTypes, MediaType } from "types/enum";
import {
    attachedDocsFolderId,
    attachedPhotosFolderId,
    attachedVideosFolderId,
    globalDocsFolderId,
    rootLevelFolderId,
} from "types/MediaConstants";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { add } from "utilities/array/array";
import { isSupportedFileExtension } from "utilities/file/file";

import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BrowseBTFilesHandler } from "commonComponents/entity/media/BrowseBTFiles/BrowseBTFiles.api.handler";
import { IFolder } from "commonComponents/entity/media/BrowseBTFiles/BrowseBTFiles.types";
import { BrowseBTFilesPresentational } from "commonComponents/entity/media/BrowseBTFiles/BrowseBTFilesPresentational";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";

import { SelectedMediaListViews } from "entity/media/common/mediaTypes";

export interface IBrowseBTFilesProps {
    handler?: BrowseBTFilesHandler;
    modalConfig?: IModalConfiguration;
    allowedMediaTypes?: MediaType[];
    /**
     * @default ["*"]
        By default, Browse BT Files operates using a blacklist method. Specifiy allowed file types to only allow white listed files 
    */
    allowedFileTypes?: string[];
    jobId?: number;
    leadId?: number;
    multiple?: boolean;

    onCancel: () => void;
    onSubmit: (file: BTFileSystem[]) => Promise<void>;
    onChange?: (files: BTFileSystem[]) => void;
}

const defaultAllowedFileTypes = ["*"];
const defaultHandler = new BrowseBTFilesHandler();
const defaultAllowedMediaTypes = [
    MediaType.All,
    MediaType.Document,
    MediaType.Photo,
    MediaType.Video,
];

const BrowseBTFilesInternal: FunctionComponent<IBrowseBTFilesProps> = ({
    handler = defaultHandler,
    allowedMediaTypes = defaultAllowedMediaTypes,
    allowedFileTypes = defaultAllowedFileTypes,
    multiple = true,
    modalConfig,
    onCancel,
    onSubmit,
    onChange,
    jobId,
    leadId,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [filesAndFolders, setFilesAndFolders] = useState<BTFileSystem[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<BTFileSystem[]>([]);
    const [folderStack, setFolderStack] = useState<IFolder[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mediaType, setMediaType] = useState<MediaType>(allowedMediaTypes?.[0] ?? MediaType.All);
    const [lightboxSelectedId, setLightboxSelectedId] = useState<number | null>(null);
    const [view, setView] = useState<SelectedMediaListViews>(
        BTLocalStorage.get("bt-string-selectedBrowseBTListView")
    );

    useEffect(() => {
        const updateFileList = async (
            mediaType: MediaType,
            folderStack: IFolder[],
            jobId?: number,
            leadId?: number
        ) => {
            const defaultFolderId =
                jobId === JobIdTypes.GlobalDocs ? globalDocsFolderId : rootLevelFolderId;
            const folderStackIds = folderStack.map((f) => f.id);

            let requestMediaType = mediaType;
            if (folderStackIds.includes(attachedDocsFolderId)) {
                requestMediaType = MediaType.Document;
            } else if (folderStackIds.includes(attachedPhotosFolderId)) {
                requestMediaType = MediaType.Photo;
            } else if (folderStackIds.includes(attachedVideosFolderId)) {
                requestMediaType = MediaType.Video;
            }

            const folderId =
                folderStack.length > 0 ? folderStack[folderStack.length - 1].id : defaultFolderId;

            try {
                // Set files and folders to null to re-display the loading spinner
                setIsLoading(true);

                const response = await handler.getDocuments(
                    requestMediaType,
                    folderId,
                    jobId,
                    leadId
                );
                setFilesAndFolders(response.files);
                setIsLoading(false);
            } catch (e) {
                showAPIErrorMessage(e);
            }
        };

        void updateFileList(mediaType, folderStack, jobId, leadId);
    }, [mediaType, folderStack, jobId, leadId, handler]);

    useEffect(() => {
        setMediaType(allowedMediaTypes?.[0] ?? MediaType.All);
    }, [allowedMediaTypes]);

    const handleSubmit = useCallback(async () => {
        try {
            setIsSubmitting(true);
            await onSubmit(
                selectedFiles.filter((file) => file.folderType === FolderTypes.NotAFolder)
            );
        } finally {
            setIsSubmitting(false);
        }
    }, [onSubmit, selectedFiles]);

    const handleSlideChange = (_: number, slide: IBaseSlide) => {
        if (slide.metadata?.mediaId !== undefined) {
            setLightboxSelectedId(slide.metadata.mediaId);
        }
    };
    const handleCloseLightbox = useCallback(() => setLightboxSelectedId(null), []);

    const getSupportedFiles = useCallback(
        (files: BTFileSystem[]) => {
            const supportedFiles = files.filter((file) => {
                const fileNameWithExtension = `${file.title}.${file.extension}`;
                return (
                    file.folderType !== FolderTypes.NotAFolder ||
                    isSupportedFileExtension(fileNameWithExtension, allowedFileTypes)
                );
            });
            if (supportedFiles.length < files.length) {
                void message.error({
                    content: `The file type selected is not supported. Files with the following extensions are allowed:
                                        ${allowedFileTypes.join(", ")}`,
                    duration: 5,
                });
            }

            return supportedFiles;
        },
        [allowedFileTypes]
    );

    const handleValidateAndSetSelectedFiles = useCallback(
        (files: BTFileSystem[]) => {
            const supportedFiles = getSupportedFiles(files);
            setSelectedFiles(supportedFiles);

            onChange?.(supportedFiles);
        },
        [getSupportedFiles, onChange]
    );

    const handleFilesSelected = useCallback(
        (newSelection: BTFileSystem[]) => {
            if (!multiple) {
                handleValidateAndSetSelectedFiles(
                    newSelection.length > 1 ? [newSelection[0]] : newSelection
                );
                return;
            }

            // newSelection will only include files in the current folder view
            // this will not include files previously selected from other folders
            // Add already selected files with new selection, filtering out any duplicates
            // Then remove any files that are in the current folder view but weren't in the new selection
            const newlySelectedFileIds = new Set(newSelection.map((f) => f.id));
            const removedFileIds = new Set(
                filesAndFolders.filter((f) => !newlySelectedFileIds.has(f.id)).map((f) => f.id)
            );

            const finalSection = uniqBy([...selectedFiles, ...newSelection], (f) => f.id).filter(
                (f) => !removedFileIds.has(f.id)
            );

            handleValidateAndSetSelectedFiles(finalSection);
        },
        [handleValidateAndSetSelectedFiles, filesAndFolders, selectedFiles, multiple]
    );

    const handleChangeMediaType = (e: RadioChangeEvent) => {
        setMediaType(e.target.value);
        setFolderStack([]);
    };

    const handleChangeView = (value: SelectedMediaListViews) => {
        setView(value);
        BTLocalStorage.set("bt-string-selectedBrowseBTListView", value);
    };

    const handleSelectFile = useCallback(
        (file: BTFileSystem) => {
            const fileId = file.id;

            // Reload table if changing folders
            if (file.folderType !== FolderTypes.NotAFolder) {
                setFolderStack(add(folderStack, { id: fileId, title: file.title }));
                return;
            }

            // If single select, just select the new file
            if (!multiple) {
                handleValidateAndSetSelectedFiles([file]);
                return;
            }

            // If multi select, then toggle
            const isFileAlreadySelected = selectedFiles.find((f) => f.id === file.id) !== undefined;
            if (!isFileAlreadySelected) {
                handleValidateAndSetSelectedFiles(add(selectedFiles, file));
            } else {
                handleValidateAndSetSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
            }
        },
        [folderStack, handleValidateAndSetSelectedFiles, multiple, selectedFiles]
    );

    const handleNavigateToParent = useCallback(() => {
        const newFolderStack = [...folderStack];

        newFolderStack.pop();

        setFolderStack(newFolderStack);
    }, [folderStack]);

    const component = (
        <BrowseBTFilesPresentational
            filesAndFolders={filesAndFolders}
            isLoading={isLoading}
            isSubmitting={isSubmitting}
            allowedMediaTypes={allowedMediaTypes}
            multiple={multiple}
            mediaType={mediaType}
            selectedFiles={selectedFiles}
            folderStack={folderStack}
            lightboxSelectedId={lightboxSelectedId}
            view={view}
            modalConfig={modalConfig}
            onFilesSelected={handleFilesSelected}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            onChangeMediaType={handleChangeMediaType}
            onNavigateToParent={handleNavigateToParent}
            onFileClick={handleSelectFile}
            onOpenLightbox={setLightboxSelectedId}
            onSlideChange={handleSlideChange}
            onCloseLightbox={handleCloseLightbox}
            onViewChange={handleChangeView}
        />
    );

    if (modalConfig) {
        return (
            <BTModal
                data-testid="btModalBrowseBTFiles"
                visible
                useModalLayout
                title="Browse Buildertrend Files"
                width="650px"
                removeBodyPadding
                beforeClose={modalConfig.beforeClose}
            >
                <BTModalLayout title="Browse Buildertrend Files" modalConfig={modalConfig}>
                    {component}
                </BTModalLayout>
            </BTModal>
        );
    }

    return component;
};

export const BrowseBTFiles = withErrorBoundary(BrowseBTFilesInternal)(
    "Could not browse Browse Buildertrend files"
);
export default BrowseBTFiles;
