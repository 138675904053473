import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

import { BTLoginTypes } from "types/enum";

import { routes } from "utilities/routes";

import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import InvalidInvitePage from "entity/LinkAccountActivation/InvalidInvite/InvalidInvitePage";
import {
    defaultLinkAccountHandler,
    ILinkAccountHandler,
} from "entity/LinkAccountActivation/LinkAccountBuildertrend/LinkAccount.api.handler";

interface IUrlProps {}

export interface IFinzalizeCreateAccountProps extends RouteComponentProps<IUrlProps> {
    username?: string;
    shareToken: string;
    handler?: ILinkAccountHandler;
}

function FinalizeCreateAccountInternal({
    shareToken,
    username,
    history,
    handler = defaultLinkAccountHandler,
}: Readonly<IFinzalizeCreateAccountProps>) {
    const [activationComplete, setActivationComplete] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(null);

    useEffect(() => {
        if (error != null) {
            history.replace(routes.user.getLinkInvalidInvite(shareToken, BTLoginTypes.BUILDER));
        } else if (activationComplete) {
            // This will need to be update to handle all login types when non-builders are created in auth0
            history.replace(routes.landingPage.getLink());
        }
    }, [activationComplete, error, history, shareToken]);

    useEffect(() => {
        const finalizeActivation = async () => {
            try {
                await handler.finalizeActivation({
                    shareToken: shareToken,
                    emailAddress: encodeURIComponent(username!),
                });
                setActivationComplete(true);
            } catch (error) {
                setError(error);
            }
        };
        void finalizeActivation();
    }, [history, shareToken, username, handler]);

    if (error) {
        return <InvalidInvitePage loginType={BTLoginTypes.BUILDER} />;
    }

    return (
        <BTRow className="FinalizeCreateAccount" justify="center">
            <BTLoading />
        </BTRow>
    );
}

export const FinalizeCreateAccount = withErrorBoundary(FinalizeCreateAccountInternal)(
    "Could not finalize account creation"
);

export default FinalizeCreateAccount;
