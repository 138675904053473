import { AxiosError } from "axios";
import { useMemo, useState } from "react";

import { BTSelectItem, ExceptionResponse } from "types/apiResponse/apiResponse";

import { showApixErrorMessage } from "utilities/apiHandler";

import { BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTSelectLazy } from "commonComponents/btWrappers/BTSelectLazy/BTSelectLazy";
import { IBuilderSearchExtraData } from "commonComponents/entity/builder/BuilderSearch/BuilderSearch.api.handler";

import {
    adminBuildersSearchGetV2,
    AdminBuildersSearchGetV2Params,
    BuilderFilterTypes,
    ClosedAccountTypes,
} from "handlers";

import { IBuilderSetupSearchFormValues } from "entity/builderSetup/BuilderSetupSearch/BuilderSetupSearch";

interface IBuilderSearchPropsV2 {
    "data-testid": string;
    onChange: (
        field: string,
        value: number,
        selectedItem?: BTSelectItem<IBuilderSearchExtraData>
    ) => void;
    placeholder?: string;
    label?: string;
    accountStatus?: ClosedAccountTypes;
    accountType?: BuilderFilterTypes;
}

export function BuilderSearchV2({
    "data-testid": testId,
    onChange,
    placeholder = "Search for a builder",
    label,
    accountStatus,
    accountType,
}: IBuilderSearchPropsV2) {
    const [selectedItem, setSelectedItem] = useState<
        BTSelectItem<IBuilderSearchExtraData> | undefined
    >(undefined);
    const [data, setData] = useState<BTSelectItem<IBuilderSearchExtraData>[] | undefined>(
        undefined
    );

    const handleChange = (
        field: string,
        value: number,
        selectedItem?: BTSelectItem<IBuilderSearchExtraData>
    ) => {
        setSelectedItem(selectedItem);
        onChange(field, value, selectedItem);
    };

    const searchData = useMemo(() => {
        if (!data) return [];
        return data[0].children?.map((item) => {
            return {
                ...item,
                title: item.title + " (ID: " + item.value + ")",
            };
        });
    }, [data]);

    const handleSearch = async (searchQuery: string) => {
        try {
            let params: AdminBuildersSearchGetV2Params = {
                SearchQuery: searchQuery.trim(),
                AccountStatus: accountStatus ? accountStatus : undefined,
                AccountType: accountType ? accountType : undefined,
                PriceTier: undefined,
            };
            const response = await adminBuildersSearchGetV2(params);
            let data = response.data.builderList!.options
                ? response.data.builderList!.options.map(
                      (b) => new BTSelectItem<IBuilderSearchExtraData>(b)
                  )
                : [];
            setData(data);
        } catch (e) {
            showApixErrorMessage(e as AxiosError<ExceptionResponse>);
        }
    };

    return (
        <BTFormItem label={label}>
            <BTSelectLazy<IBuilderSetupSearchFormValues, IBuilderSearchExtraData>
                data-testid={testId}
                id="builderId"
                searchData={searchData}
                value={selectedItem}
                onChange={handleChange}
                onSearch={handleSearch}
                onBlur={() => {}}
                placeholder={placeholder}
                hideSelectAll={true}
            />
        </BTFormItem>
    );
}

export default BuilderSearchV2;
