import { useContext } from "react";

import { BdsIcon } from "@buildertrend/components";

import { EnvironmentInfoContext } from "helpers/globalContext/EnvironmentInfoContext";

import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconCaretSmallDown } from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

import {
    ShowAccountingWebhookTesting,
    ShowApplicationAndSearchAndSpam,
    ShowBTAdminUsers,
    ShowBuilders,
    ShowBuilderSetup,
    ShowDevUtilitiesDiv,
    ShowFeatureFlagsAndIntegration,
    ShowHangfire,
    ShowLogs,
    ShowOnlinePaymentReport,
    ShowPaymentsPrescreening,
    ShowRebateHawk,
    ShowRiskInsurance,
    ShowStuckPaymentUtility,
    ShowSubscriptionItemSetup,
    ShowSubscriptionSignUpCodes,
    ShowSupportToolsAndBillingDiv,
} from "entity/admin/AdminMainPermissions";

import "./BTAdminNavigation.less";

const BTAdminNavigationInternal = () => {
    const envInfo = useContext(EnvironmentInfoContext);
    let iconStyles = { color: envInfo?.color };

    // support tools
    const showSupportToolsDiv = ShowSupportToolsAndBillingDiv();

    const showBuilders = ShowBuilders();
    const showBTAdminUsers = ShowBTAdminUsers();
    const showPaymentsPrescreening = ShowPaymentsPrescreening();
    const showRebateHawk = ShowRebateHawk();
    const showStuckPaymentUtility = ShowStuckPaymentUtility();
    const showBuilderSetup = ShowBuilderSetup();

    const Builders = (
        <AdminMenuItemWebforms
            link="/btadmin/adminBuilders.aspx"
            text="Builders"
            key="support-builder"
        />
    );
    const BTAdminUsers = (
        <AdminMenuItemWebforms
            link="/btadmin/BTAdminUsersList.aspx"
            text="BTAdmin Users"
            key="support-btadmin-users"
        />
    );
    const PaymentsPrescreening = (
        <AdminMenuItem
            link={routes.onlinePayments.adminPrescreening}
            text="Payments Prescreening"
            key="support-payments"
        />
    );
    const RebateHawk = (
        <AdminMenuItem
            link={routes.rebateReceiptReview.list}
            text="Rebate Hawk"
            key="support-rebate-hawk"
        />
    );
    const StuckPaymentUtility = (
        <AdminMenuItem
            link={routes.onlinePayments.stuckPaymentUtility}
            text="Stuck Payment Utility"
            key="support-stuck-payment"
        />
    );
    const BuilderSetup = (
        <AdminMenuItem
            link={routes.builderSetup.adminSearch}
            text="Builder Setup"
            key="support-builder-setup"
        />
    );

    // risk insurance

    const showRiskInsurance = ShowRiskInsurance();

    const RiskInsuranceActivityReport = (
        <AdminMenuItemWebforms
            link="/btadmin/adminInsuranceActivityReport.aspx"
            text="Risk Insurance Activity Report"
            key="risk-insurance-activity-report"
        />
    );

    const RiskInsuranceUpdateUtility = (
        <AdminMenuItem
            link={routes.builderRiskInsurance.briUpdateUtility}
            text="Risk Insurance Update Utility"
            key="risk-insurance-update-utility"
        />
    );

    // billing reports

    const showBillingReportsDiv = ShowSupportToolsAndBillingDiv();
    const showOnlinePaymentReport = ShowOnlinePaymentReport();
    const showSubscriptionItemSetup = ShowSubscriptionItemSetup();
    const showSubscriptionSignUpCodes = ShowSubscriptionSignUpCodes();

    const OnlinePaymentReport = (
        <AdminMenuItem
            link={routes.onlinePayments.adminMerchantPaymentList}
            text="Online Payment Report"
            key="billing-online-payment"
        />
    );

    const SubscriptionItemSetup = (
        <AdminMenuItem
            link={routes.subscriptionItemSetup.details}
            text="Subscription Item Setup"
            key="billing-subscription-setup"
        />
    );

    const SubscriptionSignUpCodes = (
        <AdminMenuItem
            link={routes.subscriptionSignUpCodes.list}
            text="Subscription Sign Up Codes"
            key="billing-subscription-codes"
        />
    );

    // dev utilities
    const showDevUtilitiesDiv = ShowDevUtilitiesDiv();
    const showLogs = ShowLogs();
    const showHangfire = ShowHangfire();
    const showAccountingWebhookTesting = ShowAccountingWebhookTesting();
    const showIntegrationPartners = ShowFeatureFlagsAndIntegration();
    const showFeatureFlags = ShowFeatureFlagsAndIntegration();
    const showSearchUtilities = ShowApplicationAndSearchAndSpam();
    const showApplicationSettings = ShowApplicationAndSearchAndSpam();

    const Logs = (
        <AdminMenuItemWebforms link="/btadmin/adminLogs.aspx" text="Logs" key="dev-util-logs" />
    );

    const Hangfire = (
        <AdminMenuItemWebforms link="/btadmin/hangfire" text="Hangfire" key="dev-util-hangfire" />
    );

    const AccountingWebhookTesting = (
        <AdminMenuItemWebforms
            link="/btadmin/accountingWebhookTesting.aspx"
            text="Accounting Webhook Testing"
            key="dev-util-accounting"
        />
    );

    const FeatureFlags = (
        <AdminMenuItem link={routes.featureFlags.list} text="Feature Flags" key="dev-util-flags" />
    );

    const IntegrationPartners = (
        <AdminMenuItem
            link={routes.partners.list}
            text="Integration Partners"
            key="dev-util-partner"
        />
    );

    const SearchUtilties = (
        <AdminMenuItem
            link={routes.search.btAdminUtility}
            text="Search Utilities"
            key="dev-util-search"
        />
    );

    const ApplicationSettings = (
        <AdminMenuItemWebforms
            link="/btadmin/ApplicationDefaults.aspx"
            text="Application Settings"
            key="dev-util-settings"
        />
    );

    return (
        <>
            <div className="MainMenu">
                <div className="Title" style={iconStyles}>
                    BTAdmin - {envInfo?.name}
                </div>
                <div className="AdminMenuContainer">
                    <div className="link">
                        <BTLink
                            to=""
                            href="/btadmin/adminmain.aspx"
                            useAutoSPARouting={false}
                            className="admin-main-link"
                            id="main-nav-main"
                            isUnderline={false}
                        >
                            Main
                        </BTLink>
                    </div>
                    {showSupportToolsDiv && (
                        <div className="link">
                            <BTDropdown
                                data-testid="support-main-nav-button"
                                aria-label="Support Tools"
                                className="AdminDropDown"
                                overlay={
                                    <BTMenu>
                                        {showBuilders ? Builders : null}
                                        {showBTAdminUsers && BTAdminUsers}
                                        {showPaymentsPrescreening && PaymentsPrescreening}
                                        {showRebateHawk && RebateHawk}
                                        {showStuckPaymentUtility && StuckPaymentUtility}
                                        {showBuilderSetup && BuilderSetup}
                                    </BTMenu>
                                }
                            >
                                <BTButton
                                    data-testid="mediaBreadcrumb"
                                    type="tertiary"
                                    className="breadcrumbEllipsis"
                                    noShadow
                                >
                                    <div className="AdminMenuContainer">
                                        <span>
                                            <BdsIcon
                                                iconName="Support"
                                                size="lg"
                                                style={iconStyles}
                                            />
                                        </span>
                                        <span>
                                            Support Tools <MainNavCaret />
                                        </span>
                                    </div>
                                </BTButton>
                            </BTDropdown>
                        </div>
                    )}
                    {showRiskInsurance && (
                        <div className="link">
                            <BTDropdown
                                data-testid="risk-main-nav-button"
                                aria-label="Risk Insurance Tools"
                                className="AdminDropDown"
                                overlay={
                                    <BTMenu>
                                        {showRiskInsurance && RiskInsuranceActivityReport}
                                        {showRiskInsurance && RiskInsuranceUpdateUtility}
                                    </BTMenu>
                                }
                            >
                                <BTButton
                                    data-testid="mediaBreadcrumb"
                                    type="tertiary"
                                    className="breadcrumbEllipsis"
                                    noShadow
                                >
                                    <div className="AdminMenuContainer">
                                        <span>
                                            <BdsIcon
                                                iconName="RiskInsurance"
                                                size="lg"
                                                style={iconStyles}
                                            />
                                        </span>
                                        <span>
                                            Risk Insurance Tools <MainNavCaret />
                                        </span>
                                    </div>
                                </BTButton>
                            </BTDropdown>
                        </div>
                    )}
                    {showBillingReportsDiv && (
                        <div className="link">
                            <BTDropdown
                                data-testid="billing-main-nav-button"
                                aria-label="Billing Reports"
                                className="AdminDropDown"
                                overlay={
                                    <BTMenu>
                                        {showOnlinePaymentReport ? OnlinePaymentReport : null}
                                        {showSubscriptionItemSetup ? SubscriptionItemSetup : null}
                                        {showSubscriptionSignUpCodes
                                            ? SubscriptionSignUpCodes
                                            : null}
                                    </BTMenu>
                                }
                            >
                                <BTButton
                                    data-testid="mediaBreadcrumb"
                                    type="tertiary"
                                    className="breadcrumbEllipsis"
                                    noShadow
                                >
                                    <div className="AdminMenuContainer">
                                        <span>
                                            <BdsIcon
                                                iconName="FinancialManagement"
                                                size="lg"
                                                style={iconStyles}
                                            />
                                        </span>
                                        <span>
                                            Billing Reports <MainNavCaret />
                                        </span>
                                    </div>
                                </BTButton>
                            </BTDropdown>
                        </div>
                    )}
                    {showDevUtilitiesDiv && (
                        <div className="link">
                            <BTDropdown
                                data-testid="dev-main-nav-button"
                                aria-label="Dev Utilities"
                                className="AdminDropDown"
                                overlay={
                                    <BTMenu>
                                        {showLogs ? Logs : null}
                                        {showHangfire ? Hangfire : null}

                                        {showAccountingWebhookTesting
                                            ? AccountingWebhookTesting
                                            : null}

                                        {showFeatureFlags ? FeatureFlags : null}
                                        {showIntegrationPartners ? IntegrationPartners : null}
                                        {showSearchUtilities ? SearchUtilties : null}
                                        {showApplicationSettings ? ApplicationSettings : null}
                                    </BTMenu>
                                }
                            >
                                <BTButton
                                    data-testid="mediaBreadcrumb"
                                    type="tertiary"
                                    className="breadcrumbEllipsis"
                                    noShadow
                                >
                                    <div className="AdminMenuContainer">
                                        <span>
                                            <BdsIcon
                                                iconName="GearSix"
                                                size="lg"
                                                style={iconStyles}
                                            />
                                        </span>
                                        <span>
                                            Dev Utilities <MainNavCaret />
                                        </span>
                                    </div>
                                </BTButton>
                            </BTDropdown>
                        </div>
                    )}

                    <div className="link">
                        <BTLink
                            to=""
                            href="/btadmin/adminlogout.aspx"
                            useAutoSPARouting={false}
                            className="admin-main-link"
                            id="main-nav-main"
                            isUnderline={false}
                        >
                            Logout
                        </BTLink>
                    </div>
                </div>
            </div>
        </>
    );
};

export const BTAdminNavigation = () => {
    return (
        <>
            <BTAdminNavigationInternal />
        </>
    );
};

const MainNavCaret: React.FunctionComponent = () => (
    <BTIconCaretSmallDown className="nav-bar-caret-down" />
);

interface IAdminMenuItemProps {
    link: string;
    text: string;
    key: string;
}

const AdminMenuItem: React.FunctionComponent<IAdminMenuItemProps> = (props) => (
    <BTMenuItem data-testid={props.key + "-menu"} {...props}>
        <BTLink
            className="admin-main-link"
            to={props.link}
            id={props.key}
            isUnderline={false}
            useAutoSPARouting={true}
        >
            {props.text}
        </BTLink>
    </BTMenuItem>
);

const AdminMenuItemWebforms: React.FunctionComponent<IAdminMenuItemProps> = (props) => (
    <BTMenuItem data-testid={props.key + "-menu"} {...props}>
        <BTLink
            className="admin-main-link"
            to=""
            href={props.link}
            id={props.key}
            isUnderline={false}
            useAutoSPARouting={false}
        >
            {props.text}
        </BTLink>
    </BTMenuItem>
);
