import { SortOrder } from "antd/lib/table/interface";
import { InjectedFormikProps } from "formik";
import {
    AttachedFiles,
    AttachedFilesRequest,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import moment from "moment";
import { RouteComponentProps } from "react-router";

import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";
import {
    CustomFieldAssociatedType,
    DocumentInstanceType,
    JobStatusTypes,
    LeadStatus,
    ProposalStatus,
} from "types/enum";

import { alphabetize } from "utilities/form/form";
import {
    EmptyStateEntity,
    getPagingData,
    GridRequest,
    IPagingDataRequest,
    ListMetadata,
    mapPagingDataToRequest,
    PagingData,
} from "utilities/list/list.types";

import {
    AddressEntity,
    AddressFormValues,
    IHasAddress,
    LocationEntity,
} from "commonComponents/entity/address/Address/Address.api.types";
import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";

import { ContactEntity } from "entity/contact/Contact/Contact.api.types";
import { FilterEntity, IFilterFormValues } from "entity/filters/Filter/Filter.api.types";
import { getFilterString } from "entity/filters/filters.utils";
import { IActivityPerformedByResponse } from "entity/lead/Common/leadActivity";
import { ILeadProposalTabProps } from "entity/lead/Lead/tabs/LeadProposals/LeadProposalTab";
import { LeadActivityTemplateFormActions } from "entity/lead/LeadActivityTemplate/LeadActivityTemplate.api.types";
import { LeadProposalPaymentStatusType } from "entity/leadProposal/common/LeadProposalPaymentStatus";
import { CopyOptions } from "entity/leadProposal/LeadProposalImport/LeadProposalImport.types";

import { default as SoldLeadCount } from "./LeadSoldCount.api.json";

export type LeadTabNames = "general" | "activities" | "proposals";
export type LeadActivitiesColumns = "type" | "communication" | "date" | "status";
export type LeadPrompts =
    | "reassign"
    | "delete"
    | "convert"
    | "jobFromTemplate"
    | "copyProposalToTemplate"
    | "proposalImport"
    | "proposalPrompt"
    | "deleteActivities"
    | "colorChange"
    | "activityImport"
    | "activityCopy"
    | undefined;
export type LeadFormActions =
    | "save"
    | "saveAndNew"
    | "saveAndClose"
    | "delete"
    | "setStatus"
    | "openAsNew"
    | "createActivity"
    | "createProposal"
    | "createJobFromScratch"
    | "createJobFromTemplate"
    | "createJobFromExisting"
    | "proposalImport"
    | "activityCopy"
    | "dismiss"
    | undefined;
export type LeadSaveEvent = React.FormEvent<HTMLFormElement> | React.MouseEvent<any, MouseEvent>;

export interface ILeadTabContainerProps {
    baseProps: ILeadTabProps;
    generalProps: ILeadGeneralTabProps;
    activityProps: ILeadActivityDetailsListProps;
    proposalProps: ILeadProposalTabProps;
    loadActivities: () => void;
}

export interface ILeadTabProps extends InjectedFormikProps<RouteComponentProps, ILeadFormValues> {
    entity: LeadEntity;
    currentTab: LeadTabNames;
    actionBeingPerformed: LeadFormActions;
    onTabChange: (newTab: string) => void;
    onSave: (
        action: LeadFormActions,
        values: ILeadFormValues,
        event?: LeadSaveEvent
    ) => Promise<boolean>;
    onExisting: (leadId: number) => Promise<void>;
}

export interface ILeadActivityListEntityProps {
    onSaveParentEntity: (action: LeadFormActions) => Promise<void>;
}

export interface ILeadGeneralTabProps {
    onJobTypeListUpdated: (newTypes: BTSelectItem[]) => void;
    onMultiselectListUpdated: (field: string, newItems: BTSelectItem[]) => void;
    onStatusChanged: (
        newStatus: LeadStatus,
        actionBeingPerformed?: LeadFormActions
    ) => Promise<void>;
}

export interface ILeadActivityDetailsListProps extends RouteComponentProps {
    permissions: ILeadActivityListPermissions;
    entityId: number;
    actionBeingPerformed: LeadFormActions | LeadActivityTemplateFormActions;
    salespeople: BTSelectItem[];
    activities?: LeadActivityResponse[];
    fromContact?: boolean;
    filterEntity: FilterEntity;
    sortDirection: SortOrder;
    pagingData?: PagingData;
    selectedActivityIds: number[];
    isEmptyStateBannerDismissed: boolean;
    columnTitles: LeadActivitiesColumns[];
    logCompletedActivity?: boolean;
    onSavedFiltersUpdated: (newFilters: FilterEntity) => void;
    onFiltersUpdated: (newFilters: IFilterFormValues) => Promise<void>;
    onSortUpdated: (newSort: SortOrder) => void;
    onPageChanged: (newScroll: PagingData) => void;
    onActivitySelected: (newIds: number[]) => void;
    onPromptOpened: (prompt: LeadPrompts) => void;
    onBannerDismissed: (entityType: EmptyStateEntity) => Promise<void>;
    listMetadata?: ListMetadata;
}

export interface ILeadActivityListPermissions {
    canEdit: boolean;
    canReassign: boolean;
    canDelete: boolean;
}

export interface ILeadFormValues {
    name: string;
    address: AddressFormValues;
    estimatedStartPrice: number;
    estimatedEndPrice: number;
    notes: string;
    status: number;
    projectedSalesDate?: moment.Moment;
    soldDate?: moment.Moment;
    projectType: number;
    contractType: number;
    salespeople: number[];
    confidence: number;
    customFields: ICustomFieldFormValues[];
    attachedFiles: AttachedFilesRequest;
    attachedFilesConfiguration: AttachedFiles;
    tags: number[];
    source: number[];
    deleteContact: boolean;
    contactSelector: number;
    contactAddress?: AddressFormValues;
    sourceLeadId?: number;
    location: LocationEntity;
}

export interface IActivityCopyFormValues {
    templateName: string;
    copyAddresses: boolean;
    openTemplate: boolean;
}

export class LeadEntity
    implements IBaseEntity, IHasAttachedFiles, IHasCustomFieldContainer, IHasAddress
{
    constructor(resp: any) {
        this.id = resp.id;
        this.leadId = resp.id;
        this.builderId = resp.builderId;
        this.canAdd = resp.canAdd;
        this.canEdit = resp.canEdit;
        this.canDelete = resp.canDelete;
        this.canConvertToJob = resp.canConvertToJob;
        this.canReassign = resp.canReassign;
        this.createdJobCountForBuilder = resp.createdJobCountForBuilder;
        this.jobStatus = resp.jobStatus ?? undefined;

        const data = resp.general;

        this.title = data.name.value || "";
        this.confidence = data.confidence.value;
        this.projectedSalesDate = data.projectedSalesDate.value
            ? moment(data.projectedSalesDate.value)
            : undefined;
        this.soldDate = data.soldDate.value ? moment(data.soldDate.value) : undefined;
        this.projectType = data.projectType.value.map((type: any) => new BTSelectItem(type));
        this.contractType = data.contractType.value.map((type: any) => new BTSelectItem(type));
        this.status = data.status.value.map((status: any) => new BTSelectItem(status));
        this.tags = alphabetize(data.tags.value.map((tag: any) => new BTSelectItem(tag)));
        this.source = alphabetize(data.source.value.map((source: any) => new BTSelectItem(source)));
        this.estimatedStartPrice = data.estimatedStartPrice.value;
        this.estimatedEndPrice = data.estimatedEndPrice.value;
        this.notes = data.notes.value;

        // Service sends down salespeople nested within an empty parent option
        this.salespeople = data.salespeople.options[0].options.map(
            (user: any) =>
                new BTSelectItem({
                    ...user,
                    selected: data.salespeople.value.includes(Number(user.id)),
                })
        );

        this.activityCount = resp.activityCount;
        this.proposalCount = resp.proposalCount;

        if (data.emailAttachedFiles) {
            this.emailAttachments = data.emailAttachedFiles.map(
                (e: any) => new LeadEmailAttachmentResponse(e)
            );
        } else {
            this.emailAttachments = [];
        }

        this.dateInfo = new LeadDateInfo(data.leadDates);

        this.convertedJobId = data.convertedJobId ?? undefined;
        this.canViewRelatedJob = data.canViewRelatedJob;

        this.contactsEnabled = data.contactsEnabled;
        this.contact = resp.contact
            ? new ContactEntity(resp.contact)
            : new ContactEntity(data.contact);
        this.promptToDeleteContact =
            this.contact.canDelete &&
            this.contact.leads.length === 1 &&
            this.contact.jobs.length === 0;

        this.address = new AddressEntity(data.address);
        this.customFieldOptions = resp.customFieldOptions;
        this.customFieldsCanConfigure = resp.customFieldsCanConfigure;
        this.customFields = data.customFields || [];
        this.attachedFiles = new AttachedFiles(data.attachedFiles);
        this.refreshPDF = resp.refreshPDF;
        this.generatedEmail = resp.generatedEmail;
        if (resp.proposalId) {
            this.proposalId = resp.proposalId;
        }
        this.canAddLeadProposal = resp.canAddLeadProposal;
        if (resp.hasSingleApprovedProposal) {
            this.hasSingleApprovedProposal = resp.hasSingleApprovedProposal;
        }
    }

    id: number;
    builderId: number;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canConvertToJob: boolean;
    canReassign: boolean;
    canAddLeadProposal: boolean;

    contactsEnabled: boolean;
    contact: ContactEntity;
    promptToDeleteContact: boolean;

    // This is called "name" in form values, but that is already on the attachments interface
    title: string;
    confidence: number;
    projectedSalesDate?: moment.Moment;
    soldDate?: moment.Moment;
    salespeople: BTSelectItem[];
    projectType: BTSelectItem[];
    contractType: BTSelectItem[];
    status: BTSelectItem[];
    tags: BTSelectItem[];
    source: BTSelectItem[];
    estimatedStartPrice: number;
    estimatedEndPrice: number;
    notes: string;
    generatedEmail?: string;

    emailAttachments: LeadEmailAttachmentResponse[];

    dateInfo: LeadDateInfo;
    convertedJobId?: number;
    jobStatus?: number;
    canViewRelatedJob: boolean;
    createdJobCountForBuilder: number;

    // IHasAddress
    address: AddressEntity;

    // IHasCustomFieldContainer
    customFieldOptions: ICustomFieldOptionsList;
    customFields: ICustomFieldResponse[];
    customFieldAssociatedType = CustomFieldAssociatedType.Lead;
    customFieldsCanConfigure: boolean;

    // IHasAttachedFiles
    leadId: number;
    name = "Lead";
    jobId = undefined;
    documentInstanceType = DocumentInstanceType.Lead;
    attachedFiles: AttachedFiles;

    refreshPDF: boolean;

    activityCount: number;
    proposalCount: number;
    proposalId: number;
    hasSingleApprovedProposal?: boolean;
}

class LeadDateInfo {
    constructor(data: any) {
        if (data.createdDate) {
            this.createdDate = moment(data.createdDate);
        }
        this.createdBy = data.createdBy;
        this.createdById = data.createdById;
        if (data.contactedDate) {
            this.contactedDate = moment(data.contactedDate);
        }
        this.contactedBy = data.contactedBy;
        if (data.convertedDate) {
            this.convertedDate = moment(data.convertedDate);
        }
        this.convertedBy = data.convertedBy;
    }
    createdDate?: moment.Moment;
    contactedDate?: moment.Moment;
    convertedDate?: moment.Moment;
    createdBy: string;
    createdById: number;
    contactedBy: string;
    convertedBy: string;
    convertedById: number;
}

export class LeadUpdateResponse {
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
    }
    id: number;
    jobId: number;
}

export class LeadActivityResponse {
    constructor(data: any) {
        this.activityId = data.activityId;
        this.scheduledDate = moment(data.scheduledDate);
        if (data.completedDate) {
            this.completedDate = moment(data.completedDate);
        }
        this.statusContent = data.statusContent;
        this.icon = data.icon;
        this.color = data.color;
        this.title = data.title;
        this.details = data.details;
        this.performedBy = data.performedBy;
    }
    activityId: number;
    scheduledDate: moment.Moment;
    completedDate?: moment.Moment;
    statusContent: ILeadActityStatusContent;
    icon: number;
    color: string;
    title: string;
    details: string;
    performedBy: IActivityPerformedByResponse;
}

export interface ILeadActityStatusContent {
    status: LeadActivityStatus;
    secondaryStatus: LeadActivitySecondaryStatus;
}

export enum LeadActivityStatus {
    Pending = 0,
    PastDue = 1,
    Completed = 2,
    QueuedForSend = 3,
}

export enum LeadActivitySecondaryStatus {
    QueuedForSend = 0,
    WaitingForMessage = 1,
    WaitingForEmailAddress = 2,
    None = 3,
    LeadEmailUnsubscribed = 4,
}

export class LeadProposalListResponse {
    constructor(response: any) {
        this.proposals = response.proposals.map((item: any) => new LeadProposalResponse(item));
        if (response.listMetadata) {
            this.listMetadata = new ListMetadata(response.listMetadata);
        }
        this.showTakeoffStatus = response.showTakeoffStatus;
    }
    proposals: LeadProposalResponse[];
    listMetadata?: ListMetadata;
    showTakeoffStatus: boolean;
}

export class FutureLeadActivityIdsResponse {
    constructor(response: any) {
        this.activityIds = response.activityIds;
    }
    activityIds: number[];
}

export class FutureLeadActivitiesCountResponse {
    constructor(response: any) {
        this.activityCount = response.actCount;
    }
    activityCount: number;
}

export class LeadProposalResponse {
    constructor(data: any) {
        this.proposalId = data.proposalId;
        if (data.leadId) {
            this.leadId = data.leadId;
        }
        if (data.jobId) {
            this.jobId = data.jobId;
            this.jobStatus = data.jobStatus;
        }
        this.title = data.title;
        this.status = data.status;
        this.updated = moment(data.updated);
        this.lastViewedDate = data.lastViewedDate;
        this.lastViewedDateDisplay = data.lastViewedDateDisplay;
        this.hasPayment = data.hasPayment;
        this.ownerPrice = data.ownerPrice;
        this.paymentDueDateString = data.paymentDueDateString;
        this.amountDue = data.amountDue;
        this.paymentStatus = data.paymentStatus;
        this.proposalFile = data.proposalFile;
        this.documentCount = data.documentCount;
        this.emails = data.emails;
        this.statusUpdatedByName = data.statusUpdatedByName;
        this.statusUpdatedDate = data.statusUpdatedDate
            ? moment(data.statusUpdatedDate)
            : undefined;
        this.isConnectedToTakeoff = data.isConnectedToTakeoff;
        if (data.isFromSnapshot) {
            this.isFromSnapshot = data.isFromSnapshot;
        }
    }
    leadId?: number;
    jobId?: number;
    jobStatus?: JobStatusTypes;
    proposalId: number;
    title: string;
    status: ProposalStatus;
    updated: moment.Moment;
    lastViewedDate: moment.Moment;
    lastViewedDateDisplay: string;
    hasPayment: boolean;
    ownerPrice: number;
    paymentDueDateString: string;
    amountDue: number;
    paymentStatus: LeadProposalPaymentStatusType;
    proposalFile: string;
    documentCount: number;
    emails: string[];
    statusUpdatedByName: string;
    statusUpdatedDate?: moment.Moment;
    isConnectedToTakeoff: boolean;
    isFromSnapshot?: boolean;
}

export class LeadEmailAttachmentResponse {
    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.titleExtension = data.titleExtension;
        this.is360Media = data.is360Media;
        if (data.titleLink) {
            this.titleLink = data.titleLink;
        }
        this.emailSubject = data.emailSubject;
        this.activityId = data.activityId;
        if (data.sentDate) {
            this.sentDate = moment(data.sentDate);
        }
        this.performedBy = data.performedBy;
    }
    id: number;
    title: string;
    titleExtension: string;
    is360Media: boolean;
    titleLink?: string;
    emailSubject: string;
    activityId: number;
    sentDate?: moment.Moment;
    performedBy: string;
}

export class DefaultProposalResponse {
    constructor(data: any) {
        this.id = data.id;
    }
    id: number;
}

export class LeadProposalTemplateCopyResponse {
    constructor(data: any) {
        this.newProposalTemplateId = data;
    }

    newProposalTemplateId: number;
}

export class ActivityCopyResponse {
    constructor(data: any) {
        this.newTemplateId = data.newTemplateId;
    }
    newTemplateId: number;
}

export class WhatToCopyResponse {
    constructor(data: any) {
        this.whatToCopy = data.whatToCopy.value.map((x: any) => new BTSelectItem(x));
    }

    whatToCopy: BTSelectItem<IWhatToCopyExtraData>[];
}

export interface IWhatToCopyExtraData {
    leadCopyOptionLabel?: string;
}

export interface IImportProposalToLeadRequest {
    leadIds: number[];
    template: number;
    copyOptions: CopyOptions[];
}

export class LeadActivityListResponse {
    constructor(response: any) {
        this.activities = response.data?.map((item: any) => new LeadActivityResponse(item)) ?? [];
        if (response.listMetaData) {
            this.listMetadata = new ListMetadata(response.listMetaData);
        }
        if (response.page !== undefined) {
            this.pagingData = getPagingData(response.page, response.pageSize, response.records);
        } else {
            this.pagingData = getPagingData(1, 1, 0);
        }
    }
    activities: LeadActivityResponse[];
    sortDirection: SortOrder;
    pagingData: PagingData;
    listMetadata?: ListMetadata;
}

export class LeadActivityListRequest {
    constructor(
        leadId: number,
        filters: IFilterFormValues,
        sortDirection: SortOrder,
        pagingData: PagingData
    ) {
        this.filters = getFilterStringWithLeadId(leadId, filters);
        this.gridRequest = getGridRequestWithSort(sortDirection);
        this.pagingData = mapPagingDataToRequest(pagingData, false);
    }
    filters: string;
    gridRequest: GridRequest;
    pagingData: IPagingDataRequest;
}

function getFilterStringWithLeadId(leadId: number, filters: IFilterFormValues) {
    // Splice the lead ID into the filter string
    const filterObj = JSON.parse(getFilterString(filters));
    const leadIdFilterKey = "14";
    filterObj[leadIdFilterKey] = leadId;
    return JSON.stringify(filterObj);
}

function getGridRequestWithSort(sortDirection: SortOrder) {
    const request = new GridRequest(EmptyStateEntity.LeadActivities);
    // Sort by the scheduled date column
    request.sortColumn = "2";
    request.sortDirection = sortDirection === "ascend" ? "asc" : "desc";
    return request;
}

export interface IMassUpdateActivityColorRequest {
    ids: number[];
    color: string;
}

export interface IMassActivityReassignRequest {
    ids: number[];
    salesperson: number;
}

export class UpdateStatusSetupResponse {
    hasSoldLead: boolean;
    status: BTSelectItem[];
    constructor(response: typeof SoldLeadCount) {
        this.hasSoldLead = response.hasSoldLead;
        this.status = response.status.map((status) => new BTSelectItem(status));
    }
}
