import qs from "query-string";
import { FunctionComponent, useContext, useState } from "react";
import { RouteComponentProps } from "react-router";

import { AppProvider } from "helpers/AppProvider";
import { defaultAppProviderHandler } from "helpers/AppProvider.api.handler";
import { setPortalLoginType } from "helpers/AppRouteChange";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoginTypes, DirectoryType, EntityTypes, MediaType } from "types/enum";

import { useLoadEffect } from "utilities/api.utilities";
import { showAPIErrorMessage } from "utilities/apiHandler";
import { getPortalForLoginType } from "utilities/portal/portal";
import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import {
    defaultJobPickerHandler,
    IJobPickerHandler,
} from "commonComponents/utilities/JobPicker/JobPicker.api.handler";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";
import { selectJobsAndSetJobMode } from "commonComponents/utilities/JobPicker/JobPicker.utils";

import { mediaFoldersByFolderIdGetV2 } from "handlers";

import { isGlobalDocsJob } from "entity/media/common/media.utils";
import { ScheduleListContainerTabs } from "entity/schedule/ScheduleListContainer/ScheduleListContainer.api.types";

import {
    defaultEntityLinkRouterHandler,
    IEntityLinkRouterHandler,
} from "./EntityLinkRouter.api.handler";
import { IEntityRecord } from "./EntityLinkRouter.api.types";

interface IUrlProps {
    externalId: string;
    type: string;
}

export interface IEntityLinkRouterProps extends RouteComponentProps<IUrlProps> {
    entityLinkHandler?: IEntityLinkRouterHandler;
    jobPickerHandler?: IJobPickerHandler;
}

const loadingMessages = [
    "👷‍♂️ Gathering our tools and blueprints... Linking you shortly!",
    "🚧 Building a bridge to your next view... Stay with us!",
    "🏗 Lifting your request with our digital crane... Linking up soon!",
    "⚙️ Mixing the digital cement... Setting your link in just a moment!",
];

const EntityLinkRouterInternal: FunctionComponent<IEntityLinkRouterProps> = ({
    entityLinkHandler = defaultEntityLinkRouterHandler,
    jobPickerHandler = defaultJobPickerHandler,
    match,
}) => {
    const [data, setData] = useState<IEntityRecord>();
    const [mediaType, setMediaType] = useState<MediaType>();
    const userInfo = useContext(UserInfoContext);
    const builderInfo = useContext(BuilderInfoContext);
    const loginType = userInfo?.credentialLoginType ?? BTLoginTypes.BUILDER;
    setPortalLoginType(loginType);
    const portalType = getPortalForLoginType(loginType);
    const { externalId } = match.params;
    useLoadEffect(
        (handleLoadException) => {
            async function loadData() {
                try {
                    const apiResponse = await entityLinkHandler.get(externalId, portalType);

                    if (apiResponse.organizationId !== builderInfo?.builderId && userInfo) {
                        const orgLink = userInfo.orgLinks?.find(
                            (orgLink) => orgLink.orgId === apiResponse.organizationId
                        );
                        if (orgLink) {
                            try {
                                await jobPickerHandler.switchAccount(orgLink.orgLinkId);
                                await jobPickerHandler.set(
                                    {
                                        jobId: apiResponse.jobId ?? JobIdTypes.AllJobs,
                                        builderId: orgLink.orgId,
                                    },
                                    [],
                                    false,
                                    {
                                        keywordFilter: "",
                                        otherFilter: JobStatusFilterTypes.NoFilter.toString(),
                                        leadStatusFilter: "",
                                        jobGroupFilter: [],
                                        projectManagerFilter: [],
                                        selectedBuilderFilter: orgLink.orgId,
                                    },
                                    false
                                ).response;
                            } catch (e) {
                                reportError(e);
                            }
                        }
                    }

                    if (apiResponse.entityType === EntityTypes.Folder) {
                        try {
                            const folderInfo = await mediaFoldersByFolderIdGetV2(
                                apiResponse.entityId,
                                {
                                    jobId: apiResponse.jobId,
                                }
                            );
                            if (userInfo?.credentialLoginType === BTLoginTypes.CONTACT) {
                                // Need to call switch linked accounts (which is actually switch jobs) for contacts that are on multiple jobs
                                try {
                                    const newJobId = folderInfo.data.jobId!;
                                    selectJobsAndSetJobMode([newJobId], false);
                                    await jobPickerHandler.switchOwner(newJobId);
                                } catch (e) {
                                    showAPIErrorMessage(e);
                                }
                            }
                            setMediaType(folderInfo.data.mediaType);
                        } catch (e) {
                            reportError(e);
                        }
                    }

                    setData(apiResponse);
                } catch (e) {
                    handleLoadException(e);
                }
            }
            void loadData();
        },
        [
            externalId,
            entityLinkHandler,
            match.path,
            portalType,
            builderInfo?.builderId,
            userInfo,
            jobPickerHandler,
        ]
    );
    const getFolderRedirectRoute = () => {
        if (!data) {
            return "";
        }
        let redirectURL = window.location.origin + routes.appBase;
        const directoryType = isGlobalDocsJob(data.jobId!)
            ? DirectoryType.GlobalDocs
            : DirectoryType.Standard;
        switch (mediaType) {
            case MediaType.Photo:
                redirectURL += routes.photo.getListLink(directoryType, data.entityId, data.jobId);
                break;
            case MediaType.Video:
                redirectURL += routes.video.getListLink(directoryType, data.entityId, data.jobId);
                break;
            case MediaType.Document:
                redirectURL += routes.document.getListLink(
                    directoryType,
                    data.entityId,
                    data.jobId
                );
                break;
        }
        return redirectURL;
    };

    const loading = <BTLoading loadingMessages={loadingMessages} loadingMessageDelay={2000} />;

    if (data) {
        switch (data.entityType) {
            case EntityTypes.DailyLog:
                location.replace(
                    window.location.origin +
                        routes.appBase +
                        routes.dailyLog.getFullDetailsLink(portalType, data.entityId, data.jobId!)
                );
                break;
            case EntityTypes.Todo:
                location.replace(
                    window.location.origin +
                        routes.appBase +
                        routes.toDo.getFullDetailsLink(portalType, data.entityId, data.jobId!)
                );
                break;
            case EntityTypes.Schedule:
                if (!data.jobId) {
                    throw new Error(
                        `Expected jobId to exist in response for entity type EntityTypes.Schedule`
                    );
                }

                location.replace(
                    window.location.origin +
                        routes.appBase +
                        routes.schedule.getFullDetailsLink({
                            tab: ScheduleListContainerTabs.Default,
                            id: data.entityId,
                            jobId: data.jobId,
                        })
                );
                break;
            case EntityTypes.PurchaseOrder:
                const poRedirectRoute =
                    window.location.origin +
                    routes.appBase +
                    routes.purchaseOrder.getListLink() +
                    routes.purchaseOrder.getDetailsLink(data.entityId, data.jobId);
                location.replace(poRedirectRoute);
                break;
            case EntityTypes.Bill:
                if (!data.jobId) {
                    throw new Error(
                        `Expected jobId to exist in response for entity type EntityTypes.Bill`
                    );
                }

                const billRedirectRoute =
                    window.location.origin +
                    routes.appBase +
                    routes.bill.getListLink() +
                    routes.bill.getDetailsLink(data.entityId, data.jobId);

                location.replace(billRedirectRoute);
                break;
            case EntityTypes.Selection:
                if (loginType === BTLoginTypes.BUILDER && data.jobId) {
                    location.replace(
                        window.location.origin +
                            routes.appBase +
                            routes.selection.getListLink() +
                            routes.selection.getDetailsLink(data.entityId, data.jobId)
                    );
                } else {
                    const selectionRoute = routesWebforms.Selection.GetListUrl(loginType);
                    const selectionUrl = qs.parseUrl(selectionRoute);
                    const selectionRedirectRoute = qs.stringifyUrl({
                        url: selectionUrl.url,
                        query: {
                            ...selectionUrl.query,
                            // capitalization of the query params is important here
                            selectionID: data.entityId.toString(),
                            jobID: data.jobId?.toString(),
                        },
                    });
                    location.replace(selectionRedirectRoute);
                }
                break;
            case EntityTypes.Folder:
                if (!data.jobId) {
                    throw new Error(
                        `Expected jobId to exist in response for entity type EntityTypes.Folder`
                    );
                }

                location.replace(getFolderRedirectRoute());
                break;
            default:
                break;
        }
    }

    // Show loading spinner until entity is loaded
    return loading;
};

const SpaEnabledEntityLinkRouter = (props: IEntityLinkRouterProps) => {
    return (
        <AppProvider handler={defaultAppProviderHandler}>
            <EntityLinkRouterInternal {...props} />
        </AppProvider>
    );
};
export const EntityLinkRouter = withErrorBoundary(SpaEnabledEntityLinkRouter)(
    "Could not load Entity Link Router"
);
export default EntityLinkRouter;
