import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent, PropsWithChildren, useCallback, useRef, useState } from "react";

import { addCloseOnScroll, removeCloseOnScroll } from "utilities/helpers";

import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconEllipsisOutlined } from "commonComponents/btWrappers/BTIcon";

interface IMediaContextMenuProps {
    file: BTFileSystem;
    overlay: React.ReactElement | (() => React.ReactElement);
    iconSize?: number;
}

const MediaContextMenuIcon: FunctionComponent<{ className?: string; size?: number }> = ({
    className,
    size,
}) => <BTIconEllipsisOutlined className={className} rotate={90} size={size} />;

export const HiddenMediaContextMenu: FunctionComponent<{ iconSize?: number }> = ({ iconSize }) => (
    <MediaContextMenuIcon className="invisible" size={iconSize} />
);

export const MediaContextMenu: FunctionComponent<PropsWithChildren<IMediaContextMenuProps>> = ({
    children,
    file,
    overlay,
    iconSize,
}) => {
    const dropdownMenuSpan = useRef<HTMLSpanElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleScroll = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleVisibleChange = useCallback(
        (visible: boolean) => {
            setIsOpen(visible);

            if (dropdownMenuSpan.current) {
                if (visible) {
                    addCloseOnScroll(dropdownMenuSpan.current, handleScroll);
                } else {
                    removeCloseOnScroll(dropdownMenuSpan.current, handleScroll);
                }
            }
        },
        [handleScroll]
    );

    return (
        <>
            <span ref={dropdownMenuSpan} />
            <BTDropdown
                overlay={overlay}
                trigger={children ? ["contextMenu"] : ["click"]}
                visible={isOpen}
                onVisibleChange={handleVisibleChange}
                destroyPopupOnHide
            >
                {children || (
                    <div
                        className="flex justify-content-center"
                        onContextMenu={() => handleVisibleChange(false)}
                        data-testid={`file-context-menu-${file.id}`}
                    >
                        <MediaContextMenuIcon size={iconSize} />
                    </div>
                )}
            </BTDropdown>
        </>
    );
};
