import { useQuery } from "@tanstack/react-query";
import { message, Skeleton } from "antd";
import { useEffect } from "react";

import { BdsButton } from "@buildertrend/components";

import { defaultEntityLinkRouterHandler } from "helpers/routing/EntityLinkRouter/EntityLinkRouter.api.handler";
import {
    IEntityRecord,
    IEntityRecordInsert,
} from "helpers/routing/EntityLinkRouter/EntityLinkRouter.api.types";

import { getJobPickerState } from "utilities/jobPicker/jobPicker";
import { getShareUrl } from "utilities/sharing/share.utilities";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTIconCopy } from "commonComponents/btWrappers/BTIcon";
import { BTInputCopy } from "commonComponents/btWrappers/BTInputCopy/BTInputCopy";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";

import "./EntityShareButton.less";

export interface ISharingConfig {
    /**
     * Data necessary to create an IEntityRecord. By providing this prop, you are opting-in to
     * generating a sharable link to this modal.
     * Make sure to implement the correct types in EntityRecordSyncManager.
     */
    entity: IEntityRecordInsert;
    /**
     * Use this to customize how the entity record is fetched.
     * Must return an IEntityRecord type
     */
    overrideGetEntityRecord?: (data: IEntityRecordInsert) => Promise<IEntityRecord>;
}

export interface IBTShareButtonProps {
    sharing?: ISharingConfig;
}

const canShare = (sharing?: ISharingConfig): sharing is ISharingConfig => {
    if (sharing === undefined || getJobPickerState().isTemplateMode) {
        return false;
    }

    return sharing.entity.id > 0;
};

const getShareLink = async (config: ISharingConfig) => {
    const { overrideGetEntityRecord, entity } = config;
    let entityRecord: IEntityRecord;
    if (overrideGetEntityRecord) {
        entityRecord = await overrideGetEntityRecord(entity);
    } else {
        entityRecord = await defaultEntityLinkRouterHandler.insert(entity);
    }

    return getShareUrl(entityRecord);
};

// Wrap getShareLink around tanstack useQuery
const useGetShareLink = (shareConfig: ISharingConfig | undefined) => {
    return useQuery({
        queryKey: ["getShareLink", shareConfig],
        queryFn: async () => {
            if (!shareConfig) {
                throw new Error("Share config was not defined");
            }
            return await getShareLink(shareConfig);
        },
        retry: 2,
        enabled: false,
    });
};

export const EntityShareButton: React.FunctionComponent<Pick<IBTShareButtonProps, "sharing">> = ({
    sharing,
}) => {
    const canShareEntity = canShare(sharing);
    const { data, error, refetch } = useGetShareLink(sharing);

    useEffect(() => {
        if (error) {
            console.error(error);
            void message.error("Unable to share at this time.");
        }
    }, [error]);

    let popoverContent: React.ReactNode;
    if (!data) {
        popoverContent = (
            <Skeleton.Input active size="small" className="EntityCopyButton-LoadingSkeleton" />
        );
    } else {
        popoverContent = (
            <BTInputCopy
                data-testid="shareUrl"
                id="shareUrl"
                value={data}
                onChange={() => {}}
                disabled
            />
        );
    }

    return (
        <div className="EntityShareButtonContainer" data-testid="entityShareButtonContainer">
            {canShareEntity && (
                <BTPopover
                    content={popoverContent}
                    placement="bottom"
                    arrowPointAtCenter
                    removeSpacing
                    unconstrainedWidth
                    onVisibleChange={(visible) => {
                        if (visible && !data) {
                            void refetch();
                        }
                    }}
                    trigger="click"
                >
                    {/* We need to use title so as not to conflict with other popover on this element */}
                    {/* eslint-disable-next-line react/forbid-dom-props */}
                    <span title="Copy Share Link">
                        <BTButton
                            className="EntityCopyButton"
                            data-testid="entityCopyButton"
                            type="tertiary"
                            icon={<BTIconCopy size={20} />}
                        />
                    </span>
                </BTPopover>
            )}
        </div>
    );
};

export const EntityShareButtonBDS: React.FunctionComponent<
    Pick<IBTShareButtonProps, "sharing"> & { id: string }
> = ({ sharing, id }) => {
    const canShareEntity = canShare(sharing);
    const { data, error, refetch } = useGetShareLink(sharing);

    useEffect(() => {
        if (error) {
            console.error(error);
            void message.error("Unable to share at this time.");
        }
    }, [error]);

    let popoverContent: React.ReactNode;
    if (!data) {
        popoverContent = (
            <Skeleton.Input active size="small" className="EntityCopyButton-LoadingSkeleton" />
        );
    } else {
        popoverContent = (
            <BTInputCopy
                data-testid="shareUrl"
                id="shareUrl"
                value={data}
                onChange={() => {}}
                disabled
            />
        );
    }

    return (
        <div className="EntityShareButtonContainer" data-testid="entityShareButtonContainer">
            {canShareEntity && (
                <BTPopover
                    content={popoverContent}
                    placement="bottomRight"
                    arrowPointAtCenter
                    removeSpacing
                    unconstrainedWidth
                    onVisibleChange={(visible) => {
                        if (visible && !data) {
                            void refetch();
                        }
                    }}
                    trigger="click"
                >
                    <BdsButton
                        id={id}
                        testid="entityCopyButton"
                        displayType="tertiary"
                        iconName="LinkSimple"
                        tooltip="Copy Share Link"
                        value="copyShareLink"
                    />
                </BTPopover>
            )}
        </div>
    );
};
