/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { BodyType, ErrorType } from "../utilities/api/apix-handler";
import type {
    BadRequestResponse,
    CopyTemplateToOrgsRequest,
    CreateTemplateAdminRequest,
    ForbiddenResponse,
    TemplatesActionrequiredGetV2Params,
} from "./models";
import { BTLoginTypes, JobImportActionRequiredType } from "./models";
import type {
    CreateTemplateAdminResponse,
    JobImportActionRequiredResponse,
    OrgToOrgCopyResponse,
    TemplatesGetCountsResponse,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 * @summary Retrieves the count of entities associated with a specific template.
 */
export const templatesCountsByTemplateExternalIdGetV2 = (
    templateExternalId: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<TemplatesGetCountsResponse>(
        { url: `/apix/v2/Templates/${templateExternalId}/counts`, method: "GET", signal },
        options
    );
};

export const getTemplatesCountsByTemplateExternalIdGetV2QueryKey = (templateExternalId: string) => {
    return [`/apix/v2/Templates/${templateExternalId}/counts`] as const;
};

export const getTemplatesCountsByTemplateExternalIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>,
    TError = ErrorType<BadRequestResponse | ForbiddenResponse>
>(
    templateExternalId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getTemplatesCountsByTemplateExternalIdGetV2QueryKey(templateExternalId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>
    > = ({ signal }) =>
        templatesCountsByTemplateExternalIdGetV2(templateExternalId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!templateExternalId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type TemplatesCountsByTemplateExternalIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>
>;
export type TemplatesCountsByTemplateExternalIdGetV2QueryError = ErrorType<
    BadRequestResponse | ForbiddenResponse
>;

/**
 * @summary Retrieves the count of entities associated with a specific template.
 */

export function useTemplatesCountsByTemplateExternalIdGetV2<
    TData = Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>,
    TError = ErrorType<BadRequestResponse | ForbiddenResponse>
>(
    templateExternalId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof templatesCountsByTemplateExternalIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getTemplatesCountsByTemplateExternalIdGetV2QueryOptions(
        templateExternalId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const templatesActionrequiredGetV2 = (
    params?: TemplatesActionrequiredGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<JobImportActionRequiredResponse>(
        { url: `/apix/v2/Templates/actionrequired`, method: "GET", params, signal },
        options
    );
};

export const getTemplatesActionrequiredGetV2QueryKey = (
    params?: TemplatesActionrequiredGetV2Params
) => {
    return [`/apix/v2/Templates/actionrequired`, ...(params ? [params] : [])] as const;
};

export const getTemplatesActionrequiredGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof templatesActionrequiredGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: TemplatesActionrequiredGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof templatesActionrequiredGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getTemplatesActionrequiredGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof templatesActionrequiredGetV2>>> = ({
        signal,
    }) => templatesActionrequiredGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof templatesActionrequiredGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type TemplatesActionrequiredGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof templatesActionrequiredGetV2>>
>;
export type TemplatesActionrequiredGetV2QueryError = ErrorType<unknown>;

export function useTemplatesActionrequiredGetV2<
    TData = Awaited<ReturnType<typeof templatesActionrequiredGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: TemplatesActionrequiredGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof templatesActionrequiredGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getTemplatesActionrequiredGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const templatesOrgToOrgCopyPostV2 = (
    copyTemplateToOrgsRequest: BodyType<CopyTemplateToOrgsRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<OrgToOrgCopyResponse>(
        {
            url: `/apix/v2/Templates/org-to-org-copy`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: copyTemplateToOrgsRequest,
        },
        options
    );
};

export const getTemplatesOrgToOrgCopyPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof templatesOrgToOrgCopyPostV2>>,
        TError,
        { data: BodyType<CopyTemplateToOrgsRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof templatesOrgToOrgCopyPostV2>>,
    TError,
    { data: BodyType<CopyTemplateToOrgsRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof templatesOrgToOrgCopyPostV2>>,
        { data: BodyType<CopyTemplateToOrgsRequest> }
    > = (props) => {
        const { data } = props ?? {};

        return templatesOrgToOrgCopyPostV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type TemplatesOrgToOrgCopyPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof templatesOrgToOrgCopyPostV2>>
>;
export type TemplatesOrgToOrgCopyPostV2MutationBody = BodyType<CopyTemplateToOrgsRequest>;
export type TemplatesOrgToOrgCopyPostV2MutationError = ErrorType<unknown>;

export const useTemplatesOrgToOrgCopyPostV2 = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof templatesOrgToOrgCopyPostV2>>,
        TError,
        { data: BodyType<CopyTemplateToOrgsRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof templatesOrgToOrgCopyPostV2>>,
    TError,
    { data: BodyType<CopyTemplateToOrgsRequest> },
    TContext
> => {
    const mutationOptions = getTemplatesOrgToOrgCopyPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 */
export const adminTemplatesPostV2 = (
    createTemplateAdminRequest: BodyType<CreateTemplateAdminRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<CreateTemplateAdminResponse>(
        {
            url: `/apix/v2/admin/Templates`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: createTemplateAdminRequest,
        },
        options
    );
};

export const getAdminTemplatesPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminTemplatesPostV2>>,
        TError,
        { data: BodyType<CreateTemplateAdminRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof adminTemplatesPostV2>>,
    TError,
    { data: BodyType<CreateTemplateAdminRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof adminTemplatesPostV2>>,
        { data: BodyType<CreateTemplateAdminRequest> }
    > = (props) => {
        const { data } = props ?? {};

        return adminTemplatesPostV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AdminTemplatesPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof adminTemplatesPostV2>>
>;
export type AdminTemplatesPostV2MutationBody = BodyType<CreateTemplateAdminRequest>;
export type AdminTemplatesPostV2MutationError = ErrorType<unknown>;

export const useAdminTemplatesPostV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminTemplatesPostV2>>,
        TError,
        { data: BodyType<CreateTemplateAdminRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof adminTemplatesPostV2>>,
    TError,
    { data: BodyType<CreateTemplateAdminRequest> },
    TContext
> => {
    const mutationOptions = getAdminTemplatesPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};

export const getTemplatesCountsByTemplateExternalIdGetV2ResponseMock = (
    overrideResponse: Partial<TemplatesGetCountsResponse> = {}
): TemplatesGetCountsResponse => ({
    bidPackagesCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    billsCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    documentFoldersCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    estimatesCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    ownerInvoicesCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    photoFoldersCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    purchaseOrdersCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    scheduleItemsCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    selectionsCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    specificationsCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    surveysCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    toDosCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    videoFoldersCount: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    ...overrideResponse,
});

export const getTemplatesActionrequiredGetV2ResponseMock = (
    overrideResponse: Partial<JobImportActionRequiredResponse> = {}
): JobImportActionRequiredResponse => ({
    actionRequiredType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(JobImportActionRequiredType)),
        undefined,
    ]),
    jobExtraData: faker.helpers.arrayElement([
        {
            leadId: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            shouldTurnCalendarOffline: faker.helpers.arrayElement([
                faker.datatype.boolean(),
                undefined,
            ]),
        },
        undefined,
    ]),
    jobId: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    scheduleExtraData: faker.helpers.arrayElement([
        {
            backgroundJobId: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            canAddUsers: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            canSendNotifications: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            canViewConflicts: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            conflicts: faker.helpers.arrayElement([
                {
                    conflicts: faker.helpers.arrayElement([
                        Array.from(
                            { length: faker.number.int({ min: 1, max: 10 }) },
                            (_, i) => i + 1
                        ).map(() => ({
                            conflicts: faker.helpers.arrayElement([
                                Array.from(
                                    { length: faker.number.int({ min: 1, max: 10 }) },
                                    (_, i) => i + 1
                                ).map(() => ({
                                    color: faker.helpers.arrayElement([
                                        faker.helpers.arrayElement([faker.word.sample(), null]),
                                        undefined,
                                    ]),
                                    endDate: faker.helpers.arrayElement([
                                        (() =>
                                            /* @__PURE__ */ new Date(
                                                `${faker.date.past().toISOString().split(".")[0]}Z`
                                            ))(),
                                        undefined,
                                    ]),
                                    endDateTime: faker.helpers.arrayElement([
                                        (() =>
                                            /* @__PURE__ */ new Date(
                                                `${faker.date.past().toISOString().split(".")[0]}Z`
                                            ))(),
                                        undefined,
                                    ]),
                                    endUtc: faker.helpers.arrayElement([
                                        (() =>
                                            /* @__PURE__ */ new Date(
                                                `${faker.date.past().toISOString().split(".")[0]}Z`
                                            ))(),
                                        undefined,
                                    ]),
                                    jobName: faker.helpers.arrayElement([
                                        faker.helpers.arrayElement([faker.word.sample(), null]),
                                        undefined,
                                    ]),
                                    jobsiteID: faker.helpers.arrayElement([
                                        faker.number.int({ min: undefined, max: undefined }),
                                        undefined,
                                    ]),
                                    relatedConflicts: faker.helpers.arrayElement([
                                        faker.number.int({ min: undefined, max: undefined }),
                                        undefined,
                                    ]),
                                    scheduleId: faker.helpers.arrayElement([
                                        faker.number.int({ min: undefined, max: undefined }),
                                        undefined,
                                    ]),
                                    showEndTime: faker.helpers.arrayElement([
                                        faker.datatype.boolean(),
                                        undefined,
                                    ]),
                                    showStartTime: faker.helpers.arrayElement([
                                        faker.datatype.boolean(),
                                        undefined,
                                    ]),
                                    startDate: faker.helpers.arrayElement([
                                        (() =>
                                            /* @__PURE__ */ new Date(
                                                `${faker.date.past().toISOString().split(".")[0]}Z`
                                            ))(),
                                        undefined,
                                    ]),
                                    startDateTime: faker.helpers.arrayElement([
                                        (() =>
                                            /* @__PURE__ */ new Date(
                                                `${faker.date.past().toISOString().split(".")[0]}Z`
                                            ))(),
                                        undefined,
                                    ]),
                                    startUtc: faker.helpers.arrayElement([
                                        (() =>
                                            /* @__PURE__ */ new Date(
                                                `${faker.date.past().toISOString().split(".")[0]}Z`
                                            ))(),
                                        undefined,
                                    ]),
                                    title: faker.helpers.arrayElement([
                                        faker.helpers.arrayElement([faker.word.sample(), null]),
                                        undefined,
                                    ]),
                                })),
                                undefined,
                            ]),
                            globalUserId: faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                undefined,
                            ]),
                            itemCount: faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                undefined,
                            ]),
                            loginType: faker.helpers.arrayElement([
                                faker.helpers.arrayElement(Object.values(BTLoginTypes)),
                                undefined,
                            ]),
                            name: faker.helpers.arrayElement([
                                faker.helpers.arrayElement([faker.word.sample(), null]),
                                undefined,
                            ]),
                        })),
                        undefined,
                    ]),
                    count: faker.helpers.arrayElement([
                        faker.number.int({ min: undefined, max: undefined }),
                        undefined,
                    ]),
                },
                undefined,
            ]),
            isCalendarOffline: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            scheduleIdsAdded: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => faker.number.int({ min: undefined, max: undefined })
                ),
                undefined,
            ]),
            subsToAdd: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => ({
                        disabled: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                        extraData: faker.helpers.arrayElement([
                            {
                                [faker.string.alphanumeric(5)]: {},
                            },
                            undefined,
                        ]),
                        group: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        groupID: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        id: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        key: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        name: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        secondaryName: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        selected: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                    })
                ),
                undefined,
            ]),
            usersToAdd: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => ({
                        disabled: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                        extraData: faker.helpers.arrayElement([
                            {
                                [faker.string.alphanumeric(5)]: {},
                            },
                            undefined,
                        ]),
                        group: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        groupID: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        id: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        key: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        name: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        secondaryName: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        selected: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                    })
                ),
                undefined,
            ]),
        },
        undefined,
    ]),
    ...overrideResponse,
});

export const getTemplatesOrgToOrgCopyPostV2ResponseMock = (
    overrideResponse: Partial<OrgToOrgCopyResponse> = {}
): OrgToOrgCopyResponse => ({
    backgroundJobIds: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() =>
            faker.word.sample()
        ),
        undefined,
    ]),
    ...overrideResponse,
});

export const getAdminTemplatesPostV2ResponseMock = (
    overrideResponse: Partial<CreateTemplateAdminResponse> = {}
): CreateTemplateAdminResponse => ({
    success: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    templateId: faker.helpers.arrayElement([
        faker.number.int({ min: undefined, max: undefined }),
        undefined,
    ]),
    ...overrideResponse,
});

export const getTemplatesCountsByTemplateExternalIdGetV2MockHandler = (
    overrideResponse?:
        | TemplatesGetCountsResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<TemplatesGetCountsResponse> | TemplatesGetCountsResponse)
) => {
    return http.get("*/v2/Templates/:templateExternalId/counts", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getTemplatesCountsByTemplateExternalIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getTemplatesActionrequiredGetV2MockHandler = (
    overrideResponse?:
        | JobImportActionRequiredResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<JobImportActionRequiredResponse> | JobImportActionRequiredResponse)
) => {
    return http.get("*/v2/Templates/actionrequired", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getTemplatesActionrequiredGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getTemplatesOrgToOrgCopyPostV2MockHandler = (
    overrideResponse?:
        | OrgToOrgCopyResponse
        | ((
              info: Parameters<Parameters<typeof http.post>[1]>[0]
          ) => Promise<OrgToOrgCopyResponse> | OrgToOrgCopyResponse)
) => {
    return http.post("*/v2/Templates/org-to-org-copy", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getTemplatesOrgToOrgCopyPostV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminTemplatesPostV2MockHandler = (
    overrideResponse?:
        | CreateTemplateAdminResponse
        | ((
              info: Parameters<Parameters<typeof http.post>[1]>[0]
          ) => Promise<CreateTemplateAdminResponse> | CreateTemplateAdminResponse)
) => {
    return http.post("*/v2/admin/Templates", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminTemplatesPostV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};
export const getTemplatesMock = () => [
    getTemplatesCountsByTemplateExternalIdGetV2MockHandler(),
    getTemplatesActionrequiredGetV2MockHandler(),
    getTemplatesOrgToOrgCopyPostV2MockHandler(),
    getAdminTemplatesPostV2MockHandler(),
];
