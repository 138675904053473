import { Moment } from "moment";

import { ActivationStatus } from "types/enum";

import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { StatusTypes } from "commonComponents/utilities/Status/StatusDisplay";
import { StatusTagDisplay } from "commonComponents/utilities/Status/StatusTagDisplay";

export interface IUserActivationStatusTagProps {
    activationStatus: ActivationStatus;
    activationSent?: Moment;
    activationConfirmed?: Moment;
    className?: string;
}

export const UserActivationStatusTag: React.FunctionComponent<IUserActivationStatusTagProps> = (
    props: IUserActivationStatusTagProps
) => {
    const statusType = getActivationStatusType(props.activationStatus);
    const statusText = getActivationStatusText(props.activationStatus);
    return (
        <StatusTagDisplay
            className={props.className}
            data-testid="userActivationStatusTag"
            statusType={statusType}
            statusText={statusText}
            popoverContent={
                <>
                    {getActivationPopoverText(
                        props.activationStatus,
                        props.activationSent,
                        props.activationConfirmed
                    )}
                </>
            }
        />
    );
};

// TODO: Remove 'export' after all usages have been updated.
export const getActivationStatusType = (activationStatus: ActivationStatus): StatusTypes => {
    switch (activationStatus) {
        case ActivationStatus.Active:
            return "success";
        case ActivationStatus.NotSent:
            return "";
        case ActivationStatus.Pending:
            return "pending";
        case ActivationStatus.Disabled:
            return "danger";
        default:
            return "";
    }
};

// TODO: Remove 'export' after all usages have been updated.
export const getActivationStatusText = (activationStatus: ActivationStatus) => {
    switch (activationStatus) {
        case ActivationStatus.Active:
            return "Active";
        case ActivationStatus.NotSent:
            return "Inactive";
        case ActivationStatus.Pending:
            return "Pending";
        case ActivationStatus.Disabled:
            return "Disabled";
        default:
            return "Inactive";
    }
};

const getActivationPopoverText = (
    activationStatus: ActivationStatus,
    activationSent?: Moment,
    activationConfirmed?: Moment
) => {
    switch (activationStatus) {
        case ActivationStatus.Active:
            if (activationConfirmed) {
                return (
                    <>
                        Active since&nbsp;
                        <DateDisplay value={activationConfirmed} />
                    </>
                );
            }
            return "This user is Active and able to log in to Buildertrend";
        case ActivationStatus.NotSent:
            return "You haven't invited this client to Buildertrend yet";
        case ActivationStatus.Pending:
            return (
                <>
                    Hasn't accepted your invite from&nbsp;
                    <DateDisplay value={activationSent!} />
                </>
            );
        case ActivationStatus.Disabled:
            return "No longer active. You can enable their account on the customer contact page.";
    }
};
