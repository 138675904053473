import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { ClientObservableBackgroundJobDetails } from "commonComponents/utilities/ClientObservableBackgroundJobStatusPopup/ClientObservableBackgroundJobStatusPopup.types";

export function GenericItem({ process }: { process: ClientObservableBackgroundJobDetails }) {
    return (
        <>
            <BTTitle level={4} className="TruncateText margin-bottom-zero">
                {process.processName}
            </BTTitle>
        </>
    );
}
