/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { ErrorType } from "../utilities/api/apix-handler";
import type { HasNotificationResponse } from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const notificationsHasNotificationsGetV2 = (
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<HasNotificationResponse>(
        { url: `/apix/v2/Notifications/has-notifications`, method: "GET", signal },
        options
    );
};

export const getNotificationsHasNotificationsGetV2QueryKey = () => {
    return [`/apix/v2/Notifications/has-notifications`] as const;
};

export const getNotificationsHasNotificationsGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
        TError,
        TData
    >;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getNotificationsHasNotificationsGetV2QueryKey();

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>
    > = ({ signal }) => notificationsHasNotificationsGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type NotificationsHasNotificationsGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>
>;
export type NotificationsHasNotificationsGetV2QueryError = ErrorType<unknown>;

export function useNotificationsHasNotificationsGetV2<
    TData = Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
        TError,
        TData
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getNotificationsHasNotificationsGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const notificationsViewedNotificationsPostV2 = (
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<void>(
        { url: `/apix/v2/Notifications/viewed-notifications`, method: "POST" },
        options
    );
};

export const getNotificationsViewedNotificationsPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
        void
    > = () => {
        return notificationsViewedNotificationsPostV2(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type NotificationsViewedNotificationsPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>
>;

export type NotificationsViewedNotificationsPostV2MutationError = ErrorType<unknown>;

export const useNotificationsViewedNotificationsPostV2 = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = getNotificationsViewedNotificationsPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};

export const getNotificationsHasNotificationsGetV2ResponseMock = (
    overrideResponse: Partial<HasNotificationResponse> = {}
): HasNotificationResponse => ({
    hasNotifications: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getNotificationsHasNotificationsGetV2MockHandler = (
    overrideResponse?:
        | HasNotificationResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<HasNotificationResponse> | HasNotificationResponse)
) => {
    return http.get("*/v2/Notifications/has-notifications", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getNotificationsHasNotificationsGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getNotificationsViewedNotificationsPostV2MockHandler = (
    overrideResponse?:
        | void
        | ((info: Parameters<Parameters<typeof http.post>[1]>[0]) => Promise<void> | void)
) => {
    return http.post("*/v2/Notifications/viewed-notifications", async (info) => {
        if (typeof overrideResponse === "function") {
            await overrideResponse(info);
        }
        return new HttpResponse(null, { status: 200 });
    });
};
export const getNotificationsMock = () => [
    getNotificationsHasNotificationsGetV2MockHandler(),
    getNotificationsViewedNotificationsPostV2MockHandler(),
];
