import { message } from "antd";
import classNames from "classnames";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import Lightbox, {
    IBaseSlide,
    IDownloadFunctionProps,
    RenderSlideProps,
    ViewCallbackProps,
} from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Download from "yet-another-react-lightbox/plugins/download";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { generateExternalAttachmentFromLink } from "utilities/document/fileDownload.utils";
import { lazyLoadWithRetry } from "utilities/react.utilities";
import { AllOrNone } from "utilities/type/AllOrNone";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    is360PhotoSlide,
    isShareableMedia,
    isVimeoVideoSlide,
    shouldAutoplayVideo,
} from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { LightboxSocialMediaButtons } from "commonComponents/btWrappers/BTLightbox/common/LightboxSocialMediaButtons";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";
import { VideoPlayer } from "commonComponents/utilities/VideoPlayer/VideoPlayer";

import { IVideoListHandler, VideoListHandler } from "entity/video/VideoList/VideoList.api.handler";

import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/styles.css";

import "./BTLightbox.less";

const Pannellum = lazyLoadWithRetry(() => import("../../utilities/Pannellum/PannellumWrapper"));

interface ICommonBTLightboxProps {
    /**
     * Whether or not the Lightbox is open
     */
    open: boolean;
    /**
     * Callback when the Lightbox closes. You probably want to set the parent's open state to false here
     */
    onClose(): void;
    /**
     * The slides that the Lightbox will display and navigate through
     */
    slides: IBaseSlide[];
    /**
     * Optional video handler - specifically so we can mock it in tests
     */
    videoHandler?: IVideoListHandler;
}

interface IControlledBTLightboxProps {
    /**
     * Controls the index being displayed on the Lightbox.
     * By default, the Lightbox will start at index 0 and maintain it's own state.
     * You must provide the onSlideChange callback and maintain the state yourself when using this prop.
     */
    index: number;
    /**
     * Callback when a new slide becomes visible in the Lightbox
     * @param index the index of the slide
     * @param slide the slide object
     */
    onSlideChange(index: number, slide: IBaseSlide): void;
}

export type IBTLightboxProps = ICommonBTLightboxProps & AllOrNone<IControlledBTLightboxProps>;

const defaultVideoHandler = new VideoListHandler();

export const BTLightbox: FunctionComponent<IBTLightboxProps> = ({
    open,
    onClose,
    slides,
    index,
    onSlideChange,
    videoHandler = defaultVideoHandler,
}) => {
    const [isSlideshowPlaying, setIsSlideshowPlaying] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);

    const slidesToRender = useMemo(
        () =>
            slides.map((slide, i) => {
                return {
                    ...slide,
                    description: isShareableMedia(slide) ? (
                        <BTRow gutter={[8, 0]}>
                            <BTCol>
                                <LightboxSocialMediaButtons index={i} slide={slide} />
                            </BTCol>
                            <BTCol>{slide.description}</BTCol>
                        </BTRow>
                    ) : (
                        slide.description
                    ),
                };
            }),
        [slides]
    );

    const handleClose = useCallback(() => {
        setIsSlideshowPlaying(false);
        setIsZoomed(false);
        onClose();
    }, [onClose]);

    const handleSlideView = useCallback(
        ({ index }: ViewCallbackProps) => {
            onSlideChange?.(index, slides[index]);
        },
        [onSlideChange, slides]
    );

    const handleSlideshowStart = () => {
        setIsSlideshowPlaying(true);
    };

    const handleSlideshowStop = () => {
        setIsSlideshowPlaying(false);
    };

    const handleZoomChange = (zoomData: { zoom: number }) => {
        setIsZoomed(zoomData.zoom > 1);
    };

    const handleCustomSlideDownload = ({ slide, saveAs }: IDownloadFunctionProps) => {
        if (slide.type === "custom-video") {
            void loadData();
            async function loadData() {
                try {
                    const response = await videoHandler.getVideoDownloadLinks(
                        [slide.vimeoId],
                        slide.metadata?.jobId ?? JobIdTypes.AllJobs
                    );

                    if (response.linksList?.length > 0) {
                        await Promise.all(
                            response.linksList.map(
                                async (linkUrl, i) =>
                                    await generateExternalAttachmentFromLink(linkUrl, i)
                            )
                        );
                    } else if (response.linksList?.length === 0) {
                        void message.error("This video is unable to be downloaded.");
                    }
                } catch (e) {
                    showAPIErrorMessage(e);
                }
            }
        }
        // If slides were provided via the mediaDataToSlide or btFileSystemToSlide utility function, they will have a download object
        else if (slide.download) {
            saveAs(slide.download.url, slide.download.filename);
        }
        // If slides were built manually, attempt to download the piece of media from src property
        else if (slide.src) {
            saveAs(slide.src);
        } else {
            void message.error(
                "An error has occurred trying to download this file, please try again later."
            );
        }
    };

    const renderSlide = ({ slide, offset }: RenderSlideProps) => {
        if (isVimeoVideoSlide(slide)) {
            return (
                <FocusProvider disabled={!open} hotkeysToRemove={["navRight", "navLeft"]}>
                    <VideoPlayer
                        className="VideoMediaView"
                        vimeoUrl={slide.src}
                        autoPlay={shouldAutoplayVideo(offset, isSlideshowPlaying)}
                        vimeoId={slide.vimeoId}
                    />
                </FocusProvider>
            );
        }

        if (is360PhotoSlide(slide)) {
            return (
                // We have to stop the pointer down from propagating up so the slide doesn't change
                <div onPointerDown={(e) => e.stopPropagation()}>
                    {/* Empty height/width overridden by less styles */}
                    <FocusProvider disabled={!open} hotkeysToRemove={["navRight", "navLeft"]}>
                        <Pannellum
                            width=""
                            height=""
                            autoLoad
                            image={slide.src}
                            showFullscreenCtrl={true}
                        />
                    </FocusProvider>
                </div>
            );
        }

        // if the slide type wasn't one of these custom types, it is a yarl native type
        // return undefined here so that yarl will handle the rendering of the slide
        return undefined;
    };

    const renderNavigation = slides.length <= 1 ? () => null : undefined;

    return (
        <div
            onKeyDown={(e) => {
                // prevent base modal from closing
                if (e.key === "Escape") {
                    e.stopPropagation();
                }
            }}
        >
            <BTSuspense>
                <FocusProvider disabled={!open} hotkeysToRemove={["navRight", "navLeft"]}>
                    <Lightbox
                        className={classNames("BTLightbox", {
                            Slideshow: isSlideshowPlaying,
                            Pannable: isZoomed,
                        })}
                        open={open}
                        close={handleClose}
                        slides={slidesToRender}
                        index={index}
                        plugins={[Zoom, Counter, Captions, Slideshow, Video, Download]}
                        zoom={{ maxZoomPixelRatio: 10 }}
                        counter={{ style: { top: "unset", left: "unset", bottom: 0, right: 0 } }}
                        slideshow={{ autoplay: false }}
                        on={{
                            view: handleSlideView,
                            slideshowStart: handleSlideshowStart,
                            slideshowStop: handleSlideshowStop,
                            zoom: handleZoomChange,
                        }}
                        carousel={{
                            finite: slides.length <= 1,
                            preload: 1,
                            imageProps: {
                                draggable: !isZoomed,
                            },
                        }}
                        captions={{ showToggle: true }}
                        render={{
                            slide: renderSlide,
                            buttonPrev: renderNavigation,
                            buttonNext: renderNavigation,
                            buttonSlideshow: renderNavigation,
                        }}
                        download={{ download: handleCustomSlideDownload }}
                    />
                </FocusProvider>
            </BTSuspense>
        </div>
    );
};
