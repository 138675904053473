import classNames from "classnames";
import { useLayoutEffect, useRef } from "react";

import {
    stickyHeaderHeightActions,
    stickyHeaderHeightTitle,
    stickyHeaderLevelActions,
    stickyHeaderLevelTitle,
    StickyHeaderType,
} from "commonComponents/btWrappers/BTListLayout/BTListLayoutHeader";
import { useStickyHeight } from "commonComponents/utilities/StickyContext";

import "./GridContainerHeader.less";

interface IGridContainerHeaderProps {
    className?: string;
    content: React.ReactNode;
    stickyHeader?: StickyHeaderType;
    autoHeight?: boolean;
}

export const GridContainerHeader: React.FunctionComponent<IGridContainerHeaderProps> = (props) => {
    const { className, stickyHeader = "Title", autoHeight = false } = props;

    // This is a hack to get the height of the header to the kendo grid container to set the top dynamically
    const headerRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (headerRef.current) {
            const height = (headerRef.current as HTMLDivElement).offsetHeight || 0;
            document.documentElement.style.setProperty(
                "--grid-container-header-height",
                `${height}px`
            );
        }
    }, [headerRef]);

    const isTitle = stickyHeader === "Title";

    const stickyHeaderHeight = isTitle ? stickyHeaderHeightTitle : stickyHeaderHeightActions;
    const stickyHeaderLevel = isTitle ? stickyHeaderLevelTitle : stickyHeaderLevelActions;
    const top = useStickyHeight(stickyHeaderHeight, "top", stickyHeaderLevel);

    const combinedClasses = classNames(className, "GridContainer-Header StickyLayoutHeader", {
        isTitle: !isTitle,
    });

    return (
        <div
            ref={headerRef}
            className={combinedClasses}
            style={{ height: !autoHeight ? stickyHeaderHeight : "auto", top }}
        >
            {props.content}
        </div>
    );
};
