import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { DocumentInstanceType, MediaType } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import {
    AttachVideoRequest,
    BTFileUploadDocsRequest,
    BTFileUploadEntity,
    BTFileUploadPhotosRequest,
    BTFileUploadPhotosResponse,
    CreateVideoRequest,
    EntityDocsRequest,
    EntityDocsResponse,
    IBTFileUploadFormValues,
} from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";

export interface IFileUploadHandler {
    get(folderId: number, jobId: number, mediaType: MediaType): Promise<BTFileUploadEntity>;
    saveDocs(folderId: number, values: IBTFileUploadFormValues): Promise<EmptyResponseEntity>;
    savePhotos(
        folderId: number,
        values: IBTFileUploadFormValues
    ): Promise<BTFileUploadPhotosResponse>;
    saveVideo(data: CreateVideoRequest): Promise<EmptyResponseEntity>;
    attachVideo(data: AttachVideoRequest): Promise<BTFileSystem>;
    attachVideoExternal(
        data: AttachVideoRequest,
        subId: string,
        entityId: string
    ): Promise<BTFileSystem>;
    saveEntityDocs(data: EntityDocsRequest): Promise<EntityDocsResponse>;
    postEntityDocsExternal(
        data: EntityDocsRequest,
        subId: string,
        entityId: string,
        docType: DocumentInstanceType
    ): Promise<EntityDocsResponse>;
}

export class FileUploadHandler implements IFileUploadHandler {
    async get(folderId: number, jobId: number, mediaType: MediaType): Promise<BTFileUploadEntity> {
        return await APIHandler("/api/Media/FileUploader", {
            method: "GET",
            data: { folderId, jobId, mediaType },
            responseType: BTFileUploadEntity,
        });
    }

    async saveDocs(
        folderId: number,
        values: IBTFileUploadFormValues
    ): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Documents/TempDocuments", {
            method: "POST",
            data: new BTFileUploadDocsRequest(folderId, values),
            responseType: EmptyResponseEntity,
        });
    }

    async savePhotos(
        folderId: number,
        values: IBTFileUploadFormValues
    ): Promise<BTFileUploadPhotosResponse> {
        return await APIHandler("/api/Photos/SaveTempPhotos", {
            method: "POST",
            data: new BTFileUploadPhotosRequest(folderId, values),
            responseType: BTFileUploadPhotosResponse,
        });
    }

    async saveVideo(data: CreateVideoRequest): Promise<EmptyResponseEntity> {
        return await APIHandler("/api/Videos/CreateVideo", {
            method: "POST",
            data,
            responseType: EmptyResponseEntity,
        });
    }

    async attachVideo(data: AttachVideoRequest): Promise<BTFileSystem> {
        return await APIHandler(`/api/Videos/Attach?jobId=${data.jobId}`, {
            method: "PUT",
            data,
            responseType: BTFileSystem,
        });
    }

    async saveEntityDocs(data: EntityDocsRequest): Promise<EntityDocsResponse> {
        return await APIHandler("/api/Documents/EntityDocs", {
            method: "POST",
            data,
            responseType: EntityDocsResponse,
        });
    }

    // External Uploads
    async attachVideoExternal(
        data: AttachVideoRequest,
        subId: string,
        entityId: string
    ): Promise<BTFileSystem> {
        return await APIHandler(
            `/api/Videos/AttachExternal?jobId=${data.jobId}&entityId=${entityId}&subId=${subId}`,
            {
                method: "PUT",
                data,
                responseType: BTFileSystem,
            }
        );
    }

    // External Uploads
    async postEntityDocsExternal(
        data: EntityDocsRequest,
        subId: string,
        entityId: string,
        docType: DocumentInstanceType
    ): Promise<EntityDocsResponse> {
        return await APIHandler(
            `/api/documents/entityDocsExternal?docType=${docType}&entityId=${entityId}&subId=${subId}`,
            {
                method: "POST",
                data,
                responseType: EntityDocsResponse,
            }
        );
    }
}
