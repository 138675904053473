import { PictureFilled } from "@ant-design/icons";
import { FunctionComponent } from "react";
import { Grey7 } from "styles/antTheme/Colors";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

export const BTIconPictureFilled: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useAntIcon(baseProps);

    return <PictureFilled {...props} style={{ ...props.style, color: Grey7 }} />;
};
