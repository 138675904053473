import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { FolderTypes, ImageResizeMode } from "types/enum";

import { dateSorter } from "utilities/sort/sort";

import { canViewBTFileSystemInLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { IBTColumnProps } from "commonComponents/btWrappers/BTTable/BTTable";
import { DateDisplay } from "commonComponents/utilities/DateDisplay/DateDisplay";
import { IconFile } from "commonComponents/utilities/IconFile/IconFile";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

export function thumbnailColumn({
    width,
    onClick,
}: {
    width?: string | number;
    onClick: (e: React.MouseEvent<HTMLImageElement, MouseEvent>, file: BTFileSystem) => void;
}): IBTColumnProps<BTFileSystem> {
    return {
        width,
        dataIndex: "extension",
        title: "",
        render: (extension, file) => {
            const isFolder = file.folderType !== FolderTypes.NotAFolder;
            const isGlobalFolder = file.folderType === FolderTypes.GlobalFolder;
            const thumbnail = file.thumbnail ?? file.docPath;
            const canViewInLightbox = canViewBTFileSystemInLightbox(file);
            return (
                <div className="flex justify-content-center">
                    <IconFile
                        isFolder={isFolder}
                        isGlobalFolder={isGlobalFolder}
                        fileType={extension}
                        size="small"
                        thumbnail={canViewInLightbox ? thumbnail : undefined}
                        thumbnailSize="medium"
                        thumbnailResizeMode={ImageResizeMode.Max}
                        onClick={(e) => onClick(e, file)}
                    />
                </div>
            );
        },
    };
}

export function titleColumn({
    width,
}: { width?: string | number } = {}): IBTColumnProps<BTFileSystem> {
    return {
        width,
        dataIndex: "title",
        title: "Name",
        render: (title) => <TextTruncate className="TitleColumn">{title}</TextTruncate>,
        sorter: (a, b) => a.title.localeCompare(b.title),
    };
}

export function fileSizeColumn({
    width,
}: {
    width?: string | number;
} = {}): IBTColumnProps<BTFileSystem> {
    return {
        width,
        dataIndex: "fileSizeFormatted",
        title: "Size",
        sorter: (a, b) => a.fileSizeFormatted.localeCompare(b.fileSizeFormatted),
    };
}

export function lastModifiedColumn({
    width,
}: {
    width?: string | number;
} = {}): IBTColumnProps<BTFileSystem> {
    return {
        width,
        dataIndex: "lastModifiedOn",
        title: "Modified On",
        render: (lastModifiedOn: moment.Moment | null) =>
            lastModifiedOn ? (
                <TextTruncate className="DateColumn">
                    <DateDisplay value={lastModifiedOn} showTime />
                </TextTruncate>
            ) : null,
        sorter: (a, b) => dateSorter(a.lastModifiedOn, b.lastModifiedOn),
    };
}

export function addedByColumn({
    width,
}: {
    width?: string | number;
} = {}): IBTColumnProps<BTFileSystem> {
    return {
        width,
        dataIndex: "addedBy",
        title: "Added By",
        render: (addedBy) => <TextTruncate className="AddedByColumn">{addedBy}</TextTruncate>,
        sorter: (a, b) => a.addedBy.localeCompare(b.addedBy),
    };
}
