import { Pagination } from "antd";
import classNames from "classnames";
import { Component } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { BTLocalStorage } from "types/btStorage";

import { track } from "utilities/analytics/analytics";
import { getPagingData, ListEntityType, PagingData } from "utilities/list/list.types";
import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { Hotkey } from "commonComponents/utilities/Hotkey/Hotkey";
import { getHotkeyProps } from "commonComponents/utilities/Hotkey/hotkey.utility";
import { NumberDisplay } from "commonComponents/utilities/NumberDisplay/NumberDisplay";

import "./BTPagination.less";

interface IBTPaginationProps {
    /** List id for saving/retrieval */
    entityType: ListEntityType;
    pagingData: PagingData;
    onChange: (updatedPagingData: PagingData) => void;
    resetScrollOnChange?: boolean;
    id: KeyOfOrString;
    className?: string;
}

@track({ component: "Pagination Controls" })
export class BTPagination extends Component<IBTPaginationProps> {
    static defaultProps = {
        resetScrollOnChange: true,
        className: "",
    };

    availablePageSizes = [20, 50, 75, 100, 250];

    private resetScrollPosition = () => {
        const { resetScrollOnChange } = this.props;
        if (resetScrollOnChange) {
            window.scrollTo({ left: 0, top: 0 });
        }
    };

    private handleChange = (page: number, pageSize?: number) => {
        const { pagingData, onChange } = this.props;
        onChange(getPagingData(page, pageSize, pagingData.total));
        this.resetScrollPosition();
    };

    private handlePageSizeChange = (pageSize?: number) => {
        const { pagingData, onChange } = this.props;
        // reset back to page 1 and set page size
        onChange(getPagingData(1, pageSize, pagingData.total));
        this.resetScrollPosition();
        this.setPageSizePreferences(pageSize);
    };

    private setPageSizePreferences = (pageSize?: number) => {
        const { entityType } = this.props;

        const oldPageSizePreferences = BTLocalStorage.get("bt-object-pageSizePreferences");
        const newPageSizePreferences = {
            ...oldPageSizePreferences,
            [entityType]: pageSize,
        };

        BTLocalStorage.set("bt-object-pageSizePreferences", newPageSizePreferences);
    };

    private renderRange = () => {
        const { pageSize, firstRow, lastRow, total } = this.props.pagingData;
        const start = <NumberDisplay value={firstRow} decimalScale={0} />;
        const end = <NumberDisplay value={lastRow < total ? lastRow : total} decimalScale={0} />;
        const totalItems = <NumberDisplay value={total} decimalScale={0} />;
        if (pageSize) {
            return (
                <span className="range">
                    {start}-{end} of {totalItems} items
                </span>
            );
        } else {
            return null;
        }
    };

    private renderPageSizeSelector = () => {
        const { id } = this.props;
        const { pageSize, total } = this.props.pagingData;
        if (pageSize && total > this.availablePageSizes[0]) {
            const pageSizeOptions = this.availablePageSizes.map(
                (size) =>
                    new BTSelectItem({
                        key: size,
                        value: size,
                        name: `${size} / page`,
                        selected: pageSize === size,
                    })
            );

            return (
                <BTSelect
                    id={id}
                    data-testid={id}
                    className="PageSizeSelect"
                    treeData={pageSizeOptions}
                    value={pageSize}
                    onChange={(field: string, value: number) => this.handlePageSizeChange(value)}
                    onBlur={() => {}}
                    getPopupContainer={(trigger) => trigger.parentNode}
                />
            );
        } else {
            return null;
        }
    };

    private getItemRender = (
        page: number,
        type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
        originalElement: React.ReactElement<HTMLElement>
    ) => {
        let element = originalElement;
        if (type === "prev") {
            const newPage = page--;
            element = (
                <Hotkey onCommand={() => this.handleChange(newPage)} {...getHotkeyProps("navLeft")}>
                    {originalElement}
                </Hotkey>
            );
        } else if (type === "next") {
            const newPage = page++;
            element = (
                <Hotkey
                    onCommand={() => this.handleChange(newPage)}
                    {...getHotkeyProps("navRight")}
                >
                    {originalElement}
                </Hotkey>
            );
        }
        return element;
    };

    render() {
        const {
            pagingData: { currentPage, pageSize, total },
            entityType,
            className,
        } = this.props;
        const divClassnames = classNames("BTPagination", className);

        return (
            <div className={divClassnames}>
                {this.renderRange()}
                <Pagination
                    className="navigator"
                    simple
                    defaultPageSize={getDefaultPageSize(entityType)}
                    hideOnSinglePage
                    current={currentPage}
                    pageSize={pageSize}
                    total={total}
                    onChange={this.handleChange}
                    onShowSizeChange={this.handlePageSizeChange}
                    itemRender={this.getItemRender}
                />
                {this.renderPageSizeSelector()}
            </div>
        );
    }
}

export default BTPagination;

export function getDefaultPageSize(entityType: ListEntityType, defaultValue: number = 50) {
    const pageSizePreferences = BTLocalStorage.get("bt-object-pageSizePreferences");

    return pageSizePreferences[entityType] ?? defaultValue;
}
