import { Menu, MenuProps } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { CSSProperties } from "react";

export interface IBTMenuProps extends MenuProps {
    activeKey?: string;
    className?: string | undefined;
    /**
     * @example
     * "contextMenu" | "actionMenu"
     */
    "data-testid"?: string;
    onClick?: MenuClickEventHandler;
    onOpenChange?: (openKeys: string[]) => void;
    openKeys?: string[];
    selectable?: boolean;
    selectedKeys?: string[];
    style?: CSSProperties | undefined;
    triggerSubMenuAction?: "click" | "hover";
}

export const BTMenu: React.FC<IBTMenuProps> = ({ children, ...otherProps }) => {
    return (
        // eslint-disable-next-line react/forbid-elements
        <Menu {...otherProps} tabIndex={-1}>
            {children}
        </Menu>
    );
};
