import Icon from "@ant-design/icons";
import classnames from "classnames";
import { FunctionComponent } from "react";

import SVG from "images/Trash.svg?react";

import "./BTIconTrash.less";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

interface IBTIconTrashProps extends IBTIconProps {
    isDangerous?: boolean;
}

export const BTIconTrash: FunctionComponent<IBTIconTrashProps> = (baseProps) => {
    const { isDangerous, ...iconProps } = baseProps;
    const [props] = useBaseIcon(iconProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon
            {...props}
            component={SVG}
            className={classnames("BTIconTrash", props.className, { isDangerous })}
        />
    );
};
