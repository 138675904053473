import { useEffect } from "react";
import { useLocation } from "react-router";

import { BTLoginTypes } from "types/enum";

/** Sets the login portal type on the window */
export function setPortalLoginType(portalType: BTLoginTypes) {
    let btJScriptGlobals = window.btJScriptGlobals;
    btJScriptGlobals.loginTypeInt = portalType;

    switch (portalType) {
        case BTLoginTypes.OWNER:
            btJScriptGlobals.loginType = "OWNER";
            break;
        case BTLoginTypes.SUBS:
            btJScriptGlobals.loginType = "SUBS";
            break;
        case BTLoginTypes.BUILDER:
            btJScriptGlobals.loginType = "BUILDER";
            break;
        case BTLoginTypes.BTADMIN:
            btJScriptGlobals.loginType = "BTADMIN";
            break;
    }
}

export function AppRouteChange() {
    const location = useLocation();

    useEffect(() => {
        const urlPath = location.pathname.toLowerCase();
        let usersPortalType = BTLoginTypes.BUILDER;

        if (urlPath.startsWith("/owner/")) {
            usersPortalType = BTLoginTypes.OWNER;
        } else if (urlPath.startsWith("/subs/")) {
            usersPortalType = BTLoginTypes.SUBS;
        } else if (urlPath.startsWith("/admin/")) {
            usersPortalType = BTLoginTypes.BTADMIN;
        } else if (urlPath.startsWith("/share/")) {
            usersPortalType = BTLoginTypes.ALL;
        }

        setPortalLoginType(usersPortalType);
    }, [location.pathname]);

    return null;
}
