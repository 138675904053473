import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTIconExternalLink } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { getOpenInNewTabLink } from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu.utility";

interface IMenuItemOpenInNewTabProps {
    file: BTFileSystem;
}

const MenuItemOpenInNewTab = ({ file, ...antProps }: IMenuItemOpenInNewTabProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`openInNewTab-menuitem-${file.id}`}
            data-testid={`openInNewTab-menuitem-${file.id}`}
        >
            <BTExternalLink
                href={getOpenInNewTabLink(file)}
                isUnderline={false}
                data-testid={`openInNewTab-${file.id}`}
            >
                <BTIconExternalLink /> Open in New Tab
            </BTExternalLink>
        </BTMenuItem>
    );
};

export default MenuItemOpenInNewTab;
