export class IntercomSettings {
    constructor(data: any) {
        this.app_id = data.app_id;
        this.email = data.email;
        this.user_id = data.user_id;
        this.user_hash = data.user_hash;
        this.created_at = data.created_at;
        this.name = data.name;
        this.phone = data.phone;
        this.company = new Company(data.company);
        this.Role = data.Role;
        this.Admin = data.Admin;
        this.IsBillingContact = data.IsBillingContact;
        this.HasOnboardingContact = data.HasOnboardingContact;
        this.IsAccountingAdmin = data.IsAccountingAdmin;
        this.IsLinkedToMultipleBuilders = data.IsLinkedToMultipleBuilders;
        this.IsLinkedToTheHomeDepot = data.IsLinkedToTheHomeDepot;
        this.HasInternalUserEdit = data.HasInternalUserEdit;
        this.HasScheduleEdit = data.HasScheduleEdit;
        this.InternalUserActivationNumber = data.InternalUserActivationNumber;
        this.Program = data.Program;
        this["User Login"] = data["User Login"];
        this["User Type"] = data["User Type"];
        this["Payment Consultant Email"] = data["Payment Consultant Email"];
    }

    app_id: string;
    email: string;
    user_id: string;
    user_hash: string;
    created_at: number;
    name: string;
    phone: string;
    company: Company;
    Role: string;
    Admin: boolean;
    IsBillingContact: boolean;
    HasOnboardingContact: boolean;
    IsAccountingAdmin: boolean;
    IsLinkedToMultipleBuilders: boolean;
    "User Login": string;
    "User Type": string;
    "Payment Consultant Email": string;
    IsLinkedToTheHomeDepot: boolean;
    HasInternalUserEdit: boolean;
    HasScheduleEdit: boolean;
    InternalUserActivationNumber: number;
    Program: string;
}

class Company {
    constructor(data: any) {
        this.company_id = data.company_id;
        this.name = data.name;
        this.created_at = data.created_at;
        this.monthly_spend = data.monthly_spend;
        this.website = data.website;
        this.plan = data.plan;
        this.AccountingIntegration = data.AccountingIntegration;
        this.IsPaymentProcessingLinked = data.IsPaymentProcessingLinked;
        this.BuilderStatus = data.BuilderStatus;
        this.NextBillingDate = data.NextBillingDate;
        this.cancellationRequestReceived = data.CancellationRequestReceived;
        this.IsBuilderPastDue = data.IsBuilderPastDue;
        this.CurrentStage = data.CurrentStage;
        this.IsLinkedToTheHomeDepot = data.IsLinkedToTheHomeDepot;
        this.JobCountForBuilder = data.JobCountForBuilder;
        this.HasCustomCostCodes = data.HasCustomCostCodes;
        this.FeatureFlags1To30 = data.FeatureFlags1To30;
        this.FeatureFlags31To60 = data.FeatureFlags31To60;
        this.FeatureFlags61To90 = data.FeatureFlags61To90;
        this.FeatureFlags91To120 = data.FeatureFlags91To120;
        this.FeatureFlags121To150 = data.FeatureFlags121To150;
        this.FeatureFlags151To180 = data.FeatureFlags151To180;
        this.FeatureFlags181To200 = data.FeatureFlags181To200;
        this.HOApplicationCompleted = data.HOApplicationCompleted;
        this.HOApplicationStatus = data.HOApplicationStatus;
        this.PolicyDocumentLogged = data.PolicyDocumentLogged;
        this.JobSiteApplicationCompleted = data.JobSiteApplicationCompleted;
        this.JobSiteApplicationStatus = data.JobSiteApplicationStatus;
        this.AccountOwner = data.AccountOwner;
        this.AccountExecutiveGrowth = data.AccountExecutiveGrowth;
        this.CustomerEngagementSpecialist = data.CustomerEngagementSpecialist;

        this["BTAdmin Page"] = data["BTAdmin Page"];
        this["SF builder page"] = data["SF builder page"];
    }

    company_id: string;
    name: string;
    created_at: number;
    monthly_spend: number;
    website: string;
    plan: string;
    AccountingIntegration: string;
    IsPaymentProcessingLinked: boolean;
    BuilderStatus: string;
    NextBillingDate: number;
    cancellationRequestReceived: boolean;
    IsBuilderPastDue: boolean;
    "BTAdmin Page": string;
    "SF builder page": string;
    CurrentStage: string;
    IsLinkedToTheHomeDepot: string;
    JobCountForBuilder: string;
    HasCustomCostCodes: boolean;
    FeatureFlags1To30: string;
    FeatureFlags31To60: string;
    FeatureFlags61To90: string;
    FeatureFlags91To120: string;
    FeatureFlags121To150: string;
    FeatureFlags151To180: string;
    FeatureFlags181To200: string;
    HOApplicationCompleted: number;
    HOApplicationStatus: string;
    PolicyDocumentLogged: number;
    JobSiteApplicationCompleted: number;
    JobSiteApplicationStatus: string;
    AccountOwner: string;
    AccountExecutiveGrowth: string;
    CustomerEngagementSpecialist: string;
}
