import NotFoundError from "layouts/errors/NotFoundError";
import { IListPageChildProps } from "layouts/ListPageLayout";
import { RouteComponentProps } from "react-router";

import { WhenBuilderFlag } from "helpers/globalContext/BuilderInfoContext";

interface IJobProps {
    jobId: string;
    defaultTab: string;
}

export function JobPage(_first: IListPageChildProps, second: RouteComponentProps<IJobProps>) {
    const { location } = second;

    const { jobId, defaultTab } = second.match.params;
    const query = new URLSearchParams(location.search);
    const accessedFromContact = query.get("accessedFromContact") === "true";
    const accessedFromLead = query.get("accessedFromLead") === "true";
    const ownerInvoiceId = query.get("ownerInvoiceId");
    const openCondensed = query.get("openCondensed") === "true";

    return (
        <WhenBuilderFlag
            isEnabled="bdsJobDetailsToFullPage"
            then={
                <div
                    style={{
                        color: "red",
                        fontSize: "40px",
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    Job Details Page
                    <div>Job ID: {jobId}</div>
                    <div>Default Tab: {defaultTab}</div>
                    <div>Accessed from Contact: {accessedFromContact ? "Yes" : "No"}</div>
                    <div>Accessed from Lead: {accessedFromLead ? "Yes" : "No"}</div>
                    <div>Owner Invoice ID: {ownerInvoiceId}</div>
                    <div>Open Condensed: {openCondensed ? "Yes" : "No"}</div>
                </div>
            }
            otherwise={<NotFoundError />}
        />
    );
}
