import { BTIconNotifications } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemNotifySubsProps {
    fileId: number;
    onClick: () => Promise<void>;
}

const MenuItemNotifySubs = ({ fileId, onClick, ...antProps }: IMenuItemNotifySubsProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`notifySubs-menuitem-${fileId}`}
            data-testid={`notifySubs-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconNotifications isOnWhite /> Notify Subs/Vendors
        </BTMenuItem>
    );
};

export default MenuItemNotifySubs;
