import { Component } from "react";

import "./CKEditorDisplay.less";

interface ICKEditorDisplayProps {
    dangerouslySetInnerHTML: {
        __html: string;
    };
    shouldWordBreak?: boolean;
    height?: string;
    width?: string;
}

/** This component attempts to overwrite css on child elements so the html rendered displays the same way it does in the ckeditor iframe
 * The ckeditor readonly mode was not used because it causes issues with browser print views
 */
export class CKEditorDisplay extends Component<ICKEditorDisplayProps> {
    static defaultProps = {
        shouldWordBreak: true,
    };

    render() {
        const { width, height } = this.props;
        return (
            <div
                className="CKEditorDisplay"
                style={{
                    wordBreak: this.props.shouldWordBreak ? "break-word" : "normal",
                    maxHeight: height,
                    overflow: height ? "auto" : undefined,
                    maxWidth: width,
                }}
            >
                {
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: this.props.dangerouslySetInnerHTML.__html,
                        }}
                    />
                }
            </div>
        );
    }
}
