import {
    AttachedFiles,
    AttachedFilesRequest,
    BTFileSystem,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import { LineItemType } from "legacyComponents/LineItemContainer.types";
import { partition } from "lodash-es";
import moment from "moment";
import { RouteComponentProps } from "react-router";

import { BuilderInfo } from "helpers/AppProvider.types";

import { CostTypes, DocumentInstanceType, MarkedAs, ProposalGroupTypes } from "types/enum";

import yup from "utilities/form/yup";
import { calculateMarginPercentageFromMarkup } from "utilities/lineItem/lineItemHelper";
import { isNullOrUndefined } from "utilities/object/object";
import { getProposalGroupingEntityName } from "utilities/proposals/proposal.utils";

import { ILineItemIdsAndTypes } from "commonComponents/entity/invoicing/LineItemsToInvoice/LineItemsToInvoice.api.types";
import { IRelatedItemResponse } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { IFinancialImportDropdownProps } from "commonComponents/financial/FinancialImportDropdown/FinancialImportDropdown.types";
import { GridLineItemEntityType } from "commonComponents/utilities/LineItemContainer/types/LineItem.enums";
import {
    ILineItemGroup,
    ILineItemRow,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    IMarkupLineItem,
    ITaxLineItem,
    MarkupType,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { AssemblyEntity } from "entity/assembly/Assembly/Assembly.api.types";
import {
    AdjustPercentTypes,
    BulkMarkupLineItem,
} from "entity/estimate/AdjustPercentMarkup/AdjustPercentMarkup.api.types";
import { IJumpToQuickFindItem } from "entity/estimate/common/LineItemQuickFind/LineItemQuickFind.types";
import {
    EstimateActions,
    EstimateDisplayPreferences,
    EstimateResponse,
    TabKeys,
} from "entity/estimate/Estimate/Estimate.api.types";
import { IWorksheetPresentationalValues } from "entity/estimate/EstimateLineItemContainer/EstimateLineItemContainer.types";
import {
    IRecategorizeProposalFormatRequest,
    ProposalFormatItemValidationSchema,
} from "entity/estimate/Proposal/Proposal.types";
import { IFilterDrawerProps } from "entity/filters/Filter/FilterDrawer.types";
import { AdjustPercentMarkupFormValues } from "entity/job/Job.api.types";
import { IPoLineItemIdTypeMapping } from "entity/purchaseOrder/PoMapping/PoMappingInternal/PoMappingInternal.api.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";
import { IExternalProductLink, VendorTypes } from "entity/vendor/common/vendor.types";

export interface IEstimateLineItem extends IMarkupLineItem, ITaxLineItem, ILineItemRow {
    costCategoryId: number | null;
    assemblyId: number | null;
    quantity: number;
}

export enum EntityStatus {
    New = -2,
}

export enum OptionMode {
    Required = 0,
    Pending = 1,
    Approved = 2,
    Declined = 3,
}

export enum LineItemsToGet {
    All = 0,
    InScope = 1,
    OutOfScope = 2,
    OnlyRequired = 3,
    OnlyPending = 4,
    OnlyApproved = 5,
    OnlyDeclined = 6,
    OnlyFavorited = 7,
    OnlyDecided = 8,
}

export const InScopeOptionModes: readonly OptionMode[] = [OptionMode.Required, OptionMode.Approved];

export class ProposalCategory implements IHasAttachedFiles, ILineItemGroup {
    constructor(
        categoryData: any,
        builderId: number,
        jobId?: number,
        leadId?: number,
        readonlyLineItems: boolean = true
    ) {
        this.id = categoryData.id;
        this.costCategoryId = categoryData.costCategoryId;
        this.assemblyId = categoryData.assemblyId;
        this.title = categoryData.title;
        this.description = categoryData.description;
        this.displayOrder = categoryData.displayOrder;

        const unorderedLineItems: WorksheetLineItem[] = (
            (categoryData.lineItems ?? []) as any[]
        ).map((li) => {
            const lineItem = new WorksheetLineItem({ ...li, parentId: this.id });
            if (readonlyLineItems) {
                lineItem.isEditable = false;
            }
            return lineItem;
        });

        // currently change order line items are missing display order, leave them in the order received for consistency
        const [orderedChangeOrderLineItems, unorderedLineItemsWithDisplayOrder] = partition(
            unorderedLineItems,
            (li) => li.lineItemType === LineItemType.ChangeOrder
        );

        const orderedLineItemsWithDisplayOrder = unorderedLineItemsWithDisplayOrder.sort(
            (subItem1, subItem2) => {
                if (isNullOrUndefined(subItem1.displayOrder)) {
                    return 1;
                }

                if (isNullOrUndefined(subItem2.displayOrder)) {
                    return -1;
                }

                return subItem1.displayOrder - subItem2.displayOrder;
            }
        );

        const orderedSubgroups: ProposalSubCategory[] = ((categoryData.subGroups ?? []) as any[])
            .map((subGroup) => {
                let lineItems = undefined;
                if (subGroup.estimates) {
                    lineItems = subGroup.estimates?.map((li: any) => new LeadProposalLineItem(li));
                } else if (subGroup.lineItems) {
                    lineItems = subGroup.lineItems?.map((li: any) => new WorksheetLineItem(li));
                }

                return new ProposalSubCategory(
                    { ...subGroup, lineItems },
                    builderId,
                    jobId,
                    leadId,
                    readonlyLineItems
                );
            })
            .sort((subItem1, subItem2) => subItem1.displayOrder - subItem2.displayOrder);

        // ideally we rely entirely on display order here, but until change order line items have
        // pfis, we need to ensure they follow our current rules of line items before subgroups
        this.items = [
            ...orderedLineItemsWithDisplayOrder,
            ...orderedChangeOrderLineItems,
            ...orderedSubgroups,
        ];

        // IHasAttachedFiles
        if (categoryData.attachments || categoryData.attachedFiles) {
            // if uncategorized, this will be null
            this.attachedFiles = new AttachedFiles(
                categoryData.attachments || categoryData.attachedFiles
            );
            this.attachments = new AttachedFilesRequest();
        }
        this.documentInstanceType = DocumentInstanceType.ProposalFormatItem;
        this.jobId = jobId;
        this.leadId = leadId;
        this.builderId = builderId;
        this.isExpanded = categoryData.isExpanded;
        this.isSelected = false;
        this.aggregates = null;
        this.value = categoryData.title;
        this.field = categoryData.id;
        this.openAddAttachFiles = false;
        this.openCreateDocument = false;
        this.mountFileComponent = (this.attachedFiles?.files.length ?? 0) > 0;
        this._isInEdit = categoryData._isInEdit ?? false;
        this.files = this.attachedFiles?.files;
        this.viewAllFiles = false;
        this.parentId = categoryData.parentId;
        this.optionMode = categoryData.optionMode ?? OptionMode.Required;

        // this shouldn't be relied upon going forward, we dynamically choose the name for attached files when needed
        this.name = "Category";
    }

    id: number;
    costCategoryId: number | null;
    assemblyId?: number;
    title: string;
    description: string | null;
    displayOrder: number;
    parentId?: number;
    optionMode: OptionMode;

    // IHasAttachedFiles
    attachedFiles: AttachedFiles; // used for setting up the file upload container
    attachments: AttachedFilesRequest; // used for adding files for new cost groups
    builderId: number;
    documentInstanceType: DocumentInstanceType;
    jobId: number | undefined;
    leadId: number | undefined;
    name: string;

    openAddAttachFiles: boolean;
    openCreateDocument: boolean;
    viewAllFiles: boolean;
    // value used to determine whether or not to add the file component to the page.
    // on proposals with many categories, the mass mounting of this component is very slow
    // so we will only add it to the page when it is needed (the category has files, or they click the category dropdown to add new files)
    mountFileComponent: boolean;

    isExpanded: boolean;
    aggregates: any;
    field: string;
    isSelected: boolean;
    items: (WorksheetLineItem | ProposalSubCategory)[];
    value: any;
    autoFocusedField?: string;
    _isInEdit?: boolean;
    _isDirty?: boolean;
    files: BTFileSystem[] | undefined; // This files field is for holding files as they update without triggering a re-load to allow for new and existing cost groups to show all attachments on preview tab
}

export class ProposalSubCategory extends ProposalCategory {
    constructor(
        subCategoryData: any,
        builderId: number,
        jobId?: number,
        leadId?: number,
        readonlyLineItems: boolean = true
    ) {
        super(subCategoryData, builderId, jobId, leadId, readonlyLineItems);

        this.items = subCategoryData.lineItems
            ? subCategoryData.lineItems.map((lineItem: any) => {
                  const subLineItem =
                      lineItem.lineItemType === LineItemType.ProposalLineItem
                          ? new LeadProposalLineItem(lineItem)
                          : new WorksheetLineItem({ ...lineItem, parentId: this.id });
                  if (readonlyLineItems) {
                      subLineItem.isEditable = false;
                  }
                  return subLineItem;
              })
            : [];

        this.parentId = subCategoryData.parentId;

        // this shouldn't be relied upon going forward, we dynamically choose the name for attached files when needed
        this.name = "Sub Category";
    }

    parentId: number;
    items: WorksheetLineItem[];
}

export function getDefaultProposalCategoryAttachedFiles(jobId: number) {
    return new AttachedFiles({
        title: "Attachments",
        viewingPermissions: {
            allAllowedExtensions: [],
            allowFullSizeImages: false,
            allowedDocumentExtensions: [],
            allowedPhotoExtensions: [],
            allowedVideoExtensions: [],
            blacklistedExtensions: [],
            browseExistingDocs: {
                canBrowsePhotos: false,
                canBrowseDocuments: false,
                canBrowseVideos: false,
            },
            canAddFiles: false,
            canUploadDocuments: false,
            canUploadPhotos: false,
            canUploadVideos: false,
            documentListType: 1,
            ignoreBlacklist: false,
            isFileReplaceMode: false,
            jobsiteId: jobId,
            leadId: null,
            maxFileSize: 0,
            maxFiles: 0,
            minFileSize: 0,
            videoDurationLimit: null,
            vidoeMaxSizeMB: 0,
        },
        newFileOptions: {
            canEdit: false,
            defaultValue: false,
            fileOptionKey: 0,
            loginType: 0,
            options: [
                {
                    title: "Show",
                    loginType: 0,
                    fileOptionKey: 0,
                    defaultValue: false,
                    canEdit: false,
                    visible: false,
                    options: [
                        {
                            title: "Builder",
                            loginType: 1,
                            fileOptionKey: 5,
                            defaultValue: false,
                            canEdit: false,
                            visible: false,
                            options: null,
                        },
                        {
                            title: "Owner",
                            loginType: 2,
                            fileOptionKey: 3,
                            defaultValue: true,
                            canEdit: false,
                            visible: false,
                            options: null,
                        },
                        {
                            title: "Subs",
                            loginType: 3,
                            fileOptionKey: 1,
                            defaultValue: false,
                            canEdit: false,
                            visible: false,
                            options: null,
                        },
                    ],
                },
                {
                    title: "Notify",
                    loginType: 0,
                    fileOptionKey: 0,
                    defaultValue: false,
                    canEdit: false,
                    visible: false,
                    options: [
                        {
                            title: "Builder",
                            loginType: 1,
                            fileOptionKey: 6,
                            defaultValue: false,
                            canEdit: false,
                            visible: false,
                            options: null,
                        },
                        {
                            title: "Owner",
                            loginType: 2,
                            fileOptionKey: 4,
                            defaultValue: false,
                            canEdit: false,
                            visible: false,
                            options: null,
                        },
                        {
                            title: "Subs",
                            loginType: 3,
                            fileOptionKey: 2,
                            defaultValue: false,
                            canEdit: false,
                            visible: false,
                            options: null,
                        },
                    ],
                },
            ],
            title: null,
            visible: false,
        },
        files: [],
    });
}

export class LeadProposalLineItem implements IEstimateLineItem, ILineItemRow {
    constructor(data: any, takeoffProjectLink?: any, takeoffProjectName?: any) {
        this.id = data.id;
        this.lineItemType = LineItemType.ProposalLineItem;
        this.builderId = data.builderId;
        this.leadId = data.leadId;
        this.proposalId = data.proposalId;
        // from the service, we pass down costCode, but client side we use costCodeId
        this.costCodeId = data.costCodeId ?? data.costCode ?? null;
        this.costCategoryId = data.costCategoryId;
        this.costItemId = data.costItemId;
        this.itemTitle = data.title ?? data.itemTitle;
        this.costTypes = data.costTypes ?? null;
        this.markedAs = data.markedAs;
        this.unitCost = data.unitCost;
        this.quantity = data.quantity;
        this.unit = data.unit;
        this.builderCost = data.builderCost;
        this.markupType = data.markupType;
        this.markupPercent = data.markupPercent;
        this.markupPerUnit = data.markupPerUnit;
        this.markupAmount = data.markupAmount;
        this.ownerPrice = data.ownerPrice;
        this.margin = calculateMarginPercentageFromMarkup(
            data.markupPercent ?? 0,
            data.markupAmount ?? 0,
            data.markupPerUnit ?? 0,
            data.unitCost ?? 0,
            data.quantity ?? 0
        );
        this.description = data.description;
        this.internalNotes = data.internalNotes;
        this.displayOrder = data.displayOrder;
        this.dateAddedForSorting = data.dateAdded ? moment(data.dateAdded) : null;
        this.worksheetDisplayOrder = data.worksheetDisplayOrder ?? null;
        this._isInEdit = data._isInEdit ?? false;
        this.isSelected = data.isSelected ?? false;
        this.isEditable = data.isEditable ?? true;
        this.isFirstEdit = data.isFirstEdit ?? false;
        this.formatId = data.formatId;
        this.costCategoryFormatId = data.costCategoryFormatId;
        this.parentId = data.parentId;
        this.includeItemInCatalog = false;
        this.isQuantityLinked = data.isQuantityLinked;
        this.isUnitTypeLinked = data.isUnitTypeLinked;
        this.isMarkupLinked = data.isMarkupLinked;
        this.autoFocusedField = data.autoFocusedField;
        this.assemblyId = data.assemblyId;
        this.takeoffLineItemId = data.takeoffLineItemId;
        this.takeoffProjectLink = takeoffProjectLink;
        this.takeoffProjectName = takeoffProjectName;
        this.vendorProductId = data.vendorProductId;
        this.vendorType = data.vendorType as VendorTypes;
        this.isDiscontinued = data.isDiscontinued;
        this.productLink = data.productLink;
        this.taxGroupId = data.taxGroupId ?? NoTaxId;
        this.totalWithTax = data.totalWithTax;
    }
    id: number;
    lineItemType: LineItemType;
    builderId: number;
    leadId: number;
    proposalId: number;
    costCodeId: number | null;
    costCategoryId: number;
    costItemId: number | null;
    itemTitle: string | null;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    unitCost: number;
    quantity: number;
    unit: string;
    builderCost: number;
    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
    margin: number | undefined;
    description: string;
    internalNotes: string | null;
    displayOrder: number;
    dateAddedForSorting?: moment.Moment | null;
    worksheetDisplayOrder: number | null;

    // vendor product
    vendorProductId?: number;
    vendorType?: VendorTypes;
    isDiscontinued?: boolean;
    productLink?: IExternalProductLink;

    _isInEdit: boolean;
    isEditable: boolean;
    isExpanded: boolean;
    isSelected: boolean;
    isFirstEdit: boolean;

    formatId: number;
    costCategoryFormatId: number;
    parentId?: number;
    includeItemInCatalog: boolean;

    isQuantityLinked: boolean;
    isUnitTypeLinked: boolean;
    isMarkupLinked: boolean;
    autoFocusedField?: string;

    assemblyId: number | null;
    takeoffLineItemId?: number;
    takeoffProjectLink?: string;
    takeoffProjectName?: string;

    _isDirty?: boolean;
    taxGroupId?: number;
    totalWithTax: number;
}

export class WorksheetLineItem implements IEstimateLineItem, ILineItemRow {
    constructor(data: any, takeoffProjectLink?: any, takeoffProjectName?: any) {
        this.id = data.id;
        this.builderId = data.builderId;
        this.jobId = data.jobId;
        this.lineItemType = data.lineItemType;
        this.costCodeId = data.costCodeId;
        this.costCodeTitle = data.costCodeTitle;
        this.costCategoryId = data.costCategoryId;
        this.costItemId = data.costItemId;
        this.itemTitle = data.itemTitle ?? data.title;
        this.relatedItems = data.relatedItems;
        this.costTypes = data.costTypes;
        this.markedAs = data.markedAs;
        this.unitCost = data.unitCost;
        this.unit = data.unit;
        this.quantity = data.quantity;
        this.builderCost = data.builderCost;
        this.markupType = data.markupType;
        this.markupPercent = data.markupPercent;
        this.markupPerUnit = data.markupPerUnit;
        this.markupAmount = data.markupAmount;
        this.ownerPrice = data.ownerPrice;
        this.margin = calculateMarginPercentageFromMarkup(
            data.markupPercent ?? 0,
            data.markupAmount ?? 0,
            data.markupPerUnit ?? 0,
            data.unitCost ?? 0,
            data.quantity ?? 0
        );
        this.amountInvoiced = data.amountInvoiced;
        this.description = data.description;
        this.internalNotes = data.internalNotes;
        this.includeItemInCatalog = data.includeItemInCatalog ?? false;
        this.hasRelatedPurchaseOrder = data.hasRelatedPurchaseOrder;
        this.relatedGeneralItemId = data.relatedGeneralItemId;
        this.relatedPurchaseOrderLineItemId = data.relatedPurchaseOrderLineItemId;
        this._isInEdit = data._isInEdit ?? false;
        this.isSelected = data.isSelected ?? false;
        this.isEditable =
            data.isEditable ??
            [
                LineItemType.Bid,
                LineItemType.EstimateLineItem,
                LineItemType.ProposalLineItem,
            ].includes(data.lineItemType);
        this.dateAddedForSorting = data.dateAdded ? moment(data.dateAdded) : null;

        if (data.parentId) {
            this.parentId = data.parentId;
        }

        if (data.formatData) {
            this.formatId = data.formatData.formatId;
            this.costCategoryFormatId = data.formatData.costCategoryFormatId;
            this.displayOrder = data.formatData.displayOrder;
        } else {
            // if the formatData is blank, we might be able to get the direct values
            this.formatId = data.formatId;
            this.costCategoryFormatId = data.costCategoryFormatId;
            this.displayOrder = data.displayOrder;
        }
        this.importedFromProposalFormatItemId = data.proposalFormatItemId;

        this.vendorProductId = data.productId ?? undefined;
        this.vendorType = (data.vendorType as VendorTypes) ?? undefined;
        this.isDiscontinued = data.discontinued ?? undefined;
        this.productLink = data.productLink;

        this.assemblyId = data.assemblyId ?? null;
        this.takeoffLineItemId = data.takeoffLineItemId;
        this.takeoffProjectLink = takeoffProjectLink;
        this.takeoffProjectName = takeoffProjectName;

        this.taxGroupId = data.taxGroupId ?? NoTaxId;
        this.totalWithTax = data.totalWithTax;
    }

    id: number;
    builderId: number;
    jobId: number;
    lineItemType: LineItemType;
    costCodeId: number | null;
    costCodeTitle?: string;
    costCategoryId: number | null;
    costItemId: number | null;
    itemTitle: string | null;
    relatedItems: IRelatedItemResponse[] | null;
    hasRelatedPurchaseOrder: boolean | null;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    unitCost: number;
    unit: string;
    quantity: number;
    builderCost: number;
    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
    margin: number | undefined;
    amountInvoiced: number | null;
    description: string;
    internalNotes: string | null;
    includeItemInCatalog: boolean;
    relatedGeneralItemId: number | null;
    relatedPurchaseOrderLineItemId: number | null;
    parentId?: number;
    dateAddedForSorting?: moment.Moment | null;
    taxGroupId?: number;
    totalWithTax: number;

    // vendor product
    vendorProductId?: number;
    vendorType?: VendorTypes;
    isDiscontinued?: boolean;
    productLink?: IExternalProductLink;

    // IGridLineItemViewRow
    _isInEdit?: boolean;
    isSelected?: boolean;
    isExpanded?: boolean;
    isEditable: boolean;
    autoFocusedField?: string;

    // format data
    formatId: number | null;
    costCategoryFormatId: number | null;
    displayOrder: number | null;
    importedFromProposalFormatItemId?: number;

    assemblyId: number | null;
    takeoffLineItemId?: number;
    takeoffProjectLink?: string;
    takeoffProjectName?: string;

    _isDirty?: boolean;
    _clonedFromId?: number;
}

interface ITakeoffPartner {
    id: number;
    name: string;
}

export class TakeoffPartner {
    constructor(data: ITakeoffPartner) {
        this.id = data.id;
        this.name = data.name;
    }

    id: number;
    name: string;
}

export class DisplayPreferences<ColumnType> {
    constructor(data: any) {
        this.estimateGroupType = data.estimateGroupType;
        this.estimateFormatGroupType = data.estimateFormatGroupType ?? ProposalGroupTypes.Assembly;
        this.columnDisplayPreferences = data.columnDisplayPreferences;
        this.columnPreferences = data.columnPreferences;
        this.consolidationStatus = data.consolidationStatus;
    }
    /**
     * The estimate "view"
     */
    estimateGroupType: ProposalGroupTypes;
    /**
     * If on Proposal Worksheet View, the estimate "grouping"
     */
    estimateFormatGroupType: ProposalGroupTypes;
    columnDisplayPreferences: ColumnType[];
    columnPreferences: IColumnModel[];
    consolidationStatus?: boolean;
}

export interface IFormatItemPathIndices {
    id: number;
    rootCategoryIndex: number;
    subCategoryIndex?: number;
    lineItemIndex?: number;
}

export type FormatItem = WorksheetLineItem | ProposalCategory;

export type FormatItemList =
    | WorksheetLineItem[] // None grouping format
    | ProposalCategory[] // Nested Data format
    | (ProposalCategory | WorksheetLineItem)[]; // Old flat mapped pattern

export type WorksheetValue = string | number | FormatItem | ILineItemRow | ProposalCategory[];

export const isFormatItemListGrouped = (
    formatItems: FormatItemList
): formatItems is ProposalCategory[] => {
    return formatItems.length > 0 && isProposalCategory(formatItems[0]);
};

export const isProposalCategory = (formatItem: FormatItem): formatItem is ProposalCategory => {
    return (formatItem as ProposalCategory).items !== undefined;
};

export const UnassignedCostGroupId = -1;

export const isUnassignedCategory = (formatItem: FormatItem) => {
    return formatItem.id === UnassignedCostGroupId;
};

export interface IColumnModel {
    id: string;
    width: number;
}

export type EstimateGridData = (IEstimateLineItem | ILineItemGroup<IEstimateLineItem>)[];

export interface IWorksheetPresentationalProps extends RouteComponentProps {
    estimateData: EstimateResponse;
    actionBeingPerformed: EstimateActions;
    lineItemToJumpTo?: IJumpToQuickFindItem;
    filterDrawerProps?: IFilterDrawerProps;
    externalImportProps: IFinancialImportDropdownProps;
    onLineItemSave: (
        lineItem: WorksheetLineItem,
        taxMethod?: number,
        taxGroupId?: number
    ) => Promise<void>;
    onTaxMethodChange: (
        lineItem: number,
        taxMethod: number,
        taxGroupId: number | null
    ) => Promise<void>;
    onDeleteLineItems: (lineItemIds: number[], partialDelete?: boolean) => Promise<void>;
    onOpenNewEntity: (
        lineItems: WorksheetLineItem[],
        includesAlreadyLinkedItems: boolean,
        entityType: GridLineItemEntityType
    ) => void;
    onUpdateDisplayPreferences: (
        displayPreferences: EstimateDisplayPreferences,
        showSuccessOrErrorMessage: boolean
    ) => Promise<void>;
    onApplyPercentMarkup: (
        lineItems: BulkMarkupLineItem[],
        percentMarkup: number,
        percentType: AdjustPercentTypes,
        partialUpdate?: boolean
    ) => Promise<void>;
    onApplyDefaultMarkup?: (values: AdjustPercentMarkupFormValues) => Promise<void>;
    onCreateInvoiceClick: (lineItemIdsAndTypes: ILineItemIdsAndTypes[]) => void;
    onAddToExistingInvoiceClick: (lineItemIds: number[]) => void;
    onInvoiceFromLineItemActionClick: (lineItem: WorksheetLineItem, isCreate: boolean) => void;
    onCreatePurchaseOrderClick: (lineItemIdsAndTypes: IPoLineItemIdTypeMapping[]) => void;
    onAddToExistingPurchaseOrderClick: (lineItemIdsAndTypes: IPoLineItemIdTypeMapping[]) => void;
    onAddFromCatalogClick: () => void;
    onAddFromCostCodesClick: () => void;
    onSendVendorProductsToCart: (
        vendorType: VendorTypes,
        actionBeingPerformed: EstimateActions,
        lineItems: WorksheetLineItem[]
    ) => void;
    builderInfo?: BuilderInfo | null;
    onModifyAttachments: () => Promise<void>;
    onDeleteFormatGroups: (
        formatGroupIds: number[],
        subgroupParentId: number | undefined
    ) => Promise<void>;
    getAssemblyDefault: () => Promise<AssemblyEntity>;
    renderSharedTabContent: (
        leftSideActions?: React.ReactNode,
        checkedActions?: React.ReactNode
    ) => React.ReactNode;
    onReplaceLineItemClick: (lineItemToReplace: WorksheetLineItem) => void;
    onBulkUpdateTaxableStatus: (lineItems: BulkMarkupLineItem[], taxGroupId: number) => void;
    onCostItemAddedFromSearch?: (costItem: WorksheetLineItem) => Promise<void>;
    onReformatOrSort: (request: IRecategorizeProposalFormatRequest) => Promise<void>;
    onDuplicateLineItemClick: (lineItemToDuplicate: WorksheetLineItem) => void;
    onAddNewGroupToProposal: (category: ProposalCategory) => Promise<ProposalCategory[]>;
    onUpdateProposalFormatItems: (
        categories: ProposalCategory[],
        lineItems: WorksheetLineItem[]
    ) => Promise<ProposalCategory[]>;
    onMoveItems: (categories: ProposalCategory[]) => Promise<void>;
    onLineItemEditFinished?: (
        lineItem: ILineItemRow,
        lineItemPath: string
    ) => Promise<(ILineItemRow | ILineItemGroup)[]>;
    isRevisedCosts?: boolean;
    formatDataWithoutFiltering: ProposalCategory[];
    onTabChange: (activeTab: TabKeys) => Promise<void>;
    onCompleteLeadProposalImport: () => Promise<void>;
    decidedOptionItems: Record<number, OptionMode>;
    /** Replaces Formik Props */
    setFieldValue: (field: string, value: WorksheetValue) => void;
    setValues: (values: IWorksheetPresentationalValues) => void;
    values: IWorksheetPresentationalValues;
    initialValues: IWorksheetPresentationalValues;
    isWebview?: boolean;
}

export const WorksheetValidationSchema = (props: Partial<IWorksheetPresentationalProps>) => {
    const { estimateData, isRevisedCosts } = props;
    const viewType = isRevisedCosts
        ? estimateData!.revisedCostsDisplayPreferences.estimateFormatGroupType
        : estimateData!.worksheetDisplayPreferences.estimateGroupType;

    const groupEntityName = getProposalGroupingEntityName(
        viewType,
        estimateData!.projectConnectedToTakeoffs,
        false
    );
    const subgroupEntityName = getProposalGroupingEntityName(
        viewType,
        estimateData!.projectConnectedToTakeoffs,
        false,
        {
            isSubEntity: true,
        }
    );

    return yup.object<IWorksheetPresentationalValues>({
        taxMethod: yup.number(),
        taxGroupId: yup.number().nullable(),
        columnsToDisplay: yup.array<number>().label("What to Display to Owner"),
        formatItems: yup
            .array<FormatItem>()
            .of(ProposalFormatItemValidationSchema(groupEntityName, subgroupEntityName))
            .nullable(),
        deletedCategories: yup.array<number>(),
        newCategoryId: yup.number(),
    });
};
