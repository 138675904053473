import { EmptyResponseEntity } from "types/emptyResponseEntity";
import { DocumentInstanceType, TempFileTypes } from "types/enum";

import { APIHandler } from "utilities/apiHandler";

import { BTLoginTypes } from "handlers";

import {
    DocumentCreateResponse,
    DocumentEntity,
    DocumentUpdateResponse,
    FilesFromExternalUrlsResponse,
    IDocumentCreateRequest,
    IDocumentGetDefaultsRequest,
    IDocumentUpdateRequest,
    IFilesFromExternalUrlsRequest,
    MetadataProductResponse,
    NotifyOnDocumentLinkPermissionChangeRequest,
    UpdateDocumentViewPermissionsRequest,
} from "entity/document/Document/Document.api.types";

export interface IDocumentHandler {
    defaults(request: IDocumentGetDefaultsRequest): Promise<DocumentEntity>;

    get(documentInstanceId: number): Promise<DocumentEntity>;

    update(
        documentInstanceId: number,
        request: IDocumentUpdateRequest
    ): Promise<DocumentUpdateResponse>;

    create(request: IDocumentCreateRequest): Promise<DocumentCreateResponse>;

    tempFilesFromExternalUrl(
        imageUrls: string[],
        jobId: number,
        tempFileType: TempFileTypes
    ): Promise<FilesFromExternalUrlsResponse>;

    tempFilesFromExistingDocInstanceIds(
        ids: number[],
        jobId: number,
        tempFileType: TempFileTypes
    ): Promise<FilesFromExternalUrlsResponse>;

    getMetadataForProducts(productUrls: string[]): Promise<MetadataProductResponse>;

    delete(
        documentType: DocumentInstanceType,
        documentInstanceIds: number[],
        builderId: number,
        entityId: number,
        jobId?: number
    ): Promise<void>;

    toggleDocViewPermissions(
        documentInstanceId: number,
        loginType: BTLoginTypes,
        canView: boolean
    ): Promise<EmptyResponseEntity>;

    notify(
        docInstanceId: number,
        loginType: BTLoginTypes,
        documentType: DocumentInstanceType
    ): Promise<EmptyResponseEntity>;
}

export class DocumentHandler implements IDocumentHandler {
    async create(request: IDocumentCreateRequest): Promise<DocumentCreateResponse> {
        return await APIHandler(`/api/Documents/FromTemplate`, {
            method: "POST",
            data: request,
            responseType: DocumentCreateResponse,
        });
    }

    async defaults(request: IDocumentGetDefaultsRequest): Promise<DocumentEntity> {
        return await APIHandler(`/api/Documents/DefaultProperties`, {
            method: "GET",
            data: request,
            responseType: DocumentEntity,
        });
    }

    async get(documentInstanceId: number): Promise<DocumentEntity> {
        return await APIHandler(`/api/Documents/${documentInstanceId}/DocumentProperties`, {
            method: "GET",
            responseType: DocumentEntity,
        });
    }

    async update(
        documentInstanceId: number,
        request: IDocumentUpdateRequest
    ): Promise<DocumentUpdateResponse> {
        return await APIHandler(`/api/Documents/${documentInstanceId}/UpdateDocumentProperties`, {
            method: "PUT",
            data: request,
            responseType: DocumentUpdateResponse,
        });
    }

    async tempFilesFromExternalUrl(
        imageUrls: string[],
        jobId: number,
        tempFileType: TempFileTypes
    ): Promise<FilesFromExternalUrlsResponse> {
        const request: IFilesFromExternalUrlsRequest = {
            externalUrls: imageUrls,
            jobId,
            tempFileType,
        };

        return await APIHandler("/api/Documents/TempFilesFromExternalUrl", {
            method: "POST",
            data: request,
            responseType: FilesFromExternalUrlsResponse,
        });
    }

    async tempFilesFromExistingDocInstanceIds(
        docInstanceIds: number[],
        jobId: number,
        tempFileType: TempFileTypes
    ): Promise<FilesFromExternalUrlsResponse> {
        return await APIHandler("/api/Documents/TempFilesFromExistingDocs", {
            method: "POST",
            data: {
                docInstanceIds,
                jobId,
                tempFileType,
            },
            responseType: FilesFromExternalUrlsResponse,
        });
    }

    async getMetadataForProducts(productUrls: string[]): Promise<MetadataProductResponse> {
        return await APIHandler("/api/Documents/GetMetadataForProducts", {
            method: "POST",
            data: { productUrlList: productUrls },
            responseType: MetadataProductResponse,
        });
    }

    async delete(
        documentType: DocumentInstanceType,
        documentInstanceIds: number[],
        builderId: number,
        entityId: number,
        jobId?: number
    ): Promise<void> {
        await APIHandler("/api/Documents/", {
            method: "DELETE",
            data: {
                attachedFiles: {
                    removeDocs: documentInstanceIds,
                },
                builderId,
                jobId,
                entityId,
                documentType,
            },
            responseType: EmptyResponseEntity,
        });
    }

    async toggleDocViewPermissions(
        documentInstanceId: number,
        loginType: BTLoginTypes,
        canView: boolean
    ): Promise<EmptyResponseEntity> {
        const request: UpdateDocumentViewPermissionsRequest = {
            documentInstanceId,
            type: loginType,
            canView,
        };
        return await APIHandler(`/api/Documents/ToggleDocumentViewPermissions`, {
            method: "POST",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }

    async notify(
        documentInstanceId: number,
        loginType: BTLoginTypes,
        documentType: DocumentInstanceType
    ): Promise<EmptyResponseEntity> {
        const request: NotifyOnDocumentLinkPermissionChangeRequest = {
            documentInstanceId,
            type: loginType,
            documentType,
        };
        return await APIHandler(`/api/Documents/NotifyOnDocumentLinkPermissionChange`, {
            method: "PUT",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }
}
