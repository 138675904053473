import { message } from "antd";
import { BTFileSystem, IHasAttachedFiles } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent, useCallback, useState } from "react";
import { IBaseSlide } from "yet-another-react-lightbox";

import { BTLocalStorage } from "types/btStorage";
import { BTLoginTypes, MediaType } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import {
    generateExternalAttachmentFromLink,
    triggerBrowserDownload,
} from "utilities/document/fileDownload.utils";
import { useActionBeingPerformed } from "utilities/form/form";

import {
    btConfirmAsync,
    btDeleteConfirmAsync,
} from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTModal, IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { ViewAllAttachmentsActions } from "commonComponents/entity/media/ViewAllAttachments/ViewAllAttachments.types";
import { ViewAllAttachmentsPresentational } from "commonComponents/entity/media/ViewAllAttachments/ViewAllAttachmentsPresentational";
import { SharingSettingsAPI } from "commonComponents/entity/sharing/SharingContainer/SharingContainer.types";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import { DocumentHandler, IDocumentHandler } from "entity/document/Document/Document.api.handler";
import {
    BulkDownloadHandler,
    IBulkDownloadHandler,
} from "entity/media/common/BulkDownload.api.handler";
import {
    AnnotationType,
    IDownloadZipOfAttachedFilesForEntityRequest,
    SelectedMediaListViews,
} from "entity/media/common/mediaTypes";
import { IVideoListHandler, VideoListHandler } from "entity/video/VideoList/VideoList.api.handler";

interface IViewAllAttachmentsProps {
    entity: IHasAttachedFiles;
    jobId?: number;
    files: BTFileSystem[];
    modalConfig?: IModalConfiguration;
    bulkDownloadHandler?: IBulkDownloadHandler;
    videoListHandler?: IVideoListHandler;
    documentHandler?: IDocumentHandler;
    sharingSettings?: SharingSettingsAPI;
    isReadonly?: boolean;
    isNewEntity?: boolean;
    onAddClicked?: () => void;
    onViewPermissionsUpdate?: (
        id: number,
        key: "showOwner" | "showSubs",
        shouldShow: boolean
    ) => void;
    onFileAnnotate?: (
        docInstanceId: number,
        annotationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onFileDraw?: (
        docInstanceId: number,
        annotationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onEditOnline?: (docInstanceId: number, mediaType: number) => Promise<void>;
    onFileDelete?: (fileList: BTFileSystem[]) => void;
}

const defaultBulkDownloadHandler = new BulkDownloadHandler();
const defaultVideoListHandler = new VideoListHandler();
const defaultDocumentHandler = new DocumentHandler();

export const ViewAllAttachmentsInternal: FunctionComponent<IViewAllAttachmentsProps> = ({
    entity,
    jobId,
    files,
    modalConfig,
    bulkDownloadHandler = defaultBulkDownloadHandler,
    videoListHandler = defaultVideoListHandler,
    documentHandler = defaultDocumentHandler,
    sharingSettings,
    isReadonly = true,
    isNewEntity,
    onAddClicked,
    onViewPermissionsUpdate,
    onFileAnnotate,
    onFileDraw,
    onEditOnline,
    onFileDelete,
}) => {
    const [actionBeingPerformed, setActionBeingPerformed] =
        useActionBeingPerformed<ViewAllAttachmentsActions>();
    const [lightboxSelectedId, setLightboxSelectedId] = useState<number | null>(null);
    const [view, setView] = useState<SelectedMediaListViews>(
        BTLocalStorage.get("bt-string-selectedViewAllAttachmentsListView")
    );

    const handleChangeView = (value: SelectedMediaListViews) => {
        setView(value);
        BTLocalStorage.set("bt-string-selectedViewAllAttachmentsListView", value);
    };

    const handleSlideChange = (_: number, slide: IBaseSlide) => {
        if (slide.metadata?.mediaId !== undefined) {
            setLightboxSelectedId(slide.metadata.mediaId);
        }
    };
    const handleCloseLightbox = useCallback(() => setLightboxSelectedId(null), []);

    const handleBulkDownload = async () => {
        // this downloads all, but would like it to be checked actions some day
        if (jobId) {
            await setActionBeingPerformed({
                actionBeingPerformed: "download",
                callback: async () => {
                    let data: IDownloadZipOfAttachedFilesForEntityRequest = {
                        documentInstanceType: entity.documentInstanceType,
                        entityId: entity.id,
                        jobId,
                    };

                    try {
                        const response =
                            await bulkDownloadHandler.downloadZipOfAttachedDocsForEntity(data);
                        triggerBrowserDownload(response.blobData, response.fileName);
                    } catch (e) {
                        showAPIErrorMessage(e);
                    }
                },
            });
        }
    };

    const downloadVideos = async (videoIds: number[], jobId: number) => {
        try {
            await setActionBeingPerformed({
                actionBeingPerformed: "download",
                callback: async () => {
                    const response = await videoListHandler.getVideoDownloadLinks(videoIds, jobId);
                    if (response.linksList?.length === 0) {
                        void message.info("This content is not yet available");
                        return;
                    }

                    response.linksList.forEach(async (linkUrl, i) => {
                        await generateExternalAttachmentFromLink(linkUrl, i);
                    });
                },
            });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    const handleFileDownload = async (file: BTFileSystem) => {
        if (file.videoId && jobId) {
            const videoIds = [file.videoId];
            await downloadVideos(videoIds, jobId);
        }
    };

    const handleViewPermissionsUpdate = async (file: BTFileSystem, loginType: BTLoginTypes) => {
        if (file.viewingPermissions === undefined) {
            return;
        }

        let isVisible = false;
        if (loginType === BTLoginTypes.CONTACT) {
            isVisible = !file.viewingPermissions.showOwner;
        } else if (loginType === BTLoginTypes.SUBS) {
            isVisible = !file.viewingPermissions.showSubs;
        } else {
            return;
        }

        const isTempFile = file.tempId !== undefined;
        const loginKey = loginType === BTLoginTypes.CONTACT ? "showOwner" : "showSubs";
        if (isTempFile) {
            onViewPermissionsUpdate?.(file.id, loginKey, isVisible);
            return;
        }

        try {
            await setActionBeingPerformed({
                actionBeingPerformed: "updateViewPermissions",
                callback: async () => {
                    await documentHandler.toggleDocViewPermissions(file.id, loginType, isVisible);
                },
            });

            onViewPermissionsUpdate?.(file.id, loginKey, isVisible);
        } catch (e) {
            showAPIErrorMessage(
                e,
                "There was an error updating permissions for this file, please try again"
            );
        }
    };

    const handleNotify = async (file: BTFileSystem, loginType: BTLoginTypes) => {
        if (loginType !== BTLoginTypes.SUBS && loginType !== BTLoginTypes.CONTACT) {
            return;
        }

        let title = "Notify Subs/Vendors";
        let content = <>Are you sure you would like to notify the assigned subs/vendors?</>;
        let testId = "NotifySubs";

        if (loginType === BTLoginTypes.CONTACT) {
            title = "Notify Owners";
            content = <>The owners can now view this file. Would you like to notify the owners?</>;
            testId = "NotifyOwners";
        }

        const confirmAsync = await btConfirmAsync({
            title: title,
            content: content,
            okText: "Notify",
            okButtonProps: { "data-testid": `confirm${testId}Button` },
            cancelText: "Cancel",
            cancelButtonProps: { "data-testid": `cancel${testId}Button` },
        });

        if (confirmAsync === "cancel") {
            return;
        }

        try {
            await setActionBeingPerformed({
                actionBeingPerformed: "notify",
                callback: async () => {
                    await documentHandler.notify(file.id, loginType, entity.documentInstanceType);
                },
            });

            await message.success("Notification Sent");
        } catch (e) {
            showAPIErrorMessage(e, "There was an error sending the notification");
        }
    };

    const handleFileDelete = async (file: BTFileSystem) => {
        const isTempFile = file.tempId !== undefined;
        let title = "Move File to Trash";
        let content = <>Are you sure you want to move '{file.fileName}' to Trash?</>;
        let okText = "Move to Trash";

        if (isTempFile) {
            title = "Delete File";
            content = <>Are you sure you want to delete '{file.fileName}'?</>;
            okText = "Delete";
        }

        const deleteConfirmAsync = await btDeleteConfirmAsync({
            title: title,
            content: content,
            okText: okText,
            okButtonProps: { "data-testid": "confirmRemoveAttachmentButton" },
            cancelText: "Cancel",
            cancelButtonProps: { "data-testid": "cancelRemoveAttachmentButton" },
        });

        if (deleteConfirmAsync === "cancel") {
            return;
        }

        const remainingFiles = files.filter((f) => f.id !== file.id);
        if (isTempFile) {
            onFileDelete?.(remainingFiles);
            await message.success("File deleted successfully");
            return;
        }

        try {
            await setActionBeingPerformed({
                actionBeingPerformed: "delete",
                callback: async () => {
                    await documentHandler.delete(
                        entity.documentInstanceType,
                        [file.id],
                        entity.builderId,
                        entity.id,
                        jobId
                    );
                },
            });

            onFileDelete?.(remainingFiles);

            await message.success("File moved to Trash successfully");
        } catch (e) {
            showAPIErrorMessage(e, "There was an error deleting this file, please try again");
        }
    };

    const component = (
        <ViewAllAttachmentsPresentational
            jobId={jobId}
            files={files}
            view={view}
            lightboxSelectedId={lightboxSelectedId}
            actionBeingPerformed={actionBeingPerformed}
            modalConfig={modalConfig}
            onViewChange={handleChangeView}
            onOpenLightbox={setLightboxSelectedId}
            onSlideChange={handleSlideChange}
            onCloseLightbox={handleCloseLightbox}
            onBulkDownload={handleBulkDownload}
            onFileDownload={handleFileDownload}
            onAddClicked={onAddClicked}
            onViewPermissionsUpdate={handleViewPermissionsUpdate}
            onNotify={handleNotify}
            onFileAnnotate={onFileAnnotate}
            onFileDraw={onFileDraw}
            onEditOnline={onEditOnline}
            onFileDelete={handleFileDelete}
            sharingSettings={sharingSettings}
            isReadonly={isReadonly}
            isNewEntity={isNewEntity}
        />
    );

    if (modalConfig) {
        return (
            <BTModal
                data-testid="btModalViewAllAttachments"
                visible
                useModalLayout
                title="Attachments"
                width="1000px"
                beforeClose={modalConfig.beforeClose}
                removeBodyPadding
            >
                {component}
            </BTModal>
        );
    }

    return component;
};

export const ViewAllAttachments = withErrorBoundary(ViewAllAttachmentsInternal)(
    "Could not view all attachments"
);
