import { useEffect, useState } from "react";

export function useStateFromProps<T>(propValue: T): [T, (value: T) => void] {
    const [value, setValue] = useState<T>(propValue);

    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    return [value, setValue];
}
