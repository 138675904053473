import classNames from "classnames";
import { Component, useContext } from "react";
import { NumericFormat } from "react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { round } from "utilities/math/math";

import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

interface ICurrencyDisplayPropsInternal {
    builderCurrency: CurrencyLocale;
    value: number;
    decimalScale: number;
    accountingMode?: boolean;
    "data-testid"?: string;
    suffix?: string;
    className?: string;
}

function CurrencyDisplayInternal(props: ICurrencyDisplayPropsInternal) {
    const {
        decimalScale,
        builderCurrency,
        accountingMode,
        "data-testid": dataTestId,
        className,
    } = props;
    let { value, suffix } = props;

    let prefix = builderCurrency.currencySymbol.trim();
    const isNegative = value < 0;

    if (isNegative && accountingMode) {
        prefix = "(" + (prefix ?? "");
        suffix = ")" + (suffix ?? "");
        value = -value;
    }

    return (
        <NumericFormat
            value={value}
            displayType="text"
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            thousandSeparator={builderCurrency.groupSeparator}
            decimalSeparator={builderCurrency.decimalSeparator}
            thousandsGroupStyle={builderCurrency.thousandsGroupStyle}
            prefix={prefix}
            suffix={suffix}
            dir="ltr"
            data-testid={dataTestId}
            className={classNames(className)}
        />
    );
}

interface ICurrencyDisplayProps {
    value: number;

    /** @default 2 */
    decimalScale?: number;

    /** negative numbers are wrapped in () */
    accountingMode?: boolean;

    /** Currency will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    "data-testid"?: string;

    /** @default undefined */
    suffix?: string;

    className?: string;

    shouldTruncate?: boolean;
}

/**
 * Displays a formatted currency as text, use whenever you want to show currency for display only
 */
export const CurrencyDisplay: React.FunctionComponent<ICurrencyDisplayProps> = ({
    forceUSCulture,
    value,
    shouldTruncate,
    decimalScale = 2,
    ...props
}) => {
    const builderInfo = useContext(BuilderInfoContext);
    const locale =
        forceUSCulture || !builderInfo
            ? CurrencyLocale.default
            : builderInfo!.locale.currencyLocale;

    const roundedValue = round(value, decimalScale);
    const displayValue = (
        <CurrencyDisplayInternal
            builderCurrency={locale}
            value={roundedValue}
            decimalScale={decimalScale}
            {...props}
        />
    );

    if (shouldTruncate) {
        return <TextTruncate>{displayValue}</TextTruncate>;
    }

    return displayValue;
};

export class CurrencyVariableScaleDisplay extends Component<ICurrencyDisplayProps> {
    static defaultProps = {
        decimalScale: 2,
    };

    render() {
        const { value, decimalScale } = this.props;
        const stringValue = value?.toString() ?? "";
        const decimalIndex = stringValue.indexOf(".");
        let scale = 0;
        if (decimalIndex !== -1) {
            scale = stringValue.length - (decimalIndex + 1);
        }

        if (scale < decimalScale!) {
            scale = decimalScale!;
        }
        return <CurrencyDisplay {...this.props} value={value} decimalScale={scale} />;
    }
}
