import {
    AttachedFiles,
    AttachedFilesRequest,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import moment from "moment";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { DocumentInstanceType } from "types/enum";

import {
    ExternalEmailLookupEntity,
    ExternalEmailLookupItem,
} from "commonComponents/entity/externalEmailLookup/ExternalEmailLookup/ExternalEmailLookup.api.types";
import { IMessageContentValues } from "commonComponents/entity/message/MessageContent";

import { default as FolderAPIResponse } from "entity/message/common/Folders.api.json";
import { default as MessageAPIResponse } from "entity/message/Message/Message.api.json";

export class MessageDetailsEntity implements IHasAttachedFiles {
    emailMessageItem: EmailMessageItem;
    isUnread: boolean;
    unreadCount: number;
    contactList: ContactList;
    contactSelectItems: BTSelectItem[];
    folder?: MessageDetailsFolder;
    folderId?: number;
    builderId: number;
    documentInstanceType: DocumentInstanceType;
    name: string;
    attachedFiles: AttachedFiles;
    id: number;
    jobId: number | undefined;
    isByCurrentUser: boolean;
    isOwnerRestricted: boolean;
    showDefaultRecipientMissingMessage: boolean;
    canAddToDos: boolean;
    outOfOfficeUsers: number[];

    constructor(data: any) {
        if (!data.emailMessageItem.builderId) {
            data.emailMessageItem.builderId = data.builderID;
        }
        this.id = data.emailMessageItem.messageId || 0;
        this.builderId = data.emailMessageItem.builderId;
        this.jobId = data.emailMessageItem.jobId;

        this.contactList = new ContactList(data.contactList);
        this.emailMessageItem = new EmailMessageItem(data.emailMessageItem);
        this.attachedFiles = this.emailMessageItem.attachedFiles;
        this.contactSelectItems = mapContactListToSelectItems(
            [
                ...this.emailMessageItem.toRecipients,
                ...this.emailMessageItem.ccRecipients,
                ...this.emailMessageItem.bccRecipients,
            ],
            this.contactList
        );

        this.isUnread = data.isUnread;
        this.unreadCount = data.unreadCount;
        this.folder = data.folder ? new MessageDetailsFolder(data.folder) : undefined;
        this.folderId = data.folderId;
        this.documentInstanceType = DocumentInstanceType.MessagesEmailAttachment;
        this.name = "Message Details";
        this.isByCurrentUser = data.isByCurrentUser;
        this.isOwnerRestricted = data.isOwnerRestricted;
        this.canAddToDos = data.canAddToDos;
        this.showDefaultRecipientMissingMessage = data.showDefaultRecipientMissingMessage;
        this.outOfOfficeUsers = data.outOfOfficeUsers;
    }
}

export class MessageDetailsExternalEntity implements IHasAttachedFiles {
    emailMessageItem: EmailMessageItem;
    builderId: number;
    builderLogo: string;
    name: string;
    attachedFiles: AttachedFiles;
    id: number;
    jobId: number | undefined;
    documentInstanceType: DocumentInstanceType;

    constructor(data: any) {
        if (!data.emailMessageItem.builderId) {
            data.emailMessageItem.builderId = data.builderID;
        }
        this.id = data.emailMessageItem.messageId || 0;
        this.jobId = data.emailMessageItem.jobId;
        this.builderId = data.emailMessageItem.builderId;
        this.emailMessageItem = new EmailMessageItem(data.emailMessageItem);
        this.attachedFiles = this.emailMessageItem.attachedFiles;
        this.documentInstanceType = DocumentInstanceType.MessagesEmailAttachment;
        this.name = "Message Details";
        this.builderLogo = data.builderLogo;
    }
}

export class DraftSaveResponse {
    constructor(data: any) {
        this.failedFields = data.failedFields;
        if (!this.failedFields) {
            this.message = { messageId: data.message.messageId };
        }
    }
    // Unfortunately, this is not of type EmailMessageItem.
    message: { messageId: number };
    failedFields?: { key: string; message: string[] }[];
}

export class EmailMessageItem implements IHasAttachedFiles {
    subjectForReply: string;
    subjectForForward: string;
    body: string;
    respondingToMessageId: number;
    attachments?: any; // Suspect this might be leftover old code
    attachedFiles: AttachedFiles;
    attachmentCount: number;
    unreadCount: number;
    includeSignature: boolean;
    replyInfo: ReplyInfo;
    includeOriginalBody: boolean;
    canMark: boolean;
    canMove: boolean;
    canDelete: boolean;
    isDeletePermanent: boolean;
    messageId: number;
    toRecipients: Recipient[];
    ccRecipients: Recipient[];
    bccRecipients: Recipient[];
    externalToRecipients: ExternalEmailLookupEntity;
    externalCcRecipients: ExternalEmailLookupEntity;
    externalBccRecipients: ExternalEmailLookupEntity;
    subject: string;
    jobsite: string;
    jobId: number;
    messageDate: moment.Moment;
    from: string;
    isFromExternal: boolean;
    isSentByUserDeleted: boolean;
    sentByEmailAddress: string;
    sentByGlobalUserId: number;
    replyType: number;
    isUnread: boolean;
    bodyPreview: string;
    name: string;
    documentInstanceType: DocumentInstanceType;
    builderId: number;
    id: number;
    bodyForToDo: string;

    constructor(data: any) {
        this.subjectForReply = data.subjectForReply;
        this.subjectForForward = data.subjectForForward;
        this.body = data.signature || data.body || "&nbsp;";
        if (data.signature && data.body) {
            // Message is a reply---place signature above original content
            this.body = data.signature + "<br/>" + data.body;
        }
        this.respondingToMessageId = data.respondingToMessageId;
        this.attachments = data.attachments;
        this.attachedFiles = new AttachedFiles(data.attachedFiles);
        this.attachmentCount = data.attachmentCount;
        this.unreadCount = data.unreadCount;
        this.includeSignature = data.includeSignature;
        this.includeOriginalBody = data.includeOriginalBody;
        this.canMark = data.canMark;
        this.canMove = data.canMove;
        this.canDelete = data.canDelete;
        this.isDeletePermanent = data.isDeletePermanent;
        this.messageId = data.messageId || 0;
        this.toRecipients = data.toRecipients
            ? data.toRecipients.map((x: any) => new Recipient(x))
            : [];
        this.ccRecipients = data.ccRecipients
            ? data.ccRecipients.map((x: any) => new Recipient(x))
            : [];
        this.bccRecipients = data.bccRecipients
            ? data.bccRecipients.map((x: any) => new Recipient(x))
            : [];
        this.externalToRecipients = getExternalRecipients(this.toRecipients);
        this.externalCcRecipients = getExternalRecipients(this.ccRecipients);
        this.externalBccRecipients = getExternalRecipients(this.bccRecipients);
        this.subject = data.subject || "";
        this.jobsite = data.jobsite;
        this.jobId = data.jobId;
        this.messageDate = moment(data.messageDate);
        this.from = data.from;
        this.isFromExternal = data.isFromExternal;
        this.isSentByUserDeleted = data.isSentByUserDeleted;
        this.sentByEmailAddress = data.sentByEmailAddress;
        this.sentByGlobalUserId = data.sentByGlobalUserId;
        this.replyType = data.replyType;
        this.replyInfo = data.replyInfo;
        this.isUnread = data.isUnread;
        this.bodyPreview = data.bodyPreview;
        this.name = "Message Details";
        this.documentInstanceType = DocumentInstanceType.MessagesEmailAttachment;
        this.id = this.messageId;
        this.builderId = data.builderId;
        this.bodyForToDo = data.bodyForToDo;
    }
}

function getExternalRecipients(input: Recipient[]) {
    return new ExternalEmailLookupEntity({
        value: input
            .filter((r) => r.isExternal)
            .map((r) => ({
                emailAddress: r.recipientName,
                friendlyName: r.friendlyName,
            })),
    });
}

export class Recipient {
    globalUserId: number;
    recipientName: string;
    friendlyName: string;
    isExternal: boolean;

    constructor(data: any) {
        this.globalUserId = data.globalUserId;
        this.recipientName = data.recipientName;
        this.friendlyName = data.friendlyName;
        this.isExternal = data.isExternal;
    }
}

export class Contact {
    name: string;
    globalUserId: number;
    emailAddress: string;
    canRemove: boolean;
    selectedField: number;

    constructor(data: any) {
        this.name = data.name;
        this.globalUserId = data.globalUserId;
        this.emailAddress = data.emailAddress;
        this.canRemove = data.canRemove;
        this.selectedField = data.selectedField;
    }
}

export class ContactList {
    builderName: string;
    builders: Contact[];
    subs: Contact[];
    owners: Contact[];
    contacts: Contact[]; // Suspect this might be leftover old code
    allContacts: BTSelectItem[];

    constructor(data: any) {
        this.builderName = data.builderName;
        this.builders = data.builders ? data.builders.map((x: any) => new Contact(x)) : [];
        this.subs = data.subs ? data.subs.map((x: any) => new Contact(x)) : [];
        this.owners = data.owners ? data.owners.map((x: any) => new Contact(x)) : [];
        this.contacts = data.contacts ? data.contacts.map((x: any) => new Contact(x)) : [];
    }
}

export class UpdateMessageResponse {
    unreadCount: number;
    destUnreadCount: number;

    constructor(data: any) {
        this.unreadCount = data.unreadCount;
        this.destUnreadCount = data.destUnreadCount;
    }
}

// Equivalent backend class is named "MessageFolderPermissions"
export class MessageDetailsFolder {
    folderId: number;
    canMark: boolean;
    canMove: boolean;
    canReply: boolean;
    canForward: boolean;
    canDelete: boolean;
    deleteIsPermanent: boolean;
    isSent: boolean;
    isDrafts: boolean;
    isReadOnly: boolean;
    isEditable: boolean;

    constructor(data: typeof MessageAPIResponse.folder) {
        this.folderId = data.folderId;
        this.canMark = data.canMark;
        this.canMove = data.canMove;
        this.canReply = data.canReply;
        this.canForward = data.canForward;
        this.canDelete = data.canDelete;
        this.deleteIsPermanent = data.deleteIsPermanent;
        this.isSent = data.isSent;
        this.isDrafts = data.isDrafts;
        this.isReadOnly = data.isReadOnly;
        this.isEditable = data.isEditable;
    }
}

export class MessageFoldersResponse {
    folders: MessageFolder[];
    isOutOfOffice: boolean;
    isSearchEnabled: boolean;
    maxFolderNameLength: number;

    constructor(data: typeof FolderAPIResponse) {
        this.folders = data.folders ? data.folders.map((x) => new MessageFolder(x)) : [];
        this.isOutOfOffice = data.isOutOfOffice;
        this.isSearchEnabled = data.isSearchEnabled;
        this.maxFolderNameLength = data.maxFolderNameLength;
    }
}

export class MessageFolder {
    constructor(data: (typeof FolderAPIResponse.folders)[0]) {
        this.folderId = data.folderId;
        this.name = data.name;
        this.unreadCount = data.unreadCount;
        this.canMark = data.canMark;
        this.canMove = data.canMove;
        this.canReply = data.canReply;
        this.canForward = data.canForward;
        this.canDelete = data.canDelete;
        this.deleteIsPermanent = data.deleteIsPermanent;
        this.isSent = data.isSent;
        this.isDrafts = data.isDrafts;
        this.isReadOnly = data.isReadOnly;
        this.isEditable = data.isEditable;
    }

    folderId: number;
    name: string;
    unreadCount: number;
    canMark: boolean;
    canMove: boolean;
    canReply: boolean;
    canForward: boolean;
    canDelete: boolean;
    deleteIsPermanent: boolean;
    isSent: boolean;
    isDrafts: boolean;
    isReadOnly: boolean;
    isEditable: boolean;
}

export type MessageDetailsFormActions =
    | undefined
    | "move"
    | "markAsUnread"
    | "delete"
    | "print"
    | "newToDo"
    | "reply"
    | "replyAll"
    | "forward"
    | "replyOrForward";

export type MessageComposeFormActions = undefined | "send" | "saveDraft" | "delete";

export interface IMessageComposeFormValues extends IMessageContentValues {
    toRecipients: number[];
    toExternals: ExternalEmailLookupItem[];
    ccRecipients: number[];
    ccExternals: ExternalEmailLookupItem[];
    bccRecipients: number[];
    bccExternals: ExternalEmailLookupItem[];
}

export class MessageComposeEntity implements IHasAttachedFiles {
    messageId: number;
    toRecipients: number[];
    toExternals: ExternalEmailLookupItem[];
    ccRecipients: number[];
    ccExternals: ExternalEmailLookupItem[];
    bccRecipients: number[];
    bccExternals: ExternalEmailLookupItem[];
    replyInfo: ReplyInfo;
    includeSignature: boolean;
    subject: string;
    jobId: number;
    body: string;
    attachedFiles: AttachedFiles;

    id: number;
    builderId: number;
    name: string;
    documentInstanceType: DocumentInstanceType;

    constructor(
        form: IMessageComposeFormValues,
        entity: MessageDetailsEntity,
        replyInfo: ReplyInfo,
        messageId: number
    ) {
        this.messageId = messageId;
        this.toRecipients = form.toRecipients;
        this.toExternals = form.toExternals.map((x: any) => new ExternalEmailLookupItem(x));
        this.ccRecipients = form.ccRecipients;
        this.ccExternals = form.ccExternals.map((x: any) => new ExternalEmailLookupItem(x));
        this.bccRecipients = form.bccRecipients;
        this.bccExternals = form.bccExternals.map((x: any) => new ExternalEmailLookupItem(x));

        this.replyInfo = replyInfo;

        // TODO is this always true?
        this.includeSignature = true;
        this.subject = form.subject;
        this.jobId = entity.emailMessageItem.jobId;
        this.body = form.body;
        this.attachedFiles = new AttachedFiles(entity.attachedFiles);

        this.id = messageId;
        this.builderId = entity.builderId;
        this.name = "Compose Message";
        this.documentInstanceType = DocumentInstanceType.MessagesEmailAttachment;
    }
}

/**
 * This is essentially the same as the MessageComposeEntity, but it has a different type for attached files
 * Use this to pass data to the api and use the entity to map the response object for react
 */
export class MessageComposeRequest {
    messageId: number;
    toRecipients: number[];
    toExternals: ExternalEmailLookupItem[];
    ccRecipients: number[];
    ccExternals: ExternalEmailLookupItem[];
    bccRecipients: number[];
    bccExternals: ExternalEmailLookupItem[];
    replyInfo: ReplyInfo;
    jobId: number;
    subject: string;
    body: string;
    includeSignature: boolean;

    attachedFiles?: AttachedFilesRequest;

    id: number;
    builderId: number;
    name: string;
    documentInstanceType: DocumentInstanceType;

    constructor(
        form: IMessageComposeFormValues,
        entity: MessageDetailsEntity,
        replyInfo: ReplyInfo,
        messageId: number
    ) {
        this.messageId = messageId;
        this.toRecipients = form.toRecipients;
        this.toExternals = form.toExternals.map((x: any) => new ExternalEmailLookupItem(x));
        this.ccRecipients = form.ccRecipients;
        this.ccExternals = form.ccExternals.map((x: any) => new ExternalEmailLookupItem(x));
        this.bccRecipients = form.bccRecipients;
        this.bccExternals = form.bccExternals.map((x: any) => new ExternalEmailLookupItem(x));

        this.replyInfo = replyInfo;

        this.includeSignature = true;
        this.subject = form.subject;
        this.jobId = entity.emailMessageItem.jobId;
        this.body = form.body;
        // Need to remove all non-HTML line breaks. CK editor does not expect any newline characters.
        this.body = this.body.replace(/(\r\n|\n|\r)/gm, "");

        this.attachedFiles = form.attachedFiles;

        this.id = messageId;
        this.builderId = entity.builderId;
        this.name = "Compose Message";
        this.documentInstanceType = DocumentInstanceType.MessagesEmailAttachment;
    }
}

export class ReplyInfo {
    includeOriginalBody: boolean;
    // TODO number as string... plz
    replyType: string;
    respondingToMessageId: number;

    constructor(data: any) {
        this.includeOriginalBody = data.includeOriginalBody;
        this.replyType = data.replyType;
        this.respondingToMessageId = data.respondingToMessageId;
    }
}

export class MessageComposeResponse {
    constructor(data: any) {
        this.failedFields = data.failedFields;
        this.isOwnerRecipientActivated = data.isOwnerRecipientActivated;
        if (this.isOwnerRecipientActivated !== null && !this.isOwnerRecipientActivated) {
            this.ownerName = data.ownerName;
        }
    }
    failedFields?: { key: string; message: string[] }[];
    isOwnerRecipientActivated?: boolean;
    ownerName?: string;
}

const mapContactListToSelectItems = (recipients: Recipient[], contactList: ContactList) => {
    const selectKeys = (id: number | string) => ({
        id,
        key: id,
        disabled: false,
        selected: false,
    });

    const contacts: BTSelectItem[] = [];

    // Owners
    if (contactList.owners.length > 0) {
        contacts.push(
            new BTSelectItem({
                title: "Owners",
                value: "Owners",
                children: [],
                ...selectKeys("Owners"),
            })
        );
        contactList.owners.forEach((owner) => {
            contacts[0].children!.push(
                new BTSelectItem({
                    title: owner.name,
                    value: owner.globalUserId,
                    ...selectKeys(owner.globalUserId),
                })
            );
        });
    }

    // Internal Users
    contacts.push(
        new BTSelectItem({
            title: "Internal Users",
            value: "Builders",
            children: [],
            ...selectKeys("Builders"),
        })
    );
    contactList.builders.forEach((builder) => {
        contacts[contacts.length - 1].children!.push(
            new BTSelectItem({
                title: builder.name,
                value: builder.globalUserId,
                ...selectKeys(builder.globalUserId),
            })
        );
    });

    // Subs
    if (contactList.subs.length > 0) {
        contacts.push(
            new BTSelectItem({
                title: "Subs/Vendors",
                value: "Subs/Vendors",
                children: [],
                ...selectKeys("Subs/Vendors"),
            })
        );
        contactList.subs.forEach((sub) => {
            contacts[contacts.length - 1].children!.push(
                new BTSelectItem({
                    title: sub.name,
                    value: sub.globalUserId,
                    ...selectKeys(sub.globalUserId),
                })
            );
        });
    }

    return contacts;
};
