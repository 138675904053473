import { FunctionComponent } from "react";

import { formatBytes } from "commonComponents/utilities/ByteDisplay/ByteDisplay.utilities";

interface IByteDisplayProps {
    className?: string;
    value: number;
}

export const ByteDisplay: FunctionComponent<IByteDisplayProps> = ({ className, value: bytes }) => {
    const { value, label } = formatBytes(bytes);
    return <span className={className}>{`${value} ${label}`}</span>;
};
