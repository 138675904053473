import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { APIHandler, convertObjectToURL } from "utilities/apiHandler";
import { IFileUploadUtilProps } from "utilities/document/fileUpload.utils";
import { isNullOrWhitespace } from "utilities/string/string";

export interface IFileUploadHandler {
    upload(props: IFileUploadUtilProps): Promise<BTFileSystem>;
}

export class FileUploadHandler implements IFileUploadHandler {
    async upload(props: IFileUploadUtilProps): Promise<BTFileSystem> {
        let urlParams = convertObjectToURL({
            jobId: props.jobId,
            uploadFullResPhoto: props.uploadConfig.shouldUploadFullResolutionPhoto,
            builderId: props.builderId,
        });
        if (!isNullOrWhitespace(urlParams)) {
            urlParams = `?${urlParams}`;
        }

        const url =
            props.isExternal && props.externalServiceInfo
                ? `/api/Documents/TempFileExternal?entityType=${props.externalServiceInfo.tempFileType}&entityId=${props.externalServiceInfo.entityId}&subId=${props.externalServiceInfo.subId}`
                : `/api/documents/${props.tempFileType}/tempFile${urlParams}`;

        const fileFormData = new FormData();
        fileFormData.append("file", props.file, props.file.name);
        fileFormData.append("name", props.file.name);

        let progress = 0;
        const total = 100;
        if (!props.uploadConfig.shouldSuppressProgressEvents) {
            props.uploadProgress(
                new ProgressEvent("progress", { lengthComputable: true, loaded: progress, total })
            );
        }

        const simulateUpload = async () => {
            if (props.uploadConfig.shouldSuppressProgressEvents) {
                return;
            }

            return await new Promise<void>((resolve) => {
                const interval = setInterval(() => {
                    progress += 10;
                    props.uploadProgress(
                        new ProgressEvent("progress", {
                            lengthComputable: true,
                            loaded: progress,
                            total,
                        })
                    );
                    if (progress === total) {
                        resolve();
                        clearInterval(interval);
                    }
                }, 200);
            });
        };
        const response = APIHandler(url, {
            method: "POST",
            isMultiPartPostData: true,
            data: fileFormData,
            responseType: BTFileSystem,
        });

        await Promise.all([response, simulateUpload()]);

        if (!props.uploadConfig.shouldSuppressProgressEvents) {
            props.uploadProgress(
                new ProgressEvent("progress", { lengthComputable: true, loaded: progress, total })
            );
        }

        return response;
    }
}
