import { Component } from "react";

import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTInputCopy } from "commonComponents/btWrappers/BTInputCopy/BTInputCopy";
import { BTLayout, BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTStep } from "commonComponents/btWrappers/BTStep/BTStep";
import { BTSteps } from "commonComponents/btWrappers/BTSteps/BTSteps";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

interface IEmailForwardProps {
    title: string;
    subTitle?: string | undefined;
    emailAddress: string;
    onClose: () => void;
}

interface IEmailForwardState {}

export default class BTEmailForwardModal extends Component<IEmailForwardProps, IEmailForwardState> {
    render() {
        const { onClose, title, subTitle, emailAddress } = this.props;

        return (
            <BTModal
                data-testid="btModalEmailForward"
                visible
                centered
                removeBodyPadding
                width="900px"
                beforeClose={onClose}
            >
                <BTLayout>
                    <BTLayoutContent>
                        <PageSection title={title}>
                            {subTitle && <p>{subTitle}</p>}
                            <BTSteps className="margin-bottom-md">
                                <BTStep title="Copy Address" status="process" />
                                <BTStep title="Paste into Email" status="process" />
                                <BTStep title="Send Email" status="process" />
                            </BTSteps>
                            <BTForm>
                                <BTFormItem>
                                    <BTInputCopy
                                        id="emailAddress"
                                        data-testid="emailAddress"
                                        value={emailAddress}
                                        onClick={(e) =>
                                            (e.target as any).setSelectionRange(
                                                0,
                                                (e.target as any).value.length
                                            )
                                        }
                                        onChange={() => {}}
                                        onBlur={() => {}}
                                    />
                                </BTFormItem>
                            </BTForm>
                        </PageSection>
                    </BTLayoutContent>
                </BTLayout>
            </BTModal>
        );
    }
}
