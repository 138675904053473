import HeaderInfoExampleApiData from "./ownerHeaderInfo.api.json";

export function getHeaderInfoFromResponse(
    data: typeof HeaderInfoExampleApiData
): IHeaderInfoResponse {
    return {
        builderId: data.builderId,
        builderName: data.builderName,
        logoPath: data.logoPath,
        phone: data.phone,
        fax: data.fax,
        formattedAddress: data.formattedAddress,
        projectManagers: data.projectManagers ?? [],
        jobRunningTotal: {
            hasJobsitePermission: data.jobRunningTotal?.hasJobsitePermission ?? false,
            hasRunningTotalPermission: data.jobRunningTotal?.hasRunningTotalPermission ?? false,
            jobRunningTotal: data.jobRunningTotal?.jobRunningTotal ?? null,
            pricePerUnitArea: data.jobRunningTotal?.pricePerUnitArea ?? null,
            areaUnit: data.jobRunningTotal?.areaUnit ?? null,
            revisedOwnerPrice: data.jobRunningTotal?.revisedOwnerPrice ?? null,
        },
        linkedJobs: {
            disabled: data.linkedJobs?.disabled ?? false,
            jobs: data.linkedJobs?.jobs ?? [],
        },
    };
}

export interface IHeaderInfoRequest {
    selectedJobIds?: number[];
}

export class IHeaderInfoResponse {
    builderId: number;
    builderName: string | null;
    logoPath: string | null;
    phone: string | null;
    fax: string | null;
    formattedAddress: string | null;
    jobRunningTotal: IJobRunningTotalResponse;
    projectManagers: IOwnerProjectManager[];
    linkedJobs: ILinkedJobsResponse;
}

export interface IJobRunningTotalResponse {
    hasJobsitePermission: boolean;
    hasRunningTotalPermission: boolean;
    jobRunningTotal: number | null;
    pricePerUnitArea: number | null;
    areaUnit: string | null;
    revisedOwnerPrice: number | null;
}

export interface IOwnerProjectManager {
    name: string;
    phone: string;
    userId: number;
}

interface ILinkedJobsResponse {
    disabled: boolean;
    jobs: ILinkedJob[];
}

interface ILinkedJob {
    id: number;
    jobName: string;
    ownerName: string;
    hasCredentials: boolean;
}
