import { BTIconTrash } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemMoveToTrashProps {
    fileId: number;
    isNewEntity?: boolean;
    onClick: () => Promise<void>;
}

const MenuItemRemoveAttachment = ({
    fileId,
    isNewEntity,
    onClick,
    ...antProps
}: IMenuItemMoveToTrashProps) => {
    return (
        <BTMenuItem
            {...antProps}
            key={`removeAttachment-menuitem-${fileId}`}
            data-testid={`removeAttachment-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconTrash /> {isNewEntity ? "Delete" : "Move to Trash"}
        </BTMenuItem>
    );
};

export default MenuItemRemoveAttachment;
