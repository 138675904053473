import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { ContextMenuOverlay } from "commonComponents/utilities/ContextMenuOverlay/ContextMenuOverlay";

const MenuNoActions = ({ ...antProps }) => {
    return (
        <ContextMenuOverlay>
            <BTMenu {...antProps}>
                <BTMenuItem disabled data-testid="noActionsAvailable" key="noActionsAvailable">
                    No Actions Available
                </BTMenuItem>
            </BTMenu>
        </ContextMenuOverlay>
    );
};

export default MenuNoActions;
