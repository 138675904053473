import { JobStatusTypes } from "types/enum";

import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";

import { JobStatus } from "entity/job/Job.api.types";

export function mapJobStatusFilterTypesToString(jobStatusFilterType: JobStatusFilterTypes) {
    let filterTypeString = "";
    switch (jobStatusFilterType) {
        case JobStatusFilterTypes.Presale:
            filterTypeString = "Presale";
            break;
        case JobStatusFilterTypes.Open:
            filterTypeString = "Open";
            break;
        case JobStatusFilterTypes.Closed:
            filterTypeString = "Closed";
            break;
        case JobStatusFilterTypes.Warranty:
            filterTypeString = "Warranty";
            break;
        case JobStatusFilterTypes.Templates:
            filterTypeString = "Standalone Template";
            break;
        case JobStatusFilterTypes.Deleted:
            filterTypeString = "Deleted";
            break;
        default:
            break;
    }
    return filterTypeString;
}

export function mapJobStatusFilterTypesToJobStatusType(jobStatusFilterType: JobStatusFilterTypes) {
    switch (jobStatusFilterType) {
        case JobStatusFilterTypes.Presale:
            return JobStatusTypes.Presale;
        case JobStatusFilterTypes.Open:
            return JobStatusTypes.Open;
        case JobStatusFilterTypes.Closed:
            return JobStatusTypes.Closed;
        case JobStatusFilterTypes.Deleted:
            return JobStatusTypes.Deleted;
        case JobStatusFilterTypes.Templates:
            return JobStatusTypes.Template;
        default:
            return undefined;
    }
}

export function mapJobStatusToJobStatusFilterTypes(jobStatusType: JobStatus) {
    switch (jobStatusType) {
        case JobStatus.Presale:
            return JobStatusFilterTypes.Presale;
        case JobStatus.Open:
            return JobStatusFilterTypes.Open;
        case JobStatus.Closed:
            return JobStatusFilterTypes.Closed;
        case JobStatus.Warranty:
            return JobStatusFilterTypes.Warranty;
    }
}

export function getAllJobStatuses(statusesToExclude: number[]): number[] {
    // Exclude Deleted and Template statuses from the list of all statuses
    statusesToExclude.push(JobStatusTypes.Template);
    statusesToExclude.push(JobStatusTypes.Deleted);

    const allStatuses: number[] = Object.values(JobStatusTypes).filter(
        (value) => typeof value === "number" && !statusesToExclude.includes(value as number)
    ) as number[];
    return allStatuses;
}
