import { Component } from "react";

import { IEmptyStateEntityBannerProps } from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import {
    EmptyStateBanner,
    EmptyStateCallToActionButton,
} from "commonComponents/entity/emptyState/EmptyStateBanner";

import { getMessageEmptyStateDescription, getMessageEmptyStateTitle } from "./MessageEmptyState";

interface IMessageEmptyStateBannerProps extends IEmptyStateEntityBannerProps {}

export class MessageEmptyStateBanner extends Component<IMessageEmptyStateBannerProps> {
    private getInitialWithDataState = () => {
        const { onCallToActionClick } = this.props;

        return (
            <>
                {getMessageEmptyStateDescription()}
                <EmptyStateCallToActionButton onCallToActionClick={onCallToActionClick}>
                    Send a Message
                </EmptyStateCallToActionButton>
            </>
        );
    };

    render() {
        const { actionBeingPerformed, onDismiss, isDismissed, hasListData } = this.props;

        if (isDismissed || !hasListData) {
            return null;
        }

        return (
            <EmptyStateBanner
                title={getMessageEmptyStateTitle()}
                actionBeingPerformed={actionBeingPerformed}
                onDismiss={onDismiss}
                isDismissable={true}
            >
                {this.getInitialWithDataState()}
            </EmptyStateBanner>
        );
    }
}
