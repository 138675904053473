import { RouteComponentProps } from "react-router";

import { parseParams } from "utilities/url/url";

import { SystemFilterType } from "handlers";

export function getSystemFilter(
    reactRouterProps: RouteComponentProps
): SystemFilterType | undefined {
    const params = parseParams<{
        filter: SystemFilterType;
    }>(reactRouterProps.location.search);
    return params.filter ?? undefined;
}
