import moment from "moment";

import { AttachedMedia } from "entity/media/common/mediaTypes";

export type MediaReplaceFormActions = "cancel" | "save" | undefined;

export interface IMediaReplaceFormValues {
    replaceAll: boolean;
}

export class MediaReplaceEntity {
    constructor(data: any) {
        this.attachedMedia = data.map((item: any) => new AttachedMedia(item));
    }

    attachedMedia: AttachedMedia[];
}

export class BreakMediaLinksResponse {
    constructor(data: any) {
        this.documentInstanceId = data.documentInstanceId;
        this.friendlyFileName = data.friendlyFileName;
        this.lastModified = data.lastModified && moment(data.lastModified);
        this.path = data.path;
        this.physicalFileName = data.physicalFileName;
        this.title = data.title;
        this.jobId = data.jobId;
    }

    documentInstanceId: number;
    friendlyFileName: string;
    lastModified: moment.Moment;
    path: string;
    physicalFileName: string;
    title: string;
    jobId?: number;
}

export interface IGetLinkedDocumentInstancesRequest {
    documentInstanceId: number;
    includeThisDoc?: boolean;
    linkedByAnnotationGroup?: boolean;
}
