import { Steps } from "antd";

const { Step } = Steps;

interface IBTStepProps {
    className?: string;
    status?: "wait" | "process" | "finish" | "error";
    title?: React.ReactNode;
    style?: React.CSSProperties;
    description?: React.ReactNode;
    disabled?: boolean;
    icon?: React.ReactNode;
    "data-testid"?: string;
}

export const BTStep: React.FunctionComponent<IBTStepProps> = ({
    className,
    status,
    title,
    style,
    description,
    disabled,
    icon,
    ...props
}) => {
    return (
        // eslint-disable-next-line react/forbid-elements
        <Step
            {...props}
            className={className}
            status={status}
            title={title}
            style={style}
            description={description}
            disabled={disabled}
            icon={icon}
        />
    );
};
