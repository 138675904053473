import { CheckCircleOutlined } from "@ant-design/icons";
import { FunctionComponent } from "react";

import { IBTIconProps } from "../BTIcon.types";
import { useAntIcon } from "../BTIcon.utilities";

interface IBTIconCheckCircleOutlined extends IBTIconProps {
    pdfFriendly?: boolean;
}

export const BTIconCheckCircleOutlined: FunctionComponent<IBTIconCheckCircleOutlined> = (
    baseProps
) => {
    const [props] = useAntIcon(baseProps);

    if (baseProps.pdfFriendly) {
        return (
            <span
                role="img"
                aria-label="check-circle"
                className="anticon anticon-check-circle completed-item-icon"
                style={{ ...props.style }}
            >
                <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="check-circle"
                    width="1em"
                    height="1em"
                    fill={props.fill ?? "currentColor"}
                    aria-hidden="true"
                >
                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" />
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                </svg>
            </span>
        );
    } else {
        return <CheckCircleOutlined {...props} type="check-circle" />;
    }
};
