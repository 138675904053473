import Icon from "@ant-design/icons";
import { FunctionComponent } from "react";

import SVG from "images/Videos.svg?react";

import { IBTIconProps } from "../BTIcon.types";
import { useBaseIcon } from "../BTIcon.utilities";

export const BTIconFileVideo: FunctionComponent<IBTIconProps> = (baseProps) => {
    const [props] = useBaseIcon(baseProps);

    return (
        // eslint-disable-next-line react/forbid-elements
        <Icon {...props} component={SVG} />
    );
};
