import { default as AnnotationsUrlJson } from "./AnnotationsUrl.api.json";

export class AnnotationsUrlResponse {
    constructor(data: typeof AnnotationsUrlJson) {
        this.annotatedDocPathUrl = data.annotatedDocPathUrl;
        this.annotationGroupId = data.annotationGroupId;
        this.hasAnnotations = data.hasAnnotations;
        this.previewAnnotatedDocPathUrl = data.previewAnnotatedDocPath;
        this.thumbnailUrl = data.thumbUrl;
    }
    annotatedDocPathUrl: string;
    annotationGroupId?: number;
    hasAnnotations: boolean;
    previewAnnotatedDocPathUrl?: string;
    thumbnailUrl: string;
}
