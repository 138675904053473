import queryString from "query-string";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";

import { IBillProps } from "entity/bill/Bill/Bill";

const SubBill = lazyLoadWithRetry(() => import("entity/bill/SubBill/SubBill"));
const Bill = lazyLoadWithRetry(() => import("./Bill"));

export const RouteBill = (
    props: Omit<IBillProps, "handler" | "id" | "jobId" | "history" | "location" | "match">
) => (
    <RouteRelative
        path={routes.bill.details}
        render={(routeProps) => {
            const urlParams = routeProps.match.params;
            return (
                <BTSuspense>
                    <ShowOnPortal
                        builder
                        render={() => {
                            const routeValues = routeProps.match.params;
                            // eslint-disable-next-line deprecate/member-expression
                            const qsvalues: any = queryString.parse(routeProps.location.search);
                            const purchaseOrderId = parseInt(routeValues.id);
                            const jobId = parseInt(routeValues.jobId);

                            const openToLienWaiver =
                                routeValues.lienWaiver && routeValues.lienWaiver === "lienWaiver";

                            const sendToAccounting =
                                qsvalues.sendToAccounting?.toLowerCase() === "true";
                            const billToOwner = qsvalues.billToOwner?.toLowerCase() === "true";
                            return (
                                <Bill
                                    {...props}
                                    {...routeProps}
                                    id={purchaseOrderId}
                                    jobId={jobId}
                                    openToLienWaiver={openToLienWaiver}
                                    sendToAccounting={sendToAccounting}
                                    billToOwner={billToOwner}
                                />
                            );
                        }}
                    />
                    <ShowOnPortal
                        subs
                        render={() => (
                            <SubBill
                                id={parseInt(urlParams.id)}
                                jobId={parseInt(urlParams.jobId)}
                                {...routeProps}
                                modalConfig={props.modalConfig}
                                openedFrom={props.openedFrom}
                            />
                        )}
                    />
                </BTSuspense>
            );
        }}
    />
);
