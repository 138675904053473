import { APIHandler, IBlobResponse } from "utilities/apiHandler";

import { IDownloadZipOfAttachedFilesForEntityRequest } from "entity/media/common/mediaTypes";

export interface IBulkDownloadHandler {
    downloadZipOfAttachedDocsForEntity(
        data: IDownloadZipOfAttachedFilesForEntityRequest
    ): Promise<IBlobResponse>;
}

export class BulkDownloadHandler implements IBulkDownloadHandler {
    async downloadZipOfAttachedDocsForEntity(
        data: IDownloadZipOfAttachedFilesForEntityRequest
    ): Promise<IBlobResponse> {
        return await APIHandler("/api/Media/DownloadZipOfAttachedDocsForEntity", {
            method: "POST",
            data,
            responseType: "blob",
        });
    }
}
