import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { MediaType } from "types/enum";

export function getFileSystemId(mediaType: MediaType, fileSystem?: BTFileSystem) {
    if (!fileSystem) {
        return undefined;
    }

    if (mediaType === MediaType.Video) {
        return fileSystem.videoId !== null ? fileSystem.videoId : undefined;
    }
    return fileSystem.id;
}
