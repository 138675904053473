import { List } from "antd";
import { Component, ReactNode } from "react";

import { isInPortal } from "utilities/portal/portal";
import { routes } from "utilities/routes";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import {
    BTIconArrowDownUp,
    BTIconCalendarOutlined,
    BTIconCheckOutlined,
    BTIconFilter,
    BTIconNewHome,
    BTIconSortAscendingOutlined,
} from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import { IJobPickerProps } from "commonComponents/utilities/JobPicker/JobPicker";
import { JobPickerFilterTypes } from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import { JobSortOptions } from "commonComponents/utilities/JobPicker/JobPicker.types";

import { FilterUnmanaged } from "entity/filters/Filter/Filter";
import { FilterEntityType, SelectedFilterItem } from "entity/filters/Filter/Filter.api.types";
import { getAppliedFilterCount } from "entity/filters/filters.utils";
import { JobDetailTab } from "entity/job/Job.api.types";

interface IJobPickerPopoverProps extends IJobPickerProps {
    showPopover: boolean;
    sortOption: JobSortOptions;
    onVisibleChange: (visible: boolean) => void;
    shouldCloseOnScroll?: boolean;
}

export class JobPickerSortPopover extends Component<IJobPickerPopoverProps> {
    render() {
        return (
            <BTPopover
                className="ActionIcon"
                content={
                    <List<{ icon: ReactNode; text: string; value: JobSortOptions }>
                        dataSource={[
                            {
                                text: "Alphabetically",
                                icon: <BTIconSortAscendingOutlined />,
                                value: JobSortOptions.Alphabetic,
                            },
                            {
                                text: "Projected Start Date",
                                icon: <BTIconCalendarOutlined />,
                                value: JobSortOptions.StartDateProjected,
                            },
                            {
                                text: "Projected End Date",
                                icon: <BTIconCalendarOutlined />,
                                value: JobSortOptions.CloseDate,
                            },
                            {
                                text: "Created Date",
                                icon: <BTIconNewHome />,
                                value: JobSortOptions.CreatedDate,
                            },
                        ]}
                        header="Sort Jobs"
                        renderItem={(item) => (
                            <List.Item
                                onClick={async () => {
                                    this.props.onVisibleChange(false);
                                    await this.props.onSortChanged(item.value);
                                }}
                            >
                                <BTRow
                                    className="SortItemRow"
                                    responsiveMode="viewport"
                                    align="middle"
                                >
                                    <BTCol span={4}>{item.icon}</BTCol>
                                    <BTCol span={16}>{item.text}</BTCol>
                                    <BTCol span={4}>
                                        {this.props.sortOption === item.value && (
                                            <BTIconCheckOutlined />
                                        )}
                                    </BTCol>
                                </BTRow>
                            </List.Item>
                        )}
                    />
                }
                placement="rightTop"
                trigger="click"
                visible={this.props.showPopover}
                onVisibleChange={this.props.onVisibleChange}
                overlayClassName="SortJobOverlay"
            >
                <BTIconArrowDownUp data-testid="jobPickerSort" />
            </BTPopover>
        );
    }
}

export const JobPickerFiltersPopover: React.FC<IJobPickerPopoverProps> = (props) => {
    const filterType = props.templatesOnly
        ? FilterEntityType.TemplateJobPicker
        : FilterEntityType.JobPicker;

    const appliedFilterCount = getAppliedFilterCount(props.selectedFilters ?? [], props.filters);

    const handleDisableFilterField = (
        filterField: SelectedFilterItem,
        filterValues: SelectedFilterItem[]
    ) => {
        if (filterField.key === JobPickerFilterTypes.LeadStatus) {
            const jobStatusFilter = filterValues.find((x) => x.key === JobPickerFilterTypes.Other);
            if (jobStatusFilter) {
                const selectedValue = jobStatusFilter.selectedValue as JobStatusFilterTypes[];
                return (
                    selectedValue.length > 0 &&
                    !selectedValue.includes(JobStatusFilterTypes.Presale)
                );
            }
        }
        return false;
    };

    const popoverContent = (
        <FilterUnmanaged
            id={0}
            entity={props.filters}
            selectedFilterId={props.selectedFilterId}
            appliedFilter={props.selectedFilters}
            jobPickerFilterOverride={props.selectedFilters}
            filterTypeEntity={filterType}
            onSubmit={async (values) => {
                props.onVisibleChange(false);
                await props.onFilterSubmit(values, "");
            }}
            onSavedFiltersUpdated={(entity) => {
                props.onSavedFiltersUpdated(entity);
            }}
            isInPopup
            showSaveButton={isInPortal({ builder: true })}
            showSavedFilters={isInPortal({ builder: true })}
            onSavedFilterVisibleChange={props.onSavedFilterVisibleChange}
            showHasUnsavedChanges={false}
            appliedFiltersFromSavedFilter
            shouldUpdateFilterValueContext={false}
            handleDisableFilterField={handleDisableFilterField}
        />
    );

    return (
        <>
            <BTPopover
                className="ActionIcon"
                content={popoverContent}
                placement="rightTop"
                trigger="click"
                shouldCloseOnScroll={props.shouldCloseOnScroll}
                overlayClassName="JobPickerFilterPopoverOverlay"
                visible={props.showPopover || props.areSavedFiltersShowing}
                onVisibleChange={(visible) =>
                    !props.areSavedFiltersShowing && props.onVisibleChange(visible)
                } // Once we bring up the confirm modal, it will hide this popover. We don't want that because then things get de-linked lower down. Prevent the popover from hiding in that case.
            >
                <div className="flex align-items-center">
                    {appliedFilterCount > 0 && (
                        <span className="JobPickerFilterCount">({appliedFilterCount})</span>
                    )}
                    <BTIconFilter data-testid="jobPickerFilter" />
                </div>
            </BTPopover>
        </>
    );
};

export function getAddJobLink(
    inTemplateMode: boolean,
    fromScratch: boolean,
    openCondensed: boolean = false
) {
    let link;

    if (inTemplateMode) {
        // job picker is in template mode
        if (fromScratch) {
            link = `/JobPickerActions${routes.template.getTemplateDetailsLink(0)}`;
        } else {
            link = `/JobPickerActions${routes.template.getTemplateFromTemplateLink()}`;
        }
    } else {
        // job picker is in job mode
        if (fromScratch) {
            link = `/JobPickerActions${routes.job.getDetailsLink(
                0, // jobId
                JobDetailTab.Job, // defaultTab
                false, // accessedFromContact
                false, // accessedFromLead
                null, // ownerInvoiceId
                openCondensed
            )}`;
        } else {
            link = `/JobPickerActions${routes.job.getJobFromTemplateLink(0, 0, "Not Sold?")}`;
        }
    }
    return link;
}
