import { FormikErrors, InjectedFormikProps, withFormik } from "formik";
import { Component } from "react";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { BTSelectItem } from "types/apiResponse/apiResponse";

import { getSelectedValues } from "utilities/form/form";
import yup from "utilities/form/yup";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTForm, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTSelectUser } from "commonComponents/btWrappers/BTSelectUser/BTSelectUser";
import {
    IJobViewingPermissionWizardFormValues,
    OpenedFromEntity,
} from "commonComponents/entity/permissionWizard/JobViewingPermissionWizard/JobViewingPermissionWizard.api.types";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { FormikValidationSummary } from "commonComponents/utilities/validationSummary/FormikValidationSummary/FormikValidationSummary";

interface IJobViewingPermissionWizardProps {
    jobId: number;
    autoAddedSubs?: BTSelectItem[];
    autoAddedInternalUsers?: BTSelectItem[];
    subs: BTSelectItem[];
    internalUsers: BTSelectItem[];
    openedFromEntity: OpenedFromEntity;
    canSelectUsers: boolean;
    onSubmit: (
        values: IJobViewingPermissionWizardFormValues,
        setErrors: (errors: FormikErrors<IJobViewingPermissionWizardFormValues>) => void
    ) => Promise<void>;
    onClose: () => void;
    modalConfig?: IModalConfiguration;
}

const noUsersErrorMessage = "There are no subs or internal users to add to the job.";

class JobViewingPermissionWizardInternal extends Component<
    InjectedFormikProps<IJobViewingPermissionWizardProps, IJobViewingPermissionWizardFormValues>
> {
    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    private renderFooterButtons = () => {
        const { handleSubmit, onClose, canSelectUsers, values } = this.props;
        const autoAddedOnly =
            values.subIds.length === 0 &&
            values.internalUserIds.length === 0 &&
            ((values.autoAddedSubIds && values.autoAddedSubIds.length > 0) ||
                (values.autoAddedInternalUserIds && values.autoAddedInternalUserIds.length > 0));
        return (
            <>
                <BTButton
                    type="primary"
                    htmlType="submit"
                    data-testid="addUsers"
                    onClick={handleSubmit}
                >
                    {autoAddedOnly ? "Continue" : "Add Users to Job"}
                </BTButton>
                {!autoAddedOnly && (
                    <BTButton data-testid="skipOrCancel" onClick={onClose}>
                        {canSelectUsers ? "Skip" : "Cancel"}
                    </BTButton>
                )}
            </>
        );
    };

    private renderHeaderMessage = () => {
        const { openedFromEntity, autoAddedSubs, subs, internalUsers } = this.props;
        const autoAddedSubsLength = autoAddedSubs?.length ?? 0;
        if (autoAddedSubsLength + subs.length + internalUsers.length === 0) {
            throw new Error(noUsersErrorMessage);
        }

        let userGroups = "";
        if (subs.length > 0 && internalUsers.length > 0) {
            userGroups = "Subs/Vendors and Internal Users";
        } else if (subs.length > 0) {
            userGroups = "Subs/Vendors";
        } else {
            userGroups = "Internal Users";
        }

        switch (openedFromEntity) {
            case OpenedFromEntity.Schedules:
                return (
                    <>
                        <p>
                            Some {userGroups} are assigned to schedule items but cannot view this
                            job.
                        </p>
                        {this.context?.flags.estimateConsolidationPermissions && (
                            <p>
                                Note that assignees may need updated permissions to be added to this
                                job.
                            </p>
                        )}
                    </>
                );
            case OpenedFromEntity.PurchaseOrders:
                return (
                    <p>
                        Some {userGroups} do not have access to this job. Add {userGroups} to
                        continue creating purchase orders.
                    </p>
                );
            case OpenedFromEntity.Bills:
                return (
                    <p>
                        Some {userGroups} do not have access to this job. Add {userGroups} to
                        continue creating bills.
                    </p>
                );
            default:
                return <p>Some {userGroups} do not have access to this job.</p>;
        }
    };

    private renderSubsAndInternalUsersList = () => {
        const { subs, internalUsers, canSelectUsers } = this.props;

        let usersList: React.ReactNode;
        let selectMessage: string;

        if (subs.length > 0 && internalUsers.length > 0) {
            usersList = (
                <>
                    {this.renderSubsList()}
                    {this.renderInternalUsersList()}
                </>
            );
            selectMessage =
                "Would you like to add these Subs/Vendors and Internal Users to the job?";
        } else if (subs.length > 0) {
            usersList = this.renderSubsList();
            selectMessage = "Would you like to add these Subs/Vendors to the job?";
        } else if (internalUsers.length > 0) {
            usersList = this.renderInternalUsersList();
            selectMessage = "Would you like to add these Internal Users to the job?";
        } else {
            throw new Error(noUsersErrorMessage);
        }

        /* If users are selectable, override the select message */
        if (canSelectUsers) {
            selectMessage = "Select any of the following to add to this job.";
        }

        return (
            <>
                <strong>{selectMessage}</strong>
                {usersList}
            </>
        );
    };

    private renderSubsList = () => {
        const { canSelectUsers, subs, values, setFieldValue, handleBlur } = this.props;

        return (
            <BTFormItemAutomatic<IJobViewingPermissionWizardFormValues> id="subIds">
                <BTSelectUser<IJobViewingPermissionWizardFormValues>
                    id="subIds"
                    data-testid="subIds"
                    value={values.subIds}
                    treeData={subs}
                    multiple
                    readOnly={!canSelectUsers}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                />
            </BTFormItemAutomatic>
        );
    };

    private renderInternalUsersList = () => {
        const { canSelectUsers, internalUsers, values, setFieldValue, handleBlur } = this.props;

        return (
            <BTFormItemAutomatic<IJobViewingPermissionWizardFormValues> id="internalUserIds">
                <BTSelectUser<IJobViewingPermissionWizardFormValues>
                    id="internalUserIds"
                    data-testid="internalUserIds"
                    value={values.internalUserIds}
                    treeData={internalUsers}
                    multiple
                    readOnly={!canSelectUsers}
                    onChange={setFieldValue}
                    onBlur={handleBlur}
                />
            </BTFormItemAutomatic>
        );
    };

    private getPageSectionTitle = () => {
        const { subs, internalUsers, autoAddedSubs, autoAddedInternalUsers } = this.props;
        let title: string = "";
        if (subs.length > 0 && internalUsers.length > 0) {
            title = "Job Access";
        } else if (subs.length > 0 || (autoAddedSubs && autoAddedSubs.length > 0)) {
            title = "Sub/Vendor Job Access";
        } else if (
            internalUsers.length > 0 ||
            (autoAddedInternalUsers && autoAddedInternalUsers.length > 0)
        ) {
            title = "Internal User Job Access";
        } else {
            throw new Error(noUsersErrorMessage);
        }
        return title;
    };

    render() {
        const { autoAddedSubs, autoAddedInternalUsers, handleSubmit, values, errors } = this.props;

        return (
            <BTModalLayout
                footerContent={this.renderFooterButtons()}
                modalConfig={this.props.modalConfig}
            >
                <BTForm onSubmit={handleSubmit}>
                    <BTLayoutContent>
                        <FormikValidationSummary
                            errors={errors}
                            values={values}
                            showAfterSubmit={this.props.submitCount}
                            scheme={JobViewingPermissionWizardValidators}
                        />
                        <PageSection title={this.getPageSectionTitle()}>
                            {(values.autoAddedSubIds?.length ?? 0) > 0 && (
                                <>
                                    <p>
                                        These Subs/Vendors have been automatically added to the job.
                                    </p>
                                    <BTSelectUser
                                        id="autoAddedSubs"
                                        data-testid="autoAddedSubs"
                                        value={values.autoAddedSubIds}
                                        treeData={autoAddedSubs}
                                        readOnly
                                        multiple
                                        onChange={() => {}}
                                        onBlur={() => {}}
                                    />
                                </>
                            )}
                            {(values.autoAddedInternalUserIds?.length ?? 0) > 0 && (
                                <>
                                    <p>
                                        These Internal Users have been automatically added to the
                                        job.
                                    </p>
                                    <BTSelectUser
                                        id="autoAddedInternalUsers"
                                        data-testid="autoAddedInternalUsers"
                                        value={values.autoAddedInternalUserIds}
                                        treeData={autoAddedInternalUsers}
                                        readOnly
                                        multiple
                                        onChange={() => {}}
                                        onBlur={() => {}}
                                    />
                                </>
                            )}
                            {(this.props.subs.length > 0 ||
                                this.props.internalUsers.length > 0) && (
                                <>
                                    {this.renderHeaderMessage()}
                                    {this.renderSubsAndInternalUsersList()}
                                </>
                            )}
                        </PageSection>
                    </BTLayoutContent>
                </BTForm>
            </BTModalLayout>
        );
    }
}

const JobViewingPermissionWizardValidators = yup
    .object()
    .shape<IJobViewingPermissionWizardFormValues>({
        jobId: yup.number().label("Job ID"),
        subIds: yup.array<number>().label("Sub/Vendors"),
        internalUserIds: yup.array<number>().label("Internal Users"),
    });

export const JobViewingPermissionWizardPresentational = withFormik<
    IJobViewingPermissionWizardProps,
    IJobViewingPermissionWizardFormValues
>({
    // use the default values we specified above
    mapPropsToValues: (props: IJobViewingPermissionWizardProps) => ({
        jobId: props.jobId,
        autoAddedSubIds: props.autoAddedSubs ? getSelectedValues(props.autoAddedSubs) : undefined,
        autoAddedInternalUserIds: props.autoAddedInternalUsers
            ? getSelectedValues(props.autoAddedInternalUsers)
            : undefined,
        subIds: getSelectedValues(props.subs),
        internalUserIds: getSelectedValues(props.internalUsers),
    }),

    // use the yup validation object we specified above
    validationSchema: () => JobViewingPermissionWizardValidators,
    validateOnChange: true,
    validateOnBlur: true,

    handleSubmit: async (
        values: IJobViewingPermissionWizardFormValues,
        { props, setErrors, setSubmitting }
    ) => {
        setSubmitting(true);
        await props.onSubmit(values, setErrors);
        setSubmitting(false);
    },
})(JobViewingPermissionWizardInternal);
