import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";
import {
    GridRequest,
    IJobParamGridRequestData,
    mapPagingDataToRequest,
    PagingData,
} from "utilities/list/list.types";

import { IFilterFormValues } from "entity/filters/Filter/Filter.api.types";
import { getNullableFilterString } from "entity/filters/filters.utils";
import { MessageFoldersResponse } from "entity/message/common/Message.api.types";
import {
    IMoveMessageRequest,
    JobForwardAddressResponse,
    MessageListEntity,
} from "entity/message/MessageList/MessageList.api.types";
import { IFolderFormValue } from "entity/message/MessageList/MessageListFolders/MessageListFolders";

export interface IMessageListHandler {
    get(
        gridRequest?: GridRequest,
        selectedJobIds?: number[],
        filterValues?: IFilterFormValues,
        pagingData?: PagingData
    ): Promise<MessageListEntity>;
    moveMessages(moveMessageRequest: IMoveMessageRequest): Promise<EmptyResponseEntity>;
    getFolders(selectedJobIds?: number[]): Promise<MessageFoldersResponse>;
    saveFolders(
        existingFolders: IFolderFormValue[],
        newFolders: IFolderFormValue[],
        removedFolderIds: number[]
    ): Promise<EmptyResponseEntity>;
    getJobForwardAddress(jobId: number): Promise<JobForwardAddressResponse>;
}

export class MessageListHandler implements IMessageListHandler {
    async get(
        gridRequest?: GridRequest,
        selectedJobIds?: number[],
        filterValues?: IFilterFormValues,
        pagingData?: PagingData
    ): Promise<MessageListEntity> {
        const reqData: IJobParamGridRequestData = {
            gridRequest: gridRequest,
            pagingData: pagingData
                ? mapPagingDataToRequest(pagingData, false)
                : mapPagingDataToRequest(new PagingData(), false),
            filters: getNullableFilterString(filterValues),
            jobIds: selectedJobIds,
        };

        return await APIHandler("/api/messages/Grid", {
            method: "POST",
            data: reqData,
            responseType: MessageListEntity,
        });
    }

    async moveMessages(moveMessageRequest: IMoveMessageRequest): Promise<EmptyResponseEntity> {
        return APIHandler("/api/Messages/Move", {
            method: "POST",
            data: moveMessageRequest,
            responseType: EmptyResponseEntity,
        });
    }

    async getFolders(selectedJobIds?: number[]): Promise<MessageFoldersResponse> {
        const reqData: any = {
            selectedJobIds,
        };

        return await APIHandler("/api/messages/folders", {
            method: "GET",
            data: reqData,
            responseType: MessageFoldersResponse,
        });
    }

    saveFolders(
        existingFolders: IFolderFormValue[],
        newFolders: IFolderFormValue[],
        removedFolderIds: number[]
    ): Promise<EmptyResponseEntity> {
        return APIHandler(`/api/Messages/UpdateFolders`, {
            method: "PUT",
            data: { existingFolders, newFolders, removedFolderIds },
            responseType: EmptyResponseEntity,
        });
    }

    getJobForwardAddress(jobId: number): Promise<JobForwardAddressResponse> {
        return APIHandler(`/api/Messages/JobsiteForwardAddress`, {
            method: "GET",
            data: { jobId },
            responseType: JobForwardAddressResponse,
        });
    }
}
