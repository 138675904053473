import { BTSelectItem } from "types/apiResponse/apiResponse";

import type { default as MediaViewingPermissionsJson } from "commonComponents/entity/media/MediaViewingPermissionsInternal/MediaViewingPermissions.api.json";

type MediaViewingPermissionsApiData = typeof MediaViewingPermissionsJson;

export interface IMediaViewingPermissionsFormValues {
    showOwner: boolean;
    showSubs: boolean;
    internalUserIds: number[];
    subIds: number[];
    ownerIds: number[];
}

export class MediaViewingPermissionsEntity {
    constructor(data: MediaViewingPermissionsApiData) {
        this.internalUsers = data.internalUsers
            ? data.internalUsers.map((user) => new BTSelectItem(user))
            : undefined;
        this.subs = data.subs ? data.subs.map((sub) => new BTSelectItem(sub)) : undefined;
        this.owners = data.owners ? data.owners.map((owner) => new BTSelectItem(owner)) : undefined;
        this.showOwner = data.showOwner;
        this.showSubs = data.showSubs;
        this.isGlobal = data.isGlobal;
        this.internalUserIDs = data.internalUserIDs ?? [];
        this.subIDs = data.subIDs ?? [];
    }

    internalUsers?: BTSelectItem[];
    subs?: BTSelectItem[];
    owners?: BTSelectItem[];
    showOwner: boolean;
    showSubs: boolean;
    isGlobal: boolean;
    internalUserIDs?: number[];
    subIDs?: number[];
}

export enum UsersAmount {
    All = 0,
    Some = 1,
    None = 2,
}
