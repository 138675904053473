import { message } from "antd";
import { Component, createRef, useEffect, useState } from "react";
import {
    FacebookIcon,
    FacebookShareButton,
    FacebookShareCount,
    PinterestIcon,
    PinterestShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";

import { ShareType } from "types/enum";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { isNullOrUndefined } from "utilities/object/object";
import { isWhitespace } from "utilities/string/string";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import {
    FileShareHandler,
    IFileShareHandler,
} from "commonComponents/btWrappers/BTSocialMediaButtons/FileShare.api.handler";
import { IGetShareTokenRequest } from "commonComponents/btWrappers/BTSocialMediaButtons/FileShare.types";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import "./SocialMediaButtons.less";

const defaultShareUrl = "https://buildertrend.net/notfound.aspx";

enum SocialMediaTypes {
    None = "",
    Twitter = "Twitter",
    Facebook = "Facebook",
    Pinterest = "Pinterest",
}

interface IShareButtonProps {
    url: string;
    initialClickType: SocialMediaTypes | null;
    getUrlForShare: (source: SocialMediaTypes) => Promise<void>;
}

interface IHasDescriptionProp {
    description?: string;
}

interface IHasImageUrlProp {
    imageUrl?: string;
}

class BTTwitterShareButton extends Component<IShareButtonProps & IHasDescriptionProp> {
    private twitterButtonRef = createRef<HTMLDivElement>();

    private handleTwitterInnerClick = async (event: React.MouseEvent<any, MouseEvent>) => {
        const { getUrlForShare } = this.props;
        if (this.props.url === defaultShareUrl) {
            event.stopPropagation();
            await getUrlForShare(SocialMediaTypes.Twitter);
        }
    };

    componentDidUpdate(prevProps: IShareButtonProps) {
        if (
            this.props.url !== prevProps.url &&
            this.props.initialClickType === SocialMediaTypes.Twitter
        ) {
            this.twitterButtonRef.current?.click();
        }
    }

    render() {
        const { url, description } = this.props;
        return (
            <TwitterShareButton url={url} title={description}>
                <div ref={this.twitterButtonRef}>
                    <div onClick={this.handleTwitterInnerClick}>
                        <TwitterIcon size={24} round />
                        <span className="text">Tweet</span>
                    </div>
                </div>
            </TwitterShareButton>
        );
    }
}

class BTFacebookShareButton extends Component<IShareButtonProps> {
    private facebookButtonRef = createRef<HTMLDivElement>();

    private handleFacebookInnerClick = async (event: React.MouseEvent<any, MouseEvent>) => {
        const { getUrlForShare } = this.props;
        if (this.props.url === defaultShareUrl) {
            event.stopPropagation();
            await getUrlForShare(SocialMediaTypes.Facebook);
        }
    };

    componentDidUpdate(prevProps: IShareButtonProps) {
        if (
            this.props.url !== prevProps.url &&
            this.props.initialClickType === SocialMediaTypes.Facebook
        ) {
            this.facebookButtonRef.current?.click();
        }
    }

    render() {
        const { url } = this.props;
        return (
            <FacebookShareButton url={url}>
                <div ref={this.facebookButtonRef}>
                    <div onClick={this.handleFacebookInnerClick}>
                        <FacebookIcon size={24} round />
                        <span className="text">Share</span>
                        <FacebookShareCount url={url} />
                    </div>
                </div>
            </FacebookShareButton>
        );
    }
}

class BTPinterestShareButton extends Component<
    IShareButtonProps & IHasDescriptionProp & IHasImageUrlProp
> {
    private pinterestButtonRef = createRef<HTMLDivElement>();

    private handlePinterestInnerClick = async (event: React.MouseEvent<any, MouseEvent>) => {
        const { getUrlForShare } = this.props;
        if (this.props.url === defaultShareUrl) {
            event.stopPropagation();
            await getUrlForShare(SocialMediaTypes.Pinterest);
        }
    };

    componentDidUpdate(prevProps: IShareButtonProps) {
        if (
            this.props.url !== prevProps.url &&
            this.props.initialClickType === SocialMediaTypes.Pinterest
        ) {
            this.pinterestButtonRef.current?.click();
        }
    }

    render() {
        const { url, description, imageUrl } = this.props;

        // Need to force pinterest to download the file when clicked instead of redirecting to bt
        const mediaUrl = (imageUrl || url).replace("ia=0", "ia=1");

        return (
            <PinterestShareButton url={url} description={description} media={mediaUrl}>
                <div ref={this.pinterestButtonRef}>
                    <div onClick={this.handlePinterestInnerClick}>
                        <PinterestIcon size={24} round />
                        <span className="text">Pin it</span>
                    </div>
                </div>
            </PinterestShareButton>
        );
    }
}

export interface ISocialMediaButtonsMediaInfo {
    documentInstanceId?: number;
    jobId?: number | null;
}

interface IBTSocialMediaButtonsProps {
    mediaInfo?: ISocialMediaButtonsMediaInfo;
    handler?: IFileShareHandler;
}
const defaultHandler = new FileShareHandler();
export const BTSocialMediaButtons: React.FunctionComponent<IBTSocialMediaButtonsProps> = ({
    handler = defaultHandler,
    mediaInfo,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [socialSharingValues, setSocialSharingValues] = useState<{
        shareUrl: string;
        shareUrlBtnSource: SocialMediaTypes | null;
    }>({
        shareUrl: defaultShareUrl,
        shareUrlBtnSource: null,
    });

    useEffect(() => {
        // We need to reset these values when the document instance id changes
        // so that each item when clicked will make the appropriate call to get it's URL
        setSocialSharingValues({
            shareUrl: defaultShareUrl,
            shareUrlBtnSource: null,
        });
    }, [mediaInfo?.documentInstanceId]);
    const title = "Check out this picture!";

    const getSharedContentUrl = async (source: SocialMediaTypes) => {
        const errorMessage = "Something went wrong, please try again.";
        if (mediaInfo?.documentInstanceId) {
            setIsLoading(true);
            try {
                const shareTokenRequest: IGetShareTokenRequest = {
                    jobId: mediaInfo.jobId || undefined,
                    shareType: ShareType.Social,
                };
                const shareTokenResponse = await handler!.GetShareToken(
                    mediaInfo.documentInstanceId,
                    shareTokenRequest
                );
                let shareUrl = "";
                // For Facebook and Twitter we can share the Shared Content Page
                // as they respect the Open Graph tags meta tags
                // otherwise just share the direct file URL
                switch (source) {
                    case SocialMediaTypes.Facebook:
                    case SocialMediaTypes.Twitter:
                        shareUrl = shareTokenResponse.shareContentUrl;
                        break;
                    default:
                        if (!isNullOrUndefined(shareTokenResponse.shareFileUrl)) {
                            shareUrl = shareTokenResponse.shareFileUrl;
                        }
                        break;
                }
                if (isWhitespace(shareUrl)) {
                    void message.error(errorMessage);
                }
                setSocialSharingValues({
                    shareUrl,
                    shareUrlBtnSource: source,
                });
            } catch (e) {
                showAPIErrorMessage(e);
            } finally {
                setIsLoading(false);
            }
        } else {
            void message.error(errorMessage);
        }
    };

    return (
        <>
            <BTRow className="SocialMediaButtons" gutter={6}>
                <BTCol>
                    <BTTwitterShareButton
                        initialClickType={socialSharingValues.shareUrlBtnSource}
                        url={socialSharingValues.shareUrl}
                        description={title}
                        getUrlForShare={getSharedContentUrl}
                    />
                </BTCol>
                <BTCol>
                    <BTFacebookShareButton
                        initialClickType={socialSharingValues.shareUrlBtnSource}
                        url={socialSharingValues.shareUrl}
                        getUrlForShare={getSharedContentUrl}
                    />
                </BTCol>
                <BTCol>
                    <BTPinterestShareButton
                        initialClickType={socialSharingValues.shareUrlBtnSource}
                        url={socialSharingValues.shareUrl}
                        description={title}
                        getUrlForShare={getSharedContentUrl}
                    />
                </BTCol>
            </BTRow>
            {isLoading && <BTLoading />}
        </>
    );
};
