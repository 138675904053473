import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent, useCallback } from "react";

import { BTLoginTypes, MediaType } from "types/enum";

import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { areContextItemsPresent } from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu.utility";
import MenuItemAnnotate from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemAnnotate";
import MenuItemDownload from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemDownload";
import MenuItemDraw from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemDraw";
import MenuItemEditOnline from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemEditOnline";
import MenuItemNotifyOwners from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemNotifyOwners";
import MenuItemNotifySubs from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemNotifySubs";
import MenuItemOpenInNewTab from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemOpenInNewTab";
import MenuItemRemoveAttachment from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemRemoveAttachment";
import MenuItemShowOwners from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemShowOwners";
import MenuItemShowSubs from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuItemShowSubs";
import MenuNoActions from "commonComponents/entity/media/common/MediaContextMenu/MenuItems/MenuNoActions";
import { ContextMenuOverlay } from "commonComponents/utilities/ContextMenuOverlay/ContextMenuOverlay";

import { AnnotationType } from "entity/media/common/mediaTypes";

export type AttachmentItems =
    | "download"
    | "openInNewTab"
    | "showOwners"
    | "notifyOwners"
    | "showSubs"
    | "notifySubs"
    | "annotate"
    | "draw"
    | "editOnline"
    | "trash";

export interface IAttachmentOverlayProps {
    file: BTFileSystem;
    items: Record<AttachmentItems, boolean>;
    isReadOnly?: boolean;
    isNewEntity?: boolean;
    onClickDownload: (file: BTFileSystem) => Promise<void>;
    onFilePermissionsUpdate?: (file: BTFileSystem, loginType: BTLoginTypes) => Promise<void>;
    onClickNotify?: (file: BTFileSystem, loginType: BTLoginTypes) => Promise<void>;
    onClickAnnotate?: (
        docInstanceId: number,
        annoationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onClickDraw?: (
        docInstanceId: number,
        annoationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onClickEditOnline?: (docInstanceId: number, mediaType: MediaType) => Promise<void>;
    onClickDelete?: (file: BTFileSystem) => Promise<void>;
}

export const AttachmentOverlay: FunctionComponent<IAttachmentOverlayProps> = ({
    file,
    items,
    isReadOnly,
    isNewEntity,
    onClickDownload,
    onFilePermissionsUpdate,
    onClickNotify,
    onClickAnnotate,
    onClickDraw,
    onClickEditOnline,
    onClickDelete,
    ...antProps
}) => {
    const handleClickDownload = useCallback(async () => {
        await onClickDownload(file);
    }, [onClickDownload, file]);

    const handleToggleOwnerVisibility = useCallback(async () => {
        if (!onFilePermissionsUpdate) {
            return;
        }
        await onFilePermissionsUpdate(file, BTLoginTypes.CONTACT);
    }, [onFilePermissionsUpdate, file]);

    const handleToggleSubsVisibility = useCallback(async () => {
        if (!onFilePermissionsUpdate) {
            return;
        }
        await onFilePermissionsUpdate(file, BTLoginTypes.SUBS);
    }, [onFilePermissionsUpdate, file]);

    const handleNotifyOwners = useCallback(async () => {
        if (!onClickNotify) {
            return;
        }
        await onClickNotify(file, BTLoginTypes.CONTACT);
    }, [onClickNotify, file]);

    const handleNotifySubs = useCallback(async () => {
        if (!onClickNotify) {
            return;
        }
        await onClickNotify(file, BTLoginTypes.SUBS);
    }, [onClickNotify, file]);

    const handleClickAnnotate = useCallback(async () => {
        if (!onClickAnnotate) {
            return;
        }
        let annotationType = AnnotationType.PdfAnnotation;
        if (file.entityId === 0) {
            annotationType = AnnotationType.TempFile;
        }
        await onClickAnnotate(file.id, annotationType, file.mediaType);
    }, [onClickAnnotate, file.entityId, file.id, file.mediaType]);

    const handleClickDraw = useCallback(async () => {
        if (!onClickDraw) {
            return;
        }
        let annotationType = AnnotationType.PhotoDraw;
        if (file.entityId === 0) {
            annotationType = AnnotationType.TempFile;
        }
        await onClickDraw(file.id, annotationType, file.mediaType);
    }, [onClickDraw, file.entityId, file.id, file.mediaType]);

    const handleClickEditOnline = useCallback(async () => {
        if (!onClickEditOnline) {
            return;
        }
        await onClickEditOnline(file.id, file.mediaType);
    }, [onClickEditOnline, file]);

    const handleClickDelete = useCallback(async () => {
        if (!onClickDelete) {
            return;
        }
        await onClickDelete(file);
    }, [onClickDelete, file]);

    const hasContextItems = areContextItemsPresent(items);
    if (!hasContextItems) {
        return <MenuNoActions {...antProps} />;
    }

    const {
        download,
        openInNewTab,
        showOwners,
        notifyOwners,
        showSubs,
        notifySubs,
        annotate,
        draw,
        editOnline,
        trash,
    } = items;
    return (
        <ContextMenuOverlay>
            <BTMenu {...antProps} data-testid={`attachment-menu-${file.id}`}>
                {openInNewTab && <MenuItemOpenInNewTab file={file} />}
                {download && <MenuItemDownload file={file} onClick={handleClickDownload} />}
                {showOwners && (
                    <MenuItemShowOwners
                        fileId={file.id}
                        isVisibleToOwners={file.viewingPermissions!.showOwner}
                        onClick={handleToggleOwnerVisibility}
                    />
                )}
                {notifyOwners && (
                    <MenuItemNotifyOwners fileId={file.id} onClick={handleNotifyOwners} />
                )}
                {showSubs && (
                    <MenuItemShowSubs
                        fileId={file.id}
                        isVisibleToSubs={file.viewingPermissions!.showSubs}
                        onClick={handleToggleSubsVisibility}
                    />
                )}
                {notifySubs && <MenuItemNotifySubs fileId={file.id} onClick={handleNotifySubs} />}
                {annotate && <MenuItemAnnotate fileId={file.id} onClick={handleClickAnnotate} />}
                {draw && <MenuItemDraw fileId={file.id} onClick={handleClickDraw} />}
                {editOnline && (
                    <MenuItemEditOnline fileId={file.id} onClick={handleClickEditOnline} />
                )}
                {trash && (
                    <MenuItemRemoveAttachment
                        fileId={file.id}
                        isNewEntity={isNewEntity}
                        onClick={handleClickDelete}
                    />
                )}
            </BTMenu>
        </ContextMenuOverlay>
    );
};
