import { APIHandler } from "utilities/apiHandler";

import { AnnotationsUrlResponse } from "commonComponents/entity/media/Annotations/Annotations.api.types";

import { AnnotationType } from "entity/media/common/mediaTypes";

export interface IAnnotationsHandler {
    getAnnotationUrl: (
        id: number,
        annotationType: AnnotationType,
        jobId?: number
    ) => Promise<AnnotationsUrlResponse>;
}

export class AnnotationsHandler implements IAnnotationsHandler {
    getAnnotationUrl(
        id: number,
        annotationType: AnnotationType,
        jobId?: number
    ): Promise<AnnotationsUrlResponse> {
        return APIHandler(`/api/annotation/${id}/GetAtalasoftFileUrl`, {
            method: "GET",
            data: {
                jobId,
                annotype: annotationType,
            },
            responseType: AnnotationsUrlResponse,
        });
    }
}
