import { Breadcrumb, Empty, Radio, RadioChangeEvent, Space } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import classNames from "classnames";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent, useCallback, useMemo } from "react";
import { IBaseSlide } from "yet-another-react-lightbox";

import { FolderTypes, MediaType } from "types/enum";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCard } from "commonComponents/btWrappers/BTCard/BTCard";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import { BTIconArrowLeftOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox";
import {
    btFileSystemToSlide,
    canViewBTFileSystemInLightbox,
} from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTRadioGroup } from "commonComponents/btWrappers/BTRadioGroup/BTRadioGroup";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTable, IBTColumnProps } from "commonComponents/btWrappers/BTTable/BTTable";
import { IFolder } from "commonComponents/entity/media/BrowseBTFiles/BrowseBTFiles.types";
import { MediaTile } from "commonComponents/entity/media/common/MediaGrid/MediaTile";
import {
    fileSizeColumn,
    lastModifiedColumn,
    thumbnailColumn,
    titleColumn,
} from "commonComponents/entity/media/common/MediaTable/MediaTableColumns";
import { FileViewToggle } from "commonComponents/entity/media/FileViewToggle/FileViewToggle";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { Sticky } from "commonComponents/utilities/StickyContext";

import { getMediaString } from "entity/media/common/media.utils";
import { SelectedMediaListViews } from "entity/media/common/mediaTypes";

import "./BrowseBTFiles.less";

export interface IBrowseBTFilesPresentationalProps {
    filesAndFolders: BTFileSystem[];
    selectedFiles: BTFileSystem[];
    folderStack: IFolder[];
    isLoading: boolean;
    isSubmitting: boolean;
    allowedMediaTypes: MediaType[];
    multiple: boolean;
    mediaType: MediaType;
    lightboxSelectedId: number | null;
    view: SelectedMediaListViews;
    modalConfig?: IModalConfiguration;

    onCancel: () => void;
    onSubmit: () => Promise<void>;
    onChangeMediaType: (e: RadioChangeEvent) => void;
    onFilesSelected: (newSelection: BTFileSystem[]) => void;
    onNavigateToParent: () => void;
    onFileClick: (file: BTFileSystem) => void;
    onOpenLightbox: (id: number) => void;
    onSlideChange: (_: number, slide: IBaseSlide) => void;
    onCloseLightbox: () => void;
    onViewChange: (value: SelectedMediaListViews) => void;
}

export const BrowseBTFilesPresentational: FunctionComponent<IBrowseBTFilesPresentationalProps> = ({
    filesAndFolders,
    selectedFiles,
    folderStack,
    isLoading,
    isSubmitting,
    allowedMediaTypes,
    multiple,
    mediaType,
    lightboxSelectedId,
    view,
    modalConfig,
    onCancel,
    onSubmit,
    onChangeMediaType,
    onFilesSelected,
    onNavigateToParent,
    onFileClick,
    onOpenLightbox,
    onSlideChange,
    onCloseLightbox,
    onViewChange,
}) => {
    const selectedFileIds = useMemo(
        () =>
            new Set(
                selectedFiles
                    .filter((file) => file.folderType === FolderTypes.NotAFolder)
                    .map((file) => file.id)
            ),
        [selectedFiles]
    );
    const files = useMemo(
        () => filesAndFolders.filter((file) => file.folderType === FolderTypes.NotAFolder),
        [filesAndFolders]
    );
    const selectedFilesInView = useMemo(
        () => files.filter((file) => selectedFileIds.has(file.id)),
        [files, selectedFileIds]
    );

    const isFilePresent = files.length > 0;
    const someFilesInViewSelected = selectedFilesInView.length > 0;
    const allFilesInViewSelected = selectedFilesInView.length === files.length;
    const fileSelectedCount = selectedFileIds.size;
    const showSelectAll = multiple && isFilePresent;

    const handleClickThumbnail = useCallback(
        (e: React.MouseEvent<HTMLImageElement, MouseEvent>, file: BTFileSystem) => {
            // stop row from being selected
            e.stopPropagation();
            // open lightbox on double click
            const canViewInLightbox = canViewBTFileSystemInLightbox(file);
            if (canViewInLightbox && e.detail === 2) {
                onOpenLightbox(file.id);
            } else {
                onFileClick(file);
            }
        },
        [onOpenLightbox, onFileClick]
    );

    const handleClickSelectAll = () => {
        if (allFilesInViewSelected) {
            onFilesSelected([]);
        } else {
            onFilesSelected(files);
        }
    };

    const fileAndFolderColumns: IBTColumnProps<BTFileSystem>[] = useMemo(
        () => [
            thumbnailColumn({ onClick: handleClickThumbnail, width: "8%" }),
            titleColumn(),
            fileSizeColumn({ width: "15%" }),
            lastModifiedColumn({ width: "25%" }),
        ],
        [handleClickThumbnail]
    );

    const rowSelection: TableRowSelection<BTFileSystem> = useMemo(() => {
        return {
            type: multiple ? "checkbox" : "radio",
            selectedRowKeys: Array.from(selectedFileIds.values()),
            onChange: (_, newSelection) => onFilesSelected(newSelection),
            getCheckboxProps: (record) => ({
                style: record.folderType !== FolderTypes.NotAFolder ? { display: "none" } : {},
                disabled: record.folderType !== FolderTypes.NotAFolder,
            }),
            hideSelectAll: !showSelectAll,
        };
    }, [selectedFileIds, multiple, showSelectAll, onFilesSelected]);

    const slides = files.filter(canViewBTFileSystemInLightbox).map(btFileSystemToSlide);
    const index = slides.findIndex((s) => s.metadata?.mediaId === lightboxSelectedId);
    const open = index >= 0;
    return (
        <div className="BrowseBTFiles">
            <BTCard
                className="margin-bottom-zero"
                title={
                    <div className="Header">
                        <div className="HeaderRow">
                            {folderStack.length > 0 && (
                                <BTButton
                                    data-testid="btn-back"
                                    icon={<BTIconArrowLeftOutlined />}
                                    type="ghost"
                                    shape="circle"
                                    onClick={onNavigateToParent}
                                    className="BackButton"
                                />
                            )}
                            <Breadcrumb className="DirectoryPath">
                                {folderStack.map((folder) => (
                                    <Breadcrumb.Item key={folder.id.toString()}>
                                        {folder.title}
                                    </Breadcrumb.Item>
                                ))}
                            </Breadcrumb>
                        </div>

                        <Space className="flex justify-content-end HeaderRow" size="small">
                            {allowedMediaTypes.length > 1 && (
                                <BTRadioGroup
                                    value={mediaType}
                                    className="flex justify-content-end"
                                    onChange={onChangeMediaType}
                                >
                                    {allowedMediaTypes.map((mediaType) => (
                                        <Radio.Button key={mediaType} value={mediaType}>
                                            {getMediaString(mediaType)}
                                        </Radio.Button>
                                    ))}
                                </BTRadioGroup>
                            )}
                            <FileViewToggle value={view} onChange={onViewChange} />
                        </Space>
                    </div>
                }
                bordered={false}
            >
                {isLoading && <BTLoading displayMode="absolute" />}
                {view === "Table" && (
                    <BTTable<BTFileSystem>
                        className="FilePickerTable"
                        rowKey={(row) => row.id}
                        rowClassName="FileRow"
                        dataSource={filesAndFolders}
                        columns={fileAndFolderColumns}
                        rowSelection={rowSelection}
                        sticky={modalConfig ? { topLevel: 200 } : undefined}
                        onRow={(record) => ({
                            onClick: () => onFileClick(record),
                        })}
                        pagination={false}
                        size="small"
                        emptyText="This folder is empty."
                    />
                )}
                {view === "Tile" && (
                    <div
                        className={classNames("FilePickerGrid", "padding-bottom-md", {
                            "padding-top-md": !showSelectAll,
                        })}
                    >
                        {filesAndFolders.length === 0 && (
                            <Empty
                                description="This folder is empty."
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        )}
                        {filesAndFolders.length > 0 && (
                            <>
                                {showSelectAll && (
                                    <BTCheckbox
                                        data-testid="checkAll"
                                        id="checkAll"
                                        className="margin-all-sm"
                                        checked={allFilesInViewSelected}
                                        indeterminate={
                                            !allFilesInViewSelected && someFilesInViewSelected
                                        }
                                        onClick={handleClickSelectAll}
                                    >
                                        Select All
                                    </BTCheckbox>
                                )}
                                <div className="flex justify-content-center">
                                    <BTRow className="GridSection" gutter={[8, 8]}>
                                        {filesAndFolders.map((file) => {
                                            const isFile =
                                                file.folderType === FolderTypes.NotAFolder;
                                            return (
                                                <BTCol key={file.id} xs={8} sm={6}>
                                                    <MediaTile
                                                        file={file}
                                                        onClickTile={() => onFileClick(file)}
                                                        onClickThumbnail={(e) =>
                                                            handleClickThumbnail(e, file)
                                                        }
                                                        selectableProps={
                                                            isFile
                                                                ? {
                                                                      isSelected:
                                                                          selectedFileIds.has(
                                                                              file.id
                                                                          ),
                                                                      canSelectMultiple: multiple,
                                                                  }
                                                                : undefined
                                                        }
                                                    />
                                                </BTCol>
                                            );
                                        })}
                                    </BTRow>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </BTCard>
            <Sticky direction="bottom" level={0}>
                <BTRow justify="end" className="Footer">
                    <Space>
                        <BTButton
                            data-testid="cancelSelectFile"
                            type="secondary"
                            onClick={onCancel}
                        >
                            Cancel
                        </BTButton>
                        <BTButton
                            data-testid="selectFile"
                            type="primary"
                            disabled={fileSelectedCount === 0}
                            onClick={onSubmit}
                            loading={isSubmitting}
                        >
                            <BTFormattedPlural
                                value={fileSelectedCount}
                                zero="Select File"
                                one="Select File"
                                other={`Select ${fileSelectedCount} Files`}
                            />
                        </BTButton>
                    </Space>
                </BTRow>
            </Sticky>
            <BTLightbox
                slides={slides}
                index={index}
                open={open}
                onClose={onCloseLightbox}
                onSlideChange={onSlideChange}
            />
        </div>
    );
};
