/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { BodyType, ErrorType } from "../utilities/api/apix-handler";
import type {
    AdminUsersImpersonationSetupGetV2Params,
    AdminUsersOrgLinkUsersGetV2Params,
    AdminUsersUsernameSearchGetV2Params,
    BadRequestResponse,
    ChangeUsernameRequest,
    ForbiddenResponse,
    MassInviteUsersForBuilderRequest,
    UpdateAccountToAuth0Request,
    UsersAuthStoreForUsernameGetV2Params,
    UsersGetExternalCompanyInformationGetV2Params,
} from "./models";
import { ActivationStatus, AuthStoreTypes, BTLoginTypes } from "./models";
import type {
    AuthStoreForUsernameResponse,
    ChangeUsernameContract,
    ExternalCompanyInformationResponse,
    ExternalUserIdentityContract,
    ExternalUserResponse,
    GetUserActivationLoginTypeResponse,
    GetUserActivationResponse,
    ImpersonationSetupResponse,
    MassInviteUsersOnBuilderResponse,
    OrgLinkUsersResponse,
    UpdateAccountToAuth0Response,
    UsernameLookupContract,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Authorization**: `user-bearer`


**Authentication**: `LegacyBearer` | `auth0`


**Policy**: `ExternalUser`


**Stability Level**: `stable`
 * @summary Get information about the requested User.
 */
export const usersGetV2 = (options?: SecondParameter<typeof apixHandler>, signal?: AbortSignal) => {
    return apixHandler<ExternalUserResponse>(
        { url: `/apix/v2/Users`, method: "GET", signal },
        options
    );
};

export const getUsersGetV2QueryKey = () => {
    return [`/apix/v2/Users`] as const;
};

export const getUsersGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersGetV2QueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersGetV2>>> = ({ signal }) =>
        usersGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersGetV2QueryResult = NonNullable<Awaited<ReturnType<typeof usersGetV2>>>;
export type UsersGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about the requested User.
 */

export function useUsersGetV2<
    TData = Awaited<ReturnType<typeof usersGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `user-bearer`


**Authentication**: `LegacyBearer` | `passthrough`


**Policy**: `IdentityProviders`


**Stability Level**: `stable`
 * @summary Get identity information about the requested User.
 */
export const usersIdentityGetV2 = (
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalUserIdentityContract>(
        { url: `/apix/v2/Users/identity`, method: "GET", signal },
        options
    );
};

export const getUsersIdentityGetV2QueryKey = () => {
    return [`/apix/v2/Users/identity`] as const;
};

export const getUsersIdentityGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersIdentityGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersIdentityGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersIdentityGetV2QueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersIdentityGetV2>>> = ({ signal }) =>
        usersIdentityGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersIdentityGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersIdentityGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersIdentityGetV2>>
>;
export type UsersIdentityGetV2QueryError = ErrorType<void>;

/**
 * @summary Get identity information about the requested User.
 */

export function useUsersIdentityGetV2<
    TData = Awaited<ReturnType<typeof usersIdentityGetV2>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersIdentityGetV2>>, TError, TData>;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersIdentityGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `machine-to-machine-bearer`


**Authentication**: `auth0`


**Policy**: `ExternalService`


**Stability Level**: `stable`
 * @summary Get information about the specified user.
 */
export const usersByUserIdGetV2 = (
    userId: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalUserResponse>(
        { url: `/apix/v2/Users/${userId}`, method: "GET", signal },
        options
    );
};

export const getUsersByUserIdGetV2QueryKey = (userId: string) => {
    return [`/apix/v2/Users/${userId}`] as const;
};

export const getUsersByUserIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersByUserIdGetV2>>,
    TError = ErrorType<void>
>(
    userId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof usersByUserIdGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersByUserIdGetV2QueryKey(userId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersByUserIdGetV2>>> = ({ signal }) =>
        usersByUserIdGetV2(userId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!userId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersByUserIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersByUserIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersByUserIdGetV2>>
>;
export type UsersByUserIdGetV2QueryError = ErrorType<void>;

/**
 * @summary Get information about the specified user.
 */

export function useUsersByUserIdGetV2<
    TData = Awaited<ReturnType<typeof usersByUserIdGetV2>>,
    TError = ErrorType<void>
>(
    userId: string,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof usersByUserIdGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersByUserIdGetV2QueryOptions(userId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `machine-to-machine-bearer`


**Authentication**: `auth0`


**Policy**: `UserCredentialsWrite`


**Stability Level**: `stable`
 */
export const usersUpdateAccountToAuth0PostV2 = (
    updateAccountToAuth0Request: BodyType<UpdateAccountToAuth0Request>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<UpdateAccountToAuth0Response>(
        {
            url: `/apix/v2/Users/UpdateAccountToAuth0`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: updateAccountToAuth0Request,
        },
        options
    );
};

export const getUsersUpdateAccountToAuth0PostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
        TError,
        { data: BodyType<UpdateAccountToAuth0Request> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
    TError,
    { data: BodyType<UpdateAccountToAuth0Request> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
        { data: BodyType<UpdateAccountToAuth0Request> }
    > = (props) => {
        const { data } = props ?? {};

        return usersUpdateAccountToAuth0PostV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type UsersUpdateAccountToAuth0PostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>
>;
export type UsersUpdateAccountToAuth0PostV2MutationBody = BodyType<UpdateAccountToAuth0Request>;
export type UsersUpdateAccountToAuth0PostV2MutationError = ErrorType<unknown>;

export const useUsersUpdateAccountToAuth0PostV2 = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
        TError,
        { data: BodyType<UpdateAccountToAuth0Request> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof usersUpdateAccountToAuth0PostV2>>,
    TError,
    { data: BodyType<UpdateAccountToAuth0Request> },
    TContext
> => {
    const mutationOptions = getUsersUpdateAccountToAuth0PostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * **Authorization**: `machine-to-machine-bearer`


**Authentication**: `auth0`


**Policy**: `UserCredentialsWrite`


**Stability Level**: `stable`
 */
export const usersAuthStoreForUsernameGetV2 = (
    params?: UsersAuthStoreForUsernameGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<AuthStoreForUsernameResponse>(
        { url: `/apix/v2/Users/AuthStoreForUsername`, method: "GET", params, signal },
        options
    );
};

export const getUsersAuthStoreForUsernameGetV2QueryKey = (
    params?: UsersAuthStoreForUsernameGetV2Params
) => {
    return [`/apix/v2/Users/AuthStoreForUsername`, ...(params ? [params] : [])] as const;
};

export const getUsersAuthStoreForUsernameGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersAuthStoreForUsernameGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getUsersAuthStoreForUsernameGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>> = ({
        signal,
    }) => usersAuthStoreForUsernameGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersAuthStoreForUsernameGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>
>;
export type UsersAuthStoreForUsernameGetV2QueryError = ErrorType<unknown>;

export function useUsersAuthStoreForUsernameGetV2<
    TData = Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersAuthStoreForUsernameGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersAuthStoreForUsernameGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersAuthStoreForUsernameGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `machine-to-machine-bearer`


**Authentication**: `auth0`


**Policy**: `ExternalService`


**Stability Level**: `stable`
 * @summary Gets a list of ExternalUserResponse objects given a users external id
 */
export const usersGetExternalCompanyInformationGetV2 = (
    params?: UsersGetExternalCompanyInformationGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ExternalCompanyInformationResponse>(
        { url: `/apix/v2/Users/GetExternalCompanyInformation`, method: "GET", params, signal },
        options
    );
};

export const getUsersGetExternalCompanyInformationGetV2QueryKey = (
    params?: UsersGetExternalCompanyInformationGetV2Params
) => {
    return [`/apix/v2/Users/GetExternalCompanyInformation`, ...(params ? [params] : [])] as const;
};

export const getUsersGetExternalCompanyInformationGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersGetExternalCompanyInformationGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getUsersGetExternalCompanyInformationGetV2QueryKey(params);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>
    > = ({ signal }) => usersGetExternalCompanyInformationGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersGetExternalCompanyInformationGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>
>;
export type UsersGetExternalCompanyInformationGetV2QueryError = ErrorType<unknown>;

/**
 * @summary Gets a list of ExternalUserResponse objects given a users external id
 */

export function useUsersGetExternalCompanyInformationGetV2<
    TData = Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: UsersGetExternalCompanyInformationGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersGetExternalCompanyInformationGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersGetExternalCompanyInformationGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Stability Level**: `stable`
 */
export const usersExternalActivationLoginTypeByShareTokenGetV2 = (
    shareToken: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<GetUserActivationLoginTypeResponse>(
        {
            url: `/apix/v2/Users/external-activation/${shareToken}/login-type`,
            method: "GET",
            signal,
        },
        options
    );
};

export const getUsersExternalActivationLoginTypeByShareTokenGetV2QueryKey = (
    shareToken: string
) => {
    return [`/apix/v2/Users/external-activation/${shareToken}/login-type`] as const;
};

export const getUsersExternalActivationLoginTypeByShareTokenGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getUsersExternalActivationLoginTypeByShareTokenGetV2QueryKey(shareToken);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>
    > = ({ signal }) =>
        usersExternalActivationLoginTypeByShareTokenGetV2(shareToken, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!shareToken, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersExternalActivationLoginTypeByShareTokenGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>
>;
export type UsersExternalActivationLoginTypeByShareTokenGetV2QueryError = ErrorType<unknown>;

export function useUsersExternalActivationLoginTypeByShareTokenGetV2<
    TData = Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersExternalActivationLoginTypeByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersExternalActivationLoginTypeByShareTokenGetV2QueryOptions(
        shareToken,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Stability Level**: `stable`
 */
export const usersActivationByShareTokenGetV2 = (
    shareToken: string,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<GetUserActivationResponse>(
        { url: `/apix/v2/Users/activation/${shareToken}`, method: "GET", signal },
        options
    );
};

export const getUsersActivationByShareTokenGetV2QueryKey = (shareToken: string) => {
    return [`/apix/v2/Users/activation/${shareToken}`] as const;
};

export const getUsersActivationByShareTokenGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getUsersActivationByShareTokenGetV2QueryKey(shareToken);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>> = ({
        signal,
    }) => usersActivationByShareTokenGetV2(shareToken, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!shareToken, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type UsersActivationByShareTokenGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>
>;
export type UsersActivationByShareTokenGetV2QueryError = ErrorType<unknown>;

export function useUsersActivationByShareTokenGetV2<
    TData = Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
    TError = ErrorType<unknown>
>(
    shareToken: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof usersActivationByShareTokenGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getUsersActivationByShareTokenGetV2QueryOptions(shareToken, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 */
export const adminUsersUsernameSearchGetV2 = (
    params?: AdminUsersUsernameSearchGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<UsernameLookupContract>(
        { url: `/apix/v2/admin/Users/username-search`, method: "GET", params, signal },
        options
    );
};

export const getAdminUsersUsernameSearchGetV2QueryKey = (
    params?: AdminUsersUsernameSearchGetV2Params
) => {
    return [`/apix/v2/admin/Users/username-search`, ...(params ? [params] : [])] as const;
};

export const getAdminUsersUsernameSearchGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersUsernameSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminUsersUsernameSearchGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>> = ({
        signal,
    }) => adminUsersUsernameSearchGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminUsersUsernameSearchGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>
>;
export type AdminUsersUsernameSearchGetV2QueryError = ErrorType<unknown>;

export function useAdminUsersUsernameSearchGetV2<
    TData = Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersUsernameSearchGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersUsernameSearchGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getAdminUsersUsernameSearchGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 */
export const adminUsersChangeUsernamePutV2 = (
    changeUsernameRequest: BodyType<ChangeUsernameRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<ChangeUsernameContract>(
        {
            url: `/apix/v2/admin/Users/change-username`,
            method: "PUT",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: changeUsernameRequest,
        },
        options
    );
};

export const getAdminUsersChangeUsernamePutV2MutationOptions = <
    TError = ErrorType<BadRequestResponse>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersChangeUsernamePutV2>>,
        TError,
        { data: BodyType<ChangeUsernameRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof adminUsersChangeUsernamePutV2>>,
    TError,
    { data: BodyType<ChangeUsernameRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof adminUsersChangeUsernamePutV2>>,
        { data: BodyType<ChangeUsernameRequest> }
    > = (props) => {
        const { data } = props ?? {};

        return adminUsersChangeUsernamePutV2(data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AdminUsersChangeUsernamePutV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersChangeUsernamePutV2>>
>;
export type AdminUsersChangeUsernamePutV2MutationBody = BodyType<ChangeUsernameRequest>;
export type AdminUsersChangeUsernamePutV2MutationError = ErrorType<BadRequestResponse>;

export const useAdminUsersChangeUsernamePutV2 = <
    TError = ErrorType<BadRequestResponse>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersChangeUsernamePutV2>>,
        TError,
        { data: BodyType<ChangeUsernameRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof adminUsersChangeUsernamePutV2>>,
    TError,
    { data: BodyType<ChangeUsernameRequest> },
    TContext
> => {
    const mutationOptions = getAdminUsersChangeUsernamePutV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 */
export const adminUsersOrgLinkUsersGetV2 = (
    params?: AdminUsersOrgLinkUsersGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<OrgLinkUsersResponse>(
        { url: `/apix/v2/admin/Users/OrgLinkUsers`, method: "GET", params, signal },
        options
    );
};

export const getAdminUsersOrgLinkUsersGetV2QueryKey = (
    params?: AdminUsersOrgLinkUsersGetV2Params
) => {
    return [`/apix/v2/admin/Users/OrgLinkUsers`, ...(params ? [params] : [])] as const;
};

export const getAdminUsersOrgLinkUsersGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersOrgLinkUsersGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminUsersOrgLinkUsersGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>> = ({
        signal,
    }) => adminUsersOrgLinkUsersGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminUsersOrgLinkUsersGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>
>;
export type AdminUsersOrgLinkUsersGetV2QueryError = ErrorType<unknown>;

export function useAdminUsersOrgLinkUsersGetV2<
    TData = Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersOrgLinkUsersGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersOrgLinkUsersGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getAdminUsersOrgLinkUsersGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 */
export const adminUsersInvitesByOrganizationIdPostV2 = (
    organizationId: string,
    massInviteUsersForBuilderRequest: BodyType<MassInviteUsersForBuilderRequest>,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<MassInviteUsersOnBuilderResponse>(
        {
            url: `/apix/v2/admin/Users/invites/${organizationId}`,
            method: "POST",
            headers: { "Content-Type": "application/merge-patch+json" },
            data: massInviteUsersForBuilderRequest,
        },
        options
    );
};

export const getAdminUsersInvitesByOrganizationIdPostV2MutationOptions = <
    TError = ErrorType<BadRequestResponse | ForbiddenResponse>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
        TError,
        { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
    TError,
    { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
        { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> }
    > = (props) => {
        const { organizationId, data } = props ?? {};

        return adminUsersInvitesByOrganizationIdPostV2(organizationId, data, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AdminUsersInvitesByOrganizationIdPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>
>;
export type AdminUsersInvitesByOrganizationIdPostV2MutationBody =
    BodyType<MassInviteUsersForBuilderRequest>;
export type AdminUsersInvitesByOrganizationIdPostV2MutationError = ErrorType<
    BadRequestResponse | ForbiddenResponse
>;

export const useAdminUsersInvitesByOrganizationIdPostV2 = <
    TError = ErrorType<BadRequestResponse | ForbiddenResponse>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
        TError,
        { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof adminUsersInvitesByOrganizationIdPostV2>>,
    TError,
    { organizationId: string; data: BodyType<MassInviteUsersForBuilderRequest> },
    TContext
> => {
    const mutationOptions = getAdminUsersInvitesByOrganizationIdPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `stable`
 */
export const adminUsersDeactivateByGlobalUserIDPostV2 = (
    globalUserID: number,
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<void>(
        { url: `/apix/v2/admin/Users/${globalUserID}/deactivate`, method: "POST" },
        options
    );
};

export const getAdminUsersDeactivateByGlobalUserIDPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersDeactivateByGlobalUserIDPostV2>>,
        TError,
        { globalUserID: number },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof adminUsersDeactivateByGlobalUserIDPostV2>>,
    TError,
    { globalUserID: number },
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof adminUsersDeactivateByGlobalUserIDPostV2>>,
        { globalUserID: number }
    > = (props) => {
        const { globalUserID } = props ?? {};

        return adminUsersDeactivateByGlobalUserIDPostV2(globalUserID, requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type AdminUsersDeactivateByGlobalUserIDPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersDeactivateByGlobalUserIDPostV2>>
>;

export type AdminUsersDeactivateByGlobalUserIDPostV2MutationError = ErrorType<unknown>;

export const useAdminUsersDeactivateByGlobalUserIDPostV2 = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof adminUsersDeactivateByGlobalUserIDPostV2>>,
        TError,
        { globalUserID: number },
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof adminUsersDeactivateByGlobalUserIDPostV2>>,
    TError,
    { globalUserID: number },
    TContext
> => {
    const mutationOptions = getAdminUsersDeactivateByGlobalUserIDPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};
/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `AdminUser`


**Stability Level**: `draft`
 */
export const adminUsersImpersonationSetupGetV2 = (
    params?: AdminUsersImpersonationSetupGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ImpersonationSetupResponse>(
        { url: `/apix/v2/admin/Users/impersonation-setup`, method: "GET", params, signal },
        options
    );
};

export const getAdminUsersImpersonationSetupGetV2QueryKey = (
    params?: AdminUsersImpersonationSetupGetV2Params
) => {
    return [`/apix/v2/admin/Users/impersonation-setup`, ...(params ? [params] : [])] as const;
};

export const getAdminUsersImpersonationSetupGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersImpersonationSetupGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getAdminUsersImpersonationSetupGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>> = ({
        signal,
    }) => adminUsersImpersonationSetupGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type AdminUsersImpersonationSetupGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>
>;
export type AdminUsersImpersonationSetupGetV2QueryError = ErrorType<unknown>;

export function useAdminUsersImpersonationSetupGetV2<
    TData = Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: AdminUsersImpersonationSetupGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof adminUsersImpersonationSetupGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getAdminUsersImpersonationSetupGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const getUsersGetV2ResponseMock = (
    overrideResponse: Partial<ExternalUserResponse> = {}
): ExternalUserResponse => ({
    allOrgLinkIds: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() =>
            faker.string.uuid()
        ),
        undefined,
    ]),
    builderId: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    cellPhone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    email: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    firstName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    isActive: faker.datatype.boolean(),
    lastName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    phone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    userExternalId: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getUsersIdentityGetV2ResponseMock = (
    overrideResponse: Partial<ExternalUserIdentityContract> = {}
): ExternalUserIdentityContract => ({
    allOrgLinkIds: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() =>
            faker.string.uuid()
        ),
        undefined,
    ]),
    email: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    firstName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    lastName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    userExternalId: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getUsersByUserIdGetV2ResponseMock = (
    overrideResponse: Partial<ExternalUserResponse> = {}
): ExternalUserResponse => ({
    allOrgLinkIds: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() =>
            faker.string.uuid()
        ),
        undefined,
    ]),
    builderId: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    cellPhone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    email: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    firstName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
    isActive: faker.datatype.boolean(),
    lastName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    phone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    userExternalId: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getUsersUpdateAccountToAuth0PostV2ResponseMock =
    (): UpdateAccountToAuth0Response => ({});

export const getUsersAuthStoreForUsernameGetV2ResponseMock = (
    overrideResponse: Partial<AuthStoreForUsernameResponse> = {}
): AuthStoreForUsernameResponse => ({
    authStore: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(AuthStoreTypes)),
        undefined,
    ]),
    externalId: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    externalOrgLinkIds: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() =>
            faker.word.sample()
        ),
        undefined,
    ]),
    userExists: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getUsersGetExternalCompanyInformationGetV2ResponseMock = (
    overrideResponse: Partial<ExternalCompanyInformationResponse> = {}
): ExternalCompanyInformationResponse => ({
    externalUserResponses: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
            allOrgLinkIds: faker.helpers.arrayElement([
                Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
                    () => faker.string.uuid()
                ),
                undefined,
            ]),
            builderId: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
            cellPhone: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            email: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            firstName: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            id: faker.helpers.arrayElement([faker.string.uuid(), undefined]),
            isActive: faker.datatype.boolean(),
            lastName: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            phone: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            userExternalId: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        })),
        undefined,
    ]),
    ...overrideResponse,
});

export const getUsersExternalActivationLoginTypeByShareTokenGetV2ResponseMock = (
    overrideResponse: Partial<GetUserActivationLoginTypeResponse> = {}
): GetUserActivationLoginTypeResponse => ({
    loginType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(BTLoginTypes)),
        undefined,
    ]),
    ...overrideResponse,
});

export const getUsersActivationByShareTokenGetV2ResponseMock = (
    overrideResponse: Partial<GetUserActivationResponse> = {}
): GetUserActivationResponse => ({
    builderName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    canLinkExistingAccount: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    hasActiveSession: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    hasAlreadyLinked: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    isActivated: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    isFromBuilderSignup: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    loginType: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(BTLoginTypes)),
        undefined,
    ]),
    logoSrc: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    name: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    suggestedUsername: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    username: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getAdminUsersUsernameSearchGetV2ResponseMock = (
    overrideResponse: Partial<UsernameLookupContract> = {}
): UsernameLookupContract => ({
    links: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
            activatedDate: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            builderId: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            builderName: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            canBeDeactivated: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            email: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            enabled: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            id: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            isActive: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            isAuth0: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            isLastAdminUser: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            loginType: faker.helpers.arrayElement([
                faker.helpers.arrayElement(Object.values(BTLoginTypes)),
                undefined,
            ]),
            name: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            username: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        })),
        undefined,
    ]),
    usernameExists: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getAdminUsersChangeUsernamePutV2ResponseMock = (): ChangeUsernameContract => ({});

export const getAdminUsersOrgLinkUsersGetV2ResponseMock = (
    overrideResponse: Partial<OrgLinkUsersResponse> = {}
): OrgLinkUsersResponse => ({
    orgLinkUsers: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
            activation: faker.helpers.arrayElement(Object.values(ActivationStatus)),
            activationConfirmed: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            activationSent: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            credentialId: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            displayName: faker.word.sample(),
            email: faker.word.sample(),
            firstName: faker.word.sample(),
            id: faker.number.int({ min: undefined, max: undefined }),
            isFullAdmin: faker.datatype.boolean(),
            lastName: faker.word.sample(),
            loginType: faker.helpers.arrayElement([
                faker.helpers.arrayElement(Object.values(BTLoginTypes)),
                undefined,
            ]),
            userID: faker.word.sample(),
        })),
        undefined,
    ]),
    ...overrideResponse,
});

export const getAdminUsersInvitesByOrganizationIdPostV2ResponseMock = (
    overrideResponse: Partial<MassInviteUsersOnBuilderResponse> = {}
): MassInviteUsersOnBuilderResponse => ({
    success: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getAdminUsersImpersonationSetupGetV2ResponseMock = (
    overrideResponse: Partial<ImpersonationSetupResponse> = {}
): ImpersonationSetupResponse => ({
    success: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getUsersGetV2MockHandler = (
    overrideResponse?:
        | ExternalUserResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ExternalUserResponse> | ExternalUserResponse)
) => {
    return http.get("*/v2/Users", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersIdentityGetV2MockHandler = (
    overrideResponse?:
        | ExternalUserIdentityContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ExternalUserIdentityContract> | ExternalUserIdentityContract)
) => {
    return http.get("*/v2/Users/identity", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersIdentityGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersByUserIdGetV2MockHandler = (
    overrideResponse?:
        | ExternalUserResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ExternalUserResponse> | ExternalUserResponse)
) => {
    return http.get("*/v2/Users/:userId", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersByUserIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersUpdateAccountToAuth0PostV2MockHandler = (
    overrideResponse?:
        | UpdateAccountToAuth0Response
        | ((
              info: Parameters<Parameters<typeof http.post>[1]>[0]
          ) => Promise<UpdateAccountToAuth0Response> | UpdateAccountToAuth0Response)
) => {
    return http.post("*/v2/Users/UpdateAccountToAuth0", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersUpdateAccountToAuth0PostV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersAuthStoreForUsernameGetV2MockHandler = (
    overrideResponse?:
        | AuthStoreForUsernameResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<AuthStoreForUsernameResponse> | AuthStoreForUsernameResponse)
) => {
    return http.get("*/v2/Users/AuthStoreForUsername", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersAuthStoreForUsernameGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersGetExternalCompanyInformationGetV2MockHandler = (
    overrideResponse?:
        | ExternalCompanyInformationResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ExternalCompanyInformationResponse> | ExternalCompanyInformationResponse)
) => {
    return http.get("*/v2/Users/GetExternalCompanyInformation", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersGetExternalCompanyInformationGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersExternalActivationLoginTypeByShareTokenGetV2MockHandler = (
    overrideResponse?:
        | GetUserActivationLoginTypeResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<GetUserActivationLoginTypeResponse> | GetUserActivationLoginTypeResponse)
) => {
    return http.get("*/v2/Users/external-activation/:shareToken/login-type", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersExternalActivationLoginTypeByShareTokenGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getUsersActivationByShareTokenGetV2MockHandler = (
    overrideResponse?:
        | GetUserActivationResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<GetUserActivationResponse> | GetUserActivationResponse)
) => {
    return http.get("*/v2/Users/activation/:shareToken", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getUsersActivationByShareTokenGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminUsersUsernameSearchGetV2MockHandler = (
    overrideResponse?:
        | UsernameLookupContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<UsernameLookupContract> | UsernameLookupContract)
) => {
    return http.get("*/v2/admin/Users/username-search", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminUsersUsernameSearchGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminUsersChangeUsernamePutV2MockHandler = (
    overrideResponse?:
        | ChangeUsernameContract
        | ((
              info: Parameters<Parameters<typeof http.put>[1]>[0]
          ) => Promise<ChangeUsernameContract> | ChangeUsernameContract)
) => {
    return http.put("*/v2/admin/Users/change-username", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminUsersChangeUsernamePutV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminUsersOrgLinkUsersGetV2MockHandler = (
    overrideResponse?:
        | OrgLinkUsersResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<OrgLinkUsersResponse> | OrgLinkUsersResponse)
) => {
    return http.get("*/v2/admin/Users/OrgLinkUsers", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminUsersOrgLinkUsersGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminUsersInvitesByOrganizationIdPostV2MockHandler = (
    overrideResponse?:
        | MassInviteUsersOnBuilderResponse
        | ((
              info: Parameters<Parameters<typeof http.post>[1]>[0]
          ) => Promise<MassInviteUsersOnBuilderResponse> | MassInviteUsersOnBuilderResponse)
) => {
    return http.post("*/v2/admin/Users/invites/:organizationId", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminUsersInvitesByOrganizationIdPostV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getAdminUsersDeactivateByGlobalUserIDPostV2MockHandler = (
    overrideResponse?:
        | void
        | ((info: Parameters<Parameters<typeof http.post>[1]>[0]) => Promise<void> | void)
) => {
    return http.post("*/v2/admin/Users/:globalUserID/deactivate", async (info) => {
        if (typeof overrideResponse === "function") {
            await overrideResponse(info);
        }
        return new HttpResponse(null, { status: 200 });
    });
};

export const getAdminUsersImpersonationSetupGetV2MockHandler = (
    overrideResponse?:
        | ImpersonationSetupResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ImpersonationSetupResponse> | ImpersonationSetupResponse)
) => {
    return http.get("*/v2/admin/Users/impersonation-setup", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getAdminUsersImpersonationSetupGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};
export const getUsersMock = () => [
    getUsersGetV2MockHandler(),
    getUsersIdentityGetV2MockHandler(),
    getUsersByUserIdGetV2MockHandler(),
    getUsersUpdateAccountToAuth0PostV2MockHandler(),
    getUsersAuthStoreForUsernameGetV2MockHandler(),
    getUsersGetExternalCompanyInformationGetV2MockHandler(),
    getUsersExternalActivationLoginTypeByShareTokenGetV2MockHandler(),
    getUsersActivationByShareTokenGetV2MockHandler(),
    getAdminUsersUsernameSearchGetV2MockHandler(),
    getAdminUsersChangeUsernamePutV2MockHandler(),
    getAdminUsersOrgLinkUsersGetV2MockHandler(),
    getAdminUsersInvitesByOrganizationIdPostV2MockHandler(),
    getAdminUsersDeactivateByGlobalUserIDPostV2MockHandler(),
    getAdminUsersImpersonationSetupGetV2MockHandler(),
];
