import classNames from "classnames";
import { FunctionComponent } from "react";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconDocuments } from "commonComponents/btWrappers/BTIcon";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { emptyStateIconSize } from "commonComponents/entity/emptyState/common/EmptyState.api.types";
import { BTFileUploadMode } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";

interface IFileUploadAreaProps {
    uploadMode: BTFileUploadMode;
    onClickBrowseBTFiles?(): void;
}

export const FileUploadArea: FunctionComponent<IFileUploadAreaProps> = ({
    uploadMode,
    onClickBrowseBTFiles,
}) => {
    const handleClickBrowseBTFiles = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClickBrowseBTFiles?.();
    };

    const canBrowseBTFiles = uploadMode === BTFileUploadMode.Attachments && onClickBrowseBTFiles;

    return (
        <BTRow>
            <BTCol xs={24}>
                <BTRow justify="center">
                    <BTCol>
                        <BTIconDocuments
                            size={emptyStateIconSize}
                            className={classNames(
                                "EmptyStateIcon",
                                "margin-top-lg",
                                "margin-bottom-md"
                            )}
                            data-testid="EmptyStateIcon"
                        />
                    </BTCol>
                </BTRow>
                <BTRow justify="center">
                    <BTCol xs={16} lg={12} xl={8}>
                        <BTTitle level={3} className="text-center">
                            Drop files here to upload, or browse for files
                        </BTTitle>
                    </BTCol>
                </BTRow>
                <BTRow justify="center" gutter={[8, 0]}>
                    <BTCol>
                        <BTButton
                            data-testid="browseComputerFiles"
                            className="margin-top-md margin-bottom-lg"
                            type="new"
                        >
                            <ShowOnPortal
                                builder
                                render={() => (canBrowseBTFiles ? "Browse Computer" : "Browse")}
                            />
                            <ShowOnPortal btadmin subs owner render={() => "Browse"} />
                        </BTButton>
                    </BTCol>
                    {canBrowseBTFiles && (
                        <ShowOnPortal
                            builder
                            render={() => (
                                <BTCol>
                                    <BTButton
                                        data-testid="browseBTFiles"
                                        className="margin-top-md margin-bottom-lg"
                                        type="secondary"
                                        onClick={handleClickBrowseBTFiles}
                                    >
                                        Browse Buildertrend
                                    </BTButton>
                                </BTCol>
                            )}
                        />
                    )}
                </BTRow>
            </BTCol>
        </BTRow>
    );
};
