import { Radio } from "antd";
import { FunctionComponent } from "react";

import { BTIconList, BTIconTile } from "commonComponents/btWrappers/BTIcon";
import { BTRadioGroup } from "commonComponents/btWrappers/BTRadioGroup/BTRadioGroup";

import { SelectedMediaListViews } from "entity/media/common/mediaTypes";

import "./FileViewToggle.less";

interface IFileViewToggleProps {
    value: SelectedMediaListViews;
    onChange: (value: SelectedMediaListViews) => void;
    className?: string;
}

export const FileViewToggle: FunctionComponent<IFileViewToggleProps> = ({ value, onChange }) => {
    return (
        <BTRadioGroup
            id="fileViewToggle"
            data-testid="fileViewToggle"
            onChange={(e) => onChange(e.target.value)}
            className="media-list-file-toggle"
            value={value}
        >
            <Radio.Button value="Tile" onClick={(e) => e.currentTarget.blur()}>
                <BTIconTile size={20} className="media-list-file-toggle-icon" />
            </Radio.Button>
            <Radio.Button value="Table" onClick={(e) => e.currentTarget.blur()}>
                <BTIconList size={20} className="media-list-file-toggle-icon" />
            </Radio.Button>
        </BTRadioGroup>
    );
};
