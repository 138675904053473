/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useQuery } from "@tanstack/react-query";
import type {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { ErrorType } from "../utilities/api/apix-handler";
import type { SummaryOwnerUpdatesGetV2Params } from "./models";
import { FolderTypes, MediaTypes, SystemFilterType, VideoStatus } from "./models";
import type {
    ActionItemCountsContract,
    BuilderCardInfoContract,
    ClientUpdateOwnerUpdate,
    CommunicationCountsContract,
    ContactUsContract,
    DailyLogOwnerUpdate,
    OwnerUpdatesListResponse,
    PhotoGroupOwnerUpdate,
} from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const summaryJobContactInfoByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ContactUsContract>(
        { url: `/apix/v2/Summary/job/${jobId}/contact-info`, method: "GET", signal },
        options
    );
};

export const getSummaryJobContactInfoByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/contact-info`] as const;
};

export const getSummaryJobContactInfoByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getSummaryJobContactInfoByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>> = ({
        signal,
    }) => summaryJobContactInfoByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobContactInfoByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>
>;
export type SummaryJobContactInfoByJobIdGetV2QueryError = ErrorType<unknown>;

export function useSummaryJobContactInfoByJobIdGetV2<
    TData = Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSummaryJobContactInfoByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const summaryJobBuilderContactInfoByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<BuilderCardInfoContract>(
        { url: `/apix/v2/Summary/job/${jobId}/builder-contact-info`, method: "GET", signal },
        options
    );
};

export const getSummaryJobBuilderContactInfoByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/builder-contact-info`] as const;
};

export const getSummaryJobBuilderContactInfoByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getSummaryJobBuilderContactInfoByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>
    > = ({ signal }) => summaryJobBuilderContactInfoByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobBuilderContactInfoByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>
>;
export type SummaryJobBuilderContactInfoByJobIdGetV2QueryError = ErrorType<unknown>;

export function useSummaryJobBuilderContactInfoByJobIdGetV2<
    TData = Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobBuilderContactInfoByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSummaryJobBuilderContactInfoByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const summaryJobActionItemsCountByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<ActionItemCountsContract>(
        { url: `/apix/v2/Summary/job/${jobId}/action-items/count`, method: "GET", signal },
        options
    );
};

export const getSummaryJobActionItemsCountByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/action-items/count`] as const;
};

export const getSummaryJobActionItemsCountByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getSummaryJobActionItemsCountByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>
    > = ({ signal }) => summaryJobActionItemsCountByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobActionItemsCountByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>
>;
export type SummaryJobActionItemsCountByJobIdGetV2QueryError = ErrorType<unknown>;

export function useSummaryJobActionItemsCountByJobIdGetV2<
    TData = Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobActionItemsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSummaryJobActionItemsCountByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const summaryJobCommunicationsCountByJobIdGetV2 = (
    jobId: number,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<CommunicationCountsContract>(
        { url: `/apix/v2/Summary/job/${jobId}/communications/count`, method: "GET", signal },
        options
    );
};

export const getSummaryJobCommunicationsCountByJobIdGetV2QueryKey = (jobId: number) => {
    return [`/apix/v2/Summary/job/${jobId}/communications/count`] as const;
};

export const getSummaryJobCommunicationsCountByJobIdGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getSummaryJobCommunicationsCountByJobIdGetV2QueryKey(jobId);

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>
    > = ({ signal }) => summaryJobCommunicationsCountByJobIdGetV2(jobId, requestOptions, signal);

    return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryJobCommunicationsCountByJobIdGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>
>;
export type SummaryJobCommunicationsCountByJobIdGetV2QueryError = ErrorType<unknown>;

export function useSummaryJobCommunicationsCountByJobIdGetV2<
    TData = Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
    TError = ErrorType<unknown>
>(
    jobId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryJobCommunicationsCountByJobIdGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSummaryJobCommunicationsCountByJobIdGetV2QueryOptions(jobId, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const summaryOwnerUpdatesGetV2 = (
    params: SummaryOwnerUpdatesGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<OwnerUpdatesListResponse>(
        { url: `/apix/v2/Summary/owner-updates`, method: "GET", params, signal },
        options
    );
};

export const getSummaryOwnerUpdatesGetV2QueryKey = (params: SummaryOwnerUpdatesGetV2Params) => {
    return [`/apix/v2/Summary/owner-updates`, ...(params ? [params] : [])] as const;
};

export const getSummaryOwnerUpdatesGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>,
    TError = ErrorType<unknown>
>(
    params: SummaryOwnerUpdatesGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getSummaryOwnerUpdatesGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>> = ({
        signal,
    }) => summaryOwnerUpdatesGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type SummaryOwnerUpdatesGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>
>;
export type SummaryOwnerUpdatesGetV2QueryError = ErrorType<unknown>;

export function useSummaryOwnerUpdatesGetV2<
    TData = Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>,
    TError = ErrorType<unknown>
>(
    params: SummaryOwnerUpdatesGetV2Params,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<typeof summaryOwnerUpdatesGetV2>>,
            TError,
            TData
        >;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getSummaryOwnerUpdatesGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const getSummaryJobContactInfoByJobIdGetV2ResponseMock = (
    overrideResponse: Partial<ContactUsContract> = {}
): ContactUsContract => ({
    builderName: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    fax: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    formattedAddress: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    phone: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    projectManagers: faker.helpers.arrayElement([
        Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() => ({
            name: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            phone: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            userId: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
        })),
        undefined,
    ]),
    ...overrideResponse,
});

export const getSummaryJobBuilderContactInfoByJobIdGetV2ResponseMock = (
    overrideResponse: Partial<BuilderCardInfoContract> = {}
): BuilderCardInfoContract => ({
    builderAddress: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    companyLogoUrl: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    phoneNumber: faker.helpers.arrayElement([
        faker.helpers.arrayElement([faker.word.sample(), null]),
        undefined,
    ]),
    ...overrideResponse,
});

export const getSummaryJobActionItemsCountByJobIdGetV2ResponseMock = (
    overrideResponse: Partial<ActionItemCountsContract> = {}
): ActionItemCountsContract => ({
    assignedToDos: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    dueDeposits: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    duePayments: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    expiringChangeOrders: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    pendingWarranties: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    systemFilterId: faker.helpers.arrayElement([
        faker.helpers.arrayElement(Object.values(SystemFilterType)),
        undefined,
    ]),
    unapprovedSelections: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    unreadDocuments: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    unsubmittedSurveys: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    ...overrideResponse,
});

export const getSummaryJobCommunicationsCountByJobIdGetV2ResponseMock = (
    overrideResponse: Partial<CommunicationCountsContract> = {}
): CommunicationCountsContract => ({
    chats: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    messages: faker.helpers.arrayElement([
        {
            anyPastDue: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
            count: faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                undefined,
            ]),
            filterString: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
        },
        undefined,
    ]),
    ...overrideResponse,
});

export const getSummaryOwnerUpdatesGetV2ResponseClientUpdateOwnerUpdateMock = (
    overrideResponse: Partial<ClientUpdateOwnerUpdate> = {}
): ClientUpdateOwnerUpdate => ({
    ...{
        date: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        description: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        files: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
            () => ({
                breadCrumbs: faker.helpers.arrayElement([
                    faker.helpers.arrayElement([faker.word.sample(), null]),
                    undefined,
                ]),
                file: faker.helpers.arrayElement([
                    {
                        addedBy: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotatedDocPathWithBranding: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotationCount: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        annotationGroupId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        annotationLinkCount: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        attachedEntityPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        builderId: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        commentCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        dateAttached: faker.helpers.arrayElement([
                            (() =>
                                /* @__PURE__ */ new Date(
                                    `${faker.date.past().toISOString().split(".")[0]}Z`
                                ))(),
                            undefined,
                        ]),
                        docPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        docPathWithBranding: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        documentInstanceId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        downloadAnnotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        downloadDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        duration: faker.helpers.arrayElement([faker.word.sample(), undefined]),
                        entityId: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        extension: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        fileSize: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        fileSizeFormatted: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        folderType: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(FolderTypes)),
                            undefined,
                        ]),
                        hasAnnotationLinks: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        hasAnnotations: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        id: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        is360Media: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isMacroEnabledExcelFile: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isOriginalResolution: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isPhoto: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                        isTempFile: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        jobsiteId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        lastModifiedOn: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                (() =>
                                    /* @__PURE__ */ new Date(
                                        `${faker.date.past().toISOString().split(".")[0]}Z`
                                    ))(),
                                null,
                            ]),
                            undefined,
                        ]),
                        mediaType: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(MediaTypes)),
                            undefined,
                        ]),
                        permissions: faker.helpers.arrayElement([
                            {
                                canAddRfis: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canAnnotate: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canComment: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canCopy: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canDelete: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canDrawOnline: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEdit: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditBids: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditChangeOrders: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditDailyLogs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditOnline: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditScheduleItems: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditSelections: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditToDos: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditWarranties: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEmail: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canMove: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canRequestSignatures: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSetAsOwnerFeature: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSetViewingPermissions: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSign: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canViewRfis: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            },
                            undefined,
                        ]),
                        previewAnnotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        previewDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        status: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(VideoStatus)),
                            undefined,
                        ]),
                        subFolderCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        subFolderDocumentCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        thumbnail: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        title: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        videoId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        viewingPermissions: faker.helpers.arrayElement([
                            {
                                mainFile: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showAllSubs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showOwner: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showSubs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            },
                            undefined,
                        ]),
                    },
                    undefined,
                ]),
            })
        ),
        title: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        addedByDate: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        addedByUserId: faker.number.int({ min: undefined, max: undefined }),
        addedByUserName: faker.word.sample(),
        clientUpdateId: faker.number.int({ min: undefined, max: undefined }),
        endDate: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        releasedByDate: faker.helpers.arrayElement([
            faker.helpers.arrayElement([
                (() =>
                    /* @__PURE__ */ new Date(
                        `${faker.date.past().toISOString().split(".")[0]}Z`
                    ))(),
                null,
            ]),
            undefined,
        ]),
        releasedByUserId: faker.helpers.arrayElement([
            faker.helpers.arrayElement([
                faker.number.int({ min: undefined, max: undefined }),
                null,
            ]),
            undefined,
        ]),
        releasedByUserName: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        startDate: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        type: faker.helpers.arrayElement(["clientUpdate"] as const),
    },
    ...overrideResponse,
});

export const getSummaryOwnerUpdatesGetV2ResponsePhotoGroupOwnerUpdateMock = (
    overrideResponse: Partial<PhotoGroupOwnerUpdate> = {}
): PhotoGroupOwnerUpdate => ({
    ...{
        date: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        description: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        files: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
            () => ({
                breadCrumbs: faker.helpers.arrayElement([
                    faker.helpers.arrayElement([faker.word.sample(), null]),
                    undefined,
                ]),
                file: faker.helpers.arrayElement([
                    {
                        addedBy: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotatedDocPathWithBranding: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotationCount: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        annotationGroupId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        annotationLinkCount: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        attachedEntityPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        builderId: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        commentCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        dateAttached: faker.helpers.arrayElement([
                            (() =>
                                /* @__PURE__ */ new Date(
                                    `${faker.date.past().toISOString().split(".")[0]}Z`
                                ))(),
                            undefined,
                        ]),
                        docPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        docPathWithBranding: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        documentInstanceId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        downloadAnnotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        downloadDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        duration: faker.helpers.arrayElement([faker.word.sample(), undefined]),
                        entityId: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        extension: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        fileSize: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        fileSizeFormatted: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        folderType: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(FolderTypes)),
                            undefined,
                        ]),
                        hasAnnotationLinks: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        hasAnnotations: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        id: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        is360Media: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isMacroEnabledExcelFile: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isOriginalResolution: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isPhoto: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                        isTempFile: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        jobsiteId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        lastModifiedOn: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                (() =>
                                    /* @__PURE__ */ new Date(
                                        `${faker.date.past().toISOString().split(".")[0]}Z`
                                    ))(),
                                null,
                            ]),
                            undefined,
                        ]),
                        mediaType: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(MediaTypes)),
                            undefined,
                        ]),
                        permissions: faker.helpers.arrayElement([
                            {
                                canAddRfis: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canAnnotate: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canComment: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canCopy: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canDelete: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canDrawOnline: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEdit: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditBids: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditChangeOrders: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditDailyLogs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditOnline: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditScheduleItems: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditSelections: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditToDos: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditWarranties: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEmail: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canMove: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canRequestSignatures: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSetAsOwnerFeature: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSetViewingPermissions: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSign: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canViewRfis: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            },
                            undefined,
                        ]),
                        previewAnnotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        previewDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        status: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(VideoStatus)),
                            undefined,
                        ]),
                        subFolderCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        subFolderDocumentCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        thumbnail: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        title: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        videoId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        viewingPermissions: faker.helpers.arrayElement([
                            {
                                mainFile: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showAllSubs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showOwner: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showSubs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            },
                            undefined,
                        ]),
                    },
                    undefined,
                ]),
            })
        ),
        title: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        type: faker.helpers.arrayElement(["photo"] as const),
    },
    ...overrideResponse,
});

export const getSummaryOwnerUpdatesGetV2ResponseDailyLogOwnerUpdateMock = (
    overrideResponse: Partial<DailyLogOwnerUpdate> = {}
): DailyLogOwnerUpdate => ({
    ...{
        date: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        description: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        files: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(
            () => ({
                breadCrumbs: faker.helpers.arrayElement([
                    faker.helpers.arrayElement([faker.word.sample(), null]),
                    undefined,
                ]),
                file: faker.helpers.arrayElement([
                    {
                        addedBy: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotatedDocPathWithBranding: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        annotationCount: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        annotationGroupId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        annotationLinkCount: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        attachedEntityPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        builderId: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        commentCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        dateAttached: faker.helpers.arrayElement([
                            (() =>
                                /* @__PURE__ */ new Date(
                                    `${faker.date.past().toISOString().split(".")[0]}Z`
                                ))(),
                            undefined,
                        ]),
                        docPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        docPathWithBranding: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        documentInstanceId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        downloadAnnotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        downloadDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        duration: faker.helpers.arrayElement([faker.word.sample(), undefined]),
                        entityId: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        extension: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        fileSize: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        fileSizeFormatted: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        folderType: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(FolderTypes)),
                            undefined,
                        ]),
                        hasAnnotationLinks: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        hasAnnotations: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        id: faker.helpers.arrayElement([
                            faker.number.int({ min: undefined, max: undefined }),
                            undefined,
                        ]),
                        is360Media: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isMacroEnabledExcelFile: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isOriginalResolution: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        isPhoto: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
                        isTempFile: faker.helpers.arrayElement([
                            faker.datatype.boolean(),
                            undefined,
                        ]),
                        jobsiteId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        lastModifiedOn: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                (() =>
                                    /* @__PURE__ */ new Date(
                                        `${faker.date.past().toISOString().split(".")[0]}Z`
                                    ))(),
                                null,
                            ]),
                            undefined,
                        ]),
                        mediaType: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(MediaTypes)),
                            undefined,
                        ]),
                        permissions: faker.helpers.arrayElement([
                            {
                                canAddRfis: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canAnnotate: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canComment: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canCopy: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canDelete: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canDrawOnline: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEdit: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditBids: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditChangeOrders: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditDailyLogs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditOnline: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditScheduleItems: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditSelections: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditToDos: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEditWarranties: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canEmail: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canMove: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canRequestSignatures: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSetAsOwnerFeature: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSetViewingPermissions: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canSign: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                canViewRfis: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            },
                            undefined,
                        ]),
                        previewAnnotatedDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        previewDocPath: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        status: faker.helpers.arrayElement([
                            faker.helpers.arrayElement(Object.values(VideoStatus)),
                            undefined,
                        ]),
                        subFolderCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        subFolderDocumentCount: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        thumbnail: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        title: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([faker.word.sample(), null]),
                            undefined,
                        ]),
                        videoId: faker.helpers.arrayElement([
                            faker.helpers.arrayElement([
                                faker.number.int({ min: undefined, max: undefined }),
                                null,
                            ]),
                            undefined,
                        ]),
                        viewingPermissions: faker.helpers.arrayElement([
                            {
                                mainFile: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showAllSubs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showOwner: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                                showSubs: faker.helpers.arrayElement([
                                    faker.datatype.boolean(),
                                    undefined,
                                ]),
                            },
                            undefined,
                        ]),
                    },
                    undefined,
                ]),
            })
        ),
        title: faker.helpers.arrayElement([
            faker.helpers.arrayElement([faker.word.sample(), null]),
            undefined,
        ]),
        dailyLogId: faker.number.int({ min: undefined, max: undefined }),
        type: faker.helpers.arrayElement(["dailyLog"] as const),
    },
    ...overrideResponse,
});

export const getSummaryOwnerUpdatesGetV2ResponseMock = (
    overrideResponse: Partial<OwnerUpdatesListResponse> = {}
): OwnerUpdatesListResponse => ({
    data: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, (_, i) => i + 1).map(() =>
        faker.helpers.arrayElement([
            { ...getSummaryOwnerUpdatesGetV2ResponseClientUpdateOwnerUpdateMock() },
            { ...getSummaryOwnerUpdatesGetV2ResponsePhotoGroupOwnerUpdateMock() },
            { ...getSummaryOwnerUpdatesGetV2ResponseDailyLogOwnerUpdateMock() },
        ])
    ),
    nextCursor: faker.helpers.arrayElement([
        {
            cid: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            ct: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            did: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            dt: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            pt: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
        },
        undefined,
    ]),
    previousCursor: faker.helpers.arrayElement([
        {
            cid: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            ct: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            did: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            dt: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
            pt: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    (() =>
                        /* @__PURE__ */ new Date(
                            `${faker.date.past().toISOString().split(".")[0]}Z`
                        ))(),
                    null,
                ]),
                undefined,
            ]),
        },
        undefined,
    ]),
    ...overrideResponse,
});

export const getSummaryJobContactInfoByJobIdGetV2MockHandler = (
    overrideResponse?:
        | ContactUsContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ContactUsContract> | ContactUsContract)
) => {
    return http.get("*/v2/Summary/job/:jobId/contact-info", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getSummaryJobContactInfoByJobIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getSummaryJobBuilderContactInfoByJobIdGetV2MockHandler = (
    overrideResponse?:
        | BuilderCardInfoContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<BuilderCardInfoContract> | BuilderCardInfoContract)
) => {
    return http.get("*/v2/Summary/job/:jobId/builder-contact-info", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getSummaryJobBuilderContactInfoByJobIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getSummaryJobActionItemsCountByJobIdGetV2MockHandler = (
    overrideResponse?:
        | ActionItemCountsContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<ActionItemCountsContract> | ActionItemCountsContract)
) => {
    return http.get("*/v2/Summary/job/:jobId/action-items/count", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getSummaryJobActionItemsCountByJobIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getSummaryJobCommunicationsCountByJobIdGetV2MockHandler = (
    overrideResponse?:
        | CommunicationCountsContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<CommunicationCountsContract> | CommunicationCountsContract)
) => {
    return http.get("*/v2/Summary/job/:jobId/communications/count", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getSummaryJobCommunicationsCountByJobIdGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getSummaryOwnerUpdatesGetV2MockHandler = (
    overrideResponse?:
        | OwnerUpdatesListResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<OwnerUpdatesListResponse> | OwnerUpdatesListResponse)
) => {
    return http.get("*/v2/Summary/owner-updates", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getSummaryOwnerUpdatesGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};
export const getSummaryMock = () => [
    getSummaryJobContactInfoByJobIdGetV2MockHandler(),
    getSummaryJobBuilderContactInfoByJobIdGetV2MockHandler(),
    getSummaryJobActionItemsCountByJobIdGetV2MockHandler(),
    getSummaryJobCommunicationsCountByJobIdGetV2MockHandler(),
    getSummaryOwnerUpdatesGetV2MockHandler(),
];
