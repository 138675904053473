import { APIHandler } from "utilities/apiHandler";

import {
    IGetShareTokenRequest,
    ShareTokenResponse,
} from "commonComponents/btWrappers/BTSocialMediaButtons/FileShare.types";

export interface IFileShareHandler {
    GetShareToken(
        documentIntanceId: number,
        data: IGetShareTokenRequest
    ): Promise<ShareTokenResponse>;
}

export class FileShareHandler implements IFileShareHandler {
    async GetShareToken(
        documentInstanceId: number,
        data: IGetShareTokenRequest
    ): Promise<ShareTokenResponse> {
        return await APIHandler(`/api/files/${documentInstanceId}/sharetoken`, {
            method: "POST",
            data: data,
            responseType: ShareTokenResponse,
        });
    }
}
