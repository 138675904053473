import { Component } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { EmailFolder } from "types/enum";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import {
    BTConfirm,
    BTConfirmContent,
    BTConfirmFooter,
} from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import {
    IMessageDetailsHandler,
    MessageDetailsHandler,
} from "entity/message/common/Message.api.handler";

const excludedFolders = [EmailFolder.Global, EmailFolder.Sent, EmailFolder.Drafts];

interface IMoveToFolderModalProps {
    beforeClose: () => void;
    onOkay: (selectedValue: number) => void;
    currentFolderId: number;
    handler?: IMessageDetailsHandler;
    centered?: boolean;
}

interface IMoveToFolderModalState {
    folderSelectItems?: BTSelectItem[];
    selectedFolder: number;
}

/**
 * A wrapper around BTModal.  Gets folders on mount.
 */
export class MoveToFolderModal extends Component<IMoveToFolderModalProps, IMoveToFolderModalState> {
    static defaultProps = {
        handler: new MessageDetailsHandler(),
    };

    componentDidMount = async () => {
        const folderData = await this.props.handler!.getFolders();
        const currentFolder = folderData.folders.find(
            (x) => x.folderId === this.props.currentFolderId
        );
        const selectItems = folderData.folders
            .filter((x) => !excludedFolders.includes(x.folderId))
            .map(
                (x) =>
                    new BTSelectItem({
                        ...x,
                        id: x.folderId.toString(),
                        selected: x === currentFolder,
                    })
            );
        this.setState({
            folderSelectItems: selectItems,
            selectedFolder: selectItems.length > 0 ? selectItems[0].value : -1,
        });
    };

    /**
     * Handle change of the folder dropdown
     */
    private handleSelectChange = (field: string, value: number) => {
        this.setState({ selectedFolder: value });
    };

    private handleOnOk = () => {
        this.props.onOkay(this.state.selectedFolder);
    };

    render() {
        if (!this.state) {
            return <BTLoading />;
        }
        return (
            <BTConfirm visible overflow="visible" title="Move to Folder">
                <BTForm>
                    <BTConfirmContent>
                        <BTFormItem label="Destination Folder">
                            <BTSelect
                                id="folderSelect"
                                data-testid="folderSelect"
                                value={this.state.selectedFolder}
                                treeData={this.state.folderSelectItems}
                                onChange={this.handleSelectChange}
                                onBlur={() => {}}
                            />
                        </BTFormItem>
                    </BTConfirmContent>
                    <BTConfirmFooter>
                        <BTButton
                            type="secondary"
                            onClick={this.props.beforeClose}
                            data-testid="cancel"
                            className="margin-right-xs"
                        >
                            Cancel
                        </BTButton>
                        <BTButton
                            type="primary"
                            htmlType="submit"
                            onClick={this.handleOnOk}
                            data-testid="save"
                            hotkey="save"
                        >
                            Save
                        </BTButton>
                    </BTConfirmFooter>
                </BTForm>
            </BTConfirm>
        );
    }
}
