import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { MediaType } from "types/enum";

import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";
import { BTIconDownloadOutlined } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemDownloadProps {
    file: BTFileSystem;
    onClick: () => Promise<void>;
}

const MenuItemDownload = ({ file, onClick, ...antProps }: IMenuItemDownloadProps) => {
    if (file.mediaType === MediaType.Video) {
        return (
            <BTMenuItem
                {...antProps}
                key={`downloadFile-menuitem-${file.id}`}
                data-testid={`downloadFile-menuitem-${file.id}`}
                onClick={onClick}
            >
                <BTIconDownloadOutlined /> Download
            </BTMenuItem>
        );
    }

    return (
        <BTMenuItem
            {...antProps}
            key={`downloadFile-menuitem-${file.id}`}
            data-testid={`downloadFile-menuitem-${file.id}`}
        >
            <BTExternalLink
                href={file.downloadDocPath}
                isUnderline={false}
                data-testid={`downloadFile-${file.id}`}
                onClick={onClick}
            >
                <BTIconDownloadOutlined /> Download
            </BTExternalLink>
        </BTMenuItem>
    );
};

export default MenuItemDownload;
