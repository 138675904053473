import { Result } from "antd";

import { routesWebforms } from "utilities/routesWebforms";

const NotFoundError: React.FunctionComponent = () => {
    return (
        <Result
            status={404}
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <a href={routesWebforms.Login.GetLoginURL()} target="_top">
                    Return to Homepage
                </a>
            }
        />
    );
};

export default NotFoundError;
