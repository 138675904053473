import { isEqual, sortBy } from "lodash-es";

import { IBaseEntity, IIsSelectable } from "types/apiResponse/apiResponse";
import { CheckAllState } from "types/enum";

import { isTruthy } from "utilities/object/object";
import { Falsy } from "utilities/type/Falsy";

/**
 * given a key-value mapping, where each value represents a bit in
 * a bit-string, and a subset of keys, this function masks the
 * keys into a number.
 * i.e. if we have {Red:1, Blue:2, Yellow:4} and choose ["Red", "Yellow"]
 * the bits are 101 -> 5
 *
 * @params  mask : enum that maps strings to values
 *          e.g. const days = {
 *                      Sunday: 1,
 *                      Monday: 2,
 *                      Tuesday: 4,
 *                      Wednesday: 8,
 *                      Thursday: 16,
 *                      Friday: 32,
 *                      Saturday: 64,
 *                   };
 *          keys : a subset of strings from the mask
 *          e.g. ["Sunday", "Monday", "Tuesday"]
 * @return  value : a number that represents the selected keys
 *                  under the mask
 *          e.g. 7
 */
export const fromEnumToInt = (mask: object, keys: string[]) => {
    let value = 0;
    for (let key in mask) {
        if (keys.includes(key)) {
            value += mask[key];
        }
    }
    return value;
};

/**
 * given a key-value mapping, where each value represents a bit in
 * a bit-string, and a number, this function converts the number back to
 * its key set.
 * i.e. if we have {Red:1, Blue:2, Yellow:4} and the number 5, the
 * bits are 101 which maps to ["Red", "Yellow"]
 *  @params mask : enum that maps strings to values
 *           e.g. const days = {
 *                      Sunday: 1,
 *                      Monday: 2,
 *                      Tuesday: 4,
 *                      Wednesday: 8,
 *                      Thursday: 16,
 *                      Friday: 32,
 *                      Saturday: 64,
 *                   };
 *          value : a number that represents the selected keys
 *                  under the mask
 *          e.g. 7
 *  @return keys : a subset of strings from the mask
 *          e.g. ["Sunday", "Monday", "Tuesday"]
 */
export const fromIntToEnum = (mask: object, value: number) => {
    let keys = [];
    for (let key in mask) {
        if ((value & mask[key]) === mask[key]) {
            keys.push(key);
        }
    }
    return keys;
};

/**
 * Given an array and a chunkSize, returns a new array with the elements of the original array divided into distinct arrays of chunk size.
 * @param array array to divide into "chunks"
 * @param chunkSize maximum number of elements that should be contained in each "chunk"
 * @example
 * //returns [[3,5],[7,1],[6]]
 * chunkArray([3,5,7,1,6], 2)
 */
export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
    return array.reduce<T[][]>((accumulator, _, i, a) => {
        if (i % chunkSize === 0) {
            accumulator.push(a.slice(i, i + chunkSize));
        }
        return accumulator;
    }, []);
}

/**
 * Given a list of items, return the list items that have a duplicate value in the list.
 * [1,2,2,4,5,7,5] => [2,5]
 * @param array
 */
export function findDuplicateItems<T>(array: T[]): T[] {
    const counted = array.reduce(
        (accumulator: any, element: any) => ({
            ...accumulator,
            [element]: (accumulator[element] || 0) + 1,
        }),
        {}
    );
    return Array.from(
        new Set(
            array.filter((item) => {
                return counted[item] > 1;
            })
        )
    );
}

/**
 * Clone an array, creating a new copy of the array. Should only be used with arrays of primitives
 * @param array
 * @returns cloned array
 */
export function clone<T>(array: T[]): T[] {
    return Object.assign([], array);
}

/**
 * Comparison for number arrays to check for equality. It does not care about array element order
 * @example [1,2,3] == [3,1,2], [1,2,3] != [1,3,4]
 * @param arrayA : number[]
 * @param arrayB : number[]
 */
export function isEqualIgnoreOrder(arrayA: number[], arrayB: number[]): boolean {
    return isEqual(sortBy(arrayA), sortBy(arrayB));
}

/**
 * Handles reordering a list when an item moves from the startIndex to the endIndex
 * @param list array to be reordered
 * @param startIndex previous index of item to be moved
 * @param endIndex new index of item to be moved
 * @example (["Item 1", "Item 2", "Item 3"], 0, 2) returns ["Item 2", "Item 3", "Item 1"]
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

/**
 * Shuffles the order of the array
 * @param array
 * @returns the shuffled array
 */
export function shuffle<T>(array: T[]): T[] {
    return array.sort(() => Math.random() - 0.5);
}

/**
 * This will use JSON stringify to do a deep comparison of 2 arrays. This is necessary for large arrays with complex types.
 * isEqual doesn't work on arrays that aren't primitive types. The next suggestion is to use !isEmpty(xorWith(array1, array2, isEqual))), but this is *very very* slow on large arrays.
 * Order is taken into account
 * @param array1 first array to compare
 * @param array2 second array to compare
 */
export function arrayIsEqualDeep<T>(array1: T[] | undefined, array2: T[] | undefined) {
    if (array1 === undefined && array2 === undefined) {
        return true;
    }
    if (array1 === undefined || array2 === undefined) {
        return false;
    }
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export class BTSorting {
    // This method was adapted/taken from
    // http://stackoverflow.com/questions/3221289/javascript-sorting-to-match-sql-server-sorting
    public static sortBySQL_Latin1_General_CP1_CS_AS = (sA: string, sB: string) => {
        /* --- This Sorts Latin1 and extended Latin1 unicode with an approximation
                of SQL's SQL_Latin1_General_CP1_CS_AS collation.
                Certain modifying characters or contractions my be off (not tested), we trade-off
                perfect accuracy for speed and relative simplicity.

                True unicode sorting is devilishly complex and we're not getting paid enough to
                fully implement it in Javascript.  ;-)

                It looks like a definative sort would require painstaking exegesis of documents
                such as: http://unicode.org/reports/tr10/
            */
        // --- This is the master lookup table for Latin1 code-points.  Here through the extended set \u02AF
        let sortOrder = [
            -1, 151, 152, 153, 154, 155, 156, 157, 158, 2, 3, 4, 5, 6, 159, 160, 161, 162, 163, 164,
            165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 0, 7, 8, 9, 10, 11, 12, 210,
            13, 14, 15, 41, 16, 211, 17, 18, 65, 69, 71, 74, 76, 77, 80, 81, 82, 83, 19, 20, 42, 43,
            44, 21, 22, 214, 257, 266, 284, 308, 347, 352, 376, 387, 419, 427, 438, 459, 466, 486,
            529, 534, 538, 559, 576, 595, 636, 641, 647, 650, 661, 23, 24, 25, 26, 27, 28, 213, 255,
            265, 283, 307, 346, 350, 374, 385, 418, 426, 436, 458, 464, 485, 528, 533, 536, 558,
            575, 594, 635, 640, 646, 648, 660, 29, 30, 31, 32, 177, 178, 179, 180, 181, 182, 183,
            184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200,
            201, 202, 203, 204, 205, 206, 207, 208, 209, 1, 33, 53, 54, 55, 56, 34, 57, 35, 58, 215,
            46, 59, 212, 60, 36, 61, 45, 72, 75, 37, 62, 63, 64, 38, 70, 487, 47, 66, 67, 68, 39,
            219, 217, 221, 231, 223, 233, 250, 276, 312, 310, 316, 318, 392, 390, 395, 397, 295,
            472, 491, 489, 493, 503, 495, 48, 511, 599, 597, 601, 603, 652, 590, 573, 218, 216, 220,
            230, 222, 232, 249, 275, 311, 309, 315, 317, 391, 389, 394, 396, 294, 471, 490, 488,
            492, 502, 494, 49, 510, 598, 596, 600, 602, 651, 589, 655, 229, 228, 227, 226, 235, 234,
            268, 267, 272, 271, 270, 269, 274, 273, 286, 285, 290, 287, 324, 323, 322, 321, 314,
            313, 326, 325, 320, 319, 358, 357, 362, 361, 356, 355, 364, 363, 378, 377, 380, 379,
            405, 404, 403, 402, 401, 400, 407, 406, 393, 388, 417, 416, 421, 420, 432, 431, 428,
            440, 439, 447, 446, 444, 443, 442, 441, 450, 449, 468, 467, 474, 473, 470, 469, 477,
            484, 483, 501, 500, 499, 498, 507, 506, 527, 526, 540, 539, 544, 543, 542, 541, 561,
            560, 563, 562, 567, 566, 565, 564, 580, 579, 578, 577, 593, 592, 611, 610, 609, 608,
            607, 606, 613, 612, 617, 616, 615, 614, 643, 642, 654, 653, 656, 663, 662, 665, 664,
            667, 666, 574, 258, 260, 262, 261, 264, 263, 281, 278, 277, 304, 292, 289, 288, 297,
            335, 337, 332, 348, 349, 369, 371, 382, 415, 409, 434, 433, 448, 451, 462, 476, 479,
            509, 521, 520, 524, 523, 531, 530, 552, 572, 571, 569, 570, 583, 582, 581, 585, 632,
            631, 634, 638, 658, 657, 669, 668, 673, 677, 676, 678, 73, 79, 78, 680, 644, 50, 51, 52,
            40, 303, 302, 301, 457, 456, 455, 482, 481, 480, 225, 224, 399, 398, 497, 496, 605, 604,
            626, 625, 620, 619, 624, 623, 622, 621, 334, 241, 240, 237, 236, 254, 253, 366, 365,
            360, 359, 430, 429, 505, 504, 515, 514, 675, 674, 422, 300, 299, 298, 354, 353, 84, 85,
            86, 87, 239, 238, 252, 251, 513, 512, 243, 242, 245, 244, 328, 327, 330, 329, 411, 410,
            413, 412, 517, 516, 519, 518, 547, 546, 549, 548, 628, 627, 630, 629, 88, 89, 90, 91,
            92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
            111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
            128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 246,
            247, 248, 259, 279, 280, 293, 291, 339, 336, 338, 331, 340, 341, 342, 423, 367, 373,
            351, 370, 372, 383, 381, 384, 408, 414, 386, 445, 453, 452, 454, 461, 463, 460, 475,
            478, 465, 508, 522, 525, 532, 550, 553, 554, 555, 545, 556, 557, 537, 551, 568, 333,
            424, 343, 344, 586, 584, 618, 633, 637, 639, 645, 659, 649, 670, 671, 672, 679, 681,
            682, 683, 282, 686, 256, 345, 368, 375, 425, 435, 437, 535, 684, 685, 305, 296, 306,
            591, 587, 588, 144, 145, 146, 147, 148, 149, 150,
        ];

        let lenA = sA.length,
            lenB = sB.length;
        let jA = 0,
            jB = 0;
        let ignoreBuffA: number[] = [],
            ignoreBuffB: number[] = [];

        function ignoreForPrimarySort(charCode: number) {
            /* --- A bunch of characters get ignored for the primary sort weight.
                The most important ones are the hyphen and apostrophe characters.
                A bunch of control characters and a couple of odds and ends, make up
                the rest.
            */
            if (charCode < 9) return true;

            if (charCode >= 14 && charCode <= 31) return true;

            if (charCode >= 127 && charCode <= 159) return true;

            if (charCode === 39 || charCode === 45 || charCode === 173) return true;

            return false;
        }

        function sortIgnoreBuff() {
            let igLenA = ignoreBuffA.length,
                igLenB = ignoreBuffB.length;
            let kA = 0,
                kB = 0;

            while (kA < igLenA && kB < igLenB) {
                let igA = ignoreBuffA[kA++],
                    igB = ignoreBuffB[kB++];

                if (sortOrder[igA] > sortOrder[igB]) return 1;
                if (sortOrder[igA] < sortOrder[igB]) return -1;
            }
            // --- All else equal, longest string loses
            if (igLenA > igLenB) return 1;
            if (igLenA < igLenB) return -1;

            return 0;
        }

        while (jA < lenA && jB < lenB) {
            let cA = sA.charCodeAt(jA++);
            let cB = sB.charCodeAt(jB++);

            if (cA === cB) {
                continue;
            }

            while (ignoreForPrimarySort(cA)) {
                ignoreBuffA.push(cA);
                if (jA < lenA) cA = sA.charCodeAt(jA++);
                else break;
            }
            while (ignoreForPrimarySort(cB)) {
                ignoreBuffB.push(cB);
                if (jB < lenB) cB = sB.charCodeAt(jB++);
                else break;
            }

            /* --- Have we reached the end of one or both strings, ending on an ignore char?
                The strings were equal, up to that point.
                If one of the strings is NOT an ignore char, while the other is, it wins.
            */
            if (ignoreForPrimarySort(cA)) {
                if (!ignoreForPrimarySort(cB)) return -1;
            } else if (ignoreForPrimarySort(cB)) {
                return 1;
            } else {
                if (sortOrder[cA] > sortOrder[cB]) return 1;

                if (sortOrder[cA] < sortOrder[cB]) return -1;

                // --- We are equal, so far, on the main chars.  Were there ignore chars?
                let iBuffSort = sortIgnoreBuff();
                if (iBuffSort) {
                    return iBuffSort;
                }

                // --- Still here?  Reset the ignore arrays.
                ignoreBuffA = [];
                ignoreBuffB = [];
            }
        }

        /* --- We have gone through all of at least one string and they are still both
            equal barring ignore chars or unequal lengths.
        */
        let iBuffSort2 = sortIgnoreBuff();
        if (iBuffSort2) {
            return iBuffSort2;
        }

        // --- All else equal, longest string loses
        if (lenA > lenB) return 1;
        if (lenA < lenB) return -1;

        return 0;
    };
}

/**
 * This replaces each item in the original array with the supplied item where the ids are the same
 * @param arr The original data
 * @param item The item to replace
 */
export const replaceItemById = <T>(arr: (T & IBaseEntity)[], item: T & IBaseEntity) => {
    return arr.map((li) => {
        if (li.id === item.id) {
            return item;
        }
        return li;
    });
};

/**
 * This filters on the isSelected prop with additional error handling for convenience
 * @param arr Array of selectable items
 */
export const getSelectedItems = <T>(arr: (T & IIsSelectable)[] | undefined | null) => {
    if (!arr) {
        return [];
    }
    return arr.filter((a) => a.isSelected);
};

/**
 * Helper function to help determine the checked status for a list of items
 * @param arr Array of items to check for selected status
 * @param isCheckedFunc Callback function that determines if an item is selected
 */
export const getCheckAllState = <T>(
    arr: T[],
    isCheckedFunc: (value: T, index: number, array: T[]) => boolean
) => {
    if (arr.some(isCheckedFunc)) {
        if (arr.every(isCheckedFunc)) {
            return CheckAllState.AllChecked;
        }
        return CheckAllState.Indeterminate;
    }
    return CheckAllState.None;
};

export function add<T>(collection: T[], newEntity: T, index?: number): T[] {
    if (index === undefined) {
        return [...collection, newEntity];
    } else {
        const result = [...collection];
        result.splice(index, 0, newEntity);
        return result;
    }
}

export function replaceAt<T>(collection: T[], updatedEntity: T, index: number): T[] {
    const result = [...collection];
    result.splice(index, 1, updatedEntity);
    return result;
}

export function replace<T extends object>(
    collection: T[],
    updatedEntity: T,
    idField: keyof T
): T[] {
    const index = collection.findIndex((v) => v[idField] === updatedEntity[idField]);
    if (index >= 0) {
        return replaceAt(collection, updatedEntity, index);
    }
    return collection;
}

export function removeAt<T>(collection: T[], index: number): T[] {
    let result = collection;
    if (Math.abs(index) < result.length) {
        result = [...collection];
        result.splice(index, 1);
    }
    return result;
}
export function remove<T extends object>(collection: T[], entity: T, idField: keyof T): T[] {
    const index = collection.findIndex((v) => v[idField] === entity[idField]);
    if (index >= 0) {
        return removeAt(collection, index);
    }
    return collection;
}

export function findFirstIfArray<T>(collection: T[] | T) {
    return Array.isArray(collection) ? collection[0] : collection;
}

export function except<T>(collection: T[], exceptions: T[]) {
    return collection.filter((c) => !exceptions.includes(c));
}

/**
 * Identity function for the array with output typed as a tuple
 * @param elements array to retype
 */
export function asTuple<T extends unknown[]>(...elements: T) {
    return elements;
}

/**
 * Given an array that contains falsy values, filter out all values that are falsy (false, 0, etc.)
 * @param array Array that may contain falsy values
 * @returns an array with all falsy values ommitted
 */
export function filterOutFalsy<T>(array: (T | Falsy)[]) {
    return array.filter(isTruthy);
}

/**
 * Takes two sorted arrays and merges them into a single sorted array given a comparator function.
 * This has performance benefits over using Array.concat and Array.sort.
 *
 * @param sortedArray1 The first sorted array
 * @param sortedArray2 The second sorted array
 * @param comparator A function that compares two items and returns a number indicating their relative order
 * @returns A sorted array containing all items from the two input arrays
 */
export function mergeSortedArrays<T>(
    sortedArray1: T[],
    sortedArray2: T[],
    comparator: (item1: T, item2: T) => number
) {
    if (sortedArray1.length === 0) {
        return sortedArray2;
    }

    if (sortedArray2.length === 0) {
        return sortedArray1;
    }

    let index1 = 0;
    let index2 = 0;
    const mergedArray: T[] = [];

    while (mergedArray.length < sortedArray1.length + sortedArray2.length) {
        if (index1 >= sortedArray1.length) return mergedArray.concat(sortedArray2.slice(index2));
        if (index2 >= sortedArray2.length) return mergedArray.concat(sortedArray1.slice(index1));

        const item1 = sortedArray1[index1];
        const item2 = sortedArray2[index2];

        if (comparator(item1, item2) <= 0) {
            mergedArray.push(item1);
            index1++;
        } else {
            mergedArray.push(item2);
            index2++;
        }
    }

    return mergedArray;
}
