import { RouteComponentProps } from "react-router";

import "./NotificationPanel.less";

interface INotificationPanelPresentationalProps extends RouteComponentProps {
    hasNotifications: boolean;
}

// todo implement v2
function NotificationPanelPresentational(_props: INotificationPanelPresentationalProps) {
    return null;
}

export default NotificationPanelPresentational;
