import { useContext } from "react";
import { NumericFormat } from "react-number-format";

import { CurrencyLocale } from "helpers/AppProvider.types";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { round } from "utilities/math/math";

import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

interface INumberDisplayProps {
    value: number;

    /** @default 2 */
    decimalScale?: number;

    /** @default true If true it won't remove extraneous zeros, i.e. 1 will display as 1.00 */
    fixedDecimalScale?: boolean;

    /** Number will not reflect builder culture and instead show in US culture */
    forceUSCulture?: boolean;

    "data-testid"?: string;

    /** @default undefined */
    suffix?: string;

    shouldTruncate?: boolean;
}

/**
 * Displays a formatted number as text, use whenever you want to show a number for display only
 */
export const NumberDisplay: React.FunctionComponent<INumberDisplayProps> = ({
    value,
    "data-testid": dataTestId,
    suffix,
    forceUSCulture,
    decimalScale = 2,
    fixedDecimalScale = true,
    shouldTruncate,
}) => {
    const builderInfo = useContext(BuilderInfoContext);
    const builderCurrency =
        forceUSCulture || !builderInfo ? CurrencyLocale.default : builderInfo.locale.currencyLocale;

    const roundedValue = round(value, decimalScale);

    const displayValue = (
        <NumericFormat
            value={roundedValue}
            displayType="text"
            decimalScale={decimalScale!}
            fixedDecimalScale={fixedDecimalScale}
            thousandSeparator={builderCurrency.groupSeparator}
            decimalSeparator={builderCurrency.decimalSeparator}
            thousandsGroupStyle={builderCurrency.thousandsGroupStyle}
            suffix={suffix}
            dir="ltr"
            data-testid={dataTestId}
        />
    );
    if (shouldTruncate) {
        return <TextTruncate>{displayValue}</TextTruncate>;
    }

    return displayValue;
};
