import BTAdminLayout from "layouts/BTAdminLayout";
import { ServerErrorBoundary } from "layouts/errors/ServerErrorBoundary";
import WebviewPageLayout from "layouts/WebviewPageLayout";
import { isEqual } from "lodash-es";
import moment from "moment";
import queryString from "query-string";
import { Component, useContext, useEffect, useState } from "react";
import { Route, StaticContext } from "react-router";
import { BrowserRouter, RouteComponentProps, Switch } from "react-router-dom";

import { BdsGlobalProvider } from "@buildertrend/components";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import EntityLinkRouter from "helpers/routing/EntityLinkRouter/EntityLinkRouter";

import { BTLocalStorage, BTSessionStorage } from "types/btStorage";

import {
    dispatchTracking,
    ITrackingProp,
    processTracking,
    track,
} from "utilities/analytics/analytics";
import { externalPages } from "utilities/externalPages";
import { getGlobalValue } from "utilities/globalValueUtils";
import { ListEntityType } from "utilities/list/list.types";
import { isInPortal } from "utilities/portal/portal";
import { lazyLoadWithRetry } from "utilities/react.utilities";
import { ExternalRoute } from "utilities/routes/ExternalRoute";
import { routesWebforms } from "utilities/routesWebforms";
import { parseParams } from "utilities/url/url";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { IDTypes } from "commonComponents/entity/accounting/common/accounting.api.types";
import { IPaymentMethodRedirectDetails } from "commonComponents/financial/PayOnline/NewBankAccount/NewBankAccount.api.types";
import {
    JobPickerDisplayModes,
    JobPickerSelectModes,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import { JobPickerRouter } from "commonComponents/utilities/JobPicker/JobPickerRouter";
import { MultiFactorAuthModal } from "commonComponents/utilities/MultiFactorAuthModal/multiFactorAuthModal/MultiFactorAuthModal";
import PageTitle from "commonComponents/utilities/PageTitle/PageTitle";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { getSystemFilter } from "commonComponents/utilities/SystemFilters/SystemFilterUtils";

import { CommentListTabKeys } from "entity/comment/CommentList/CommentList.api.types";
import { CostCatalogViewType } from "entity/costCatalog/CostCatalog.types";
import { IDepositPrintParams } from "entity/deposit/DepositPrint/DepositPrint.api.types";
import { IEstimateWebviewRouteParams } from "entity/estimate/Estimate/EstimateWebviewContainer";
import { ProposalHandler } from "entity/estimate/Proposal/Proposal.api.handler";
import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";
import { JobListTabs } from "entity/job/JobList/JobList.api.types";
import { LeadProposalHandler } from "entity/leadProposal/LeadProposal/LeadProposal.api.handler";
import FinalizeCreateAccount from "entity/LinkAccountActivation/CreateAccount/FinalizeCreateAccount";
// Lazy loading handled internally
import { ISharedMediaRouteProps } from "entity/media/common/mediaTypes";
import { getMessageListPropsFromRoute } from "entity/message/MessageList/MessageList";
import { OnlinePaymentTabs } from "entity/onlinePayment/common/OnlinePaymentReports.types";
import PasswordReset from "entity/passwordReset/PasswordReset/PasswordReset";
import { IDocInstanceAndJobId } from "entity/photo/PhotoPreview/PhotoPreview.api.types";
import { parsePurchaseOrderMappingQueryStringParams } from "entity/purchaseOrder/PoMapping/PoMappingUtilities";
import { ScheduleEntityDefaultParams } from "entity/schedule/Schedule/Schedule.api.types";
import { ScheduleListContainerTabs } from "entity/schedule/ScheduleListContainer/ScheduleListContainer.api.types";
import { SelectionListContainerTabs } from "entity/selection/SelectionListContainer/SelectionListContainer.types";
import RedirectToSetupSummary from "entity/setup/SetupSummary/RedirectToSetupSummary";
import { LeadSourceValues } from "entity/SignupForm/SignupForm.api.types";
import { SubSetupTabKeys } from "entity/sub/SubSetup/SubSetup.api.types";
import { SubTabKeys } from "entity/sub/SubVendor/SubVendor.api.types";
import { SurveyListContainerTabs } from "entity/survey/common/SurveyListContainer/SurveyListContainer.types";
import { TemplateListTabs } from "entity/template/TemplateList/TemplateListTab/TemplateListTab.api.types";
import { ShiftListContainerTabs } from "entity/timeClock/ShiftList/ShiftListContainer/ShiftListContainer.api.types";
import { VendorTypes } from "entity/vendor/common/vendor.types";

import { JobPage } from "./entity/job/Job/JobFullPage";
import { ToDoPrintDisplayOptions } from "./entity/toDo/print/ToDoPrint/ToDoPrint.api.types";
import { AppProvider } from "./helpers/AppProvider";
import { AppRouteChange } from "./helpers/AppRouteChange";
import { DetailPageLayout } from "./layouts/DetailPageLayout";
import { IListPageChildProps, ListPageLayout } from "./layouts/ListPageLayout";
import { PrintPageLayout } from "./layouts/PrintPageLayout";
import {
    BTLoginTypes,
    DirectionTypes,
    DirectoryType,
    EmailFolder,
    ForbiddenReason,
    MediaType,
    MenuItemName,
    OpenActivityType,
    OpenedFrom,
    POMappingEntityTypes,
    ReplyType,
    SelectionsDynamicGridColumnTypes,
    ViewingPermissionAction,
} from "./types/enum";
import { IWebviewRouteParams, routes, updateWebformsQueryStringParams } from "./utilities/routes";
import { stringToArray } from "./utilities/string/string";

/** These Components are lazy loaded so we only ship down parts of the app we need */
const ApiDocs = lazyLoadWithRetry(() => import("./apiDocs/ApiDocs"));
const OrgLinkNotConnectedEmptyState = lazyLoadWithRetry(
    () => import("./entity/OrgLinkNotConnectedEmptyState/OrgLinkNotConnectedEmptyState")
);

const TimeCardPage = lazyLoadWithRetry(
    () => import("./entity/timeClock/ShiftList/ShiftListContainer/ShiftListContainer")
);

const MarketplaceRedirect = lazyLoadWithRetry(
    () => import("./entity/marketplace/Redirect/MarketplaceRedirect")
);

const RFI = lazyLoadWithRetry(() => import("./entity/rfi/Rfi/Rfi"));
const PurchaseOrderPrint = lazyLoadWithRetry(
    () => import("./entity/purchaseOrder/PurchaseOrderPrint/PurchaseOrderPrint")
);
const Warranty = lazyLoadWithRetry(() => import("./entity/warranty/Warranty/Warranty"));
const LienWaiver = lazyLoadWithRetry(() => import("entity/lienWaiver/LienWaiver/LienWaiver"));
const LienWaiverPrint = lazyLoadWithRetry(
    () => import("entity/lienWaiver/LienWaiverPrint/LienWaiverPrint")
);
const LeadProposalExternal = lazyLoadWithRetry(
    () => import("entity/leadProposal/LeadProposalExternal/LeadProposalExternal")
);
const WarrantyPrint = lazyLoadWithRetry(
    () => import("./entity/warranty/print/WarrantyPrint/WarrantyPrint")
);
const QuestionReportPrint = lazyLoadWithRetry(
    () => import("./entity/survey/print/QuestionReportPrint/QuestionReportPrint")
);
const QuestionReport = lazyLoadWithRetry(
    () => import("./entity/survey/QuestionReport/QuestionReport")
);
const SelectionPrint = lazyLoadWithRetry(
    () => import("./entity/selection/SelectionPrint/SelectionPrint")
);
const SpecificationPrint = lazyLoadWithRetry(
    () => import("./entity/specification/SpecificationPrint/SpecificationPrint")
);
const AllowancePrint = lazyLoadWithRetry(
    () => import("./entity/allowance/AllowancePrint/AllowancePrint")
);
const SurveyPrint = lazyLoadWithRetry(
    () => import("./entity/survey/print/SurveyPrint/SurveyPrint")
);
const ToDoPrint = lazyLoadWithRetry(() => import("./entity/toDo/print/ToDoPrint/ToDoPrint"));
const BidPrint = lazyLoadWithRetry(() => import("./entity/bid/BidPrint/BidPrint"));
const ChangeOrderPrint = lazyLoadWithRetry(
    () => import("./entity/changeOrder/ChangeOrderPrint/ChangeOrderPrint")
);
const OwnerInvoice = lazyLoadWithRetry(
    () => import("./entity/ownerInvoice/OwnerInvoice/OwnerInvoice")
);
const SharedExternalOwnerInvoice = lazyLoadWithRetry(
    () => import("./entity/ownerInvoice/ExternalOwnerInvoice/SharedExternalOwnerInvoice")
);
const OnlinePaymentExternal = lazyLoadWithRetry(
    () => import("./entity/onlinePayment/OnlinePaymentExternal/OnlinePaymentExternal")
);

const OnlineInvoiceExternal = lazyLoadWithRetry(
    () => import("./entity/onlinePayment/OnlineInvoiceExternal/OnlineInvoiceExternal")
);
const OutboundPaymentsExternal = lazyLoadWithRetry(
    () => import("./entity/onlinePayment/OutboundPaymentsExternal/OutboundPaymentsExternal")
);
const DepositExternal = lazyLoadWithRetry(
    () => import("./entity/deposit/DepositExternal/DepositExternal")
);
const DepositPrint = lazyLoadWithRetry(() => import("./entity/deposit/DepositPrint/DepositPrint"));
const OwnerInvoicePrint = lazyLoadWithRetry(
    () => import("./entity/ownerInvoice/OwnerInvoicePrint/OwnerInvoicePrint")
);
const CostCodesExportToAccounting = lazyLoadWithRetry(
    () => import("./entity/costCode/CostCodeExportToAccounting/CostCodeExportToAccounting")
);
const CostCodesAccountingExportLegacyWrapper = lazyLoadWithRetry(
    () =>
        import(
            "./entity/costCode/CostCodeExportToAccounting/CostCodeExportToAccountingLegacyWrapper"
        )
);
const CostCodeLegacyWrapper = lazyLoadWithRetry(
    () => import("./entity/costCode/CostCodeDetails/CostCodeDetailsLegacyWrapper")
);
const BidPackagePrint = lazyLoadWithRetry(
    () => import("./entity/bidPackage/BidPackagePrint/BidPackagePrint")
);
const Lead = lazyLoadWithRetry(() => import("./entity/lead/Lead/Lead"));
const Auth0Redirect = lazyLoadWithRetry(
    () => import("./commonComponents/utilities/Auth0/Auth0Redirect")
);
const IntegrationsSettings = lazyLoadWithRetry(
    () => import("./entity/lead/IntegrationsSettings/IntegrationsSettings")
);
const Reconsent = lazyLoadWithRetry(() => import("./entity/marketplace/Reconsent/Reconsent"));
const LeadActivity = lazyLoadWithRetry(() => import("./entity/lead/LeadActivity/LeadActivity"));
const ChangeOrderOwnerRequest = lazyLoadWithRetry(
    () => import("./entity/changeOrder/ChangeOrderOwnerRequest/ChangeOrderOwnerRequest")
);
const PaymentRequest = lazyLoadWithRetry(
    () => import("./entity/sub/PaymentRequest/PaymentRequest")
);
const ClockInClockOut = lazyLoadWithRetry(
    () => import("./entity/timeClock/ClockInClockOut/ClockInClockOut")
);
const ImportWizard = lazyLoadWithRetry(
    () => import("./entity/importWizard/ImportWizard/ImportWizard")
);
const CreditMemo = lazyLoadWithRetry(() => import("entity/creditMemo/CreditMemo/CreditMemo"));

const SurveySettings = lazyLoadWithRetry(
    () => import("./entity/survey/SurveySettings/SurveySettings")
);
const Survey = lazyLoadWithRetry(() => import("./entity/survey/Survey/Survey"));
const Notification = lazyLoadWithRetry(
    () => import("./entity/message/NotificationDisplay/NotificationDisplay")
);
const MessageCompose = lazyLoadWithRetry(
    () => import("./entity/message/MessageCompose/MessageCompose")
);
const MediaMoveFolder = lazyLoadWithRetry(
    () => import("./entity/media/MediaMoveFolder/MediaMoveFolder")
);
const MessagePrint = lazyLoadWithRetry(() => import("./entity/message/Message/MessagePrint"));
const DailyLog = lazyLoadWithRetry(() => import("./entity/dailyLog/DailyLog/DailyLog"));
const SubDivision = lazyLoadWithRetry(() => import("./entity/sub/SubDivision/SubDivision"));
const SubVendor = lazyLoadWithRetry(() => import("./entity/sub/SubVendor/SubVendor"));
const ToDoAssign = lazyLoadWithRetry(() => import("./entity/toDo/ToDoAssign/ToDoAssign"));
const ChangeOrderPayment = lazyLoadWithRetry(
    () => import("./entity/changeOrder/ChangeOrderPayment/ChangeOrderPayment")
);
const ChangeOrderApprovalSuccess = lazyLoadWithRetry(
    () => import("./entity/changeOrder/ChangeOrderApprovalSuccess/ChangeOrderApprovalSuccess")
);
const Schedule = lazyLoadWithRetry(() => import("./entity/schedule/Schedule/Schedule"));
const EstimateBidMapping = lazyLoadWithRetry(
    () => import("./entity/estimate/EstimateBidMapping/EstimateBidMapping")
);
const ToDo = lazyLoadWithRetry(() => import("./entity/toDo/details/ToDoDetails/ToDo"));
const Contact = lazyLoadWithRetry(() => import("./entity/contact/Contact/Contact"));
const LinkJobToAccounting = lazyLoadWithRetry(
    () => import("./entity/accounting/LinkJobToAccounting/LinkJobToAccounting")
);
const LinkEntityToAccounting = lazyLoadWithRetry(
    () =>
        import("./commonComponents/entity/accounting/LinkEntityToAccounting/LinkEntityToAccounting")
);
const BidPackage = lazyLoadWithRetry(() => import("./entity/bidPackage/BidPackage/BidPackage"));
const Allowance = lazyLoadWithRetry(() => import("./entity/allowance/Allowance/Allowance"));
const DependentSelections = lazyLoadWithRetry(
    () => import("./entity/selectionChoice/common/DependentSelections/DependentSelections")
);
const JobViewingPermissionWizardParent = lazyLoadWithRetry(
    () =>
        import(
            "./commonComponents/entity/permissionWizard/JobViewingPermissionWizardParent/JobViewingPermissionWizardParent"
        )
);
const SelectionChoice = lazyLoadWithRetry(
    () => import("./entity/selectionChoice/SelectionChoice/SelectionChoice")
);
const Selection = lazyLoadWithRetry(() => import("./entity/selection/Selection/Selection"));
const CopyChoiceFromSelection = lazyLoadWithRetry(
    () => import("./entity/selectionChoice/common/CopyChoiceFromSelection/CopyChoiceFromSelection")
);
const CopyMedia = lazyLoadWithRetry(() => import("./entity/media/CopyMedia/CopyMedia"));
const ChangeOrderPOMapping = lazyLoadWithRetry(
    () => import("entity/changeOrder/ChangeOrderPOMapping/ChangeOrderPOMapping")
);
const BidPOMapping = lazyLoadWithRetry(() => import("entity/bid/BidPOMapping/BidPOMapping"));
const EstimatePOMapping = lazyLoadWithRetry(
    () => import("entity/estimate/EstimatePOMapping/EstimatePOMapping")
);
const SelectionPOMapping = lazyLoadWithRetry(
    () => import("entity/selection/SelectionPOMapping/SelectionPOMapping")
);
const JobPrint = lazyLoadWithRetry(() => import("./entity/job/JobPrint/JobPrint"));
const JobFromTemplate = lazyLoadWithRetry(
    () => import("./entity/job/JobFromTemplate/JobFromTemplate")
);
const JobPriceSummary = lazyLoadWithRetry(
    () => import("./entity/job/JobPriceSummary/JobPriceSummary")
);
const JobPriceSummaryPrint = lazyLoadWithRetry(
    () => import("./entity/job/JobPriceSummaryPrint/JobPriceSummaryPrint")
);
const Shift = lazyLoadWithRetry(() => import("./entity/timeClock/Shift/Shift"));
const BidRequest = lazyLoadWithRetry(() => import("./entity/bid/Bid/Bid"));
const BuilderRiskInsuranceSettings = lazyLoadWithRetry(
    () =>
        import(
            "./entity/builderRiskInsurance/BuilderRiskInsuranceStatus/BuilderRiskInsuranceSettings"
        )
);
const NoAccess = lazyLoadWithRetry(() => import("./commonComponents/error/NoAccess/NoAccess"));
const RelatedRfi = lazyLoadWithRetry(() => import("./entity/rfi/RelatedRfi/RelatedRfi"));
const ConversationWithEntityPrompt = lazyLoadWithRetry(
    () => import("./entity/conversation/Conversation/ConversationEntityPrompt")
);
const SignupForm = lazyLoadWithRetry(() => import("./entity/SignupForm/SignupForm"));
const UnreadDocument = lazyLoadWithRetry(
    () => import("./entity/document/UnreadDocument/UnreadDocument")
);
const TemplateImport = lazyLoadWithRetry(
    () => import("./entity/templateImport/TemplateImport/TemplateImport")
);
const Template = lazyLoadWithRetry(() => import("./entity/template/Template/Template"));
const TemplateFromTemplate = lazyLoadWithRetry(
    () => import("./entity/template/TemplateFromTemplate/TemplateFromTemplate")
);
const LeadPrint = lazyLoadWithRetry(() => import("./entity/lead/LeadPrint/LeadPrint"));
const DailyLogPrint = lazyLoadWithRetry(
    () => import("entity/dailyLog/DailyLogPrint/DailyLogPrint")
);
const ContactPrint = lazyLoadWithRetry(() => import("entity/contact/ContactPrint/ContactPrint"));
const InternalUserQuickAdd = lazyLoadWithRetry(
    () => import("entity/internalUser/InternalUserQuickAdd/InternalUserQuickAdd")
);
const InternalUserRoleListDev = lazyLoadWithRetry(
    () =>
        import(
            "entity/internalUser/InternalUserRoleSettings/InternalUserRoleList/InternalUserRoleList"
        )
);
const InternalUserRoleDetailsDev = lazyLoadWithRetry(
    () => import("entity/internalUser/InternalUserRoleDetails/InternalUserRoleDetails")
);
const InternalUsersList = lazyLoadWithRetry(
    () => import("entity/internalUser/InternalUserList/InternalUserList/InternalUserList")
);
const ContactsList = lazyLoadWithRetry(() => import("entity/contact/ContactList/ContactList"));
const SubList = lazyLoadWithRetry(() => import("entity/sub/SubList/SubList"));
const SubscriptionManager = lazyLoadWithRetry(
    () => import("entity/SubscriptionManager/SubscriptionManager")
);
const SubscriptionList = lazyLoadWithRetry(() => import("entity/Subscription/SubscriptionList"));
const CreditMemoPrint = lazyLoadWithRetry(
    () => import("entity/creditMemo/CreditMemoPrint/CreditMemoPrint")
);
const PurchaseOrderList = lazyLoadWithRetry(
    () => import("entity/purchaseOrder/PurchaseOrderList/PurchaseOrderList")
);
const CreditMemosList = lazyLoadWithRetry(
    () => import("./entity/creditMemo/CreditMemoList/CreditMemoList")
);
const PurchaseOrder = lazyLoadWithRetry(
    () => import("entity/purchaseOrder/PurchaseOrder/PurchaseOrder")
);
const CostCodeImportFromAccounting = lazyLoadWithRetry(
    () => import("entity/costCode/CostCodeImportFromAccounting/CostCodeImportFromAccounting")
);
const ChangeOrder = lazyLoadWithRetry(() => import("entity/changeOrder/ChangeOrder/ChangeOrder"));
const SubBill = lazyLoadWithRetry(() => import("entity/bill/SubBill/SubBill"));
const Bill = lazyLoadWithRetry(() => import("entity/bill/Bill/Bill"));
const BillPrint = lazyLoadWithRetry(() => import("entity/bill/Bill/BillPrint/BillPrint"));
const BillList = lazyLoadWithRetry(() => import("entity/bill/BillList/BillList"));
const MediaViewingPermissions = lazyLoadWithRetry(
    () => import("entity/media/MediaViewingPermissions/MediaViewingPermissions")
);
const MediaFolderProperties = lazyLoadWithRetry(
    () => import("entity/media/MediaFolderProperties/MediaFolderProperties")
);
const PhotoPreview = lazyLoadWithRetry(() => import("entity/photo/PhotoPreview/PhotoPreview"));
const Document = lazyLoadWithRetry(() => import("entity/document/Document/Document"));
const Photo = lazyLoadWithRetry(() => import("entity/photo/Photo/Photo"));
const RFIPrint = lazyLoadWithRetry(() => import("./entity/rfi/RfiPrint/RfiPrint"));
const BrandingSettings = lazyLoadWithRetry(
    () => import("./entity/branding/BuilderAccountBranding/BuilderAccountBranding")
);

const MediaFolderEmailInfo = lazyLoadWithRetry(
    () => import("entity/media/MediaFolderEmailInfo/MediaFolderEmailInfo")
);
const ScheduleConflictReport = lazyLoadWithRetry(
    () =>
        import(
            "commonComponents/entity/scheduleConflicts/ScheduleConflictReport/ScheduleConflictReport"
        )
);
const OwnerPortalRedirect = lazyLoadWithRetry(
    () => import("commonComponents/helpers/OwnerPortalRedirect/OwnerPortalRedirect")
);
const AdminPaymentsPrescreening = lazyLoadWithRetry(
    () => import("./entity/onlinePayment/PaymentsPrescreening/PaymentsPrescreening")
);
const ExcludeFromChurn = lazyLoadWithRetry(
    () => import("./entity/excludeFromChurn/ExcludeFromChurn/ExcludeFromChurn")
);
const MediaReplace = lazyLoadWithRetry(() => import("entity/media/MediaReplace/MediaReplace"));
const AccountingSettings = lazyLoadWithRetry(
    () => import("./entity/accounting/AccountingSettings/AccountingSettings")
);

const AuthRedirect = lazyLoadWithRetry(() => import("./entity/oauth/AuthRedirect/AuthRedirect"));
const QuickBooksSyncSchedule = lazyLoadWithRetry(
    () =>
        import(
            "./entity/accounting/AccountingSettings/QuickBooksSyncSchedule/QuickBooksSyncSchedule"
        )
);
const OAuthRedirect = lazyLoadWithRetry(
    () => import("./entity/accounting/AccountingSettings/OAuthRedirect")
);
const BidExternal = lazyLoadWithRetry(() => import("entity/bid/BidExternal/BidExternal"));
const PurchaseOrderExternal = lazyLoadWithRetry(
    () => import("entity/purchaseOrder/PurchaseOrderExternal/PurchaseOrderExternal")
);
const ReceiptList = lazyLoadWithRetry(() => import("./entity/receipt/ReceiptList/ReceiptList"));
const ReceiptsUpload = lazyLoadWithRetry(
    () => import("./entity/receipt/ReceiptUpload/ReceiptUpload")
);
const ProposalPdfGeneration = lazyLoadWithRetry(
    () => import("./entity/estimate/Proposal/ProposalPdfGeneration")
);
const LeadProposalPdfGeneration = lazyLoadWithRetry(
    () =>
        import(
            "./entity/leadProposal/LeadProposal/DeprecatedLeadProposalFiles/LeadProposalPdfGeneration"
        )
);
const Video = lazyLoadWithRetry(() => import("entity/video/Video/Video"));
const OwnerSurvey = lazyLoadWithRetry(() => import("entity/survey/OwnerSurvey/OwnerSurvey"));
const CostCatalog = lazyLoadWithRetry(() => import("entity/costCatalog/CostCatalog"));
const CostGroup = lazyLoadWithRetry(() => import("entity/CostGroup/CostGroup"));
const AdminAccountChangeRequest = lazyLoadWithRetry(
    () => import("entity/AdminAccountChangeRequest/AdminAccountChangeRequest")
);
const BuilderAccountChangeRequest = lazyLoadWithRetry(
    () =>
        import(
            "entity/builderAccountChangeRequest/BuilderAccountChangeRequest/BuilderAccountChangeRequest"
        )
);
const AdminCancellation = lazyLoadWithRetry(
    () => import("entity/AdminCancellation/AdminCancellation")
);
const CostItem = lazyLoadWithRetry(() => import("entity/costItem/CostItem/CostItem"));
const CostCodeItemUsage = lazyLoadWithRetry(
    () => import("entity/costCode/CostCodeItemUsage/CostCodeItemUsage")
);
const ProposalPrint = lazyLoadWithRetry(
    () => import("entity/estimate/ProposalPrint/ProposalPrint")
);
const LeadProposalPrint = lazyLoadWithRetry(
    () => import("entity/leadProposal/LeadProposalPrint/LeadProposalPrint")
);
const MobileLeadProposalPreview = lazyLoadWithRetry(
    () => import("entity/leadProposal/MobileLeadProposalPreview/MobileLeadProposalPreview")
);
const LeadProposal = lazyLoadWithRetry(
    () => import("entity/leadProposal/LeadProposal/LeadProposal")
);
const InternalUser = lazyLoadWithRetry(
    () => import("entity/internalUser/InternalUserDetails/InternalUser")
);
const HomeDepotSettings = lazyLoadWithRetry(
    () => import("entity/vendor/HomeDepotSettings/HomeDepotSettings")
);
const LeadEmailWizard = lazyLoadWithRetry(
    () => import("entity/lead/LeadEmailWizard/LeadEmailWizard")
);
const VendorStoreFinder = lazyLoadWithRetry(
    () => import("entity/vendor/VendorStoreFinder/VendorStoreFinder")
);

const TradeAgreement = lazyLoadWithRetry(
    () => import("./entity/tradeAgreement/TradeAgreement/TradeAgreement")
);
const NotFoundError = lazyLoadWithRetry(() => import("./layouts/errors/NotFoundError"));
const ServerError = lazyLoadWithRetry(() => import("./layouts/errors/ServerError"));
const TradeAgreementPrint = lazyLoadWithRetry(
    () => import("./entity/tradeAgreement/TradeAgreementPrint/TradeAgreementPrint")
);
const SignupPriceCode = lazyLoadWithRetry(() => import("./entity/SignupPriceCode/SignupPriceCode"));
const SignupWhatsNext = lazyLoadWithRetry(
    () => import("./entity/SignupWhatsNext/SignupWhatsNext/SignupWhatsNext")
);
const ContactUs = lazyLoadWithRetry(() => import("./entity/ContactUs/ContactUs"));
const VendorReceipt = lazyLoadWithRetry(
    () => import("./entity/receipt/VendorReceipts/VendorReceipt")
);
const SubSetup = lazyLoadWithRetry(() => import("./entity/sub/SubSetup/SubSetup"));
const OwnerSetup = lazyLoadWithRetry(() => import("./entity/OwnerSetup/OwnerSetup"));
const PaymentServiceBankAccountExternal = lazyLoadWithRetry(
    () =>
        import(
            "commonComponents/financial/PaymentService/BankAccount/PaymentServiceBankAccountExternal"
        )
);
const OwnerInvoicePayOnline = lazyLoadWithRetry(
    () => import("./entity/ownerInvoice/OwnerInvoicePayOnline/OwnerInvoicePayOnline")
);
const LeadProposalPayOnlineMobile = lazyLoadWithRetry(
    () => import("./entity/leadProposal/LeadProposalPayOnline/LeadProposalPayOnlineMobile")
);
const SharedContent = lazyLoadWithRetry(
    () => import("./entity/sharedContent/SharedContent/SharedContent")
);
const LeadProposalPayOnlineWebforms = lazyLoadWithRetry(
    () => import("./entity/leadProposal/LeadProposalPayOnline/LeadProposalPayOnlineWebforms")
);
const MessageExternal = lazyLoadWithRetry(
    () => import("./entity/message/MessageExternal/MessageExternal")
);
const InviteExpiredPageWithHeader = lazyLoadWithRetry(
    () => import("./entity/UserActivationPage/InviteExpiredPageWithHeader")
);
const SignedInAndLinkedPage = lazyLoadWithRetry(
    () => import("./entity/LinkAccountActivation/LinkedAndSignedIn/SignedInLinked")
);
const AdminLogExceptionInSentry = lazyLoadWithRetry(
    () => import("./entity/AdminLogExceptionInSentry/AdminLogExceptionInSentry")
);

const LienWaiverReview = lazyLoadWithRetry(
    () => import("./entity/bill/LienWaiverReview/LienWaiverReview")
);
const LienWaiverReviewExternal = lazyLoadWithRetry(
    () => import("./entity/bill/LienWaiverReview/LienWaiverReviewExternal")
);

const Receipt = lazyLoadWithRetry(() => import("./entity/receipt/Receipt/Receipt"));
const SharedMedia = lazyLoadWithRetry(() => import("./entity/media/SharedMedia/SharedMedia"));
const PartnerListContainer = lazyLoadWithRetry(
    () => import("entity/integrationPartner/btadmin/PartnerList/PartnerList")
);
const Partner = lazyLoadWithRetry(
    () => import("entity/integrationPartner/btadmin/Partner/Partner")
);
const FeatureFlag = lazyLoadWithRetry(() => import("entity/featureFlag/FeatureFlag/FeatureFlag"));
const FeatureFlagListContainer = lazyLoadWithRetry(
    () => import("entity/featureFlag/FeatureFlagList/FeatureFlagList")
);
const SearchUtils = lazyLoadWithRetry(() => import("entity/searchUtils/SearchUtils"));
const SubscriptionSignUpCodesDetails = lazyLoadWithRetry(
    () => import("entity/Subscription/SubscriptionSignUpCodeDetails/SubscriptionSignUpCodeDetails")
);
const SubscriptionSignUpCodesGridContainer = lazyLoadWithRetry(
    () => import("entity/Subscription/SubscriptionSignUpCodesGrid/SubscriptionSignUpCodesList")
);
const SubscriptionItemSetup = lazyLoadWithRetry(
    () => import("entity/Subscription/SubscriptionItemSetupUtility/SubscriptionItemSetupUtility")
);
const SubscriptionPlanLimitsDetails = lazyLoadWithRetry(
    () => import("entity/Subscription/SubscriptionPlanLimits/SubscriptionPlanLimits")
);
const ScheduleNotificationPrompt = lazyLoadWithRetry(
    () => import("./commonComponents/entity/scheduleNotifications/ScheduleNotificationPrompt")
);
const ScheduleConfirmationExternal = lazyLoadWithRetry(
    () => import("entity/schedule/ScheduleConfirmationExternal/ScheduleConfirmationExternal")
);

const RebateList = lazyLoadWithRetry(() => import("entity/rebate/RebateList/RebateList"));

const RebateReceiptReviewList = lazyLoadWithRetry(
    () => import("entity/rebateReceiptReview/RebateReceiptReviewList/RebateReceiptReviewList")
);

const RebateHawkQueue = lazyLoadWithRetry(
    () => import("entity/rebateReceiptReview/RebateReceiptReviewQueue/RebateReceiptReviewQueue")
);

const RebateHawkQueueList = lazyLoadWithRetry(
    () => import("entity/rebateReceiptReview/Queues/RebateHawkQueueList")
);

const BuilderSetup = lazyLoadWithRetry(() => import("entity/builderSetup/BuilderSetup"));
const BuilderSetupSearch = lazyLoadWithRetry(
    () => import("entity/builderSetup/BuilderSetupSearch/BuilderSetupSearch")
);
const SetupSummary = lazyLoadWithRetry(() => import("entity/setup/SetupSummary/SetupSummary"));

// Lazy Load SPA List pages
const LandingPage = lazyLoadWithRetry(() => import("entity/landingPage/LandingPage/LandingPage"));

const FrozenBuilderWrapper = lazyLoadWithRetry(
    () => import("./entity/FrozenBuilder/FrozenBuilderWrapper")
);

const DailyLogList = lazyLoadWithRetry(() => import("./entity/dailyLog/DailyLogList/DailyLogList"));
const ToDoListWithPanel = lazyLoadWithRetry(
    () => import("./entity/toDo/ToDoList/ToDoListWithPanel")
);
const SpecificationList = lazyLoadWithRetry(
    () => import("./entity/specification/SpecificationList/SpecificationList")
);
const InvoicePaymentsList = lazyLoadWithRetry(
    () => import("./entity/invoicePayment/InvoicePaymentList/InvoicePaymentList")
);
const MessagesList = lazyLoadWithRetry(() => import("./entity/message/MessageList/MessageList"));
const DocumentList = lazyLoadWithRetry(() => import("./entity/document/DocumentList/DocumentList"));
const PhotoList = lazyLoadWithRetry(() => import("./entity/photo/PhotoList/PhotoList"));
const VideoList = lazyLoadWithRetry(() => import("./entity/video/VideoList/VideoList"));

const BidPackageList = lazyLoadWithRetry(
    () => import("./entity/bidPackage/BidPackageList/BidPackageList")
);
const BidList = lazyLoadWithRetry(() => import("entity/bid/BidList/BidList"));
const PaymentsOverview = lazyLoadWithRetry(
    () => import("./commonComponents/financial/PaymentsOverview/PaymentsOverview")
);
const Estimate = lazyLoadWithRetry(() => import("entity/estimate/Estimate/Worksheet"));
const EstimateWebviewContainer = lazyLoadWithRetry(
    () => import("entity/estimate/Estimate/EstimateWebviewContainer")
);
const OwnerProposal = lazyLoadWithRetry(
    () => import("entity/estimate/OwnerProposal/OwnerProposal")
);
const WarrantiesList = lazyLoadWithRetry(
    () => import("./entity/warranty/WarrantyListContainer/WarrantyListContainer")
);

const OwnerInvoiceList = lazyLoadWithRetry(
    () => import("./entity/ownerInvoice/OwnerInvoiceList/OwnerInvoiceList")
);

const JobsList = lazyLoadWithRetry(() => import("./entity/job/JobList/JobList"));
const JobsMapList = lazyLoadWithRetry(() => import("./entity/job/JobList/JobsMapList"));

const TemplateListLanding = lazyLoadWithRetry(
    () => import("./entity/template/TemplateList/TemplateListLanding")
);

const CommentsList = lazyLoadWithRetry(() => import("./entity/comment/CommentList/CommentList"));
const SurveyListContainer = lazyLoadWithRetry(
    () => import("entity/survey/common/SurveyListContainer/SurveyListContainer")
);
const OwnerSurveyList = lazyLoadWithRetry(
    () => import("entity/survey/OwnerSurveyList/OwnerSurveyList")
);
const ScheduleListContainer = lazyLoadWithRetry(
    () => import("./entity/schedule/ScheduleListContainer/ScheduleListContainer")
);

const NotificationHistoryList = lazyLoadWithRetry(
    () => import("./entity/notification/NotificationList/NotificationList")
);

const ChangeOrderList = lazyLoadWithRetry(
    () => import("./entity/changeOrder/ChangeOrderList/ChangeOrderList")
);
const OwnerChangeOrderList = lazyLoadWithRetry(
    () => import("./entity/changeOrder/OwnerChangeOrderList/OwnerChangeOrderList")
);
const RFIsList = lazyLoadWithRetry(() => import("./entity/rfi/RfiList/RfiList"));

const DepositList = lazyLoadWithRetry(() => import("entity/deposit/DepositList/DepositList"));
const SettingList = lazyLoadWithRetry(() => import("entity/setting/SettingList/SettingList"));
const BTEditorMobile = lazyLoadWithRetry(
    () => import("commonComponents/btWrappers/editor/BTEditorMobile/BTEditorMobile")
);
const OnlinePaymentSettings = lazyLoadWithRetry(
    () => import("entity/onlinePayment/OnlinePaymentSettings/OnlinePaymentSettings")
);
const InboundHostedOnboarding = lazyLoadWithRetry(
    () =>
        import(
            "commonComponents/financial/InboundHostedOnboardingWizard/InboundHostedOnboardingWizard"
        )
);
const PaymentTos = lazyLoadWithRetry(
    () => import("commonComponents/financial/PaymentTos/PaymentTos")
);

const ChatList = lazyLoadWithRetry(() => import("entity/chat/Chat"));
const ChatWebviewContainer = lazyLoadWithRetry(
    () => import("entity/chat/webview/ChatWebviewContainer")
);

const CommentThread = lazyLoadWithRetry(
    () => import("commonComponents/entity/comment/CommentContainer/CommentThread/CommentThread")
);

const StuckPaymentUtility = lazyLoadWithRetry(
    () => import("entity/onlinePayment/StuckPaymentUtility/StuckPaymentUtility")
);

const BRIUpdateUtility = lazyLoadWithRetry(
    () => import("entity/builderRiskInsurance/BRIUpdateUtility/BRIUpdateUtility")
);

const MobileLoginEmailUsernameEntry = lazyLoadWithRetry(
    () => import("entity/login/MobileLoginEmailUsernameEntry/MobileLoginEmailUsernameEntry")
);

const FinancialDocumentCollection = lazyLoadWithRetry(
    () => import("entity/onlinePayment/FinancialDocumentCollection/FinancialDocumentCollection")
);
const OwnerSummary = lazyLoadWithRetry(() => import("entity/OwnerSummary/OwnerSummary"));
const CostCodeList = lazyLoadWithRetry(() => import("entity/costCode/CostCodeList/CostCodeList"));

const JobCostingBudget = lazyLoadWithRetry(
    () => import("entity/budget/JobCostingBudget/JobCostingBudget")
);
const AdminOnlinePaymentReports = lazyLoadWithRetry(
    () => import("entity/onlinePayment/AdminOnlinePaymentReports/AdminOnlinePaymentReports")
);
const OnlinePaymentReports = lazyLoadWithRetry(
    () => import("./entity/onlinePayment/common/OnlinePaymentReports")
);
const OnlinePaymentsList = lazyLoadWithRetry(
    () => import("./entity/onlinePayment/OnlinePaymentList/OnlinePaymentList")
);

const LeadListContainer = lazyLoadWithRetry(
    () => import("entity/lead/LeadListContainer/LeadListContainer")
);
const AdminMain = lazyLoadWithRetry(() => import("entity/admin/AdminMain"));

const AdminUsernameLookup = lazyLoadWithRetry(
    () => import("entity/AdminUsernameLookup/AdminUsernameLookup")
);

const AdminSubscriptionManager = lazyLoadWithRetry(
    () => import("entity/adminSubscriptionManager/AdminSubscriptionManager")
);

const WorkdayExceptionBDS = lazyLoadWithRetry(
    () => import("entity/schedule/WorkDayException/Bds/WorkdayException")
);

const SelectionListContainer = lazyLoadWithRetry(
    () => import("entity/selection/SelectionListContainer/SelectionListContainer")
);

const MobileDownload = lazyLoadWithRetry(() => import("entity/mobileDownload/MobileDownload"));

const OwnerManagement = lazyLoadWithRetry(
    () => import("entity/job/UserManagement/Homeowners/ManageHomeowners")
);

const PurchasingDashboard = lazyLoadWithRetry(
    () => import("entity/rebate/PurchasingDashboard/PurchasingDashboard")
);

const ClientJobPermissions = lazyLoadWithRetry(
    () => import("./entity/contact/Contact/Client/ClientJobPermissions")
);

const ClientUpdateList = lazyLoadWithRetry(() => import("entity/clientUpdate/ClientUpdateList"));

/** End lazy loaded section */

const ApiDocsPage = () => (
    <BTSuspense>
        <ApiDocs />
    </BTSuspense>
);

const OrgLinkNotConnectedEmptyStatePage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const isFromLogin = urlParams.fromLoginPage === "true";
    return (
        <BTSuspense>
            <OrgLinkNotConnectedEmptyState fromLoginPage={isFromLogin} {...reactRouterProps} />
        </BTSuspense>
    );
};

const IndexPage = () => <div />;

const MultiFactorAuthPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const credentialId = urlParams.credentialId;
    return (
        <BTSuspense>
            <MultiFactorAuthModal {...reactRouterProps} credentialId={credentialId} />
        </BTSuspense>
    );
};

const ShiftPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const id = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);

    return (
        <BTSuspense>
            <Shift id={id} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};
const ClockInClockOutPage = (
    reactRouterProps: RouteComponentProps<{
        id: string;
        jobId: string;
        isClockIn: string;
        inMultiple: string;
    }>
) => {
    const urlParams: any = reactRouterProps.match.params;
    const id = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);
    const isClockIn = urlParams.isClockIn === "true";
    const inMultiple = urlParams.isMultiple === "true";

    return (
        <BTSuspense>
            <ClockInClockOut
                id={id}
                jobId={jobId}
                isClockIn={isClockIn}
                isMultiple={inMultiple}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};
const SignupFormPage = (
    reactRouterProps: RouteComponentProps<{
        code: string;
        autoCode: string;
        salesforceAccountID: string;
        salesforceOpportunityID: string;
        rewinBuilderID: string;
        sfRewinContactLoginID: string;
        isRewin: string;
        testExperience: string;
    }>
) => {
    const urlParams = reactRouterProps.match.params;
    const signupCode = urlParams.code;
    const isAutoCode = urlParams.autoCode ? urlParams.autoCode === "true" : false;
    // Existing routes in our legacy code only pass up the rewin builder id. In this situation we are passing 0 for the salesforce account and opp ids. If 0 is passed we want to set these values to undefined.
    const salesforceAccountID =
        urlParams.salesforceAccountID === "0" ? undefined : urlParams.salesforceAccountID;
    const salesforceOpportunityID =
        urlParams.salesforceOpportunityID === "0" ? undefined : urlParams.salesforceOpportunityID;
    const rewinBuilderID = urlParams.rewinBuilderID;
    const sfRewinContactLoginID = urlParams.sfRewinContactLoginID;
    const testExperience = urlParams.testExperience;

    // eslint-disable-next-line deprecate/member-expression
    const qs: any = queryString.parse(reactRouterProps.location.search);
    const websiteTestExperience = qs.t;
    let leadSource = undefined;
    if (qs.p_usrc !== undefined) {
        leadSource = new LeadSourceValues({
            source: qs.p_usrc,
            medium: qs.p_med,
            campaign: qs.p_camp,
            term: qs.p_term,
            content: qs.p_content,
            gclid: qs.p_gclid,
            msclkid: qs.p_msclkid,
            fbclid: qs.p_fbclid,
            sourceTags: qs.p_src,
            wbraid: qs.p_wbraid,
            gbraid: qs.p_gbraid,
            campaignId: qs.p_campid,
            reference: qs.p_refer,
            landing: qs.p_lp,
            formURL: reactRouterProps.location.pathname,
        });
    }

    return (
        <BTSuspense>
            <SignupForm
                code={signupCode}
                isAutoCode={isAutoCode}
                salesforceAccountID={salesforceAccountID}
                salesforceOpportunityID={salesforceOpportunityID}
                rewinBuilderID={rewinBuilderID}
                sfRewinContactLoginID={sfRewinContactLoginID}
                testExperience={testExperience}
                websiteTestExperience={websiteTestExperience}
                leadSource={leadSource}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SchedulePage = (reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>) => {
    const urlParams = reactRouterProps.match.params;
    const scheduleItemId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);

    // eslint-disable-next-line deprecate/member-expression
    const qs: any = queryString.parse(reactRouterProps.location.search, { parseBooleans: true });

    const entityDefaultParams =
        Object.keys(qs).length > 0 ? new ScheduleEntityDefaultParams(qs) : undefined;
    const disableLinks = qs.disableRFI;

    const initialTab = qs.initialTab ? Number(qs.initialTab) : undefined;

    return (
        <BTSuspense>
            <Schedule
                id={scheduleItemId}
                jobId={jobId}
                entityDefaultParams={entityDefaultParams}
                disableLinks={disableLinks}
                initialTab={initialTab}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const LienWaiverReviewPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <LienWaiverReview {...reactRouterProps} />
        </BTSuspense>
    );
};

const LienWaiverReviewPageExternal = (
    reactRouterProps: RouteComponentProps<{
        builderId: string;
        subId: string;
        shareToken?: string;
    }>
) => {
    return (
        <BTSuspense>
            <LienWaiverReviewExternal
                shareToken={reactRouterProps.match.params.shareToken}
                encodedBuilderId={reactRouterProps.match.params.builderId}
                encodedSubId={reactRouterProps.match.params.subId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const CostCodeLegacyWrapperPage = (
    reactRouterProps: RouteComponentProps<any, StaticContext, any>
) => {
    const qsValues: any = reactRouterProps.match.params;
    const costType = parseInt(qsValues.costType);
    const costCodeId = qsValues.costCodeId;

    return (
        <BTSuspense>
            <CostCodeLegacyWrapper {...reactRouterProps} id={costCodeId} type={costType} />
        </BTSuspense>
    );
};
const CostCodesExportToAccountingPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const urlValues: any = reactRouterProps.match.params;
    const checkExpenseAccount = urlValues.checkExpenseAccount === "true";
    const serviceItemsOnly = urlValues.serviceItemsOnly === "true";
    const doRefresh = urlValues.doRefresh === "true";
    let costCodeIds: number[];
    if (qsValues.costCodeIds !== "") {
        costCodeIds = stringToArray(qsValues.costCodeIds);
    } else {
        costCodeIds = [];
    }
    return (
        <BTSuspense>
            <CostCodesExportToAccounting
                {...reactRouterProps}
                checkExpenseAccount={checkExpenseAccount}
                serviceItemsOnly={serviceItemsOnly}
                costCodeIds={costCodeIds}
                doRefresh={doRefresh}
                isModal={true}
            />
        </BTSuspense>
    );
};
const CostCodesExportAccountingLegacyPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const urlValues: any = reactRouterProps.match.params;
    const checkExpenseAccount = urlValues.checkExpenseAccount === "true";
    const serviceItemsOnly = urlValues.serviceItemsOnly === "true";
    const doRefresh = urlValues.doRefresh === "true";
    let costCodeIds: number[];
    if (qsValues.costCodeIds !== "") {
        costCodeIds = stringToArray(qsValues.costCodeIds);
    } else {
        costCodeIds = [];
    }
    return (
        <BTSuspense>
            <CostCodesAccountingExportLegacyWrapper
                {...reactRouterProps}
                checkExpenseAccount={checkExpenseAccount}
                serviceItemsOnly={serviceItemsOnly}
                costCodeIds={costCodeIds}
                doRefresh={doRefresh}
            />
        </BTSuspense>
    );
};
const CostCodesImportFromAccountingPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <CostCodeImportFromAccounting {...reactRouterProps} />
        </BTSuspense>
    );
};
const EstimateBidMappingPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        arrayFormat: "comma",
        parseNumbers: true,
    });
    const urlValues: any = reactRouterProps.match.params;
    const ids = qsValues.ids;
    const jobId = parseInt(urlValues.jobId);
    return (
        <BTSuspense>
            <EstimateBidMapping ids={ids} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const RelatedRfiPage = (
    reactRouterProps: RouteComponentProps<{
        linkedId: string;
        linkedType: string;
        builderId: string;
        jobId: string;
    }>
) => {
    const urlParams = reactRouterProps.match.params;
    const builderId = parseInt(urlParams.builderId);
    const jobId = parseInt(urlParams.jobId);
    const linkedId = parseInt(urlParams.linkedId);
    const linkedType = parseInt(urlParams.linkedType);

    return (
        <BTSuspense>
            <RelatedRfi
                builderId={builderId}
                jobId={jobId}
                linkedId={linkedId}
                linkedType={linkedType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const RFIPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const rfiId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);

    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const linkedId: number = qsValues.linkedId;
    const linkedType: number = qsValues.linkedType;

    return (
        <BTSuspense>
            <RFI
                id={rfiId}
                jobId={jobId}
                disableLinks={linkedId !== undefined}
                linkedId={linkedId}
                linkedType={linkedType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const PurchaseOrderPrintPage = (
    reactRouterProps: RouteComponentProps<{
        shareToken?: string;
    }>
) => {
    const params = reactRouterProps.match.params;
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const purchaseOrderIds = stringToArray(qsValues.poid);
    const isPdfMode: boolean = qsValues.isPDFMode ? qsValues.isPDFMode : false;
    const showPaymentInfo: boolean = qsValues.showPaymentInfo ? qsValues.showPaymentInfo : false;

    return (
        <BTSuspense>
            <PurchaseOrderPrint
                ids={purchaseOrderIds}
                isPdfMode={isPdfMode}
                showPaymentInfoForPdf={showPaymentInfo}
                shouldAddMargin={isPdfMode}
                shareToken={params.shareToken}
            />
        </BTSuspense>
    );
};

const WarrantyPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const warrantyIds = stringToArray(qsValues.ids);

    return (
        <BTSuspense>
            <WarrantyPrint ids={warrantyIds} />
        </BTSuspense>
    );
};

const WarrantyPage = (reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>) => {
    const urlParams = reactRouterProps.match.params;
    const warrantyId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);

    return (
        <BTSuspense>
            <Warranty id={warrantyId} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const QuestionReportPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    let jobId: number = qsValues.jobId;

    return (
        <BTSuspense>
            <QuestionReportPrint jobId={jobId} />
        </BTSuspense>
    );
};

const QuestionReportPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobIds: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const questionId = parseInt(urlParams.id);
    const jobIds: number[] = urlParams.jobIds ? stringToArray(urlParams.jobIds) : [];

    return (
        <BTSuspense>
            <QuestionReport
                {...reactRouterProps}
                questionId={questionId}
                jobIds={jobIds}
                entityType={ListEntityType.SurveyReportAnswers}
            />
        </BTSuspense>
    );
};

const SelectionPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const selectionIds: number[] = qsValues.ids ? stringToArray(qsValues.ids) : [];
    let sortColumn: SelectionsDynamicGridColumnTypes | undefined;
    let sortDirection: DirectionTypes | undefined;
    let jobIds: number[] = stringToArray(qsValues.jobIds);
    let selectedView: SelectionListContainerTabs | undefined;
    let shouldPrintAll: boolean = false;

    if (qsValues.sortColumn) {
        sortColumn = parseInt(qsValues.sortColumn);
    }
    if (qsValues.sortDirection) {
        sortDirection = parseInt(qsValues.sortDirection);
    }

    if (qsValues.shouldPrintAll) {
        shouldPrintAll = Boolean(qsValues.shouldPrintAll);
    }

    if (qsValues.selectedView) {
        selectedView = parseInt(qsValues.selectedView);
    }

    return (
        <BTSuspense>
            <SelectionPrint
                ids={selectionIds}
                shouldPrintAll={shouldPrintAll}
                jobIds={jobIds}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                selectedView={selectedView}
            />
        </BTSuspense>
    );
};

const SpecificationPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const ids: number[] = stringToArray(qsValues.specificationIds);
    return (
        <BTSuspense>
            <SpecificationPrint
                ids={ids}
                // printDisplayOptions={printDisplayOptions}
            />
        </BTSuspense>
    );
};

const DependentSelectionsPage = (
    reactRouterProps: RouteComponentProps<{ choiceId: string; jobId: string }>
) => {
    const choiceId = parseInt(reactRouterProps.match.params.choiceId);
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    return (
        <BTSuspense>
            <DependentSelections choiceId={choiceId} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const SelectionPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string; lockingChoiceId?: string }>
) => {
    const id = parseInt(reactRouterProps.match.params.id);
    const jobId = parseInt(reactRouterProps.match.params.jobId);

    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    let lockingChoiceId: number | undefined;
    if (qsValues.lockingChoiceId) {
        lockingChoiceId = parseInt(qsValues.lockingChoiceId);
    }

    return (
        <BTSuspense>
            <Selection
                id={id}
                jobId={jobId}
                lockingSelectionChoiceId={lockingChoiceId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SelectionChoicePage = (
    reactRouterProps: RouteComponentProps<{
        choiceId: string;
        jobId: string;
        selectionId?: string;
    }>
) => {
    const choiceId = parseInt(reactRouterProps.match.params.choiceId);
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    const selectionId =
        reactRouterProps.match.params.selectionId !== undefined
            ? parseInt(reactRouterProps.match.params.selectionId)
            : undefined;
    return (
        <BTSuspense>
            <SelectionChoice
                id={choiceId}
                jobId={jobId}
                selectionId={selectionId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const CopyChoiceFromSelectionPage = (
    reactRouterProps: RouteComponentProps<{ targetSelectionId: string; jobId: string }>
) => {
    const targetSelectionId = parseInt(reactRouterProps.match.params.targetSelectionId);
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    return (
        <BTSuspense>
            <CopyChoiceFromSelection
                // eslint-disable-next-line deprecate/member-expression
                onClose={() => routesWebforms.closeModal()}
                id={targetSelectionId}
                jobId={jobId}
                fromReact={false}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const AllowancePrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const jobId = parseInt(qsValues.jobId);

    let id = 0;
    if (qsValues.id) {
        id = parseInt(qsValues.id);
    }

    return (
        <BTSuspense>
            <AllowancePrint id={id} jobId={jobId} />
        </BTSuspense>
    );
};

const SurveyPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const surveyIds = stringToArray(qsValues.ids);

    return (
        <BTSuspense>
            <SurveyPrint ids={surveyIds} />
        </BTSuspense>
    );
};

const IntegrationsSettingsPage = (reactRouterProps: RouteComponentProps<{ type: string }>) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const urlParams = reactRouterProps.match.params;

    const forceLogin: boolean = qsValues.forceLogin;
    const integrationType = parseInt(urlParams.type);
    return (
        <BTSuspense>
            <IntegrationsSettings
                {...reactRouterProps}
                forceLogin={forceLogin}
                integrationType={integrationType}
            />
        </BTSuspense>
    );
};

const ReconsentPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <Reconsent {...reactRouterProps} />
        </BTSuspense>
    );
};

const Auth0RedirectPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);

    return (
        <BTSuspense>
            <Auth0Redirect
                code={qsValues.code}
                {...reactRouterProps}
                redirectLink={qsValues.state}
            />
        </BTSuspense>
    );
};

const ToDoPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const ids: number[] = stringToArray(qsValues.toDoIds);
    const jobsiteIds: number[] = qsValues.jobsiteIds ? stringToArray(qsValues.jobsiteIds) : [];
    const printDisplayOptions: ToDoPrintDisplayOptions = new ToDoPrintDisplayOptions({
        completedDetails: qsValues.completedDetails,
        customFields: qsValues.customFields,
        discussions: qsValues.discussions,
    });

    return (
        <BTSuspense>
            <ToDoPrint
                ids={ids}
                jobsiteIds={jobsiteIds}
                printDisplayOptions={printDisplayOptions}
            />
        </BTSuspense>
    );
};

const ToDoAssignPage = (reactRouterProps: RouteComponentProps) => {
    const toDoIds = (reactRouterProps.match.params as any).toDoIds;
    const onClose = () => {
        // eslint-disable-next-line deprecate/member-expression
        routesWebforms.closeModal();
    };
    return (
        <BTSuspense>
            <ToDoAssign toDoIds={stringToArray(toDoIds)} onClose={onClose} {...reactRouterProps} />
        </BTSuspense>
    );
};

const ToDoPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string; isFromRfi?: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const toDoId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const dailyLogId = parseInt(qsValues.dailyLogId);
    const scheduleId = parseInt(qsValues.scheduleId);
    const isFromRfi = urlParams.isFromRfi === "true";
    const refreshParentAfterSave = qsValues.refreshParentAfterSave;
    return (
        <BTSuspense>
            <ToDo
                id={toDoId}
                jobId={jobId}
                dailyLogId={dailyLogId}
                scheduleId={scheduleId}
                isFromRfi={isFromRfi}
                refreshParentAfterSave={refreshParentAfterSave}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const LeadPage = (reactRouterProps: RouteComponentProps<{ id: string }>) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    // eslint-disable-next-line deprecate/member-expression
    const { checkoutId, showRelatedJob, showAddressMap, selectedTab } = queryString.parse(
        reactRouterProps.location.search
    ) as any;
    return (
        <BTSuspense>
            <Lead
                {...reactRouterProps}
                leadId={id}
                checkoutId={checkoutId}
                showRelatedJob={showRelatedJob !== "false"}
                showAddressMap={showAddressMap !== "false"}
                selectedTab={selectedTab}
            />
        </BTSuspense>
    );
};

const LeadPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const leadIds = stringToArray(qsValues.ids);
    const portalLoginType = qsValues.portalTypeLogin;
    const sortDirection = qsValues.sortDirection;
    const sortExpression = qsValues.sortExpression;

    return (
        <BTSuspense>
            <LeadPrint
                ids={leadIds}
                portalLoginType={portalLoginType}
                sortDirection={sortDirection}
                sortExpression={sortExpression}
                printOnLoad={true}
            />
        </BTSuspense>
    );
};

const LeadActivityPage = (
    reactRouterProps: RouteComponentProps<{ id: string; leadId: string; openActivity: string }>
) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    const leadId: number = parseInt(reactRouterProps.match.params.leadId);
    const openActivity: OpenActivityType = parseInt(reactRouterProps.match.params.openActivity);
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const activityDate: moment.Moment | undefined = qsValues.activityDate
        ? moment(qsValues.activityDate as string)
        : undefined;
    return (
        <BTSuspense>
            <LeadActivity
                id={id}
                leadId={leadId}
                openActivity={openActivity}
                activityDate={activityDate}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const BidPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const bidId = parseInt(qsValues.id);
    return (
        <BTSuspense>
            <BidPrint id={bidId} />
        </BTSuspense>
    );
};

const BidPackagePrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const bidPackageIds = stringToArray(qsValues.ids);
    return (
        <BTSuspense>
            <BidPackagePrint ids={bidPackageIds} />
        </BTSuspense>
    );
};

const ChangeOrderPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const coIds = stringToArray(qsValues.ids);
    const sortDirection =
        qsValues.sortDir && qsValues.sortDir.toLowerCase() === "desc"
            ? DirectionTypes.DESC
            : DirectionTypes.ASC;

    return (
        <BTSuspense>
            <ChangeOrderPrint
                ids={coIds}
                sortColumn={qsValues.sortExp}
                sortDirection={sortDirection}
            />
        </BTSuspense>
    );
};

const ChangeOrderOwnerRequestPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const coId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);
    return (
        <BTSuspense>
            <ChangeOrderOwnerRequest id={coId} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const ChangeOrderPage = (reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const urlParams = reactRouterProps.match.params;

    const coId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);
    let openedFrom = OpenedFrom.Nothing;
    if (qsValues.fromBid) {
        openedFrom = OpenedFrom.Bid;
    }
    if (qsValues.fromRFI) {
        openedFrom = OpenedFrom.Rfi;
    }

    return (
        <BTSuspense>
            <ChangeOrder id={coId} jobId={jobId} openedFrom={openedFrom} {...reactRouterProps} />
        </BTSuspense>
    );
};

const OwnerInvoicePage = (
    reactRouterProps: RouteComponentProps<{
        id: string;
        jobId: string;
    }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });

    const invoiceId: number = parseInt(reactRouterProps.match.params.id);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    const fromRelatedItem = qsValues.fromRelatedItem;

    return (
        <BTSuspense>
            <OwnerInvoice
                id={invoiceId}
                jobId={jobId}
                fromRelatedItem={fromRelatedItem}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SharedExternalOwnerInvoicePage = (
    reactRouterProps: RouteComponentProps<{
        id: string;
        builderId: string;
        jobId: string;
        shareToken?: string;
        checkoutId?: string;
    }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);

    // eslint-disable-next-line deprecate/function
    updateWebformsQueryStringParams();

    return (
        <BTSuspense>
            <SharedExternalOwnerInvoice
                builderId={reactRouterProps.match.params.builderId}
                id={reactRouterProps.match.params.id}
                jobId={reactRouterProps.match.params.jobId}
                shareToken={reactRouterProps.match.params.shareToken}
                checkoutId={qsValues.checkout_id}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ExternalDepositPage = (
    reactRouterProps: RouteComponentProps<{
        depositId: string;
        builderId: string;
        jobId: string;
        shareToken?: string;
    }>
) => {
    let checkoutId: string | undefined;
    // eslint-disable-next-line deprecate/member-expression
    const qsParams = queryString.parse(window.location.search);
    if (qsParams.checkout_id) {
        checkoutId = qsParams.checkout_id as string;
    }
    // eslint-disable-next-line deprecate/function
    updateWebformsQueryStringParams();
    return (
        <BTSuspense>
            <DepositExternal
                shareToken={reactRouterProps.match.params.shareToken}
                checkoutId={checkoutId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const DepositPrintPage = (reactRouterProps: RouteComponentProps) => {
    const params = parseParams<IDepositPrintParams>(reactRouterProps.location.search, {
        parseNumbers: true,
    });
    const jobId: number = Number(params.jobId);
    const depositId: number = Number(params.depositId);

    return (
        <BTSuspense>
            <DepositPrint jobId={jobId} depositId={depositId} />
        </BTSuspense>
    );
};

const ExternalDepositPrintPage = (
    reactRouterProps: RouteComponentProps<{
        shareToken: string;
    }>
) => {
    return (
        <BTSuspense>
            <DepositPrint shareToken={reactRouterProps.match.params.shareToken} />
        </BTSuspense>
    );
};
const OwnerInvoicePrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const invoiceId: number = Number(qsValues.invoiceId);
    const showCustomFields: boolean =
        qsValues.showCustomFields && qsValues.showCustomFields === "true";
    const showInvoiceDescription: boolean =
        qsValues.showInvoiceDescription && qsValues.showInvoiceDescription === "true";
    const showPaymentCode: boolean =
        qsValues.showPaymentCode && qsValues.showPaymentCode === "true";
    const isOwnerPreview: boolean = qsValues.isOwnerPreview && qsValues.isOwnerPreview === "true";

    return (
        <BTSuspense>
            <OwnerInvoicePrint
                showCustomFields={showCustomFields}
                showPaymentCode={showPaymentCode}
                showInvoiceDescription={showInvoiceDescription}
                invoiceId={invoiceId}
                isOwnerPreview={isOwnerPreview}
                isPrintPage={true}
            />
        </BTSuspense>
    );
};

const ExternalOwnerInvoicePrintPage = (
    reactRouterProps: RouteComponentProps<{
        shareToken: string;
        showCustomFields: string;
        showLineItems: string;
        showPaymentCode: string;
        showInvoiceDescription: string;
        invoiceId: string;
    }>
) => {
    let showCustomFields: boolean;
    let showPaymentCode: boolean;
    let showInvoiceDescription: boolean;
    const routeParams = reactRouterProps.match.params;
    showCustomFields = routeParams.showCustomFields === "true";
    showPaymentCode = routeParams.showPaymentCode === "true";
    showInvoiceDescription = routeParams.showInvoiceDescription === "true";

    return (
        <BTSuspense>
            <OwnerInvoicePrint
                showPaymentCode={showPaymentCode}
                showInvoiceDescription={showInvoiceDescription}
                showCustomFields={showCustomFields}
                encryptedInvoiceId={routeParams.invoiceId}
                shareToken={reactRouterProps.match.params.shareToken}
                isPrintPage={true}
            />
        </BTSuspense>
    );
};

const BankValidationRedirectLandingPage = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            let redirectUrl = BTLocalStorage.get("bt-string-paymentVerificationOauthRedirectUrl");
            if (redirectUrl) {
                redirectUrl += `&landingUrl=${encodeURIComponent(window.location.href)}`;
                window.location.assign(redirectUrl);
            } else {
                throw new Error("User landed on redirect landing page with missing redirect URL");
            }
        }, 0);
        return function () {
            clearTimeout(timeout);
        };
    }, []);

    return <></>;
};

const AdminPaymentsPrescreeningPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <AdminPaymentsPrescreening {...reactRouterProps} />
        </BTSuspense>
    );
};

const OnlinePaymentExternalPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <OnlinePaymentExternal {...reactRouterProps} />
        </BTSuspense>
    );
};

const OnlineInvoiceExternalPage = (reactRouterProps: RouteComponentProps<{ code: string }>) => {
    return (
        <BTSuspense>
            <OnlineInvoiceExternal {...reactRouterProps} />
        </BTSuspense>
    );
};

const OutboundPaymentsExternalPage = (
    reactRouterProps: RouteComponentProps<{
        shareToken: string;
    }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const isRedirected: boolean = qsValues.redirected && JSON.parse(qsValues.redirected);
    const fromEmail: boolean = qsValues.fromEmail && JSON.parse(qsValues.fromEmail);
    return (
        <BTSuspense>
            <OutboundPaymentsExternal
                shareToken={reactRouterProps.match.params.shareToken}
                fromEmail={fromEmail}
                isRedirected={isRedirected}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const DailyLogPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const pageNum: number = Number(qsValues.pn);
    const pageSize: number = Number(qsValues.ps);
    const filters = JSON.parse(decodeURIComponent(qsValues.filters));

    return (
        <BTSuspense>
            <DailyLogPrint pageNumber={pageNum} pageSize={pageSize} filters={filters} />
        </BTSuspense>
    );
};

const ProposalPrintPage = (
    reactRouterProps: RouteComponentProps<{ proposalId?: string; shareToken?: string }>
) => {
    return (
        <BTSuspense>
            <BuilderInfoContext.Consumer>
                {(builderInfo) => (
                    <ProposalPrint
                        id={reactRouterProps.match.params.proposalId}
                        shareToken={reactRouterProps.match.params.shareToken}
                        builderInfo={builderInfo}
                    />
                )}
            </BuilderInfoContext.Consumer>
        </BTSuspense>
    );
};

const LeadProposalPrintPage = (reactRouterProps: RouteComponentProps<{ proposalId: string }>) => {
    return (
        <BTSuspense>
            <LeadProposalPrint id={reactRouterProps.match.params.proposalId} />
        </BTSuspense>
    );
};

const ExternalProposalPage = (
    reactRouterProps: RouteComponentProps<{
        shareToken: string;
    }>
) => {
    return (
        <BTSuspense>
            <OwnerProposal
                shareToken={reactRouterProps.match.params.shareToken}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const LeadProposalPdfGenerationPage = (
    reactRouterProps: RouteComponentProps<{ proposalId: string; shareToken?: string }>
) => {
    const handler = new LeadProposalHandler();
    return (
        <BTSuspense>
            <LeadProposalPdfGeneration
                id={reactRouterProps.match.params.proposalId}
                handler={handler}
                shareToken={reactRouterProps.match.params.shareToken}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const JobProposalPdfGenerationPage = (
    reactRouterProps: RouteComponentProps<{ shareToken: string }>
) => {
    const handler = new ProposalHandler();
    return (
        <BTSuspense>
            <ProposalPdfGeneration
                handler={handler}
                shareToken={reactRouterProps.match.params.shareToken}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const DailyLogDetailPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string; openWithMap: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const dailyLogId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);
    // eslint-disable-next-line deprecate/member-expression
    const qsparams: any = queryString.parse(reactRouterProps.location.search);
    const isOpenWithMap = qsparams.openWithMap ? qsparams.openWithMap === "true" : false;
    const todoId = parseInt(qsparams.todoId);

    return (
        <BTSuspense>
            <DailyLog
                id={dailyLogId}
                jobId={jobId}
                todoId={todoId}
                isOpenWithMap={isOpenWithMap}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ContactPage = (
    reactRouterProps: RouteComponentProps<{ id: string; isFromSelector: string }>
) => {
    const contactId = parseInt(reactRouterProps.match.params.id);
    const isFromSelector = reactRouterProps.match.params.isFromSelector.toLowerCase() === "true";
    return (
        <BTSuspense>
            <BuilderInfoContext.Consumer>
                {(builderInfo) => (
                    <Contact
                        id={contactId}
                        isFromSelector={isFromSelector}
                        builderInfo={builderInfo!}
                        {...reactRouterProps}
                    />
                )}
            </BuilderInfoContext.Consumer>
        </BTSuspense>
    );
};

const ContactPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const contactId = parseInt(qsValues.id);
    return (
        <BTSuspense>
            <ContactPrint id={contactId} printOnLoad={true} />
        </BTSuspense>
    );
};

const LienWaiverDetailPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>
) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    return (
        <BTSuspense>
            <LienWaiver id={id} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const LienWaiverPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const ids = stringToArray(qsValues.popids);
    const isPDFMode: boolean = qsValues.isPDFMode ? qsValues.isPDFMode : false;
    return (
        <BTSuspense>
            <LienWaiverPrint ids={ids} isPdfMode={isPDFMode} shouldAddMargin={isPDFMode} />
        </BTSuspense>
    );
};

const MarketplaceRedirectPage = (reactRouterProps: RouteComponentProps) => {
    const qsValues = parseParams<{ mlt: number }>(reactRouterProps.location.search, {
        parseNumbers: true,
    });
    return (
        <BTSuspense>
            <MarketplaceRedirect marketplaceLinkType={qsValues.mlt ?? 0} {...reactRouterProps} />
        </BTSuspense>
    );
};
const NotificationPage = (
    reactRouterProps: RouteComponentProps<{
        notificationId: string;
        jobsiteId: string;
        dateSent: string;
        isArchive: string;
    }>
) => {
    const urlParams = reactRouterProps.match.params;
    const notificationId = parseInt(urlParams.notificationId);
    const jobsiteId = parseInt(urlParams.jobsiteId);
    const dateSent = moment(urlParams.dateSent);
    const isArchive = urlParams.isArchive ? urlParams.isArchive === "true" : false;
    return (
        <BTSuspense>
            <Notification
                notificationId={notificationId}
                jobsiteId={jobsiteId}
                dateSent={dateSent}
                isArchive={isArchive}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const MessageDetailsExternalPage = (
    reactRouterProps: RouteComponentProps<{ shareToken: string }>
) => {
    const shareToken = reactRouterProps.match.params.shareToken;
    return (
        <BTSuspense>
            <MessageExternal shareToken={shareToken} {...reactRouterProps} />
        </BTSuspense>
    );
};

const MessageComposePage = (
    reactRouterProps: RouteComponentProps<{ messageId: string; jobId: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    // eslint-disable-next-line deprecate/member-expression
    const qsvalues: any = queryString.parse(reactRouterProps.location.search);

    const messageId = parseInt(urlParams.messageId);
    const isDraft = Boolean(qsvalues.isDraft);
    const jobId = parseInt(urlParams.jobId);
    const replyType = qsvalues.replyType ? (parseInt(qsvalues.replyType) as ReplyType) : undefined;
    const replyId = qsvalues.replyId ? parseInt(qsvalues.replyId) : undefined;
    const defaultRecipientId = qsvalues.defaultRecipientId
        ? parseInt(qsvalues.defaultRecipientId)
        : 0;
    const docInstanceIds =
        qsvalues.docInstanceIds?.split(",").map((str: string) => Number(str)) || [];
    const paymentIds = qsvalues.paymentIds?.split(",").map((str: string) => Number(str)) || [];

    return (
        <BTSuspense>
            <MessageCompose
                {...reactRouterProps}
                jobId={jobId}
                messageId={messageId}
                isDraft={isDraft}
                replyId={replyId}
                replyType={replyType}
                defaultRecipientId={defaultRecipientId}
                docInstanceIds={docInstanceIds}
                paymentIds={paymentIds}
            />
        </BTSuspense>
    );
};

const PaymentRequestPage = (reactRouterProps: RouteComponentProps) => {
    const routeValues: any = reactRouterProps.match.params;
    const purchaseOrderId = parseInt(routeValues.poId);
    const purchaseOrderPaymentId = parseInt(routeValues.popId);

    return (
        <BTSuspense>
            <PaymentRequest
                id={purchaseOrderPaymentId}
                purchaseOrderId={purchaseOrderId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SubDivisionPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    let subIds = stringToArray(qsValues.subids);
    return (
        <BTSuspense>
            <SubDivision subIds={subIds} {...reactRouterProps} />
        </BTSuspense>
    );
};

const SubDetailPage = (
    reactRouterProps: RouteComponentProps<{ id: string; defaultTab: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const subId = parseInt(urlParams.id);
    const subDetailsTab = urlParams.defaultTab ? (urlParams.defaultTab as SubTabKeys) : undefined;
    return (
        <BTSuspense>
            <SubVendor id={subId} defaultTab={subDetailsTab} {...reactRouterProps} />
        </BTSuspense>
    );
};

const ReceiptUpload = (reactRouterProps: RouteComponentProps<{ id: string }>) => {
    return (
        <BTSuspense>
            <ReceiptsUpload {...reactRouterProps} />
        </BTSuspense>
    );
};

const ReceiptPage = (reactRouterProps: RouteComponentProps<{ id: string }>) => {
    const id: number = parseInt(reactRouterProps.match.params.id);

    return (
        <BTSuspense>
            <Receipt {...reactRouterProps} id={id} />
        </BTSuspense>
    );
};

const ReceiptListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps<any>) => {
    return (
        <BTSuspense>
            <PageTitle title="Cost Inbox" />
            <ReceiptList {...route} {...listLayoutProps} entityType={ListEntityType.Receipts} />
        </BTSuspense>
    );
};

const ImportWizardPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const builderId = parseInt(qsValues.builderId);
    const importType = parseInt(qsValues.importType);
    return (
        <BTSuspense>
            <ImportWizard importType={importType} builderId={builderId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const CreditMemoPage = (reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    return (
        <BTSuspense>
            <CreditMemo id={id} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const CreditMemoPrintPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>
) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    return (
        <BTSuspense>
            <CreditMemoPrint id={id} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const InternalUserQuickAddPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <InternalUserQuickAdd {...reactRouterProps} />
        </BTSuspense>
    );
};

const InternalUserSettingsPage = (
    reactRouterProps: RouteComponentProps<{
        showCustomFields: string;
        fromInternalUserId: string;
        currentTab: InternalUserTabKeys;
    }>
) => {
    const showCustomFields =
        reactRouterProps.match.params.showCustomFields.toLowerCase() === "true";
    const fromInternalUserId = parseInt(reactRouterProps.match.params.fromInternalUserId);
    const currentTab = reactRouterProps.match.params.currentTab;

    return (
        <BTSuspense>
            <InternalUserRoleListDev
                showCustomFields={showCustomFields}
                fromInternalUserId={fromInternalUserId}
                currentTab={currentTab}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const InternalUserRoleDetailsPage = (
    reactRouterProps: RouteComponentProps<{
        roleId: string;
        copyFromId: string | undefined;
        showCustomFields: string;
        fromInternalUserId: string;
        currentTab: InternalUserTabKeys;
    }>
) => {
    const roleId: number = parseInt(reactRouterProps.match.params.roleId);

    let copyFromId: number | undefined = undefined;
    if (reactRouterProps.match.params.copyFromId) {
        copyFromId = parseInt(reactRouterProps.match.params.copyFromId);
    }

    const showCustomFields: boolean =
        reactRouterProps.match.params.showCustomFields.toLowerCase() === "true";
    const fromInternalUserId: number = parseInt(reactRouterProps.match.params.fromInternalUserId);
    const currentTab: InternalUserTabKeys = reactRouterProps.match.params.currentTab;

    return (
        <BTSuspense>
            <InternalUserRoleDetailsDev
                roleId={roleId}
                copyFromId={copyFromId}
                showCustomFields={showCustomFields}
                fromInternalUserId={fromInternalUserId}
                currentTab={currentTab}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const InternalUserPage = (reactRouterProps: RouteComponentProps<{ id: string }>) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    // eslint-disable-next-line deprecate/member-expression
    const qs: any = queryString.parse(reactRouterProps.location.search, { parseBooleans: true });
    let initialTab = qs.initialTab;
    let error = null;
    if (qs.thdConnected || qs.thdError) {
        initialTab = InternalUserTabKeys.Vendors;

        if (qs.thdError) {
            error = qs.thdError;
        }
        error = qs.thdError;
    } else if (qs.firstConnection || qs.connected || qs.type === "takeoff") {
        initialTab = InternalUserTabKeys.Takeoff;

        if (qs.error) {
            error = qs.error;
        }
    }
    return (
        <BTSuspense>
            <InternalUser
                id={id}
                thdConnected={qs.thdConnected}
                error={error}
                initialTab={initialTab}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const InternalUsersListPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps<any>
) => {
    return (
        <BTSuspense>
            <PageTitle title="Internal Users" />
            <InternalUsersList
                {...route}
                {...listLayoutProps}
                entityType={ListEntityType.UsersList}
            />
        </BTSuspense>
    );
};

const ContactsListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    return (
        <BTSuspense>
            <PageTitle title="Customer Contacts" />
            <ContactsList {...route} {...listLayoutProps} entityType={ListEntityType.Contacts} />
        </BTSuspense>
    );
};

const SubsListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    const systemFilter = getSystemFilter(route);
    return (
        <BTSuspense>
            <PageTitle title="Subs/Vendors" />
            <SubList
                {...route}
                {...listLayoutProps}
                entityType={ListEntityType.SubsList}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const SubscriptionManagerSettingsPage = (
    reactRouterProps: RouteComponentProps<{ fromSettings: string; fromFrozenBuilder: string }>
) => {
    const urlParams: any = reactRouterProps.match.params;
    const fromSettings: boolean = urlParams.fromSettings.toLowerCase() === "true";
    const fromFrozenBuilder: boolean = urlParams.fromFrozenBuilder.toLowerCase() === "true";

    return (
        <BTSuspense>
            <SubscriptionManager
                fromSettings={fromSettings}
                fromFrozenBuilder={fromFrozenBuilder}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SurveySettingsPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <SurveySettings {...reactRouterProps} />
        </BTSuspense>
    );
};

const SurveyPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const surveyId = parseInt(urlParams.id);
    const jobId = parseInt(urlParams.jobId);
    const surveyDefinitionId = parseInt(urlParams.surveyDefinitionId);

    return (
        <BTSuspense>
            <Survey
                id={surveyId}
                jobId={jobId}
                surveyDefinitionId={surveyDefinitionId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const OwnerSurveyPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const surveyId = parseInt(urlParams.id);

    return (
        <BTSuspense>
            <OwnerSurvey id={surveyId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const MessagePrintPage = (
    reactRouterProps: RouteComponentProps<{ messageId: string; folderId: string }>
) => {
    const messageId = parseInt(reactRouterProps.match.params.messageId);
    const folderId = parseInt(reactRouterProps.match.params.folderId);
    return (
        <BTSuspense>
            <MessagePrint messageId={messageId} folderId={folderId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const MediaMoveFolderPage = (
    reactRouterProps: RouteComponentProps<{ folderId: string; jobId: string; mediaType: string }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const urlParams = reactRouterProps.match.params;

    const folderId = parseInt(urlParams.folderId);
    const jobId = parseInt(urlParams.jobId);
    const mediaType = parseInt(urlParams.mediaType);

    const documentInstanceIds = qsValues.documentInstanceIds
        ? stringToArray(qsValues.documentInstanceIds)
        : [];
    const folderIds = qsValues.folderIds ? stringToArray(qsValues.folderIds) : [];

    return (
        <BTSuspense>
            <MediaMoveFolder
                currentFolderId={folderId}
                documentInstanceIds={documentInstanceIds}
                folderIds={folderIds}
                jobId={jobId}
                mediaType={mediaType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const MediaViewingPermissionsPage = (
    reactRouterProps: RouteComponentProps<{ folderId: string; jobId: string }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const folderId: number = parseInt(reactRouterProps.match.params.folderId);
    const docInstanceIds = qsValues.docIds ? stringToArray(qsValues.docIds) : [];
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    const mediaType = parseInt(qsValues.mediaType);
    const viewingPermissionAction = qsValues.viewingPermissionAction
        ? parseInt(qsValues.viewingPermissionAction)
        : ViewingPermissionAction.Set;
    return (
        <BTSuspense>
            <MediaViewingPermissions
                documentInstanceIds={docInstanceIds}
                folderId={folderId}
                jobId={jobId}
                mediaType={mediaType}
                viewingPermissionAction={viewingPermissionAction}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const MediaFolderEmailInfoPage = (
    reactRouterProps: RouteComponentProps<{ folderId: string; jobId: string; mediaType: string }>
) => {
    const folderId = parseInt(reactRouterProps.match.params.folderId);
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    const mediaType = parseInt(reactRouterProps.match.params.mediaType);

    return (
        <BTSuspense>
            <MediaFolderEmailInfo
                parentFolderId={folderId}
                jobId={jobId}
                mediaType={mediaType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const MediaFolderPropertiesPage = (
    reactRouterProps: RouteComponentProps<{ folderId: string; jobId: string }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const folderId: number = parseInt(reactRouterProps.match.params.folderId);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    const parentFolderId = parseInt(qsValues.parentFolderId);
    const mediaType = parseInt(qsValues.mediaType);
    return (
        <BTSuspense>
            <MediaFolderProperties
                id={folderId}
                parentFolderId={parentFolderId}
                jobId={jobId}
                mediaType={mediaType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const MediaPropertiesPage = (
    reactRouterProps: RouteComponentProps<{
        docInstanceId: string;
        jobId: string;
        mediaType: string;
        folderId: string;
        templateType: string;
        templateDocumentInstanceId: string;
        activeTab: string;
    }>
) => {
    const urlParams = reactRouterProps.match.params;

    const documentInstanceId = parseInt(urlParams.docInstanceId);
    const folderId = urlParams.folderId !== "undefined" ? parseInt(urlParams.folderId) : undefined;
    const jobId = parseInt(urlParams.jobId);
    const mediaType = parseInt(urlParams.mediaType);
    const templateDocumentInstanceId =
        urlParams.templateDocumentInstanceId !== "undefined"
            ? parseInt(urlParams.templateDocumentInstanceId)
            : undefined;
    const templateType =
        urlParams.templateType !== "undefined" ? parseInt(urlParams.templateType) : undefined;
    const activeTab =
        urlParams.activeTab !== "undefined" ? parseInt(urlParams.activeTab) : undefined;

    return (
        <BTSuspense>
            {mediaType === MediaType.Document && (
                <Document
                    documentInstanceId={documentInstanceId}
                    folderId={folderId}
                    jobId={jobId}
                    mediaType={mediaType}
                    templateDocumentInstanceId={templateDocumentInstanceId}
                    templateType={templateType}
                    activeTab={activeTab}
                    {...reactRouterProps}
                />
            )}

            {mediaType === MediaType.Photo && (
                <Photo
                    documentInstanceId={documentInstanceId}
                    jobId={jobId}
                    {...reactRouterProps}
                />
            )}

            {mediaType === MediaType.Video && (
                <Video
                    documentInstanceId={documentInstanceId}
                    jobId={jobId}
                    {...reactRouterProps}
                />
            )}
        </BTSuspense>
    );
};

const MediaReplacePage = (
    reactRouterProps: RouteComponentProps<{
        documentInstanceId: string;
        isFromMediaPage: string;
        mediaType: string;
        includeThisDoc?: string;
        linkedByAnnotationGroup?: string;
    }>
) => {
    const urlParams = reactRouterProps.match.params;

    const documentInstanceId = parseInt(urlParams.documentInstanceId);
    const includeThisDoc = urlParams.includeThisDoc
        ? urlParams.includeThisDoc === "true"
        : undefined;
    const isFromMediaPage = urlParams.isFromMediaPage === "true";
    const linkedByAnnotationGroup = urlParams.linkedByAnnotationGroup
        ? urlParams.linkedByAnnotationGroup === "true"
        : undefined;
    const mediaType = parseInt(urlParams.mediaType);

    return (
        <BTSuspense>
            <MediaReplace
                documentInstanceId={documentInstanceId}
                includeThisDoc={includeThisDoc}
                isFromMediaPage={isFromMediaPage}
                linkedByAnnotationGroup={linkedByAnnotationGroup}
                mediaType={mediaType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const PhotoPreviewPage = (reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>) => {
    const [idsAndJobIds, setIdsAndJobIds] = useState<IDocInstanceAndJobId[]>([]);
    const [idsAndJobIdsIndex, setIdsAndJobIdsIndex] = useState<number>(0);

    const documentInstanceId: number = parseInt(reactRouterProps.match.params.id);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    const jobIds = BTSessionStorage.get("bt-numberArray-photoPreviewListOfJobIds");
    const docInstanceIds = BTSessionStorage.get("bt-numberArray-photoPreviewListOfDocInstanceIds");

    useEffect(() => {
        if (jobIds && docInstanceIds) {
            const docInstanceIdsMap =
                docInstanceIds?.map((value, index) => {
                    return { id: value, jobId: jobIds[index] };
                }) ?? [];
            if (!isEqual(idsAndJobIds, docInstanceIdsMap)) {
                setIdsAndJobIds(docInstanceIdsMap);
            }
        } else if (jobIds || docInstanceIds) {
            setIdsAndJobIds([]);
        }
    }, [docInstanceIds, idsAndJobIds, jobIds]);

    useEffect(() => {
        if (documentInstanceId) {
            const docInstanceIds = idsAndJobIds.map((i) => i.id);
            const index = docInstanceIds.findIndex((id) => id === documentInstanceId);
            setIdsAndJobIdsIndex(index);
        } else {
            setIdsAndJobIdsIndex(0);
        }
    }, [documentInstanceId, idsAndJobIds]);

    const handlePhotoIndexChanged = (index: number) => {
        setIdsAndJobIdsIndex(index);
    };

    return (
        <BTSuspense>
            <PhotoPreview
                id={documentInstanceId}
                jobId={jobId}
                ids={idsAndJobIds}
                onPhotoIndexChanged={handlePhotoIndexChanged}
                index={idsAndJobIdsIndex}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const PurchaseOrderListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Purchase Orders" />
            <PurchaseOrderList
                {...reactRouterProps}
                {...listLayoutProps}
                viewingTemplatesOnly={listLayoutProps.isTemplateMode}
                entityType={ListEntityType.PurchaseOrders}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const PurchaseOrderPage = (
    reactRouterProps: RouteComponentProps<{
        id: string;
        jobId: string;
        varianceChangeOrderId?: string;
    }>
) => {
    const routeParams = reactRouterProps.match.params;

    const id: number = parseInt(routeParams.id);
    const jobId: number = parseInt(routeParams.jobId);
    const varianceChangeOrderId: number | undefined =
        routeParams.varianceChangeOrderId !== undefined
            ? parseInt(routeParams.varianceChangeOrderId)
            : undefined;
    const openToScopeOfWork: boolean = reactRouterProps.location.pathname.includes("RFI");
    const isBuilder: boolean = isInPortal({ builder: true });
    // eslint-disable-next-line deprecate/member-expression
    const qsvalues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const fromBid = qsvalues.fromBid;

    return (
        <BTSuspense>
            <PurchaseOrder
                id={id}
                jobId={jobId}
                varianceChangeOrderId={varianceChangeOrderId}
                openToScopeOfWork={openToScopeOfWork}
                disableBidLinks={fromBid}
                loadLinkedBids={isBuilder}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const AdminAccountChangeRequestPage = (
    reactRouterProps: RouteComponentProps<{ builderId: string; adminId: string }>
) => {
    const routeParams = reactRouterProps.match.params;

    const builderID: number = parseInt(routeParams.builderId);
    const adminId: string = routeParams.adminId;
    return (
        <BTSuspense>
            <AdminAccountChangeRequest
                id={builderID}
                adminId={adminId}
                afterSubmit={() => {
                    // eslint-disable-next-line deprecate/member-expression
                    routesWebforms.closeModalAndReload();
                }}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const AdminCancellationPage = (
    reactRouterProps: RouteComponentProps<{ builderId: string; subscriptionId: string }>
) => {
    const routeParams = reactRouterProps.match.params;
    const builderId = parseInt(routeParams.builderId);
    const subscriptionId = routeParams.subscriptionId;
    return (
        <BTSuspense>
            <AdminCancellation
                {...reactRouterProps}
                builderId={builderId}
                subscriptionId={subscriptionId}
            />
        </BTSuspense>
    );
};

const BuilderAccountChangeRequestPage = (
    reactRouterProps: RouteComponentProps<{ builderId: string; linkId: string }>
) => {
    const routeParams = reactRouterProps.match.params;

    const builderID: number = parseInt(routeParams.builderId);
    const linkId: string = routeParams.linkId;
    return (
        <BTSuspense>
            <BuilderAccountChangeRequest id={builderID} linkId={linkId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const AdminSubscriptionPage = (
    reactRouterProps: RouteComponentProps<{
        fromSettings: string;
        builderId: string;
        isAdminUser: string;
    }>
) => {
    const builderId: number = parseInt(reactRouterProps.match.params.builderId);
    const isAdminUser: boolean = reactRouterProps.match.params.isAdminUser === "true";

    return (
        <BTSuspense>
            <SubscriptionList
                builderId={builderId}
                isAdminUser={isAdminUser}
                showCancel
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ChangeOrderPaymentPage = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>
) => {
    const id: number = parseInt(reactRouterProps.match.params.id);
    const jobId: number = parseInt(reactRouterProps.match.params.jobId);
    return (
        <BTSuspense>
            <ChangeOrderPayment id={id} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const ChangeOrderApprovalSuccessPage = () => {
    return (
        <BTSuspense>
            <ChangeOrderApprovalSuccess />
        </BTSuspense>
    );
};

const LinkJobToAccountingPage = (
    reactRouterProps: RouteComponentProps<{
        jobID: string;
        supportsJobsiteIntegrationTypes: string;
        ownerInvoiceID: string;
    }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const jobID: number = parseInt(reactRouterProps.match.params.jobID);
    const ownerInvoiceID: number = parseInt(qsValues.ownerInvoiceID);
    const supportsJobsiteIntegrationTypes: boolean =
        qsValues.supportsJobsiteIntegrationTypes === "true";

    return (
        <BTSuspense>
            <LinkJobToAccounting
                jobId={jobID}
                ownerInvoiceID={ownerInvoiceID}
                supportsJobsiteIntegrationTypes={supportsJobsiteIntegrationTypes}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const LinkEntityToAccountingPage = (
    reactRouterProps: RouteComponentProps<{ idType: string; entityID: string }>
) => {
    const idType: IDTypes = parseInt(reactRouterProps.match.params.idType);
    const entityID: number = parseInt(reactRouterProps.match.params.entityID);

    return (
        <BTSuspense>
            <LinkEntityToAccounting idType={idType} entityID={entityID} {...reactRouterProps} />
        </BTSuspense>
    );
};

const BillsListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Bills" />
            <BillList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.Payments}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const BillPage = (reactRouterProps: RouteComponentProps) => {
    const routeValues: any = reactRouterProps.match.params;
    // eslint-disable-next-line deprecate/member-expression
    const qsvalues: any = queryString.parse(reactRouterProps.location.search);
    const purchaseOrderId = parseInt(routeValues.id);
    const jobId = parseInt(routeValues.jobId);

    const openToLienWaiver = routeValues.lienWaiver && routeValues.lienWaiver === "lienWaiver";
    const isBuilder: boolean = isInPortal({ builder: true });
    const isSub: boolean = isInPortal({ subs: true });

    const sendToAccounting = qsvalues.sendToAccounting === "true";
    const billToOwner = qsvalues.billToOwner === "true";

    return (
        <BTSuspense>
            {isBuilder && (
                <Bill
                    openToLienWaiver={openToLienWaiver}
                    id={purchaseOrderId}
                    jobId={jobId}
                    sendToAccounting={sendToAccounting}
                    billToOwner={billToOwner}
                    {...reactRouterProps}
                />
            )}
            {isSub && <SubBill id={purchaseOrderId} jobId={jobId} {...reactRouterProps} />}
        </BTSuspense>
    );
};

const BillPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const billIds = stringToArray(qsValues.billIds);
    const jobId = qsValues.jobId;
    const isPdfMode: boolean = qsValues.isPDFMode ? qsValues.isPDFMode : false;
    const showPaymentInfo: boolean = qsValues.showPaymentInfo ? qsValues.showPaymentInfo : false;

    return (
        <BTSuspense>
            <BillPrint
                ids={billIds}
                jobId={jobId}
                isPdfMode={isPdfMode}
                showPaymentInfoForPdf={showPaymentInfo}
                shouldAddMargin={isPdfMode}
            />
        </BTSuspense>
    );
};

const BidPackagePage = (reactRouterProps: RouteComponentProps) => {
    const routeValues: any = reactRouterProps.match.params;
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const bidPackageId = parseInt(routeValues.id);
    const jobId = parseInt(routeValues.jobId);
    const isFromRfi = qsValues.fromRFI;
    const isTemplateMode = qsValues.isTemplateMode;
    const initialTab = qsValues.initialTab;

    return (
        <BTSuspense>
            <BidPackage
                id={bidPackageId}
                jobId={jobId}
                isFromRfi={isFromRfi}
                isTemplateMode={isTemplateMode}
                initialTab={initialTab}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const AllowancePage = (reactRouterProps: RouteComponentProps) => {
    const routeValues: any = reactRouterProps.match.params;
    const allowanceId = parseInt(routeValues.id);
    const jobId = parseInt(routeValues.jobId);

    return (
        <BTSuspense>
            <Allowance id={allowanceId} jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const MobileDownloadPage = () => {
    return (
        <BTSuspense>
            <MobileDownload />
        </BTSuspense>
    );
};

const CopyMediaPage = (reactRouterProps: RouteComponentProps<{ jobId: string }>) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    const mediaType = parseInt(qsValues.mediaType);
    const mediaIds = qsValues.mediaIds ? stringToArray(qsValues.mediaIds) : [];
    const folderIds = qsValues.folderIds ? stringToArray(qsValues.folderIds) : [];

    return (
        <BTSuspense>
            <CopyMedia
                documentInstanceIds={mediaIds}
                folderIds={folderIds}
                jobId={jobId}
                mediaType={mediaType}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const JobViewingPermissionWizardParentPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });

    const jobId = parseInt(qsValues.jobId);
    const openedFromEntity = parseInt(qsValues.openedFrom);
    const canSelectUsers = qsValues.canSelectUsers;

    const subs = qsValues.subs ? stringToArray(qsValues.subs) : [];
    const internalUsers = qsValues.internalUsers ? stringToArray(qsValues.internalUsers) : [];
    const scheduleIds = qsValues.scheduleIds ? stringToArray(qsValues.scheduleIds) : [];

    return (
        <BTSuspense>
            <JobViewingPermissionWizardParent
                jobId={jobId}
                subs={subs}
                scheduleIds={scheduleIds}
                internalUsers={internalUsers}
                openedFromEntity={openedFromEntity}
                canSelectUsers={canSelectUsers}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ChangeOrderPoMappingPage = (reactRouterProps: RouteComponentProps) => {
    const params = parsePurchaseOrderMappingQueryStringParams(reactRouterProps.location.search);

    return (
        <BTSuspense>
            <ChangeOrderPOMapping {...params} {...reactRouterProps} />
        </BTSuspense>
    );
};

const BidPoMappingPage = (reactRouterProps: RouteComponentProps) => {
    const params = parsePurchaseOrderMappingQueryStringParams(reactRouterProps.location.search);

    return (
        <BTSuspense>
            <BidPOMapping {...params} {...reactRouterProps} />
        </BTSuspense>
    );
};

const EstimatePoMappingPage = (reactRouterProps: RouteComponentProps) => {
    const { ids, ...params } = parsePurchaseOrderMappingQueryStringParams(
        reactRouterProps.location.search
    );

    return (
        <BTSuspense>
            <EstimatePOMapping
                {...params}
                idsAndTypes={ids.map((x) => ({
                    id: x,
                    lineItemType: POMappingEntityTypes.Estimates,
                }))}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SelectionPoMappingPage = (reactRouterProps: RouteComponentProps) => {
    const params = parsePurchaseOrderMappingQueryStringParams(reactRouterProps.location.search);

    return (
        <BTSuspense>
            <SelectionPOMapping
                entityType={POMappingEntityTypes.Selections}
                {...params}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SelectionChoicePoMappingPage = (reactRouterProps: RouteComponentProps) => {
    const params = parsePurchaseOrderMappingQueryStringParams(reactRouterProps.location.search);

    return (
        <BTSuspense>
            <SelectionPOMapping
                entityType={POMappingEntityTypes.SelectionChoices}
                {...params}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const JobFromTemplatePage = (
    reactRouterProps: RouteComponentProps<{ leadId: string; jobType: string; leadSoldDate: string }>
) => {
    const leadId = parseInt(reactRouterProps.match.params.leadId);
    const jobType = parseInt(reactRouterProps.match.params.jobType);
    const leadSoldDate =
        reactRouterProps.match.params.leadSoldDate !== "0"
            ? reactRouterProps.match.params.leadSoldDate
            : undefined;

    return (
        <BTSuspense>
            <JobFromTemplate
                leadId={leadId}
                jobType={jobType}
                leadSoldDate={leadSoldDate}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const JobPriceSummaryPage = (reactRouterProps: RouteComponentProps<{ jobId: string }>) => {
    const jobId = parseInt(reactRouterProps.match.params.jobId);

    return (
        <BTSuspense>
            <JobPriceSummary jobId={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const JobPricePrintPage = (
    reactRouterProps: RouteComponentProps<{ jobId: string; printOnLoad?: string }>
) => {
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    const printOnLoad: boolean = reactRouterProps.match.params.printOnLoad === "true";

    return (
        <BTSuspense>
            <JobPriceSummaryPrint jobId={jobId} printOnLoad={printOnLoad} {...reactRouterProps} />
        </BTSuspense>
    );
};

const JobPrintPage = (
    reactRouterProps: RouteComponentProps<{ jobId: string; printOnLoad?: string }>
) => {
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    const printOnLoad: boolean = reactRouterProps.match.params.printOnLoad === "true";

    return (
        <BTSuspense>
            <JobPrint jobId={jobId} printOnLoad={printOnLoad} {...reactRouterProps} />
        </BTSuspense>
    );
};

const BidRequestPage = (reactRouterProps: RouteComponentProps) => {
    const routeValues: any = reactRouterProps.match.params;
    const bidRequestId = parseInt(routeValues.id);
    const jobId = parseInt(routeValues.jobId);
    const openedFrom: OpenedFrom = parseInt(routeValues.openedFrom);

    return (
        <BTSuspense>
            <BidRequest
                id={bidRequestId}
                jobId={jobId}
                openedFrom={openedFrom}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const BuilderRiskInsuranceSettingsPage = (
    reactRouterProps: RouteComponentProps<{
        settingsLayout: string;
        autoOpenForm: string;
    }>
) => {
    const settingsLayout: boolean = reactRouterProps.match.params.settingsLayout === "true";
    const autoOpenForm: boolean = reactRouterProps.match.params.autoOpenForm === "true";

    return (
        <BTSuspense>
            <BuilderRiskInsuranceSettings
                settingsLayout={settingsLayout}
                autoOpenForm={autoOpenForm}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ConversationPage = (
    reactRouterProps: RouteComponentProps<{
        entityId: string;
        entityType: string;
        builderId: string;
        jobId?: string;
    }>
) => {
    const entityId = parseInt(reactRouterProps.match.params.entityId);
    const entityType = parseInt(reactRouterProps.match.params.entityType);
    const builderId = parseInt(reactRouterProps.match.params.builderId);
    const jobId =
        reactRouterProps.match.params.jobId !== undefined
            ? parseInt(reactRouterProps.match.params.jobId)
            : undefined;

    return (
        <BTSuspense>
            <ConversationWithEntityPrompt
                entityId={entityId}
                entityType={entityType}
                jobId={jobId && jobId > 0 ? jobId : undefined}
                builderId={builderId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const TemplatePage = (reactRouterProps: RouteComponentProps<{ jobId: string }>) => {
    const urlParams = reactRouterProps.match.params;
    const jobId = parseInt(urlParams.jobId);
    // TODO Remove the fake handler after real endpoint is created
    return (
        <BTSuspense>
            <Template id={jobId} {...reactRouterProps} />
        </BTSuspense>
    );
};
const TemplateImportPage = (
    reactRouterProps: RouteComponentProps<{ jobId: string; tabId: string }>
) => {
    const jobId = parseInt(reactRouterProps.match.params.jobId);
    const tabId = parseInt(reactRouterProps.match.params.tabId);

    return (
        <BTSuspense>
            <TemplateImport
                jobId={jobId}
                tabId={tabId}
                // eslint-disable-next-line deprecate/member-expression
                onSubmit={() => routesWebforms.closeModal()}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};
const TemplateFromTemplatePage = (
    reactRouterProps: RouteComponentProps<{ externalJobId: string }>
) => {
    return (
        <BTSuspense>
            <TemplateFromTemplate
                externalJobId={reactRouterProps.match.params.externalJobId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const UnreadDocumentPage = () => {
    return (
        <BTSuspense>
            <UnreadDocument />
        </BTSuspense>
    );
};

const RFIPrintPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    const id: number = parseInt(qsValues.id);

    return (
        <BTSuspense>
            <RFIPrint id={id} />
        </BTSuspense>
    );
};

const BrandingSettingsPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <BrandingSettings {...reactRouterProps} />
        </BTSuspense>
    );
};

const ScheduleConflictReportPage = (
    reactRouterProps: RouteComponentProps<{ view: string; id?: string }>
) => {
    const id =
        reactRouterProps.match.params.id !== undefined
            ? parseInt(reactRouterProps.match.params.id)
            : undefined;
    const view = parseInt(reactRouterProps.match.params.view);

    return (
        <BTSuspense>
            <ScheduleConflictReport
                startingId={id}
                startingView={view}
                // eslint-disable-next-line deprecate/member-expression
                handleContinue={() => routesWebforms.closeModal()}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ScheduleNotificationPromptPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseNumbers: true,
    });

    let scheduleIds: number[] = [];
    if (qsValues.scheduleIds !== "") {
        scheduleIds = stringToArray(qsValues.scheduleIds);
    }

    return (
        <BTSuspense>
            <ScheduleNotificationPrompt
                schedulesToNotify={scheduleIds}
                // eslint-disable-next-line deprecate/member-expression
                onSend={() => routesWebforms.closeModal()}
                // eslint-disable-next-line deprecate/member-expression
                onClose={() => routesWebforms.closeModal()}
            />
        </BTSuspense>
    );
};

const NoAccessPage = (reactRouterProps: RouteComponentProps<{ reason: string }>) => {
    const routeValues: any = reactRouterProps.match.params;
    const reason = parseInt(routeValues.reason);

    return (
        <BTSuspense>
            <NoAccess reason={reason} {...reactRouterProps} />
        </BTSuspense>
    );
};

const SubscriptionItemSetupPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const id = parseInt(urlParams.id);

    return (
        <BTSuspense>
            <SubscriptionItemSetup id={id} {...reactRouterProps} />
        </BTSuspense>
    );
};

// subscription sign up codes grid
const SubscriptionSignUpCodesGridPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <SubscriptionSignUpCodesGridContainer
                {...reactRouterProps}
                entityType={ListEntityType.SubscriptionSignUpCodes}
                isTemplateMode={false}
            />
        </BTSuspense>
    );
};

// subscription sign up codes details modal page
const SubscriptionSignUpCodesDetailsPage = (
    reactRouterProps: RouteComponentProps<{
        id: string;
    }>
) => {
    const urlParams: any = reactRouterProps.match.params;
    const id = parseInt(urlParams.id);
    return (
        <BTSuspense>
            <SubscriptionSignUpCodesDetails id={id} {...reactRouterProps} />
        </BTSuspense>
    );
};

const SubscriptionPlanLimitsPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <SubscriptionPlanLimitsDetails {...reactRouterProps} />
        </BTSuspense>
    );
};

const SearchUtilsPage = () => {
    return (
        <BTSuspense>
            <SearchUtils />
        </BTSuspense>
    );
};

const FeatureFlagListPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <FeatureFlagListContainer
                {...reactRouterProps}
                entityType={ListEntityType.FeatureFlag}
            />
        </BTSuspense>
    );
};
const FeatureFlagPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const id = parseInt(urlParams.id);

    return (
        <BTSuspense>
            <FeatureFlag id={id} {...reactRouterProps} />
        </BTSuspense>
    );
};

const RebateListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab = reactRouterProps.match.params.tab;

    return (
        <BTSuspense>
            <RebateList {...listLayoutProps} {...reactRouterProps} selectedTab={tab} />
        </BTSuspense>
    );
};

const PurchasingDashboardPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PurchasingDashboard {...listLayoutProps} {...reactRouterProps} />
        </BTSuspense>
    );
};

const RebateReceiptReviewListPage = () => {
    return (
        <BTSuspense>
            <RebateReceiptReviewList />
        </BTSuspense>
    );
};

const RebateReceiptReviewQueuePage = () => {
    return (
        <BTSuspense>
            <RebateHawkQueue />
        </BTSuspense>
    );
};

const RebateHawkQueueListPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <RebateHawkQueueList {...reactRouterProps} />
        </BTSuspense>
    );
};

const PartnerListPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <PartnerListContainer {...reactRouterProps} entityType={ListEntityType.Partners} />
        </BTSuspense>
    );
};

const PartnerPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const id = parseInt(urlParams.id);

    return (
        <BTSuspense>
            <Partner id={id} {...reactRouterProps} />
        </BTSuspense>
    );
};

const BuilderSetupSummaryPage = (
    listLayoutProps: IListPageChildProps,
    routeProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <SetupSummary {...routeProps} />
        </BTSuspense>
    );
};

const BuilderSetupSearchPage = () => {
    return (
        <BTSuspense>
            <BuilderSetupSearch />
        </BTSuspense>
    );
};

const BuilderSetupPage = (reactRouterProps: RouteComponentProps<{ builderId: string }>) => {
    const urlParams = reactRouterProps.match.params;
    const builderId = urlParams.builderId;
    return (
        <BTSuspense>
            <BuilderSetup builderId={builderId} />
        </BTSuspense>
    );
};

const OwnerPortalRedirectPage = (reactRouterProps: RouteComponentProps) => {
    const urlParams: any = reactRouterProps.match.params;
    const jobId = parseInt(urlParams.jobId);

    return (
        <BTSuspense>
            <OwnerPortalRedirect {...reactRouterProps} jobId={jobId} />
        </BTSuspense>
    );
};

const ExcludeFromChurnPage = (reactRouterProps: RouteComponentProps<{ builderId: string }>) => {
    const builderId: number = parseInt(reactRouterProps.match.params.builderId);
    return (
        <BTSuspense>
            <ExcludeFromChurn builderID={builderId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const AccountingSettingsPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <AccountingSettings {...reactRouterProps} />
        </BTSuspense>
    );
};

const AuthRedirectPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qs: any = queryString.parse(reactRouterProps.location.search, { parseBooleans: true });
    const { code, state, clientId, ...rest } = qs;

    return (
        <BTSuspense>
            <AuthRedirect
                code={code}
                state={state}
                clientId={clientId}
                additionalParams={rest}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const OAuthRedirectPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qs: any = queryString.parse(reactRouterProps.location.search, { parseBooleans: true });
    return (
        <BTSuspense>
            <OAuthRedirect
                code={qs.code}
                realmId={qs.realmId}
                error={qs.error}
                state={qs.state}
                launch={qs.launch}
                disconnect={qs.disconnect}
                queryString={reactRouterProps.location.search}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const QuickBooksSyncSchedulePage = () => {
    return (
        <BTSuspense>
            <QuickBooksSyncSchedule />
        </BTSuspense>
    );
};

const BidExternalPage = (
    reactRouterProps: RouteComponentProps<{
        builderId: string;
        bidId: string;
        subId: string;
        shareToken?: string;
    }>
) => {
    return (
        <BTSuspense>
            <BidExternal
                builderId={reactRouterProps.match.params.builderId}
                bidId={reactRouterProps.match.params.bidId}
                subId={reactRouterProps.match.params.subId}
                shareToken={reactRouterProps.match.params.shareToken}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const PurchaseOrderExternalPage = (
    reactRouterProps: RouteComponentProps<{ poId: string; subId: string; shareToken?: string }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const isPdfMode: boolean = qsValues.isPdfMode ?? false;
    return (
        <BTSuspense>
            <PurchaseOrderExternal
                poId={reactRouterProps.match.params.poId}
                subId={reactRouterProps.match.params.subId}
                shareToken={reactRouterProps.match.params.shareToken}
                isPdfMode={isPdfMode}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SharedMediaExternalPage = (reactRouterProps: RouteComponentProps<{ shareToken: string }>) => {
    return (
        <BTSuspense>
            <SharedMedia
                shareToken={reactRouterProps.match.params.shareToken}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const CostCatalogSetupPage = (reactRouterProps: RouteComponentProps<{ viewType?: string }>) => {
    const viewType = +(reactRouterProps.match.params.viewType ?? CostCatalogViewType.Setup);
    return (
        <BTSuspense>
            <CostCatalog viewType={viewType} {...reactRouterProps} />
        </BTSuspense>
    );
};

const CostGroupPage = (reactRouterProps: RouteComponentProps<{ id: string }>) => {
    return (
        <BTSuspense>
            <CostGroup id={+reactRouterProps.match.params.id} {...reactRouterProps} />
        </BTSuspense>
    );
};

const CostItemPage = (
    reactRouterProps: RouteComponentProps<{ id: string; costCodeId: string }>
) => {
    let selectedCostCodeId: number | undefined = undefined;
    if (reactRouterProps.match.params.costCodeId) {
        selectedCostCodeId = parseInt(reactRouterProps.match.params.costCodeId);
    }
    return (
        <BTSuspense>
            <CostItem
                id={parseInt(reactRouterProps.match.params.id)}
                selectedCostCodeId={selectedCostCodeId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const CostCodeItemUsagePage: React.FunctionComponent<RouteComponentProps<{ id: string }>> = (
    reactRouterProps
) => {
    const builderInfo = useContext(BuilderInfoContext);
    return (
        <BTSuspense>
            <CostCodeItemUsage
                id={parseInt(reactRouterProps.match.params.id)}
                {...reactRouterProps}
                currencySymbol={builderInfo!.locale.currencyLocale.currencySymbol}
            />
        </BTSuspense>
    );
};

const LeadProposalExternalPage = (
    reactRouterProps: RouteComponentProps<{
        proposalId: string;
        leadId: string;
        builderId: string;
        isBuilder: string;
        shareToken?: string;
    }>
) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);

    return (
        <BTSuspense>
            <LeadProposalExternal
                encodedProposalId={reactRouterProps.match.params.proposalId}
                encodedLeadId={reactRouterProps.match.params.leadId}
                encodedBuilderId={reactRouterProps.match.params.builderId}
                isBuilder={reactRouterProps.match.params.isBuilder === "true"}
                shareToken={reactRouterProps.match.params.shareToken}
                checkoutId={qsValues.checkout_id}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const LeadProposalTemplatePage = (
    reactRouterProps: RouteComponentProps<{ builderId: string; proposalTemplateId: string }>
) => {
    const routeParams = reactRouterProps.match.params;

    const proposalId = parseInt(routeParams.proposalTemplateId);
    return (
        <BTSuspense>
            <LeadProposal id={proposalId} leadId={0} {...reactRouterProps} />
        </BTSuspense>
    );
};

const MobileLeadProposalPreviewPage = (
    reactRouterProps: RouteComponentProps<{ leadId?: string; proposalId: string }>
) => {
    const routeParams = reactRouterProps.match.params;

    const proposalId = parseInt(routeParams.proposalId);
    return (
        <BTSuspense>
            <MobileLeadProposalPreview id={proposalId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const HomeDepotSettingsPage = (reactRouterProps: RouteComponentProps) => {
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    const userContext = useContext(UserInfoContext);
    const noAccessDialog = <NoAccess {...reactRouterProps} reason={ForbiddenReason.Denied} />;
    return (
        <BTSuspense>
            {userContext?.isAdmin && (
                <HomeDepotSettings
                    {...reactRouterProps}
                    isFirstConnection={qsValues.thdFirstConnected}
                    error={qsValues.thdError}
                />
            )}
            {!userContext?.isAdmin && noAccessDialog}
        </BTSuspense>
    );
};

const LeadEmailWizardPage = (reactRouterProps: RouteComponentProps<{ id: string }>) => {
    const routeParams = reactRouterProps.match.params;

    const leadIds = stringToArray(routeParams.id);
    return (
        <BTSuspense>
            <LeadEmailWizard ids={leadIds} {...reactRouterProps} />
        </BTSuspense>
    );
};

const ContactSupportPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <ContactUs {...reactRouterProps} />
        </BTSuspense>
    );
};

const VendorStoreFinderPage = (reactRouterProps: RouteComponentProps<{ vendorType: string }>) => {
    const routeParams = reactRouterProps.match.params;
    const vendorType = parseInt(routeParams.vendorType);
    return (
        <BTSuspense>
            <VendorStoreFinder vendorType={vendorType} {...reactRouterProps} />
        </BTSuspense>
    );
};

const SubTradeAgreementPage = (
    reactRouterProps: RouteComponentProps<{ builderId: string; subId: string }>
) => {
    const builderId = parseInt(reactRouterProps.match.params.builderId);
    const subId = parseInt(reactRouterProps.match.params.subId);
    return (
        <BTSuspense>
            <TradeAgreement {...reactRouterProps} builderId={builderId} subId={subId} />
        </BTSuspense>
    );
};

const TradeAgreementPrintPage = (
    reactRouterProps: RouteComponentProps<{ tradeAgreementId: string }>
) => {
    const id = parseInt(reactRouterProps.match.params.tradeAgreementId);
    return (
        <BTSuspense>
            <TradeAgreementPrint {...reactRouterProps} id={id} />
        </BTSuspense>
    );
};

const SignupPriceCodePage = () => {
    return (
        <BTSuspense>
            <SignupPriceCode />
        </BTSuspense>
    );
};

const SignupWhatsNextPage = (
    reactRouterProps: RouteComponentProps<{
        builderId: string;
        fromEmail: string;
        isRewin: string;
        loginId: string;
    }>
) => {
    const id = reactRouterProps.match.params.builderId;
    const fromEmail: boolean = reactRouterProps.match.params.fromEmail === "true";
    const isRewin: boolean = reactRouterProps.match.params.isRewin === "true";
    const loginId: string = reactRouterProps.match.params.loginId;
    return (
        <BTSuspense>
            <SignupWhatsNext
                {...reactRouterProps}
                builderId={id}
                fromEmail={fromEmail}
                isRewin={isRewin}
                loginId={loginId}
            />
        </BTSuspense>
    );
};

const LandingListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Summary" />
            <LandingPage {...reactRouterProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

const OwnerSummaryListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    return (
        <BTSuspense>
            <PageTitle title="Summary" />
            <OwnerSummary {...route} {...listLayoutProps} />
        </BTSuspense>
    );
};

const JobPickerRouterPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    const params = new URLSearchParams(window.location.search);
    const nextUrl = params.get("nextUrl") ?? "/";
    return <JobPickerRouter {...listLayoutProps} nextUrl={nextUrl} {...route} />;
};

const DailyLogListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    return (
        <BTSuspense>
            <PageTitle title="Daily Logs" />
            <DailyLogList {...route} {...listLayoutProps} entityType={ListEntityType.DailyLogs} />
        </BTSuspense>
    );
};

const FrozenBuilderPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <FrozenBuilderWrapper {...reactRouterProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

const ToDoListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="To-Do's" />
            <ToDoListWithPanel
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.Todos}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const OwnerProposalPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <OwnerProposal {...reactRouterProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

const EstimatesPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Estimate" />
            <Estimate {...reactRouterProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

const WebviewEstimatesPage = (route: RouteComponentProps<IEstimateWebviewRouteParams>) => {
    return (
        <BTSuspense>
            <EstimateWebviewContainer
                webviewRouteParams={route.match.params}
                history={route.history}
                location={route.location}
                match={route.match}
            />
        </BTSuspense>
    );
};

const OwnerInvoicePaymentsListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Invoice Payments" />
            <InvoicePaymentsList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.InvoicePayments}
            />
        </BTSuspense>
    );
};

const OnlinePaymentReportPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab =
        OnlinePaymentTabs[reactRouterProps.match.params.tab as keyof typeof OnlinePaymentTabs];

    return (
        <BTSuspense>
            <PageTitle title="Online Payment Report" />
            <OnlinePaymentReports {...reactRouterProps} {...listLayoutProps} tab={tab} />
        </BTSuspense>
    );
};

const OnlinePaymentReportSubsPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Online Payment Report" />
            <OnlinePaymentsList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.OnlinePaymentReport}
                showLayout
            />
        </BTSuspense>
    );
};

const CreditMemoListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const qsParams = parseParams<{ isExternal: string }>(reactRouterProps.location.search);
    const isExternal = qsParams.isExternal === "true";
    return (
        <BTSuspense>
            <PageTitle title="Credit Memos" />
            <CreditMemosList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.CreditMemos}
                isExternal={isExternal}
            />
        </BTSuspense>
    );
};

const BidPackageListBuildersPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(reactRouterProps);
    return (
        <BTSuspense>
            <PageTitle title="Bids" />
            <BidPackageList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.BidPackages}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const BidsListSubsPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Bids" />
            <BidList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.SubsBids}
            />
        </BTSuspense>
    );
};

const ClientUpdateListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Client Updates" />
            <ClientUpdateList {...reactRouterProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

const PaymentsOverviewPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Buildertrend Payments" />
            <PaymentsOverview {...reactRouterProps} />
        </BTSuspense>
    );
};

const JobsListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Jobs" />
            <JobsList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.JobsitesList}
                tab={JobListTabs.List}
            />
        </BTSuspense>
    );
};

const JobsListMapPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Job's Map" />
            <JobsMapList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.JobsMap}
            />
        </BTSuspense>
    );
};

const TemplatesListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab =
        TemplateListTabs[reactRouterProps.match.params.tab as keyof typeof TemplateListTabs];
    return (
        <BTSuspense>
            <PageTitle title="Templates" />
            <TemplateListLanding {...listLayoutProps} {...reactRouterProps} tab={tab} />
        </BTSuspense>
    );
};

const WarrantyListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Warranties" />
            <WarrantiesList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.Warranty}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const OwnerInvoicesListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const qsParams = parseParams<{
        isExternal: string;
        checkout_id: string;
    }>(reactRouterProps.location.search);
    const isExternal = qsParams.isExternal === "true";
    const checkoutId = qsParams.checkout_id as string | undefined;
    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Owner Invoices" />
            <OwnerInvoiceList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.CustomerInvoices}
                isExternal={isExternal}
                checkoutId={checkoutId}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const CommentsListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab =
        CommentListTabKeys[reactRouterProps.match.params.tab as keyof typeof CommentListTabKeys];
    return (
        <BTSuspense>
            <PageTitle title="Comments" />
            <CommentsList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.Comments}
                tab={tab}
            />
        </BTSuspense>
    );
};

const SurveyListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab =
        SurveyListContainerTabs[
            reactRouterProps.match.params.tab as keyof typeof SurveyListContainerTabs
        ];

    return (
        <BTSuspense>
            <PageTitle title="Surveys" />
            <SurveyListContainer {...reactRouterProps} {...listLayoutProps} activeTab={tab} />
        </BTSuspense>
    );
};

const OwnerSurveyListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Surveys" />
            <OwnerSurveyList
                entityType={ListEntityType.OwnerSurvey}
                {...reactRouterProps}
                {...listLayoutProps}
            />
        </BTSuspense>
    );
};

const SpecificationListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Specifications" />
            <SpecificationList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.Specification}
            />
        </BTSuspense>
    );
};

const MessagesListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{
        folder: string;
        messageId?: string;
    }>
) => {
    const routeProps = getMessageListPropsFromRoute(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Messages" />
            <MessagesList
                {...reactRouterProps}
                {...listLayoutProps}
                {...routeProps}
                entityType={ListEntityType.Messages}
            />
        </BTSuspense>
    );
};

const OldMessagesListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Messages" />
            <MessagesList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.Messages}
                systemFilter={systemFilter}
                folderId={EmailFolder.Inbox}
            />
        </BTSuspense>
    );
};

const DocumentListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<ISharedMediaRouteProps>
) => {
    const directoryTypeName =
        DirectoryType[reactRouterProps.match.params.directoryType as keyof typeof DirectoryType];
    const folderId = parseInt(reactRouterProps.match.params.folderId);

    return (
        <BTSuspense>
            <PageTitle title="Documents" />
            <DocumentList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.MediaList}
                allBuildersSelected={false}
                directoryType={directoryTypeName}
                folderId={folderId}
            />
        </BTSuspense>
    );
};

const PhotoListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<ISharedMediaRouteProps>
) => {
    const directoryTypeName =
        DirectoryType[reactRouterProps.match.params.directoryType as keyof typeof DirectoryType];
    const folderId = parseInt(reactRouterProps.match.params.folderId);

    return (
        <BTSuspense>
            <PageTitle title="Photos" />
            <PhotoList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.MediaList}
                allBuildersSelected={false}
                directoryType={directoryTypeName}
                folderId={folderId}
            />
        </BTSuspense>
    );
};

const VideoListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<ISharedMediaRouteProps>
) => {
    const directoryTypeName = DirectoryType[reactRouterProps.match.params.directoryType];
    const folderId = parseInt(reactRouterProps.match.params.folderId);

    return (
        <BTSuspense>
            <PageTitle title="Videos" />
            <VideoList
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.MediaList}
                allBuildersSelected={false}
                directoryType={directoryTypeName}
                folderId={folderId}
            />
        </BTSuspense>
    );
};

const ChatListPage = () => {
    return (
        <BTSuspense>
            <PageTitle title="Chat" />
            <ChatList />
        </BTSuspense>
    );
};

const SelectionsListPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab: SelectionListContainerTabs =
        SelectionListContainerTabs[
            reactRouterProps.match.params.tab as keyof typeof SelectionListContainerTabs
        ];

    const systemFilter = getSystemFilter(reactRouterProps);

    return (
        <BTSuspense>
            <PageTitle title="Selections" />
            <SelectionListContainer
                {...listLayoutProps}
                {...reactRouterProps}
                tab={tab}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const TimeClockPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ tab: string }>
) => {
    const tab =
        ShiftListContainerTabs[
            reactRouterProps.match.params.tab as keyof typeof ShiftListContainerTabs
        ];

    return (
        <BTSuspense>
            <PageTitle title="Time Clock" />
            <TimeCardPage
                {...reactRouterProps}
                {...listLayoutProps}
                entityType={ListEntityType.TimeClock}
                currentTab={tab}
            />
        </BTSuspense>
    );
};

const WebviewChatListPage = (
    route: RouteComponentProps<IWebviewRouteParams & { interfaceVersion: string }>
) => {
    return (
        <BTSuspense>
            <PageTitle title="Chat" />
            <ChatWebviewContainer webviewRouteParams={route.match.params} />
        </BTSuspense>
    );
};

const WebviewCommentThreadPage = (
    routeProps: RouteComponentProps<
        {
            builderId: string;
            entityId: string;
            entityType: string;
            jobId: string;
        } & IWebviewRouteParams
    >
) => {
    const builderId = parseInt(routeProps.match.params.builderId);
    const entityId = parseInt(routeProps.match.params.entityId);
    const entityType = parseInt(routeProps.match.params.entityType);
    const platform = routeProps.match.params.platform;

    const jobIdParam = routeProps.match.params.jobId;
    const jobId = jobIdParam !== undefined ? parseInt(jobIdParam) : undefined;

    return (
        <BTSuspense>
            <CommentThread
                builderId={builderId}
                entityId={entityId}
                entityType={entityType}
                jobId={jobId}
                platform={platform}
                webviewConfig={routeProps.match.params}
                {...routeProps}
            />
        </BTSuspense>
    );
};

const WebviewOwnerInvoicePrintPage = (
    routeProps: RouteComponentProps<
        {
            invoiceId: string;
            showCustomFields: string;
            isOwnerPreview: string;
            showPaymentCode: string;
            showInvoiceDescription: string;
        } & IWebviewRouteParams
    >
) => {
    const invoiceId = parseInt(routeProps.match.params.invoiceId);
    const showCustomFields = routeProps.match.params.showCustomFields === "true";
    const isOwnerPreview = routeProps.match.params.isOwnerPreview === "true";
    const showPaymentCode = routeProps.match.params.showPaymentCode === "true";
    const showInvoiceDescription = routeProps.match.params.showInvoiceDescription === "true";

    return (
        <BTSuspense>
            <OwnerInvoicePrint
                showCustomFields={showCustomFields}
                showPaymentCode={showPaymentCode}
                showInvoiceDescription={showInvoiceDescription}
                invoiceId={invoiceId}
                isOwnerPreview={isOwnerPreview}
                isPrintPage={true}
                printOnLoad={false}
            />
        </BTSuspense>
    );
};

const BTMobileEditorPage = (
    routeProps: RouteComponentProps<
        {
            viewMode: "embed" | "fullscreen";
            interfaceVersion: string;
        } & IWebviewRouteParams
    >
) => {
    return (
        <BTSuspense>
            <BTEditorMobile {...routeProps.match.params} />
        </BTSuspense>
    );
};

const ScheduleBaselineListPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Schedule" />
            <ScheduleListContainer
                {...route}
                {...listLayoutProps}
                currentTab={ScheduleListContainerTabs.Baseline}
                allBuildersSelected={false}
            />
        </BTSuspense>
    );
};

const ScheduleListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    // TODO: SPA-Schedules -- clean up allBuildersSelected prop when clearing from Gantt usage
    const tabParm = Number((route.match.params as any).tab);
    const systemFilter = getSystemFilter(route);

    return (
        <BTSuspense>
            <PageTitle title="Schedule" />
            <ScheduleListContainer
                {...route}
                {...listLayoutProps}
                currentTab={tabParm}
                allBuildersSelected={false}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const bdsWorkdayExceptionFullPageExperience = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps<{ workdayExceptionID: string; jobID: string; isOffline: string }>
) => {
    const workdayExceptionID: number = Number(route.match.params.workdayExceptionID);
    const jobID: number = Number(route.match.params.jobID);
    const isOffline: boolean = route.match.params.isOffline === "true";
    return (
        <BTSuspense>
            <WorkdayExceptionBDS
                {...route}
                {...listLayoutProps}
                workdayExceptionID={workdayExceptionID}
                jobIds={listLayoutProps.jobIds}
                jobID={jobID}
                isOffline={isOffline}
            />
        </BTSuspense>
    );
};

const ChangeOrderListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    const systemFilter = getSystemFilter(route);

    return (
        <BTSuspense>
            <PageTitle title="Change Orders" />
            <ShowOnPortal
                builder
                subs
                owner
                render={({ isOwner, isBuilder }) => {
                    if (!isOwner) {
                        return (
                            <ChangeOrderList
                                {...route}
                                {...listLayoutProps}
                                entityType={
                                    !isBuilder
                                        ? ListEntityType.SubChangeOrders
                                        : ListEntityType.ChangeOrders
                                }
                                systemFilter={systemFilter}
                            />
                        );
                    }

                    return (
                        <OwnerChangeOrderList
                            {...route}
                            {...listLayoutProps}
                            jobId={listLayoutProps.jobIds[0]}
                        />
                    );
                }}
            />
        </BTSuspense>
    );
};

const DepositListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps<any>) => {
    let checkoutId: string | undefined;
    if (window.location.search.includes("checkout_id")) {
        // eslint-disable-next-line deprecate/member-expression
        const qsParams: any = queryString.parse(window.location.search);
        checkoutId = qsParams.checkout_id;
    }

    return (
        <BTSuspense>
            <PageTitle title="Deposits" />
            <DepositList
                {...route}
                {...listLayoutProps}
                entityType={ListEntityType.Deposits}
                checkoutId={checkoutId}
            />
        </BTSuspense>
    );
};

const RFIsListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    const systemFilter = getSystemFilter(route);

    return (
        <BTSuspense>
            <PageTitle title="RFIs" />
            <RFIsList
                {...route}
                {...listLayoutProps}
                entityType={ListEntityType.RFIList}
                allBuildersSelected={false}
                systemFilter={systemFilter}
            />
        </BTSuspense>
    );
};

const NotificationHistoryListPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Notification History" />
            <NotificationHistoryList
                {...route}
                {...listLayoutProps}
                entityType={ListEntityType.NotificationHistory}
            />
        </BTSuspense>
    );
};

const NotificationListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    return (
        <BTSuspense>
            <PageTitle title="Notifications" />
            <NotificationHistoryList
                {...route}
                {...listLayoutProps}
                entityType={ListEntityType.NotificationHistory}
            />
        </BTSuspense>
    );
};

const SettingsListPage = (listLayoutProps: IListPageChildProps, route: RouteComponentProps) => {
    return (
        <BTSuspense>
            <PageTitle title="Settings" />
            <SettingList {...route} {...listLayoutProps} entityType={ListEntityType.Settings} />
        </BTSuspense>
    );
};

const OnlinePaymentSettingsFullPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Online Payments" />
            <OnlinePaymentSettings {...route} {...listLayoutProps} />
        </BTSuspense>
    );
};

const OnlinePaymentSettingsPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <OnlinePaymentSettings {...reactRouterProps} />
        </BTSuspense>
    );
};

const InboundHostedOnboardingPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Online Payments" />
            <InboundHostedOnboarding {...route} {...listLayoutProps} />
        </BTSuspense>
    );
};

const PaymentTosPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps<{ service: string }>
) => {
    return (
        <BTSuspense>
            <PageTitle title="Pending Terms of Service to Sign" />
            <PaymentTos {...route} {...listLayoutProps} />
        </BTSuspense>
    );
};

const LegacyWebformsServerErrorPage = () => {
    const loginType = getGlobalValue("loginTypeInt");

    if (loginType === null || loginType === BTLoginTypes.ALL) {
        return (
            <BTSuspense>
                <PageTitle title="Error Encountered" />
                <ServerError>
                    <a href={routesWebforms.Login.GetLoginURL({})} target="_top">
                        Back to login
                    </a>
                </ServerError>
            </BTSuspense>
        );
    }

    const summaryPageLink = routes.landingPage.getLinkForLoginType(loginType, true);

    return (
        <BTSuspense>
            <PageTitle title="Error Encountered" />
            <ServerError>
                <a href={summaryPageLink} target="_top">
                    Back to summary page
                </a>
            </ServerError>
        </BTSuspense>
    );
};

const VendorReceiptPage = (
    reactRouterProps: RouteComponentProps<{
        vendorType: string;
        vendorPurchaseId: string;
    }>
) => {
    const vendorPurchaseId = parseInt(reactRouterProps.match.params.vendorPurchaseId);
    const vendorType = parseInt(reactRouterProps.match.params.vendorType) as VendorTypes;

    return (
        <BTSuspense>
            <VendorReceipt id={vendorPurchaseId} vendorType={vendorType} {...reactRouterProps} />
        </BTSuspense>
    );
};

const OwnerSetupPage = (
    reactRouterProps: RouteComponentProps<{ jobId: string; externalId?: string }>
) => {
    return (
        <BTSuspense>
            <OwnerSetup
                jobId={Number(reactRouterProps.match.params.jobId)}
                externalId={reactRouterProps.match.params.externalId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SubSetupPage = (
    reactRouterProps: RouteComponentProps<{
        id: string;
        initialTab: string;
        openTradeAgreement: string;
    }>
) => {
    const id = Number(reactRouterProps.match.params.id);
    const initialTab = reactRouterProps.match.params.initialTab;
    const openTradeAgreement = reactRouterProps.match.params.openTradeAgreement === "true";

    return (
        <BTSuspense>
            <SubSetup
                id={id}
                initialTab={initialTab as SubSetupTabKeys}
                openTradeAgreement={openTradeAgreement}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const SharedContentPage = (reactRouterProps: RouteComponentProps<{ shareToken: string }>) => {
    const shareToken = reactRouterProps.match.params.shareToken;
    // eslint-disable-next-line deprecate/member-expression
    const qsValues: any = queryString.parse(reactRouterProps.location.search);
    return (
        <BTSuspense>
            <SharedContent
                shareToken={shareToken}
                checkoutId={qsValues.checkout_id}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const MicrodepositVerificationExternal = (
    reactRouterProps: RouteComponentProps<{
        shareToken: string;
        builderId: string;
        merchantPaymentMethodId: string;
    }>
) => {
    return (
        <BTSuspense>
            <PaymentServiceBankAccountExternal
                shareToken={reactRouterProps.match.params.shareToken}
                encodedBuilderId={reactRouterProps.match.params.builderId}
                encodedMerchantPaymentMethodId={
                    reactRouterProps.match.params.merchantPaymentMethodId
                }
            />
        </BTSuspense>
    );
};

// Mobile pay online wrappers
const OwnerInvoicePayOnlineMobileWrapper = (
    reactRouterProps: RouteComponentProps<{ id: string; jobId: string }>
) => {
    const qsValues = parseParams<{
        redirectDetails: string;
        landingUrl: string;
        isMobileInAppBrowser: boolean;
    }>(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    let redirectDetails: IPaymentMethodRedirectDetails | undefined;
    if (qsValues.redirectDetails) {
        redirectDetails = JSON.parse(decodeURIComponent(qsValues.redirectDetails));
        if (qsValues.landingUrl) {
            redirectDetails!.oauthRedirectLandingUrl = qsValues.landingUrl;
        }
    }

    return (
        <BTSuspense>
            <OwnerInvoicePayOnline
                id={parseInt(reactRouterProps.match.params.id)}
                jobId={parseInt(reactRouterProps.match.params.jobId)}
                isFromMobileApp
                isMobileInAppBrowser={qsValues.isMobileInAppBrowser}
                onClose={() => {}}
                redirectDetails={redirectDetails}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};
const LeadProposalPayOnlineMobileWrapper = (
    reactRouterProps: RouteComponentProps<{ id: string }>
) => {
    const qsValues = parseParams<{
        isMobileInAppBrowser: boolean;
    }>(reactRouterProps.location.search, {
        parseBooleans: true,
    });
    return (
        <BTSuspense>
            <LeadProposalPayOnlineMobile
                id={parseInt(reactRouterProps.match.params.id)}
                isMobileInAppBrowser={qsValues.isMobileInAppBrowser}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const LeadProposalPayOnlineWebformsWrapper = (
    reactRouterProps: RouteComponentProps<{ id: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const proposalId = parseInt(urlParams.id);
    return (
        <BTSuspense>
            <LeadProposalPayOnlineWebforms id={proposalId} {...reactRouterProps} />
        </BTSuspense>
    );
};

const ExternalLeadProposalPayOnlineWebformsWrapper = (
    reactRouterProps: RouteComponentProps<{ encryptedProposalId: string; builderId: string }>
) => {
    const urlParams = reactRouterProps.match.params;

    return (
        <BTSuspense>
            <LeadProposalPayOnlineWebforms
                encryptedProposalId={urlParams.encryptedProposalId}
                encryptedBuilderId={urlParams.builderId}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const FinalizeCreateAccountPage = (
    reactRouterProps: RouteComponentProps<{ shareToken: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const shareToken = urlParams.shareToken;
    const qsValues = parseParams<{ username: string }>(reactRouterProps.location.search);
    const username: string | undefined = qsValues.username;

    return (
        <BTSuspense>
            <FinalizeCreateAccount
                shareToken={shareToken}
                username={username}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const ExternalInviteExpiredPage = () => {
    // redirected here if an auth0 user tries to link from activations in error
    return (
        <BTSuspense>
            <InviteExpiredPageWithHeader />
        </BTSuspense>
    );
};

const SignedInAndLinkedActivationPage = (
    reactRouterProps: RouteComponentProps<{ shareToken: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const shareToken = urlParams.shareToken;
    const qsValues = parseParams<{ username: string }>(reactRouterProps.location.search);
    const username = qsValues.username;
    return (
        <BTSuspense>
            <SignedInAndLinkedPage
                shareToken={shareToken}
                username={username ?? ""}
                history={reactRouterProps.history}
                location={reactRouterProps.location}
                match={reactRouterProps.match}
            />
        </BTSuspense>
    );
};

const AdminLogExceptionInSentryPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <AdminLogExceptionInSentry {...reactRouterProps} />
        </BTSuspense>
    );
};

const ScheduleConfirmationExternalPage = (
    reactRouterProps: RouteComponentProps<{
        builderId: string;
        userId: string;
        shareToken: string;
    }>
) => {
    return (
        <BTSuspense>
            <ScheduleConfirmationExternal
                builderId={reactRouterProps.match.params.builderId}
                userId={reactRouterProps.match.params.userId}
                shareToken={reactRouterProps.match.params.shareToken}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const StuckPaymentUtilityPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <StuckPaymentUtility {...reactRouterProps} />
        </BTSuspense>
    );
};

const BRIUpdateUtilityPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <BRIUpdateUtility {...reactRouterProps} />
        </BTSuspense>
    );
};

const MobileLoginEmailUsernameEntryPage = (reactRouterProps: RouteComponentProps) => {
    const qsValues: any = parseParams(reactRouterProps.location.search);
    const clientId = qsValues.client_id;
    const redirectUri = qsValues.redirect_uri;
    const stateParam = qsValues.state;
    const audience = qsValues.audience;
    const scope = qsValues.scope;
    const codeChallengeMethod = qsValues.code_challenge_method;
    const codeChallenge = qsValues.code_challenge;
    return (
        <BTSuspense>
            <MobileLoginEmailUsernameEntry
                clientId={clientId}
                redirectUri={redirectUri}
                stateParam={stateParam}
                audience={audience}
                scope={scope}
                codeChallengeMethod={codeChallengeMethod}
                codeChallenge={codeChallenge}
                {...reactRouterProps}
            />
        </BTSuspense>
    );
};

const FinancialDocumentCollectionPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Upload Documents" />
            <FinancialDocumentCollection {...route} {...listLayoutProps} />
        </BTSuspense>
    );
};

const PasswordResetPage = (reactRouterProps: RouteComponentProps) => {
    const qsValues = parseParams<{ id: number; jwt: string }>(reactRouterProps.location.search);

    const globalUserId = qsValues.id;
    const jwt = qsValues.jwt;

    return <PasswordReset jwt={jwt!} globalUserId={globalUserId!} {...reactRouterProps} />;
};

const CostCodesPage = (listLayoutProps: IListPageChildProps, routeProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <CostCodeList {...routeProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

const JobCostingBudgetPage = (
    listLayoutProps: IListPageChildProps,
    routeProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <PageTitle title="Job Costing Budget" />
            <JobCostingBudget
                {...routeProps}
                {...listLayoutProps}
                entityType={ListEntityType.JobCostingBudget}
            />
        </BTSuspense>
    );
};
const LeadOpportunityListPage = (
    listLayoutProps: IListPageChildProps,
    route: RouteComponentProps
) => {
    const systemFilter = getSystemFilter(route);
    return (
        <BTSuspense>
            <LeadListContainer {...route} {...listLayoutProps} systemFilter={systemFilter} />
        </BTSuspense>
    );
};

const AdminMerchantPaymentListPage = (reactRouterProps: RouteComponentProps) => {
    return (
        <BTSuspense>
            <AdminOnlinePaymentReports {...reactRouterProps} />
        </BTSuspense>
    );
};
const AdminMainPage = () => {
    return (
        <BTSuspense>
            <AdminMain />
        </BTSuspense>
    );
};

const AdminSubscriptionManagerPage = () => {
    return (
        <BTSuspense>
            <AdminSubscriptionManager />
        </BTSuspense>
    );
};

const AdminUsernameLookupPage = (reactRouterProps: RouteComponentProps) => {
    const params = parseParams<{
        username?: string;
    }>(reactRouterProps.location.search);
    return (
        <BTSuspense>
            <AdminUsernameLookup {...reactRouterProps} username={params.username ?? ""} />
        </BTSuspense>
    );
};

const HomewnerManagementPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps<{ jobId: string }>
) => {
    const urlParams = reactRouterProps.match.params;
    const jobId = urlParams.jobId ? parseInt(urlParams.jobId) : 0;

    return (
        <BTSuspense>
            <BuilderInfoContext.Consumer>
                {(builderInfo) => (
                    <>
                        <PageTitle title="Manage homeowners" />
                        <OwnerManagement
                            {...reactRouterProps}
                            {...listLayoutProps}
                            builderInfo={builderInfo}
                            jobId={jobId}
                        />
                    </>
                )}
            </BuilderInfoContext.Consumer>
        </BTSuspense>
    );
};

const ClientJobPermissionsPage = (
    listLayoutProps: IListPageChildProps,
    reactRouterProps: RouteComponentProps
) => {
    return (
        <BTSuspense>
            <ClientJobPermissions {...reactRouterProps} {...listLayoutProps} />
        </BTSuspense>
    );
};

@track(undefined, {
    dispatch: (data) => dispatchTracking(data),
    process: (ownTrackingData) => processTracking(ownTrackingData),
})
class AppInternal extends Component<ITrackingProp> {
    render() {
        // Not ideal long-term here, but we need this to set the brand color on our /app page and the error page for the footer. Need to probably put it in context long-term from the nav bar request
        return (
            <BdsGlobalProvider>
                <AppProvider sendPageView>
                    <BrowserRouter basename={routes.appBase + "/"}>
                        <AppRouteChange />
                        <RedirectToSetupSummary />

                        {/* eslint-disable-next-line react/forbid-elements */}
                        <Switch>
                            <Route path={routes.apiDocs} component={ApiDocsPage} />
                            {/* BTAdmin Routes */}
                            <BTAdminLayout
                                path={routes.admin.users.usernameLookup}
                                component={AdminUsernameLookupPage}
                            />
                            {/* Global routes */}
                            <Route
                                path={routes.notConnected.path}
                                component={OrgLinkNotConnectedEmptyStatePage}
                            />
                            <Route path={routes.passwordReset.path} component={PasswordResetPage} />

                            <ListPageLayout
                                path={routes.jobPicker.selectJob}
                                component={JobPickerRouterPage}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                                jobPickerDisplayMode={JobPickerDisplayModes.JobPickerRouter}
                                selectedMenuItem={MenuItemName.None}
                                allowGlobalJob
                                keepQueryParams
                                isJobPickerRouter
                            />
                            <DetailPageLayout
                                path={routes.account.frozen}
                                component={FrozenBuilderPage}
                            />
                            <ListPageLayout
                                path={routes.landingPage.path}
                                showJobPicker
                                component={LandingListPage}
                                selectedMenuItem={MenuItemName.Summary}
                                jobPickerDisplayMode={JobPickerDisplayModes.Jobsites}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            {/* Customer Portal Routes */}
                            <ListPageLayout
                                path={routes.home}
                                exact
                                component={IndexPage}
                                selectedMenuItem={MenuItemName.None}
                                showJobPicker={false}
                            />
                            <Route path={routes.entityLink.path} component={EntityLinkRouter} />
                            <ListPageLayout
                                path={routes.ownerSummary.getLink()}
                                showJobPicker={false}
                                component={OwnerSummaryListPage}
                                selectedMenuItem={MenuItemName.Summary}
                                hideOwnerHeaderInfo
                            />
                            <ListPageLayout
                                path={routes.lead.leadList}
                                showJobPicker={false}
                                component={LeadOpportunityListPage}
                                selectedMenuItem={MenuItemName.Leads}
                            />
                            <ListPageLayout
                                path={routes.dailyLog.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={DailyLogListPage}
                                selectedMenuItem={MenuItemName.DailyLogs}
                                jobPickerDisplayMode={JobPickerDisplayModes.DailyLogs}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.internalUsers.list}
                                showJobPicker={false}
                                component={InternalUsersListPage}
                                selectedMenuItem={MenuItemName.Users}
                            />
                            <ListPageLayout
                                path={routes.contact.list}
                                showJobPicker={false}
                                component={ContactsListPage}
                                selectedMenuItem={MenuItemName.Contacts}
                            />
                            <ListPageLayout
                                path={routes.sub.list}
                                showJobPicker={false}
                                component={SubsListPage}
                                selectedMenuItem={MenuItemName.Subs}
                            />
                            <ListPageLayout
                                path={routes.toDo.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={ToDoListPage}
                                selectedMenuItem={MenuItemName.ToDos}
                                jobPickerDisplayMode={JobPickerDisplayModes.ToDos}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.estimate.proposalPrint}
                                component={ProposalPrintPage}
                            />
                            <ListPageLayout
                                path={routes.estimate.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={EstimatesPage}
                                selectedMenuItem={MenuItemName.Estimate}
                                jobPickerDisplayMode={JobPickerDisplayModes.Estimates}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            <ListPageLayout
                                path={routes.estimate.ownerProposal}
                                showJobPicker={false}
                                component={OwnerProposalPage}
                                selectedMenuItem={MenuItemName.Estimate}
                            />
                            <ListPageLayout
                                path={routes.bidPackage.list}
                                showJobPicker={isInPortal({ builder: true })}
                                component={BidPackageListBuildersPage}
                                selectedMenuItem={MenuItemName.Bids}
                                jobPickerDisplayMode={JobPickerDisplayModes.Bids}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.bid.list}
                                showJobPicker={false}
                                component={BidsListSubsPage}
                                selectedMenuItem={MenuItemName.Bids}
                                jobPickerDisplayMode={JobPickerDisplayModes.Bids}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.clientUpdates.list}
                                showJobPicker={true}
                                component={ClientUpdateListPage}
                                selectedMenuItem={MenuItemName.DailyLogs}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                                jobPickerDisplayMode={JobPickerDisplayModes.Custom}
                            />

                            <ListPageLayout
                                path={routes.onlinePayments.paymentOverview}
                                selectedMenuItem={MenuItemName.AcceptPaymentsOnline}
                                component={PaymentsOverviewPage}
                                showJobPicker={false}
                            />

                            <ListPageLayout
                                path={routes.job.flatDetails}
                                component={JobPage}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                                jobPickerDisplayMode={JobPickerDisplayModes.JobPickerRouter}
                                selectedMenuItem={MenuItemName.None}
                                allowGlobalJob
                                keepQueryParams
                            />
                            <ListPageLayout
                                path={routes.job.list}
                                showJobPicker={false}
                                component={JobsListPage}
                                selectedMenuItem={MenuItemName.JobsList}
                            />
                            <ListPageLayout
                                path={routes.job.map}
                                showJobPicker={false}
                                component={JobsListMapPage}
                                selectedMenuItem={MenuItemName.JobsMap}
                            />
                            <ListPageLayout
                                path={routes.template.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={TemplatesListPage}
                                selectedMenuItem={MenuItemName.Templates}
                                jobPickerDisplayMode={JobPickerDisplayModes.Jobsites}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            {/* Specification pages */}
                            <PrintPageLayout
                                path={routes.specification.print}
                                component={SpecificationPrintPage}
                            />
                            <ListPageLayout
                                path={routes.specification.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={SpecificationListPage}
                                selectedMenuItem={MenuItemName.Specifications}
                                jobPickerDisplayMode={JobPickerDisplayModes.Specifications}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            <DetailPageLayout
                                path={routes.messages.print}
                                component={MessagePrintPage}
                            />

                            <ListPageLayout
                                path={routes.messages.details}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={MessagesListPage}
                                selectedMenuItem={MenuItemName.Messages}
                                jobPickerDisplayMode={JobPickerDisplayModes.Messages}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.messages.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={MessagesListPage}
                                selectedMenuItem={MenuItemName.Messages}
                                jobPickerDisplayMode={JobPickerDisplayModes.Messages}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />

                            {/* Temporary to maintain old routes */}
                            <ListPageLayout
                                path={routes.messages.oldList}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={OldMessagesListPage}
                                selectedMenuItem={MenuItemName.Messages}
                                jobPickerDisplayMode={JobPickerDisplayModes.Messages}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.document.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={DocumentListPage}
                                selectedMenuItem={MenuItemName.Documents}
                                jobPickerDisplayMode={JobPickerDisplayModes.Documents}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                                allowGlobalDocs
                            />
                            <ListPageLayout
                                path={routes.photo.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={PhotoListPage}
                                selectedMenuItem={MenuItemName.Photos}
                                jobPickerDisplayMode={JobPickerDisplayModes.Photos}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            <ListPageLayout
                                path={routes.video.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={VideoListPage}
                                selectedMenuItem={MenuItemName.Videos}
                                jobPickerDisplayMode={JobPickerDisplayModes.Videos}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                                allowGlobalDocs
                            />
                            <ListPageLayout
                                path={routes.warranty.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={WarrantyListPage}
                                selectedMenuItem={MenuItemName.Warranty}
                                jobPickerDisplayMode={JobPickerDisplayModes.Warranty}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.ownerInvoice.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={OwnerInvoicesListPage}
                                selectedMenuItem={MenuItemName.OwnerPayments}
                                jobPickerDisplayMode={JobPickerDisplayModes.OwnerPayments}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.invoicePayments.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={OwnerInvoicePaymentsListPage}
                                selectedMenuItem={MenuItemName.OwnerPayments}
                                jobPickerDisplayMode={JobPickerDisplayModes.OwnerPayments}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <PrintPageLayout
                                path={routes.creditMemos.print}
                                component={CreditMemoPrintPage}
                            />
                            <ListPageLayout
                                path={routes.creditMemos.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={CreditMemoListPage}
                                selectedMenuItem={MenuItemName.OwnerPayments}
                                jobPickerDisplayMode={JobPickerDisplayModes.OwnerPayments}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.onlinePayments.paymentReport}
                                showJobPicker={isInPortal({ builder: true })}
                                component={OnlinePaymentReportPage}
                                selectedMenuItem={MenuItemName.OnlinePaymentReport}
                                jobPickerDisplayMode={JobPickerDisplayModes.OnlinePaymentReport}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.onlinePayments.paymentReportSubs}
                                showJobPicker={isInPortal({ subs: true })}
                                component={OnlinePaymentReportSubsPage}
                                selectedMenuItem={MenuItemName.OnlinePaymentReport}
                                jobPickerDisplayMode={JobPickerDisplayModes.OnlinePaymentReport}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.comment.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={CommentsListPage}
                                selectedMenuItem={MenuItemName.Comments}
                                jobPickerDisplayMode={JobPickerDisplayModes.Comments}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.survey.ownerList}
                                showJobPicker={isInPortal({ builder: true })}
                                component={OwnerSurveyListPage}
                                selectedMenuItem={MenuItemName.Surveys}
                                jobPickerDisplayMode={JobPickerDisplayModes.None}
                            />
                            <ListPageLayout
                                path={routes.survey.list}
                                showJobPicker={isInPortal({ builder: true })}
                                component={SurveyListPage}
                                selectedMenuItem={MenuItemName.Surveys}
                                jobPickerDisplayMode={JobPickerDisplayModes.Survey}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.chat.list}
                                showJobPicker={false}
                                component={ChatListPage}
                                selectedMenuItem={MenuItemName.Chat}
                                keepQueryParams
                                hideOwnerHeaderInfo
                            />
                            <ListPageLayout
                                path={routes.selection.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={SelectionsListPage}
                                selectedMenuItem={MenuItemName.Selections}
                                jobPickerDisplayMode={JobPickerDisplayModes.Selections}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            <ListPageLayout
                                path={routes.timeClock.list}
                                showJobPicker={true}
                                component={TimeClockPage}
                                selectedMenuItem={MenuItemName.TimeClock}
                                jobPickerDisplayMode={JobPickerDisplayModes.TimeClock}
                                allowGlobalJob
                            />
                            <ListPageLayout
                                path={routes.bill.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={BillsListPage}
                                selectedMenuItem={MenuItemName.Bills}
                                jobPickerDisplayMode={JobPickerDisplayModes.Bills}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.costCodeManagement.costCodes}
                                showJobPicker={false}
                                component={CostCodesPage}
                                selectedMenuItem={MenuItemName.CostCodes}
                            />
                            {/**
                             * Webview pages
                             */}
                            <WebviewPageLayout
                                path={routes.chat.webview}
                                component={WebviewChatListPage}
                            />
                            <WebviewPageLayout
                                path={routes.comment.webview}
                                component={WebviewCommentThreadPage}
                            />
                            <WebviewPageLayout
                                path={routes.ownerInvoice.printWebview}
                                component={WebviewOwnerInvoicePrintPage}
                            />
                            <WebviewPageLayout
                                path={routes.estimate.estimateWebView}
                                component={WebviewEstimatesPage}
                            />
                            {/**
                             * SPA Schedules list page route/component
                             */}
                            <ListPageLayout
                                path={routes.schedule.baselineList}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={ScheduleBaselineListPage}
                                selectedMenuItem={MenuItemName.Calendar}
                                jobPickerDisplayMode={JobPickerDisplayModes.Calendar}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            <ListPageLayout
                                path={routes.schedule.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={ScheduleListPage}
                                selectedMenuItem={MenuItemName.Calendar}
                                jobPickerDisplayMode={JobPickerDisplayModes.Calendar}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.changeOrder.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={ChangeOrderListPage}
                                selectedMenuItem={MenuItemName.ChangeOrders}
                                jobPickerDisplayMode={JobPickerDisplayModes.ChangeOrder}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.deposits.list}
                                showJobPicker={isInPortal({ builder: true })}
                                component={DepositListPage}
                                selectedMenuItem={MenuItemName.Deposits}
                                jobPickerDisplayMode={JobPickerDisplayModes.OwnerPayments}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.rfi.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={RFIsListPage}
                                selectedMenuItem={MenuItemName.RFIs}
                                jobPickerDisplayMode={JobPickerDisplayModes.Rfis}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.notifications.legacyList}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={NotificationHistoryListPage}
                                selectedMenuItem={MenuItemName.NotificationHistory}
                                jobPickerDisplayMode={JobPickerDisplayModes.NotificationHistory}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            <ListPageLayout
                                path={routes.notifications.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={NotificationListPage}
                                selectedMenuItem={MenuItemName.NotificationHistory}
                                jobPickerDisplayMode={JobPickerDisplayModes.NotificationHistory}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />

                            <ListPageLayout
                                path={routes.jobCostingBudget.path}
                                showJobPicker={isInPortal({ builder: true })}
                                component={JobCostingBudgetPage}
                                selectedMenuItem={MenuItemName.JobCostingBudget}
                                jobPickerDisplayMode={JobPickerDisplayModes.Budget}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            {/* todo switch to ListPageLayout in the SPA project */}
                            <DetailPageLayout
                                path={routes.errorPage.legacyWebformsServerError}
                                component={LegacyWebformsServerErrorPage}
                            />
                            {/* Master Pages */}
                            <DetailPageLayout
                                path={routes.multiFactorAuth.multiFactorAuth}
                                component={MultiFactorAuthPage}
                            />
                            {/* Message page */}
                            <DetailPageLayout
                                path={routes.notifications.details}
                                component={NotificationPage}
                            />
                            <DetailPageLayout
                                path={routes.messages.compose}
                                component={MessageComposePage}
                            />
                            <DetailPageLayout
                                path={routes.messages.detailsExternal}
                                component={MessageDetailsExternalPage}
                            />
                            {/* RFI pages */}
                            <DetailPageLayout
                                path={routes.rfi.related}
                                component={RelatedRfiPage}
                            />
                            <DetailPageLayout path={routes.rfi.details} component={RFIPage} />
                            <PrintPageLayout path={routes.rfi.print} component={RFIPrintPage} />
                            {/* Online Payment pages*/}
                            <Route path={routes.onlinePayments.settings}>
                                <ListPageLayout
                                    path={routes.onlinePayments.settings}
                                    showJobPicker={false}
                                    component={OnlinePaymentSettingsFullPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <Route path={routes.onlinePayments.inboundHostedOnboarding}>
                                <ListPageLayout
                                    path={routes.onlinePayments.inboundHostedOnboarding}
                                    showJobPicker={false}
                                    component={InboundHostedOnboardingPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <Route path={routes.onlinePayments.financialDocumentCollection}>
                                <ListPageLayout
                                    path={routes.onlinePayments.financialDocumentCollection}
                                    showJobPicker={false}
                                    component={FinancialDocumentCollectionPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <DetailPageLayout
                                path={routes.onlinePayments.bankValidationRedirect}
                                component={BankValidationRedirectLandingPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.adminPrescreening}
                                component={AdminPaymentsPrescreeningPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.settings}
                                component={OnlinePaymentSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.paymentServicePaymentMethod.external}
                                component={MicrodepositVerificationExternal}
                            />
                            <DetailPageLayout
                                path={
                                    routes.onlinePayments.paymentServicePaymentMethod
                                        .externalWithShareToken
                                }
                                component={MicrodepositVerificationExternal}
                            />
                            <DetailPageLayout
                                path={routes.ownerInvoice.payOnlineMobile}
                                component={OwnerInvoicePayOnlineMobileWrapper}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.payOnlineMobile}
                                component={LeadProposalPayOnlineMobileWrapper}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.stuckPaymentUtility}
                                component={StuckPaymentUtilityPage}
                            />
                            <DetailPageLayout
                                path={routes.builderRiskInsurance.briUpdateUtility}
                                component={BRIUpdateUtilityPage}
                            />
                            <BTAdminLayout
                                path={routes.onlinePayments.adminMerchantPaymentList}
                                component={AdminMerchantPaymentListPage}
                            />
                            {/* Warranty pages */}
                            <PrintPageLayout
                                path={routes.warranty.print}
                                component={WarrantyPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.warranty.details}
                                component={WarrantyPage}
                            />
                            {/* Selection pages */}
                            <PrintPageLayout
                                path={routes.selection.print}
                                component={SelectionPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.selection.details}
                                component={SelectionPage}
                            />
                            {/* Selection Choice Pages */}
                            <DetailPageLayout
                                path={routes.selectionChoice.copyChoicesFromSelection}
                                component={CopyChoiceFromSelectionPage}
                            />
                            <DetailPageLayout
                                path={routes.selectionChoice.details}
                                component={SelectionChoicePage}
                            />
                            <DetailPageLayout
                                path={routes.selectionChoice.dependentSelections}
                                component={DependentSelectionsPage}
                            />
                            {/* Allowance pages */}
                            <PrintPageLayout
                                path={routes.allowance.print}
                                component={AllowancePrintPage}
                            />
                            <DetailPageLayout
                                path={routes.allowance.details}
                                component={AllowancePage}
                            />
                            <ExternalRoute
                                path={routes.help.giveFeedback}
                                url={externalPages.LaunchNotes}
                            />
                            <DetailPageLayout
                                path={routes.mobile.getMobileDownload()}
                                component={MobileDownloadPage}
                            />
                            {/* Survey pages */}
                            <PrintPageLayout
                                path={routes.survey.print}
                                component={SurveyPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.survey.questionReportPrint}
                                component={QuestionReportPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.survey.questionReport}
                                component={QuestionReportPage}
                            />
                            <DetailPageLayout
                                path={routes.survey.settings}
                                component={SurveySettingsPage}
                            />
                            <DetailPageLayout path={routes.survey.details} component={SurveyPage} />
                            <DetailPageLayout
                                path={routes.survey.ownerSurvey}
                                component={OwnerSurveyPage}
                            />
                            {/* ToDo pages */}
                            <PrintPageLayout path={routes.toDo.print} component={ToDoPrintPage} />
                            <DetailPageLayout
                                path={routes.toDo.assign}
                                component={ToDoAssignPage}
                            />
                            <DetailPageLayout path={routes.toDo.details} component={ToDoPage} />
                            {/* Lead pages */}
                            <PrintPageLayout path={routes.lead.print} component={LeadPrintPage} />
                            <DetailPageLayout
                                path={routes.leadActivity.details}
                                component={LeadActivityPage}
                            />
                            <DetailPageLayout path={routes.lead.details} component={LeadPage} />
                            <DetailPageLayout
                                path={routes.lead.emailWizard}
                                component={LeadEmailWizardPage}
                            />
                            <DetailPageLayout
                                component={LeadProposalPayOnlineWebformsWrapper}
                                path={routes.leadProposals.payOnlineWebforms}
                            />
                            <DetailPageLayout
                                component={ExternalLeadProposalPayOnlineWebformsWrapper}
                                path={routes.leadProposals.externalPayOnlineWebforms}
                            />
                            {/* Marketplace Integrations pages */}
                            <DetailPageLayout
                                path={routes.marketplace.integrationSettings}
                                component={IntegrationsSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.marketplace.reconsent}
                                component={ReconsentPage}
                            />
                            {/* Auth0 Pages */}
                            <DetailPageLayout
                                path={routes.auth0.redirect}
                                component={Auth0RedirectPage}
                            />
                            {/* Bid pages */}
                            <PrintPageLayout path={routes.bid.print} component={BidPrintPage} />
                            <DetailPageLayout
                                path={routes.bid.external}
                                component={BidExternalPage}
                            />
                            <DetailPageLayout
                                path={routes.bid.details}
                                component={BidRequestPage}
                            />
                            {/* Job pages*/}
                            <PrintPageLayout
                                path={routes.job.print}
                                component={JobPricePrintPage}
                            />
                            <PrintPageLayout path={routes.job.jobPrint} component={JobPrintPage} />
                            <DetailPageLayout
                                path={routes.job.jobFromTemplate}
                                component={JobFromTemplatePage}
                            />
                            <DetailPageLayout
                                path={routes.job.jobPriceSummary}
                                component={JobPriceSummaryPage}
                            />
                            {/* Cost Catalog Pages */}
                            <DetailPageLayout
                                path={routes.costCatalog.settings}
                                component={CostCatalogSetupPage}
                            />
                            <DetailPageLayout
                                path={routes.costCatalog.costGroupDetailsPath}
                                component={CostGroupPage}
                            />
                            <DetailPageLayout
                                path={routes.costCatalog.costCodePath}
                                component={CostCodeItemUsagePage}
                            />
                            <DetailPageLayout
                                path={routes.costCatalog.costItemDetailsPath}
                                component={CostItemPage}
                            />
                            {/* Cost Code pages*/}
                            <DetailPageLayout
                                path={routes.costCode.costCodeLegacyLink}
                                component={CostCodeLegacyWrapperPage}
                            />
                            <DetailPageLayout
                                path={routes.costCode.manageAccounting}
                                component={CostCodesExportToAccountingPage}
                            />
                            <DetailPageLayout
                                path={routes.costCode.quickFixManageAccounting}
                                component={CostCodesExportAccountingLegacyPage}
                            />
                            <DetailPageLayout
                                path={routes.costCode.legacyAccountingImport}
                                component={CostCodesImportFromAccountingPage}
                            />
                            {/* Change Order pages */}
                            <PrintPageLayout
                                path={routes.changeOrder.print}
                                component={ChangeOrderPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.changeOrderPaymentDetails}
                                component={ChangeOrderPaymentPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.ownerRequest}
                                component={ChangeOrderOwnerRequestPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.changeOrderApprovalSuccess}
                                component={ChangeOrderApprovalSuccessPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.changeOrderDetails}
                                component={ChangeOrderPage}
                            />
                            {/* Estimate and Proposal Pages */}
                            <DetailPageLayout
                                path={routes.estimate.proposalPdfGenerationPage}
                                component={JobProposalPdfGenerationPage}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.estimate.proposalPrintExternal}
                                component={ProposalPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.estimate.externalProposalPage}
                                component={ExternalProposalPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.externalDetails}
                                component={LeadProposalExternalPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.mobilePreviewLegacy}
                                component={MobileLeadProposalPreviewPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.mobilePreview}
                                component={MobileLeadProposalPreviewPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.proposalPdfGenerationPage}
                                component={LeadProposalPdfGenerationPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.templateDetails}
                                component={LeadProposalTemplatePage}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.leadProposals.leadProposalPrint}
                                component={LeadProposalPrintPage}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.leadProposals.leadProposalPrintExternal}
                                component={LeadProposalPrintPage}
                            />
                            {/* Bid Package pages */}
                            <PrintPageLayout
                                path={routes.bidPackage.print}
                                component={BidPackagePrintPage}
                            />
                            <DetailPageLayout
                                path={routes.bidPackage.details}
                                component={BidPackagePage}
                            />
                            {/* Purchase Order Pages */}
                            <PrintPageLayout
                                path={routes.purchaseOrder.print}
                                component={PurchaseOrderPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.purchaseOrder.printExternal}
                                component={PurchaseOrderPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.purchaseOrder.details}
                                component={PurchaseOrderPage}
                            />
                            <DetailPageLayout
                                path={routes.purchaseOrder.external}
                                component={PurchaseOrderExternalPage}
                            />
                            <ListPageLayout
                                path={routes.purchaseOrder.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={PurchaseOrderListPage}
                                selectedMenuItem={MenuItemName.PurchaseOrders}
                                jobPickerDisplayMode={JobPickerDisplayModes.PurchaseOrders}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            {/* Bill Pages */}
                            <PrintPageLayout path={routes.bill.print} component={BillPrintPage} />
                            <DetailPageLayout path={routes.bill.details} component={BillPage} />
                            <DetailPageLayout
                                path={routes.bill.lienWaiverReview}
                                component={LienWaiverReviewPage}
                            />
                            <DetailPageLayout
                                path={routes.bill.lienWaiverReviewExternal}
                                component={LienWaiverReviewPageExternal}
                            />
                            <DetailPageLayout
                                path={routes.outboundPayments.external}
                                component={OutboundPaymentsExternalPage}
                            />
                            {/* Owner Invoice pages */}
                            <DetailPageLayout
                                path={routes.ownerInvoice.details}
                                component={OwnerInvoicePage}
                            />
                            <DetailPageLayout
                                path={routes.ownerInvoice.externalDetails}
                                component={SharedExternalOwnerInvoicePage}
                            />
                            <DetailPageLayout
                                path={routes.deposits.external}
                                component={ExternalDepositPage}
                            />
                            <PrintPageLayout
                                path={routes.deposits.print}
                                component={DepositPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.deposits.externalPrintShareToken}
                                component={ExternalDepositPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.ownerInvoice.print}
                                component={OwnerInvoicePrintPage}
                            />
                            <PrintPageLayout
                                path={routes.ownerInvoice.externalPrint}
                                component={ExternalOwnerInvoicePrintPage}
                            />
                            <PrintPageLayout
                                path={routes.ownerInvoice.externalPrintShareToken}
                                component={ExternalOwnerInvoicePrintPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.OnlinePaymentCodeEntry}
                                component={OnlinePaymentExternalPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.OnlineInvoiceCodeEntry}
                                component={OnlineInvoiceExternalPage}
                            />
                            {/* Daily Log pages */}
                            <PrintPageLayout
                                path={routes.dailyLog.print}
                                component={DailyLogPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.dailyLog.details}
                                component={DailyLogDetailPage}
                            />
                            {/* Contact */}
                            <DetailPageLayout
                                path={routes.contact.details}
                                component={ContactPage}
                            />
                            <PrintPageLayout
                                path={routes.contact.print}
                                component={ContactPrintPage}
                            />
                            {/* Lien Waiver */}
                            <DetailPageLayout
                                path={routes.lienWaiver.details}
                                component={LienWaiverDetailPage}
                            />
                            <PrintPageLayout
                                path={routes.lienWaiver.print}
                                component={LienWaiverPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.lienWaiver.printExternal}
                                component={LienWaiverPrintPage}
                            />
                            {/* Receipts pages */}
                            <ListPageLayout
                                path={routes.receipts.list}
                                showJobPicker={false}
                                component={ReceiptListPage}
                                selectedMenuItem={MenuItemName.Receipts}
                            />
                            <DetailPageLayout
                                path={routes.receipts.upload}
                                component={ReceiptUpload}
                            />
                            <DetailPageLayout
                                path={routes.receipts.details}
                                component={ReceiptPage}
                            />
                            {/* Builder Risk Insurance pages */}
                            <DetailPageLayout
                                path={routes.builderRiskInsurance.settings}
                                component={BuilderRiskInsuranceSettingsPage}
                            />
                            {/* Branding pages */}
                            <DetailPageLayout
                                path={routes.branding.settings}
                                component={BrandingSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.marketplace.getRedirectPath()}
                                component={MarketplaceRedirectPage}
                            />
                            <ListPageLayout
                                path={routes.jobCostingBudget.path}
                                showJobPicker={isInPortal({ builder: true })}
                                component={JobCostingBudgetPage}
                                selectedMenuItem={MenuItemName.JobCostingBudget}
                                jobPickerDisplayMode={JobPickerDisplayModes.Budget}
                                jobPickerSelectMode={JobPickerSelectModes.Single}
                            />
                            {/* todo switch to ListPageLayout in the SPA project */}
                            <DetailPageLayout
                                path={routes.errorPage.legacyWebformsServerError}
                                component={LegacyWebformsServerErrorPage}
                            />
                            {/* Master Pages */}
                            <DetailPageLayout
                                path={routes.multiFactorAuth.multiFactorAuth}
                                component={MultiFactorAuthPage}
                            />
                            {/* Message page */}
                            <DetailPageLayout
                                path={routes.notifications.details}
                                component={NotificationPage}
                            />
                            <DetailPageLayout
                                path={routes.messages.compose}
                                component={MessageComposePage}
                            />
                            <DetailPageLayout
                                path={routes.messages.detailsExternal}
                                component={MessageDetailsExternalPage}
                            />
                            {/* RFI pages */}
                            <DetailPageLayout
                                path={routes.rfi.related}
                                component={RelatedRfiPage}
                            />
                            <DetailPageLayout path={routes.rfi.details} component={RFIPage} />
                            <PrintPageLayout path={routes.rfi.print} component={RFIPrintPage} />
                            {/* Online Payment pages*/}
                            <Route path={routes.onlinePayments.settings}>
                                <ListPageLayout
                                    path={routes.onlinePayments.settings}
                                    showJobPicker={false}
                                    component={OnlinePaymentSettingsFullPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <Route path={routes.onlinePayments.inboundHostedOnboarding}>
                                <ListPageLayout
                                    path={routes.onlinePayments.inboundHostedOnboarding}
                                    showJobPicker={false}
                                    component={InboundHostedOnboardingPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <Route path={routes.onlinePayments.PaymentTos}>
                                <ListPageLayout
                                    path={routes.onlinePayments.PaymentTos}
                                    showJobPicker={false}
                                    component={PaymentTosPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <Route path={routes.onlinePayments.financialDocumentCollection}>
                                <ListPageLayout
                                    path={routes.onlinePayments.financialDocumentCollection}
                                    showJobPicker={false}
                                    component={FinancialDocumentCollectionPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            <DetailPageLayout
                                path={routes.onlinePayments.bankValidationRedirect}
                                component={BankValidationRedirectLandingPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.adminPrescreening}
                                component={AdminPaymentsPrescreeningPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.settings}
                                component={OnlinePaymentSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.paymentServicePaymentMethod.external}
                                component={MicrodepositVerificationExternal}
                            />
                            <DetailPageLayout
                                path={
                                    routes.onlinePayments.paymentServicePaymentMethod
                                        .externalWithShareToken
                                }
                                component={MicrodepositVerificationExternal}
                            />
                            <DetailPageLayout
                                path={routes.ownerInvoice.payOnlineMobile}
                                component={OwnerInvoicePayOnlineMobileWrapper}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.payOnlineMobile}
                                component={LeadProposalPayOnlineMobileWrapper}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.stuckPaymentUtility}
                                component={StuckPaymentUtilityPage}
                            />
                            <DetailPageLayout
                                path={routes.builderRiskInsurance.briUpdateUtility}
                                component={BRIUpdateUtilityPage}
                            />
                            <BTAdminLayout
                                path={routes.onlinePayments.adminMerchantPaymentList}
                                component={AdminMerchantPaymentListPage}
                            />
                            {/* Warranty pages */}
                            <PrintPageLayout
                                path={routes.warranty.print}
                                component={WarrantyPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.warranty.details}
                                component={WarrantyPage}
                            />
                            {/* Selection pages */}
                            <PrintPageLayout
                                path={routes.selection.print}
                                component={SelectionPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.selection.details}
                                component={SelectionPage}
                            />
                            {/* Selection Choice Pages */}
                            <DetailPageLayout
                                path={routes.selectionChoice.copyChoicesFromSelection}
                                component={CopyChoiceFromSelectionPage}
                            />
                            <DetailPageLayout
                                path={routes.selectionChoice.details}
                                component={SelectionChoicePage}
                            />
                            <DetailPageLayout
                                path={routes.selectionChoice.dependentSelections}
                                component={DependentSelectionsPage}
                            />
                            {/* Allowance pages */}
                            <PrintPageLayout
                                path={routes.allowance.print}
                                component={AllowancePrintPage}
                            />
                            <DetailPageLayout
                                path={routes.allowance.details}
                                component={AllowancePage}
                            />
                            <ExternalRoute
                                path={routes.help.giveFeedback}
                                url={externalPages.LaunchNotes}
                            />
                            <DetailPageLayout
                                path={routes.mobile.getMobileDownload()}
                                component={MobileDownloadPage}
                            />
                            {/* Survey pages */}
                            <PrintPageLayout
                                path={routes.survey.print}
                                component={SurveyPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.survey.questionReportPrint}
                                component={QuestionReportPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.survey.questionReport}
                                component={QuestionReportPage}
                            />
                            <DetailPageLayout
                                path={routes.survey.settings}
                                component={SurveySettingsPage}
                            />
                            <DetailPageLayout path={routes.survey.details} component={SurveyPage} />
                            <DetailPageLayout
                                path={routes.survey.ownerSurvey}
                                component={OwnerSurveyPage}
                            />
                            {/* ToDo pages */}
                            <PrintPageLayout path={routes.toDo.print} component={ToDoPrintPage} />
                            <DetailPageLayout
                                path={routes.toDo.assign}
                                component={ToDoAssignPage}
                            />
                            <DetailPageLayout path={routes.toDo.details} component={ToDoPage} />
                            {/* Lead pages */}
                            <PrintPageLayout path={routes.lead.print} component={LeadPrintPage} />
                            <DetailPageLayout
                                path={routes.leadActivity.details}
                                component={LeadActivityPage}
                            />
                            <DetailPageLayout path={routes.lead.details} component={LeadPage} />
                            <DetailPageLayout
                                path={routes.lead.emailWizard}
                                component={LeadEmailWizardPage}
                            />
                            <DetailPageLayout
                                component={LeadProposalPayOnlineWebformsWrapper}
                                path={routes.leadProposals.payOnlineWebforms}
                            />
                            <DetailPageLayout
                                component={ExternalLeadProposalPayOnlineWebformsWrapper}
                                path={routes.leadProposals.externalPayOnlineWebforms}
                            />
                            {/* Marketplace Integrations pages */}
                            <DetailPageLayout
                                path={routes.marketplace.integrationSettings}
                                component={IntegrationsSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.marketplace.reconsent}
                                component={ReconsentPage}
                            />
                            {/* Auth0 Pages */}
                            <DetailPageLayout
                                path={routes.auth0.redirect}
                                component={Auth0RedirectPage}
                            />
                            {/* Bid pages */}
                            <PrintPageLayout path={routes.bid.print} component={BidPrintPage} />
                            <DetailPageLayout
                                path={routes.bid.external}
                                component={BidExternalPage}
                            />
                            <DetailPageLayout
                                path={routes.bid.details}
                                component={BidRequestPage}
                            />
                            {/* Job pages*/}
                            <PrintPageLayout
                                path={routes.job.print}
                                component={JobPricePrintPage}
                            />
                            <PrintPageLayout path={routes.job.jobPrint} component={JobPrintPage} />
                            <DetailPageLayout
                                path={routes.job.jobFromTemplate}
                                component={JobFromTemplatePage}
                            />
                            <DetailPageLayout
                                path={routes.job.jobPriceSummary}
                                component={JobPriceSummaryPage}
                            />
                            {/* Cost Catalog Pages */}
                            <DetailPageLayout
                                path={routes.costCatalog.settings}
                                component={CostCatalogSetupPage}
                            />
                            <DetailPageLayout
                                path={routes.costCatalog.costGroupDetailsPath}
                                component={CostGroupPage}
                            />
                            <DetailPageLayout
                                path={routes.costCatalog.costCodePath}
                                component={CostCodeItemUsagePage}
                            />
                            <DetailPageLayout
                                path={routes.costCatalog.costItemDetailsPath}
                                component={CostItemPage}
                            />
                            {/* Cost Code pages*/}
                            <DetailPageLayout
                                path={routes.costCode.costCodeLegacyLink}
                                component={CostCodeLegacyWrapperPage}
                            />
                            <DetailPageLayout
                                path={routes.costCode.manageAccounting}
                                component={CostCodesExportToAccountingPage}
                            />
                            <DetailPageLayout
                                path={routes.costCode.quickFixManageAccounting}
                                component={CostCodesExportAccountingLegacyPage}
                            />
                            <DetailPageLayout
                                path={routes.costCode.legacyAccountingImport}
                                component={CostCodesImportFromAccountingPage}
                            />
                            {/* Change Order pages */}
                            <PrintPageLayout
                                path={routes.changeOrder.print}
                                component={ChangeOrderPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.changeOrderPaymentDetails}
                                component={ChangeOrderPaymentPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.ownerRequest}
                                component={ChangeOrderOwnerRequestPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.changeOrderApprovalSuccess}
                                component={ChangeOrderApprovalSuccessPage}
                            />
                            <DetailPageLayout
                                path={routes.changeOrder.changeOrderDetails}
                                component={ChangeOrderPage}
                            />
                            {/* Estimate and Proposal Pages */}
                            <DetailPageLayout
                                path={routes.estimate.proposalPdfGenerationPage}
                                component={JobProposalPdfGenerationPage}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.estimate.proposalPrintExternal}
                                component={ProposalPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.estimate.externalProposalPage}
                                component={ExternalProposalPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.externalDetails}
                                component={LeadProposalExternalPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.mobilePreviewLegacy}
                                component={MobileLeadProposalPreviewPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.mobilePreview}
                                component={MobileLeadProposalPreviewPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.proposalPdfGenerationPage}
                                component={LeadProposalPdfGenerationPage}
                            />
                            <DetailPageLayout
                                path={routes.leadProposals.templateDetails}
                                component={LeadProposalTemplatePage}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.leadProposals.leadProposalPrint}
                                component={LeadProposalPrintPage}
                            />
                            <PrintPageLayout
                                width={800}
                                path={routes.leadProposals.leadProposalPrintExternal}
                                component={LeadProposalPrintPage}
                            />
                            {/* Bid Package pages */}
                            <PrintPageLayout
                                path={routes.bidPackage.print}
                                component={BidPackagePrintPage}
                            />
                            <DetailPageLayout
                                path={routes.bidPackage.details}
                                component={BidPackagePage}
                            />
                            {/* Purchase Order Pages */}
                            <PrintPageLayout
                                path={routes.purchaseOrder.print}
                                component={PurchaseOrderPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.purchaseOrder.printExternal}
                                component={PurchaseOrderPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.purchaseOrder.details}
                                component={PurchaseOrderPage}
                            />
                            <DetailPageLayout
                                path={routes.purchaseOrder.external}
                                component={PurchaseOrderExternalPage}
                            />
                            <ListPageLayout
                                path={routes.purchaseOrder.list}
                                showJobPicker={isInPortal({ builder: true, subs: true })}
                                component={PurchaseOrderListPage}
                                selectedMenuItem={MenuItemName.PurchaseOrders}
                                jobPickerDisplayMode={JobPickerDisplayModes.PurchaseOrders}
                                jobPickerSelectMode={JobPickerSelectModes.Multiple}
                            />
                            {/* Bill Pages */}
                            <PrintPageLayout path={routes.bill.print} component={BillPrintPage} />
                            <DetailPageLayout path={routes.bill.details} component={BillPage} />
                            <DetailPageLayout
                                path={routes.bill.lienWaiverReview}
                                component={LienWaiverReviewPage}
                            />
                            <DetailPageLayout
                                path={routes.bill.lienWaiverReviewExternal}
                                component={LienWaiverReviewPageExternal}
                            />
                            <DetailPageLayout
                                path={routes.outboundPayments.external}
                                component={OutboundPaymentsExternalPage}
                            />
                            {/* Owner Invoice pages */}
                            <DetailPageLayout
                                path={routes.ownerInvoice.details}
                                component={OwnerInvoicePage}
                            />
                            <DetailPageLayout
                                path={routes.ownerInvoice.externalDetails}
                                component={SharedExternalOwnerInvoicePage}
                            />
                            <DetailPageLayout
                                path={routes.deposits.external}
                                component={ExternalDepositPage}
                            />
                            <PrintPageLayout
                                path={routes.deposits.print}
                                component={DepositPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.deposits.externalPrintShareToken}
                                component={ExternalDepositPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.ownerInvoice.print}
                                component={OwnerInvoicePrintPage}
                            />
                            <PrintPageLayout
                                path={routes.ownerInvoice.externalPrint}
                                component={ExternalOwnerInvoicePrintPage}
                            />
                            <PrintPageLayout
                                path={routes.ownerInvoice.externalPrintShareToken}
                                component={ExternalOwnerInvoicePrintPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.OnlinePaymentCodeEntry}
                                component={OnlinePaymentExternalPage}
                            />
                            <DetailPageLayout
                                path={routes.onlinePayments.OnlineInvoiceCodeEntry}
                                component={OnlineInvoiceExternalPage}
                            />
                            {/* Daily Log pages */}
                            <PrintPageLayout
                                path={routes.dailyLog.print}
                                component={DailyLogPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.dailyLog.details}
                                component={DailyLogDetailPage}
                            />
                            {/* Contact */}
                            <DetailPageLayout
                                path={routes.contact.details}
                                component={ContactPage}
                            />
                            <PrintPageLayout
                                path={routes.contact.print}
                                component={ContactPrintPage}
                            />
                            {/* Lien Waiver */}
                            <DetailPageLayout
                                path={routes.lienWaiver.details}
                                component={LienWaiverDetailPage}
                            />
                            <PrintPageLayout
                                path={routes.lienWaiver.print}
                                component={LienWaiverPrintPage}
                            />
                            <PrintPageLayout
                                path={routes.lienWaiver.printExternal}
                                component={LienWaiverPrintPage}
                            />
                            {/* Receipts pages */}
                            <ListPageLayout
                                path={routes.receipts.list}
                                showJobPicker={false}
                                component={ReceiptListPage}
                                selectedMenuItem={MenuItemName.Receipts}
                            />
                            <DetailPageLayout
                                path={routes.receipts.upload}
                                component={ReceiptUpload}
                            />
                            <DetailPageLayout
                                path={routes.receipts.details}
                                component={ReceiptPage}
                            />
                            {/* Builder Risk Insurance pages */}
                            <DetailPageLayout
                                path={routes.builderRiskInsurance.settings}
                                component={BuilderRiskInsuranceSettingsPage}
                            />
                            {/* Branding pages */}
                            <DetailPageLayout
                                path={routes.branding.settings}
                                component={BrandingSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.marketplace.getRedirectPath()}
                                component={MarketplaceRedirectPage}
                            />

                            {/* Comment pages */}
                            <DetailPageLayout
                                path={routes.conversation.details}
                                component={ConversationPage}
                            />
                            {/* Sub/Vendor pages */}
                            <DetailPageLayout
                                path={routes.sub.paymentRequest}
                                component={PaymentRequestPage}
                            />
                            <DetailPageLayout
                                path={routes.sub.division}
                                component={SubDivisionPage}
                            />
                            <DetailPageLayout path={routes.sub.details} component={SubDetailPage} />
                            {/* Estimate pages */}
                            <DetailPageLayout
                                path={routes.estimate.estimateBidMapping}
                                component={EstimateBidMappingPage}
                            />
                            {/* Document pages */}
                            <DetailPageLayout
                                path={routes.document.unread}
                                component={UnreadDocumentPage}
                            />
                            {/* PhotoPreview */}
                            <DetailPageLayout
                                path={routes.photo.preview}
                                component={PhotoPreviewPage}
                            />
                            {/* Media pages */}
                            <DetailPageLayout path={routes.media.copy} component={CopyMediaPage} />
                            <DetailPageLayout
                                path={routes.media.mediaProperties}
                                component={MediaPropertiesPage}
                            />
                            <DetailPageLayout
                                path={routes.media.folderProperties}
                                component={MediaFolderPropertiesPage}
                            />
                            <DetailPageLayout
                                path={routes.media.move}
                                component={MediaMoveFolderPage}
                            />
                            <DetailPageLayout
                                path={routes.media.replace}
                                component={MediaReplacePage}
                            />
                            <DetailPageLayout
                                path={routes.media.viewingPermissions}
                                component={MediaViewingPermissionsPage}
                            />
                            <DetailPageLayout
                                path={routes.media.folderEmailInfo}
                                component={MediaFolderEmailInfoPage}
                            />
                            <DetailPageLayout
                                path={routes.media.shared}
                                component={SharedMediaExternalPage}
                            />
                            {/* Schedule pages */}
                            <DetailPageLayout
                                path={routes.schedule.details}
                                component={SchedulePage}
                            />
                            <DetailPageLayout
                                path={routes.schedule.conflictReport}
                                component={ScheduleConflictReportPage}
                            />
                            <DetailPageLayout
                                path={routes.schedule.notificationPrompt}
                                component={ScheduleNotificationPromptPage}
                            />
                            <DetailPageLayout
                                path={routes.schedule.confirmationExternal}
                                component={ScheduleConfirmationExternalPage}
                            />
                            {/* Time Clock pages */}
                            <DetailPageLayout
                                path={routes.timeClock.shiftDetails}
                                component={ShiftPage}
                            />
                            <DetailPageLayout
                                path={routes.timeClock.clockInClockOut}
                                component={ClockInClockOutPage}
                            />
                            {/* Import Wizard Page */}
                            <DetailPageLayout
                                path={routes.importWizard.adminPath}
                                component={ImportWizardPage}
                            />
                            {/* Template Import Page */}
                            <DetailPageLayout
                                path={routes.templateImport.details}
                                component={TemplateImportPage}
                            />
                            {/* Templates Page */}
                            <DetailPageLayout
                                path={routes.template.templateDetails}
                                component={TemplatePage}
                            />
                            <DetailPageLayout
                                path={routes.template.templateFromTemplate}
                                component={TemplateFromTemplatePage}
                            />
                            {/* Credit Memo Pages */}
                            <DetailPageLayout
                                path={routes.creditMemos.details}
                                component={CreditMemoPage}
                            />
                            {/* Internal Users */}
                            <DetailPageLayout
                                path={routes.internalUsers.quickAdd}
                                component={InternalUserQuickAddPage}
                            />
                            <DetailPageLayout
                                path={routes.internalUsers.internalUserRoleDetails}
                                component={InternalUserRoleDetailsPage}
                            />
                            <DetailPageLayout
                                path={routes.internalUsers.internalUserSettings}
                                component={InternalUserSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.internalUsers.internalUserDetails}
                                component={InternalUserPage}
                            />
                            {/* Subscription Manager Page*/}
                            <DetailPageLayout
                                path={routes.subscriptionManager.settings}
                                component={SubscriptionManagerSettingsPage}
                            />
                            {/* Accounting Pages */}
                            <DetailPageLayout
                                path={routes.accounting.quickBooksSyncSchedule}
                                component={QuickBooksSyncSchedulePage}
                            />
                            <DetailPageLayout
                                path={routes.accounting.linkJobToAccounting.wizard}
                                component={LinkJobToAccountingPage}
                            />
                            <DetailPageLayout
                                path={routes.accounting.linkEntityToAccounting.details}
                                component={LinkEntityToAccountingPage}
                            />
                            <DetailPageLayout
                                path={routes.accounting.oAuthRedirect}
                                component={OAuthRedirectPage}
                            />
                            <DetailPageLayout
                                path={routes.accounting.settings}
                                component={AccountingSettingsPage}
                            />
                            {/* OAuth Pages */}
                            <DetailPageLayout
                                path={routes.oauth.authRedirect}
                                component={AuthRedirectPage}
                            />
                            {/* Vendor Integration Pages */}
                            <DetailPageLayout
                                path={routes.vendor.homeDepotSettings}
                                component={HomeDepotSettingsPage}
                            />
                            <DetailPageLayout
                                path={routes.vendor.vendorStoreFinder}
                                component={VendorStoreFinderPage}
                            />
                            {/* Builder Settings Page - Should be below all other settings pages */}
                            <Route path={routes.settings.settings}>
                                <ListPageLayout
                                    path={routes.settings.settings}
                                    showJobPicker={false}
                                    component={SettingsListPage}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            </Route>
                            {/* Subscription */}
                            <DetailPageLayout
                                path={routes.subscription.adminList}
                                component={AdminSubscriptionPage}
                            />
                            {/* Subscription Sign Up Codes */}
                            <DetailPageLayout
                                path={routes.subscriptionSignUpCodes.list}
                                component={SubscriptionSignUpCodesGridPage}
                            />
                            <DetailPageLayout
                                path={routes.subscriptionSignUpCodes.details}
                                component={SubscriptionSignUpCodesDetailsPage}
                            />
                            {/* Subscription Pricing Guardrails */}
                            <BTAdminLayout
                                path={routes.admin.subscriptionPricingLimits.list}
                                component={SubscriptionPlanLimitsPage}
                            />
                            {/* Account Change Request*/}
                            <DetailPageLayout
                                path={routes.accountChange.adminAccountChangeRequest}
                                component={AdminAccountChangeRequestPage}
                            />
                            <DetailPageLayout
                                path={routes.accountChange.builderAccountChangeRequest}
                                component={BuilderAccountChangeRequestPage}
                            />
                            {/* Cancellation */}
                            <DetailPageLayout
                                path={routes.cancellation.cancelBuilder}
                                component={AdminCancellationPage}
                            />
                            {/* Add Performing Users to Job */}
                            <DetailPageLayout
                                path={routes.addPerformingUsersToJob.path}
                                component={JobViewingPermissionWizardParentPage}
                            />
                            {/* Entity to Purchase Order (PO) Mapping */}
                            <DetailPageLayout
                                path={routes.poMapping.getChangeOrderPath()}
                                component={ChangeOrderPoMappingPage}
                            />
                            <DetailPageLayout
                                path={routes.poMapping.getBidPath()}
                                component={BidPoMappingPage}
                            />
                            <DetailPageLayout
                                path={routes.poMapping.getEstimatePath()}
                                component={EstimatePoMappingPage}
                            />
                            <DetailPageLayout
                                path={routes.poMapping.getSelectionPath()}
                                component={SelectionPoMappingPage}
                            />
                            <DetailPageLayout
                                path={routes.poMapping.getSelectionChoicePath()}
                                component={SelectionChoicePoMappingPage}
                            />
                            {/* Search Sync Utility */}
                            <DetailPageLayout
                                path={routes.search.btAdminUtility}
                                component={SearchUtilsPage}
                            />
                            {/* Feature Flag */}
                            <DetailPageLayout
                                path={routes.featureFlags.list}
                                component={FeatureFlagListPage}
                            />
                            <DetailPageLayout
                                path={routes.featureFlags.details}
                                component={FeatureFlagPage}
                            />
                            {/* Rebate Pages */}
                            <ListPageLayout
                                component={RebateListPage}
                                path={routes.rebates.list}
                                selectedMenuItem={MenuItemName.Rebates}
                                showJobPicker={false}
                            />
                            <ListPageLayout
                                component={PurchasingDashboardPage}
                                path={`${routes.purchasing.dashboard}`}
                                selectedMenuItem={MenuItemName.Rebates}
                                showJobPicker={false}
                            />
                            <BTAdminLayout
                                path={routes.rebateReceiptReview.queues}
                                component={RebateHawkQueueListPage}
                            />
                            <BTAdminLayout
                                path={routes.rebateReceiptReview.queue}
                                component={RebateReceiptReviewQueuePage}
                            />
                            <BTAdminLayout
                                path={routes.rebateReceiptReview.list}
                                component={RebateReceiptReviewListPage}
                            />
                            <DetailPageLayout
                                path={routes.partners.list}
                                component={PartnerListPage}
                            />
                            <DetailPageLayout
                                path={routes.partners.details}
                                component={PartnerPage}
                            />
                            {/* Subscription Item Setup Utility*/}
                            <DetailPageLayout
                                path={routes.subscriptionItemSetup.details}
                                component={SubscriptionItemSetupPage}
                            />
                            {/* Builder Setup */}
                            <DetailPageLayout
                                path={routes.builderSetup.adminSetup}
                                component={BuilderSetupPage}
                            />
                            <DetailPageLayout
                                path={routes.builderSetup.adminSearch}
                                component={BuilderSetupSearchPage}
                            />

                            <ListPageLayout
                                path={routes.builderSetup.setup}
                                showJobPicker={false}
                                component={BuilderSetupSummaryPage}
                                selectedMenuItem={MenuItemName.None}
                                hideNav
                            />
                            {/* Owner Portal Redirect for Builder Jobpicker */}
                            <DetailPageLayout
                                path={routes.ownerPortalRedirect}
                                component={OwnerPortalRedirectPage}
                            />
                            {/* Owner Portal Redirect for Builder Jobpicker */}
                            <DetailPageLayout
                                path={routes.excludeFromChurn.excludeFromChurn}
                                component={ExcludeFromChurnPage}
                            />
                            {/* Error pages... */}
                            <DetailPageLayout
                                path={routes.errorPage.noAccess}
                                component={NoAccessPage}
                            />
                            {/* Log Exception in Sentry Page */}
                            <DetailPageLayout
                                path={routes.sentry.adminLogExceptionInSentry}
                                component={AdminLogExceptionInSentryPage}
                            />
                            {/* Help link pages */}
                            <DetailPageLayout
                                path={routes.contact.contactUs}
                                component={ContactSupportPage}
                            />
                            {/* Sub Trade Agreement Page */}
                            <PrintPageLayout
                                path={routes.tradeAgreement.print}
                                component={TradeAgreementPrintPage}
                            />
                            <DetailPageLayout
                                path={routes.tradeAgreement.subTradeAgreement}
                                component={SubTradeAgreementPage}
                            />
                            {/* Signup Whats Next Page */}
                            <DetailPageLayout
                                path={routes.signupWhatsNext.whatsNext}
                                component={SignupWhatsNextPage}
                            />
                            {/* Signup page */}
                            <DetailPageLayout
                                path={routes.signupForm.signup}
                                component={SignupFormPage}
                            />
                            {/* Signup Price Code Detail Page */}
                            <DetailPageLayout
                                path={routes.signupPriceCodePage.signup}
                                component={SignupPriceCodePage}
                            />
                            {/* Vendor receipt external print/pdf page */}
                            <PrintPageLayout
                                path={routes.vendor.vendorReceipt}
                                component={VendorReceiptPage}
                            />
                            <DetailPageLayout
                                path={routes.owner.externalIdPaymentMethodApprove}
                                component={OwnerSetupPage}
                            />
                            <DetailPageLayout
                                path={routes.owner.setup}
                                component={OwnerSetupPage}
                            />
                            <DetailPageLayout path={routes.sub.setup} component={SubSetupPage} />
                            {/* Finalize Create Account Page */}
                            <DetailPageLayout
                                path={routes.user.finalizeCreateAccount}
                                component={FinalizeCreateAccountPage}
                            />
                            {/* Shared Content Pages */}
                            <DetailPageLayout
                                path={routes.share.path}
                                component={SharedContentPage}
                            />
                            {/* Workday Exception BDS Full Page Experience */}
                            <ListPageLayout
                                path={routes.workdayException.bdsDetails}
                                component={bdsWorkdayExceptionFullPageExperience}
                                selectedMenuItem={MenuItemName.Calendar}
                                showJobPicker={true}
                                jobPickerDisplayMode={3} // 3 = calendar
                            />
                            <DetailPageLayout
                                path={routes.editor.mobile}
                                component={BTMobileEditorPage}
                            />
                            <DetailPageLayout
                                path={routes.editor.legacyMobile}
                                component={BTMobileEditorPage}
                            />
                            <DetailPageLayout
                                path={routes.mobile.mobileLogin}
                                component={MobileLoginEmailUsernameEntryPage}
                            />
                            <DetailPageLayout
                                path={routes.user.linkInviteExpired}
                                component={ExternalInviteExpiredPage}
                            />
                            <DetailPageLayout
                                path={routes.user.signedInLinked}
                                component={SignedInAndLinkedActivationPage}
                            />
                            <ListPageLayout
                                path={routes.job.userManagement.pathForHomeOwnerManagement}
                                component={HomewnerManagementPage}
                                showJobPicker={false}
                                selectedMenuItem={MenuItemName.None}
                            />

                            <ListPageLayout
                                path={routes.contact.clientJobPermissionSettings}
                                component={ClientJobPermissionsPage}
                                showJobPicker={false}
                                selectedMenuItem={MenuItemName.None}
                            />
                            <BTAdminLayout
                                path={routes.admin.adminMain}
                                component={AdminMainPage}
                            />
                            <BTAdminLayout
                                path={routes.admin.adminSubscriptionManager.subscriptionManager}
                                component={AdminSubscriptionManagerPage}
                            />
                            {/* Error page must be last, place nothing below this */}
                            {window.self !== window.top ? (
                                <DetailPageLayout
                                    path=""
                                    component={() => (
                                        <BTSuspense>
                                            <NotFoundError />
                                        </BTSuspense>
                                    )}
                                />
                            ) : (
                                <ListPageLayout
                                    showJobPicker={false}
                                    component={() => (
                                        <BTSuspense>
                                            <NotFoundError />
                                        </BTSuspense>
                                    )}
                                    selectedMenuItem={MenuItemName.None}
                                />
                            )}
                        </Switch>
                    </BrowserRouter>
                </AppProvider>
            </BdsGlobalProvider>
        );
    }
}

export const App: React.FC = () => {
    return (
        <ServerErrorBoundary>
            <AppInternal />
        </ServerErrorBoundary>
    );
};
