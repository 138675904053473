import moment from "moment";

import { IBaseEntity, IIsSelectable } from "types/apiResponse/apiResponse";

import { IListRowData, ListEntity } from "utilities/list/list.types";

import { DocumentContextMenuProps } from "commonComponents/utilities/DocumentContextMenu/DocumentContextMenu";

export type MessageListActions = "refreshFolders" | "saveFolders" | "deleteFolder" | undefined;
export type MessageListPrompts = "forwardIntoBT" | "moveMessage" | undefined;

// These need to match the dynamic grid column numbers
export enum MessageListSortColumns {
    From = 3,
    Subject = 4,
    JobName = 11,
    SentDate = 12,
    LastModifiedDate = 13,
}

export class MessageListEntity extends ListEntity {
    constructor(data: any) {
        super(data);
        this.data = data.data.map((li: any) => new MessageEntry(li));
    }
    data: MessageEntry[];
}

export class MessageEntry implements IBaseEntity, IListRowData, IIsSelectable {
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.builderId = data.builderId;

        this.from = data.from;
        this.subject = data.subject;
        this.body = data.body;
        this.jobName = data.jobName;
        this.attachedFiles = new DocumentContextMenuProps(data.attachedFiles);
        this.sentDate = data.sentDate && moment(data.sentDate);
        this.lastModifiedDate = data.lastModifiedDate && moment(data.lastModifiedDate);
        this.lastResponseDate = data.lastResponseDate && moment(data.lastResponseDate);

        this.isForwarded = data.isForwarded;
        this.isReply = data.isReply;
        this.isRead = data.isRead;
        this.emailMessageFolderId = data.emailMessageFolderId;
        this.recipients = data.recipients;

        this.isSelected = false;
    }

    // IBaseEntity
    id: number;
    jobId: number;
    builderId: number;

    // IIsSelectable
    isSelected: boolean;

    from: string;
    subject: string;
    body: string;
    jobName: string;
    sentDate?: moment.Moment;
    lastModifiedDate?: moment.Moment;
    lastResponseDate?: moment.Moment;
    isForwarded: boolean;
    isReply: boolean;
    isRead: boolean;
    emailMessageFolderId: number;
    recipients: string;
    attachedFiles: DocumentContextMenuProps;
}

export class JobForwardAddressResponse {
    constructor(data: any) {
        this.email = data.email;
        this.jobId = data.jobId;
        this.name = data.name;
    }
    email: string;
    jobId: number;
    name: string;
}

export interface IMoveMessageRequest {
    destFolderId: number;
    messageIds: number[];
    sourceFolderId: number;
}
