// todo rename to NotificationPanel after flag removal
import { RouteComponentProps } from "react-router";

import {
    INotificationPanelHandler,
    NotificationPanelHandler,
} from "entity/notification/NotificationPanel/NotificationPanel.api.handler";
import NotificationPanelPresentational from "entity/notification/NotifticationPanelV2/NotificationPanelPresentational";

interface INotificationPanelV2Props extends RouteComponentProps {
    hasNotifications: boolean;
    notificationHandler?: INotificationPanelHandler;
}

const handler = new NotificationPanelHandler();

function NotificationPanelV2({
    hasNotifications,
    // eslint-disable-next-line unused-imports/no-unused-vars
    notificationHandler = handler,
    ...rest
}: INotificationPanelV2Props) {
    return <NotificationPanelPresentational hasNotifications={hasNotifications} {...rest} />;
}

export default NotificationPanelV2;
