import { BTSelectItem } from "types/apiResponse/apiResponse";

export const unassignedGroupId = -1;

export interface IJumpToQuickFindItem {
    id: number;
    uniqueJumpId: number;
}

export interface ILineItemQuickFindState {
    searchQuery: string;
    results?: BTSelectItem<IQuickFindItem>[];
    selectedResultIndex: number;
    isSearching: boolean;
}

export interface IQuickFindItem {
    id: number;
    title: string;
    type: "LineItem" | "Group" | "Category";
    costCodeTitle?: string;
    assemblyId?: number | null;
    costCategoryId?: number | null;
    parent?: IQuickFindItem;
    children?: IQuickFindItem[];
}
