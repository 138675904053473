import { Content, Extension, FocusPosition, Mark, Node } from "@tiptap/react";
import { v4 as uuid } from "uuid";

import { KeyOfOrString } from "utilities/type/PropsOfType";

import { EditorControlBreakpointNames } from "commonComponents/btWrappers/editor/core/EditorControlBar/EditorControlBar.types";
import { IInputDebouncingContext } from "commonComponents/utilities/Input/InputDebouncingContext";

import { SerializableEditorModel } from "handlers";

export interface IEditorApiType {
    content: Content;
    schemaVersion: string;
}

export class EditorContent {
    constructor(data: IEditorApiType, addUUid: boolean = true) {
        this.schemaVersion = data.schemaVersion;
        this.content = data.content;

        if (addUUid) {
            this._uuid = uuid();
        }
    }

    content: Content;
    schemaVersion: string;

    /**
     * UUID generated to check unique instances when loading content from an API. Reason this exists
     * is so that the editor can be force re-mounted when reloading the editor from an API update
     */
    _uuid: string | undefined;

    // Used to represent an "empty" value/editor. Good for initializing an empty editor value
    public static CreateEmpty() {
        return new EditorContent({
            content: null,
            schemaVersion: currentSchemaVersion,
        });
    }

    public static FromApiV1(data: IEditorApiType, addUUid: boolean = true) {
        return new EditorContent(data, addUUid);
    }

    public static FromApix(data: SerializableEditorModel, addUUid: boolean = true) {
        return new EditorContent(data as unknown as IEditorApiType, addUUid);
    }

    /** Convenience method for simple messages. */
    public static FromPlainText(text: string) {
        return new EditorContent({
            content: {
                type: "doc",
                content: [
                    {
                        type: "paragraph",
                        content: [
                            {
                                type: "text",
                                text,
                            },
                        ],
                    },
                ],
            },
            schemaVersion: currentSchemaVersion,
        });
    }
}

/**
 * Should match the current schema version on the backend
 */
export const currentSchemaVersion = "1.0.0";

/**
 * The default priority level for most parseHTML definitions within extensions
 */
export const defaultHTMLParserPriority = 50;

/**
 * Dictionary where keys represent supported sizes and values represent the size of the element (in em units)
 */
export const supportedFontSizes = {
    small: 0.7,
    medium: 1,
    large: 1.3,
} as const;

export type TiptapExtensionStorageType<T extends Node | Mark | Extension> = T extends Node<
    unknown,
    infer S
>
    ? S
    : T extends Mark<unknown, infer S>
    ? S
    : T extends Extension<unknown, infer S>
    ? S
    : never;

export interface IBTEditorBaseProps<FormValues> {
    /**
     * The input id
     */
    id: KeyOfOrString<FormValues>;
    /**
     * The data test id of the **content** field
     */
    "data-testid": string;
    /**
     * The **initial** value of the editor. Note that the editor is not currently "controllable". via
     * the value field. You can set the value to `EditorContent.CreateEmpty` to clear the editor or initialize
     * it with an empty value
     */
    value: EditorContent;
    /**
     * Returns the current editor content when the editor's content has been changed. Note that this
     * event handler is debounced. Pass in formik's setFieldValue here.
     */
    onChange: (key: KeyOfOrString<FormValues>, value: EditorContent) => void;

    /**
     * Event handler that gets called on blur. Pass in formik's setFieldTouched here.
     */
    onBlur: (key: KeyOfOrString<FormValues>, touched: boolean, shouldValidate?: boolean) => void;

    /**
     * Event handler that gets called on focus.
     */
    onFocus?: () => void;
    /**
     * Property that determines if the editor is in "readonly" mode. If readonly is set, the editor
     * controls are hidden, editor content padding is removed, and the content is displayed like
     * plain-text
     */
    readOnly?: boolean;

    /**
     * Property that determines if the editor needs to hide images.
     */
    hideImages?: boolean;

    /**
     * If true, does not render a border around the editor. Useful if the editor is the focus of the
     * modal/page
     */
    borderless?: boolean;

    /**
     * Auto focus when the editor is first loaded. If undefined, it will not focus on load
     * @example
     * autoFocus="end"
     */
    autoFocus?: FocusPosition;

    /**
     * Debouncing configuration overrides. May be useful for testing purposes
     */
    debouncingContext?: IInputDebouncingContext;

    /**
     * The size of the editor's control bar. Probably shouldn't change this, but it can be used
     * to help testing as the default "auto" doesn't work for jsdom tests
     *
     * @default "auto"
     */
    editorControlSize?: "auto" | EditorControlBreakpointNames;
}

export const editorStorageKeys = {
    editorState: "editorState",
    editorStateDispatch: "editorStateDispatch",
};
