import { APIHandler } from "utilities/apiHandler";

import { FilterEntity, FilterEntityType } from "./Filter.api.types";

export interface IFilterHandler {
    get(id: FilterEntityType, isBTAdmin?: boolean, jobID?: number | null): Promise<FilterEntity>;
}

export class FilterHandler implements IFilterHandler {
    async get(
        id: FilterEntityType,
        isBTAdmin?: boolean,
        jobID?: number | null
    ): Promise<FilterEntity> {
        const data = {
            jobID: jobID,
            useSession: jobID === undefined,
        };
        return await APIHandler(`/api/Filters/${id}`, {
            method: "GET",
            responseType: FilterEntity,
            isBTAdmin,
            data: data,
        });
    }
}

export const defaultFilterHandler = new FilterHandler();
