import { BTIconHomeowners } from "commonComponents/btWrappers/BTIcon";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";

interface IMenuItemShowOwnersProps {
    fileId: number;
    isVisibleToOwners: boolean;
    onClick: () => Promise<void>;
}

const MenuItemShowOwners = ({
    fileId,
    isVisibleToOwners,
    onClick,
    ...antProps
}: IMenuItemShowOwnersProps) => {
    const opacityPercent = isVisibleToOwners ? "40%" : "100%";
    const text = isVisibleToOwners ? "Hide from Owners" : "Show Owners";

    return (
        <BTMenuItem
            {...antProps}
            key={`showOwners-menuitem-${fileId}`}
            data-testid={`showOwners-menuitem-${fileId}`}
            onClick={onClick}
        >
            <BTIconHomeowners style={{ opacity: opacityPercent }} /> {text}
        </BTMenuItem>
    );
};

export default MenuItemShowOwners;
