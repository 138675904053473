import { Divider } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { IExternalServiceInfo } from "legacyComponents/FileUploadContainer";
import { BTFileSystem, FileViewOptions } from "legacyComponents/FileUploadContainer.types";
import { getFileViewOptionDefaults } from "legacyComponents/FileUploadContainer.utils";
import { Component } from "react";
import { v4 as uuid } from "uuid";
import { IBaseSlide } from "yet-another-react-lightbox";

import { MediaType, TempFileTypes, ViewingPermissionAction } from "types/enum";

import {
    IUploadConfig,
    showFailedUploadMessage,
    uploadMultiMedia,
} from "utilities/document/fileUpload.utils";
import { getSelectedValues } from "utilities/form/form";
import yup from "utilities/form/yup";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";
import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTForm } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox";
import {
    btFileSystemToSlide,
    canViewBTFileSystemInLightbox,
} from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTUploadRaw } from "commonComponents/btWrappers/BTUploadRaw/BTUploadRaw";
import { BrowseBTFiles } from "commonComponents/entity/media/BrowseBTFiles/BrowseBTFiles";
import {
    BTFileUploadAction,
    BTFileUploadMode,
    IBTFileUploadFormValues,
} from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { getFileSystemId } from "commonComponents/entity/media/BTFileUpload/BTFileUpload.utils";
import "commonComponents/entity/media/BTFileUpload/BTFileUploadPresentational.less";
import { FileItem } from "commonComponents/entity/media/FileItem/FileItem";
import { FileUploadArea } from "commonComponents/entity/media/FileUploadArea/FileUploadArea";
import { MediaAttachmentOptions } from "commonComponents/entity/media/MediaAttachmentOptions/MediaAttachmentOptions";
import { MediaNotifications } from "commonComponents/entity/media/MediaNotifications/MediaNotifications";
import MediaViewingPermissionsInternal from "commonComponents/entity/media/MediaViewingPermissionsInternal/MediaViewingPermissionsInternal";
import { MediaViewingPermissionsEntity } from "commonComponents/entity/media/MediaViewingPermissionsInternal/MediaViewingPermissionsInternal.api.types";
import { getUnsavedChangesModalConfig } from "commonComponents/utilities/DirtyTracking/UnsavedChangesPrompt";
import {
    IBTInjectedFormikProps,
    withFormikBT,
} from "commonComponents/utilities/Formik/WithFormikBT";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { FormikValidationSummary } from "commonComponents/utilities/validationSummary/FormikValidationSummary/FormikValidationSummary";

interface IBTFileUploadPresentationalProps {
    jobId?: number;
    leadId?: number;
    title?: string;
    builderId?: number;
    fileList: File[];
    failedFileIds: { [key: string]: boolean | undefined };
    allowedFileTypes: string[];
    viewingPermissions?: MediaViewingPermissionsEntity;
    fileViewOptions?: FileViewOptions;
    mediaType: MediaType;
    tempFileType: TempFileTypes;
    isTemplateMode: boolean;
    actionBeingPerformed: BTFileUploadAction;
    areViewingPermissionsDropdownsHidden: boolean;
    areNotificationsHidden: boolean;
    modalConfig?: IModalConfiguration;
    uploadMode: BTFileUploadMode;
    disableSave?: boolean;
    hasMediaOptions: boolean;
    isExternal?: boolean;
    externalServiceInfo?: IExternalServiceInfo;

    onSave: (values: IBTFileUploadFormValues) => Promise<void>;
    onDiscard: () => void;
    onFileListUpdated: (fileList: UploadFile<BTFileSystem>[]) => void;
    onShowViewingPermissionsDropdowns: () => void;
    onToggleNotifications: () => void;
}

/** Determines if the supplied file should show thumbnail & preview in Lightbox */
const shouldShowPreview = (file: BTFileSystem) => {
    const canView = canViewBTFileSystemInLightbox(file);
    if (file.mediaType === MediaType.Video) {
        return canView && file.thumbnail;
    }

    return canView;
};

interface IBTFileUploadState {
    selectedMediaId: number | null;
    showBrowseBuildertrendFiles: boolean;
}

class BTFileUploadInternal extends Component<
    IBTInjectedFormikProps<IBTFileUploadPresentationalProps, IBTFileUploadFormValues>,
    IBTFileUploadState
> {
    state: Readonly<IBTFileUploadState> = {
        selectedMediaId: null,
        showBrowseBuildertrendFiles: false,
    };

    componentDidMount = async () => {
        const {
            values,
            jobId,
            builderId,
            fileList,
            mediaType,
            onFileListUpdated,
            tempFileType,
            isExternal,
            externalServiceInfo,
        } = this.props;

        onFileListUpdated(values.fileList);

        let uploadPromises: Promise<void>[] = [];
        for (let i = 0; i < fileList.length; i++) {
            // assume this.props.fileList and the mapped values.fileList are in the same order
            const uid = values.fileList[i].uid;
            uploadPromises.push(
                uploadMultiMedia({
                    jobId: jobId ?? null,
                    builderId,
                    file: fileList[i],
                    tempFileType: tempFileType,
                    mediaType,
                    uploadConfig: this.getUploadConfig(),
                    shouldSuppressErrorMessage: true,
                    isExternal: isExternal,
                    externalServiceInfo: externalServiceInfo,
                    uploadProgress: () => {},
                })
                    .then((resp) => {
                        this.setManualUploadStatus(uid, "done", resp);
                    })
                    .catch((error) => {
                        this.setManualUploadStatus(uid, "error");
                        showFailedUploadMessage(error);
                    })
            );
        }

        if (uploadPromises.length) {
            await Promise.allSettled(uploadPromises);
        }
    };

    componentDidUpdate = async (prevProps: IBTFileUploadPresentationalProps) => {
        const { values, failedFileIds, mediaType, areNotificationsHidden, validateForm } =
            this.props;

        if (failedFileIds !== prevProps.failedFileIds) {
            this.updateAllFileListState(
                values.fileList
                    .filter((file) => {
                        const fileSystemId = getFileSystemId(mediaType, file.response);
                        return (
                            file.status === "error" ||
                            (fileSystemId && failedFileIds[fileSystemId.toString()])
                        );
                    })
                    .map((file) => {
                        return file.status === "error" ? file : { ...file, status: "error" };
                    })
            );
        }

        if (areNotificationsHidden !== prevProps.areNotificationsHidden) {
            await validateForm();
        }
    };

    private setManualUploadStatus = (
        uid: string,
        status: "done" | "error",
        response?: BTFileSystem
    ) => {
        const { values } = this.props;
        this.updateAllFileListState(
            values.fileList.map((file) => (file.uid === uid ? { ...file, status, response } : file))
        );
    };

    private updateAllFileListState = (fileList: UploadFile[]) => {
        const { setFieldValue, onFileListUpdated } = this.props;
        setFieldValue("fileList", fileList);
        onFileListUpdated(fileList);
    };

    private handleFileListChange = (info: UploadChangeParam) => {
        const { values } = this.props;
        const prevFile = values.fileList.find((file) => file.uid === info.file.uid);
        if (prevFile) {
            if (prevFile.status !== info.file.status && prevFile.status !== "error") {
                this.updateAllFileListState(
                    values.fileList.map((file) => (file === prevFile ? info.file : file))
                );
            }
        } else if (info.file.status && info.file.status !== "removed") {
            this.updateAllFileListState([...values.fileList, info.file]);
        }

        if (info.file.status === "error") {
            showFailedUploadMessage(info.file.error);
        }
    };

    private handleFileListRemove = (file: UploadFile) => {
        const { values } = this.props;
        const newFileList = values.fileList.filter((f) => f.uid !== file.uid);
        this.updateAllFileListState(newFileList);
    };

    private handleFileListClearAll = () => {
        this.updateAllFileListState([]);
    };

    private getUploadConfig = (): IUploadConfig => {
        return { shouldUploadFullResolutionPhoto: true, shouldSuppressProgressEvents: true };
    };

    private handleSlideChange = (_: number, slide: IBaseSlide) => {
        if (slide.metadata?.mediaId !== undefined) {
            this.setState({ selectedMediaId: slide.metadata.mediaId });
        }
    };

    private handleLightboxOpen = (mediaId: number | null) => {
        this.setState({ selectedMediaId: mediaId });
    };

    private handleLightboxClose = () => {
        this.setState({ selectedMediaId: null });
    };

    private handleToggleNotifications = () => {
        const { onToggleNotifications, setFieldValue, areNotificationsHidden } = this.props;

        if (!areNotificationsHidden) {
            setFieldValue("notifications.notifyInternalUsers", false);
            setFieldValue("notifications.notifySubs", false);
            setFieldValue("notifications.notifyOwner", false);
            setFieldValue("comments", "");
        }

        onToggleNotifications();
    };

    private handleViewingPermissionsChanged = (e: CheckboxChangeEvent) => {
        const { handleChange, setFieldValue } = this.props;
        handleChange(e);

        // uncheck notifications if corresponding recipient field is unchecked
        if (!e.target.checked) {
            if (e.target.id === "showBuilder") {
                setFieldValue("notifications.notifyInternalUsers", false);
            } else if (e.target.id === "showSubs") {
                setFieldValue("notifications.notifySubs", false);
            } else if (e.target.id === "showOwner") {
                setFieldValue("notifications.notifyOwner", false);
            }
        }
    };

    private setViewingPermissionsFieldValue = (field: string, value: any) => {
        const { setFieldValue } = this.props;
        setFieldValue(field, value);

        // uncheck notifications if corresponding recipient field is empty
        if (Array.isArray(value) && value.length === 0) {
            if (field === "internalUserIds") {
                setFieldValue("notifications.notifyInternalUsers", false);
            } else if (field === "subIds") {
                setFieldValue("notifications.notifySubs", false);
            }
        }
    };

    private getModalConfig = () => {
        const { getDirtyTrackingProps, modalConfig, values, onSave } = this.props;
        const isSaveDisabled = values.fileList.every((file) =>
            ["error", "removed", undefined].includes(file.status)
        );
        return getUnsavedChangesModalConfig(
            getDirtyTrackingProps,
            {
                onCloseWithSave: async () => {
                    await onSave(values);
                    modalConfig?.beforeClose();
                },
                onCloseWithoutSave: modalConfig?.beforeClose,
            },
            modalConfig?.parentRoute,
            !isSaveDisabled
        );
    };

    private handleSelectBuildertrendFile = async (files: BTFileSystem[]) => {
        const { values } = this.props;

        this.updateAllFileListState([
            ...values.fileList,
            ...files.map((file) => ({
                name: file.title,
                uid: uuid(),
                status: "done" as const,
                response: {
                    ...file,
                    uniqueId: file.id,
                    existingBTDoc: true,
                    fileStatus: file.status,
                },
                size: file.fileSize,
            })),
        ]);

        this.handleHideBrowseBuildertrendFiles();
    };

    private handleShowBrowseBuildertrendFiles = () => {
        this.setState({ showBrowseBuildertrendFiles: true });
    };

    private handleHideBrowseBuildertrendFiles = () => {
        this.setState({ showBrowseBuildertrendFiles: false });
    };

    render() {
        const {
            values,
            errors,
            title,
            submitCount,
            viewingPermissions,
            mediaType,
            tempFileType,
            isTemplateMode,
            actionBeingPerformed,
            allowedFileTypes,
            areViewingPermissionsDropdownsHidden,
            areNotificationsHidden,
            failedFileIds,
            jobId,
            leadId,
            uploadMode,
            fileViewOptions,
            hasMediaOptions,
            isExternal,
            externalServiceInfo,
            onDiscard,
            handleSubmit,
            setFieldTouched,
            onShowViewingPermissionsDropdowns,
        } = this.props;
        const isSaving = actionBeingPerformed === "save";

        const currentTotalFailedFiles = values.fileList.filter((file) => {
            const fileSystemId = getFileSystemId(mediaType, file.response);
            return fileSystemId && failedFileIds[fileSystemId.toString()];
        }).length;

        const slides = values.fileList
            .filter((file) => file.response && shouldShowPreview(file.response))
            .map((file) => btFileSystemToSlide(file.response!));
        const index = slides.findIndex((s) => s.metadata?.mediaId === this.state.selectedMediaId);

        const isInternalUserNotificationDisabled =
            mediaType === MediaType.Photo ? false : values.internalUserIds.length === 0;
        const isSubNotificationDisabled =
            mediaType === MediaType.Photo ? !values.showSubs : values.subIds.length === 0;
        const isOwnerNotificationDisabled = !values.showOwner;

        return (
            <BTModalLayout
                title={title}
                footerContent={
                    <>
                        <BTButton<BTFileUploadAction>
                            data-testid="save"
                            type="primary"
                            disabled={
                                this.props.disableSave ||
                                values.fileList.every((file) =>
                                    ["error", "removed", undefined].includes(file.status)
                                )
                            }
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="save"
                            hotkey="save"
                            onClick={handleSubmit}
                        >
                            Save
                        </BTButton>
                        <BTButton<BTFileUploadAction>
                            data-testid="discard"
                            disabled={values.fileList.length === 0}
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="discard"
                            onClick={onDiscard}
                        >
                            Discard
                        </BTButton>
                    </>
                }
                modalConfig={this.getModalConfig()}
            >
                <BTLayoutContent>
                    <FormikValidationSummary
                        values={values}
                        errors={errors}
                        scheme={BTFileUploadValidators(this.props)}
                        showAfterSubmit={submitCount}
                    />
                    {currentTotalFailedFiles > 0 && (
                        <BTAlert
                            type="error"
                            message={
                                <>
                                    {`${currentTotalFailedFiles} `}
                                    <BTFormattedPlural
                                        value={currentTotalFailedFiles}
                                        one="file"
                                        other="files"
                                    />
                                    {` failed to save. Please try uploading individually. If problem persists, the file may be corrupted.`}
                                </>
                            }
                            data-testid="failedToSave"
                        />
                    )}
                    <BTForm onSubmitCapture={handleSubmit}>
                        <BTRow gutter={[16, 16]}>
                            <BTCol xs={24} md={hasMediaOptions ? 10 : 24}>
                                <PageSection removeBodyPadding>
                                    <BTUploadRaw
                                        id="fileList"
                                        data-testid="fileList"
                                        isArea
                                        multiple
                                        jobId={jobId}
                                        disabled={isSaving}
                                        tempFileType={tempFileType}
                                        mediaType={mediaType}
                                        allowedFileTypes={allowedFileTypes}
                                        value={values.fileList}
                                        isExternal={isExternal}
                                        externalServiceInfo={externalServiceInfo}
                                        uploadConfig={this.getUploadConfig()}
                                        showUploadList={false}
                                        onChange={this.handleFileListChange}
                                        onRemove={this.handleFileListRemove}
                                    >
                                        <FileUploadArea
                                            uploadMode={uploadMode}
                                            onClickBrowseBTFiles={
                                                this.handleShowBrowseBuildertrendFiles
                                            }
                                        />
                                    </BTUploadRaw>
                                    {this.state.showBrowseBuildertrendFiles && (
                                        <BrowseBTFiles
                                            onCancel={this.handleHideBrowseBuildertrendFiles}
                                            onSubmit={this.handleSelectBuildertrendFile}
                                            allowedFileTypes={allowedFileTypes}
                                            jobId={jobId}
                                            leadId={leadId}
                                            modalConfig={{
                                                parentRoute:
                                                    this.props.modalConfig?.parentRoute ?? "",
                                                beforeClose: this.handleHideBrowseBuildertrendFiles,
                                            }}
                                        />
                                    )}
                                    {values.fileList.length > 0 && (
                                        <>
                                            <Divider className="margin-top-zero margin-bottom-xs BTFileUpload-Divider" />
                                            <BTRow responsiveMode="viewport" align="middle">
                                                <BTCol xs={24} sm={12}>
                                                    <span className="BTFileUpload-AddLeftBodyPadding">
                                                        {values.fileList.length}
                                                        <BTFormattedPlural
                                                            value={values.fileList.length}
                                                            one=" File"
                                                            other=" Files"
                                                        />
                                                    </span>
                                                </BTCol>
                                                <BTCol
                                                    xs={24}
                                                    sm={12}
                                                    className="flex justify-content-end"
                                                >
                                                    <BTButton
                                                        data-testid="clearAll"
                                                        className="margin-right-md"
                                                        type="link"
                                                        isolated
                                                        disabled={isSaving}
                                                        onClick={this.handleFileListClearAll}
                                                    >
                                                        Clear All
                                                    </BTButton>
                                                </BTCol>
                                            </BTRow>
                                            <Divider className="margin-top-xs margin-bottom-zero BTFileUpload-Divider" />

                                            <div className="BTFileUpload-FileListContainer">
                                                <BTLightbox
                                                    open={this.state.selectedMediaId !== null}
                                                    onClose={this.handleLightboxClose}
                                                    slides={slides}
                                                    index={index}
                                                    onSlideChange={this.handleSlideChange}
                                                />
                                                <div className="margin-bottom-xs" />
                                                {values.fileList.map((file, index) => (
                                                    <FileItem
                                                        key={file.uid}
                                                        file={file}
                                                        mediaType={mediaType}
                                                        isFirst={index === 0}
                                                        isSaving={isSaving}
                                                        failedFileIds={failedFileIds}
                                                        onFileListRemove={this.handleFileListRemove}
                                                        onClickPreview={this.handleLightboxOpen}
                                                    />
                                                ))}
                                                <div className="margin-top-xs" />
                                            </div>
                                        </>
                                    )}
                                </PageSection>
                            </BTCol>

                            {uploadMode !== BTFileUploadMode.Attachments && viewingPermissions && (
                                <ShowOnPortal
                                    builder
                                    render={() => (
                                        <BTCol xs={24} md={14}>
                                            <MediaViewingPermissionsInternal
                                                entity={viewingPermissions}
                                                values={values}
                                                disabled={isSaving}
                                                areDropdownsHidden={
                                                    areViewingPermissionsDropdownsHidden
                                                }
                                                documentCount={values.fileList.length}
                                                mediaType={mediaType}
                                                viewingPermissionAction={
                                                    ViewingPermissionAction.Set
                                                }
                                                handleChange={this.handleViewingPermissionsChanged}
                                                setFieldValue={this.setViewingPermissionsFieldValue}
                                                setFieldTouched={setFieldTouched}
                                                onShowDropdowns={onShowViewingPermissionsDropdowns}
                                                showOwnersDropdown={false}
                                            />
                                            {!isTemplateMode && (
                                                <MediaNotifications
                                                    {...this.props}
                                                    isSaving={isSaving}
                                                    areNotificationsHidden={areNotificationsHidden}
                                                    uploadMode={uploadMode}
                                                    onToggleNotifications={
                                                        this.handleToggleNotifications
                                                    }
                                                    mediaType={mediaType}
                                                    internalUserNotificationDisabled={
                                                        isInternalUserNotificationDisabled
                                                    }
                                                    subNotificationDisabled={
                                                        isSubNotificationDisabled
                                                    }
                                                    ownerNotificationDisabled={
                                                        isOwnerNotificationDisabled
                                                    }
                                                    internalUserNotificationVisible
                                                    subNotificationVisible
                                                    ownerNotificationVisible
                                                />
                                            )}
                                        </BTCol>
                                    )}
                                />
                            )}
                            {uploadMode === BTFileUploadMode.Attachments && fileViewOptions && (
                                <BTCol xs={24} md={14}>
                                    <MediaAttachmentOptions
                                        {...this.props}
                                        onViewingPermissionsChange={
                                            this.handleViewingPermissionsChanged
                                        }
                                        fileViewOptions={fileViewOptions}
                                        isTemplateMode={isTemplateMode}
                                        isSaving={isSaving}
                                        areNotificationsHidden={areNotificationsHidden}
                                        uploadMode={uploadMode}
                                        onToggleNotifications={this.handleToggleNotifications}
                                        mediaType={mediaType}
                                    />
                                </BTCol>
                            )}
                        </BTRow>
                    </BTForm>
                </BTLayoutContent>
            </BTModalLayout>
        );
    }
}

const BTFileUploadValidators = (props: IBTFileUploadPresentationalProps) =>
    yup.object().shape<IBTFileUploadFormValues>({
        fileList: yup.array().of(yup.mixed()).required().label("File List"),
        internalUserIds: yup.array().of(yup.number()).label("Internal Users"),
        subIds: yup.array().of(yup.number()).label("Subs"),
        ownerIds: yup.array().of(yup.number()).label("Owner"),
        showBuilder: yup.bool().label("Show Builder"),
        showSubs: yup.bool().label("Show Subs"),
        showOwner: yup.bool().label("Show Owner"),
        notifications: yup
            .object()
            .shape({
                notifyInternalUsers: yup.bool().label("Notify Internal Users"),
                notifySubs: yup.bool().label("Notify Subs"),
                notifyOwner: yup.bool().label("Notify Owner"),
            })
            .test(
                "requiredRecipient",
                "You must select at least one recipient for your notification.",
                (value) => {
                    return (
                        props.areNotificationsHidden ||
                        (value &&
                            (value.notifyInternalUsers || value.notifySubs || value.notifyOwner))
                    );
                }
            )
            .label("Notification Recipients"),
        comments: yup.string().max(500).label("Comments"),
    });

export const BTFileUploadPresentational = withFormikBT<
    IBTFileUploadPresentationalProps,
    IBTFileUploadFormValues
>({
    mapPropsToValues: (props: IBTFileUploadPresentationalProps) => {
        let defaultOptions = {
            showBuilder: true,
            showSubs: props.viewingPermissions?.showSubs ?? false,
            showOwner: props.viewingPermissions?.showOwner ?? false,
            notifyBuilder: false,
            notifySubs: false,
            notifyOwner: false,
        };

        if (props.uploadMode === BTFileUploadMode.Attachments && props.fileViewOptions) {
            defaultOptions = getFileViewOptionDefaults(props.fileViewOptions);
        }

        return {
            fileList: props.fileList.map((file, index) => ({
                uid: ((index + 1) * -1).toString(),
                name: file.name,
                size: file.size,
                type: file.type,
                status: "uploading",
                originFileObj: {} as RcFile,
            })),
            internalUserIds: props.viewingPermissions?.internalUsers
                ? getSelectedValues(props.viewingPermissions.internalUsers)
                : [],
            subIds: props.viewingPermissions?.subs
                ? getSelectedValues(props.viewingPermissions.subs)
                : [],
            ownerIds: props.viewingPermissions?.owners
                ? getSelectedValues(props.viewingPermissions.owners)
                : [],
            showBuilder: defaultOptions.showBuilder,
            showSubs: defaultOptions.showSubs,
            showOwner: defaultOptions.showOwner,
            notifications: {
                notifyInternalUsers: defaultOptions.notifyBuilder,
                notifySubs: defaultOptions.notifySubs,
                notifyOwner: defaultOptions.notifyOwner,
            },
            comments: "",
        };
    },

    validationSchema: BTFileUploadValidators,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    handleSubmit: async (values: IBTFileUploadFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.onSave(values);
        setSubmitting(false);
    },
})(BTFileUploadInternal);
