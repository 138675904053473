import { FormikValues } from "formik";

import { getObjectDiff, ObjectDiff } from "utilities/object/object";

export interface IDirtyTrackingConfig<Values> {
    /**
     * These values will always show as "false" in the dirtyValues prop and will not affect isDirty.
     * @example [ "attachedFilesConfiguration", "checkedIds","lineItems.*.isExpanded" ]
     */
    ignoreDirtyValues?: ((keyof Values & string) | string)[];
    /**
     * This controls whether dirtyValues and isDirty are computed when form values change
     * @default true
     */
    enableDirtyTracking?: boolean;
}

type DirtyValues<Values extends FormikValues> = ObjectDiff<Values>;

export interface IDirtyTrackingInjectedProps<Values extends FormikValues> {
    getDirtyTrackingProps: () => IDirtyTrackingProps<Values>;
    /**
     * Sets the field value without triggering dirty tracking
     */
    setFieldValueWithoutDirtyTracking: (field: string, value: Object) => void;
}

export interface IDirtyTrackingProps<Values extends FormikValues> {
    /**
     * Boolean values indicating if the properties of a form values object differ from their original state.
     * Return object follows the same structure as the source form values type.
     */
    dirtyValues: DirtyValues<Values>;
    /**
     * Whether any key on the form differs from its original state (excluding keys specified in ignoreDirtyValues).
     */
    isDirty: boolean;
    /**
     * A flat list of keys that are dirty, formatted like so: [ "title", "predecessor.0.lag", "description" ]
     */
    dirtyKeys: string[];
}

export function getDirtyValues<Values extends FormikValues>(
    getValues: () => {
        initialValues: Values;
        currentValues: Values;
        setFieldValueWithoutDirtyTracking: (field: string, value: Object) => void;
    },
    config: IDirtyTrackingConfig<Values>
): IDirtyTrackingInjectedProps<Values> {
    if (config.enableDirtyTracking === false) {
        return {
            getDirtyTrackingProps: () => ({
                dirtyValues: {},
                isDirty: false,
                dirtyKeys: [],
            }),
            setFieldValueWithoutDirtyTracking: () => {},
        };
    }
    const { initialValues, currentValues, setFieldValueWithoutDirtyTracking } = getValues();
    const getDirtyTrackingProps = () => {
        const [diffObject, hasDiff, dirtyKeys] = getObjectDiff(
            initialValues,
            currentValues,
            config.ignoreDirtyValues
        );
        return {
            dirtyValues: diffObject,
            isDirty: hasDiff,
            dirtyKeys,
        };
    };
    return {
        getDirtyTrackingProps,
        setFieldValueWithoutDirtyTracking,
    };
}
