import { FunctionComponent, useContext } from "react";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoginTypes, MediaType } from "types/enum";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTFormItem, BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTextArea } from "commonComponents/btWrappers/BTTextArea/BTTextArea";
import {
    BTFileUploadMode,
    IBTFileUploadFormValues,
} from "commonComponents/entity/media/BTFileUpload/BTFileUpload.api.types";
import { IBTInjectedFormikProps } from "commonComponents/utilities/Formik/WithFormikBT";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { RequiredFieldIndicator } from "commonComponents/utilities/RequiredFieldIndicator/RequiredFieldIndicator";

interface IMediaNotificationsProps {
    areNotificationsHidden: boolean;
    internalUserNotificationVisible: boolean;
    subNotificationVisible: boolean;
    ownerNotificationVisible: boolean;
    internalUserNotificationDisabled: boolean;
    subNotificationDisabled: boolean;
    ownerNotificationDisabled: boolean;
    uploadMode: BTFileUploadMode;
    mediaType: MediaType;
    isSaving: boolean;
    onToggleNotifications: () => void;
}

export const MediaNotifications: FunctionComponent<
    IBTInjectedFormikProps<IMediaNotificationsProps, IBTFileUploadFormValues>
> = ({
    areNotificationsHidden,
    internalUserNotificationVisible,
    subNotificationVisible,
    ownerNotificationVisible,
    internalUserNotificationDisabled,
    subNotificationDisabled,
    ownerNotificationDisabled,
    values,
    setFieldValue,
    setFieldTouched,
    uploadMode,
    onToggleNotifications,
    handleChange,
    isSaving,
}) => {
    const userInfo = useContext(UserInfoContext);
    return (
        <PageSection title="Notifications" className="margin-top-md">
            {areNotificationsHidden && (
                <BTRow align="middle">
                    <BTCol xs={15}>
                        <BTFormItem label="Create Notification">
                            Notifications are emails sent to selected users about the files you've
                            uploaded.
                        </BTFormItem>
                    </BTCol>
                    <BTCol xs={9} className="flex justify-content-end">
                        <BTButton
                            data-testid="createNotifications"
                            type="link"
                            disabled={isSaving}
                            onClick={onToggleNotifications}
                        >
                            Create
                        </BTButton>
                    </BTCol>
                </BTRow>
            )}
            {!areNotificationsHidden && (
                <>
                    <BTFormItemAutomatic<IBTFileUploadFormValues>
                        id="notifications"
                        label={
                            <>
                                Who would you like to notify?
                                <RequiredFieldIndicator />
                            </>
                        }
                    >
                        {ownerNotificationVisible && (
                            <BTCheckbox
                                data-testid="notifyOwner"
                                id="notifications.notifyOwner"
                                className="margin-right-sm"
                                disabled={isSaving || ownerNotificationDisabled}
                                checked={values.notifications.notifyOwner}
                                onChange={handleChange}
                            >
                                Owners
                            </BTCheckbox>
                        )}
                        {subNotificationVisible && (
                            <BTCheckbox
                                data-testid="notifySubs"
                                id="notifications.notifySubs"
                                className="margin-right-sm"
                                disabled={isSaving || subNotificationDisabled}
                                checked={values.notifications.notifySubs}
                                onChange={handleChange}
                            >
                                Subs / Vendors
                            </BTCheckbox>
                        )}
                        {internalUserNotificationVisible && (
                            <BTCheckbox
                                data-testid="notifyInternalUsers"
                                id="notifications.notifyInternalUsers"
                                disabled={isSaving || internalUserNotificationDisabled}
                                checked={values.notifications.notifyInternalUsers}
                                onChange={handleChange}
                            >
                                {userInfo?.loginType === BTLoginTypes.BUILDER
                                    ? "Internal Users"
                                    : "Builder"}
                            </BTCheckbox>
                        )}
                    </BTFormItemAutomatic>
                    {uploadMode !== BTFileUploadMode.Attachments && (
                        <BTFormItemAutomatic<IBTFileUploadFormValues> id="comments">
                            <BTTextArea
                                data-testid="comments"
                                id="comments"
                                disabled={isSaving}
                                value={values.comments}
                                maxLength={500}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                            />
                        </BTFormItemAutomatic>
                    )}
                    <BTButton
                        data-testid="cancelNotifications"
                        type="secondary"
                        disabled={isSaving}
                        onClick={onToggleNotifications}
                    >
                        Cancel
                    </BTButton>
                </>
            )}
        </PageSection>
    );
};
