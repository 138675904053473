import { TooltipPlacement } from "antd/lib/tooltip";

import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";

interface IMainNavTooltipProps {
    enabled: boolean;
    children: JSX.Element;
    title: string;
    className?: string;
    placement?: TooltipPlacement;
}

function MainNavTooltip({ children, title, className, enabled, placement }: IMainNavTooltipProps) {
    return (
        <BTPopover content={title} disabled={!enabled} className={className} placement={placement}>
            {children}
        </BTPopover>
    );
}

export default MainNavTooltip;
