export enum GridLineItemEntityType {
    Bid = 0,
    BidPackage = 1,
    PurchaseOrder = 2,
    ToDo = 3,
    PurchaseOrderPayment = 4,
    SelectionChoice = 5,
    ChangeOrder = 6,
    Allowance = 7,
    Bill = 8,
    TimeCardItem = 9,
    EstimateLineItem = 10,
    ProposalLineItem = 11,
    ProposalTemplateLineItem = 12,
    JobTemplateLineItem = 13,
    CustomerInvoice = 14,
    OtherAccountingCost = 15,
    CreditMemo = 16,
}

// todo: these are copied from dynamic grid - likely far less column types for line items.
export enum GridLineItemColumnType {
    id = "id",
    text = "text",
    number = "number",
    flatCurrency = "flatCurrency",
    titleAndCostCode = "titleAndCostCode",
    flatNumber = "flatNumber",
    readOnlyCurrency = "readOnlyCurrency",
    markup = "markup",
    ownerPrice = "ownerPrice",
    singleSelect = "singleSelect",
    percentInvoiced = "percentInvoiced",
    textArea = "textArea",
    action = "action",
    costType = "costType",
    readOnlyPercentage = "readOnlyPercentage",
    empty = "empty",
    worksheetTitleAndCostCode = "worksheetTitleAndCostCode",
    relatedOwnerInvoice = "ownerInvoice",
    paymentSummary = "paymentSummary",
    paymentPercent = "paymentPercent",
    paymentAmount = "paymentAmount",
    tax = "taxGroupId",
    margin = "margin",
    markedAs = "markedAs",
}
