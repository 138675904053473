import { Component } from "react";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { ITemplateProps } from "entity/template/Template/Template";

const Template = lazyLoadWithRetry(() => import("entity/template/Template/Template"));

export class RouteTemplate extends Component<
    Omit<ITemplateProps, "id" | "history" | "location" | "match">
> {
    render() {
        return (
            <BuilderInfoContext.Consumer>
                {(builderInfo) => (
                    <RouteRelative
                        path={routes.template.templateDetails}
                        render={(routerProps) => {
                            const urlParams = routerProps.match.params;
                            const jobId = parseInt(urlParams.jobId);
                            return (
                                <BTSuspense>
                                    <Template
                                        id={jobId}
                                        builderInfo={builderInfo}
                                        {...routerProps}
                                        {...this.props}
                                    />
                                </BTSuspense>
                            );
                        }}
                    />
                )}
            </BuilderInfoContext.Consumer>
        );
    }
}
