import {
    AttachedFiles,
    AttachedFilesRequest,
    BTFileSystem,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import {
    ILineItemContainerData,
    ILineItemData,
    ILineItemResponse,
    LineItem,
    LineItemType,
    PriceTypes,
} from "legacyComponents/LineItemContainer.types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import {
    BTSelectItem,
    IBaseEntity,
    IWithServiceValidationErrors,
    mapBTServiceDropdownToSelectItem,
    ServiceValidation,
} from "types/apiResponse/apiResponse";
import {
    AccountingIntegrationType,
    CustomFieldAssociatedType,
    DocumentInstanceType,
    JobContractType,
    PaymentEntityTypes,
    PaymentType,
} from "types/enum";

import { getTaxGroupSelectItemsFromLineItemDefaults } from "utilities/lineItem/lineItemHelper";

import {
    AccountingValidationData,
    IHasAccountingValidation,
} from "commonComponents/accounting/AccountingValidation/AccountingValidation.types";
import {
    ContactOrgLink,
    IContactEmailAndInviteFormValues,
    IContactOrgLink,
} from "commonComponents/entity/contact/ContactEmailAndInvite/ContactEmailAndInvite/ContactEmailAndInvite.api.types";
import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import {
    DiscussionsEntity,
    IHasDiscussionContainer,
} from "commonComponents/entity/discussion/DiscussionContainer/DiscussionContainer.api.types";
import { Email } from "commonComponents/entity/email/InputEmail/InputEmail.types";
import { PaymentStatusType } from "commonComponents/entity/invoicing/LineItemsToInvoice/LineItemsToInvoice.api.types";
import { getUniqueParents } from "commonComponents/entity/invoicing/LineItemsToInvoice/LineItemsToInvoicePresentational";
import {
    IHasLinkedScheduleItem,
    LinkToScheduleItemEntity,
    LinkToScheduleItemFormValues,
} from "commonComponents/entity/linkToScheduleItem/LinkToScheduleItem/LinkToScheduleItem.api.types";
import { MerchantPaymentListData } from "commonComponents/entity/merchantPayment/MerchantPaymentList/MerchantPaymentList";
import { RecentItemsTooltipConfig } from "commonComponents/entity/recentItemsTooltip/RecentItemsTooltip";
import {
    AccountingEntityTypes,
    AccountingValidationResponse,
} from "commonComponents/financial/Common/Accounting.types";
import { TaxGroupServiceListItemExtraData } from "commonComponents/financial/TaxRateSelect/TaxRateSelect.api.types";

import { CreditMemoAppliedInvoice } from "entity/creditMemo/CreditMemo/CreditMemo.api.types";
import { IHasOnlinePayments } from "entity/onlinePayment/OnlinePayment.api.types";
import { InvoiceStatus } from "entity/ownerInvoice/common/OwnerInvoiceStatus/OwnerInvoiceStatus";
import {
    IOwnerInvoiceLineItem,
    OwnerInvoiceLineItem,
} from "entity/ownerInvoice/OwnerInvoiceLineItemContainer/OwnerInvoiceLineItemContainer.types";
import { UnverfiedPaymentMethodResponse } from "entity/OwnerSummary/OwnerSummary.api.types";
import { TaxMethod } from "entity/tax/common/tax.types";
import { TaxRateBreakdownInfo } from "entity/tax/common/TaxRateBreakdown/TaxRateBreakdown.api.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";

import type { default as OwnerInvoiceApiResponse } from "./OwnerInvoice.api.json";

export interface IOwnerInvoiceLinkResponse {
    ownerInvoiceId: number;
    invoiceUrl: string;
}
export enum OwnerInvoiceLineItemColumn {
    Items = 0,
    CostType = 1,
    UnitCost = 2,
    Quantity = 3,
    Markup = 4,
    MarkupAmount = 5,
    Tax = 6,
    OwnerPrice = 7,
    Description = 8,
    UnitPrice = 9,
    BuilderCost = 10,
    MarkedAs = 11,
}

export type OwnerInvoiceFormActions =
    | undefined
    | "save"
    | "saveAndRelease"
    | "saveAndInvoice"
    | "saveAndClose"
    | "reset"
    | "unrelease"
    | "delete"
    | "void"
    | "resend"
    | "print"
    | "addOwnerEmail"
    | "skipAddingOwnerEmail"
    | "saveDefaults"
    | "viewOwnerPreview"
    | "sendToAccounting";

export interface IOwnerInvoiceFormValues {
    title: string;
    customInvoiceId: string;
    description: string;
    paidDate?: moment.Moment;
    status: InvoiceStatus;
    amountPaid: number;
    ownerEmail: string;
    createInvoiceChkbox: boolean;
    notifyOwner: boolean;
    customFields: ICustomFieldFormValues[];
    attachedFiles: AttachedFilesRequest;
    showLineItemsToOwner: boolean;
    showPaymentCode: boolean;
    showCustomFields: boolean;
    showInvoiceDescription: boolean;
    lineItems: LineItem[];
    costCodeIds: number[];
    builderCost: string;
    unifiedDeadlineRequest: LinkToScheduleItemFormValues;
    internalNotes: string;
    priceType: PriceTypes;
    containerIsValid: boolean;
    ownerInvoiceLineItems: IOwnerInvoiceLineItem[];
    amount: number;
    taxMethod: TaxMethod;
    taxGroupId?: number;
    contactSaveRequest?: IContactEmailAndInviteFormValues;
    columnPreferences: OwnerInvoiceLineItemColumn[];
    groupLineItemsByCostCode: boolean;
}

export interface IOwnerOwnerInvoiceFormValues {
    priceType: PriceTypes;
    ownerInvoiceLineItems: IOwnerInvoiceLineItem[];
    amount: number;
    showLineItemsToOwner: boolean;
    showPaymentCode?: boolean;
    showCustomFields?: boolean;
    showInvoiceDescription?: boolean;
    taxMethod: TaxMethod;
    taxGroupId?: number;
    groupLineItemsByCostCode?: boolean;
}

export interface IOwnerInvoiceOwnerPreviewDisplayPreferences {
    columnPreferences: OwnerInvoiceLineItemColumn[];
    saveColumnPreferencesAsDefault: boolean;
    showLineItems: boolean | undefined;
    showPaymentCode: boolean | undefined;
    showCustomFields: boolean | undefined;
    showInvoiceDescription: boolean | undefined;
    groupLineItemsByCostCode: boolean | undefined;
}

export interface ISaveOwnerInvoiceOwnerPreviewDisplayPreferencesRequest {
    columnPreferences: OwnerInvoiceLineItemColumn[];
    saveAsDefault: boolean;
    hideLineItems: boolean | undefined;
    showPaymentCode: boolean | undefined;
    showCustomFields: boolean | undefined;
    showInvoiceDescription: boolean | undefined;
    groupLineItemsByCostCode: boolean | undefined;
}

export enum InvoicePaymentMethods {
    Other = -1,
    CreditCard = 0,
    Check = 1,
    OnlinePayment = 2,
    QuickBooks = 3,
    Xero = 4,
    CreditMemo = 5,
    Deposit = 6,
}

export class OwnerInvoiceEntity
    implements
        IBaseEntity,
        IHasLinkedScheduleItem,
        IHasAttachedFiles,
        IHasDiscussionContainer,
        IHasCustomFieldContainer,
        IHasAccountingValidation,
        IHasOnlinePayments
{
    constructor(data: any) {
        this.id = data.id || 0;
        this.jobId = data.jobId;
        this.builderId = data.builderId;
        this.jobName = data.jobName?.value;
        this.instanceKey = uuidv4();
        this.customInvoiceId = data.customInvoiceId.value;
        this.customInvoiceIdPrefix = data.customInvoiceId.prefix;
        this.title = data.title.value;
        if (data.recentItemsTooltip) {
            this.recentItemsTooltip = new RecentItemsTooltipConfig(data.recentItemsTooltip);
        }
        this.merchantPayments = data.merchantPayments
            ? new MerchantPaymentListData(data.merchantPayments)
            : undefined;
        this.invoicePayments = data.invoicePayments?.map(
            (payment: any) => new InvoicePayment(payment)
        );
        this.amountPaid = data.amountPaid.value;
        this.balance = data.balance.value;
        this.amount = data.amount.value;
        this.canPayOtherAmount = data.canEditPaymentAmount;

        if (data.paidDate.value) {
            this.paidDate = moment(data.paidDate.value);
        }
        this.description = data.description.value;
        this.internalNotes = data.internalNotes;
        this.addedBy = data.addedBy ? data.addedBy.value : undefined;
        this.status = data.status.value;

        this.createdByDate = moment(data.createdByDate);
        this.createdByName = data.createdByName;
        this.createdById = data.createdById;
        this.lastUpdatedByDate = moment(data.lastUpdatedByDate);
        this.lastUpdatedByName = data.lastUpdatedByName;
        this.lastUpdatedById = data.lastUpdatedById;

        this.isTemplate = data.isTemplate;
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit || this.id === 0;
        this.canDelete = data.canDelete;
        this.canVoid = data.canVoid;
        this.canRefund = data.canRefund;
        this.canPayOtherAmount = data.canEditPaymentAmount;
        this.showRefund = data.showRefund;
        this.showDeleteMessage = !!data.deleteMessage;
        this.shouldRequestOwnerEmail = data.shouldRequestOwnerEmail;
        // presence of this key on response indicates should prompt to notify
        this.shouldPromptNotifyOwner = data.showNotifyOwnerChkbox !== undefined;
        this.canOwnerViewInvoicesTab = data.canOwnerViewInvoicesTab;
        if (data.jobInfo) {
            this.ownerEmail = new Email(data.jobInfo.ownerEmail);
            this.jobContractType = data.jobInfo.contractType;
        }
        this.isConnectedToAccounting = data.isConnectedToAccounting;
        this.isConnectedAndInvoicedToAccounting = data.isConnectedAndInvoicedToAccounting;
        this.isBilled = data.isBilled;
        this.showPayOnlineBtn = data.showPayOnlineBtn;
        this.showPushPaymentBtn = data.showPushPaymentBtn;
        this.showTerminalPaymentsEnabled = data.terminalPaymentsEnabled;
        this.payOnlineUrl = data.payOnlineUrl;
        this.maxWepayRefundDays = data.maxWepayRefundDays;

        this.linkToScheduleItemEntity = new LinkToScheduleItemEntity(data);
        this.attachedFiles = new AttachedFiles(data.attachedFiles);
        if (data.attachedFilesForRelatedEntity) {
            this.attachedFilesForRelatedEntity = data.attachedFilesForRelatedEntity.map(
                (file: any) => new BTFileSystem(file)
            );
        }

        this.discussions = new DiscussionsEntity(data.discussions);

        this.customFields = data.customFields;
        this.customFieldOptions = data.customFieldOptions;
        this.customFieldsCanConfigure = data.customFieldsCanConfigure;
        this.showLineItemsToOwner = data.showLineItemsToOwner?.value;
        this.showRelatedStackItems = data.showRelatedStackItems;
        if (data.lineItems) {
            this.lineItems = {
                ...data.lineItems,
                value: data.lineItems.value.map((x: any) => {
                    // bt-line-item requires calculatedAmount to be populated
                    return { ...x, calculatedAmount: x.builderCost };
                }),
            };
        }

        this.lineItemContainerData = {
            entityId: this.id,
            pageType: "owner invoice",
            pageTypeEnum: LineItemType.OwnerInvoice,
            priceType: data.useLineItems?.value ? PriceTypes.LineItems : PriceTypes.FlatFee,
            showToOwner: this.showLineItemsToOwner,
            showMarkupTotal: false,
            displayTitle: true,
            descriptionMax: 2500,
            internalNotesMax: 2500,
            hasEditPermission: this.canEdit,
            customUnitCostHeader: "Unit Price",
            customBuilderCostHeader: "Owner Price",
            hideFlatFeeOwnerPrice: true,
            showToOwnerTooltipVisible: false,
            disableBuilderCost:
                this.isBilled || !this.canEdit || this.status === InvoiceStatus.Void,
            descriptionReadOnly: this.isBilled,
            lineItemReadOnly: this.isBilled || !this.canEdit || this.status === InvoiceStatus.Void,
            canReorderLineItems: true,
            canEditCostCodes: data.canEditCostCodes,
        };

        if (data.lineItems?.value) {
            this.lineItemInitialValues = data.lineItems.value.map(
                (x: ILineItemResponse) =>
                    new LineItem(
                        x,
                        this.lineItemContainerData.pageType,
                        this.lineItemContainerData.pageTypeEnum
                    )
            );
        } else {
            this.lineItemInitialValues = [];
        }

        this.ownerInvoiceLineItems =
            data.lineItems?.value.map((x: ILineItemResponse) => new OwnerInvoiceLineItem(x)) ?? [];
        if (
            data.invoiceFields &&
            data.invoiceFields.resetInvoice &&
            data.invoiceFields.resetInvoice.actionData &&
            data.invoiceFields.resetInvoice.actionData.resetMessage
        ) {
            this.removeFromAccountingMessage =
                data.invoiceFields.resetInvoice.actionData.resetMessage;
        }
        this.accountingValidation = {
            entityId: this.id,
            entityType: AccountingEntityTypes.OwnerInvoice,
            showValidation: false,
            showAccountsReceivable: data.showAccountsReceivable,
            canGetLatestStatus: data.canGetLatestStatus,
            canResetAccounting:
                this.removeFromAccountingMessage !== undefined &&
                this.removeFromAccountingMessage !== "",
            canSendToAccounting: data.createInvoiceChkbox || data.createInvoiceButton,
            shouldValidateOnLoad: true,
            accountingIntegrationName: data.accountingSectionTitle,
            transactionsAlertMessage:
                data?.invoiceFields?.resetInvoice?.actionData?.transactionsAlertMessage,
        };
        this.shouldSendToAccounting =
            this.canOwnerViewInvoicesTab &&
            this.status === InvoiceStatus.Unreleased &&
            data.createInvoiceChkbox &&
            data.createInvoiceChkbox.value;
        this.accountingSectionTitle = data.accountingSectionTitle;

        if (
            this.status === InvoiceStatus.Void &&
            data.status.actions &&
            data.status.actions.find((x: any) => x.id === PaymentStatusType.PendingReleased)
        ) {
            this.resetToPendingCustomMessage = data.status.actions.find(
                (x: any) => x.id === PaymentStatusType.PendingReleased
            ).message;
        }
        this.entityType = PaymentEntityTypes.OwnerInvoice;
        this.needsWepaySignup = data.wepaySignupButton !== undefined;
        this.payOnlineMessage = data.payOnlineMessage;
        this.customTitle = `Owner Invoice ID ${this.customInvoiceId}: ${this.title}`;

        this.totalTaxAmount = data.totalTaxAmount && data.totalTaxAmount.value;
        this.accountingBalance = data.accountingBalance && data.accountingBalance.value;
        this.accountingIntegrationName = data.accountingIntegrationName;
        this.addFromRelatedEntities = data.addFromRelatedEntities;

        this.taxMethod = data.taxMethod && mapBTServiceDropdownToSelectItem(data.taxMethod);
        this.taxGroupId = data.taxGroupId ?? NoTaxId;
        this.taxRateBreakdown =
            data.taxRateBreakdown && new TaxRateBreakdownInfo(data.taxRateBreakdown);
        this.totalWithTax = data.totalWithTax?.value;
        this.hasCostCodesFeature = data.hasCostCodesFeature;

        this.accountingIntegrationType = data.accountingType;
        this.payeeList = data.payeeList?.map((x: IContactOrgLink) => new ContactOrgLink(x)) ?? [];
        this.hasBeenSentToOwner = data.hasBeenSentToOwner;

        this.encodedEntityId = data.encodedEntityId;
        this.taxAmount = data.taxAmount;
        this.totalInvoiceAmountWithTaxes = data.totalInvoiceAmountWithTaxes;
        this.showDeadlineTime = data.dueTime?.value !== undefined && data.dueTime?.value !== null;
        if (data.dueTime?.value) {
            this.deadLine = moment(data.dueTime.value);
        } else if (data.deadLine?.value) {
            this.deadLine = moment(data.deadLine.value);
        }

        this.showInviteOnlinePaymentsButton = data.showInviteOnlinePaymentsButton !== undefined;
        this.taxGroups =
            data.lineItems?.defaults &&
            getTaxGroupSelectItemsFromLineItemDefaults(data.lineItems.defaults);

        this.canViewAllInvoicesOnJob = data.canViewAllInvoicesOnJob ?? false;
        this.applicableDeposits = data.applicableDeposits?.map(
            (x: (typeof OwnerInvoiceApiResponse.applicableDeposits)[0]) => new ApplicableDeposit(x)
        );
        if (data.releasedDate) {
            this.releasedDate = moment(data.releasedDate);
        }
        this.releasedByName = data.releasedByName;

        this.columnPreferences = data.ownerInvoiceLineItemColumnPreferences ?? [];
        this.defaultGeneralOwnerInvoiceColumns = data.defaultGeneralOwnerInvoiceColumns;
        this.defaultCostPlusOwnerInvoiceColumns = data.defaultCostPlusOwnerInvoiceColumns;
        this.defaultGeneralHideOwnerLineItems = data.defaultGeneralHideOwnerLineItems;
        this.defaultCostPlusHideOwnerLineItems = data.defaultCostPlusHideOwnerLineItems;
        this.defaultGeneralGroupLineItemsByCostCode = data.defaultGeneralGroupLineItemsByCostCode;
        this.defaultCostPlusGroupLineItemsByCostCode = data.defaultCostPlusGroupLineItemsByCostCode;
        this.showPaymentCode = data.customerInvoiceInformation?.showPaymentCode ?? false;
        this.showCustomFields = data.customerInvoiceInformation?.showCustomFields ?? false;
        this.showInvoiceDescription =
            data.customerInvoiceInformation?.showInvoiceDescription ?? false;
        this.groupLineItemsByCostCode =
            data.customerInvoiceInformation?.groupLineItemsByCostCode ?? false;

        this.jobHasLoanPaymentMethod = data.jobHasLoanPaymentMethod ?? false;
        this.loanHasPendingDrawRequest = data.loanHasPendingDrawRequest ?? false;
        this.builderHasLendingEnabled = data.builderHasLendingEnabled ?? false;
        this.isConnectedToInboundPayments = data.isConnectedToInboundPayments ?? false;
        this.isInboundPaymentsEnabledOnJob = data.isInboundPaymentsEnabledOnJob ?? false;
        this.disableApplyADepositButton = data.disableApplyADepositButton ?? false;
    }

    id: number;
    jobId: number;
    builderId: number;
    instanceKey: string;
    jobContractType?: JobContractType;

    jobName: string;
    customInvoiceId: string;
    customInvoiceIdPrefix: string;
    title: string;
    recentItemsTooltip: RecentItemsTooltipConfig;
    merchantPayments?: MerchantPaymentListData;
    invoicePayments?: InvoicePayment[];

    amountPaid: number;
    balance: number;
    paidDate?: moment.Moment;
    amount: number;

    description: string;
    internalNotes: string;
    addedBy: string;
    status: InvoiceStatus;
    releasedDate?: moment.Moment;
    releasedByName?: string;

    createdByDate: moment.Moment;
    createdByName: string;
    createdById: number | null;
    lastUpdatedByDate: moment.Moment;
    lastUpdatedByName: string;
    lastUpdatedById: number | null;

    isTemplate: boolean;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canVoid: boolean;
    canRefund: boolean;
    canPayOtherAmount: boolean;
    showRefund: boolean;
    showDeleteMessage: boolean;
    shouldRequestOwnerEmail: boolean;
    shouldPromptNotifyOwner: boolean;
    canOwnerViewInvoicesTab: boolean;
    ownerEmail: Email;

    isConnectedToAccounting: boolean;
    isConnectedAndInvoicedToAccounting: boolean;
    isBilled?: boolean;
    showPayOnlineBtn: boolean;
    showPushPaymentBtn: boolean;
    showTerminalPaymentsEnabled: boolean;
    payOnlineUrl: string;
    maxWepayRefundDays: number;
    needsWepaySignup: boolean;
    payOnlineMessage: string;
    addFromRelatedEntities: AddFromRelatedEntitiesResponse;
    // IHasLinkedScheduleItem
    linkToScheduleItemEntity: LinkToScheduleItemEntity;

    // IHasAttachedFiles
    attachedFiles: AttachedFiles;
    attachedFilesForRelatedEntity: BTFileSystem[];
    documentInstanceType: DocumentInstanceType = DocumentInstanceType.CustomerInvoices;
    name: string = "Owner Invoice";

    // IHasDiscussionContainer
    discussions: DiscussionsEntity;

    // IHasCustomFields
    customFields: ICustomFieldResponse[];
    customFieldAssociatedType: CustomFieldAssociatedType = CustomFieldAssociatedType.OwnerPayments;
    customFieldOptions: ICustomFieldOptionsList;
    customFieldsCanConfigure: boolean;

    // IHasLineItems
    lineItems?: ILineItemData;
    lineItemContainerData: ILineItemContainerData;
    lineItemInitialValues: LineItem[];
    showLineItemsToOwner: boolean;
    groupLineItemsByCostCode: boolean;
    showRelatedStackItems: boolean;
    ownerInvoiceLineItems: IOwnerInvoiceLineItem[];

    // IHasAccountingValidation
    accountingValidation: AccountingValidationData;
    accountingSectionTitle: string;
    removeFromAccountingMessage?: string;
    resetToPendingCustomMessage: string | undefined;
    entityType: PaymentEntityTypes;

    shouldSendToAccounting: boolean | undefined;
    customTitle: string;

    totalTaxAmount?: number;
    accountingBalance?: number;
    accountingIntegrationName?: string;

    // Tax Fields
    taxMethod: BTSelectItem[] | undefined;
    taxGroupId?: number;
    taxRateBreakdown?: TaxRateBreakdownInfo;
    totalWithTax?: number;

    hasCostCodesFeature: boolean;

    accountingIntegrationType?: AccountingIntegrationType;

    // Contact Information
    payeeList: IContactOrgLink[];
    hasBeenSentToOwner: boolean;

    encodedEntityId: string;
    taxAmount?: number;
    totalInvoiceAmountWithTaxes?: number;
    showDeadlineTime: boolean;
    deadLine?: moment.Moment;
    showInviteOnlinePaymentsButton: boolean;
    taxGroups?: BTSelectItem<TaxGroupServiceListItemExtraData>[];
    canViewAllInvoicesOnJob: boolean;
    applicableDeposits: ApplicableDeposit[] | null;

    columnPreferences: OwnerInvoiceLineItemColumn[];
    defaultCostPlusOwnerInvoiceColumns?: OwnerInvoiceLineItemColumn[];
    defaultGeneralOwnerInvoiceColumns?: OwnerInvoiceLineItemColumn[];
    defaultGeneralHideOwnerLineItems?: boolean;
    defaultGeneralGroupLineItemsByCostCode?: boolean;
    defaultCostPlusHideOwnerLineItems?: boolean;
    defaultCostPlusGroupLineItemsByCostCode?: boolean;
    showPaymentCode: boolean;
    showCustomFields: boolean;
    showInvoiceDescription: boolean;

    builderHasLendingEnabled: boolean;
    jobHasLoanPaymentMethod: boolean;
    loanHasPendingDrawRequest: boolean;
    isConnectedToInboundPayments: boolean;
    isInboundPaymentsEnabledOnJob: boolean;
    disableApplyADepositButton: boolean;
}

export class OwnerInvoiceSaveResponse implements IWithServiceValidationErrors {
    constructor(data: any) {
        this.id = data.id;
        this.formMessage = data.formMessage;
        this.failedFields = data.failedFields;
        this.invoiceAccountingError = data.invoiceAccountingError;
        this.relatedEntitySyncErrorMessage = data.relatedEntitySyncErrorMessage;

        if (data.accountingValidation) {
            this.accountingValidation = new AccountingValidationResponse(data.accountingValidation);
        }
    }
    id: number;
    jobId: number;
    formMessage: string;
    failedFields: ServiceValidation[];
    invoiceAccountingError?: string;
    accountingValidation?: AccountingValidationResponse;
    relatedEntitySyncErrorMessage?: string;
}

export class OwnerInvoiceAccountingResponse {
    constructor(data: any) {
        this.id = data.id;
        this.accountingValidation = data.accountingValidation;
    }

    id: number;
    accountingValidation?: AccountingValidationResponse;
}

export class InvoicePayment {
    constructor(data: any) {
        this.id = data.invoicePaymentId;
        this.paymentDate = moment(data.paymentDate);
        this.paymentAmount = data.paymentAmount.value;
        this.paymentMethod = data.paymentMethod;
        this.paymentStatus = data.paymentStatus;
        this.paymentStatusReason = data.paymentStatusReason;
        this.isLinkedToAccounting = data.isLinkedToAccounting;
        this.isOnlinePayment = data.isOnlinePayment;
        this.lastUpdatedDate = data.lastUpdatedDate && moment(data.lastUpdatedDate);
        this.createdBy = data.createdBy;
        this.createdById = data.createdById;
        this.createdByDate = moment(data.createdByDate);
        this.receivedByName = data.receivedByName;
        this.voidedDate = data.voidedDate && moment(data.voidedDate);
        this.voidedByName = data.voidedBy;
        this.merchantPaymentMethod = data.merchantPaymentMethod;
        this.netDeposit = data.netDeposit?.value;
        this.feeAmount = data.feeAmount?.value;
        this.merchantPaymentId = data.merchantPaymentId;
        this.unverifiedPaymentMethod = data.unverifiedPaymentMethod;
        this.originalMerchantPaymentAmount = data.originalMerchantPaymentAmount?.value;
        this.isPendingSync = data.isPendingSync;
        this.isStuckInPendingSync = data.isStuckInPendingSync;
        this.refundReason = data.refundReason;
        this.showOwnerRefundReason = data.showOwnerRefundReason;
        this.canVoid = data.canVoid;
        this.creditMemos = data.creditMemos
            ? data.creditMemos.map((cm: any) => new CreditMemoAppliedInvoice(cm))
            : null;
        this.isDeposit = this.paymentMethod === InvoicePaymentMethods.Deposit;
        this.depositId = data.depositId;
        this.depositTitle = data.depositTitle;
        this.creditMemoId = data.creditMemoId;
        this.creditMemoTitle = data.creditMemoTitle;
    }

    id: number;
    paymentDate: moment.Moment;
    paymentAmount: number;
    paymentMethod: InvoicePaymentMethods;
    paymentStatus: CustomerInvoicePaymentStatus;
    paymentStatusReason: string;
    isLinkedToAccounting: boolean;
    isOnlinePayment: boolean;
    lastUpdatedDate?: moment.Moment;
    createdBy: string;
    createdById: number;
    createdByDate: moment.Moment;
    receivedByName: string;
    voidedByName: string;
    voidedDate: moment.Moment;
    merchantPaymentMethod?: PaymentType;
    unverifiedPaymentMethod?: UnverfiedPaymentMethodResponse;
    netDeposit?: number;
    feeAmount?: number;
    merchantPaymentId?: number;
    originalMerchantPaymentAmount?: number;
    isPendingSync: boolean;
    isStuckInPendingSync: boolean;
    refundReason: string;
    showOwnerRefundReason: boolean;
    canVoid: boolean;
    creditMemos: CreditMemoAppliedInvoice[] | null;
    isDeposit?: boolean;
    depositId?: number;
    depositTitle?: string;
    creditMemoId?: number;
    creditMemoTitle?: string;

    isOnlinePaymentAmountChanged() {
        return (
            this.isOnlinePayment &&
            this.originalMerchantPaymentAmount !== undefined &&
            this.originalMerchantPaymentAmount !== this.paymentAmount
        );
    }
}

export class UnstackLineItemRequest {
    constructor(
        lineItemIds: number[],
        newLineItemId: number,
        lineItemType: LineItemType,
        markupPercent?: number
    ) {
        this.lineItemIds = lineItemIds;
        this.newLineItemId = newLineItemId;
        this.lineItemType = lineItemType;
        this.markupPercent = markupPercent;
    }
    lineItemIds: number[];
    newLineItemId: number;
    lineItemType: LineItemType;
    markupPercent?: number;
}

export class UnstackLineItemResponse {
    constructor(data: any) {
        this.lineItems = data.lineItems.map((li: any) => new OwnerInvoiceLineItem(li));
        this.newLineItemId = data.newLineItemId;
    }
    lineItems: OwnerInvoiceLineItem[];
    newLineItemId: number;
}

export class ApplicableDeposit {
    constructor(data: (typeof OwnerInvoiceApiResponse.applicableDeposits)[0]) {
        this.id = data.id;
        this.referenceId = data.referenceId;
        this.title = data.title;
        this.amountRemaining = data.amountRemaining;
        this.isConnectedToAccounting = data.isConnectedToAccounting;
    }

    id: number;
    referenceId: string;
    title: string;
    amountRemaining: number;
    isConnectedToAccounting: boolean;
}

export function getInvoicePaymentMethodText(invoicePayment: InvoicePayment) {
    switch (invoicePayment.paymentMethod) {
        case InvoicePaymentMethods.CreditCard:
            return "Credit Card";
        case InvoicePaymentMethods.Check:
            return "Check";
        case InvoicePaymentMethods.OnlinePayment:
            return "Online Payment";
        case InvoicePaymentMethods.QuickBooks:
            return "QuickBooks";
        case InvoicePaymentMethods.Xero:
            return "Xero";
        case InvoicePaymentMethods.CreditMemo:
            return invoicePayment.creditMemoTitle
                ? `Credit Memo - ${invoicePayment.creditMemoTitle}`
                : "Credit Memo";
        case InvoicePaymentMethods.Deposit:
            return invoicePayment.depositTitle
                ? `Deposit - ${invoicePayment.depositTitle}`
                : "Deposit";
        case InvoicePaymentMethods.Other:
            return "Other";
        default:
            return null;
    }
}

export function getPaymentTypeText(merchantPaymentMethod: PaymentType) {
    switch (merchantPaymentMethod) {
        case PaymentType.CreditCard:
            return "Credit Card";
        case PaymentType.Check:
            return "ACH";
        case PaymentType.Loan:
            return "Draw Request";
        case PaymentType.PushPayments:
            return "Bank Transfer";
        default:
            return null;
    }
}

export enum CustomerInvoicePaymentStatus {
    Paid = 0,
    Void = 1,
    Processing = 2,
    Failed = 3,
    Cancelled = 4,
    Reserved = 5,
    ChargedBack = 6,
    Complete = 7,
    Refunded = 8,
    Applied = 9,
}

export class AddFromRelatedEntitiesResponse {
    showRelatedChangeOrders: boolean;
    showRelatedSelectionChoices: boolean;
    showRelatedEstimates: boolean;
    showRelatedBillsAndPurchaseOrderPayments: boolean;
    showRelatedTimeClockItems: boolean;
    showRelatedAccountingCosts: boolean;
}

export class LineItemColumnPreferencesSaveRequest {
    constructor(data: any) {
        this.preferences = data.preferences;
        this.saveAsDefault = data.saveAsDefault;
    }
    preferences: OwnerInvoiceLineItemColumn[];
    saveAsDefault: boolean;
}

export function getOwnerInvoiceItemColumnOptions(shouldAddTaxColumns: boolean) {
    const columnOptions = [
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.Items,
            name: "Items",
            value: OwnerInvoiceLineItemColumn.Items,
            disabled: true,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.CostType,
            name: "Cost Type",
            value: OwnerInvoiceLineItemColumn.CostType,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.Description,
            name: "Description",
            value: OwnerInvoiceLineItemColumn.Description,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.UnitPrice,
            name: "Unit Price",
            value: OwnerInvoiceLineItemColumn.UnitPrice,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.Quantity,
            name: "Quantity",
            value: OwnerInvoiceLineItemColumn.Quantity,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.BuilderCost,
            name: "Builder Cost",
            value: OwnerInvoiceLineItemColumn.BuilderCost,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.OwnerPrice,
            name: "Owner Price",
            value: OwnerInvoiceLineItemColumn.OwnerPrice,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.UnitCost,
            name: "Unit Cost",
            value: OwnerInvoiceLineItemColumn.UnitCost,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.Markup,
            name: "Markup",
            value: OwnerInvoiceLineItemColumn.Markup,
        }),
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.MarkupAmount,
            name: "Markup Amount",
            value: OwnerInvoiceLineItemColumn.MarkupAmount,
        }),
    ];

    if (shouldAddTaxColumns) {
        columnOptions.push(
            new BTSelectItem({
                id: OwnerInvoiceLineItemColumn.Tax,
                name: "Tax",
                value: OwnerInvoiceLineItemColumn.Tax,
                disabled: true,
            })
        );
    }

    columnOptions.splice(
        2,
        0, // insert after Cost Type and don't remove any items
        new BTSelectItem({
            id: OwnerInvoiceLineItemColumn.MarkedAs,
            name: "Marked As",
            value: OwnerInvoiceLineItemColumn.MarkedAs,
        })
    );

    return columnOptions;
}

export class OwnerInvoiceRelatedEntityIDsForAttachments {
    constructor(data: any) {
        this.relatedEntityIDs = getUniqueParents(data.lineItems).map((li) => li.id);
        this.lineItemType = data.lineItems[0].lineItemType;
    }
    relatedEntityIDs: (number | string)[] | null;
    lineItemType: LineItemType | null;
}
