import classNames from "classnames";
import { Component, createRef, ReactNode, useState } from "react";

import { addCloseOnScroll, removeCloseOnScroll } from "utilities/helpers";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import {
    BTFormattedPlural,
    IBTFormattedPluralProps,
} from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import { BTIconCaretSmallDown } from "commonComponents/btWrappers/BTIcon";
import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSpin } from "commonComponents/btWrappers/BTSpin/BTSpin";
import {
    IRelatedContextMenuItem,
    IRelatedItemContextMenuEntity,
} from "commonComponents/entity/relatedItem/RelatedItemContextMenu/RelatedItemContextMenu.api.types";

import "./RelatedItemContextMenu.less";

interface IRelatedItemContextMenuProps extends IBTFormattedPluralProps {
    entityId: number;
    builderId: number;
    jobId: number;
    /** Pass in a function to load the menu items */
    getContextMenu: (entityId: number, builderId: number) => Promise<IRelatedItemContextMenuEntity>;
    /** Pass in a function to display title. If not provided title string will be used */
    renderTitle?: (item: IRelatedContextMenuItem) => ReactNode;
    className?: string;
}

interface IRelatedItemContextMenuState {
    menuItems?: IRelatedContextMenuItem[];
}

export class RelatedItemContextMenu extends Component<
    IRelatedItemContextMenuProps,
    IRelatedItemContextMenuState
> {
    static defaultProps = {
        one: "Item",
        other: "Items",
    };

    state: Readonly<IRelatedItemContextMenuState> = {
        menuItems: undefined,
    };

    private lazyLoadMenu = async (isVisible: boolean) => {
        const { getContextMenu, entityId, builderId } = this.props;
        if (isVisible) {
            const contextMenu = await getContextMenu(entityId, builderId);

            if (contextMenu.contextMenuItems.length > 0) {
                this.setState({ menuItems: contextMenu.contextMenuItems });
            } else {
                throw new Error("No items were found for this entity");
            }
        }
    };

    private getMenu = () => {
        if (this.state.menuItems) {
            const { renderTitle } = this.props;
            return (
                <BTMenu>
                    {this.state.menuItems.map((item) => (
                        <BTMenuItem
                            data-testid={`RelatedMenuItem- ${item.id}`}
                            key={item.id}
                            className={classNames("RelatedMenuItem", this.props.className, {
                                readOnly: item.isReadonly,
                            })}
                        >
                            {item.isReadonly ? (
                                renderTitle ? (
                                    renderTitle(item)
                                ) : (
                                    item.title
                                )
                            ) : (
                                <BTLinkRelative to={item.linkRoute}>
                                    {renderTitle ? renderTitle(item) : item.title}
                                </BTLinkRelative>
                            )}
                        </BTMenuItem>
                    ))}
                </BTMenu>
            );
        }
        return (
            <BTMenu>
                <BTMenuItem data-testid="RelatedMenuItem-loading">
                    <BTRow align="middle" justify="center">
                        <BTCol>
                            <BTSpin />
                        </BTCol>
                    </BTRow>
                </BTMenuItem>
            </BTMenu>
        );
    };

    render() {
        const { entityId, value } = this.props;

        return (
            <LazyLoadContextMenu
                menu={this.getMenu()}
                lazyLoadMenu={this.lazyLoadMenu}
                entityId={entityId}
                className="RelatedItemContextMenu"
            >
                <span>
                    {value} Related <BTFormattedPlural {...this.props} />
                </span>
            </LazyLoadContextMenu>
        );
    }
}

interface ILazyLoadContextMenuProps {
    children: React.ReactNode;
    menu: JSX.Element;
    lazyLoadMenu: (isVisible: boolean) => Promise<void>;
    entityId: number;
    className?: string;
}

export const LazyLoadContextMenu: React.FC<ILazyLoadContextMenuProps> = (props) => {
    const { menu, lazyLoadMenu, children, entityId, className } = props;
    const [visible, setVisible] = useState(false);
    const dropdownMenuSpan = createRef<HTMLDivElement>();

    const onVisibleChange = (isVisible: boolean) => {
        setVisible(isVisible);
        void lazyLoadMenu(isVisible);

        if (dropdownMenuSpan.current) {
            if (isVisible) {
                addCloseOnScroll(dropdownMenuSpan.current, () => setVisible(false), true);
            } else {
                removeCloseOnScroll(dropdownMenuSpan.current, () => setVisible(false), true);
            }
        }
    };

    return (
        <>
            <span ref={dropdownMenuSpan} style={{ position: "absolute" }} />
            <BTDropdown
                overlay={menu}
                trigger={["click"]}
                onVisibleChange={onVisibleChange}
                visible={visible}
                className={classNames(className, "BTDropdown")}
                overlayStyle={{ zIndex: 0 }}
            >
                <BTButton type="link" data-testid={`contextMenu_${entityId}`}>
                    {children}
                    <BTIconCaretSmallDown className="caretDown" />
                </BTButton>
            </BTDropdown>
        </>
    );
};
