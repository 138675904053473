import { useHistory, useRouteMatch } from "react-router";

import { routes } from "utilities/routes";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { JobsitesRequiredPresentational } from "commonComponents/helpers/JobsitesRequired/JobsitesRequiredPresentational/JobsitesRequiredPresentational";

export interface IJobsitesRequired {
    jobIds?: number[];
    isTemplateMode?: boolean;
    jobPickerReady?: boolean;
    requireSingleJob?: boolean;
    allowTemplates?: boolean;
    setSelectedJobId?: (jobId: number, orgId?: number, loadMissingJobs?: boolean) => Promise<void>;
}

/**
 * an HoC that requires a valid set of jobs selected before it will render its wrapped component
 */
export const withJobsitesRequired =
    (
        listTitle?: string,
        StandaloneDetailRouteComponent?: React.ComponentType<{ modalConfig?: IModalConfiguration }>
    ) =>
    <P extends object>(
        Component: React.ComponentType<P & IJobsitesRequired>
    ): React.FunctionComponent<P & IJobsitesRequired> => {
        return function WithJobsRequired(props) {
            const match = useRouteMatch();
            const history = useHistory();
            const {
                jobIds,
                isTemplateMode,
                jobPickerReady,
                requireSingleJob,
                allowTemplates = true,
            } = props;

            if (!allowTemplates && isTemplateMode) {
                history.replace(routes.template.getListLink());
            }

            const isInvalidJobSelection =
                jobPickerReady &&
                (!jobIds || jobIds.length === 0 || (requireSingleJob && jobIds.length !== 1));

            if (isInvalidJobSelection) {
                return (
                    <>
                        <JobsitesRequiredPresentational
                            listTitle={listTitle}
                            isTemplateMode={isTemplateMode}
                        />
                        {StandaloneDetailRouteComponent && (
                            <StandaloneDetailRouteComponent
                                modalConfig={{
                                    beforeClose: () => history.push(match.url),
                                    parentRoute: match.url,
                                }}
                            />
                        )}
                    </>
                );
            }

            return <Component {...props} />;
        };
    };
