/* eslint-disable no-restricted-globals */
import { message } from "antd";
import Cookies from "js-cookie";
import { debounce, intersection, isEqual, throttle } from "lodash-es";
import queryString from "query-string";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";

import { AppDefaultInfo } from "helpers/AppProvider.types";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { SpaInfoContext } from "helpers/globalContext/SPAInfoContext";

import { IAPIHandlerResult } from "types/apiResponse/apiResponse";
import { BTLocalStorage, BTSessionStorage, LocalStorageKeys } from "types/btStorage";
import { CommonConstants } from "types/constants";
import { BTLoginTypes, FilterType, LeadStatus } from "types/enum";

import { APIError, showAPIErrorMessage } from "utilities/apiHandler";
import { getGlobalValue } from "utilities/globalValueUtils";
import {
    filterJobsList,
    getJobPickerState,
    getSelectedJobId,
    getSelectedJobIdsForJobPicker,
    isSpecialJob,
    selectJobs,
    setJobPickerState,
} from "utilities/jobPicker/jobPicker";
import { EmptyStateEntity, ListMetadata } from "utilities/list/list.types";
import { isInPortal } from "utilities/portal/portal";
import { getBaseRoute, routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import {
    EmptyStateHandler,
    IEmptyStateHandler,
} from "commonComponents/entity/emptyState/common/EmptyState.api.handler";
import { RouteJob } from "commonComponents/entity/job/RouteJob/RouteJob";
import { JobStatusFilterTypes } from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import { IHeaderInfoResponse } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo.api.types";
import {
    JobPicker,
    ResizableJobPickerLoading,
} from "commonComponents/utilities/JobPicker/JobPicker";
import {
    IJobPickerHandler,
    JobPickerHandler,
} from "commonComponents/utilities/JobPicker/JobPicker.api.handler";
import {
    AccountSwitcherItem,
    IApiJobBuilderRequest,
    IApiJobFilterRequest,
    JobPickerFilterTypes,
    JobPickerJob,
    JobPickerOwnerInfo,
    JobPickerPermissions,
    JobPickerSetResponse,
} from "commonComponents/utilities/JobPicker/JobPicker.api.types";
import {
    JobIdTypes,
    JobPickerDisplayModes,
    JobPickerSelectModes,
    JobSortOptions,
} from "commonComponents/utilities/JobPicker/JobPicker.types";
import { getCollapsed } from "commonComponents/utilities/JobPicker/JobPicker.utils";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { FilterHandler, IFilterHandler } from "entity/filters/Filter/Filter.api.handler";
import {
    FilterEntity,
    FilterEntityType,
    IFilterFormValues,
    SelectedFilterItem,
} from "entity/filters/Filter/Filter.api.types";
import {
    getFilterString,
    getFilterStringFromItems,
    getSelectedFilterItems,
} from "entity/filters/filters.utils";
import { JobRunningTotal } from "entity/job/Job.api.types";
import { RouteMessageCompose } from "entity/message/MessageCompose/MessageComposeRoute";
import { RouteTemplate } from "entity/template/Template/RouteTemplate";
import { RouteTemplateFromTemplate } from "entity/template/TemplateFromTemplate/RouteTemplateFromTemplate";
import { TemplateListTabs } from "entity/template/TemplateList/TemplateListTab/TemplateListTab.api.types";

import "./JobPicker.less";

interface IJobPickerWrapperProps extends RouteComponentProps {
    handler?: IJobPickerHandler;
    filterHandler?: IFilterHandler;
    emptyStateHandler?: IEmptyStateHandler;
}

interface IJobPickerWrapperInternalProps {
    isSpa: boolean | null;
    appDefaultInfo?: AppDefaultInfo;
}

interface IJobPickerWrapperState {
    jobs: JobPickerJob[];
    filters?: FilterEntity;
    selectedFilters?: SelectedFilterItem[];
    selectedJobIds: number | number[] | null;
    jobRunningTotal: JobRunningTotal | undefined;
    displayMode: JobPickerDisplayModes;
    permissions: JobPickerPermissions;
    ownerInfo: JobPickerOwnerInfo;
    templatesOnly: boolean;
    sortOption: JobSortOptions;
    selectMode: JobPickerSelectModes;
    keywordSearch: string;
    listMetadata: ListMetadata;
    availableAccounts: AccountSwitcherItem[];
    collapsed: boolean;
    loading: boolean;
    areSavedFiltersShowing: boolean;
    headerInfo?: IHeaderInfoResponse;
    isConnectedToAccounting: boolean;
    userId: number;
    isListLimited: boolean;
}

/**
 * Wrapper for Webforms to use the jobpicker
 */
export class JobPickerWrapperInternal extends Component<
    IJobPickerWrapperProps & IJobPickerWrapperInternalProps,
    IJobPickerWrapperState
> {
    private jobPickerSubscription: any | undefined;
    static defaultProps = {
        handler: new JobPickerHandler(),
        filterHandler: new FilterHandler(),
        emptyStateHandler: new EmptyStateHandler(),
    };
    static contextType = BuilderInfoContext;
    context!: React.ContextType<typeof BuilderInfoContext>;

    state: Readonly<IJobPickerWrapperState> = {
        jobs: [],
        filters: undefined,
        selectedFilters: undefined,
        selectedJobIds: [],
        jobRunningTotal: undefined,
        availableAccounts: [],
        displayMode: JobPickerDisplayModes.None,
        permissions: new JobPickerPermissions({
            canAddJobs: false,
            canViewJobDetails: false,
            canSendMessage: false,
            canViewOwnerSite: false,
        }),
        ownerInfo: new JobPickerOwnerInfo({ owners: [] }),
        templatesOnly: false,
        sortOption: JobSortOptions.Alphabetic,
        selectMode: JobPickerSelectModes.Multiple,
        keywordSearch: "",
        listMetadata: new ListMetadata({
            // Need to instantiate it here, but it will be overridden down below, just placeholder
            hasData: true,
            emptyStatesMetadata: {
                hasFilteredData: false,
                isNewToEntity: false,
                isReadOnly: true,
            },
        }),
        collapsed: getCollapsed(),
        loading: false,
        areSavedFiltersShowing: false,
        isConnectedToAccounting: false,
        userId: 0,
        isListLimited: false,
    };

    private jobStatusFilter: JobStatusFilterTypes[] = [JobStatusFilterTypes.Open];

    private handleResize = (collapsed: boolean) => {
        this.handleJobPickerHeight(collapsed);
    };

    componentDidMount = async () => {
        // eslint-disable-next-line no-restricted-syntax
        if ((window as any).AsyncJobPickerHelper === undefined) {
            // If knockout wasn't able to be loaded in, refresh to try and load it again
            // eslint-disable-next-line no-restricted-syntax
            if ((window as any).ko === undefined) {
                window.location.reload();
                return;
            }

            // If a race condition has the AsyncJobPicker not ready yet, wait to recieve an event that the job picker is initialized.
            // eslint-disable-next-line no-restricted-syntax
            this.jobPickerSubscription = (window as any).ko.postbox.subscribe(
                // eslint-disable-next-line no-restricted-syntax
                (window as any).JobPickerPubSubKeys.JobPickerInitialized,
                this.handleAsyncJobPickerInitialized
            );
        } else {
            await this.handleAsyncJobPickerInitialized();
        }
        document.addEventListener("visibilitychange", this.selectJobOnTabFocus, false);
    };

    selectJobOnTabFocus = () => {
        // When a browser tab is focused and the page is not a SPA then re-select the job in session if not previously selected
        const hidden = document["hidden"];
        let selectedJobIds = this.state.selectedJobIds;
        const jobPickerState = BTLocalStorage.get("bt-object-dangerousJobPickerState");
        if (
            !this.props.isSpa &&
            hidden !== undefined &&
            !hidden &&
            selectedJobIds &&
            jobPickerState
        ) {
            if (typeof selectedJobIds === "number") {
                selectedJobIds = [selectedJobIds];
            }
            if (!isEqual(jobPickerState.selectedJobIds, selectedJobIds)) {
                const job = this.state.jobs.find((j) => j.jobId === getSelectedJobId());
                if (job) {
                    void this.handleJobChange(job, this.state.keywordSearch, true);
                }
            }
        }
    };

    private handleAsyncJobPickerInitialized = async () => {
        // eslint-disable-next-line no-restricted-syntax
        const currentState: any = (window as any).AsyncJobPickerHelper.getCurrentState();
        const displayMode: JobPickerDisplayModes = currentState.displayMode;
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.has("accountSwitched")) {
            queryParams.delete("accountSwitched");
            // eslint-disable-next-line deprecate/member-expression
            routesWebforms.replaceState(null, null, "?" + queryParams.toString());
        }
        // eslint-disable-next-line no-restricted-syntax
        (window as any).JobPickerBridge = {
            pushState: (data: any) => {
                // State being pushed from elsewhere to here
                // Map jobs to the proper object if they're not already. On initialize, they aren't in the same format as the service
                let jobs = data.jobs.map((x: any) =>
                    x.jobInfo ? (x as JobPickerJob) : new JobPickerJob(x)
                );
                data = { ...data, jobs: jobs };

                this.setState({
                    jobs: data.jobs,
                    selectedJobIds: getSelectedJobIdsForJobPicker(
                        data.selectedJobId as number,
                        data.jobs as JobPickerJob[],
                        this.state.selectMode
                    ),
                    templatesOnly: data.templatesOnly,
                });

                // eslint-disable-next-line no-restricted-syntax
                (window as any).btMaster.HideProcessingSpinner();
            },
            // ignorePageReload may be required when switching to a different page after selecting job
            setSelectedJobId: async (id: number, ignorePageReload: boolean = false) => {
                const job =
                    id !== 0
                        ? this.state.jobs.find((x) => x.jobId === id)
                        : new JobPickerJob({ jobId: 0, builderId: 0 });
                if (job !== undefined) {
                    await this.handleJobChange(job, this.state.keywordSearch, ignorePageReload);
                }
                // If the job is not in the job picker then set it on the server side instead
                else {
                    const useOpenOrClosedJobs = BTLocalStorage.get(
                        "bt-boolean-redirectedToJobNotInJobPicker"
                    );
                    if (useOpenOrClosedJobs) {
                        await window.JobPickerBridge.setJobStatusFilterToAllActiveJobs();
                        BTLocalStorage.remove("bt-boolean-redirectedToJobNotInJobPicker");
                    }

                    const response = await this.getSetJobDetailsResponse(
                        { jobId: id, builderId: currentState.builderId },
                        this.state.jobs.map((x) => ({ jobId: x.jobId, builderId: x.builderId })),
                        currentState.persistJobsite,
                        {
                            keywordFilter: "",
                            otherFilter: this.state.templatesOnly
                                ? "Templates"
                                : this.jobStatusFilter,
                            selectedBuilderFilter: currentState.builderId.toString(),
                        } as IApiJobFilterRequest,
                        this.state.templatesOnly
                    );

                    // If we are reloading then the job picker filters need to be cleared, unless we were are using Open or Closed jobs
                    if (response!.needsPageReload && !useOpenOrClosedJobs) {
                        localStorage.removeItem(
                            getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, true)
                        );
                        localStorage.removeItem(
                            getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false)
                        );
                        localStorage.removeItem(
                            getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, true)
                        );
                        localStorage.removeItem(
                            getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, false)
                        );
                        localStorage.removeItem(
                            getJobPickerStorageKey(LocalStorageKeys.SelectedJobPickerFilterId, true)
                        );
                        localStorage.removeItem(
                            getJobPickerStorageKey(
                                LocalStorageKeys.SelectedJobPickerFilterId,
                                false
                            )
                        );
                    }

                    this.handleJobPickerSetResponse(response, ignorePageReload);
                    // If using Open or Closed jobs, we'll need to reload (if we haven't already) in order to properly set the job picker's filters
                    if (useOpenOrClosedJobs && (ignorePageReload || !response!.needsPageReload)) {
                        window.location.reload();
                    }
                }
            },
            setJobStatusFilterToAllActiveJobs: async () => {
                this.jobStatusFilter = [
                    JobStatusFilterTypes.Open,
                    JobStatusFilterTypes.Closed,
                    JobStatusFilterTypes.Presale,
                    JobStatusFilterTypes.Warranty,
                ];

                const neighborhoodFilter = new SelectedFilterItem(null);
                neighborhoodFilter.key = JobPickerFilterTypes.Neighborhood;
                neighborhoodFilter.selectedValue = "";
                neighborhoodFilter.type = FilterType.MultiSelect;

                const projectManagerFilter = new SelectedFilterItem(null);
                projectManagerFilter.key = JobPickerFilterTypes.ProjectManager;
                projectManagerFilter.selectedValue = "";
                projectManagerFilter.type = FilterType.MultiSelect;

                const jobStatusFilter = new SelectedFilterItem(null);
                jobStatusFilter.key = JobPickerFilterTypes.Other;
                jobStatusFilter.type = FilterType.MultiSelect;
                jobStatusFilter.selectedValue = [
                    JobStatusFilterTypes.Open,
                    JobStatusFilterTypes.Closed,
                    JobStatusFilterTypes.Presale,
                    JobStatusFilterTypes.Warranty,
                ];

                const leadStatusFilter = new SelectedFilterItem(null);
                leadStatusFilter.key = JobPickerFilterTypes.LeadStatus;
                leadStatusFilter.type = FilterType.MultiSelect;
                leadStatusFilter.selectedValue = [];

                const filterString = getFilterStringFromItems([
                    neighborhoodFilter,
                    projectManagerFilter,
                    jobStatusFilter,
                    leadStatusFilter,
                ]) as string;

                localStorage.removeItem(
                    getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, true)
                );
                localStorage.removeItem(
                    getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false)
                );
                localStorage.removeItem(
                    getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, true)
                );
                localStorage.removeItem(
                    getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, false)
                );

                localStorage.setItem(
                    getJobPickerStorageKey(
                        LocalStorageKeys.CurrentJobPickerFilter,
                        currentState.templatesOnly
                    ),
                    filterString
                );
            },
            refreshJobPicker: async (selectMode?: JobPickerSelectModes) => {
                const filterString =
                    localStorage.getItem(
                        getJobPickerStorageKey(
                            LocalStorageKeys.CurrentJobPickerFilter,
                            this.state.templatesOnly
                        )
                    ) || "";
                await this.loadJobs(
                    this.state.displayMode,
                    filterString,
                    this.state.templatesOnly,
                    undefined,
                    false,
                    selectMode
                );
            },
            isJobInJobPicker: async (selectedJobId: number) => {
                if (selectedJobId === 0) {
                    return true;
                }
                // Check if selected job is in the job picker
                return this.state.jobs.find((x) => x.jobId === selectedJobId) !== undefined;
            },
        };
        // eslint-disable-next-line no-restricted-syntax
        (window as any).AsyncJobPickerHelper.setComponentReady();

        await this.setupJobPickerWrapper(currentState, displayMode);
    };

    private setupJobPickerWrapper = async (
        currentState: any,
        displayMode: JobPickerDisplayModes
    ) => {
        let currentJobName: string = "";
        try {
            const filters = await this.loadFilters(currentState.templatesOnly);
            const defaultFilter = filters.savedFilters.find((x) => x.isDefault);
            // eslint-disable-next-line deprecate/member-expression
            const qsParams: { jobStatus?: number } = queryString.parse(window.location.search, {
                parseBooleans: true,
            });

            const storedFilterString = localStorage.getItem(
                getJobPickerStorageKey(
                    LocalStorageKeys.CurrentJobPickerFilter,
                    currentState.templatesOnly
                )
            );
            let selectedFilterString =
                storedFilterString || (defaultFilter && defaultFilter.value) || "";

            // REMOVE with ado-165119 followup. We were seeing possible
            // malformed json in localStorage, causing server-side parsing issues.
            try {
                JSON.parse(selectedFilterString);
            } catch (e) {
                if (e instanceof SyntaxError) {
                    const neighborhoodFilter = `"${JobPickerFilterTypes.Neighborhood}":""`;
                    const pmFilter = `"${JobPickerFilterTypes.ProjectManager}":""`;
                    const otherFilter = `"${JobPickerFilterTypes.Other}":"${JobStatusFilterTypes.Open}"`;
                    const leadStatusFilter = `"${JobPickerFilterTypes.LeadStatus}":""`;
                    selectedFilterString = `{${neighborhoodFilter},${pmFilter},${otherFilter},${leadStatusFilter}}`;
                }
            }

            localStorage.setItem(
                getJobPickerStorageKey(
                    LocalStorageKeys.CurrentJobPickerFilter,
                    currentState.templatesOnly
                ),
                selectedFilterString
            );

            let jobPickerState = getJobPickerState();
            const storageValue = {
                ...jobPickerState,
                filters: !currentState.templatesOnly
                    ? selectedFilterString
                    : jobPickerState.filters,
                templateFilters: currentState.templatesOnly
                    ? selectedFilterString
                    : jobPickerState.templateFilters,
                allBuildersSelected: this.isAllBuildersSelected(
                    jobPickerState.selectedJobIds.length === 1 &&
                        jobPickerState.selectedJobIds[0] > 0
                ),
            };
            setJobPickerState(storageValue);

            const resp = await this.loadJobs(
                displayMode,
                selectedFilterString,
                currentState.templatesOnly as boolean,
                undefined,
                true
            );
            // if we have selected filter string, convert to object for <Filter> form
            let selectedFilters = getSelectedFilterItems(filters, selectedFilterString);
            if (resp.needToSetFilterToOpenAndClosed) {
                if (selectedFilters === undefined) {
                    selectedFilters = [];
                }

                const existingOtherFilter = selectedFilters.find(
                    (x) => x.key === JobPickerFilterTypes.Other
                );
                if (existingOtherFilter) {
                    existingOtherFilter.selectedValue =
                        JobStatusFilterTypes.Open | JobStatusFilterTypes.Closed;
                } else {
                    const openOrCloseFilter = new SelectedFilterItem(null as any);
                    openOrCloseFilter.key = JobPickerFilterTypes.Other;
                    openOrCloseFilter.selectedValue =
                        JobStatusFilterTypes.Open | JobStatusFilterTypes.Closed;
                    openOrCloseFilter.type = FilterType.SingleSelect;
                    selectedFilters.push(openOrCloseFilter);
                }
            }

            const selectedStatusFilters = selectedFilters?.find(
                (x) => x.key === JobPickerFilterTypes.Other
            )?.selectedValue;
            if (Array.isArray(selectedStatusFilters)) {
                if (qsParams.jobStatus !== undefined) {
                    selectedStatusFilters.push(Number(qsParams.jobStatus));
                }
                this.jobStatusFilter = [...new Set(selectedStatusFilters)]; // Remove duplicates and turn back into an array
            } else {
                this.jobStatusFilter = [JobStatusFilterTypes.Open];
            }

            const singleJobSelected =
                this.state.jobs.filter((x) => x.jobId !== JobIdTypes.AllJobs).length === 1;
            let jobIdToSet = JobPickerSelectModes.Multiple ? JobIdTypes.AllJobs : JobIdTypes.NoJobs;
            // eslint-disable-next-line deprecate/member-expression
            const selectedJobCookie = Cookies.get("saved_SelectedJob");

            // Use the last selected job from the saved_SelectedJob cookie set by the job picker.
            // If the job does not exist in the job picker then fallback to default behavior
            if (selectedJobCookie !== undefined && selectedJobCookie !== null) {
                const jobIdFromCookie = parseInt(selectedJobCookie);
                if (this.state.jobs.find((j) => j.jobId === jobIdFromCookie)) {
                    jobIdToSet = jobIdFromCookie;
                }
            } else if (singleJobSelected) {
                jobIdToSet = this.state.jobs.find((x) => x.jobId !== JobIdTypes.AllJobs)!.jobId;
            }

            // Assign builder id base on if jobIdToSet is a job id or one of the JobIdTypes
            const builderIdToSet =
                !isSpecialJob(jobIdToSet) && this.state.jobs.length > 0
                    ? this.state.jobs.find((x) => x.jobId !== JobIdTypes.AllJobs)!.builderId
                    : currentState.builderId;

            // If one of the JobIdTypes is the only jobsite, then don't send up selected job ids
            const selectedJobIds =
                this.state.jobs.length === 1 && isSpecialJob(jobIdToSet)
                    ? []
                    : this.state.jobs.map((x) => ({ jobId: x.jobId, builderId: x.builderId }));

            // When you first login, we trigger an event to set your selected job to all listed. That's based on this persistJobsite value
            const refreshForTimeClock =
                this.state.jobs.filter((x) => x.jobId === JobIdTypes.GlobalJob).length > 0 &&
                Array.isArray(this.state.selectedJobIds);

            // If the selectedJobId is not in the jobId from the response, refresh the selectedJobId
            // The jobpicker state and the jobid in the response sometimes went out of sync causing the list page to error out
            // This hack forces the jobpicker state update.
            const triggerUpdate = () => {
                const jobIdsFromResp = resp.jobs.map((x) => x.jobId);
                return (
                    intersection(jobIdsFromResp, jobPickerState.selectedJobIds).length !==
                    jobPickerState.selectedJobIds.length
                );
            };
            if (
                currentState.persistJobsite ||
                currentState.persistFilters ||
                refreshForTimeClock ||
                triggerUpdate()
            ) {
                this.setState({ loading: true });
                await this.getSetJobDetailsResponse(
                    { jobId: jobIdToSet, builderId: builderIdToSet },
                    selectedJobIds,
                    currentState.persistJobsite || currentState.persistFilters,
                    {
                        keywordFilter: "",
                        otherFilter: currentState.templatesOnly
                            ? "Templates"
                            : this.jobStatusFilter.toString(),
                        leadStatusFilter: "",
                        selectedBuilderFilter: currentState.builderId.toString(),
                    },
                    this.state.templatesOnly
                );

                this.setState({ loading: false });
            }

            let jobPickerSelectedIds = getSelectedJobIdsForJobPicker(
                resp.selectedJobId,
                resp.jobs,
                currentState.selectMode
            );
            jobPickerState = getJobPickerState();
            if (typeof jobPickerSelectedIds === "number") {
                jobPickerSelectedIds = [jobPickerSelectedIds];
            } else if (
                !jobPickerSelectedIds &&
                currentState.selectMode !== JobPickerSelectModes.Single
            ) {
                jobPickerSelectedIds = jobPickerState.selectedJobIds;
            }
            jobPickerSelectedIds = jobPickerSelectedIds ?? [];
            selectJobs(
                jobPickerSelectedIds,
                jobPickerSelectedIds.length > 1 || resp.selectedJobId === JobIdTypes.AllJobs
            );

            this.setState({
                filters,
                selectedFilters,
                sortOption: currentState.jobSortChoice,
                selectMode: currentState.selectMode,
                templatesOnly: currentState.templatesOnly,
            });
            const selectedJobs = resp.jobs.filter((j) => j.jobId === resp.selectedJobId);
            if (selectedJobs.length === 1) {
                currentJobName = selectedJobs[0].jobName;
            }
        } catch (e) {
            showAPIErrorMessage(e);
        }

        // eslint-disable-next-line no-restricted-syntax
        (window as any).ko.postbox.publish(
            // eslint-disable-next-line no-restricted-syntax
            (window as any).JobPickerPubSubKeys.JobPickerLoadComplete,
            {
                selectedJobId: getSelectedJobId(),
                selectedJobName: currentJobName,
            }
        );
    };

    componentWillUnmount() {
        this.clearSetJobAPIHandlerResult();
        if (this.jobPickerSubscription !== undefined) {
            this.jobPickerSubscription.dispose();
        }
        document.removeEventListener("visibilitychange", this.selectJobOnTabFocus);
    }

    setJobAPIHandlerResult?: IAPIHandlerResult<JobPickerSetResponse>;
    private getSetJobDetailsResponse(
        selectedJobId: IApiJobBuilderRequest,
        selectedJobIds: IApiJobBuilderRequest[],
        persistFilters: boolean,
        filtersData: IApiJobFilterRequest,
        isTemplateMode: boolean
    ): Promise<JobPickerSetResponse | undefined> {
        this.clearSetJobAPIHandlerResult();
        // non-SPA storage
        this.setJobAPIHandlerResult = this.props.handler!.set(
            selectedJobId,
            selectedJobIds,
            persistFilters,
            filtersData
        );
        // SPA storage
        let jobIds = getSelectedJobIdsForJobPicker(
            selectedJobId.jobId,
            selectedJobIds,
            this.state.selectMode
        );
        if (typeof jobIds === "number") {
            jobIds = [jobIds];
        }
        jobIds = jobIds ?? [];
        const jobPickerState = getJobPickerState();
        const isAllJobsSelected = jobIds.length > 1 || selectedJobId.jobId === JobIdTypes.AllJobs;
        const storageValue = {
            ...jobPickerState,
            keywordSearch: filtersData.keywordFilter,
            isTemplateMode: isTemplateMode,
            selectedJobIds: jobIds,
            isAllJobsSelected: isAllJobsSelected,
            allBuildersSelected: this.isAllBuildersSelected(jobIds.length === 1 && jobIds[0] > 0),
        };
        setJobPickerState(storageValue);

        return this.setJobAPIHandlerResult.response;
    }

    private clearSetJobAPIHandlerResult() {
        if (this.setJobAPIHandlerResult) {
            this.setJobAPIHandlerResult.cancel();
            this.setJobAPIHandlerResult = undefined;
        }
    }

    private loadFilters = async (templatesOnly: boolean): Promise<FilterEntity> => {
        const filterType = templatesOnly
            ? FilterEntityType.TemplateJobPicker
            : FilterEntityType.JobPicker;
        return await this.props.filterHandler!.get(filterType);
    };

    private loadJobs = async (
        displayMode: JobPickerDisplayModes,
        filterString: string,
        templatesOnly: boolean,
        filterFormValues: IFilterFormValues | undefined,
        isInitialLoad: boolean = false,
        selectMode?: JobPickerSelectModes
    ) => {
        // eslint-disable-next-line no-restricted-syntax
        const currentState = (window as any).AsyncJobPickerHelper.getCurrentState();

        const jobPickerState = getJobPickerState();
        const resp = await this.props.handler!.get(
            currentState.builderId,
            getSelectedJobId(),
            currentState.allowGlobalJob,
            selectMode ?? currentState.selectMode,
            displayMode,
            filterString,
            templatesOnly,
            false
        );
        let jobs = resp.jobs;

        // resp.userId set this to state

        const selectedJobIds = getSelectedJobIdsForJobPicker(getSelectedJobId(), jobs, selectMode);

        this.setState((prevState) => ({
            jobs: jobs,
            selectedJobIds: selectedJobIds,
            jobRunningTotal: resp.headerInfo?.jobRunningTotal,
            permissions: resp.permissions,
            ownerInfo: resp.ownerInfo,
            displayMode: displayMode,
            keywordSearch:
                localStorage.getItem(
                    getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false)
                ) || "", // For keyword search, we should maintain it as you change between templates and jobs
            listMetadata: resp.listMetadata,
            availableAccounts: resp.availableAccounts,
            selectedFilters: filterFormValues ? filterFormValues.items : prevState.selectedFilters,
            loading: false,
            selectMode: selectMode ?? prevState.selectMode,
            headerInfo: resp.headerInfo,
            isConnectedToAccounting: resp.isConnectedToAccounting,
            userId: resp.userId,
            isListLimited: resp.isListLimited,
        }));

        if (isInitialLoad) {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).AsyncJobPickerHelper.loadComplete(resp);
        } else {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).AsyncJobPickerHelper.updateJobs(resp);
            this.handleJobPickerHeight(this.state.collapsed); // When reloading jobs list, resize the JP based on added or removed jobs
        }
        // eslint-disable-next-line no-restricted-syntax
        (window as any).AsyncJobPickerHelper.updateJobs({ ...resp, jobs: jobs });

        const storageValue = {
            ...jobPickerState,
            filters: !this.state.templatesOnly ? filterString : jobPickerState.filters,
            templateFilters: this.state.templatesOnly
                ? filterString
                : jobPickerState.templateFilters,
        };
        setJobPickerState(storageValue);

        this.loadMetadata(jobs, displayMode);

        return resp;
    };

    private loadMetadata(jobs: JobPickerJob[], displayMode: JobPickerDisplayModes) {
        void this.props.handler
            ?.getMetadata(
                jobs.map((v) => v.jobId),
                displayMode
            )
            .then((r) => {
                let lookup = {};
                for (let i in r.jobs) {
                    lookup[r.jobs[i].jobId] = r.jobs[i];
                }
                jobs = jobs.map((j) => {
                    const metadata = lookup[j.jobId];
                    if (metadata) {
                        j.hasTakeoff = metadata.hasTakeoff;
                    }

                    return j;
                });

                this.setState({
                    jobs: jobs,
                });
            });
    }

    private refreshJobs = async () => {
        const defaultFilter =
            this.state.filters && this.state.filters.savedFilters.find((x) => x.isDefault);
        const filterString =
            localStorage.getItem(
                getJobPickerStorageKey(
                    LocalStorageKeys.CurrentJobPickerFilter,
                    this.state.templatesOnly
                )
            ) ||
            (defaultFilter && defaultFilter.value) ||
            "";
        await this.loadJobs(
            this.state.displayMode,
            filterString,
            this.state.templatesOnly,
            undefined,
            undefined,
            this.state.selectMode
        );
    };

    private handleFilterSubmit = async (filterValues: IFilterFormValues, keywordSearch: string) => {
        this.setState(
            {
                loading: true,
                keywordSearch,
            },
            void this.submitFiltersAsync(filterValues, keywordSearch)
        );
    };

    private submitFiltersAsync = async (filterValues: IFilterFormValues, keywordSearch: string) => {
        const currentJobCount = this.state.jobs.filter((x) => x.jobId > JobIdTypes.AllJobs).length;
        // reload jobs with new filters
        const filterString = getFilterString(filterValues);
        await this.loadJobs(
            this.state.displayMode,
            filterString,
            this.state.templatesOnly,
            filterValues
        );
        // call endpoint to update filters
        // eslint-disable-next-line no-restricted-syntax
        const jpHelper = (window as any).AsyncJobPickerHelper;
        const builderId: number = jpHelper.getCurrentState().builderId;
        const jobGroupFilterItem = filterValues.items.find(
            (i) => i.key === JobPickerFilterTypes.Neighborhood
        );
        const projectManagerFilterItem = filterValues.items.find(
            (i) => i.key === JobPickerFilterTypes.ProjectManager
        );
        const selectedJobStatusFilters = filterValues.items.find(
            (x) => x.key === JobPickerFilterTypes.Other
        )?.selectedValue;
        const selectedLeadStatusFilters = filterValues.items.find(
            (x) => x.key === JobPickerFilterTypes.LeadStatus
        )?.selectedValue as LeadStatus[];
        if (Array.isArray(selectedJobStatusFilters)) {
            this.jobStatusFilter = selectedJobStatusFilters;
        } else {
            this.jobStatusFilter = [JobStatusFilterTypes.Open];
        }
        const filterRequest: IApiJobFilterRequest = {
            keywordFilter: keywordSearch,
            otherFilter: this.state.templatesOnly ? "Templates" : this.jobStatusFilter.toString(),
            leadStatusFilter: selectedLeadStatusFilters ? selectedLeadStatusFilters.toString() : "",
            jobGroupFilter: jobGroupFilterItem
                ? (jobGroupFilterItem.selectedValue as number[]).map((s: number) => s.toString())
                : undefined,
            projectManagerFilter: projectManagerFilterItem
                ? (projectManagerFilterItem.selectedValue as number[]).map((s: number) =>
                      s.toString()
                  )
                : undefined,
            selectedBuilderFilter: builderId,
        };
        let selectedJob: JobPickerJob | undefined =
            this.state.jobs.find((x) => x.jobId === this.state.selectedJobIds) ??
            new JobPickerJob({ jobId: JobIdTypes.NoJobs });

        // Was on the only job in the list, treat it as all listed
        const allJobs = this.state.jobs.find((x) => x.jobId === JobIdTypes.AllJobs);
        if (currentJobCount === 1 && allJobs) {
            selectedJob = allJobs;
        }
        const selectedJobs: IApiJobBuilderRequest[] =
            selectedJob?.jobId === JobIdTypes.AllJobs
                ? this.state.jobs.map((j: any) => ({
                      jobId: j.jobId,
                      builderId: j.builderId,
                  }))
                : [];

        // persist filter/search in localStorage
        localStorage.setItem(
            getJobPickerStorageKey(
                LocalStorageKeys.CurrentJobPickerFilter,
                this.state.templatesOnly
            ),
            filterString
        );
        localStorage.setItem(
            getJobPickerStorageKey(
                LocalStorageKeys.SelectedJobPickerFilterId,
                this.state.templatesOnly
            ),
            filterValues.savedFilter.id.toString()
        );
        localStorage.setItem(
            getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false),
            keywordSearch
        ); // For keyword search, we should maintain it as you change between templates and jobs

        const jobBuilderRequest: IApiJobBuilderRequest = selectedJob ?? allJobs!;
        const setResponse = await this.getSetJobDetailsResponse(
            jobBuilderRequest,
            selectedJobs,
            true,
            filterRequest,
            this.state.templatesOnly
        );
        if (
            jobBuilderRequest.jobId === JobIdTypes.AllJobs ||
            jobBuilderRequest.jobId === JobIdTypes.NoJobs
        ) {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).btMaster.ShowProcessingSpinnerForPostbacks();
            jpHelper.triggerPostback(); // Maintaining this from webforms. Need to get the Please Select A Job option to come up when applicable
        } else {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).AsyncJobPickerHelper.triggerJobUpdate(selectedJob);
            this.handleJobPickerSetResponse(setResponse);
            void message.success("Results Updated");
        }
    };

    private handleBuilderChange = async (newBuilderId: number) => {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).btMaster.ShowProcessingSpinnerForPostbacks();
        // eslint-disable-next-line no-restricted-syntax
        const jpHelper = (window as any).AsyncJobPickerHelper;

        this.setState({
            selectedJobIds: JobIdTypes.NoJobs,
            keywordSearch: "",
        });

        const { templatesOnly } = this.state;

        const filterRequest: IApiJobFilterRequest = {
            keywordFilter: "",
            otherFilter: templatesOnly ? "Templates" : this.jobStatusFilter.toString(),
            leadStatusFilter: "",
            jobGroupFilter: [],
            projectManagerFilter: [],
            selectedBuilderFilter: newBuilderId,
        };

        await this.getSetJobDetailsResponse(
            {
                jobId: JobIdTypes.NoJobs,
                builderId: newBuilderId,
            },
            [],
            true,
            filterRequest,
            this.state.templatesOnly
        );

        jpHelper.getCurrentState().builderId = newBuilderId;
        jpHelper.triggerPostback();
    };

    private handleJobChange = async (
        job: JobPickerJob,
        keywordSearch: string,
        ignorePageReload: boolean = false
    ) => {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).btMaster.ShowProcessingSpinnerForPostbacks();
        const isSubWithMultipleBuildersSelected =
            isInPortal({ subs: true }) &&
            ((typeof this.state.selectedJobIds === "number" &&
                this.state.selectedJobIds === JobIdTypes.AllJobs) ||
                (Array.isArray(this.state.selectedJobIds) &&
                    this.state.selectedJobIds!.length > 1));
        const filterRequest: IApiJobFilterRequest = {
            keywordFilter: keywordSearch,
            otherFilter: this.state.templatesOnly ? "Templates" : this.jobStatusFilter.toString(),
            leadStatusFilter: "",
            selectedBuilderFilter: isSubWithMultipleBuildersSelected ? 0 : job.builderId,
        };
        let setResponse: JobPickerSetResponse | undefined;

        if (job.jobId === 0) {
            const jobs = filterJobsList(this.state.jobs, keywordSearch, this.state.templatesOnly);
            const jobIds = jobs.map((x) => ({ jobId: x.jobId, builderId: x.builderId }));
            setResponse = await this.getSetJobDetailsResponse(
                { builderId: 0, jobId: JobIdTypes.AllJobs },
                jobIds,
                false,
                filterRequest,
                this.state.templatesOnly
            );
            // eslint-disable-next-line no-restricted-syntax
            (window as any).AsyncJobPickerHelper.updateJobs({ jobs: jobs, selectedJobId: 0 });
        } else {
            setResponse = await this.getSetJobDetailsResponse(
                { builderId: job.builderId, jobId: job.jobId },
                [],
                false,
                filterRequest,
                this.state.templatesOnly
            );
        }
        this.setState({
            keywordSearch,
            headerInfo: setResponse?.headerInfo,
            jobRunningTotal: setResponse?.headerInfo?.jobRunningTotal,
            ownerInfo: setResponse?.ownerInfo!,
        });
        localStorage.setItem(
            getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false),
            keywordSearch
        ); // For keyword search, we should maintain it as you change between templates and jobs
        // eslint-disable-next-line no-restricted-syntax
        const shouldHideSpinner = (window as any).AsyncJobPickerHelper.triggerJobUpdate(job);
        this.handleJobPickerSetResponse(setResponse, ignorePageReload);
        this.handleJobPickerHeight(this.state.collapsed); // Have to adjust height because if you go from no jobs to having a job you get a scrollbar where you otherwise wouldn't
        if (shouldHideSpinner) {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).btMaster.HideProcessingSpinner();
        }
    };

    // ignorePageReload may be required when switching to a different page after selecting job
    private handleJobPickerSetResponse = (
        setResponse?: JobPickerSetResponse,
        ignorePageReload: boolean = false
    ) => {
        if (!setResponse) return;

        if (setResponse.needsPageReload && !ignorePageReload) {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).btMaster.ShowProcessingSpinnerForPostbacks();
            // Do a reload if using a hash router
            if (window.location.href.includes("#/")) {
                window.location.reload();
            } else {
                window.location.assign(window.location.href);
            }
        }

        this.setState({
            jobRunningTotal: setResponse?.headerInfo?.jobRunningTotal,
            ownerInfo: setResponse?.ownerInfo,
        });
    };

    private handleCollapse = (isCollapsed: boolean) => {
        this.setState({ collapsed: isCollapsed });
        this.handleJobPickerHeight(isCollapsed);
    };

    private closeModal = (matchProps: RouteComponentProps) => {
        matchProps.history.replace(matchProps.match.url);
        if (this.shouldRefresh) {
            // eslint-disable-next-line deprecate/member-expression
            routesWebforms.refreshPage();
        }
    };

    private closeModalRefreshJobs = async (matchProps: RouteComponentProps) => {
        this.closeModal(matchProps);
        await this.refreshJobs();
    };

    private shouldRefresh = false;
    private refreshAfterClose = () => {
        this.shouldRefresh = true;
    };

    /**
     * Handles resizing the jobpicker (on webforms). There's a lot of cases to handle in here, wanted to document up here
     * Case 1: Jobpicker never needs a scrollbar, and the page also doesn't have a scroll bar - Just render the JP
     * Case 2: Jobpicker never needs a scrollbar and the page does have a scrollbar - JP scrolls with page until the branding bar is out of the page, then JP scrolls with the page
     * Case 3: Jobpicker needs a scrollbar while header is in view, but won't always need it: Recalculate need for scrollbar as you scroll and as the header goes out of view *and the footer comes in view* We shrink the jobpicker when footer comes in view, this handles that too
     * Case 4: Always has a scrollbar - Scroll area needs to resize as we scroll down the page and the branding header leaves view or Legal footer enters view
     */
    handleJobPickerHeight = throttle((isCollapsed: boolean) => {
        window.btMaster.setSidebarSize(isCollapsed);
    }, 1); // We are throttling this to 1ms so we don't call setState in a loop, for big builders this will take more than 1ms, but there is noticable jitter, so I want to keep it low, just not each time scroll fires

    private handleSortChanged = async (sortOption: JobSortOptions) => {
        this.setState({ sortOption });
        await this.props.handler!.setSort(sortOption);
        if (this.state.isListLimited) {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).AsyncJobPickerHelper.triggerPostback();
        }
    };

    private handleTemplateModeChanged = async (
        templatesOnly: boolean,
        builderId: number,
        initialTemplateTab?: TemplateListTabs
    ) => {
        if (this.state.templatesOnly === templatesOnly) {
            return;
        }

        // eslint-disable-next-line no-restricted-syntax
        (window as any).AsyncJobPickerHelper.setTemplateMode(templatesOnly);

        await this.getSetJobDetailsResponse(
            { builderId: 0, jobId: JobIdTypes.NoJobs },
            [],
            true,
            {
                keywordFilter: this.state.keywordSearch,
                otherFilter: templatesOnly ? "Templates" : JobStatusFilterTypes.Open.toString(),
                leadStatusFilter: "",
                selectedBuilderFilter: builderId,
            },
            templatesOnly
        );

        // Note that setState is not needed here because we redirect.
        // So the workflow is: Redirect->Initialize->Initialize has TemplatesOnly true->Load JobPicker and Templates mode is true
        // eslint-disable-next-line no-restricted-syntax
        (window as any).btMaster.ShowProcessingSpinnerForPostbacks();
        if (templatesOnly) {
            const defaultTab = this.state.permissions.canViewJobDetails
                ? TemplateListTabs.MyTemplates
                : TemplateListTabs.SelectedTemplate;
            const initialTab = initialTemplateTab ?? defaultTab;
            window.location.assign(getBaseRoute() + routes.template.getListLink(initialTab));
        } else {
            window.location.assign(getBaseRoute() + routes.landingPage.getLink());
        }
    };

    private getSelectedFilterIdFromStorage = () => {
        return (
            parseInt(
                localStorage.getItem(
                    getJobPickerStorageKey(
                        LocalStorageKeys.SelectedJobPickerFilterId,
                        this.state.templatesOnly
                    )
                ) || ""
            ) || undefined
        );
    };

    private handleKeywordClear = async () => {
        await this.handleKeywordUpdate("");
    };

    private handleKeywordUpdate = async (newKeywordSearch: string) => {
        // eslint-disable-next-line no-restricted-syntax
        (window as any).btMaster.ShowProcessingSpinnerForPostbacks();
        localStorage.setItem(
            getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false),
            newKeywordSearch
        ); // For keyword search, we should maintain it as you change between templates and jobs
        // eslint-disable-next-line no-restricted-syntax
        const jpHelper = (window as any).AsyncJobPickerHelper;
        const builderId = jpHelper.GetSelectedBuilderId();
        let selectedJobId: number =
            typeof this.state.selectedJobIds === "number"
                ? this.state.selectedJobIds
                : JobIdTypes.AllJobs;
        // If a Sub with multiple builders is clearing while on "All Builders"
        if (this.isAllBuildersSelected(selectedJobId > 0)) {
            selectedJobId = JobIdTypes.NoJobs;
        }
        await this.getSetJobDetailsResponse(
            { builderId: builderId, jobId: selectedJobId },
            [],
            false,
            {
                keywordFilter: newKeywordSearch,
                otherFilter: this.state.templatesOnly
                    ? "Templates"
                    : this.jobStatusFilter.toString(),
                leadStatusFilter: "",
                selectedBuilderFilter: builderId,
            },
            this.state.templatesOnly
        );

        // eslint-disable-next-line no-restricted-syntax
        (window as any).AsyncJobPickerHelper.triggerPostback();
    };

    private isAllBuildersSelected = (hasSingleJobSelected: boolean) => {
        // eslint-disable-next-line no-restricted-syntax
        const jpHelper = (window as any).AsyncJobPickerHelper;
        const builderId = jpHelper.GetSelectedBuilderId();

        return (
            isInPortal({ subs: true }) &&
            builderId === CommonConstants.EmptyInteger &&
            !hasSingleJobSelected
        );
    };

    private handleDebouncedKeywordUpdate = debounce(this.handleKeywordUpdate, 1000);

    private handleDismissBanner = async () => {
        const entityType = this.state.templatesOnly
            ? EmptyStateEntity.JobTemplates
            : EmptyStateEntity.Jobs;
        await this.props.emptyStateHandler!.dismissAlert(entityType);
        this.handleJobPickerHeight(false);
    };

    // After switching accounts, we need to use assign to reload the page so that the old hidMasterBuilderId form value isn't persisted
    // For pages using hashRouter (i.e. Document Redesign pages), we need to also add a qsParam to force the reload.
    private reloadAfterAccountSwitch() {
        let location = window.location.href.split("#");
        if (location[1]) {
            window.location.assign(`${location[0]}?accountSwitched=true#${location[1]}`);
        } else {
            window.location.assign(window.location.href);
        }
    }

    private handleSwitchError = (e: any, newUserId?: number) => {
        if (e instanceof APIError && e.response.data?.switchBuilderFrozen) {
            // Builder frozen, show the frozen screen
            let qsDict = {
                dMethod: "iframe",
                overrideSessionUserLoginID: newUserId,
                fromAccountSwitcher: true,
            };
            // eslint-disable-next-line no-restricted-syntax
            (window as any).btMaster.btDialogs.createAndFireDialog({
                dBoxMethod: "iframe",
                width: "1200px",
                height: "800px",
                // eslint-disable-next-line no-restricted-syntax
                src: (window as any).QSHandler.CreateQueryStringFromDictionary(
                    // eslint-disable-next-line no-restricted-syntax
                    (window as any).Global.PageUrlHandler.MiscPageUrl.FrozenBuilderNotice,
                    qsDict
                ),
                showCloseX: "true",
                showLoadingSpinner: "true",
                closeCallback: () => window.location.reload(),
            });
        } else {
            showAPIErrorMessage(e);
        }
    };

    private handleAccountChange = async (items: AccountSwitcherItem[]) => {
        try {
            if (items.length > 1) {
                void this.handleBuilderChange(CommonConstants.EmptyInteger);
                return;
            }
            const resp = await this.props.handler!.switchAccount(items[0].globalUserId);

            if (
                getGlobalValue("loginTypeInt") === BTLoginTypes.SUBS &&
                items[0].loginType === BTLoginTypes.SUBS
            ) {
                await this.handleBuilderChange(items[0].builderId);
            } else if (resp.refreshUrl) {
                window.location.assign(resp.refreshUrl);
            } else {
                this.reloadAfterAccountSwitch();
            }
        } catch (e) {
            this.handleSwitchError(e, items[0].globalUserId);
        }
    };

    render = () => {
        // Data in here is currently placeholder
        if (
            this.state === null ||
            this.state.jobs === undefined ||
            this.state.filters === undefined
        ) {
            return <ResizableJobPickerLoading />;
        }
        const summaryUrl = this.state.templatesOnly
            ? getBaseRoute() + routes.template.getListLink()
            : routes.landingPage.getLinkForLoginType(getGlobalValue("loginTypeInt")!, true);

        const width = BTLocalStorage.get("bt-number-sidebarWidth");
        // eslint-disable-next-line no-restricted-syntax
        const jpHelperState = (window as any).AsyncJobPickerHelper.getCurrentState();

        return (
            <>
                <JobPicker
                    onJobPickerJobChanged={this.handleJobChange}
                    onJobPickerBuilderChanged={this.handleBuilderChange}
                    displayMode={this.state.displayMode}
                    selectedJobIds={this.state.selectedJobIds}
                    jobs={this.state.jobs}
                    filters={this.state.filters}
                    selectedFilters={this.state.selectedFilters}
                    selectedFilterId={this.getSelectedFilterIdFromStorage()}
                    onCollapse={this.handleCollapse}
                    permissions={this.state.permissions}
                    ownerInfo={this.state.ownerInfo}
                    width={width}
                    templatesOnly={this.state.templatesOnly}
                    onTemplateModeChanged={this.handleTemplateModeChanged}
                    onFilterSubmit={this.handleFilterSubmit}
                    onSavedFiltersUpdated={(filters: FilterEntity) =>
                        this.setState({ filters: filters })
                    }
                    onSortChanged={this.handleSortChanged}
                    sortOption={this.state.sortOption}
                    selectMode={this.state.selectMode}
                    keywordSearch={this.state.keywordSearch}
                    onKeywordClear={this.handleKeywordClear}
                    listMetadata={this.state.listMetadata}
                    onDismissBanner={this.handleDismissBanner}
                    availableAccounts={this.state.availableAccounts}
                    loading={this.state.loading}
                    areSavedFiltersShowing={this.state.areSavedFiltersShowing}
                    onSavedFilterVisibleChange={(visible) => {
                        this.setState({ areSavedFiltersShowing: visible });
                    }}
                    onAccountChange={this.handleAccountChange}
                    headerInfo={this.state.headerInfo}
                    jobRunningTotal={this.state.jobRunningTotal}
                    summaryUrl={summaryUrl}
                    match={this.props.match}
                    history={this.props.history}
                    location={this.props.location}
                    isConnectedToAccounting={this.state.isConnectedToAccounting}
                    fromReact={false}
                    builderId={parseInt(jpHelperState.builderId)}
                    jobId={
                        typeof this.state.selectedJobIds === "number"
                            ? this.state.selectedJobIds
                            : JobIdTypes.AllJobs
                    }
                    userId={this.state.userId}
                    isTemplateMode={this.state.templatesOnly}
                    onResizeWindow={this.handleResize}
                    isListLimited={this.state.isListLimited}
                    onServerKeywordUpdate={this.handleDebouncedKeywordUpdate}
                />
                <RouteRelative
                    path="/JobPickerActions"
                    render={(routeProps) => (
                        <>
                            <RouteJob
                                beforeClose={() => this.closeModalRefreshJobs(this.props)}
                                history={routeProps.history}
                                parentRoute={routeProps.match.path}
                                onSave={this.refreshAfterClose}
                            />
                            <RouteTemplate
                                modalConfig={{
                                    beforeClose: () => this.closeModalRefreshJobs(this.props),
                                    parentRoute: routeProps.match.path,
                                }}
                                onCreateSuccess={this.refreshAfterClose}
                            />
                            <RouteTemplateFromTemplate
                                modalConfig={{
                                    beforeClose: () => this.closeModalRefreshJobs(this.props),
                                    parentRoute: routeProps.match.path,
                                }}
                            />
                            <RouteMessageCompose
                                modalConfig={{
                                    beforeClose: () => this.closeModal(this.props),
                                    parentRoute: routeProps.match.path,
                                }}
                            />
                        </>
                    )}
                />
            </>
        );
    };
}

const JobPickerWrapper = (props: IJobPickerWrapperProps) => {
    return (
        <AppDefaultInfoContext.Consumer>
            {(appDefaultValues) => (
                <SpaInfoContext.Consumer>
                    {(spaContext) => (
                        <JobPickerWrapperInternal
                            {...props}
                            appDefaultInfo={appDefaultValues}
                            isSpa={spaContext.isSpa}
                        />
                    )}
                </SpaInfoContext.Consumer>
            )}
        </AppDefaultInfoContext.Consumer>
    );
};

export default JobPickerWrapper;

// When changing, don't forget to also update the Webform versions of these functions that are in AdminAutologin.js
export function getJobPickerStorageKey(
    keyBase: string,
    templatesOnly: boolean,
    builderId?: number
) {
    let currentBuilderId;

    if (builderId) {
        currentBuilderId = builderId;
    } else {
        // eslint-disable-next-line no-restricted-syntax
        currentBuilderId = (window as any).AsyncJobPickerHelper.getCurrentState().builderId;
    }
    if (currentBuilderId) {
        const templateString = templatesOnly ? "1" : "0";
        return `${keyBase}_${currentBuilderId}_${templateString}`;
    }
    return "";
}

export function clearJobPickerKeys(builderId: number) {
    // JobPicker Filters
    localStorage.removeItem(
        getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, true, builderId)
    );
    localStorage.removeItem(
        getJobPickerStorageKey(LocalStorageKeys.CurrentJobPickerFilter, false, builderId)
    );
    // JobPicker Search Bar
    localStorage.removeItem(
        getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, true, builderId)
    );
    localStorage.removeItem(
        getJobPickerStorageKey(LocalStorageKeys.JobPickerKeywordSearch, false, builderId)
    );
    // JobPicker Filter Id
    localStorage.removeItem(
        getJobPickerStorageKey(LocalStorageKeys.SelectedJobPickerFilterId, true, builderId)
    );
    localStorage.removeItem(
        getJobPickerStorageKey(LocalStorageKeys.SelectedJobPickerFilterId, false, builderId)
    );
    BTLocalStorage.remove("bt-object-dangerousJobPickerState");
    BTSessionStorage.remove("bt-object-dangerousJobPickerState");
}
