import { FileViewOptions } from "legacyComponents/FileUploadContainer.types";
import { getNotifyOptions, getShowOptions } from "legacyComponents/FileUploadContainer.utils";

import { BTLoginTypes } from "types/enum";

export function getAttachmentOptions(fileViewOptions: FileViewOptions | undefined) {
    const showOptions = getShowOptions(fileViewOptions);
    const notifyOptions = getNotifyOptions(fileViewOptions);

    // The show and notify sections only support these login types.
    // Any other types that would happen to be present should not show the section
    const supportedLoginTypes = [
        BTLoginTypes.BUILDER,
        BTLoginTypes.SUBS,
        BTLoginTypes.OWNER,
        BTLoginTypes.CONTACT,
    ];

    const isShowVisible =
        showOptions?.visible &&
        showOptions?.options
            ?.filter((o) => supportedLoginTypes.includes(o.loginType))
            .some((o) => o.visible);

    const isNotifyVisible =
        notifyOptions?.visible &&
        notifyOptions?.options
            ?.filter((o) => supportedLoginTypes.includes(o.loginType))
            .some((o) => o.visible);

    return {
        showOptions,
        isShowVisible: isShowVisible ?? false,
        notifyOptions,
        isNotifyVisible: isNotifyVisible ?? false,
    };
}
